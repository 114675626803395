import { convertFlat } from "../commonLoop";
import { createKeyValueTable } from "../keyValueTable";
export const keyValueMatch = async ({ query, listDefinitionsTable, result, workerContext, }) => {
    const dict = createKeyValueTable(result, listDefinitionsTable, query);
    if (!dict) {
        return [];
    }
    const _result = convertFlat(workerContext, result, (e) => {
        const key = e.currentWord;
        const ret = (dict[key] ?? []).map((e2) => {
            const newe = JSON.parse(JSON.stringify(e));
            newe.currentWord = e2;
            return newe;
        });
        return ret;
    });
    return _result;
};
