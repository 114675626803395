import CasinoIcon from "@mui/icons-material/Casino";
import { Button, ToggleButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useCallback, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

const renderRow = (props: ListChildComponentProps<string[] | ReactNode[]>) => {
  const { index, style, data } = props;

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      sx={{ my: 0, py: 0 }}
    >
      <ListItemText
        primary={data[index]}
        sx={{ my: 0, py: 0, whiteSpace: "nowrap" }}
      />
    </ListItem>
  );
};

type ResultProps = {
  items: string[] | ReactNode[];
  loading?: boolean;
  loadingMessage?: ReactNode;
  row?: (props: ListChildComponentProps<string[] | ReactNode[]>) => JSX.Element;
};

export const ResultList: FC<ResultProps> = ({
  items,
  loading,
  loadingMessage,
  row,
}) => {
  const [download, setDownload] = useState("");
  const handleDownload = () => {
    setDownload(
      "data:text/plain;charset=utf-8," + encodeURIComponent(items.join("\n"))
    );
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenRandomWordSnack = useCallback(() => {
    if (items.length === 0) {
      return;
    }
    console.log("enqueueSnackbar");
    enqueueSnackbar(items[Math.floor(Math.random() * items.length)]);
  }, [enqueueSnackbar, items]);

  return (
    <>
      <Typography mt={2} variant="h4">
        結果
      </Typography>
      {loading ? (
        <Typography mt={2} variant="body1">
          {loadingMessage ?? "検索中..."}
        </Typography>
      ) : (
        <>
          <Typography mt={2} variant="body1">
            {!loading && items.length > 0 && (
              <ToggleButton
                value="random"
                size="small"
                color="primary"
                onChange={() => {
                  handleOpenRandomWordSnack();
                }}
                sx={{ mr: 1 }}
              >
                <CasinoIcon />
              </ToggleButton>
            )}
            {items.length}件
          </Typography>
          {items.length > 0 && (
            <Box>
              <a
                href={download}
                download="download.txt"
                onClick={handleDownload}
              >
                ダウンロード
              </a>
              <Button
                variant="contained"
                sx={{ mt: 2, ml: 2, mr: 2 }}
                onClick={() => {
                  navigator.clipboard.writeText(items.join("\n"));
                }}
              >
                クリップボードにコピー
              </Button>
            </Box>
          )}
        </>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          bgcolor: "background.paper",
        }}
      >
        <FixedSizeList
          height={600}
          width={"100%"}
          itemSize={24}
          itemCount={items.length}
          overscanCount={20}
          itemData={items}
        >
          {row ?? renderRow}
        </FixedSizeList>
      </Box>
    </>
  );
};
