import { Position } from "./Position";

export type Cell = {
  qdir: number;
  qnum: number;
};
export type Board = {
  cell: Cell[];
  cols: number;
  rows: number;
  inBoard: (pos: Position) => boolean;
};

export const maxSize = 256;
export const toId = (pos: Position) => (pos.y << 16) | pos.x;
export const toPos = (id: number) => ({
  x: id & 0xff,
  y: id >> 16,
});

export const createBoard = (col: number, row: number) => {
  const board = {
    cell: [],
    cols: col,
    rows: row,
    inBoard: (pos: Position) => {
      return pos.x >= 0 && pos.x < col && pos.y >= 0 && pos.y < row;
    },
  } as Board;
  for (let i = 0; i < col * row; i++) {
    board.cell.push({
      qdir: -1,
      qnum: -1,
    });
  }
  board.cell[4].qnum = 3;
  return board;
};
