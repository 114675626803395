export const riddledCapitalData = [
  ["とうきょう", "＊＊＊＊う"],
  ["うつのみや", "＊＊＊＊や"],
  ["せんだい", "＊＊＊い"],
  ["おおさか", "しずおか、ふくおか、もりおか、＊＊＊か"],
  ["しずおか", "おおさか、ふくおか、もりおか、＊＊＊か"],
  ["ふくおか", "おおさか、しずおか、もりおか、＊＊＊か"],
  ["もりおか", "おおさか、しずおか、もりおか、＊＊＊か"],
  ["ながさき", "みやざき、＊＊＊き"],
  ["みやざき", "ながさき、＊＊＊き"],
  ["まえばし", "＊＊＊し"],
  ["おおいた", "にいがた、やまがた、＊＊＊た"],
  ["にいがた", "おおいた、やまがた、＊＊＊た"],
  ["やまがた", "おおいた、にいがた、＊＊＊た"],
  ["やまぐち", "＊＊＊ち"],
  ["たかまつ", "＊＊＊つ"],
  ["きょうと", "くまもと、＊＊＊と"],
  ["くまもと", "きょうと、＊＊＊と"],
  [
    "おかやま",
    "かごしま、さいたま、とくしま、ひろしま、ふくしま、まつやま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "かごしま",
    "おかやま、さいたま、とくしま、ひろしま、ふくしま、まつやま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "さいたま",
    "おかやま、かごしま、とくしま、ひろしま、ふくしま、まつやま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "とくしま",
    "おかやま、かごしま、さいたま、ひろしま、ふくしま、まつやま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "ひろしま",
    "おかやま、かごしま、さいたま、とくしま、ふくしま、まつやま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "ふくしま",
    "おかやま、かごしま、さいたま、とくしま、ひろしま、まつやま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "まつやま",
    "おかやま、かごしま、さいたま、とくしま、ひろしま、ふくしま、よこはま、わかやま、＊＊＊ま",
  ],
  [
    "よこはま",
    "おかやま、かごしま、さいたま、とくしま、ひろしま、ふくしま、まつやま、わかやま、＊＊＊ま",
  ],
  [
    "わかやま",
    "おかやま、かごしま、さいたま、とくしま、ひろしま、ふくしま、まつやま、よこはま、＊＊＊ま",
  ],
  ["うつのみや", "＊＊＊み＊"],
  ["とうきょう", "＊＊＊ょ＊"],
  ["あおもり", "とっとり、＊＊＊り"],
  ["とっとり", "あおもり、＊＊＊り"],
  ["さっぽろ", "＊＊＊ろ"],
  ["かなざわ", "＊＊＊わ"],
  ["ふくい", "＊＊い"],
  ["おおいた", "＊＊い＊"],
  ["きょうと", "＊＊う＊"],
  ["まつえ", "＊＊え"],
  ["しずおか", "ふくおか、もりおか、＊＊お＊"],
  ["ふくおか", "しずおか、もりおか、＊＊お＊"],
  ["もりおか", "しずおか、ふくおか、＊＊お＊"],
  ["にいがた", "やまがた、＊＊が＊"],
  ["やまがた", "にいがた、＊＊が＊"],
  ["とうきょう", "＊＊き＊＊"],
  ["やまぐち", "＊＊ぐ＊"],
  ["おおさか", "ながさき、＊＊さ＊"],
  ["ながさき", "おおさか、＊＊さ＊"],
  ["かなざわ", "みやざき、＊＊ざ＊"],
  ["みやざき", "かなざわ、＊＊ざ＊"],
  ["かごしま", "とくしま、ひろしま、ふくしま、＊＊し＊"],
  ["とくしま", "かごしま、ひろしま、ふくしま、＊＊し＊"],
  ["ひろしま", "かごしま、とくしま、ふくしま、＊＊し＊"],
  ["ふくしま", "かごしま、とくしま、ひろしま、＊＊し＊"],
  ["あきた", "＊＊た"],
  ["さいたま", "＊＊た＊"],
  ["せんだい", "＊＊だ＊"],
  ["こうち", "＊＊ち"],
  ["おおつ", "＊＊つ"],
  ["とっとり", "＊＊と＊"],
  ["ながの", "＊＊の"],
  ["うつのみや", "＊＊の＊＊"],
  ["よこはま", "＊＊は＊"],
  ["まえばし", "＊＊ば＊"],
  ["こうふ", "＊＊ふ"],
  ["こうべ", "＊＊べ"],
  ["さっぽろ", "＊＊ぽ＊"],
  ["とやま", "＊＊ま"],
  ["たかまつ", "＊＊ま＊"],
  ["あおもり", "くまもと、＊＊も＊"],
  ["くまもと", "あおもり、＊＊も＊"],
  ["なごや", "＊＊や"],
  ["おかやま", "まつやま、わかやま、＊＊や＊"],
  ["まつやま", "おかやま、わかやま、＊＊や＊"],
  ["わかやま", "おかやま、まつやま、＊＊や＊"],
  ["さいたま", "にいがた、＊い＊＊"],
  ["にいがた", "さいたま、＊い＊＊"],
  ["こうち", "こうふ、こうべ、＊う＊"],
  ["こうふ", "こうち、こうべ、＊う＊"],
  ["こうべ", "こうち、こうふ、＊う＊"],
  ["とうきょう", "＊う＊＊＊"],
  ["まえばし", "＊え＊＊"],
  ["おおつ", "＊お＊"],
  ["あおもり", "おおいた、おおさか、＊お＊＊"],
  ["おおいた", "あおもり、おおさか、＊お＊＊"],
  ["おおさか", "あおもり、おおいた、＊お＊＊"],
  ["さが", "＊が"],
  ["ながの", "＊が＊"],
  ["おかやま", "たかまつ、わかやま、＊か＊＊"],
  ["たかまつ", "おかやま、わかやま、＊か＊＊"],
  ["わかやま", "おかやま、たかまつ、＊か＊＊"],
  ["ながさき", "＊が＊＊"],
  ["あきた", "＊き＊"],
  ["ふくい", "＊く＊"],
  ["とくしま", "ふくおか、ふくしま、＊く＊＊"],
  ["ふくおか", "とくしま、ふくしま、＊く＊＊"],
  ["ふくしま", "とくしま、ふくおか、＊く＊＊"],
  ["なごや", "＊ご＊"],
  ["よこはま", "＊こ＊＊"],
  ["かごしま", "＊ご＊＊"],
  ["しずおか", "＊ず＊＊"],
  ["まつえ", "＊つ＊"],
  ["さっぽろ", "とっとり、＊っ＊＊"],
  ["とっとり", "さっぽろ、＊っ＊＊"],
  ["まつやま", "＊つ＊＊"],
  ["うつのみや", "＊つ＊＊＊"],
  ["みと", "＊と"],
  ["かなざわ", "＊な＊＊"],
  ["なは", "＊は"],
  ["ちば", "＊ば"],
  ["ぎふ", "＊ふ"],
  ["くまもと", "やまがた、やまぐち、＊ま＊＊"],
  ["やまがた", "くまもと、やまぐち、＊ま＊＊"],
  ["やまぐち", "くまもと、やまがた、＊ま＊＊"],
  ["とやま", "＊や＊"],
  ["みやざき", "＊や＊＊"],
  ["きょうと", "＊ょ＊＊"],
  ["なら", "＊ら"],
  ["もりおか", "＊り＊＊"],
  ["ひろしま", "＊ろ＊＊"],
  ["せんだい", "＊ん＊＊"],
  ["あきた", "あ＊＊"],
  ["あおもり", "あ＊＊＊"],
  ["うつのみや", "う＊＊＊＊"],
  ["おおつ", "お＊＊"],
  ["おおいた", "おおさか、おかやま、お＊＊＊"],
  ["おおさか", "おおいた、おかやま、お＊＊＊"],
  ["おかやま", "おおいた、おおさか、お＊＊＊"],
  ["かごしま", "かなざわ、か＊＊＊"],
  ["かなざわ", "かごしま、か＊＊＊"],
  ["ぎふ", "ぎ＊"],
  ["きょうと", "き＊＊＊"],
  ["くまもと", "く＊＊＊"],
  ["こうち", "こうふ、こうべ、こ＊＊"],
  ["こうふ", "こうち、こうべ、こ＊＊"],
  ["こうべ", "こうち、こうふ、こ＊＊"],
  ["さが", "さ＊"],
  ["さいたま", "さっぽろ、さ＊＊＊"],
  ["さっぽろ", "さいたま、さ＊＊＊"],
  ["しずおか", "し＊＊＊"],
  ["せんだい", "せ＊＊＊"],
  ["たかまつ", "た＊＊＊"],
  ["ちば", "ち＊"],
  ["つ", "＊"],
  ["とやま", "と＊＊"],
  ["とくしま", "とっとり、と＊＊＊"],
  ["とっとり", "とくしま、と＊＊＊"],
  ["とうきょう", "と＊＊＊＊"],
  ["なは", "なら、な＊"],
  ["なら", "なは、な＊"],
  ["ながの", "なごや、な＊＊"],
  ["なごや", "ながの、な＊＊"],
  ["ながさき", "な＊＊＊"],
  ["にいがた", "に＊＊＊"],
  ["ひろしま", "ひ＊＊＊"],
  ["ふくい", "ふ＊＊"],
  ["ふくおか", "ふくしま、ふ＊＊＊"],
  ["ふくしま", "ふくおか、ふ＊＊＊"],
  ["まつえ", "ま＊＊"],
  ["まえばし", "まつやま、ま＊＊＊"],
  ["まつやま", "まえばし、ま＊＊＊"],
  ["みと", "み＊"],
  ["みやざき", "み＊＊＊"],
  ["もりおか", "も＊＊＊"],
  ["やまがた", "やまがた、や＊＊＊"],
  ["やまぐち", "やまぐち、や＊＊＊"],
  ["よこはま", "よ＊＊＊"],
  ["わかやま", "わ＊＊＊"],
];
