import {
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Stack,
  Box,
  Button,
} from "@mui/material";

const componentMap: Record<string, any> = {
  Container,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
};

export const renderComponentFromJson = (
  json: any,
  index: number
): JSX.Element | null => {
  if (!json) return null;

  const { component, props, children, option } = json;
  const Component = componentMap[component];
  if (!Component) return null;

  if (component === "Typography") {
    const { variant, content } = props;
    return (
      <Typography
        variant={variant}
        key={index}
        sx={{
          overflow: "wrap",
          wordBreak: "break-all",
          maxWidth: "100%",
        }}
        component="p"
      >
        {content}
      </Typography>
    );
  }

  let modifiedChildren = children;
  if (Array.isArray(children)) {
    modifiedChildren = children.map(renderComponentFromJson);
  } else if (typeof children === "object") {
    modifiedChildren = renderComponentFromJson(children, index);
  } else if (typeof children === "string") {
    modifiedChildren = children;
  }

  if (component === "Card") {
    return (
      <Component sx={props} key={index}>
        {modifiedChildren}
      </Component>
    );
  }
  if (component === "Button") {
    return (
      <Component
        {...props}
        onClick={() => {
          window.open(option.href, "_blank");
        }}
        sx={{ mt: 1 }}
        key={index}
      >
        {modifiedChildren}
      </Component>
    );
  }

  return (
    <Component {...props} key={index}>
      {modifiedChildren}
    </Component>
  );
};
