import { splitGrapheme } from "./splitGrapheme";
const alphabetToBrailleTable = {
    a: "⠁",
    b: "⠃",
    c: "⠉",
    d: "⠙",
    e: "⠑",
    f: "⠋",
    g: "⠛",
    h: "⠓",
    i: "⠊",
    j: "⠚",
    k: "⠅",
    l: "⠇",
    m: "⠍",
    n: "⠝",
    o: "⠕",
    p: "⠏",
    q: "⠟",
    r: "⠗",
    s: "⠎",
    t: "⠞",
    u: "⠥",
    v: "⠧",
    w: "⠺",
    x: "⠭",
    y: "⠽",
    z: "⠵",
    A: "⠠⠁",
    B: "⠠⠃",
    C: "⠠⠉",
    D: "⠠⠙",
    E: "⠠⠑",
    F: "⠠⠋",
    G: "⠠⠛",
    H: "⠠⠓",
    I: "⠠⠊",
    J: "⠠⠚",
    K: "⠠⠅",
    L: "⠠⠇",
    M: "⠠⠍",
    N: "⠠⠝",
    O: "⠠⠕",
    P: "⠠⠏",
    Q: "⠠⠟",
    R: "⠠⠗",
    S: "⠠⠎",
    T: "⠠⠞",
    U: "⠠⠥",
    V: "⠠⠧",
    W: "⠠⠺",
    X: "⠠⠭",
    Y: "⠠⠽",
    Z: "⠠⠵",
    "0": "⠼⠚",
    "1": "⠼⠁",
    "2": "⠼⠃",
    "3": "⠼⠉",
    "4": "⠼⠙",
    "5": "⠼⠑",
    "6": "⠼⠋",
    "7": "⠼⠛",
    "8": "⠼⠓",
    "9": "⠼⠊",
    ".": "⠲",
    ",": "⠂",
    "?": "⠦",
    "!": "⠖",
    ";": "⠆",
    ":": "⠒",
    "-": "⠤",
    "’": "⠄",
    "“": "⠘⠦",
    "”": "⠘⠴",
    "(": "⠐⠣",
    ")": "⠐⠜",
};
const hiraganaToBrailleTable = {
    あ: "⠁",
    い: "⠃",
    う: "⠉",
    え: "⠋",
    お: "⠊",
    か: "⠡",
    き: "⠣",
    く: "⠩",
    け: "⠫",
    こ: "⠪",
    さ: "⠱",
    し: "⠳",
    す: "⠹",
    せ: "⠻",
    そ: "⠺",
    た: "⠕",
    ち: "⠗",
    つ: "⠝",
    て: "⠟",
    と: "⠞",
    な: "⠅",
    に: "⠇",
    ぬ: "⠍",
    ね: "⠏",
    の: "⠎",
    は: "⠥",
    ひ: "⠧",
    ふ: "⠭",
    へ: "⠯",
    ほ: "⠮",
    ま: "⠵",
    み: "⠷",
    む: "⠽",
    め: "⠿",
    も: "⠾",
    や: "⠌",
    ゆ: "⠬",
    よ: "⠜",
    ら: "⠑",
    り: "⠓",
    る: "⠙",
    れ: "⠛",
    ろ: "⠚",
    わ: "⠄",
    を: "⠆",
    ん: "⠴",
    が: "⠐⠡",
    ぎ: "⠐⠣",
    ぐ: "⠐⠩",
    げ: "⠐⠫",
    ご: "⠐⠪",
    ざ: "⠐⠱",
    じ: "⠐⠳",
    ず: "⠐⠹",
    ぜ: "⠐⠻",
    ぞ: "⠐⠺",
    だ: "⠐⠕",
    ぢ: "⠐⠗",
    づ: "⠐⠝",
    で: "⠐⠟",
    ど: "⠐⠞",
    ば: "⠐⠥",
    び: "⠐⠧",
    ぶ: "⠐⠭",
    べ: "⠐⠯",
    ぼ: "⠐⠮",
    ぱ: "⠘⠥",
    ぴ: "⠘⠧",
    ぷ: "⠘⠭",
    ぺ: "⠘⠯",
    ぽ: "⠘⠮",
    ぁ: "⠈⠁",
    ぃ: "⠈⠃",
    ぅ: "⠈⠉",
    ぇ: "⠈⠋",
    ぉ: "⠈⠊",
    っ: "⠈⠝",
    ゃ: "⠈⠌",
    ゅ: "⠈⠬",
    ょ: "⠈⠜",
    ー: "⠤",
    "、": "⠂",
    "。": "⠲",
};
const brailleToAlphabetTable = {
    "⠁": "a",
    "⠃": "b",
    "⠉": "c",
    "⠙": "d",
    "⠑": "e",
    "⠋": "f",
    "⠛": "g",
    "⠓": "h",
    "⠊": "i",
    "⠚": "j",
    "⠅": "k",
    "⠇": "l",
    "⠍": "m",
    "⠝": "n",
    "⠕": "o",
    "⠏": "p",
    "⠟": "q",
    "⠗": "r",
    "⠎": "s",
    "⠞": "t",
    "⠥": "u",
    "⠧": "v",
    "⠺": "w",
    "⠭": "x",
    "⠽": "y",
    "⠵": "z",
    "⠠⠁": "A",
    "⠠⠃": "B",
    "⠠⠉": "C",
    "⠠⠙": "D",
    "⠠⠑": "E",
    "⠠⠋": "F",
    "⠠⠛": "G",
    "⠠⠓": "H",
    "⠠⠊": "I",
    "⠠⠚": "J",
    "⠠⠅": "K",
    "⠠⠇": "L",
    "⠠⠍": "M",
    "⠠⠝": "N",
    "⠠⠕": "O",
    "⠠⠏": "P",
    "⠠⠟": "Q",
    "⠠⠗": "R",
    "⠠⠎": "S",
    "⠠⠞": "T",
    "⠠⠥": "U",
    "⠠⠧": "V",
    "⠠⠺": "W",
    "⠠⠭": "X",
    "⠠⠽": "Y",
    "⠠⠵": "Z",
    "⠼⠚": "0",
    "⠼⠁": "1",
    "⠼⠃": "2",
    "⠼⠉": "3",
    "⠼⠙": "4",
    "⠼⠑": "5",
    "⠼⠋": "6",
    "⠼⠛": "7",
    "⠼⠓": "8",
    "⠼⠊": "9",
    "⠲": ".",
    "⠂": ",",
    "⠦": "?",
    "⠖": "!",
    "⠆": ";",
    "⠒": ":",
    "⠤": "-",
    "⠄": "’",
    "⠘⠦": "“",
    "⠘⠴": "”",
    "⠐⠣": "(",
    "⠐⠜": ")",
};
const brailleToHiraganaTable = {
    "⠁": "あ",
    "⠃": "い",
    "⠉": "う",
    "⠋": "え",
    "⠊": "お",
    "⠡": "か",
    "⠣": "き",
    "⠩": "く",
    "⠫": "け",
    "⠪": "こ",
    "⠱": "さ",
    "⠳": "し",
    "⠹": "す",
    "⠻": "せ",
    "⠺": "そ",
    "⠕": "た",
    "⠗": "ち",
    "⠝": "つ",
    "⠟": "て",
    "⠞": "と",
    "⠅": "な",
    "⠇": "に",
    "⠍": "ぬ",
    "⠏": "ね",
    "⠎": "の",
    "⠥": "は",
    "⠧": "ひ",
    "⠭": "ふ",
    "⠯": "へ",
    "⠮": "ほ",
    "⠵": "ま",
    "⠷": "み",
    "⠽": "む",
    "⠿": "め",
    "⠾": "も",
    "⠌": "や",
    "⠬": "ゆ",
    "⠜": "よ",
    "⠑": "ら",
    "⠓": "り",
    "⠙": "る",
    "⠛": "れ",
    "⠚": "ろ",
    "⠄": "わ",
    "⠆": "を",
    "⠴": "ん",
    "⠐⠡": "が",
    "⠐⠣": "ぎ",
    "⠐⠩": "ぐ",
    "⠐⠫": "げ",
    "⠐⠪": "ご",
    "⠐⠱": "ざ",
    "⠐⠳": "じ",
    "⠐⠹": "ず",
    "⠐⠻": "ぜ",
    "⠐⠺": "ぞ",
    "⠐⠕": "だ",
    "⠐⠗": "ぢ",
    "⠐⠝": "づ",
    "⠐⠟": "で",
    "⠐⠞": "ど",
    "⠐⠥": "ば",
    "⠐⠧": "び",
    "⠐⠭": "ぶ",
    "⠐⠯": "べ",
    "⠐⠮": "ぼ",
    "⠘⠥": "ぱ",
    "⠘⠧": "ぴ",
    "⠘⠭": "ぷ",
    "⠘⠯": "ぺ",
    "⠘⠮": "ぽ",
    "⠈⠁": "ぁ",
    "⠈⠃": "ぃ",
    "⠈⠉": "ぅ",
    "⠈⠋": "ぇ",
    "⠈⠊": "ぉ",
    "⠈⠝": "っ",
    "⠈⠌": "ゃ",
    "⠈⠬": "ゅ",
    "⠈⠜": "ょ",
    "⠤": "ー",
    "⠂": "、",
    "⠲": "。",
};
function convertToBraille(input, conversionTable) {
    return splitGrapheme(input)
        .map((char) => conversionTable[char] || char)
        .join("");
}
function convertFromBraille(input, conversionTable) {
    const braillePattern = new RegExp(Object.keys(conversionTable).join("|"), "g");
    return input.replace(braillePattern, (matched) => conversionTable[matched]);
}
export const brailleToAlphabet = (text) => {
    return convertFromBraille(text, brailleToAlphabetTable);
};
export const brailleToKana = (text) => {
    return convertFromBraille(text, brailleToHiraganaTable);
};
export const toBraille = (text) => {
    return convertToBraille(convertToBraille(text, alphabetToBrailleTable), hiraganaToBrailleTable);
};
