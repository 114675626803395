import {
  splitGrapheme,
  symbolRegexPatternReplaceTable,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useCallback, useRef, useState } from "react";
import { BsRegex } from "react-icons/bs";
import { useStatePersist } from "use-state-persist";
import WidthFullIcon from "@mui/icons-material/WidthFull";
import WidthNormalIcon from "@mui/icons-material/WidthNormal";
import { width } from "@mui/system";

type RegexInputWithCompletionProps = {
  pattern: string;
  onPatternChange: (newPattern: string) => void;
  disabled?: boolean;
  onBlur?: () => void;
  children?: React.ReactNode;
};

export const RegexInputWithCompletion: FC<RegexInputWithCompletionProps> = ({
  pattern,
  onPatternChange,
  disabled,
  onBlur,
  children,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const textRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const [replaceFullsizeSymbolToHalfsize] = useStatePersist(
    "general-search-replace-fullsize-symbol-to-halfsize",
    false
  );

  const [enableCompletion] = useStatePersist(
    "regex-input-enable-completion",
    true
  );

  const [displayWidthFull, setDisplayWidthFull] = useState<boolean>(false);
  const middleSize = useMediaQuery("(min-width:600px)");

  const handleInsert = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      symbol: string,
      cursor?: number
    ) => {
      event.preventDefault(); // フォーカスの喪失を防ぐ
      const _pattern = pattern || "";

      const start = textRef.current?.selectionStart || 0;
      const end = textRef.current?.selectionEnd || 0;
      const newText = _pattern.slice(0, start) + symbol + _pattern.slice(end);

      onPatternChange(newText);
      setTimeout(() => {
        textRef.current?.focus();
        textRef.current?.setSelectionRange(
          start + symbol.length + (cursor ?? 0),
          start + symbol.length + (cursor ?? 0)
        );
      }, 0);
    },
    [pattern, onPatternChange]
  );

  const handleCursor = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cursor: number
  ) => {
    event.preventDefault(); // フォーカスの喪失を防ぐ
    setTimeout(() => {
      textRef.current?.focus();
      textRef.current?.setSelectionRange(cursor, cursor);
    }, 0);
  };

  const [displaySingleSymbol, setDisplaySingleSymbol] =
    useStatePersist<boolean>(
      "general-search-form-display-single-symbol",
      false
    );
  const [displayNumber, setDisplayNumber] = useStatePersist<boolean>(
    "general-search-form-display-number",
    false
  );
  const [displayBrackets, setDisplayBrackets] = useStatePersist<boolean>(
    "general-search-form-display-brackets",
    false
  );

  return (
    <Box
      sx={{
        display: "inline-block",
        verticalAlign: "top",
        minWidth: 300,
        width: displayWidthFull ? "100%" : "auto",
        mr: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          mb: 2,
          width: "100%",
        }}
      >
        <TextField
          label="正規表現パターン"
          variant="outlined"
          multiline
          value={pattern}
          onChange={(e) => {
            onPatternChange(e.target.value.replace(/…/g, "..."));
          }}
          sx={{
            ml: 1,
            mr: 1,
            width: "100%",
          }}
          onCompositionEnd={(e) => {
            if (replaceFullsizeSymbolToHalfsize) {
              const newPattern = splitGrapheme(pattern)
                .map((c: string) =>
                  symbolRegexPatternReplaceTable[c]
                    ? symbolRegexPatternReplaceTable[c]
                    : c
                )
                .join("");
              onPatternChange(newPattern);
            }
          }}
          fullWidth
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputRef={textRef}
          disabled={disabled}
          InputProps={{
            endAdornment: middleSize ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle regex input"
                  color={"default"}
                  onClick={() => {
                    setDisplayWidthFull(!displayWidthFull);
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {displayWidthFull ? <WidthNormalIcon /> : <WidthFullIcon />}
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
        />
        {enableCompletion && isFocused && !disabled && (
          <>
            <ButtonGroup size="small">
              <Button
                variant={displaySingleSymbol ? "contained" : "outlined"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setDisplaySingleSymbol(!displaySingleSymbol);
                }}
                size="small"
              >
                記
              </Button>
              <Button
                variant={displayNumber ? "contained" : "outlined"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setDisplayNumber(!displayNumber);
                }}
                size="small"
              >
                数
              </Button>
              <Button
                variant={displayBrackets ? "contained" : "outlined"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setDisplayBrackets(!displayBrackets);
                }}
                size="small"
              >
                括
              </Button>
              <Button
                variant={"contained"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleCursor(e, 0);
                }}
                size="small"
              >
                ≪
              </Button>
              <Button
                variant={"contained"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleInsert(e, "", -1);
                }}
                size="small"
              >
                ＜
              </Button>
              <Button
                variant={"contained"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleInsert(e, "", 1);
                }}
                size="small"
              >
                ＞
              </Button>
              <Button
                variant={"contained"}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleCursor(e, 10000000);
                }}
                size="small"
              >
                ≫
              </Button>
            </ButtonGroup>
            <ButtonGroup size="small">
              {displaySingleSymbol &&
                [".", "|", "*", "+", "?", "^", "$", "\\"].map((symbol) => (
                  <Button
                    variant="outlined"
                    onMouseDown={(e) => handleInsert(e, symbol)}
                    size="small"
                    disableFocusRipple={true}
                    disableRipple={true}
                    disableTouchRipple={true}
                    focusRipple={false}
                  >
                    {symbol}
                  </Button>
                ))}
            </ButtonGroup>
            <ButtonGroup size="small">
              {displayNumber &&
                ["1", "2", "3", "4", "5"].map((symbol) => (
                  <Button
                    variant="outlined"
                    onMouseDown={(e) => handleInsert(e, symbol)}
                    size="small"
                  >
                    {symbol}
                  </Button>
                ))}
            </ButtonGroup>
            <ButtonGroup size="small">
              {displayNumber &&
                ["6", "7", "8", "9", "0"].map((symbol) => (
                  <Button
                    variant="outlined"
                    onMouseDown={(e) => handleInsert(e, symbol)}
                    size="small"
                  >
                    {symbol}
                  </Button>
                ))}
            </ButtonGroup>
            <ButtonGroup size="small">
              {displayBrackets &&
                [
                  { symbol: "()", cursor: -1 },
                  { symbol: "(?=)", cursor: -1 },
                  { symbol: "(?!)", cursor: -1 },
                  { symbol: "{}", cursor: -1 },
                  { symbol: "{,}", cursor: -2 },
                  { symbol: "[]", cursor: -1 },
                  { symbol: "[^-]", cursor: -2 },
                ].map((elem) => (
                  <Button
                    variant="outlined"
                    onMouseDown={(e) =>
                      handleInsert(e, elem.symbol, elem.cursor)
                    }
                    size="small"
                    sx={{ whiteSpace: "nowrap", px: 0 }}
                  >
                    {elem.symbol}
                  </Button>
                ))}
            </ButtonGroup>
          </>
        )}
        {children}
      </Box>
    </Box>
  );
};
