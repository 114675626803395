"use client";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Paper, Tab, Tabs, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useImaginateConfig } from '../../imaginate/ImaginateProvider';
import { useSiteConfig } from "../../EniverseProvider";
import { CommonAppProp } from "../../imaginate/mainView/CommponAppProp";
import { useDoc } from "../../storekit/firestore/pure/useDoc";
import { useMultipleDoc } from "../../storekit/firestore/pure/useMultipleDoc";
import { ProblemData } from "./PuzsqContestProblemItem";
import { GeneralArrayForm, GeneralForm } from "./lib/GeneralFormBuilder";

type ContestData = {
  title?: string;
  description?: string;
  problem?: { problemId: string }[];
};

const initialData: ContestData = {
  title: "",
  description: "",
  problem: [],
};

export const PuzsqContestManagerView: FC<CommonAppProp> = (props) => {
  const { currentNode, updateNode } = props;

  const [contents, _setContents] = useState<ContestData>(initialData);

  const [{ serviceId }] = useSiteConfig();
  const [{projectId}] = useImaginateConfig();
  const path = `/workspace/${serviceId}/project/${projectId}/contents/${currentNode.data?.contentsId}`;
  const [serverContents, setServerContents] = useDoc<ContestData>({
    path,
  });
  const pathList = useMemo(
    () =>
      contents.problem?.map(
        (problem) =>
          `/workspace/${serviceId}/project/${projectId}/contents/${problem.problemId}`
      ) ?? [],
    [contents]
  );
  const [puzzleDataList] = useMultipleDoc<ProblemData>({ pathList });

  console.log(path, serverContents, "serverContents");
  console.log(puzzleDataList, "puzzleDataList");

  useEffect(() => {
    if (serverContents) {
      _setContents({ ...initialData, ...serverContents });
    }
  }, [serverContents]);

  const setContents = (newContents: Partial<ContestData>) => {
    _setContents({ ...contents, ...newContents });
    console.log({ ...contents, ...newContents }, "newContents");
  };
  const saveServerContents = () => {
    setServerContents(contents);
  };

  const [tabValue, setTabValue] = useState("setting");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <TabContext value={tabValue}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="setting" value="setting" />
          <Tab label="view" value="view" />
        </Tabs>
        <TabPanel value="setting">
          <GeneralForm
            type="string"
            content={contents.title}
            setContent={(content) => {
              setContents({ title: content });
            }}
            label="title"
          />
          <GeneralForm
            type="string"
            content={contents.description}
            setContent={(content) => {
              setContents({ description: content });
            }}
            label="description"
          />
          <Typography sx={{ my: 1 }} variant="h5">
            problemId
          </Typography>
          <GeneralArrayForm
            type="array"
            content={contents.problem}
            setContent={(content) => {
              setContents({ problem: content });
            }}
            label="problemIds"
            defaultValue={{ problemId: "" }}
            typeData={[
              {
                name: "problemId",
                type: "string",
              },
            ]}
          />
          <Button onClick={saveServerContents} variant="contained">
            save
          </Button>
        </TabPanel>
        <TabPanel value="view">
          <Paper sx={{ p: 2, maxWidth: "md", mx: "auto" }}>
            <Typography variant="h5">{contents.title}</Typography>
            <Typography variant="h6">{contents.description}</Typography>
            {pathList.map((path, index) => {
              return (
                <Box key={path}>
                  <Typography>
                    {index + 1}
                    {puzzleDataList[path]?.data?.kind ?? ""}
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      src={puzzleDataList[path]?.data?.image ?? ""}
                      alt="puzzle"
                      height={200}
                    />
                  </Box>
                </Box>
              );
            })}
          </Paper>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
