import { splitGrapheme } from "./splitGrapheme";
const morseCode = {
    a: "・－",
    b: "－・・・",
    c: "－・－・",
    d: "－・・",
    e: "・",
    f: "・・－・",
    g: "－－・",
    h: "・・・・",
    i: "・・",
    j: "・－－－",
    k: "－・－",
    l: "・－・・",
    m: "－－",
    n: "－・",
    o: "－－－",
    p: "・－－・",
    q: "－－・－",
    r: "・－・",
    s: "・・・",
    t: "－",
    u: "・・－",
    v: "・・・－",
    w: "・－－",
    x: "－・・－",
    y: "－・－－",
    z: "－－・・",
    0: "－－－－－",
    1: "・－－－－",
    2: "・・－－－",
    3: "・・・－－",
    4: "・・・・－",
    5: "・・・・・",
    6: "－・・・・",
    7: "－－・・・",
    8: "－－－・・",
    9: "－－－－・",
    "・": "・－・－・－",
    ",": "－－・・－－",
    "?": "・・－－・・",
    "'": "・－－－－・",
    "!": "－・－・－－",
    "/": "－・・－・",
    "(": "－・－－・",
    ")": "－・－－・－",
    "&": "・－・・・",
    ":": "－－－・・・",
    ";": "－・－・－・",
    "=": "－・・・－",
    "+": "・－・－・",
    "－": "－・・・・－",
    _: "・・－－・－",
    '"': "・－・・－・",
    $: "・・・－・・－",
    "@": "・－－・－・",
    あ: "－－・－－",
    い: "・－",
    う: "・・－",
    え: "－・－－－",
    お: "・－・・・",
    か: "・－・・",
    き: "－・－・・",
    く: "・・・－",
    け: "－・－－",
    こ: "－－－－",
    さ: "－・－・－",
    し: "－－・－・",
    す: "－－－・－",
    せ: "・－－－・",
    そ: "－－－・",
    た: "－・",
    ち: "・・－・",
    つ: "・－－・",
    て: "・－・－－",
    と: "・・－・・",
    な: "・－・",
    に: "－・－・",
    ぬ: "・・・・",
    ね: "－－・－",
    の: "・・－－",
    は: "－・・・",
    ひ: "－－・・－",
    ふ: "－－・・",
    へ: "・",
    ほ: "－・・",
    ま: "－・・－",
    み: "・・－・－",
    む: "－",
    め: "－・・・－",
    も: "－・・－・",
    や: "・－－",
    ゆ: "－・・－－",
    よ: "－－",
    ら: "・・・",
    り: "－－・",
    る: "－・－－・",
    れ: "－－－",
    ろ: "・－・－",
    わ: "－・－",
    を: "・－－－",
    ん: "・－・－・",
    が: "・－・・ ・・",
    ぎ: "－・－・・ ・・",
    ぐ: "・・・－ ・・",
    げ: "－・－－ ・・",
    ご: "－－－－ ・・",
    ざ: "－・－・－ ・・",
    じ: "－－・－・ ・・",
    ず: "－－－・－ ・・",
    ぜ: "・－－－・ ・・",
    ぞ: "－－－・ ・・",
    だ: "－・ ・・",
    ぢ: "・・－・ ・・",
    づ: "・－－・ ・・",
    で: "・－・－－ ・・",
    ど: "・・－・・ ・・",
    ば: "－・・・ ・・",
    び: "－－・・－ ・・",
    ぶ: "－－・・ ・・",
    べ: "・ ・・",
    ぼ: "－・・ ・・",
    ぱ: "－・・・ ・・－－・",
    ぴ: "－－・・－ ・・－－・",
    ぷ: "－－・・ ・・－－・",
    ぺ: "・ ・・－－・",
    ぽ: "－・・ ・・－－・",
    ぁ: "－－・－－",
    ぃ: "・－",
    ぅ: "・・－",
    ぇ: "－・－－－",
    ぉ: "・－・・・",
    ゃ: "・－－",
    ゅ: "－・・－－",
    ょ: "－－",
    っ: "・－－・",
    ー: "・－－・－",
    "、": "・－・－・－",
};
const morseToHiraganaTable = {
    "－－・－－": "あ",
    "・－": "い",
    "・・－": "う",
    "－・－－－": "え",
    "・－・・・": "お",
    "・－・・": "か",
    "－・－・・": "き",
    "・・・－": "く",
    "－・－－": "け",
    "－－－－": "こ",
    "－・－・－": "さ",
    "－－・－・": "し",
    "－－－・－": "す",
    "・－－－・": "せ",
    "－－－・": "そ",
    "－・": "た",
    "・・－・": "ち",
    "・－－・": "つ",
    "・－・－－": "て",
    "・・－・・": "と",
    "・－・": "な",
    "－・－・": "に",
    "・・・・": "ぬ",
    "－－・－": "ね",
    "・・－－": "の",
    "－・・・": "は",
    "－－・・－": "ひ",
    "－－・・": "ふ",
    "・": "へ",
    "－・・": "ほ",
    "－・・－": "ま",
    "・・－・－": "み",
    "－": "む",
    "－・・・－": "め",
    "－・・－・": "も",
    "・－－": "や",
    "－・・－－": "ゆ",
    "－－": "よ",
    "・・・": "ら",
    "－－・": "り",
    "－・－－・": "る",
    "－－－": "れ",
    "・－・－": "ろ",
    "－・－": "わ",
    "・－－－": "を",
    "・－・－・": "ん",
    "・・": "゛",
    "・・－－・": "゜",
    "・－－・－": "ー",
    "・－・－・－": "、",
    "－－－－－": "0",
    "・－－－－": "1",
    "・・－－－": "2",
    "・・・－－": "3",
    "・・・・－": "4",
    "・・・・・": "5",
    "－・・・・": "6",
    "－－・・・": "7",
    "－－－・・": "8",
    "－－－－・": "9",
};
const morseToAlphabetTable = {
    "・－": "a",
    "－・・・": "b",
    "－・－・": "c",
    "－・・": "d",
    "・": "e",
    "・・－・": "f",
    "－－・": "g",
    "・・・・": "h",
    "・・": "i",
    "・－－－": "j",
    "－・－": "k",
    "・－・・": "l",
    "－－": "m",
    "－・": "n",
    "－－－": "o",
    "・－－・": "p",
    "－－・－": "q",
    "・－・": "r",
    "・・・": "s",
    "－": "t",
    "・・－": "u",
    "・・・－": "v",
    "・－－": "w",
    "－・・－": "x",
    "－・－－": "y",
    "－－・・": "z",
    "－－－－－": "0",
    "・－－－－": "1",
    "・・－－－": "2",
    "・・・－－": "3",
    "・・・・－": "4",
    "・・・・・": "5",
    "－・・・・": "6",
    "－－・・・": "7",
    "－－－・・": "8",
    "－－－－・": "9",
    "・－・－・－": ".",
    "－－・・－－": ",",
    "・・－－・・": "?",
    "・－－－－・": "'",
    "－・－・－－": "!",
    "－・・－・": "/",
    "－・－－・": "(",
    "－・－－・－": ")",
    "・－・・・": "&",
    "－・－・－・": ";",
    "－・・・－": "=",
    "・－・－・": "+",
    "－・・・・－": "_",
    "・・－－・－": '"',
    "・・・－・・－": "$",
    "・－－・－・": "@",
};
const dakuToHiraganaTable = {
    "か゛": "が",
    "き゛": "ぎ",
    "く゛": "ぐ",
    "け゛": "げ",
    "こ゛": "ご",
    "さ゛": "ざ",
    "し゛": "じ",
    "す゛": "ず",
    "せ゛": "ぜ",
    "そ゛": "ぞ",
    "た゛": "だ",
    "ち゛": "ぢ",
    "つ゛": "づ",
    "て゛": "で",
    "と゛": "ど",
    "は゛": "ば",
    "ひ゛": "び",
    "ふ゛": "ぶ",
    "へ゛": "べ",
    "ほ゛": "ぼ",
    "は゜": "ぱ",
    "ひ゜": "ぴ",
    "ふ゜": "ぷ",
    "へ゜": "ぺ",
    "ほ゜": "ぽ",
};
export const hiraganaToMorse = (text) => {
    const morseText = text
        .split("\n")
        .map((line) => splitGrapheme(line.trim())
        .map((char) => morseCode[char.toLowerCase()])
        .join(" "))
        .join("\n");
    return morseText;
};
export const morseToHiragana = (text) => {
    // japanese and engilish
    const morseText = text
        .split("\n")
        .map((line) => line
        .trim()
        .split(" ")
        .map((char) => morseToHiraganaTable[char])
        .join("")
        .replace(/(.)(゛|゜)/g, (_, p1, p2) => dakuToHiraganaTable[p1 + p2]))
        .join("\n");
    return morseText;
};
export const morseToAlphabet = (text) => {
    const morseText = text
        .split("\n")
        .map((line) => line
        .trim()
        .split(" ")
        .map((char) => morseToAlphabetTable[char])
        .join(""))
        .join("\n");
    return morseText;
};
