import {
  DictionaryKey,
  dictionaries,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  selectClasses,
} from "@mui/material";
import { FC, useContext } from "react";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type ColumnSelectorProps = {
  labelName: string;
  selected: number[];
  column: string[];
  setSelected: (value: number[]) => void;
  isDemo: boolean;
  onBlur?: () => void;
};

export const ColumnSelector: FC<ColumnSelectorProps> = ({
  labelName,
  isDemo,
  onBlur,
  selected,
  column,
  setSelected,
}) => {
  return (
    <FormControl variant="outlined">
      <InputLabel id="select-multi-column">{labelName}</InputLabel>
      <Select
        labelId="select-multi-column"
        multiple={true}
        value={selected ?? []}
        onChange={(e) =>
          setSelected(
            typeof e.target.value === "string"
              ? e.target.value.split(",").map((e) => parseInt(e))
              : e.target.value
          )
        }
        onBlur={onBlur}
        sx={{
          ml: 1,
          width: "200px",
        }}
        disabled={isDemo}
        id="multiple-chip"
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={column[value]} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {column.map((e, i) => (
          <MenuItem value={i} key={e}>
            {e}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
