import { convertFlat, estimateFilter, filter } from "../commonLoop";
import { createDictionaryTable, createSortedTable, } from "../table/dictionaryTable";
export const dictionaryMatchFilter = async ({ query, input, result, workerContext, }) => {
    if (!query.anagramMatch) {
        const dictKey = query.dictionary ||
            (input.inputDictionary !== "free" ? input.inputDictionary : "");
        if (!dictKey) {
            return [];
        }
        const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
        const dict = createDictionaryTable(dictKeyArr);
        if (!dict) {
            return [];
        }
        const filterFunc = (e) => {
            return ((query.notMatch && !dict[e.currentWord]) ||
                (!query.notMatch && !!dict[e.currentWord]));
        };
        if (input.estimation) {
            return estimateFilter(workerContext, result, filterFunc, input.estimation, query);
        }
        return await filter(workerContext, result, filterFunc);
    }
    else {
        // anagram match
        // アナグラムして辞書にある単語に変換します。
        const dictKey = query.dictionary ||
            (input.inputDictionary !== "free" ? input.inputDictionary : "");
        if (!dictKey) {
            return [];
        }
        const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
        const dict = createSortedTable(dictKeyArr);
        if (!dict) {
            return [];
        }
        return await convertFlat(workerContext, result, (e) => {
            const key = e.currentWord.split("").sort().join("");
            if (!query.notMatch) {
                const ret = (dict[key] ?? []).map((e2) => {
                    const newe = JSON.parse(JSON.stringify(e));
                    newe.currentWord = e2;
                    return newe;
                });
                return ret;
            }
            else {
                return dict[key]?.length ? [] : [e];
            }
        });
    }
};
