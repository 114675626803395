export const pokemon1 = `フシギダネ
フシギソウ
フシギバナ
ヒトカゲ
リザード
リザードン
ゼニガメ
カメール
カメックス
キャタピー
トランセル
バタフリー
ビードル
コクーン
スピアー
ポッポ
ピジョン
ピジョット
コラッタ
ラッタ
オニスズメ
オニドリル
アーボ
アーボック
ピカチュウ
ライチュウ
サンド
サンドパン
ニドラン♀
ニドリーナ
ニドクイン
ニドラン♂
ニドリーノ
ニドキング
ピッピ
ピクシー
ロコン
キュウコン
プリン
プクリン
ズバット
ゴルバット
ナゾノクサ
クサイハナ
ラフレシア
パラス
パラセクト
コンパン
モルフォン
ディグダ
ダグトリオ
ニャース
ペルシアン
コダック
ゴルダック
マンキー
オコリザル
ガーディ
ウインディ
ニョロモ
ニョロゾ
ニョロボン
ケーシィ
ユンゲラー
フーディン
ワンリキー
ゴーリキー
カイリキー
マダツボミ
ウツドン
ウツボット
メノクラゲ
ドククラゲ
イシツブテ
ゴローン
ゴローニャ
ポニータ
ギャロップ
ヤドン
ヤドラン
コイル
レアコイル
カモネギ
ドードー
ドードリオ
パウワウ
ジュゴン
ベトベター
ベトベトン
シェルダー
パルシェン
ゴース
ゴースト
ゲンガー
イワーク
スリープ
スリーパー
クラブ
キングラー
ビリリダマ
マルマイン
タマタマ
ナッシー
カラカラ
ガラガラ
サワムラー
エビワラー
ベロリンガ
ドガース
マタドガス
サイホーン
サイドン
ラッキー
モンジャラ
ガルーラ
タッツー
シードラ
トサキント
アズマオウ
ヒトデマン
スターミー
バリヤード
ストライク
ルージュラ
エレブー
ブーバー
カイロス
ケンタロス
コイキング
ギャラドス
ラプラス
メタモン
イーブイ
シャワーズ
サンダース
ブースター
ポリゴン
オムナイト
オムスター
カブト
カブトプス
プテラ
カビゴン
フリーザー
サンダー
ファイヤー
ミニリュウ
ハクリュー
カイリュー
ミュウツー
ミュウ`;
