import { RectPos, WordObj } from "../gridToolType";

export const useWordListToGrid = ({ artboard }: { artboard: RectPos }) => {
  /**
   *
   * @param wordList
   * @returns
   */
  const wordListToGrid = (wordList: WordObj[]) => {
    const grid: string[][] = [];
    for (let i = 0; i < artboard.height; i++) {
      grid.push([]);
      for (let j = 0; j < artboard.width; j++) {
        grid[i].push("");
      }
    }
    for (const wordObj of wordList) {
      const { word, x, y, direction } = wordObj;
      for (let i = 0; i < word.length; i++) {
        let pos = { x: 0, y: 0 };
        if (direction === "right") {
          pos = { x: x + i, y: y };
        } else if (direction === "right-down") {
          pos = { x: x + i, y: y + i };
        } else if (direction === "down") {
          pos = { x: x, y: y + i };
        } else if (direction === "left-down") {
          pos = { x: x - i, y: y + i };
        } else if (direction === "left") {
          pos = { x: x - i, y: y };
        } else if (direction === "left-up") {
          pos = { x: x - i, y: y - i };
        } else if (direction === "up") {
          pos = { x: x, y: y - i };
        } else if (direction === "right-up") {
          pos = { x: x + i, y: y - i };
        } else {
          pos = { x: x + i, y: y };
        }

        pos.x -= artboard.x;
        pos.y -= artboard.y;
        if (
          pos.x < 0 ||
          pos.x >= artboard.width ||
          pos.y < 0 ||
          pos.y >= artboard.height
        ) {
          continue;
        }

        grid[pos.y][pos.x] += word[i];
      }
    }
    for (let i = 0; i < artboard.height - artboard.y; i++) {
      for (let j = 0; j < artboard.width - artboard.x; j++) {
        grid[i][j] = [...new Set(grid[i][j])].sort().join("");
      }
    }
    return grid;
  };

  return { wordListToGrid };
};
