import {
  Excalidraw,
  MainMenu,
  WelcomeScreen
} from "@excalidraw/excalidraw";
import {
  ExcalidrawImperativeAPI,
  ExcalidrawInitialDataState
} from "@excalidraw/excalidraw/types/types";
import { ResolvablePromise } from "@excalidraw/excalidraw/types/utils";
import { FC, useRef, useState } from "react";

import { CommonAppProp } from "./CommponAppProp";
import { resolvablePromise } from "./utils";

export const ExcalidrawView: FC<CommonAppProp> = (props) => {
  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null);
  const [viewModeEnabled, setViewModeEnabled] = useState(false);
  const [gridModeEnabled, setGridModeEnabled] = useState(false);
  const [exportWithDarkMode, setExportWithDarkMode] = useState<boolean>(false);
  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ExcalidrawInitialDataState | null>;
  }>({ promise: null! });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }
  const [theme, setTheme] = useState<"light" | "dark">("light");

  console.log("ExcalidrawView props", props);

  return (
    <div>
      <Excalidraw
        excalidrawAPI={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
        initialData={initialStatePromiseRef.current.promise}
        viewModeEnabled={viewModeEnabled}
        gridModeEnabled={gridModeEnabled}
        theme={theme}
      >
        <MainMenu>
          {/*
          <MainMenu.Item
            onSelect={async () => {
              const path = await save({ defaultPath: "image.excalidraw" });
              if (!excalidrawAPI) {
                return;
              }

              const file = serializeAsJSON(
                excalidrawAPI.getSceneElements(),
                excalidrawAPI.getAppState(),
                excalidrawAPI.getFiles(),
                "local"
              );
              await writeTextFile(path, file);
            }}
          >
            Save...
          </MainMenu.Item>
          <MainMenu.Item
            onSelect={async () => {
              if (!excalidrawAPI) {
                return;
              }
              const path = await save({ defaultPath: "image.png" });
              const blob = await exportToBlob({
                elements: excalidrawAPI?.getSceneElements(),
                mimeType: "image/png",
                appState: excalidrawAPI.getAppState(),
                files: excalidrawAPI?.getFiles(),
              });
              const arrayBuffer = await blob.arrayBuffer();
              await writeBinaryFile(path, arrayBuffer);
            }}
          >
            Export to png...
          </MainMenu.Item>

          <MainMenu.Item
            onSelect={async () => {
              if (!excalidrawAPI) {
                return;
              }
              const path = await save({ defaultPath: "image.svg" });
              const svg = await exportToSvg({
                elements: excalidrawAPI?.getSceneElements(),
                appState: excalidrawAPI.getAppState(),
                files: excalidrawAPI?.getFiles(),
              });
              await writeTextFile(path, svg.outerHTML);
            }}
          >
            Export to svg...
          </MainMenu.Item>
          */}

          <MainMenu.DefaultItems.LoadScene />
          <MainMenu.DefaultItems.ClearCanvas />
          <MainMenu.DefaultItems.ToggleTheme />
          <MainMenu.DefaultItems.Help />
        </MainMenu>
        <WelcomeScreen />
      </Excalidraw>
    </div>
  );
};
