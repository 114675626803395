import { Box, Divider } from "@mui/material";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { If } from "../../../../_lib/eniverse/util/Condition";
import { useWorker } from "../../../../hooks/useWorker";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { SimpleSearchTopForm } from "./GeneralSearch/recipe/form/SimpleSearchTop";
import { WordSearchResult } from "./WordSearchResult";
import {
  FunctionSearchProgress,
  DictionaryKey,
  GeneralSearchQuery,
  GeneralSearchInput,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

export type WordSearchResultTable = {
  buta: string[];
  common: string[];
  illust1: string[];
  cefr: string[];
};
type DictKeyList = "buta" | "common" | "illust1" | "cefr";

const initialResult: () => WordSearchResultTable = () => ({
  buta: [],
  common: [],
  illust1: [],
  cefr: [],
});

export const hasResult = (result: WordSearchResultTable) => {
  return Object.values(result).some((item) => item.length > 0);
};

export const WordSearch: FC = () => {
  const [mode, setMode] = useState<"simple" | "condition">("simple");
  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState<WordSearchResultTable>(initialResult());
  const [tab, setTab] = useState(0);
  const [progress, setProgress] = useState<FunctionSearchProgress>({
    startTime: 0,
    currentTime: 0,
    queryProgress: [],
  });
  const { generalSearch, load, abort } = useWorker();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const ctx = useContext(EnigmaStudioContext);

  useEffect(() => {
    let count = 0;
    (["buta", "common", "illust1", "cefr"] as DictionaryKey[]).forEach(
      (item: DictionaryKey) => {
        ++count;
        ctx.setLoaded(false);
        ctx.setLoadProgress(((ctx.enabledDictionary.length / count) * 100) | 0);
        load(item, false, (result: string[] | undefined) => {
          if (
            result?.sort().join(",") === ctx.enabledDictionary.sort().join(",")
          ) {
            ctx.setLoaded(true);
          }
          const progress = (((result?.length ?? 0) / count) * 100) | 0;
          ctx.setLoadProgress(Math.min(progress, 200 - progress));
        });
        ctx.setEnabledDictionary([
          ...new Set(ctx.enabledDictionary.concat(item)),
        ]);
      }
    );
  }, []);

  const [queries, setQueries] = useState<GeneralSearchQuery[]>([
    {
      type: "sequence",
      sequenceType: "simpleSearch",
      processes: [],
    },
  ]);
  const onSearch = useCallback(() => {
    setResult(initialResult());
    setSearching(true);

    queries.forEach((query) => {
      if (query.type === "regex") {
        query.pattern = query.pattern ?? "";
      }
      if (query.type === "candidateCharacters") {
        query.characters = query.characters ?? "";
      }
    });

    let searchCount = 0;
    let searchList = ["illust1", "common", "cefr", "buta"] as DictKeyList[];

    const search = (inputDictionary: DictKeyList) => {
      if (!inputDictionary) {
        return;
      }
      const input = {
        queries: queries,
        inputDictionary: inputDictionary,
        inputDictionaryFree: "",
        listDefinitions: [],
      };

      generalSearch(
        { input: input as GeneralSearchInput },
        (
          res: { s: string }[] | undefined,
          payload: {
            input: GeneralSearchInput;
          }
        ) => {
          if (res) {
            const words = res.map((e) => e.s);
            setResult((prev) => {
              return {
                ...prev,
                [payload.input.inputDictionary as DictKeyList]: [...words],
              };
            });
            if (searchCount < searchList.length) {
              search(searchList[++searchCount]);
            }
            setSearching(false);
          }
        },
        (progress) => {
          if (progress) {
            setProgress(progress);
          }
        }
      );
    };
    search(searchList[searchCount]);
  }, [generalSearch, queries]);

  const updateQuery = (index: number, field: string, value: any) => {
    const updatedQueries = [...queries] as any[];
    updatedQueries[index][field] = value;
    setQueries(updatedQueries as GeneralSearchQuery[]);
  };

  const deleteQueryField = (index: number, field: string) => {
    const updatedQueries = [...queries] as any[];
    delete updatedQueries[index][field];
    setQueries(updatedQueries as GeneralSearchQuery[]);
  };

  const reset = () => {
    setQueries([
      {
        type: "sequence",
        sequenceType: "simpleSearch",
        processes: [],
      },
    ]);
    setResult(initialResult());
  };

  return (
    <>
      <Box
        sx={{
          my: 12,
        }}
      />
      <If condition={mode === "simple"}>
        <SimpleSearchTopForm
          index={0}
          query={{
            type: "sequence",
            sequenceType: "simpleSearch",
            processes: [],
          }}
          isDemo={false}
          updateQuery={updateQuery}
          deleteQueryField={deleteQueryField}
          search={onSearch}
        />
      </If>
      <If condition={hasResult(result)}>
        <Box
          sx={{
            my: 2,
          }}
        />
        <Divider />
        <WordSearchResult
          items={[]}
          progress={progress}
          queries={queries}
          reset={reset}
          result={result}
        />
      </If>
    </>
  );
};
