export const presetItems = [
  {
    name: "s:sei",
    value:
      "あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん",
  },
  {
    name: "d:daku",
    value: "がぎぐげござじずぜぞだぢづでど;ばびぶべぼ",
  },
  {
    name: "d2s:daku2sei",
    value: "かきくけこさしすせそたちつてと;はひふへほ",
  },
  {
    name: "h:handaku",
    value: "ぱぴぷぺぽ",
  },
  {
    name: "h2s:handaku2sei",
    value: "はひふへほ",
  },
  {
    name: "k:kogaki",
    value: "ぁぃぅぇぉゃゅょっゎ",
  },
  {
    name: "k2s:kogaki2sei",
    value: "あいうえおやゆよつわ",
  },
  {
    name: "50g:50ongyo",
    value:
      "あいうえお;かきくけこ;さしすせそ;たちつてと;なにぬねの;はひふへほ;まみむめも;や#ゆ#よ;らりるれろ;わ###を",
  },
  {
    name: "50g2a:50ongyo2adan",
    value:
      "あああああ;かかかかか;さささささ;たたたたた;ななななな;ははははは;ままままま;や#や#や;ららららら;わ###わ",
  },
  {
    name: "50d:50ondan",
    value:
      "あかさたなはまやらわ;いきしちにひみ#り;うくすつぬふむゆる;えけせてねへめ#れ;おこそとのほもよろを",
  },
  {
    name: "50d2a:50ondan2agyo",
    value:
      "ああああああああああ;いいいいいいいいい;ううううううううう;えええええええええ;おおおおおおおおおお",
  },
  { name: "a:abc", value: "abcdefghijklmnopqrstuvwxyz" },
  {
    name: "iroha",
    value:
      "いろはにほへとちりぬるをわかよたれそつねならむうゐのおくやまけふこえてあさきゆめみしゑひもせす",
  },
];
