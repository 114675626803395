import { Container } from "@mui/material";
import { FC } from "react";
import { PlainText } from "../common/PlainText";
import { Title } from "../common/Title";
import { checkOldPlan } from "../auth/checkOldPlan";

export const OldPlan: FC = () => {
  const param = new URLSearchParams(window.location.search);
  const planKey = param.get("plan") ?? "normal";
  const oldPlan = checkOldPlan(planKey);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>リンク更新のお知らせ</Title>

      <PlainText>
        現在のリンクの有効期間が終了しています。FANBOXより新しいリンクをご利用ください。
      </PlainText>
      {oldPlan === "oldpro" && (
        <PlainText>
          Fanbox:
          <a
            href="https://wand.fanbox.cc/posts/6628879"
            target="_blank"
            rel="noreferrer"
          >
            EnigmaStudio Pro
          </a>
        </PlainText>
      )}
      {oldPlan === "oldpro+" && (
        <PlainText>
          Fanbox:
          <a
            href="https://wand.fanbox.cc/posts/6628886"
            target="_blank"
            rel="noreferrer"
          >
            EnigmaStudio Pro+方眼ツール
          </a>
        </PlainText>
      )}
      <br />
      <PlainText>
        <a href="https://studio.enigmahouse.jp/">通常プランで続行</a>
      </PlainText>
    </Container>
  );
};
