import { Box, Button, Container, TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { ResultList } from "../../common/Result";
import { Title } from "../../common/Title";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { PlainText } from "../../common/PlainText";
import { DemoModeMessage } from "../../commonStudio/DemoModeMessage";

export const Caesar: FC = () => {
  const [result, setResult] = useState<string[]>([]);
  const [pattern, setPattern] =
    useState<string>(`あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんあ
がぎぐげござじずぜぞだぢづでど#
ばびぶべぼ#
ぱぴぷぺぽ#
ゃゅょ#
アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンア
ガギグゲゴザジズゼゾダヂヅデド#
バビブベボ#
パピプペポ#
ャュョ#
ABCDEFGHIJKLMNOPQRSTUVWXYZA
abcdefghijklmnopqrstuvwxyza
`);

  const convert = (text: string) => {
    const result: string[] = [];
    result.push("00\t" + text);
    for (let n = 0; n < 50; n++) {
      let line = "";
      for (let i = 0; i < text.length; i++) {
        const c = text.charAt(i);
        if (c === "#") {
          line += "#";
          continue;
        }
        const index = pattern.indexOf(c);
        if (index >= 0) {
          if (pattern.charAt(index + 1) === "\n") {
            line += "#";
          }
          line += pattern.charAt(index + 1);
        } else {
          line += c;
        }
      }
      result.push((n + 1).toString().padStart(2, "0") + "\t" + line);
      text = line;
    }
    return result;
  };

  const [keywords, setKeywords] = useState("");
  useEffect(() => {
    setResult(convert(keywords));
  }, [pattern, keywords]);

  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const isDemo = plan === "normal";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>シーザーリスト</Title>
      <PlainText>
        指定されたキーワードを何文字かずらした文字列の一覧を生成します。
      </PlainText>
      <DemoModeMessage isDemo={isDemo} />
      <Box>
        <TextField
          label="文字配列"
          variant="outlined"
          sx={{ width: "80vw", mt: 2 }}
          multiline
          value={pattern}
          onChange={(event) => {
            setPattern(event.target.value);
          }}
          disabled={isDemo}
        />

        <Box>
          <Button
            variant="contained"
            onClick={() => {
              setPattern(`あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんあ
がぎぐげござじずぜぞだぢづでど#
ばびぶべぼ#
ぱぴぷぺぽ#
ゃゅょ#
アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンア
ガギグゲゴザジズゼゾダヂヅデド#
バビブベボ#
パピプペポ#
ャュョ#
ABCDEFGHIJKLMNOPQRSTUVWXYZA
abcdefghijklmnopqrstuvwxyza`);
            }}
          >
            プリセット1
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setPattern(`んをわろれるりらよゆやもめむみまほへふひはのねぬになとてつちたそせすしさこけくきかおえういあん
どでづぢだぞぜずじざごげぐぎが#
ぼべぶびば#
ぽぺぷぴぱ#
ょゅゃ#
ンヲワロレルリラヨユヤモメムミマホヘフヒハノネヌニナトテツチタソセスシサコケクキカオエウイアン
ドデヅヂダゾゼズジザゴゲグギガ#
ボベブビバ#
ポペプピパ#
ョュャ#
ZYXWVUTSRQPONMLKJIHGFEDCBAZ
zyxwvutsrqponmlkjihgfedcbaz`);
            }}
          >
            プリセット1反転
          </Button>
        </Box>
        <TextField
          label="Keyword"
          variant="outlined"
          sx={{ width: "70vw", mt: 2 }}
          value={keywords}
          onChange={(event) => {
            setKeywords(event.target.value);
            setResult(convert(event.target.value));
          }}
          disabled={isDemo}
        />

        <ResultList items={result} loading={false} loadingMessage={"計算中"} />
      </Box>
    </Container>
  );
};
