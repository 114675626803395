import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import "./SprintAnswer.css";
// App.tsx
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TwitterShareButton, XIcon } from "react-share";
import { useStatePersist } from "use-state-persist";
import { sprintAnswerEvents } from "./data/events";

import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import useSound from "use-sound";

// https://www.springin.org/sound-stock/category/system/
import cursorSound from "../../../../static/sounds/sprintAnswer/cursor.mp3";
import endBestSound from "../../../../static/sounds/sprintAnswer/endBest.mp3";
import endNormalSound from "../../../../static/sounds/sprintAnswer/endNormal.mp3";
import startSound from "../../../../static/sounds/sprintAnswer/start.mp3";
import successSound from "../../../../static/sounds/sprintAnswer/success.mp3";
import errorSound from "../../../../static/sounds/sprintAnswer/error.mp3";

const SprintAnswerGame: React.FC = () => {
  const { eventId } = useParams<"eventId">();
  const [elapsedTime, setElapsedTime] = useState<number>(0); // Update this line
  const [startDateTime, setStartDateTime] = useState<number>(
    new Date().getTime()
  );
  const [problemStartDateTime, setProblemStartDateTime] = useState<number>(
    new Date().getTime()
  );
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [eventInfo, setEventInfo] = useState(
    sprintAnswerEvents[eventId ?? "sample"]
  );
  const [bestTime, setBestTime] = useStatePersist<{ [key: string]: number }>(
    "enigma-gamein-sprint-answer-game-best-time",
    {}
  );

  const [enableSound, setEnableSound] = useStatePersist<boolean>(
    "enigma-gamein-sprint-answer-game-enable-sound",
    true
  );

  const [displayAnswer, setDisplayAnswer] = useState<boolean>(false);
  const [answerOpenCount, setAnswerOpenCount] = useState<number>(0);
  const [errorCount, setErrorCount] = useState<number>(0);

  const [playSuccess] = useSound(successSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });
  const [playClear] = useSound(endNormalSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });
  const [playBestClear] = useSound(endBestSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });
  const [playStart] = useSound(startSound, {
    volume: 0.77777777,
    soundEnabled: enableSound,
    onload: () => {
      console.log("loaded");
    },
  });
  const [playCursor] = useSound(cursorSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });
  const [playMuteStart] = useSound(startSound, {
    volume: 0,
    soundEnabled: false,
  });
  const [playError] = useSound(errorSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (eventInfo.questions.length === 0) {
    }
  }, [eventInfo, eventId]);

  const startGame = useCallback(() => {
    playStart();
    setStartDateTime(new Date().getTime());
    setProblemStartDateTime(new Date().getTime());
    setInputValue("");
    setIsGameStarted(true);
    setDisplayAnswer(false);
    setAnswerOpenCount(0);
    setErrorCount(0);
    setCurrentQuestion(0);

    setEventInfo((prevEventInfo) =>
      eventInfo.createQuestions
        ? {
            ...prevEventInfo,
            questions: eventInfo.createQuestions(),
          }
        : prevEventInfo
    );
  }, [
    eventInfo,
    playStart,
    setStartDateTime,
    setProblemStartDateTime,
    setInputValue,
    setIsGameStarted,
    setDisplayAnswer,
    setAnswerOpenCount,
    setErrorCount,
    setCurrentQuestion,
  ]);
  const endGame = () => {
    setIsGameOver(true);
  };
  const resetGame = useCallback(() => {
    if (isGameStarted) {
      playCursor();
    }
    setElapsedTime(0);
    setStartDateTime(new Date().getTime());
    setProblemStartDateTime(new Date().getTime());
    setCurrentQuestion(0);
    setIsGameOver(false);
    setIsGameStarted(false);
  }, [
    isGameStarted,
    playCursor,
    setElapsedTime,
    setProblemStartDateTime,
    setCurrentQuestion,
    setIsGameOver,
    setIsGameStarted,
  ]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === " ") {
        if (!isGameStarted) {
          startGame();
        } else if (eventInfo.retry && isGameOver) {
          event.preventDefault();
          resetGame();
        }
      }
      if (event.key === "Escape") {
        if (!isGameStarted) {
          if (eventInfo.addMenu) {
            playCursor();
            navigate("/g/sprint-answer");
          }
        } else {
          resetGame();
        }
      }
    },
    [
      isGameStarted,
      isGameOver,
      eventInfo,
      startGame,
      resetGame,
      navigate,
      playCursor,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isGameStarted, isGameOver, eventInfo, handleKeyDown]);

  useEffect(() => {
    if (!eventInfo) {
      return;
    }
    eventInfo.questions.forEach((question) => {
      if (!question.image) {
        return;
      }
      const img = new Image();
      img.src = question.image; // プリロードする
    });
  }, [eventInfo]);

  useEffect(() => {
    if (!isGameStarted) return;
    if (isGameOver) {
      const elapsedTime = (new Date().getTime() - startDateTime) / 1000;
      setElapsedTime(elapsedTime);
      if (eventId && answerOpenCount === 0 && errorCount === 0) {
        setBestTime((prevBestTime) => {
          const newBestTime = { ...prevBestTime };
          if (newBestTime[eventId]) {
            newBestTime[eventId] = Math.min(newBestTime[eventId], elapsedTime);
          } else {
            newBestTime[eventId] = elapsedTime;
          }
          return newBestTime;
        });
        if (
          answerOpenCount === 0 &&
          (!bestTime[eventId] || elapsedTime < bestTime[eventId])
        ) {
          playBestClear();
        } else {
          playClear();
        }
      }
      return;
    }

    const timer = setInterval(() => {
      setElapsedTime((prevTime) =>
        isGameOver ? prevTime : (new Date().getTime() - startDateTime) / 1000
      );
    }, 100);

    return () => clearInterval(timer);
  }, [isGameStarted, isGameOver]);

  const [isIME, setIsIME] = useState<boolean>(false);

  const submit = (value: string) => {
    if (eventInfo.questions[currentQuestion].answers.includes(value)) {
      setCurrentQuestion((prevQuestion) => {
        setProblemStartDateTime(new Date().getTime());
        if (prevQuestion + 1 >= eventInfo.questions.length) {
          endGame();
          return prevQuestion;
        } else {
          playSuccess();
        }
        return prevQuestion + 1;
      });
      setDisplayAnswer(false);
      setInputValue("");
    } else if (eventInfo.questions[currentQuestion].errors?.includes(value)) {
      playError();
      setErrorCount((prevCount) => prevCount + 1);
      setInputValue("");
    } else if (["!", "！"].includes(value)) {
      setDisplayAnswer(true);
      setAnswerOpenCount((prevCount) => prevCount + 1);
      setInputValue("");
    }
  };
  const onCompositionStart = () => {
    setIsIME(true);
  };
  const onCompositionEnd = () => {
    setIsIME(false);
    submit(inputValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === " ") {
      setInputValue("");
    } else {
      setInputValue(value);
    }

    if (!isIME) {
      submit(value);
    }
  };

  const submitScore = async (event: any) => {
    if (!eventId) {
      return;
    }

    const name = prompt("名前を入力してください");
    if (!name) {
      return;
    }
    return;
    /*
    const data = {
      name: name,
      score: elapsedTime,
    };

    const ref = doc(db, "race", eventId, "user", name);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      await setDoc(ref, data);
      alert("記録を送信しました");
    }*/
  };

  if (!isGameStarted) {
    return (
      <>
        <Stack direction="row">
          {eventInfo.addMenu && (
            <Button
              variant="outlined"
              component={Link}
              to={"/g/sprint-answer"}
              sx={{ mx: 1 }}
            >
              ゲームの一覧
            </Button>
          )}

          <Box
            sx={{
              ml: "auto",
              mr: "10px",
            }}
          >
            <IconButton aria-label="enable-sound" size={"large"}>
              {enableSound ? (
                <VolumeUpIcon
                  onClick={() => {
                    setEnableSound(false);
                  }}
                />
              ) : (
                <VolumeOffIcon
                  onClick={() => {
                    setEnableSound(true);
                    playCursor({
                      forceSoundEnabled: true,
                    });
                  }}
                />
              )}
            </IconButton>
          </Box>
        </Stack>
        <div className="App">
          <h1>{eventInfo.title}</h1>
          {eventInfo.descriptions}
          {eventInfo.retry && eventId && bestTime[eventId] && (
            <p className="result-best-time">
              ベストタイム: {bestTime[eventId].toFixed(2)}s
            </p>
          )}
          <Button
            className="start-button"
            onClick={() => {
              startGame();
            }}
            variant={"contained"}
            size="large"
          >
            スタート
          </Button>
          <p>スペースキーでもスタートできます。</p>
          {eventInfo.descriptions2}
        </div>
      </>
    );
  }

  if (isGameOver) {
    return (
      <>
        <Stack direction="row">
          <Button
            variant="outlined"
            component={Link}
            to={"/g/sprint-answer"}
            sx={{ mx: 1 }}
          >
            ゲームの一覧
          </Button>
          <Box
            sx={{
              ml: "auto",
              mr: "10px",
            }}
          >
            <IconButton aria-label="enable-sound" size={"large"}>
              {enableSound ? (
                <VolumeUpIcon
                  onClick={() => {
                    setEnableSound(false);
                  }}
                />
              ) : (
                <VolumeOffIcon
                  onClick={() => {
                    setEnableSound(true);
                    playCursor({
                      forceSoundEnabled: true,
                    });
                  }}
                />
              )}
            </IconButton>
          </Box>
        </Stack>

        <div className="App">
          <h1>{eventInfo.title}</h1>
          {eventInfo.retry && eventId && bestTime[eventId] && (
            <p className="result-best-time">
              ベストタイム: {bestTime[eventId].toFixed(2)}s
            </p>
          )}
          <Typography variant="h6" className="result-time">
            タイム: {elapsedTime.toFixed(2)}s{" "}
            {(answerOpenCount > 0 || errorCount > 0) && " (参考記録/"}
            {answerOpenCount > 0 && ` 解答表示: ${answerOpenCount}回)`}
            {errorCount > 0 && ` ミス: ${errorCount}回)`}
          </Typography>

          <Box sx={{ mt: 6 }}>
            <TwitterShareButton
              url={"https://gamein.enigmahouse.jp/g/sprint-answer/" + eventId}
              title={`『${eventInfo.title}』を${elapsedTime.toFixed(2)}秒${
                answerOpenCount > 0 ? `(+確認${answerOpenCount}回)` : ""
              }${errorCount > 0 ? `(+ミス${errorCount}回)` : ""}

でクリアしました！
`}
              hashtags={(eventInfo.hashTag ? [eventInfo.hashTag] : []).concat([
                "enigmagamein",
              ])}
              className="twitter-share-button"
              style={{
                borderRadius: "20px",
                width: "130px",
                lineHeight: "20px",
                verticalAlign: "middle",
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
              }}
            >
              <XIcon size={32} round />
              <span>結果をPost</span>
            </TwitterShareButton>
          </Box>

          {eventInfo.submit && (
            <Button
              sx={{
                my: 4,
              }}
              onClick={submitScore}
              variant={"contained"}
            >
              記録を送信
            </Button>
          )}
          {eventInfo.retry && (
            <Button
              sx={{
                my: 6,
              }}
              onClick={resetGame}
              variant={"contained"}
            >
              リトライ
            </Button>
          )}
        </div>
      </>
    );
  }

  if (eventInfo.questions.length === 0) {
    return <div>loading...</div>;
  }
  return (
    <Container
      maxWidth="lg"
      sx={{
        my: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h5"
        color={
          answerOpenCount > 0 || errorCount > 0
            ? "text.secondary"
            : "text.primary"
        }
      >
        {elapsedTime.toFixed(1)}s
      </Typography>
      <div className="question-number">
        <Typography color="text.primary" sx={{ fontSize: "1.5rem", mb: 2 }}>
          {currentQuestion + 1}/{eventInfo.questions.length} 問目
        </Typography>
      </div>
      <div className="question">
        {eventInfo.questions[currentQuestion].problemText && (
          <Box>
            {(eventInfo.questions[currentQuestion].problemText ?? "")
              .split("\n")
              .map((line, index) => (
                <Typography
                  variant={eventInfo.textStyle?.variant ?? "h4"}
                  key={index}
                >
                  {line}
                </Typography>
              ))}
          </Box>
        )}
        {eventInfo.questions[currentQuestion].image && (
          <img
            src={eventInfo.questions[currentQuestion].image}
            alt="問題画像"
          />
        )}
      </div>
      {eventInfo.questions[currentQuestion].hint &&
        (displayAnswer ||
          (new Date().getTime() - problemStartDateTime) / 1000 >
            (eventInfo.hintOpenTime ?? 30)) && (
          <Box sx={{ maxWidth: "480px", m: 1 }}>
            <Typography color="text.secondary" variant="body2">
              {eventInfo.questions[currentQuestion].hint}
            </Typography>
          </Box>
        )}
      <br className="spacer" />

      {displayAnswer && (
        <Box>
          <Typography variant={"h6"} color={"text.primary"}>
            {eventInfo.questions[currentQuestion].answers[0]}
          </Typography>
          <Typography variant={"subtitle1"} color={"text.secondary"}>
            {eventInfo.questions[currentQuestion].answers.slice(1).join(", ")}
          </Typography>
        </Box>
      )}

      {(eventInfo.questions[currentQuestion].type ?? "text") === "text" ? (
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onCompositionStart={onCompositionStart}
          onCompositionEnd={onCompositionEnd}
          className="answer"
          autoFocus
        />
      ) : eventInfo.questions[currentQuestion].type === "numeric" ? (
        <input
          type="text"
          inputMode="numeric"
          pattern="[\d]*"
          value={inputValue}
          onChange={handleInputChange}
          onCompositionStart={onCompositionStart}
          onCompositionEnd={onCompositionEnd}
          className="answer"
          autoFocus
        />
      ) : (
        <input
          type="text"
          inputMode="decimal"
          pattern="[\d\.]{0,2}"
          value={inputValue}
          onChange={handleInputChange}
          onCompositionStart={onCompositionStart}
          onCompositionEnd={onCompositionEnd}
          className="answer"
          autoFocus
        />
      )}
      {eventInfo.questions[currentQuestion].guideText && (
        <Typography variant={"subtitle1"} color={"text.secondary"}>
          {eventInfo.questions[currentQuestion].guideText}
        </Typography>
      )}
      <br className="spacer" />

      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        sx={{ mt: 8 }}
      >
        {eventInfo.answerButton && !displayAnswer && (
          <Box sx={{ width: "100%" }}>
            <Button
              onClick={(event) => {
                event.preventDefault();
                setDisplayAnswer(true);
                setAnswerOpenCount((prevCount) => prevCount + 1);
              }}
              variant={"contained"}
              size="large"
              sx={{ mt: 2 }}
            >
              解答を表示
            </Button>
          </Box>
        )}
        {eventInfo.retry && (
          <Box sx={{ width: "100%" }}>
            <Button
              onClick={resetGame}
              variant={"contained"}
              size="large"
              sx={{ mt: 2 }}
            >
              リセット
            </Button>
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default SprintAnswerGame;
