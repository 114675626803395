const dakuToSeiTable = {
    が: "か",
    ぎ: "き",
    ぐ: "く",
    げ: "け",
    ご: "こ",
    ざ: "さ",
    じ: "し",
    ず: "す",
    ぜ: "せ",
    ぞ: "そ",
    だ: "た",
    ぢ: "ち",
    づ: "つ",
    で: "て",
    ど: "と",
    ば: "は",
    び: "ひ",
    ぶ: "ふ",
    べ: "へ",
    ぼ: "ほ",
    ぱ: "は",
    ぴ: "ひ",
    ぷ: "ふ",
    ぺ: "へ",
    ぽ: "ほ",
    ガ: "カ",
    ギ: "キ",
    グ: "ク",
    ゲ: "ケ",
    ゴ: "コ",
    ザ: "サ",
    ジ: "シ",
    ズ: "ス",
    ゼ: "セ",
    ゾ: "ソ",
    ダ: "タ",
    ヂ: "チ",
    ヅ: "ツ",
    デ: "テ",
    ド: "ト",
    バ: "ハ",
    ビ: "ヒ",
    ブ: "フ",
    ベ: "ヘ",
    ボ: "ホ",
    パ: "ハ",
    ピ: "ヒ",
    プ: "フ",
    ペ: "ヘ",
    ポ: "ホ",
};
const smallToLargeTable = {
    ぁ: "あ",
    ぃ: "い",
    ぅ: "う",
    ぇ: "え",
    ぉ: "お",
    っ: "つ",
    ゃ: "や",
    ゅ: "ゆ",
    ょ: "よ",
    ゎ: "わ",
    ァ: "ア",
    ィ: "イ",
    ゥ: "ウ",
    ェ: "エ",
    ォ: "オ",
    ッ: "ツ",
    ャ: "ヤ",
    ュ: "ユ",
    ョ: "ヨ",
    ヮ: "ワ",
};
export const smallToLarge = (word) => {
    return word.replace(/[ぁぃぅぇぉっゃゅょゎァィゥェォャュョッヮ]/g, (s) => smallToLargeTable[s]);
};
export const dakuToSei = (word) => {
    return word.replace(/[がぎぐげござじずぜぞだぢづでどばびぶべぼぱぴぷぺぽガギグゲゴザジズゼゾダヂヅデドバビブベボパピプペポ]/g, (s) => dakuToSeiTable[s]);
};
