import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { ModeType } from "../Menu/ModeSelector";

type SquareMenuProps = {
  mode: ModeType | null;
  blackList: { x: number; y: number }[];
  whiteList: { x: number; y: number }[];
  artboard: { x: number; y: number; width: number; height: number };
  displayCrosswordNumberType: string;
  setBlackList: React.Dispatch<
    React.SetStateAction<{ x: number; y: number }[]>
  >;
  handleDisplayCrosswordNumberTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

export const SquareMenu: FC<SquareMenuProps> = ({
  mode,
  blackList,
  whiteList,
  artboard,
  displayCrosswordNumberType,
  setBlackList,
  handleDisplayCrosswordNumberTypeChange,
}) => {
  if (mode !== "square") {
    return <></>;
  }
  return (
    <>
      <Box>
        <Button
          variant="contained"
          onClick={() => {
            setBlackList([]);
          }}
          sx={{
            mr: 2,
          }}
        >
          黒マスを初期化
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const _blackList = [...blackList];
            for (let i = artboard.x; i < artboard.x + artboard.width; i++) {
              for (let j = artboard.y; j < artboard.y + artboard.height; j++) {
                if (
                  !blackList.some((b) => b.x === i && b.y === j) &&
                  !whiteList.some((w) => w.x === i && w.y === j)
                ) {
                  _blackList.push({ x: i, y: j });
                }
              }
            }
            setBlackList(_blackList);
          }}
        >
          盤面の白マス以外を黒マスに
        </Button>
      </Box>
      <Box>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={displayCrosswordNumberType}
            onChange={handleDisplayCrosswordNumberTypeChange}
          >
            <span style={{ marginTop: 10, marginRight: 10 }}>
              クロスワード採番
            </span>
            <FormControlLabel value="none" control={<Radio />} label="非表示" />
            <FormControlLabel
              value="horizontal"
              control={<Radio />}
              label="横振り"
            />
            <FormControlLabel
              value="vertical"
              control={<Radio />}
              label="縦振り"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};
