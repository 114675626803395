import { NodeModel } from "@minoru/react-dnd-treeview";
import { Breadcrumbs, Container, Link, Typography } from "@mui/material";
import { FC } from "react";
import { CustomData } from "../../../../_lib/eniverse/imaginate/treeView/types";

type HeaderProps = {
  children?: React.ReactNode;
  parentNode: NodeModel<CustomData> | null;
  currentNode: NodeModel<CustomData> | null;
  handleParentClick: () => void;
};

export const InnerHeader: FC<HeaderProps> = (props) => {
  const { parentNode, currentNode, handleParentClick } = props;

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "left",
        py: 2,
        width: "100%",
      }}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {parentNode && (
          <Link
            underline="hover"
            key="1"
            color="inherit"
            onClick={handleParentClick}
            sx={{ cursor: "pointer" }}
          >
            {parentNode.text}
          </Link>
        )}
        {currentNode && (
          <Typography key="2" color="text.primary">
            {currentNode.text}
          </Typography>
        )}
      </Breadcrumbs>
    </Container>
  );
};
