import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CloudItemProps, Metadata, TextDocFile } from "./cloudType";
import { SelectedFile } from "./CloudItemNew";
import { SelectedFileCard } from "./SelectedFileCard";

type Props = {
  selectedFile?: SelectedFile;
  onSelectedFile: (file: SelectedFile | undefined) => void;
  handleUpload: ({
    success,
    failed,
  }: {
    success: () => void;
    failed: () => void;
  }) => void;
} & CloudItemProps;

export const TextDragDropZone: FC<Props> = (props) => {
  const { selectedFile, onSelectedFile, handleUpload, fileListData } = props;
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);

  const onDropAccepted = useCallback(
    async (files: File[]) => {
      const mixFiles = [...files, ...currentFiles];
      const uniqueFiles = Array.from(
        new Map(mixFiles.map((file) => [file.name, file])).values()
      );
      setCurrentFiles(uniqueFiles);
      const textArr = (await uniqueFiles[0].text()).split("\n");
      const lineSize = textArr.length;
      const firstLine = textArr[0];
      const separator = firstLine.includes("\t")
        ? "\t"
        : firstLine.includes(",")
        ? ","
        : undefined;
      const existingFile = fileListData?.files[uniqueFiles[0].name];

      if (existingFile) {
        const doc: TextDocFile & Metadata = {
          ...existingFile,
          size: uniqueFiles[0].size,
          type: "text",
          lineSize,
        };
        if (separator) {
          doc.separator = separator;
          doc.headerRow = 1;
          doc.headerText = firstLine;
        } else {
          doc.headerRow = 0;
        }
        onSelectedFile({
          file: uniqueFiles[0],
          doc,
          existing: true,
        });
        return;
      } else {
        const doc: TextDocFile = {
          name: uniqueFiles[0].name,
          fileName: uniqueFiles[0].name,
          size: uniqueFiles[0].size,
          type: "text",
          lineSize,
        };
        if (separator) {
          doc.separator = separator;
          doc.headerRow = 1;
          doc.headerText = firstLine;
        } else {
          doc.headerRow = 0;
        }
        onSelectedFile({
          file: uniqueFiles[0],
          doc,
        });
      }
    },
    [currentFiles, fileListData?.files, onSelectedFile]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    inputRef,
  } = useDropzone({
    accept: {
      "text/plain": [".txt", ".TXT"],
      "text/csv": [".csv", ".CSV"],
    },
    maxFiles: 1,
    maxSize: 1048576 * 30,
    onDropAccepted,
    multiple: false,
  });

  const baseStyle = useMemo(
    () => ({
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "200px",
      height: "100%",
      padding: "20px",
      borderWidth: "2px",
      borderRadius: "8px",
      borderColor: "#bbbbbb",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#888888",
      outline: "none",
    }),
    []
  );

  const focusedStyle = useMemo(
    () => ({
      borderColor: "#44AA55",
    }),
    []
  );

  const acceptStyle = useMemo(
    () => ({
      borderColor: "#44AA55",
      backgroundColor: "#fafafa",
      color: "#44AA55",
    }),
    []
  );

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
      } as React.CSSProperties),

    [isFocused, isDragAccept, baseStyle, focusedStyle, acceptStyle]
  );

  const clearFile = useCallback(() => {
    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
    setCurrentFiles([]);
    onSelectedFile(undefined);
  }, [inputRef, onSelectedFile]);

  // Workaround of react-dropzone: Make it possible to remove file from acceptedFiles state
  // Reference: https://github.com/react-dropzone/react-dropzone/issues/805
  const handleRemoveFile = useCallback(
    async (file: File) => {
      const newFiles = [...currentFiles];
      newFiles.splice(newFiles.indexOf(file), 1);
      clearFile();
    },
    [currentFiles, clearFile]
  );

  const handleRemoveAll = useCallback(() => {
    clearFile();
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  }, [clearFile, acceptedFiles]);

  const acceptingFilesCard = useMemo(() => {
    return currentFiles.map((file, i) => (
      <SelectedFileCard
        key={i}
        file={file}
        onRemove={() => {
          handleRemoveFile(file);
        }}
      />
    ));
  }, [currentFiles, handleRemoveFile]);

  return (
    <>
      {currentFiles.length <= 0 && (
        <div className="dragdrop-hitarea-wrap">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <span className="icon-upload">⇪</span>
            <p style={{ textAlign: "center" }}>
              ここにファイルをドロップ
              <br />
              もしくはここをクリック/タップしてファイルを選択
              <br />
              (30MBまでアップロードできます)
            </p>
          </div>
        </div>
      )}

      {currentFiles.length > 0 &&
        selectedFile &&
        selectedFile.doc &&
        selectedFile.doc.type === "text" && (
          <>
            <Grid container className="accepting-file-card-list">
              {acceptingFilesCard}
            </Grid>
            {selectedFile.doc.headerRow === 1 && (
              <p>
                <Typography
                  variant="caption"
                  sx={{
                    my: 2,
                    color: "error.main",
                  }}
                >
                  区切り付きテキストのため、一行目がヘッダ行として扱われます。
                </Typography>
              </p>
            )}
            <Box>
              <TextField
                label="ファイル名"
                value={currentFiles[0].name ?? ""}
                disabled
                sx={{
                  my: 2,
                }}
              />
              <TextField
                label="タイプ"
                value={
                  selectedFile.doc.separator === ","
                    ? "カンマ区切りテキスト"
                    : selectedFile.doc.separator === "\t"
                    ? "タブ区切りテキスト"
                    : "テキスト"
                }
                disabled
                sx={{
                  my: 2,
                }}
              />
              <TextField
                label="ファイルサイズ"
                value={currentFiles[0]?.size ?? ""}
                disabled
                sx={{
                  my: 2,
                }}
              />
              <TextField
                label="行数"
                value={selectedFile.doc.lineSize ?? 0}
                disabled
                sx={{
                  my: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                label="表示名"
                value={selectedFile.doc.name ?? ""}
                sx={{
                  my: 2,
                }}
                onChange={(e) => {
                  onSelectedFile({
                    file: currentFiles[0],
                    doc: {
                      ...selectedFile.doc,
                      name: e.target.value,
                    },
                  });
                }}
                error={selectedFile.doc.name === ""}
              />
            </Box>
          </>
        )}
      <Box>
        {selectedFile?.existing && (
          <p>
            <Typography
              variant="caption"
              sx={{
                my: 2,
                color: "error.main",
              }}
            >
              同名のファイルが存在します。アップロードすると上書きされます。
            </Typography>
          </p>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            handleUpload({
              success: handleRemoveAll,
              failed: handleRemoveAll,
            })
          }
          disabled={
            (currentFiles?.length ?? 0) <= 0 || selectedFile?.doc.name === ""
          }
          sx={{
            my: 2,
            mr: 2,
          }}
        >
          {currentFiles.length >= 2 && "すべて"}アップロード
        </Button>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          disabled={(currentFiles?.length ?? 0) <= 0}
          onClick={handleRemoveAll}
          sx={{
            my: 2,
            mr: 2,
          }}
        >
          リセット
        </Button>
      </Box>
    </>
  );
};
TextDragDropZone.displayName = "DragDropZone";
