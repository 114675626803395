import { GeneralSearchQuery } from "@langue-de-chat-llc/enigmastudio-algorithm";
import { useCallback, useMemo, useState } from "react";
import { useStatePersist } from "use-state-persist";
import { decompress } from "../../../../../../lib/compress";
import {
  generalSearchObjFromJson,
  jsonFromGeneralSearchObj,
} from "./generalSearchObject";
import { EstimationHook, useEstimation } from "./useEstimation";

export type GeneralSearchQueryHook = {
  queries: GeneralSearchQuery[];
  setQueries: (value: GeneralSearchQuery[]) => void;
  queryJson: string;
  setQueryJson: (value: string) => void;
  sequencialQueries: GeneralSearchQuery[];
  traceCounter: number[];
  setQueryFromJson: (json: string) => boolean;
  jsonError: boolean;
  setJsonError: (value: boolean) => void;
  removeQuery: (index: number) => void;
  updateQuery: (index: number, field: string, value: any) => void;
  deleteQueryField: (index: number, field: string) => void;
} & EstimationHook;

export const useGeneralSearchQuery = (): GeneralSearchQueryHook => {
  const [queries, setQueries] = useStatePersist<GeneralSearchQuery[]>(
    "general-search-queries",
    []
  );
  const [queryJson, setQueryJson] = useStatePersist<string>(
    "general-search-query",
    `[]`
  );

  const param = new URLSearchParams(window.location.search);
  const queryKey = param.get("q");
  const sequencialQueries = useMemo(() => {
    return queries
      .map((query) => {
        if (query?.type === "sequence") {
          return query.processes;
        } else {
          return query;
        }
      })
      .flat();
  }, [queries]);

  const traceCounter = useMemo(() => {
    const counter: number[] = [];
    let count = 1;
    let plus = 0;
    sequencialQueries.forEach((query, i) => {
      if (!query) {
        return;
      }
      counter.push(count);
      if (query.enabled ?? true) {
        if (
          query.type === "regexReplace" ||
          query.type === "parallelTransform" ||
          (query.type === "dictionaryMatch" && query.anagramMatch) ||
          query.type === "keyValueMatch" ||
          query.type === "conditionalTransform"
        ) {
          count += 1 + plus;
          plus = 0;
        }
        if (query.type === "regexMatchCompare") {
          plus += (
            (query.trace ?? []).filter(
              (_, i) => i < query.replacements.length
            ) ?? []
          ).reduce((sum: number, trace: boolean) => sum + (trace ? 1 : 0), 0);
        }
      }
    });
    counter.push(count);
    return counter;
  }, [sequencialQueries]);

  const estimationSet = useEstimation(queries);
  const { estimation, isEstimation } = estimationSet;
  const [jsonError, setJsonError] = useState(false);

  const setQueryFromJson = useCallback(
    (json: string) => {
      const parsedObj = generalSearchObjFromJson(json);
      if (parsedObj.error) {
        setQueryJson(json);
        setJsonError(true);
        return false;
      }
      setQueries(parsedObj.queries);
      setQueryJson(json);
      setJsonError(false);
      return true;
    },
    [setQueries, setQueryJson]
  );

  if (queryKey) {
    decompress(queryKey).then((decompressed) => {
      setQueryFromJson(decompressed);
      param.delete("q");
      if (param.toString() !== "") {
        window.history.replaceState({}, "", "?" + param.toString());
      } else {
        window.history.replaceState({}, "", "/general-search");
      }
    });
  }

  const removeQuery = useCallback(
    (index: number) => {
      const updatedQueries = [...queries].filter((_, i) => i !== index);
      setQueries(updatedQueries);
      setQueryJson(
        jsonFromGeneralSearchObj({
          queries: updatedQueries,
          estimation: isEstimation ? estimation : undefined,
          version: "2",
        })
      );
      setJsonError(false);
    },
    [estimation, isEstimation, queries, setQueries, setQueryJson]
  );

  const updateQuery = useCallback(
    (index: number, field: string, value: any) => {
      const updatedQueries = [...queries] as any[];
      updatedQueries[index][field] = value;
      setQueries(updatedQueries as GeneralSearchQuery[]);
      setQueryJson(
        jsonFromGeneralSearchObj({
          queries: updatedQueries,
          estimation: isEstimation ? estimation : undefined,
          version: "2",
        })
      );
      setJsonError(false);
    },
    [estimation, isEstimation, queries, setQueries, setQueryJson]
  );

  const deleteQueryField = useCallback(
    (index: number, field: string) => {
      const updatedQueries = [...queries] as any[];
      delete updatedQueries[index][field];
      setQueries(updatedQueries as GeneralSearchQuery[]);
      setQueryJson(
        jsonFromGeneralSearchObj({
          queries: updatedQueries,
          estimation: isEstimation ? estimation : undefined,
          version: "2",
        })
      );
      setJsonError(false);
    },
    [estimation, isEstimation, queries, setQueries, setQueryJson]
  );

  return {
    queries,
    setQueries,
    queryJson,
    setQueryJson,
    sequencialQueries,
    traceCounter,
    ...estimationSet,
    setQueryFromJson,
    jsonError,
    setJsonError,
    removeQuery,
    updateQuery,
    deleteQueryField,
  };
};
