import { Question } from "../events";

export const zz = {
  id: "zz",
  title: "一桁のZZ",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「B × F」→「L」のように
      <br />
      A〜Zを1から26に対応させた数字を使って、
      <br />
      27進数の一桁の掛け算の計算を行います。
    </>
  ),
  descriptions: [
    <p key={0}>
      「B × Z」→「AY」のように
      <br />
      A〜Zを1から26に対応させた数字を使って、27進数の一桁の掛け算の計算を行います。
      <br />
      A→1, Z→26, A0→27, AA→28, B0→54, BA→55, ...
      <br />
      大文字小文字は任意、0は0または.に対応しています。
      <br />
      全部で10問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () => {
    const questions: Question[] = [];
    for (let i = 0; i < 10; i++) {
      const a = Math.floor(Math.random() * 26) + 1;
      const b = Math.floor(Math.random() * 26) + 1;
      const answer = a * b;
      const answers = [
        ...new Set(
          [
            "0ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            ".ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            "0abcdefghijklmnopqrstuvwxyz",
            ".abcdefghijklmnopqrstuvwxyz",
          ].map((e) => {
            return (
              (answer >= 27 ? e[Math.floor(answer / 27)] : "") + e[answer % 27]
            );
          })
        ),
      ];

      const question = {
        problemText: `${String.fromCharCode(a + 64)} × ${String.fromCharCode(
          b + 64
        )}`,
        answers,
      };
      questions.push(question);
    }
    return questions;
  },
  shareButton: true,
  answerButton: true,
  hashTag: "一桁のZZ",
};