import {
  dictionaries,
  DictionaryKey,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStatePersist } from "use-state-persist";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { debugKey, proKey, proPlusKey } from "../../../App";
import { UserClaims, useUserClaims } from "../auth/useUserClaims";
import { DocFile } from "../Studio/CloudItem/cloudType";

type ServiceId = "gamein" | "studio";
export type EnigmaStudioContextType = {
  plan: string;
  service: ServiceId;
  loaded: boolean;
  enableLoad: boolean;
  setEnableLoad: Dispatch<SetStateAction<boolean>>;
  setLoaded: Dispatch<SetStateAction<boolean>>;
  loadProgress: number;
  setLoadProgress: Dispatch<SetStateAction<number>>;
  enabledDictionary: DictionaryKey[];
  setEnabledDictionary: (enabledDictionary: DictionaryKey[]) => void;
  role?: string | null;
  claims: UserClaims;
  workerInstance: Worker | null;
  enabledUserDictionary: {
    key: string;
    name: string;
    doc: DocFile;
  }[];
  setEnabledUserDictionary: (
    enabledUserDictionary: {
      key: string;
      name: string;
      doc: DocFile;
    }[]
  ) => void;
  multiSelect: boolean;
  setMultiSelect: Dispatch<SetStateAction<boolean>>;
};

export const EnigmaStudioContext = createContext<EnigmaStudioContextType>({
  plan: "normal",
  service: "studio",
  enableLoad: false,
  setEnableLoad: () => {},
  loaded: false,
  setLoaded: () => {},
  loadProgress: 0,
  setLoadProgress: () => {},
  enabledDictionary: (
    JSON.parse(
      localStorage.getItem("enabledDictionary") || "[]"
    ) as DictionaryKey[]
  ).filter((e) => Object.keys(dictionaries).includes(e)),
  setEnabledDictionary: () => {},
  claims: {},
  workerInstance: null,
  enabledUserDictionary: [],
  setEnabledUserDictionary: () => {},
  multiSelect: false,
  setMultiSelect: () => {},
});
const bizDomain = ["langue-de-chat.llc"];
const bizTrialDomain = ["greendice.co.jp"];

export const EnigmaStudioContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const ctx = useContext(EnigmaStudioContext);
  const [enabledDictionary, setEnabledDictionary] = useState<DictionaryKey[]>(
    ctx.enabledDictionary
  );
  const [enabledUserDictionary, setEnabledUserDictionary] = useState<
    {
      key: string;
      name: string;
      doc: DocFile;
    }[]
  >(ctx.enabledUserDictionary);
  const [loaded, setLoaded] = useState(false);
  const [loadProgress, setLoadProgress] = useState(0);
  const [enableLoad, setEnableLoad] = useState(ctx.enableLoad);
  const [multiSelect, setMultiSelect] = useStatePersist(
    "dictionary-multi-select",
    false
  );

  const { user } = useFirebaseLogin();
  const [claims] = useUserClaims(user);
  const { stripeRole, manualRole } = claims;

  const role = stripeRole || manualRole;

  const param = new URLSearchParams(window.location.search);

  const planKey =
    sessionStorage.getItem("planKey") ?? param.get("plan") ?? "normal";
  if (planKey !== "normal") {
    sessionStorage.setItem("planKey", planKey);
    param.delete("plan");
  } else {
    sessionStorage.removeItem("planKey");
  }

  const workerInstance = useMemo(() => {
    return new Worker(new URL("../../../worker/worker.ts", import.meta.url));
  }, []);

  const userDomain = user?.email?.split("@")[1];
  const plan = bizDomain.includes(userDomain ?? "")
    ? "biz"
    : bizTrialDomain.includes(userDomain ?? "")
    ? "biz-trial"
    : user && role === "cloudplus"
    ? "cloudplus"
    : user && role === "cloud"
    ? "cloud"
    : user && role === "online"
    ? "online"
    : user && planKey === proPlusKey
    ? "lc-pro+"
    : user && planKey === proKey
    ? "lc-pro"
    : user
    ? "lc"
    : planKey === proPlusKey
    ? "pro+"
    : planKey === proKey
    ? "pro"
    : planKey === debugKey
    ? "debug"
    : "normal";

  const [, setPlanKeyRecord] = useStatePersist<{
    [key: string]: string;
  }>("hasPlanKey", {});

  const pathname = window.location.pathname;
  const service = useMemo(() => {
    return pathname.match("^/g(/|$)") ? "gamein" : ("studio" as ServiceId);
  }, [pathname]);

  useEffect(() => {
    if (plan !== "normal") {
      setPlanKeyRecord((prev) => {
        if (prev[plan] === planKey) {
          return prev;
        }
        prev[plan] = planKey;
        return prev;
      });
    }
  }, [plan, planKey, setPlanKeyRecord]);

  useEffect(() => {
    if (!workerInstance) {
      return;
    }
    ctx.enabledDictionary.forEach((key) => {
      if (!enableLoad) {
        return;
      }
      workerInstance.postMessage({
        type: "load",
        payload: {
          dictionary: key,
          hasAll: ctx.enabledDictionary.includes("all"),
        },
      });
      workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
        const result = ev.data;
        if (result?.sort().join(",") === enabledDictionary.sort().join(",")) {
          setLoaded(true);
        }
        setLoadProgress(
          (((result?.length ?? 0) / enabledDictionary.length) * 100) | 0
        );
      };
    });

    if (ctx.enabledDictionary.length === 0) {
      setLoaded(true);
      setLoadProgress(100);
    }
  }, [ctx.enabledDictionary, enableLoad, enabledDictionary, workerInstance]);

  const context = {
    enableLoad,
    setEnableLoad,
    enabledDictionary,
    setEnabledDictionary: (enabledDictionary: DictionaryKey[]) => {
      localStorage.setItem(
        "enabledDictionary",
        JSON.stringify(enabledDictionary)
      );
      setEnabledDictionary(enabledDictionary);
    },
    enabledUserDictionary,
    setEnabledUserDictionary,
    loaded,
    setLoaded,
    loadProgress,
    setLoadProgress,
    plan,
    service,
    role,
    claims,
    workerInstance,
    multiSelect,
    setMultiSelect,
  };
  return (
    <EnigmaStudioContext.Provider value={context}>
      {children}
    </EnigmaStudioContext.Provider>
  );
};
