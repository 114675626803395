export const lpEditorDataTable = {
  default: {
    label: "利用するアイテムを選択してください",
    form: [
      [
        { formType: "category-label", label: "テキスト" },
        {
          formType: "button",
          setType: "mainTitle",
          label: "メインタイトル",
        },
        { formType: "button", setType: "title", label: "タイトル" },
        { formType: "button", setType: "subTitle", label: "サブタイトル" },
      ],
      [
        { formType: "category-label", label: "画像" },
        {
          formType: "button",
          setType: "contentsVisual",
          label: "コンテンツビジュアル",
        },
        { formType: "button", setType: "imagelist", label: "画像リスト" },
      ],
      [
        { formType: "category-label", label: "その他" },
        {
          formType: "button",
          setType: "linkButton",
          label: "リンクボタン",
        },
        { formType: "button", setType: "table", label: "表" },
        {
          formType: "button",
          setType: "bgAnchor",
          label: "背景チェンジャー",
        },
        { formType: "button", setType: "space", label: "スペース" },
      ],
    ],
    summaryView: [],
  },
  mainTitle: {
    label: "メインタイトル",
    form: [
      [{ formType: "text", setField: "title", label: "メインタイトル" }],
      [{ formType: "text", setField: "description", label: "説明" }],
    ],
    summary: [
      [{ formType: "text", setField: "title", label: "メインタイトル" }],
      [{ formType: "text", setField: "description", label: "説明" }],
    ],
  },
  space: {
    label: "スペース",
    form: [[{ formType: "number", setField: "height", label: "スペース" }]],
    summary: [[{ formType: "number", setField: "height", label: "スペース" }]],
  },
  title: {
    label: "タイトル",
    form: [
      [{ formType: "text", setField: "title", label: "タイトル" }],
      [{ formType: "text", setField: "anchor", label: "リンクアンカー" }],
    ],
    summary: [
      [{ formType: "text", setField: "title", label: "タイトル" }],
      [{ formType: "text", setField: "anchor", label: "リンクアンカー" }],
    ],
  },
  subTitle: {
    label: "サブタイトル",
    form: [[{ formType: "text", setField: "text", label: "サブタイトル" }]],
    summary: [[{ formType: "text", setField: "text", label: "文字列" }]],
  },
  contentsVisual: {
    label: "コンテンツビジュアル",
    form: [[{ formType: "text", setField: "src", label: "画像URL" }]],
    summary: [[{ formType: "text", setField: "src", label: "画像URL" }]],
  },
  bgAnchor: {
    label: "背景チェンジャー",
    form: [[{ formType: "text", setField: "src", label: "画像URL" }]],
    summary: [[{ formType: "text", setField: "src", label: "画像URL" }]],
  },
  table: {
    label: "表",
    editType: "table",
  },
  linkButton: {
    label: "リンクボタン",
    form: [
      [
        { formType: "text", setField: "buttonName", label: "ボタン名" },
        { formType: "text", setField: "href", label: "リンクURL" },
      ],
    ],
    summary: [
      [
        { formType: "text", setField: "buttonName", label: "ボタン名" },
        { formType: "text", setField: "href", label: "リンクURL" },
      ],
    ],
  },
  imagelist: {
    label: "画像リスト",
  },
};
