import { Box, Tabs, Tab } from "@mui/material";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const StackViewTab = ({
  children,
  value,
  handleChange,
  labels = ["管理","編集フォーム", "プレビュー"],
}: {
  value: number;
  children?: React.ReactNode;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  labels?: string[];
}) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {labels.map((label, index) => (
          <Tab label={label} {...a11yProps(index)} key={index} />
        ))}
      </Tabs>
    </Box>
  );
};
