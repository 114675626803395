import "./SprintAnswer.css";
// App.tsx
import {
  Breadcrumbs,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Title } from "../../common/Title";
import SprintAnswerMenuItem from "./SprintAnswerMenuItem";
import { sprintAnswerEvents } from "./data/events";
import { Link } from "react-router-dom";

const SprintAnswerMenu: FC = () => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">
          <Button variant="outlined" component={Link} to={"/g"} sx={{ mx: 1 }}>
            EnigmaGAMEINトップ
          </Button>
        </Typography>
      </Breadcrumbs>
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Title>スプリントアンサー</Title>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {Object.keys(sprintAnswerEvents)
            .filter((key) => sprintAnswerEvents[key].addMenu)
            .map((key) => (
              <SprintAnswerMenuItem event={sprintAnswerEvents[key]} key={key} />
            ))}
        </Stack>
      </Container>
    </>
  );
};

export default SprintAnswerMenu;
