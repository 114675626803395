import { FC } from "react";
import { Label, Text } from "react-konva";
import {
  CrossPoint,
  Pos,
  WhiteLineCandidate,
  WhiteLineObj,
  WordObj,
} from "../gridToolType";

export type SkeletonCandidateViewProps = {
  size: number;
  gridOffsetX: number;
  gridOffsetY: number;
  whiteLineCandidateList: WhiteLineCandidate[][];
  whiteLines: WhiteLineObj[];
  wordList: WordObj[];
  rightIndexCrossPointTable: {
    [key: number]: CrossPoint[];
  };
  downIndexCrossPointTable: {
    [key: number]: CrossPoint[];
  };
};

export const SkeletonCandidateView: FC<SkeletonCandidateViewProps> = ({
  size,
  gridOffsetX,
  gridOffsetY,
  whiteLineCandidateList,
  whiteLines,
  wordList,
  rightIndexCrossPointTable,
  downIndexCrossPointTable,
}) => {
  return (
    <>
      {whiteLineCandidateList.map((listObj, index) => {
        const more = listObj.length >= 5;
        if (!more && listObj.length !== 0) {
          return null;
        }
        const whiteLine = whiteLines[index];
        if (!whiteLine) {
          return null;
        }
        const wordObj = {
          word: (more
            ? "○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○○"
            : "××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××××"
          ).substring(0, whiteLine.length),
          x: whiteLine.x,
          y: whiteLine.y,
          direction: whiteLine.direction,
        };
        return Array.from(wordObj.word).map((char, charIndex) => {
          let x, y;

          if (wordObj.direction === "right") {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "right-down") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "down") {
            x = wordObj.x * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left-down") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left") {
            x = (wordObj.x - charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "left-up") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "up") {
            x = wordObj.x * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "right-up") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          }

          return (
            <Label
              key={`skeleton-word-${index}-${charIndex}`}
              x={x + gridOffsetX} // 画面上での座標
              y={y + gridOffsetY} // 画面上での座標
              width={size}
              height={size}
            >
              <Text
                fontFamily="Arial"
                fontSize={size * 0.9}
                text={char}
                fill={more ? "#388e3c" : "#B00020"}
                align="center"
                verticalAlign="bottom"
                width={size}
                height={size}
                fontStyle="bold"
              />
            </Label>
          );
        });
      })}

      {whiteLineCandidateList.map((listObj, index) => {
        if (listObj.length !== 1) {
          return null;
        }
        const whiteLine = whiteLines[index];
        if (!whiteLine) {
          return null;
        }
        if (!wordList[listObj[0].index]) {
          return null;
        }
        const wordObj = {
          word: wordList[listObj[0].index].word,
          x: whiteLine.x,
          y: whiteLine.y,
          direction: whiteLine.direction,
        };
        return Array.from(wordObj.word).map((char, charIndex) => {
          let x, y;

          if (wordObj.direction === "right") {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "right-down") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "down") {
            x = wordObj.x * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left-down") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left") {
            x = (wordObj.x - charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "left-up") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "up") {
            x = wordObj.x * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "right-up") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          }

          return (
            <Label
              key={`skeleton-word-${index}-${charIndex}`}
              x={x + gridOffsetX} // 画面上での座標
              y={y + gridOffsetY} // 画面上での座標
              width={size}
              height={size}
            >
              <Text
                fontFamily="Arial"
                fontSize={size * 0.9}
                text={char}
                fill={"#449"}
                align="center"
                verticalAlign="bottom"
                width={size}
                height={size}
                fontStyle="bold"
              />
            </Label>
          );
        });
      })}

      {whiteLineCandidateList.map((listObj, index) => {
        if (listObj.length !== 1) {
          return null;
        }
        const whiteLine = whiteLines[index];
        if (!whiteLine) {
          return null;
        }
        const wordObj = {
          word: wordList[listObj[0].index].word,
          x: whiteLine.x,
          y: whiteLine.y,
          direction: whiteLine.direction,
        };
        return Array.from(wordObj.word).map((char, charIndex) => {
          let x, y;

          if (wordObj.direction === "right") {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "right-down") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "down") {
            x = wordObj.x * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left-down") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left") {
            x = (wordObj.x - charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "left-up") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "up") {
            x = wordObj.x * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "right-up") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          }

          return (
            <Label
              key={`skeleton-word-${index}-${charIndex}`}
              x={x + gridOffsetX} // 画面上での座標
              y={y + gridOffsetY} // 画面上での座標
              width={size}
              height={size}
            >
              <Text
                fontFamily="Arial"
                fontSize={size * 0.9}
                text={char}
                fill={"#449"}
                align="center"
                verticalAlign="bottom"
                width={size}
                height={size}
                fontStyle="bold"
              />
            </Label>
          );
        });
      })}

      {whiteLineCandidateList.map((listObj, index) => {
        if (listObj.length < 2) {
          return null;
        }
        const whiteLine = whiteLines[index];
        if (!whiteLine) {
          return null;
        }
        const wordObj = {
          word: listObj.map((e) => wordList[e.index].word),
          x: whiteLine.x,
          y: whiteLine.y,
          direction: whiteLine.direction,
        };

        const crossPoints =
          whiteLine.direction === "right"
            ? rightIndexCrossPointTable[whiteLine.index]
            : downIndexCrossPointTable[whiteLine.index];

        if (!crossPoints) {
          return null;
        }

        return Array.from(wordObj.word[0]).map((_, charIndex) => {
          const chars = wordObj.word.map((word) => word[charIndex]);
          let x, y;

          if (wordObj.direction === "right") {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "right-down") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "down") {
            x = wordObj.x * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left-down") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y + charIndex) * size;
          } else if (wordObj.direction === "left") {
            x = (wordObj.x - charIndex) * size;
            y = wordObj.y * size;
          } else if (wordObj.direction === "left-up") {
            x = (wordObj.x - charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "up") {
            x = wordObj.x * size;
            y = (wordObj.y - charIndex) * size;
          } else if (wordObj.direction === "right-up") {
            x = (wordObj.x + charIndex) * size;
            y = (wordObj.y - charIndex) * size;
          } else {
            x = (wordObj.x + charIndex) * size;
            y = wordObj.y * size;
          }

          if (
            whiteLine.direction === "down" &&
            crossPoints.some(
              (pos: Pos) =>
                pos.x === wordObj.x && pos.y === wordObj.y + charIndex
            )
          ) {
            return null;
          }

          if (chars.every((c) => c === chars[0])) {
            return (
              <Label
                key={`skeleton-word-${index}-${charIndex}`}
                x={x + gridOffsetX} // 画面上での座標
                y={y + gridOffsetY} // 画面上での座標
                width={size}
                height={size}
              >
                <Text
                  fontFamily="Arial"
                  fontSize={size * 0.9}
                  text={chars[0]}
                  fill={"#449"}
                  align="center"
                  verticalAlign="bottom"
                  width={size}
                  height={size}
                  fontStyle="bold"
                />
              </Label>
            );
          } else {
            return (
              <Label
                key={`skeleton-word-${index}-${charIndex}`}
                x={x + gridOffsetX} // 画面上での座標
                y={y + gridOffsetY} // 画面上での座標
                width={size}
                height={size}
              >
                <Text
                  fontFamily="Arial"
                  fontSize={size * 0.45}
                  text={chars[0]}
                  fill={"#449"}
                  align="left"
                  verticalAlign="top"
                  width={size}
                  height={size}
                  fontStyle="bold"
                />
                {chars.length > 1 && (
                  <Text
                    fontFamily="Arial"
                    fontSize={size * 0.45}
                    text={chars[1]}
                    fill={"#449"}
                    align="right"
                    verticalAlign={chars.length === 2 ? "bottom" : "top"}
                    width={size}
                    height={size}
                    fontStyle="bold"
                  />
                )}
                {chars.length > 2 && (
                  <Text
                    fontFamily="Arial"
                    fontSize={size * 0.45}
                    text={chars[2]}
                    fill={"#449"}
                    align="left"
                    verticalAlign="bottom"
                    width={size}
                    height={size}
                    fontStyle="bold"
                  />
                )}
                {chars.length > 3 && (
                  <Text
                    fontFamily="Arial"
                    fontSize={size * 0.45}
                    text={chars[3]}
                    fill={"#449"}
                    align="right"
                    verticalAlign="bottom"
                    width={size}
                    height={size}
                    fontStyle="bold"
                  />
                )}
              </Label>
            );
          }
        });
      })}
    </>
  );
};
