import {
  GeneralSearchQuery,
  EstimationInput,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

export type GeneralSearchObject = {
  queries: GeneralSearchQuery[];
  estimation?: EstimationInput;
  inputDictionary?: string[];
  inputDictionaryFree?: string;
  listDefinitions?: { name: string; content: string; loop: boolean }[];
  version: "1" | "2" | "auto";
  error?: string;
};

export const generalSearchObjFromJson = (json: string): GeneralSearchObject => {
  try {
    const parsed = JSON.parse(json);
    if (Array.isArray(parsed)) {
      return {
        queries: parsed,
        inputDictionary: [],
        inputDictionaryFree: "",
        listDefinitions: [],
        version: "1",
      };
    } else {
      return {
        queries: parsed.queries ?? [],
        estimation: parsed.estimation ?? undefined,
        inputDictionary: parsed.inputDictionary ?? [],
        inputDictionaryFree: parsed.inputDictionaryFree ?? "",
        listDefinitions: parsed.listDefinitions ?? [],
        version: parsed.version ?? "auto",
      };
    }
  } catch (error: any) {
    return {
      queries: [],
      version: "auto",
      error: error.toString(),
    };
  }
};

export const jsonFromGeneralSearchObj = (
  obj: GeneralSearchObject,
  short: boolean = false
): string => {
  if (obj.estimation === undefined) {
    delete obj.estimation;
  }
  if (short) {
    return JSON.stringify(obj);
  }
  return JSON.stringify(obj, null, 2);
};
