import { NodeModel } from "@minoru/react-dnd-treeview";
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { CustomData } from "../../../../../_lib/eniverse/imaginate/treeView/types";
import { FileTypeIcon } from "../../../../../_lib/eniverse/imaginate/TypeIcon";

type KachidokiManagerViewProps = {
  addApp: ({ type, name }: { type: string; name: string }) => void;
  currentNode: NodeModel<CustomData>;
  treeData: NodeModel<CustomData>[];
  setSelectedNode: (node: NodeModel<CustomData>) => void;
};
export const KachidokiManagerView: FC<KachidokiManagerViewProps> = (props) => {
  const { addApp, treeData, setSelectedNode, currentNode } = props;
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        verticalAlign: "bottom",
        mb: 2,
      }}
      useFlexGap
      flexWrap={"wrap"}
    >
      {treeData &&
        treeData
          .filter((node) => node.parent === currentNode.id)
          .map((node) => {
            return (
              <Card sx={{ width: 240 }} key={node.id}>
                <CardActionArea
                  onClick={() => {
                    setSelectedNode(node);
                  }}
                >
                  <CardContent>
                    <FileTypeIcon
                      fileType={""}
                      sx={{
                        fontSize: 80,
                      }}
                    />
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        mt: 1,
                      }}
                    >
                      {node.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
      {treeData &&
        !treeData.some(
          (node) =>
            node.parent === currentNode.id &&
            node.data?.fileType === "image-library"
        ) && (
          <Card
            sx={{
              width: 240,
              bgcolor: "divider",
            }}
            elevation={0}
            variant="outlined"
          >
            <CardActionArea
              onClick={async () => {
                await addApp({
                  type: "image-library",
                  name: "画像ライブラリ",
                });
              }}
            >
              <CardContent>
                <LibraryAddIcon
                  sx={{
                    fontSize: 80,
                  }}
                />
                <Typography gutterBottom variant="h6" component="div">
                  画像ライブラリ初期化
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      {treeData &&
        !treeData.some(
          (node) =>
            node.parent === currentNode.id &&
            node.data?.fileType === "kachidoki-setter"
        ) && (
          <Card
            sx={{
              width: 240,
              bgcolor: "divider",
            }}
            elevation={0}
            variant="outlined"
          >
            <CardActionArea
              onClick={async () => {
                await addApp({
                  type: "kachidoki-setter",
                  name: "問題設定",
                });
              }}
            >
              <CardContent>
                <LibraryAddIcon
                  sx={{
                    fontSize: 80,
                  }}
                />
                <Typography gutterBottom variant="h6" component="div">
                  問題設定初期化
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
    </Stack>
  );
};
