import { FC, useContext, useState } from "react";
import { ImaginateConfigProps, ImaginateContext } from "./ImaginateContext";

export type ImaginateProviderProps = {
  initialImaginateConfig: ImaginateConfigProps;
  children: React.ReactNode;
};

export const ImaginateProvider: FC<ImaginateProviderProps> = ({
  initialImaginateConfig,
  children,
}) => {
  const [imaginateConfig, setImaginateConfig] = useState<ImaginateConfigProps>(
    initialImaginateConfig
  );
  return (
    <ImaginateContext.Provider value={[imaginateConfig, setImaginateConfig]}>
      {children}
    </ImaginateContext.Provider>
  );
};
export const useImaginateConfig = () => {
  const context = useContext(ImaginateContext);
  if (!context) {
    throw new Error(
      "useImaginateConfig must be used within a ImaginateProvider"
    );
  }
  return context;
};
