import { Box } from "@mui/material";
import { StorageReference } from "firebase/storage";
import { FC, useCallback } from "react";
import { ConvertTimestampRecursively } from "../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { CloudItemSelect } from "./CloudItemSelect";
import { CloudDoc, DocFile, Metadata } from "./cloudType";

type Props = {
  selectedDictionaries: string[];
  setSelectedDictionaries: (dictionaries: string[]) => void;
  tabValue: string;
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
  fileListLoading: boolean;
  storageTable: Record<string, StorageReference>;
};

export const CloudItemSelectWrapper: FC<Props> = (props) => {
  const {
    selectedDictionaries,
    setSelectedDictionaries,
    fileListData,
    setFileListData,
    fileListLoading,
    storageTable,
  } = props;

  const selectToggleText = useCallback(
    (doc: DocFile & Metadata, textRef: StorageReference) => {
      const key = textRef.name;
      const dictKey = "user:" + key;
      if (!selectedDictionaries.includes(dictKey)) {
        setSelectedDictionaries([...selectedDictionaries, dictKey]);
        return;
      }
      setSelectedDictionaries(
        selectedDictionaries.filter((d) => d !== dictKey)
      );
    },
    [selectedDictionaries, setSelectedDictionaries]
  );

  if (fileListLoading) {
    return <div>loading</div>;
  }

  return (
    <Box
      sx={{
        mt: 1,
      }}
    >
      <CloudItemSelect
        fileListData={fileListData}
        setFileListData={setFileListData}
        selectedDictionaries={selectedDictionaries}
        setSelectedDictionaries={setSelectedDictionaries}
        storageTable={storageTable}
        onSelect={selectToggleText}
      />
    </Box>
  );
};
