export const symbolRegexPatternReplaceTable = {
    "！": "!",
    "＆": "&",
    "（": "(",
    "）": ")",
    "＋": "+",
    "，": ",",
    "、": ",",
    "－": "-",
    "．": ".",
    "。": ".",
    "／": "/",
    "：": ":",
    "；": ";",
    "＜": "<",
    "＝": "=",
    "＞": ">",
    "？": "?",
    "＠": "@",
    "［": "[",
    "］": "]",
    "「": "[",
    "」": "]",
    "＾": "^",
    "＿": "_",
    "｛": "{",
    "｜": "|",
    "｝": "}",
    "～": "~",
    "％": "%",
    "＃": "#",
    "＄": "$",
    "＊": "*",
    "…": "...",
};
export const symbolRegexReplacementReplaceTable = {
    "＄": "$",
    "＜": "<",
    "＞": ">",
    "（": "(",
    "）": ")",
    "：": ":",
    "；": ";",
    "＆": "&",
    "～": "~",
    "，": ",",
    "、": ",",
};
