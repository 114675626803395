/* eslint-disable no-restricted-globals */
import { permutation } from "js-combinatorics";
import { timeout } from "./lib/timeout";
import { splitGrapheme } from "../wordGenerator/splitGrapheme";
import { workerStatus } from ".";
const numberText = new Set(splitGrapheme("0123456789"));
let progress = {
    startTime: 0,
    currentTime: 0,
    tryCount: 0,
    maxCount: 1,
};
let beforeTime = 0;
const sendProgress = async (forced) => {
    progress.currentTime = Date.now();
    if (forced || beforeTime + 50 < progress.currentTime) {
        await timeout(0);
        self.postMessage({ progress, abort: workerStatus.abort });
        beforeTime = progress.currentTime;
    }
    return workerStatus.abort;
};
export const solveCryptarithm = async (equations, max) => {
    const chars = new Set(splitGrapheme(equations
        .flatMap((eq) => [eq.left, eq.right])
        .map((op) => getAllOperands(op))
        .join("")).filter((c) => !numberText.has(c)));
    const firstChars = new Set(equations
        .flatMap((eq) => [eq.left, eq.right])
        .map((op) => getFirstChars(op))
        .flat()
        .filter((c) => !numberText.has(c))); // 最初の文字を取得
    progress.tryCount = 0;
    progress.maxCount = Number(permutation(10, chars.size));
    const ret = [];
    for (let nums of getPermutations([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], chars.size)) {
        const mapping = tryAssign({}, Array.from(chars), nums, firstChars);
        if (mapping && equations.every((eq) => isSolution(mapping, eq))) {
            ret.push(mapping);
            if (ret.length >= max) {
                return ret;
            }
        }
        ++progress.tryCount;
        if (progress.tryCount % 1000 === 0) {
            await sendProgress();
        }
    }
    return ret;
};
const wordToNumber = (word, mapping) => {
    let numStr = "";
    for (let char of word) {
        if (numberText.has(char)) {
            numStr += char;
            continue;
        }
        char.toLowerCase();
        numStr += mapping[char];
    }
    return parseInt(numStr);
};
const tryAssign = (mapping, remainingChars, remainingNums, firstChars) => {
    if (remainingChars.length === 0) {
        return mapping;
    }
    const char = remainingChars[0];
    for (let i = 0; i < remainingNums.length; i++) {
        // 最初の文字に0を割り当てない
        if (remainingNums[i] === 0 && firstChars.has(char))
            return null;
        const newMapping = { ...mapping, [char]: remainingNums[i] };
        const newRemainingNums = [
            ...remainingNums.slice(0, i),
            ...remainingNums.slice(i + 1),
        ];
        const solution = tryAssign(newMapping, remainingChars.slice(1), newRemainingNums, firstChars);
        if (solution) {
            return solution;
        }
    }
    return null;
};
function* getPermutations(arr, size) {
    const permutation = Array(size).fill(0);
    const used = Array(arr.length).fill(false);
    function* generate(pos) {
        if (pos === size) {
            yield permutation.slice();
            return;
        }
        for (let i = 0; i < arr.length; i++) {
            if (used[i])
                continue;
            used[i] = true;
            permutation[pos] = arr[i];
            yield* generate(pos + 1);
            used[i] = false; // backtrack
        }
    }
    yield* generate(0);
}
const isSolution = (mapping, equation) => {
    const leftValue = calculate(equation.left, mapping);
    const rightValue = calculate(equation.right, mapping);
    switch (equation.operator) {
        case "=":
            return Math.abs(leftValue - rightValue) < 1e-9;
        case "<":
            return leftValue < rightValue;
        case ">":
            return leftValue > rightValue;
        default:
            throw new Error(`Unknown operator: ${equation.operator}`);
    }
};
const calculate = (Term, mapping) => {
    if (typeof Term === "string") {
        return wordToNumber(Term, mapping);
    }
    const nums = Term.children.map((child) => calculate(child, mapping));
    switch (Term.operator) {
        case "+":
            return nums.reduce((a, b) => a + b);
        case "-":
            return nums.reduce((a, b) => a - b);
        case "*":
            return nums.reduce((a, b) => a * b);
        case "/":
            return nums.reduce((a, b) => a / b);
        case "^":
            return nums.reduce((a, b) => Math.pow(a, b));
        case "+#":
            return nums[0];
        case "-#":
            return -nums[0];
        default:
            throw new Error(`Unknown operator: ${Term.operator}`);
    }
};
const getAllOperands = (Term) => {
    if (typeof Term === "string") {
        return Term;
    }
    return Term.children.map((child) => getAllOperands(child)).join("");
};
const getFirstChars = (Term) => {
    if (typeof Term === "string") {
        if (Term.length >= 2) {
            return [Term[0]];
        }
        else {
            return [];
        }
    }
    return Term.children.flatMap((child) => getFirstChars(child));
};
// 演算子の優先順位を定義します。
const precedence = {
    "+": 1,
    "-": 1,
    "*": 2,
    "/": 2,
    "^": 3,
    "+#": 4, // 単項の+は二項の演算子よりも優先順位が高いです
    "-#": 4, // 同様に単項の-も
};
// 数式文字列を逆ポーランド記法に変換します。
const toPostfix = (equation) => {
    let outputQueue = "";
    let operatorStack = [];
    let tokens = equation
        .replace(/\s+/g, "")
        .split(/([\+\-\*\/\^\(\)])/)
        .filter(Boolean);
    tokens.forEach((token, i) => {
        if (token === "+" || token === "-") {
            if (i === 0 || tokens[i - 1] === "(") {
                // token is a unary operator
                operatorStack.push((token + "#"));
            }
            else {
                // token is a binary operator
                while (operatorStack.length > 0 &&
                    precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]) {
                    outputQueue += operatorStack.pop() + " ";
                }
                operatorStack.push(token);
            }
        }
        else if (precedence[token]) {
            // token is an operator
            while (operatorStack.length > 0 &&
                precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]) {
                outputQueue += operatorStack.pop() + " ";
            }
            operatorStack.push(token);
        }
        else if (token === "(") {
            operatorStack.push("(");
        }
        else if (token === ")") {
            while (operatorStack[operatorStack.length - 1] !== "(") {
                outputQueue += operatorStack.pop() + " ";
            }
            operatorStack.pop(); // remove '('
        }
        else {
            // token is an operand
            outputQueue += token + " ";
        }
    });
    while (operatorStack.length > 0) {
        outputQueue += operatorStack.pop() + " ";
    }
    return outputQueue.trim().split(" ");
};
// 逆ポーランド記法を解析して木構造を作成します。
const parsePostfix = (postfix) => {
    let stack = [];
    postfix.forEach((token) => {
        if (token === "+#" || token === "-#") {
            // token is a unary operator
            let operand = stack.pop();
            stack.push({ operator: token, children: [operand] });
        }
        else if (precedence[token]) {
            // token is an operator
            let right = stack.pop();
            let left = stack.pop();
            stack.push({ operator: token, children: [left, right] });
        }
        else {
            // token is an operand
            stack.push(token);
        }
    });
    return stack[0];
};
// 数式文字列をEquationに変換します。
export const parseEquation = (equation) => {
    let [left, operator, right] = equation.split(/([<>=])/).filter(Boolean);
    return {
        left: parsePostfix(toPostfix(left)),
        right: parsePostfix(toPostfix(right)),
        operator,
    };
};
// solveCryptarithm(equations.map((e) => parseEquation(e)));
