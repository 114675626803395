import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import CasinoIcon from "@mui/icons-material/Casino";
import CropIcon from "@mui/icons-material/Crop";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import SaveIcon from "@mui/icons-material/Save";
import SquareIcon from "@mui/icons-material/Square";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import TitleIcon from "@mui/icons-material/Title";

export type ModeType =
  | "artboard"
  | "square"
  | "white-square"
  | "text"
  | "char"
  | "dice"
  | "skeleton"
  | "select"
  | "drag"
  | "save";

export const useMode = () => {
  const [mode, setMode] = useState<ModeType | null>("text");
  const handleMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: ModeType | null
  ) => {
    setMode(newMode);
  };

  return {
    mode,
    handleMode,
  };
};

type Props = {
  mode: ModeType | null;
  handleMode: (
    event: React.MouseEvent<HTMLElement>,
    newMode: ModeType | null
  ) => void;
};

export const ModeSelector:FC<Props> = ({mode, handleMode}) => {

  return (
    <Box>
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={handleMode}
        aria-label="text alignment"
      >
        <ToggleButton value="artboard" aria-label="centered">
          <CropIcon />
        </ToggleButton>
        <ToggleButton value="square" aria-label="right aligned">
          <SquareIcon />
        </ToggleButton>
        <ToggleButton value="white-square" aria-label="right aligned">
          <SquareOutlinedIcon />
        </ToggleButton>
        <ToggleButton value="text" aria-label="centered">
          <TextFormatIcon />
        </ToggleButton>
        <ToggleButton value="char" aria-label="centered">
          <TitleIcon />
        </ToggleButton>
        {false && (
          <ToggleButton value="drag" aria-label="centered">
            <OpenWithIcon />
          </ToggleButton>
        )}
        {false && (
          <ToggleButton value="dice" aria-label="centered">
            <CasinoIcon />
          </ToggleButton>
        )}
        <ToggleButton value="skeleton" aria-label="centered">
          <Grid3x3Icon />
        </ToggleButton>
        <ToggleButton value="save" aria-label="centered">
          <SaveIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}