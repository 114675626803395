import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";

export const SelectedFileCard = memo(
  ({ file, onRemove }: { file: File; onRemove: () => void }) => {
    const [line, setLine] = useState<number | null>(null);
    const [text, setText] = useState<string | null>(null);

    useEffect(() => {
      file.text().then((text) => {
        setLine(text.split("\n").length);
        // 500文字 or 20行
        const shortText = text
          .split("\n")
          .slice(0, 20)
          .join("\n")
          .slice(0, 500);
        if (text.length !== shortText.length) {
          setText(shortText + "\n...");
        } else {
          setText(text);
        }
      });
      return () => {
        setLine(null);
      };
    }, [file]);
    return (
      <Box>
        <Box>
          <Box
            sx={{
              display: "inline-list-item",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              mt: 1,
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={onRemove}
              sx={{
                ml: "auto",
                mr: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
              }}
            >
              {file.name} ({line} 語)
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    );
  }
);
SelectedFileCard.displayName = "SelectedFileCard";
