import {
  dakuToSei,
  kanaToRoman,
  smallToLarge,
  splitGrapheme,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import { Button, Grid } from "@mui/material";
import kuromoji from "kuromoji";
import { FC, useCallback, useContext, useMemo } from "react";
import { jaconv } from "../../../../../../lib/jaconv";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { ConvertProps } from "./ConvertTemplate";

export const TextTypeConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const kuromojiBuilder = useMemo(() => {
    return kuromoji.builder({ dicPath: "/dict" });
  }, []);

  const toYomi = useCallback(
    (text: string) => {
      kuromojiBuilder.build((err, tokenizer) => {
        if (err) {
          console.log(err);
        } else {
          const tokens = tokenizer.tokenize(text);
          console.log(tokens);

          if (tokens.length === 0) {
            return;
          }
          const convertedText = tokens
            .map((e) => {
              if (e.reading) {
                return e.reading;
              }
              return e.surface_form;
            })
            .join("");

          setConverted(jaconv.toHiragana(convertedText));
        }
      });
    },
    [setConverted]
  );

  const toMorphologicalPartition = useCallback(
    (text: string) => {
      kuromojiBuilder.build((err, tokenizer) => {
        if (err) {
          console.log(err);
        } else {
          const tokens = tokenizer.tokenize(text);
          console.log(tokens);

          if (tokens.length === 0) {
            return;
          }
          const convertedText = tokens.map((e) => e.surface_form).join(",");
          setConverted(jaconv.toHiragana(convertedText));
        }
      });
    },
    [setConverted]
  );

  return (
    <Grid item>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(jaconv.toKatakana(pattern));
        }}
      >
        カタカナ
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(jaconv.toHiragana(pattern));
        }}
      >
        ひらがな
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(dakuToSei(pattern));
        }}
      >
        濁音除去
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(smallToLarge(pattern));
        }}
      >
        小書き文字を大きく
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          toYomi(pattern);
        }}
      >
        漢字→かな
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          toMorphologicalPartition(pattern);
        }}
      >
        形態素分割
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(pattern.toLowerCase());
        }}
      >
        小文字
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(pattern.toUpperCase());
        }}
      >
        大文字
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            kanaToRoman(pattern, "hepburn", {
              bmp: true,
              ignoreMark: true,
            }).toLowerCase()
          );
        }}
      >
        ヘボン式ローマ字
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            kanaToRoman(pattern, "kunrei", {
              bmp: true,
              ignoreMark: true,
            }).toLowerCase()
          );
        }}
      >
        訓令式ローマ字
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            kanaToRoman(pattern, "hepburnTyping", {
              bmp: true,
              ignoreMark: true,
            }).toLowerCase()
          );
        }}
        disabled={plan === "normal"}
      >
        ヘボン風ローマ字入力
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            kanaToRoman(pattern, "kunreiTyping", {
              bmp: true,
              ignoreMark: true,
            }).toLowerCase()
          );
        }}
        disabled={plan === "normal"}
      >
        訓令風ローマ字入力
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          const word = splitGrapheme(pattern)
            .map((e) => {
              if (e.charCodeAt(0) >= 0x3041 && e.charCodeAt(0) <= 0x3096) {
                //is hiragana
                return "あ";
              } else if (
                e.charCodeAt(0) >= 0x30a1 &&
                e.charCodeAt(0) <= 0x30f6
              ) {
                // is katakana
                return "ア";
              } else if (
                e.charCodeAt(0) >= 0x4e00 &&
                e.charCodeAt(0) <= 0x9fff
              ) {
                // is kanji
                return "漢";
              } else if (/[A-Z]/.test(e)) {
                // A-Z
                return "A";
              } else if (/[a-z]/.test(e)) {
                // a-z
                return "a";
              }
              return e;
            })
            .join("");
          setConverted(word);
        }}
      >
        テキスト→文字種
      </Button>
    </Grid>
  );
};
