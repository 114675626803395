const normal = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const smallCapitals = [
  "ᴀ",
  "ʙ",
  "ᴄ",
  "ᴅ",
  "ᴇ",
  "ғ",
  "ɢ",
  "ʜ",
  "ɪ",
  "ᴊ",
  "ᴋ",
  "ʟ",
  "ᴍ",
  "ɴ",
  "ᴏ",
  "ᴘ",
  "ǫ",
  "ʀ",
  "s",
  "ᴛ",
  "ᴜ",
  "ᴠ",
  "ᴡ",
  "x",
  "ʏ",
  "ᴢ",
  "ᴀ",
  "ʙ",
  "ᴄ",
  "ᴅ",
  "ᴇ",
  "ғ",
  "ɢ",
  "ʜ",
  "ɪ",
  "ᴊ",
  "ᴋ",
  "ʟ",
  "ᴍ",
  "ɴ",
  "ᴏ",
  "ᴘ",
  "ǫ",
  "ʀ",
  "s",
  "ᴛ",
  "ᴜ",
  "ᴠ",
  "ᴡ",
  "x",
  "ʏ",
  "ᴢ",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const outlineFonts = [
  "𝕒",
  "𝕓",
  "𝕔",
  "𝕕",
  "𝕖",
  "𝕗",
  "𝕘",
  "𝕙",
  "𝕚",
  "𝕛",
  "𝕜",
  "𝕝",
  "𝕞",
  "𝕟",
  "𝕠",
  "𝕡",
  "𝕢",
  "𝕣",
  "𝕤",
  "𝕥",
  "𝕦",
  "𝕧",
  "𝕨",
  "𝕩",
  "𝕪",
  "𝕫",
  "𝔸",
  "𝔹",
  "ℂ",
  "𝔻",
  "𝔼",
  "𝔽",
  "𝔾",
  "ℍ",
  "𝕀",
  "𝕁",
  "𝕂",
  "𝕃",
  "𝕄",
  "ℕ",
  "𝕆",
  "ℙ",
  "ℚ",
  "ℝ",
  "𝕊",
  "𝕋",
  "𝕌",
  "𝕍",
  "𝕎",
  "𝕏",
  "𝕐",
  "ℤ",
  "𝟘",
  "𝟙",
  "𝟚",
  "𝟛",
  "𝟜",
  "𝟝",
  "𝟞",
  "𝟟",
  "𝟠",
  "𝟡",
];

const bubbleBlack = [
  "🅐",
  "🅑",
  "🅒",
  "🅓",
  "🅔",
  "🅕",
  "🅖",
  "🅗",
  "🅘",
  "🅙",
  "🅚",
  "🅛",
  "🅜",
  "🅝",
  "🅞",
  "🅟",
  "🅠",
  "🅡",
  "🅢",
  "🅣",
  "🅤",
  "🅥",
  "🅦",
  "🅧",
  "🅨",
  "🅩",
  "🅐",
  "🅑",
  "🅒",
  "🅓",
  "🅔",
  "🅕",
  "🅖",
  "🅗",
  "🅘",
  "🅙",
  "🅚",
  "🅛",
  "🅜",
  "🅝",
  "🅞",
  "🅟",
  "🅠",
  "🅡",
  "🅢",
  "🅣",
  "🅤",
  "🅥",
  "🅦",
  "🅧",
  "🅨",
  "🅩",
  "⓿",
  "❶",
  "❷",
  "❸",
  "❹",
  "❺",
  "❻",
  "❼",
  "❽",
  "❾",
];

const bubbleWhite = [
  "ⓐ",
  "ⓑ",
  "ⓒ",
  "ⓓ",
  "ⓔ",
  "ⓕ",
  "ⓖ",
  "ⓗ",
  "ⓘ",
  "ⓙ",
  "ⓚ",
  "ⓛ",
  "ⓜ",
  "ⓝ",
  "ⓞ",
  "ⓟ",
  "ⓠ",
  "ⓡ",
  "ⓢ",
  "ⓣ",
  "ⓤ",
  "ⓥ",
  "ⓦ",
  "ⓧ",
  "ⓨ",
  "ⓩ",
  "Ⓐ",
  "Ⓑ",
  "Ⓒ",
  "Ⓓ",
  "Ⓔ",
  "Ⓕ",
  "Ⓖ",
  "Ⓗ",
  "Ⓘ",
  "Ⓙ",
  "Ⓚ",
  "Ⓛ",
  "Ⓜ",
  "Ⓝ",
  "Ⓞ",
  "Ⓟ",
  "Ⓠ",
  "Ⓡ",
  "Ⓢ",
  "Ⓣ",
  "Ⓤ",
  "Ⓥ",
  "Ⓦ",
  "Ⓧ",
  "Ⓨ",
  "Ⓩ",
  "⓪",
  "①",
  "②",
  "③",
  "④",
  "⑤",
  "⑥",
  "⑦",
  "⑧",
  "⑨",
];

const squareBlack = [
  "🅰",
  "🅱",
  "🅲",
  "🅳",
  "🅴",
  "🅵",
  "🅶",
  "🅷",
  "🅸",
  "🅹",
  "🅺",
  "🅻",
  "🅼",
  "🅽",
  "🅾",
  "🅿",
  "🆀",
  "🆁",
  "🆂",
  "🆃",
  "🆄",
  "🆅",
  "🆆",
  "🆇",
  "🆈",
  "🆉",
  "🅰",
  "🅱",
  "🅲",
  "🅳",
  "🅴",
  "🅵",
  "🅶",
  "🅷",
  "🅸",
  "🅹",
  "🅺",
  "🅻",
  "🅼",
  "🅽",
  "🅾",
  "🅿",
  "🆀",
  "🆁",
  "🆂",
  "🆃",
  "🆄",
  "🆅",
  "🆆",
  "🆇",
  "🆈",
  "🆉",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const squareWhite = [
  "🄰",
  "🄱",
  "🄲",
  "🄳",
  "🄴",
  "🄵",
  "🄶",
  "🄷",
  "🄸",
  "🄹",
  "🄺",
  "🄻",
  "🄼",
  "🄽",
  "🄾",
  "🄿",
  "🅀",
  "🅁",
  "🅂",
  "🅃",
  "🅄",
  "🅅",
  "🅆",
  "🅇",
  "🅈",
  "🅉",
  "🄰",
  "🄱",
  "🄲",
  "🄳",
  "🄴",
  "🄵",
  "🄶",
  "🄷",
  "🄸",
  "🄹",
  "🄺",
  "🄻",
  "🄼",
  "🄽",
  "🄾",
  "🄿",
  "🅀",
  "🅁",
  "🅂",
  "🅃",
  "🅄",
  "🅅",
  "🅆",
  "🅇",
  "🅈",
  "🅉",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const boldItalic = [
  "𝙖",
  "𝙗",
  "𝙘",
  "𝙙",
  "𝙚",
  "𝙛",
  "𝙜",
  "𝙝",
  "𝙞",
  "𝙟",
  "𝙠",
  "𝙡",
  "𝙢",
  "𝙣",
  "𝙤",
  "𝙥",
  "𝙦",
  "𝙧",
  "𝙨",
  "𝙩",
  "𝙪",
  "𝙫",
  "𝙬",
  "𝙭",
  "𝙮",
  "𝙯",
  "𝘼",
  "𝘽",
  "𝘾",
  "𝘿",
  "𝙀",
  "𝙁",
  "𝙂",
  "𝙃",
  "𝙄",
  "𝙅",
  "𝙆",
  "𝙇",
  "𝙈",
  "𝙉",
  "𝙊",
  "𝙋",
  "𝙌",
  "𝙍",
  "𝙎",
  "𝙏",
  "𝙐",
  "𝙑",
  "𝙒",
  "𝙓",
  "𝙔",
  "𝙕",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

// And so on for each variable you have...

const bold = [
  "𝗮",
  "𝗯",
  "𝗰",
  "𝗱",
  "𝗲",
  "𝗳",
  "𝗴",
  "𝗵",
  "𝗶",
  "𝗷",
  "𝗸",
  "𝗹",
  "𝗺",
  "𝗻",
  "𝗼",
  "𝗽",
  "𝗾",
  "𝗿",
  "𝘀",
  "𝘁",
  "𝘂",
  "𝘃",
  "𝘄",
  "𝘅",
  "𝘆",
  "𝘇",
  "𝗔",
  "𝗕",
  "𝗖",
  "𝗗",
  "𝗘",
  "𝗙",
  "𝗚",
  "𝗛",
  "𝗜",
  "𝗝",
  "𝗞",
  "𝗟",
  "𝗠",
  "𝗡",
  "𝗢",
  "𝗣",
  "𝗤",
  "𝗥",
  "𝗦",
  "𝗧",
  "𝗨",
  "𝗩",
  "𝗪",
  "𝗫",
  "𝗬",
  "𝗭",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const italic = [
  "𝘢",
  "𝘣",
  "𝘤",
  "𝘥",
  "𝘦",
  "𝘧",
  "𝘨",
  "𝘩",
  "𝘪",
  "𝘫",
  "𝘬",
  "𝘭",
  "𝘮",
  "𝘯",
  "𝘰",
  "𝘱",
  "𝘲",
  "𝘳",
  "𝘴",
  "𝘵",
  "𝘶",
  "𝘷",
  "𝘸",
  "𝘹",
  "𝘺",
  "𝘻",
  "𝘈",
  "𝘉",
  "𝘊",
  "𝘋",
  "𝘌",
  "𝘍",
  "𝘎",
  "𝘏",
  "𝘐",
  "𝘑",
  "𝘒",
  "𝘓",
  "𝘔",
  "𝘕",
  "𝘖",
  "𝘗",
  "𝘘",
  "𝘙",
  "𝘚",
  "𝘛",
  "𝘜",
  "𝘝",
  "𝘞",
  "𝘟",
  "𝘠",
  "𝘡",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const serifBoldItalic = [
  "𝒂",
  "𝒃",
  "𝒄",
  "𝒅",
  "𝒆",
  "𝒇",
  "𝒈",
  "𝒉",
  "𝒊",
  "𝒋",
  "𝒌",
  "𝒍",
  "𝒎",
  "𝒏",
  "𝒐",
  "𝒑",
  "𝒒",
  "𝒓",
  "𝒔",
  "𝒕",
  "𝒖",
  "𝒗",
  "𝒘",
  "𝒙",
  "𝒚",
  "𝒛",
  "𝑨",
  "𝑩",
  "𝑪",
  "𝑫",
  "𝑬",
  "𝑭",
  "𝑮",
  "𝑯",
  "𝑰",
  "𝑱",
  "𝑲",
  "𝑳",
  "𝑴",
  "𝑵",
  "𝑶",
  "𝑷",
  "𝑸",
  "𝑹",
  "𝑺",
  "𝑻",
  "𝑼",
  "𝑽",
  "𝑾",
  "𝑿",
  "𝒀",
  "𝒁",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const serifBold = [
  "𝐚",
  "𝐛",
  "𝐜",
  "𝐝",
  "𝐞",
  "𝐟",
  "𝐠",
  "𝐡",
  "𝐢",
  "𝐣",
  "𝐤",
  "𝐥",
  "𝐦",
  "𝐧",
  "𝐨",
  "𝐩",
  "𝐪",
  "𝐫",
  "𝐬",
  "𝐭",
  "𝐮",
  "𝐯",
  "𝐰",
  "𝐱",
  "𝐲",
  "𝐳",
  "𝐀",
  "𝐁",
  "𝐂",
  "𝐃",
  "𝐄",
  "𝐅",
  "𝐆",
  "𝐇",
  "𝐈",
  "𝐉",
  "𝐊",
  "𝐋",
  "𝐌",
  "𝐍",
  "𝐎",
  "𝐏",
  "𝐐",
  "𝐑",
  "𝐒",
  "𝐓",
  "𝐔",
  "𝐕",
  "𝐖",
  "𝐗",
  "𝐘",
  "𝐙",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const serifItalic = [
  "𝑎",
  "𝑏",
  "𝑐",
  "𝑑",
  "𝑒",
  "𝑓",
  "𝑔",
  "𝒉",
  "𝑖",
  "𝑗",
  "𝑘",
  "𝑙",
  "𝑚",
  "𝑛",
  "𝑜",
  "𝑝",
  "𝑞",
  "𝑟",
  "𝑠",
  "𝑡",
  "𝑢",
  "𝑣",
  "𝑤",
  "𝑥",
  "𝑦",
  "𝑧",
  "𝐴",
  "𝐵",
  "𝐶",
  "𝐷",
  "𝐸",
  "𝐹",
  "𝐺",
  "𝐻",
  "𝐼",
  "𝐽",
  "𝐾",
  "𝐿",
  "𝑀",
  "𝑁",
  "𝑂",
  "𝑃",
  "𝑄",
  "𝑅",
  "𝑆",
  "𝑇",
  "𝑈",
  "𝑉",
  "𝑊",
  "𝑋",
  "𝑌",
  "𝑍",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const frakturBold = [
  "𝖆",
  "𝖇",
  "𝖈",
  "𝖉",
  "𝖊",
  "𝖋",
  "𝖌",
  "𝖍",
  "𝖎",
  "𝖏",
  "𝖐",
  "𝖑",
  "𝖒",
  "𝖓",
  "𝖔",
  "𝖕",
  "𝖖",
  "𝖗",
  "𝖘",
  "𝖙",
  "𝖚",
  "𝖛",
  "𝖜",
  "𝖝",
  "𝖞",
  "𝖟",
  "𝕬",
  "𝕭",
  "𝕮",
  "𝕯",
  "𝕰",
  "𝕱",
  "𝕲",
  "𝕳",
  "𝕴",
  "𝕵",
  "𝕶",
  "𝕷",
  "𝖒",
  "𝕹",
  "𝕺",
  "𝕻",
  "𝕼",
  "𝕽",
  "𝕾",
  "𝖙",
  "𝖀",
  "𝖁",
  "𝖂",
  "𝖃",
  "𝖄",
  "𝖅",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const fraktur = [
  "𝔞",
  "𝔟",
  "𝔠",
  "𝔡",
  "𝔢",
  "𝔣",
  "𝔤",
  "𝔥",
  "𝔦",
  "𝔧",
  "𝔨",
  "𝔩",
  "𝔪",
  "𝔫",
  "𝔬",
  "𝔭",
  "𝔮",
  "𝔯",
  "𝔰",
  "𝔱",
  "𝔲",
  "𝔳",
  "𝔴",
  "𝔵",
  "𝔶",
  "𝔷",
  "𝔄",
  "𝔅",
  "ℭ",
  "𝔇",
  "𝔈",
  "𝔉",
  "𝔊",
  "ℌ",
  "ℑ",
  "𝔍",
  "𝔎",
  "𝔏",
  "𝔐",
  "𝔑",
  "𝔒",
  "𝔓",
  "𝔔",
  "ℜ",
  "𝔖",
  "𝔗",
  "𝔘",
  "𝔙",
  "𝔚",
  "𝔛",
  "𝔜",
  "ℨ",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const hieroGlyph = [
  "𓄿",
  "𓃀",
  "𓎡",
  "𓂧",
  "𓇋",
  "𓆑",
  "𓎼",
  "𓎛",
  "𓇋",
  "𓆓",
  "𓎡",
  "𓃭",
  "𓅓",
  "𓈖",
  "𓍯",
  "𓏤",
  "𓏘",
  "𓂋",
  "𓋴",
  "𓏏",
  "𓅱",
  "𓆑",
  "𓅱",
  "𓎡",
  "𓋴",
  "𓇋",
  "𓊃",
  "𓄿",
  "𓃀",
  "𓎡",
  "𓂧",
  "𓇋",
  "𓆑",
  "𓎼",
  "𓎛",
  "𓇋",
  "𓆓",
  "𓎡",
  "𓃭",
  "𓅓",
  "𓈖",
  "𓍯",
  "𓏤",
  "𓏘",
  "𓂋",
  "𓋴",
  "𓏏",
  "𓅱",
  "𓆑",
  "𓅱",
  "𓎡",
  "𓋴",
  "𓇋",
  "𓊃",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const cursiveBold = [
  "𝓪",
  "𝓫",
  "𝓬",
  "𝓭",
  "𝓮",
  "𝓯",
  "𝓰",
  "𝓱",
  "𝓲",
  "𝓳",
  "𝓴",
  "𝓵",
  "𝓶",
  "𝓷",
  "𝓸",
  "𝓹",
  "𝓺",
  "𝓻",
  "𝓼",
  "𝓽",
  "𝓾",
  "𝓿",
  "𝔀",
  "𝔁",
  "𝔂",
  "𝔃",
  "𝓐",
  "𝓑",
  "𝓒",
  "𝓓",
  "𝓔",
  "𝓕",
  "𝓖",
  "𝓗",
  "𝓘",
  "𝓙",
  "𝓚",
  "𝓛",
  "𝓜",
  "𝓝",
  "𝓞",
  "𝓟",
  "𝓠",
  "𝓡",
  "𝓢",
  "𝓣",
  "𝓤",
  "𝓥",
  "𝓦",
  "𝓧",
  "𝓨",
  "𝓩",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const cursive = [
  "𝒶",
  "𝒷",
  "𝒸",
  "𝒹",
  "𝑒",
  "𝒻",
  "𝑔",
  "𝒽",
  "𝒾",
  "𝒿",
  "𝓀",
  "𝓁",
  "𝓂",
  "𝓃",
  "𝑜",
  "𝓅",
  "𝓆",
  "𝓇",
  "𝓈",
  "𝓉",
  "𝓊",
  "𝓋",
  "𝓍",
  "𝓎",
  "𝓏",
  "𝒜",
  "ℬ",
  "𝒞",
  "𝒟",
  "ℰ",
  "ℱ",
  "𝒢",
  "ℋ",
  "ℐ",
  "𝒥",
  "𝒦",
  "ℒ",
  "ℳ",
  "𝒩",
  "𝒪",
  "𝒫",
  "𝒬",
  "ℛ",
  "𝒮",
  "𝒯",
  "𝒰",
  "𝒱",
  "𝒲",
  "𝒳",
  "𝒴",
  "𝒵",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const upsideDown = [
  "ɐ",
  "q",
  "ɔ",
  "p",
  "ǝ",
  "ɟ",
  "ƃ",
  "ɥ",
  "ı",
  "ɾ",
  "ʞ",
  "ן",
  "ɯ",
  "u",
  "o",
  "d",
  "b",
  "ɹ",
  "s",
  "ʇ",
  "n",
  "ʌ",
  "ʍ",
  "x",
  "ʎ",
  "z",
  "Ɐ",
  "ꓭ",
  "Ɔ",
  "ꓷ",
  "Ǝ",
  "Ⅎ",
  "ꓨ",
  "H",
  "I",
  "ſ",
  "ꓘ",
  "ꓶ",
  "W",
  "N",
  "O",
  "Ԁ",
  "O",
  "̀",
  "ꓤ",
  "S",
  "ꓕ",
  "ꓵ",
  "ꓥ",
  "M",
  "X",
  "⅄",
  "Z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const fontList = [
  normal,
  smallCapitals,
  outlineFonts,
  bubbleBlack,
  bubbleWhite,
  squareBlack,
  squareWhite,
  boldItalic,
  bold,
  italic,
  serifBoldItalic,
  serifBold,
  serifItalic,
  frakturBold,
  fraktur,
  hieroGlyph,
  cursiveBold,
  cursive,
  upsideDown,
];

const fontTable: { [key: string]: string[] } = {
  normal: normal,
  smallCapitals: smallCapitals,
  outlineFonts: outlineFonts,
  bubbleBlack: bubbleBlack,
  bubbleWhite: bubbleWhite,
  squareBlack: squareBlack,
  squareWhite: squareWhite,
  boldItalic: boldItalic,
  bold: bold,
  italic: italic,
  serifBoldItalic: serifBoldItalic,
  serifBold: serifBold,
  serifItalic: serifItalic,
  frakturBold: frakturBold,
  fraktur: fraktur,
  hieroGlyph: hieroGlyph,
  cursiveBold: cursiveBold,
  cursive: cursive,
  upsideDown: upsideDown,
};

export const fontConvert = (text: string, font: string) => {
  const result = toNormal(text).replace(/[A-Za-z0-9]/g, (s) => {
    const index = normal.indexOf(s);
    if (index >= 0) {
      return fontTable[font][index];
    } else {
      return s;
    }
  });
  return result;
};

export const toNormal = (text: string) => {
  fontList.forEach((font) => {
    text = text.replace(new RegExp("(" + font.join("|") + ")", "g"), (s) => {
      const index = font.indexOf(s);
      if (normal.indexOf(s) >= 0) {
        return s;
      }
      console.log(s, font, index, normal[index]);
      if (index >= 0) {
        return normal[index];
      }
      return s;
    });
  });
  console.log(text);
  return text;
};
