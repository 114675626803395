import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { useWorker } from "../../../hooks/useWorker";
import { ResultList } from "../common/Result";
import { PlainText } from "../common/PlainText";
import { Title } from "../common/Title";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import { DemoModeMessage } from "../commonStudio/DemoModeMessage";
import { DictionaryKey, dictionaries } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const WordMatching: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  const [keywords, setKeywords] = useState("");
  const [wordLength, setWordLength] = useState(0);
  const [dictionary, setDictionary] = useState("");
  const [result, setResult] = useState<string[]>([]);
  const [searching, setSearching] = useState(false);
  const worker = useWorker();
  const onSearch = useCallback(() => {
    setSearching(true);
    worker.wordMatching(
      keywords,
      wordLength,
      dictionary as DictionaryKey,
      (result) => {
        if (result) {
          setResult(result);
          setSearching(false);
        }
      }
    );
  }, [keywords, wordLength, dictionary, worker]);

  const handleChangeDictionary = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDictionary((event.target as HTMLInputElement).value);
    },
    []
  );

  const plan = ctx.plan;
  const isDemo = plan === "normal";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>マッチングツール</Title>
      <PlainText>
        カンマ区切りで単語を入力すると、それぞれの単語から1文字ずつ拾ってできる単語を出力します
      </PlainText>
      <PlainText>"*"は任意の1文字を表します。</PlainText>
      <DemoModeMessage isDemo={isDemo} />
      <TextField
        label="Keywords"
        variant="outlined"
        sx={{ width: "80vw", mt: 3 }}
        value={keywords}
        onChange={(event) => {
          setKeywords(event.target.value);
        }}
        disabled={isDemo}
      />
      <TextField
        type="number"
        label="文字数"
        variant="outlined"
        sx={{ mt: 2 }}
        value={wordLength}
        onChange={(event) => {
          setWordLength(parseInt(event.target.value));
        }}
        disabled={isDemo}
      />
      <Box>
        <FormControl sx={{ mt: 2 }} disabled={isDemo}>
          <FormLabel id="row-radio-buttons-group-label">辞書</FormLabel>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={dictionary}
            onChange={handleChangeDictionary}
          >
            {ctx.enabledDictionary
              .sort(
                (a, b) =>
                  dictionaries[a as DictionaryKey].index -
                  dictionaries[b as DictionaryKey].index
              )
              .map((key) => (
                <FormControlLabel
                  value={key}
                  control={<Radio />}
                  label={dictionaries[key as DictionaryKey].name}
                  key={key}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <Button variant="contained" onClick={onSearch} disabled={!ctx.loaded}>
          検索
        </Button>
      </Box>
      <ResultList items={result} loading={searching} />
    </Container>
  );
};
