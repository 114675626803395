import {
  GeneralSearchQuery,
  RegexReplace,
  replaceSign,
  Sequence,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import { Stack, TextField } from "@mui/material";
import Mexp from "math-expression-evaluator";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BasicFormProps } from "../../FormProps";
import { RegexReplaceForm } from "../../form/RegexReplaceForm";

export const VariableParallelTransformForm: FC<BasicFormProps<Sequence>> = ({
  index,
  query,
  updateQuery,
  deleteQueryField,
  isDemo,
  onBlur,
}) => {
  const mexp = useMemo(() => new Mexp(), []);
  const [replaceQuery, setReplaceQuery] = useState<RegexReplace>(
    query.option?.replaceQuery || {
      type: "regexReplace",
      pattern: "",
      replacement: "",
      matchOnly: false,
    }
  );

  const updateReplaceQuery = useCallback(
    (index: number, field: string, value: any) => {
      (replaceQuery as any)[field] = value;
      setReplaceQuery({ ...replaceQuery });
    },
    [replaceQuery, setReplaceQuery]
  );

  const [variableName, setVariableName] = useState<string>(
    query.option?.variableName || "@@"
  );
  const [startValue, setStartValue] = useState<string>(
    query.option?.startValue || "0"
  );
  const [endValue, setEndValue] = useState<string>(
    query.option?.endValue || "8"
  );

  const update = useCallback(() => {
    if (!/^@.+/.test(variableName)) {
      return;
    }
    try {
      const processes: GeneralSearchQuery[] = [];
      for (
        let i = parseInt(startValue || "0");
        i < parseInt(endValue || "8") + 1;
        i++
      ) {
        const pattern = replaceQuery.pattern
          .replaceAll(variableName, i.toString())
          .replace(/\{([^}]*)\}/g, (match, p1: string) => {
            const splitted = p1.split(",");
            const bracketCountArray = splitted.map((v) => {
              return (
                (v.match(/\(/g) || []).length - (v.match(/\)/g) || []).length
              );
            });
            const bracketSumArray = bracketCountArray.reduce(
              (acc: number[], v, i) => {
                if (i === 0) {
                  return [v];
                }
                return acc.concat(acc[i - 1] + v);
              },
              []
            );

            const formula = splitted
              .reduce(
                (acc: string[][], v, i) => {
                  if (bracketSumArray[i - 1] === 0) {
                    acc.push([]);
                  }
                  acc[acc.length - 1].push(v);
                  return acc;
                },
                [[]] as string[][]
              )
              .map((v) => v.join(","));
            return `{${formula
              .map((v: string) => mexp.eval(replaceSign(v), [], {}))
              .join(",")}}`;
          })
          .replace(/@\{([^}]*)\}/g, "$1");

        const replacement = replaceQuery.replacement
          .replaceAll(variableName, i.toString())
          .replace(/\{([^}]*)\}/g, (match, p1: string) => {
            const splitted = p1.split(",");
            const bracketCountArray = splitted.map((v) => {
              return (
                (v.match(/\(/g) || []).length - (v.match(/\)/g) || []).length
              );
            });
            const bracketSumArray = bracketCountArray.reduce(
              (acc: number[], v, i) => {
                if (i === 0) {
                  return [v];
                }
                return acc.concat(acc[i - 1] + v);
              },
              []
            );

            const formula = splitted
              .reduce(
                (acc: string[][], v, i) => {
                  if (bracketSumArray[i - 1] === 0) {
                    acc.push([]);
                  }
                  acc[acc.length - 1].push(v);
                  return acc;
                },
                [[]] as string[][]
              )
              .map((v) => v.join(","));
            return `{${formula
              .map((v: string) => mexp.eval(replaceSign(v), [], {}))
              .join(",")}}`;
          })
          .replace(/@\{([^}]*)\}/g, "$1");

        processes.push({
          type: "regexReplace",
          pattern: pattern,
          replacement: replacement,
          matchOnly: replaceQuery.matchOnly,
        });
      }

      const newQuery = {
        option: {
          variableName: variableName,
          startValue: startValue,
          endValue: endValue,
          replaceQuery: replaceQuery,
        },
        processes: [
          {
            type: "parallelTransform",
            processes: processes,
          },
        ],
      };
      updateQuery(index, "processes", newQuery.processes);
      updateQuery(index, "option", newQuery.option);
      if (onBlur) onBlur();
    } catch (e) {
      console.log(e);
    }
  }, [
    variableName,
    startValue,
    endValue,
    replaceQuery,
    updateQuery,
    index,
    onBlur,
    mexp,
  ]);

  useEffect(() => {
    console.log(query.processes, startValue, endValue);
    if (
      !query.processes ||
      query.processes.length === 0 ||
      (query.processes[0].type === "parallelTransform" &&
        query.processes[0].processes.length === 0 &&
        startValue !== endValue)
    ) {
      update();
    }
  }, [endValue, query.processes, startValue, update]);

  return (
    <>
      <Stack>
        <Stack direction="row" sx={{ mt: 2 }}>
          <TextField
            label="変数名"
            variant="outlined"
            value={variableName || ""}
            onChange={(e) => {
              setVariableName(e.target.value);
            }}
            onBlur={update}
            sx={{ ml: 1, width: "25%", maxWidth: "200px" }}
            disabled={isDemo}
            error={!/^@.+/.test(variableName)}
            helperText="@から始まる文字列"
          />
          <TextField
            label="初期値"
            variant="outlined"
            value={startValue || ""}
            inputMode="numeric"
            onChange={(e) => {
              setStartValue(e.target.value);
            }}
            onBlur={update}
            sx={{ ml: 1, width: "25%", maxWidth: "80px" }}
            disabled={isDemo}
          />
          <TextField
            label="終了値"
            variant="outlined"
            value={endValue || ""}
            inputMode="numeric"
            onChange={(e) => {
              setEndValue(e.target.value);
            }}
            onBlur={update}
            sx={{ ml: 1, width: "25%", maxWidth: "80px" }}
            disabled={isDemo}
          />
        </Stack>
        <RegexReplaceForm
          index={0}
          query={replaceQuery}
          updateQuery={updateReplaceQuery}
          deleteQueryField={deleteQueryField}
          onBlur={update}
          isDemo={isDemo}
        />
      </Stack>
    </>
  );
};
