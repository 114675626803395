export const pokemon3 = `キモリ
ジュプトル
ジュカイン
アチャモ
ワカシャモ
バシャーモ
ミズゴロウ
ヌマクロー
ラグラージ
ポチエナ
グラエナ
ジグザグマ
マッスグマ
ケムッソ
カラサリス
アゲハント
マユルド
ドクケイル
ハスボー
ハスブレロ
ルンパッパ
タネボー
コノハナ
ダーテング
スバメ
オオスバメ
キャモメ
ペリッパー
ラルトス
キルリア
サーナイト
アメタマ
アメモース
キノココ
キノガッサ
ナマケロ
ヤルキモノ
ケッキング
ツチニン
テッカニン
ヌケニン
ゴニョニョ
ドゴーム
バクオング
マクノシタ
ハリテヤマ
ルリリ
ノズパス
エネコ
エネコロロ
ヤミラミ
クチート
ココドラ
コドラ
ボスゴドラ
アサナン
チャーレム
ラクライ
ライボルト
プラスル
マイナン
バルビート
イルミーゼ
ロゼリア
ゴクリン
マルノーム
キバニア
サメハダー
ホエルコ
ホエルオー
ドンメル
バクーダ
コータス
バネブー
ブーピッグ
パッチール
ナックラー
ビブラーバ
フライゴン
サボネア
ノクタス
チルット
チルタリス
ザングース
ハブネーク
ルナトーン
ソルロック
ドジョッチ
ナマズン
ヘイガニ
シザリガー
ヤジロン
ネンドール
リリーラ
ユレイドル
アノプス
アーマルド
ヒンバス
ミロカロス
ポワルン
カクレオン
カゲボウズ
ジュペッタ
ヨマワル
サマヨール
トロピウス
チリーン
アブソル
ソーナノ
ユキワラシ
オニゴーリ
タマザラシ
トドグラー
トドゼルガ
パールル
ハンテール
サクラビス
ジーランス
ラブカス
タツベイ
コモルー
ボーマンダ
ダンバル
メタング
メタグロス
レジロック
レジアイス
レジスチル
ラティアス
ラティオス
カイオーガ
グラードン
レックウザ
ジラーチ
デオキシス`;
