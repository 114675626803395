import { Text as PdfText } from "@react-pdf/renderer";
import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { StackData, StackTextData } from "../stackData";

export const Text: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "text"}>
      <PdfText style={{ fontSize: 10, fontWeight: "thin" }}>
        {(contents as StackTextData).text}
      </PdfText>
    </If>
  );
};
