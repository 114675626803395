import { checkWordMatching } from "../../lib/bipartiteMatching";
import { estimateFilter, filter } from "../commonLoop";
export const fishBoneFilter = async ({ query, input, result, workerContext, }) => {
    const keywords = (query.keyword ?? "")
        .replace(/\s+/g, ",")
        .replace(/\//g, ",")
        .split(",");
    let regex = null;
    let regexTable = {};
    const filterFunc = (e) => {
        if (query.ordered) {
            if (query.partialMatch) {
                if (query.ordered) {
                    // 順番固定 & 連続 & 部分一致
                    if (regexTable[e.currentWord.length] === undefined) {
                        const regexList = [];
                        for (let i = 0; i < keywords.length - e.currentWord.length + 1; ++i) {
                            const regex = new RegExp("^" +
                                keywords
                                    .slice(i, i + e.currentWord.length)
                                    .map((e) => `[${e}]`)
                                    .join("") +
                                "$");
                            regexList.push(regex);
                        }
                        regexTable[e.currentWord.length] = regexList;
                    }
                    const regexList = regexTable[e.currentWord.length];
                    const flag = regexList.some((regex) => {
                        return e.currentWord.match(regex);
                    });
                    return flag;
                }
                else {
                    // 順番固定 & 非連続 & 部分一致
                    if (regex === null) {
                        regex = new RegExp("^" +
                            keywords.map((e) => (e === "*" ? ".?" : `[${e}]?`)).join("") +
                            "$");
                    }
                    return !!e.currentWord.match(regex);
                }
            }
            else {
                // 順番固定 & 完全一致
                if (regex === null) {
                    regex = new RegExp("^" +
                        keywords.map((e) => (e === "*" ? "." : `[${e}]`)).join("") +
                        "$");
                }
                return !!e.currentWord.match(regex);
            }
        }
        else {
            if (query.partialMatch) {
                if (query.consecutively) {
                    // 非順番 & 連続 & 部分一致
                    if (e.currentWord.length > keywords.length) {
                        return false;
                    }
                    let flag = false;
                    for (let i = 0; i < keywords.length - e.currentWord.length + 1; ++i) {
                        const partialKeywords = keywords.slice(i, i + e.currentWord.length);
                        flag =
                            flag ||
                                checkWordMatching(e.currentWord, partialKeywords, e.currentWord.length).match;
                    }
                    return flag;
                }
                else {
                    // 非順番 & 非連続 & 部分一致
                    if (e.currentWord.length > keywords.length) {
                        return false;
                    }
                    const flag = checkWordMatching(e.currentWord, keywords, e.currentWord.length).match;
                    return flag;
                }
            }
            else {
                // 非順番 & 完全一致
                if (e.currentWord.length !== keywords.length) {
                    return false;
                }
                return checkWordMatching(e.currentWord, keywords, e.currentWord.length)
                    .match;
            }
        }
    };
    if (input.estimation) {
        return estimateFilter(workerContext, result, filterFunc, input.estimation, query);
    }
    return filter(workerContext, result, filterFunc);
};
