"use client";
import {
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useImaginateConfig } from "../../imaginate/ImaginateProvider";
import { useSiteConfig } from "../../EniverseProvider";
import { CommonAppProp } from "../../imaginate/mainView/CommponAppProp";
import { useDoc } from "../../storekit/firestore/pure/useDoc";
import { GeneralFormBuilder, TypeData } from "./lib/GeneralFormBuilder";

export type ProblemData = {
  index: string;
  kind: string;
  author: string;
  url: string;
  image: string;
  isChecked: boolean;
  checkedBy: string;
  difficulty: number;
  code?: string;
};

const typeData: TypeData = [
  { name: "kind", type: "string" },
  { name: "author", type: "string" },
  { name: "url", type: "string" },
  { name: "image", type: "string" },
  { name: "isChecked", type: "boolean" },
  { name: "checkedBy", type: "string" },
  { name: "answerKey", type: "string" },
  {
    name: "difficulty",
    type: "number",
  },
];

const initialData: ProblemData = {
  index: "",
  kind: "",
  author: "",
  url: "",
  image: "",
  isChecked: false,
  checkedBy: "",
  difficulty: 0,
};

export const PuzsqContestProblemItemView: FC<CommonAppProp> = (props) => {
  const { currentNode, updateNode } = props;

  const [contents, _setContents] = useState<ProblemData>(initialData);

  const [{ serviceId }] = useSiteConfig();
  const [{ projectId }] = useImaginateConfig();
  const path = `/workspace/${serviceId}/project/${projectId}/contents/${currentNode.data?.contentsId}`;
  const [serverContents, setServerContents] = useDoc<ProblemData>({
    path,
  });

  useEffect(() => {
    if (serverContents) {
      _setContents({ ...initialData, ...serverContents });
    }
  }, [serverContents]);

  const setContents = (newContents: Partial<ProblemData>) => {
    _setContents({ ...contents, ...newContents });
    console.log({ ...contents, ...newContents }, "newContents");
  };
  const saveServerContents = () => {
    setServerContents(contents);
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(currentNode.data?.contentsId ?? "");
  };

  return (
    <>
      <TextField
        id="filled-read-only-input"
        label={"id"}
        value={currentNode.data?.contentsId}
        InputProps={{
          readOnly: true,
        }}
        sx={{ width: "100%", cursor: "pointer", mb: 1 }}
        variant="filled"
        onClick={handleClick}
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={"コピーしました"}
      />
      <Grid container>
        <Grid item xs={8} sm={6}>
          <GeneralFormBuilder
            content={contents}
            setContent={setContents}
            label={"puzzle"}
            typeData={typeData}
          />
          <Button onClick={saveServerContents} variant="contained" sx={{
            mt: 2
          }}>
            save
          </Button>
        </Grid>
        <Grid item xs={4} sm={6}>
          {
            <Stack spacing={1}>
              {contents.image && (
                <img
                  src={contents.image}
                  alt="puzzle"
                  width={"100%"}
                  style={{
                    maxWidth: 300,
                  }}
                />
              )}
              {(contents.url ?? "").startsWith("https://puzz.link") && (
                <iframe
                  src={contents.url}
                  title="puzzle"
                  height={"600"}
                  width={"166%"}
                  style={{
                    transformOrigin: "0 0",
                    transform: "scale(0.6)",
                    overflow: "hidden",
                    margin: 0,
                    padding: 0,
                  }}
                />
              )}
            </Stack>
          }
        </Grid>
      </Grid>
    </>
  );
};
