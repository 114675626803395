import { progress } from "../generalSearchProgress";
import { generalSearchFuncTable, } from "./generalSearchFunctions";
export const parallelFilter = async (args) => {
    const { query: _query, input, words, listDefinitionsTable, result, workerContext, } = args;
    const query = _query;
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount = 0;
    const ret = [];
    for (const q of query.processes) {
        q.unmatchEstimationOperator = query.unmatchEstimationOperator;
        q.unmatchEstimationValue = query.unmatchEstimationValue;
        const res = await generalSearchFuncTable[q.type]({
            query: q,
            input,
            words,
            listDefinitionsTable,
            result,
            workerContext,
        });
        ret.push(res);
    }
    if (query.unmatchEstimationOperator) {
        ret.push(result.map((e) => {
            const currentEstimation = e.currentEstimation ?? 1;
            let nextEstimation;
            if (query.unmatchEstimationOperator === "add") {
                nextEstimation =
                    currentEstimation + parseFloat(query.unmatchEstimationValue ?? "0");
            }
            else {
                nextEstimation =
                    currentEstimation * parseFloat(query.unmatchEstimationValue ?? "0");
            }
            const newE = {
                ...e,
                currentEstimation: nextEstimation,
            };
            return newE;
        }));
    }
    const resultSet = new Set();
    ret.forEach((e) => {
        e.forEach((ee) => {
            resultSet.add(ee);
        });
    });
    return Array.from(resultSet);
};
