import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import {
  blue,
  brown,
  cyan,
  green,
  indigo,
  lightGreen,
  orange,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsRegex } from "react-icons/bs";
import { useStatePersist } from "use-state-persist";
import { BasicFormProps } from "../../FormProps";
import { Sequence, splitGrapheme, symbolRegexPatternReplaceTable } from "@langue-de-chat-llc/enigmastudio-algorithm";

const numberColors: {
  [key: number]: string;
} = {
  1: red[500],
  2: indigo[500],
  3: green[500],
  4: orange[500],
  5: purple[500],
  6: cyan[500],
  7: yellow[700],
  8: brown[500],
  9: blue[500],
  10: lightGreen[500],
};

const captureText: {
  [key: number]: string;
} = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
  10: "⑩",
};

const captureRegex: {
  [key: number]: string;
} = {
  1: "\\1",
  2: "\\2",
  3: "\\3",
  4: "\\4",
  5: "\\5",
  6: "\\6",
  7: "\\7",
  8: "\\8",
  9: "\\9",
  10: "\\10",
};

type CharacterObject = {
  character: string;
  min?: string;
  max?: string;
  capture?: boolean;
};

type SearchObject = {
  characterObjects: CharacterObject[];
  matchMode: "match" | "contain" | "start" | "end";
  mode: "simple" | "detail";
};

export const SimpleSearchTopForm: FC<BasicFormProps<Sequence>> = ({
  index,
  query,
  updateQuery,
  isDemo,
  onBlur,
  search,
}) => {
  const [sampleText, setSampleText] = useState<string>(
    query.option?.characterObjects
      ?.map((c: CharacterObject) => c.character)
      .join("") ?? ""
  );
  const [searchObject, setSearchObject] = useState<SearchObject>(
    query.option ?? {
      characterObjects: [],
      matchMode: "match",
      mode: "simple",
    }
  );

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const textRef = useRef<HTMLInputElement>(null);
  const [regexPattern, setRegexPattern] = useState("");
  const [regexInputMode, setRegexInputMode] = useState(false);
  const [cursor, setCursor] = useState<number>(0);
  const [notMatch, setNotMatch] = useState<boolean>(false);

  const regexMatchMode = useMemo(() => {
    if (regexPattern.startsWith("^") && regexPattern.endsWith("$")) {
      return "match";
    }
    if (regexPattern.startsWith("^")) {
      return "start";
    }
    if (regexPattern.endsWith("$")) {
      return "end";
    }
    return "contain";
  }, [regexPattern]);

  useEffect(() => {
    textRef.current?.focus();
  }, []);

  const searchObjectToRegex = useCallback((searchObject: SearchObject) => {
    const { matchMode } = searchObject;

    const regex =
      (matchMode === "match" || matchMode === "start" ? "^" : "") +
      searchObject.characterObjects
        .map((c) => {
          let chars = c.character;
          if (c.capture) {
            chars = "(" + chars + ")";
          }
          return chars;
        })
        .join("") +
      (matchMode === "match" || matchMode === "end" ? "$" : "");
    return regex;
  }, []);

  const update = useCallback(() => {
    const regex = regexInputMode
      ? regexPattern
      : searchObjectToRegex(searchObject);
    updateQuery(index, "processes", [
      {
        type: "regex",
        pattern: regex,
        notMatch,
      },
    ]);
    updateQuery(index, "option", searchObject);
    if (onBlur) onBlur();
  }, [searchObject, updateQuery, index, notMatch, onBlur]);

  useEffect(() => {
    if (!query.processes || query.processes.length === 0) {
      update();
    }
  }, [query.processes, update]);

  useEffect(() => {
    update();
  }, [cursor, searchObject]);

  const isSmallSize = !useMediaQuery("(min-width:900px)");

  const handleClickRegex = () => {
    if (!regexInputMode) {
      const regex = searchObjectToRegex(searchObject);
      setRegexPattern(regex);
    }
    setRegexInputMode((show) => !show);
    setTimeout(() => {
      textRef.current?.focus();
      if (
        searchObject.matchMode === "start" ||
        searchObject.matchMode === "match"
      ) {
        textRef.current?.setSelectionRange(1, 1);
      } else {
        textRef.current?.setSelectionRange(0, 0);
      }
    }, 0);
  };

  const handleMouseDownInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleInsert = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      symbol: string,
      cursor?: number
    ) => {
      event.preventDefault(); // フォーカスの喪失を防ぐ
      const _pattern = regexPattern || "";

      const start = textRef.current?.selectionStart || 0;
      const end = textRef.current?.selectionEnd || 0;
      const newText = _pattern.slice(0, start) + symbol + _pattern.slice(end);

      setRegexPattern(newText);
      setTimeout(() => {
        textRef.current?.focus();
        textRef.current?.setSelectionRange(
          start + symbol.length + (cursor ?? 0),
          start + symbol.length + (cursor ?? 0)
        );
      }, 0);
    },
    [regexPattern]
  );

  const handleCursor = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cursor: number
  ) => {
    event.preventDefault(); // フォーカスの喪失を防ぐ
    setTimeout(() => {
      textRef.current?.focus();
      textRef.current?.setSelectionRange(cursor, cursor);
    }, 0);
  };

  const [replaceFullsizeSymbolToHalfsize] = useStatePersist(
    "word-search-replace-fullsize-symbol-to-halfsize",
    true
  );

  const [displaySingleSymbol, setDisplaySingleSymbol] =
    useStatePersist<boolean>("word-search-form-display-single-symbol", true);
  const [displayNumber, setDisplayNumber] = useStatePersist<boolean>(
    "word-search-form-display-number",
    false
  );
  const [displayBrackets, setDisplayBrackets] = useStatePersist<boolean>(
    "word-search-form-display-brackets",
    true
  );
  const [enableCompletion] = useStatePersist(
    "regex-input-enable-completion",
    true
  );

  return (
    <>
      {searchObject.mode === "simple" && (
        <Grid container>
          <Grid item xs={12} md={7}>
            <TextField
              label={regexInputMode ? "正規表現入力" : "簡易入力"}
              variant="outlined"
              value={regexInputMode ? regexPattern : sampleText || ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  update();
                  if (search) {
                    window.setTimeout(() => {
                      search();
                    }, 1);
                  }
                }
              }}
              onCompositionEnd={
                regexInputMode
                  ? (e) => {
                      if (replaceFullsizeSymbolToHalfsize) {
                        const newPattern = splitGrapheme(regexPattern)
                          .map((c: string) =>
                            symbolRegexPatternReplaceTable[c]
                              ? symbolRegexPatternReplaceTable[c]
                              : c
                          )
                          .join("");
                        setRegexPattern(newPattern);
                      }
                    }
                  : undefined
              }
              inputRef={textRef}
              onChange={
                regexInputMode
                  ? (e) => setRegexPattern(e.target.value)
                  : (e) => {
                      setSampleText(e.target.value.replace(/…/g, "..."));
                      const number: { [key: string]: number } = {};
                      const characterObjects = splitGrapheme(
                        e.target.value.replace(/…/g, "...")
                      ).map((c) => {
                        let character = ["？", "?"].includes(c)
                          ? "."
                          : ["〜"].includes(c)
                          ? ".*"
                          : c;
                        if (
                          [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                          ].includes(character)
                        ) {
                          number[character] = (number[character] ?? 0) + 1;
                          if (number[character] > 1) {
                            character = "\\" + character;
                          } else {
                            return {
                              character: ".",
                              capture: true,
                            };
                          }
                        }
                        return {
                          character,
                        };
                      });

                      setSearchObject((prev) => {
                        return {
                          ...prev,
                          characterObjects,
                        };
                      });
                    }
              }
              onBlur={regexInputMode ? handleBlur : update}
              sx={{ mt: 1, mb: 0, mx: 1, width: "90%" }}
              helperText={
                regexInputMode ? (
                  <></>
                ) : (
                  <>
                    ？で任意の1文字、〜で任意のn文字
                    <br />
                    同じ数字は同じ文字 (例:う12さ12→うおうさおう)
                  </>
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle regex input"
                      color={regexInputMode ? "primary" : "default"}
                      onClick={handleClickRegex}
                      onMouseDown={handleMouseDownInput}
                    >
                      <BsRegex />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {enableCompletion && regexInputMode && (
              <>
                <ButtonGroup size="small">
                  <Button
                    variant={displaySingleSymbol ? "contained" : "outlined"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setDisplaySingleSymbol(!displaySingleSymbol);
                    }}
                    size="small"
                  >
                    記
                  </Button>
                  <Button
                    variant={displayNumber ? "contained" : "outlined"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setDisplayNumber(!displayNumber);
                    }}
                    size="small"
                  >
                    数
                  </Button>
                  <Button
                    variant={displayBrackets ? "contained" : "outlined"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setDisplayBrackets(!displayBrackets);
                    }}
                    size="small"
                  >
                    括
                  </Button>
                  <Button
                    variant={"contained"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleCursor(e, 0);
                    }}
                    size="small"
                  >
                    ≪
                  </Button>
                  <Button
                    variant={"contained"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleInsert(e, "", -1);
                    }}
                    size="small"
                  >
                    ＜
                  </Button>
                  <Button
                    variant={"contained"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleInsert(e, "", 1);
                    }}
                    size="small"
                  >
                    ＞
                  </Button>
                  <Button
                    variant={"contained"}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleCursor(e, 10000000);
                    }}
                    size="small"
                  >
                    ≫
                  </Button>
                </ButtonGroup>
                <ButtonGroup size="small">
                  {displaySingleSymbol &&
                    [".", "|", "*", "+", "?", "^", "$", "\\"].map((symbol) => (
                      <Button
                        variant="outlined"
                        onMouseDown={(e) => handleInsert(e, symbol)}
                        size="small"
                        disableFocusRipple={true}
                        disableRipple={true}
                        disableTouchRipple={true}
                        focusRipple={false}
                      >
                        {symbol}
                      </Button>
                    ))}
                </ButtonGroup>
                <ButtonGroup size="small">
                  {displayNumber &&
                    ["1", "2", "3", "4", "5"].map((symbol) => (
                      <Button
                        variant="outlined"
                        onMouseDown={(e) => handleInsert(e, symbol)}
                        size="small"
                      >
                        {symbol}
                      </Button>
                    ))}
                </ButtonGroup>
                <ButtonGroup size="small">
                  {displayNumber &&
                    ["6", "7", "8", "9", "0"].map((symbol) => (
                      <Button
                        variant="outlined"
                        onMouseDown={(e) => handleInsert(e, symbol)}
                        size="small"
                      >
                        {symbol}
                      </Button>
                    ))}
                </ButtonGroup>
                <ButtonGroup size="small">
                  {displayBrackets &&
                    [
                      { symbol: "()", cursor: -1 },
                      { symbol: "(?=)", cursor: -1 },
                      { symbol: "(?!)", cursor: -1 },
                      { symbol: "{}", cursor: -1 },
                      { symbol: "{,}", cursor: -2 },
                      { symbol: "[]", cursor: -1 },
                      { symbol: "[^-]", cursor: -2 },
                    ].map((elem) => (
                      <Button
                        variant="outlined"
                        onMouseDown={(e) =>
                          handleInsert(e, elem.symbol, elem.cursor)
                        }
                        size="small"
                        sx={{ whiteSpace: "nowrap", px: 0 }}
                      >
                        {elem.symbol}
                      </Button>
                    ))}
                </ButtonGroup>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            <ToggleButtonGroup
              color="primary"
              value={
                regexInputMode
                  ? regexMatchMode
                  : (searchObject.matchMode as
                      | "match"
                      | "contain"
                      | "start"
                      | "end")
              }
              orientation={isSmallSize ? "horizontal" : "vertical"}
              size="small"
              exclusive
              onChange={(e, value) => {
                if (value === null) {
                  update();
                  if (search && sampleText !== "") {
                    window.setTimeout(() => {
                      search();
                    }, 1);
                  }
                  return;
                }
                if (regexInputMode) {
                  setRegexPattern((prev) => {
                    const str = prev.replace(/^(\^)/, "").replace(/(\$)$/, "");

                    return (
                      (value === "match" || value === "start" ? "^" : "") +
                      str +
                      (value === "match" || value === "end" ? "$" : "")
                    );
                  });
                }

                setSearchObject((prev) => {
                  return {
                    ...prev,
                    matchMode: value as "match" | "contain" | "start" | "end",
                  };
                });

                update();
                if (search) {
                  if (regexInputMode && regexPattern !== "") {
                    window.setTimeout(() => {
                      search();
                    }, 1);
                  } else if (!regexInputMode && sampleText !== "") {
                    window.setTimeout(() => {
                      search();
                    }, 1);
                  }
                }
              }}
              disabled={isDemo}
              onBlur={update}
              aria-label="Platform"
            >
              <ToggleButton
                value={"match"}
                sx={{
                  width: "100%",
                }}
              >
                に一致[^...$]
              </ToggleButton>
              <ToggleButton
                value={"contain"}
                sx={{
                  width: "100%",
                }}
              >
                を含む
              </ToggleButton>
              <ToggleButton
                value={"start"}
                sx={{
                  width: "100%",
                }}
              >
                で始まる[^]
              </ToggleButton>
              <ToggleButton
                value={"end"}
                sx={{
                  width: "100%",
                }}
              >
                で終わる[$]
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (search) {
                  search();
                }
              }}
              sx={{
                mt: 2,
                mb: 2,
                ml: 1,
              }}
            >
              単語検索
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
