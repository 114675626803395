export const town = `とうべつ
まつまえ
ふくしま
しりうち
きこない
ななえ
しかべ
もり
やくも
おしゃまんべ
えさし
かみのくに
あっさぶ
おとべ
おくしり
いまかね
せたな
すっつ
くろまつない
らんこし
にせこ
きもべつ
きょうごく
くっちゃん
きょうわ
いわない
しゃこたん
ふるびら
にき
よいち
なんぽろ
ないえ
かみすながわ
ゆに
ながぬま
くりやま
つきがた
うらうす
しんとつかわ
もせうし
ちっぷべつ
うりゅう
ほくりゅう
ぬまた
たかす
ひがしかぐら
とうま
ぴっぷ
あいべつ
かみかわ
ひがしかわ
びえい
かみふらの
なかふらの
みなみふらの
わっさむ
けんぶち
しもかわ
びふか
なかがわ
ほろかない
ましけ
おびら
とままえ
はぼろ
えんべつ
てしお
はまとんべつ
なかとんべつ
えさし
とよとみ
ほろのべ
れぶん
りしり
りしりふじ
びほろ
つべつ
おおぞら
しゃり
きよさと
こしみず
くんねっぷ
おけと
さろま
えんがる
ゆうべつ
たきのうえ
おこっぺ
おうむ
とようら
とうやこ
そうべつ
しらおい
あつま
あびら
むかわ
ひだか
びらとり
にいかっぷ
うらかわ
さまに
えりも
しんひだか
おとふけ
しほろ
かみしほろ
しかおい
しんとく
しみず
めむろ
たいき
ひろお
まくべつ
いけだ
とよころ
ほんべつ
あしょろ
りくべつ
うらほろ
くしろ
あっけし
はまなか
しべちゃ
てしかが
しらぬか
べつかい
なかしべつ
しべつ
らうす
ひらない
いまべつ
そとがはま
あじがさわ
ふかうら
ふじさき
おおわに
いたやなぎ
つるた
なかどまり
のへじ
しちのへ
ろくのへ
よこはま
とうほく
おいらせ
おおま
さんのへ
ごのへ
たっこ
なんぶ
はしかみ
しずくいし
くずまき
いわて
しわ
やはば
にしわが
かねがさき
ひらいずみ
すみた
おおつち
やまだ
いわいずみ
かるまい
ひろの
いちのへ
ざおう
しちかしゅく
おおがわら
むらた
しばた
かわさき
まるもり
わたり
やまもと
まつしま
しちがはま
りふ
たいわ
おおさと
しかま
かみ
わくや
みさと
おながわ
みなみさんりく
こさか
ふじさと
みたね
はっぽう
ごじょうめ
はちろうがた
いかわ
みさと
うご
やまのべ
なかやま
かほく
にしかわ
あさひ
おおえ
おおいしだ
かねやま
もがみ
ふながた
まむろがわ
たかはた
かわにし
おぐに
しらたか
いいで
みかわ
しょうない
ゆざ
こおり
くにみ
かわまた
かがみいし
しもごう
ただみ
みなみあいづ
にしあいづ
ばんだい
いなわしろ
あいづばんげ
やないづ
みしま
かねやま
あいづみさと
やぶき
たなぐら
やまつり
はなわ
いしかわ
あさかわ
ふるどの
みはる
おの
ひろの
ならは
とみおか
おおくま
ふたば
なみえ
しんち
いばらき
おおあらい
しろさと
だいご
あみ
かわち
やちよ
ごか
さかい
とね
かみのかわ
ましこ
もてぎ
いちかい
はが
みぶ
のぎ
しおや
たかねざわ
なす
なかがわ
よしおか
かんな
しもにた
かんら
なかのじょう
ながのはら
くさつ
ひがしあがつま
みなかみ
たまむら
いたくら
めいわ
ちよだ
おおいずみ
おうら
いな
みよし
もろやま
おごせ
なめがわ
らんざん
おがわ
かわじま
よしみ
はとやま
ときがわ
よこぜ
みなの
ながとろ
おがの
みさと
かみかわ
かみさと
よりい
みやしろ
すぎと
まつぶし
しすい
さかえ
こうざき
たこ
とうのしょう
くじゅうくり
しばやま
よこしばひかり
いちのみや
むつざわ
しらこ
ながら
ちょうなん
おおたき
おんじゅく
きょなん
みずほ
ひので
おくたま
おおしま
はちじょう
はやま
さむかわ
おおいそ
にのみや
なかい
おおい
まつだ
やまきた
かいせい
はこね
まなづる
ゆがわら
あいかわ
せいろう
たがみ
あが
いずもざき
ゆざわ
つなん
かみいち
たてやま
にゅうぜん
あさひ
かわきた
つばた
うちなだ
しか
ほうだつしみず
なかのと
あなみず
のと
えいへいじ
いけだ
みなみえちぜん
えちぜん
みはま
たかはま
おおい
わかさ
いちかわみさと
はやかわ
みのぶ
なんぶ
ふじかわ
しょうわ
にしかつら
ふじかわぐちこ
こうみ
さくほ
かるいざわ
みよた
たてしな
ながわ
しもすわ
ふじみ
たつの
みのわ
いいじま
まつかわ
たかもり
あなん
あげまつ
なぎそ
きそ
いけだ
さかき
おぶせ
やまのうち
しなの
いいづな
ぎなん
かさまつ
ようろう
たるい
せきがはら
ごうど
わのうち
あんぱち
いびがわ
おおの
いけだ
きたがた
さかほぎ
とみか
かわべ
ひちそう
やおつ
しらかわ
みたけ
ひがしいず
かわづ
みなみいず
まつざき
にしいず
かんなみ
しみず
ながいずみ
おやま
よしだ
かわねほん
もり
とうごう
とよやま
おおぐち
ふそう
おおはる
かにえ
あぐい
ひがしうら
みなみちた
みはま
たけとよ
こうた
したら
とうえい
きそさき
とういん
こもの
あさひ
かわごえ
たき
めいわ
おおだい
たまき
わたらい
たいき
みなみいせ
きほく
みはま
きほう
ひの
りゅうおう
あいしょう
とよさと
こうら
たが
おおやまざき
くみやま
いで
うじたわら
かさぎ
わづか
せいか
きょうたんば
いね
よさの
しまもと
とよの
のせ
ただおか
くまとり
たじり
みさき
たいし
かなん
いながわ
たか
いなみ
はりま
いちかわ
ふくさき
かみかわ
たいし
かみごおり
さよう
かみ
しんおんせん
へぐり
さんごう
いかるが
あんど
かわにし
みやけ
たわらもと
たかとり
かんまき
おうじ
こうりょう
かわい
よしの
おおよど
しもいち
きみの
かつらぎ
くどやま
こうや
ゆあさ
ひろがわ
ありだがわ
みはま
ひだか
ゆら
いなみ
みなべ
ひだかがわ
しらはま
かみとんだ
すさみ
なちかつうら
たいじ
こざがわ
くしもと
いわみ
わかさ
ちづ
やず
みささ
ゆりはま
ことうら
ほくえい
だいせん
なんぶ
ほうき
にちなん
ひの
こうふ
おくいずも
いいなん
かわもと
みさと
おおなん
つわの
よしか
あま
にしのしま
おきのしま
わけ
はやしま
さとしょう
やかげ
かがみの
しょうおう
なぎ
くめなん
みさき
きびちゅうおう
ふちゅう
かいた
くまの
さか
あきおおた
きたひろしま
おおさきかみじま
せら
じんせきこうげん
すおうおおしま
わき
かみのせき
たぶせ
ひらお
あぶ
かつうら
かみかつ
いしい
かみやま
なか
むぎ
みなみ
かいよう
まつしげ
きたじま
あいずみ
いたの
かみいた
つるぎ
ひがしみよし
とのしょう
しょうどしま
みき
なおしま
うたづ
あやがわ
ことひら
たどつ
まんのう
かみじま
くまこうげん
まさき
とべ
うちこ
いかた
まつの
きほく
あいなん
とうよう
なはり
たの
やすだ
もとやま
おおとよ
とさ
いの
によどがわ
なかとさ
さかわ
おち
ゆすはら
つの
しまんと
おおつき
くろしお
うみ
ささぐり
しめ
すえ
しんぐう
ひさやま
かすや
あしや
みずまき
おかがき
おんが
こたけ
くらて
けいせん
ちくぜん
たちあらい
おおき
ひろかわ
かわら
そえだ
いとだ
かわさき
おおとう
ふくち
かんだ
みやこ
よしとみ
こうげ
ちくじょう
よしのがり
きやま
かみみね
みやき
げんかい
ありた
おおまち
こうほく
しろいし
たら
ながよ
とぎつ
ひがしそのぎ
かわたな
はさみ
おぢか
さざ
しんかみごとう
みさと
ぎょくとう
なんかん
ながす
なごみ
おおづ
きくよう
みなみおぐに
おぐに
たかもり
みふね
かしま
ましき
こうさ
やまと
ひかわ
あしきた
つなぎ
にしき
たらぎ
ゆのまえ
あさぎり
れいほく
ひじ
ここのえ
くす
みまた
たかはる
くにとみ
あや
たかなべ
しんとみ
きじょう
かわみなみ
つの
かどがわ
みさと
たかちほ
ひのかげ
ごかせ
さつま
ながしま
ゆうすい
おおさき
ひがしくしら
きんこう
みなみおおすみ
きもつき
なかたね
みなみたね
やくしま
せとうち
たつごう
きかい
とくのしま
あまぎ
いせん
わどまり
ちな
よろん
もとぶ
きん
かでな
ちゃたん
にしはら
よなばる
はえばる
くめじま
やえせ
たけとみ
よなぐに`
