import {
  ParallelTransform,
  Transform,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import { FilterQueryBase } from "@langue-de-chat-llc/enigmastudio-algorithm/dist/param/generalSearchFilter";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useCallback, useEffect, useState } from "react";
import { BasicFormProps } from "../FormProps";
import { AnagramDictionaryMatchForm } from "./AnagramDictionaryMatchForm";
import { RegexReplaceForm } from "./RegexReplaceForm";

type Props = BasicFormProps<ParallelTransform> & {
  isEstimation: boolean;
};

export const ParallelTransformForm: FC<Props> = ({
  index,
  query,
  updateQuery,
  deleteQueryField,
  isDemo,
  isEstimation,
}) => {
  const updateSequenceQuery = useCallback(
    (sIndex: number, sField: string, sValue: any) => {
      (query.processes[sIndex] as any)[sField] = sValue;
      updateQuery(index, "processes", query.processes);
    },
    [index, query.processes, updateQuery]
  );

  useEffect(() => {
    query.processes = query.processes || [];
    updateQuery(
      index,
      "processes",
      query.processes.filter((e) => {
        return ["regexReplace", "anagramDictionaryMatch"].includes(e.type);
      })
    );
  }, [index, query, query.processes, updateQuery]);

  const [transformType, setTransformType] = useState("regexReplace");

  return (
    <>
      <FormControl variant="outlined" sx={{ mb: 2, width: "200px" }}>
        <InputLabel>変換処理タイプ</InputLabel>
        <Select
          value={transformType || "regexReplace"}
          onChange={(e) => {
            setTransformType(e.target.value as string);
          }}
          disabled={isDemo}
        >
          <MenuItem value="regexReplace">正規表現置換</MenuItem>
          <MenuItem value="anagramDictionaryMatch">
            アナグラム辞書マッチ
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="text"
        onClick={() => {
          const updatedProcesses = [...(query.processes || [])];
          updatedProcesses.push({
            type: transformType ?? "regex",
          } as Transform);
          updateQuery(index, "processes", updatedProcesses);
        }}
      >
        追加
      </Button>
      {query.processes?.map((process: any, pIndex: number) => (
        <Box
          key={pIndex}
          mb={0}
          mt={2}
          sx={{ border: "dashed", py: 1, borderColor: "#ccc" }}
        >
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              const updatedProcesses = [...(query.processes || [])];
              updatedProcesses.splice(pIndex, 1);
              updateQuery(index, "processes", updatedProcesses);
            }}
          >
            削除
          </Button>

          {process?.type === "regexReplace" && (
            <RegexReplaceForm
              index={pIndex}
              query={process}
              updateQuery={updateSequenceQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {process?.type === "anagramDictionaryMatch" && (
            <AnagramDictionaryMatchForm
              index={pIndex}
              query={process}
              updateQuery={updateSequenceQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {isEstimation && (
            <Box
              sx={{
                mt: 1,
                ml: 1,
                mb: 0,
                textAlign: "left",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={
                  (process as FilterQueryBase)?.matchEstimationOperator ??
                  "multiply"
                }
                aria-label="Operator"
              >
                <ToggleButton
                  value="add"
                  size="small"
                  onClick={() => {
                    updateSequenceQuery(
                      pIndex,
                      "matchEstimationOperator",
                      "add"
                    );
                  }}
                >
                  <AddIcon />
                </ToggleButton>
                <ToggleButton
                  value="multiply"
                  size="small"
                  onClick={() => {
                    updateSequenceQuery(
                      pIndex,
                      "matchEstimationOperator",
                      "multiply"
                    );
                  }}
                >
                  <CloseIcon />
                </ToggleButton>
              </ToggleButtonGroup>

              <TextField
                label="マッチ時評価値"
                value={
                  (process as FilterQueryBase)?.matchEstimationValue ?? "1"
                }
                onChange={(e) => {
                  updateSequenceQuery(
                    pIndex,
                    "matchEstimationValue",
                    e.target.value
                  );
                }}
                inputProps={{
                  inputMode: "decimal",
                  type: "number",
                  step: "0.1",
                }}
                size="small"
                sx={{
                  ml: 1,
                  my: 0,
                  mb: 0.5,
                  py: 0,
                  fontSize: "0.8rem",
                }}
              />
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};
