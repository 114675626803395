import { workerStatus } from "../..";
import { progress, sendProgress } from "../generalSearchProgress";
import { createSeparatedDictionaryTable } from "../table/dictionaryTable";
export const dictionaryTransform = async ({ query, input: _input, result, workerContext, }) => {
    const dictKey = query.dictionary;
    if (!dictKey) {
        return [];
    }
    const dict = createSeparatedDictionaryTable(dictKey, query.src, query.dst, query.separator);
    if (!dict) {
        return [];
    }
    console.log("dictionaryTransform", dict);
    if (query.matchOnly ?? true) {
        result = result.filter((e) => {
            return dict[e.currentWord];
        });
    }
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount += result.length;
    const temp = progress.get().queryProgress[progress.get().queryProgress.length - 1]
        .currentWordCount;
    const ret = [];
    let i = 0;
    for (const e of result) {
        ++i;
        if (workerStatus.abort) {
            break;
        }
        progress.get().queryProgress[progress.get().queryProgress.length - 1].currentWordCount = temp + i;
        if ((temp + i) % 100 === 0) {
            if (await sendProgress(workerContext)) {
                break;
            }
        }
        if (!(query.grouping ?? true) && dict[e.currentWord]) {
            ret.push(...dict[e.currentWord].map((e2) => {
                // deep clone e
                const newe = JSON.parse(JSON.stringify(e));
                newe.currentWord = e2;
                newe.trace.push(e2);
                return newe;
            }));
        }
        else {
            const e2 = dict[e.currentWord]
                ? dict[e.currentWord].join("/")
                : e.currentWord;
            const newe = JSON.parse(JSON.stringify(e));
            newe.currentWord = e2;
            newe.trace.push(e2);
            ret.push(newe);
        }
    }
    await sendProgress(workerContext);
    console.log("ret", ret);
    return ret;
};
