export const prob13thRoot = [
  ["5879", "10021782715148878848524958303030378299215695465239"],
  ["5880", "10043966117579463407667456351959777280000000000000"],
  ["5881", "10066194838486428227401826132699484378925741871641"],
  ["5882", "10088468962721360004047266308746556366111236268032"],
  ["5883", "10110788575280249043375575511113895846111926822763"],
  ["5884", "10133153761303710399310173803351271732284389064704"],
  ["5885", "10155564606077205313596402523264516479757080078125"],
  ["5886", "10178021195031262956801462515314728890408656429056"],
  ["5887", "10200523613741702471002716441101301905963412040447"],
  ["5888", "10223071947929855314523446839648457769634788016128"],
  ["5889", "10245666283462787909075527904636128759738501376769"],
  ["5890", "10268306706353524589668835552453998690000000000000"],
  ["5891", "10290993302761270857647587273223954689772911644371"],
  ["5892", "10313726158991636937214170486942834467827259277312"],
  ["5893", "10336505361496861635801385668856043293063386566293"],
  ["5894", "10359330996876036508654398362295317220972978397184"],
  ["5895", "10382203151875330327984062363712692578294677734375"],
  ["5896", "10405121913388213857053644843729782345047354638336"],
  ["5897", "10428087368455684929561352959909041867157574470377"],
  ["5898", "10451099604266493834681430621854221424347081515008"],
  ["5899", "10474158708157369008126963487373151839554178896699"],
  ["5900", "10497264767613243029597900000000000000000000000000"],
  ["5901", "10520417870267478926978166323388880534182609256701"],
  ["5902", "10543618103902096787646123387169042874444458811392"],
  ["5903", "10566865556448000677262984932006702741638560579023"],
  ["5904", "10590160315985205866404186429062995253340158296064"],
  ["5905", "10613502470743066365399066050984643279468994140625"],
  ["5906", "10636892109100502767744590488227017648228119945216"],
  ["5907", "10660329319586230402459230336101682076470282067107"],
  ["5908", "10683814190878987795743462024675740470681784025088"],
  ["5909", "10707346811807765442313745825741921236361972541029"],
  ["5910", "10730927271352034886777202348740034710000000000000"],
  ["5911", "10754555658641978115414583130956026637896075624631"],
  ["5912", "10778232062958717258739504436768232238766486454272"],
  ["5913", "10801956573734544605202287206365629928455388544953"],
  ["5914", "10825729280553152926407120236444028075006801977344"],
  ["5915", "10849550273149866114211638134107427741287841796875"],
  ["5916", "10873419641411870130078380360777633578166513565696"],
  ["5917", "10897337475378444267047972775559986976016930514637"],
  ["5918", "10921303865241192724704248497441432229163251130368"],
  ["5919", "10945318901344276497501900633123668657742195225759"],
  ["5920", "10969382674184645576827635462433669120000000000000"],
  ["5921", "10993495274412271467166171036321246842389403264161"],
  ["5922", "11017656792830380016742802823663625690489972989952"],
  ["5923", "11041867320395684563014635042665220621428412948083"],
  ["5924", "11066126948218619393382953630782280880856733057024"],
  ["5925", "11090435767563573521499594444032013416290283203125"],
  ["5926", "11114793869849124779540538232479721991116010315776"],
  ["5927", "11139201346648274226820342213850911789891996696967"],
  ["5928", "11163658289688680875121396661803872305223563214848"],
  ["5929", "11188164790852896731112373839637724635465912266889"],
  ["5930", "11212720942178602156230615844317173930000000000000"],
  ["5931", "11237326835858841544403587479884223844268340291291"],
  ["5932", "11261982564242259317984900153814977116633072402432"],
  ["5933", "11286688219833336242280792984882563753910783072413"],
  ["5934", "11311443895292626059043337826821514243530549551104"],
  ["5935", "11336249683436992439307015748770947596859130859375"],
  ["5936", "11361105677239846255945693671320300274294462611456"],
  ["5937", "11386011969831383176327411336208619093880962698097"],
  ["5938", "11410968654498821575444770588553425605702749462528"],
  ["5939", "11435975824686640769899101073124687263983505380419"],
  ["5940", "11461033573996819573116958890850467840000000000000"],
  ["5941", "11486141996189075172177897528660451373409014422021"],
  ["5942", "11511301185181102326632833465158925765640165875712"],
  ["5943", "11536511235048812889692712266687268848272099001943"],
  ["5944", "11561772240026575652167564723304906183821527875584"],
  ["5945", "11587084294507456509536426632304620585533447265625"],
  ["5946", "11612447493043458952528980218300612860319684468736"],
  ["5947", "11637861930345764881600159883903575980053199802027"],
  ["5948", "11663327701284975745679350013744095114437651857408"],
  ["5949", "11688844900891354005576187907341878042442516682349"],
  ["5950", "11714413624355064922425370593261718750000000000000"],
  ["5951", "11740033967026418671553250278365875929866976972351"],
  ["5952", "11765706024416112782149389511984992132244325793792"],
  ["5953", "11791429892195474903126633795704183112345695620673"],
  ["5954", "11817205666196705895553646345415995294532006846464"],
  ["5955", "11843033442413123252044237013546177240321044921875"],
  ["5956", "11868913316999404843488205006130363832568555503616"],
  ["5957", "11894845386271832993508802981928665115061412492757"],
  ["5958", "11920829746708538881032318399229731219029029183488"],
  ["5959", "11946866494949747271355656580635186784801172688679"],
  ["5960", "11972955727798021576098198897148559360000000000000"],
  ["5961", "11999097542218509242424598730539243467160966858281"],
  ["5962", "12025292035339187471925567457431032892457139740672"],
  ["5963", "12051539304451109269544092610095816266290966612603"],
  ["5964", "12077839447008649822934920607735773740164651679744"],
  ["5965", "12104192560629753212645527018331554645010986328125"],
  ["5966", "12130598743096179453507188205139287192058044932096"],
  ["5967", "12157058092353751867625159433855811948861242454287"],
  ["5968", "12183570706512604789357356066559292080588501024768"],
  ["5969", "12210136683847431602671326346991497007019988315409"],
  ["5970", "12236756122797733111269696488798854770000000000000"],
  ["5971", "12263429121968066241874661314212205416321794595811"],
  ["5972", "12290155780128293081062486555540557297543494500352"],
  ["5973", "12316936196213830246039382126002655466728328461733"],
  ["5974", "12343770469325898589750499190042532320838997991424"],
  ["5975", "12370658698731773240714197716592252254486083984375"],
  ["5976", "12397600983865033977974125381977722300830555570176"],
  ["5977", "12424597424325815941562043202532768507652632570617"],
  ["5978", "12451648119881060678864728120713833161178931765248"],
  ["5979", "12478753170464767527288677942813902055929850878539"],
  ["5980", "12505912676178245333616739531481006080000000000000"],
  ["5981", "12533126737290364510451176992375350879080746300941"],
  ["5982", "12560395454237809430138092761671416926526920056832"],
  ["5983", "12587718927625331156568510999948955855516424452063"],
  ["5984", "12615097258226000515251829528541514582039675797504"],
  ["5985", "12642530546981461502057743706844885065035400390625"],
  ["5986", "12670018895002185031023143143652754372409987833856"],
  ["5987", "12697562403567723021620879962504978305132550925747"],
  ["5988", "12725161174126962825887705500527594865773854588928"],
  ["5989", "12752815308298381995809070812535315503190984094069"],
  ["5990", "12780524907870303391358885177479277990000000000000"],
  ["5991", "12808290074801150629592725962877922207755907689671"],
  ["5992", "12836110911219703875193392694889677822659093594112"],
  ["5993", "12863987519425355972868098007395561248253168667593"],
  ["5994", "12891920001888368921996988303080710484218713513984"],
  ["5995", "12919908461250130693933087453259394159666748046875"],
  ["5996", "12947953000323412392354157691301280894678923739136"],
  ["5997", "12976053722092625757067373017211024193636674803677"],
  ["5998", "13004210729714081011668101928411895572873535479808"],
  ["5999", "13032424126516245055454498124310785421773192077999"],
  ["6000", "13060694016000000000000000000000000000000000000000"],
  ["6001", "13089020501838902050786242247704638701778808078001"],
  ["6002", "13117403687879440734299285723536774944375103496192"],
  ["6003", "13145843678141298470992474911993135428494229992323"],
  ["6004", "13174340576817610494519635831655536769890321956864"],
  ["6005", "13202894488275225117642731072947168378419189453125"],
  ["6006", "13231505517054964345218492842787190832511330902016"],
  ["6007", "13260173767871884834668959411795446875435634688407"],
  ["6008", "13288899345615539204341245217552866736427277221888"],
  ["6009", "13317682355350237690162280072546012163603783346329"],
  ["6010", "13346522902315310150994658458040878010000000000000"],
  ["6011", "13375421091925368423100145755466402394387784381931"],
  ["6012", "13404377029770569024117794475167084649544073347072"],
  ["6013", "13433390821616876206964030088831745123794556110253"],
  ["6014", "13462462573406325364062472956746965472672558342144"],
  ["6015", "13491592391257286782311670064484417863675537109375"],
  ["6016", "13520780381464729749199317845936534494679186538496"],
  ["6017", "13550026650500487010471965270990305488339418263937"],
  ["6018", "13579331305013519579769594616800056617423563399168"],
  ["6019", "13608694451830181900634885921812594077445574815059"],
  ["6020", "13638116197954487361307380041637969920000000000000"],
  ["6021", "13667596650568374162713164485772289725798465117461"],
  ["6022", "13697135917031971540061115814291537267312745930752"],
  ["6023", "13726734104883866338457142314173525664990537505383"],
  ["6024", "13756391321841369942948280956095110034746270285824"],
  ["6025", "13786107675800785563408913254620134830474853515625"],
  ["6026", "13815883274837675874681775617866774829935421464576"],
  ["6027", "13845718227207131012386851077247609712851534414267"],
  ["6028", "13875612641344036924811640933938709082421642395648"],
  ["6029", "13905566625863344081296726846582071197683375800189"],
  ["6030", "13935580289560336537530946214585941230000000000000"],
  ["6031", "13965653741410901358170916383486933189145774400591"],
  ["6032", "13995787090571798397200056213403706518527627231232"],
  ["6033", "14025980446380930436442666908871529586652411461713"],
  ["6034", "14056233918357613682649047708527836908417719803904"],
  ["6035", "14086547616202848623568036076448407111824951171875"],
  ["6036", "14116921649799591243423776423639719848759584096256"],
  ["6037", "14147356129213024598213936118503170724402537223397"],
  ["6038", "14177851164690830751247002619229025884410151395328"],
  ["6039", "14208406866663463069336710979280287489513352017719"],
  ["6040", "14239023345744418880072066739617136640000000000000"],
  ["6041", "14269700712730512490581845328319553520675855947321"],
  ["6042", "14300439078602148568212865540017431207609290432512"],
  ["6043", "14331238554523595883541751464262458790348506063243"],
  ["6044", "14362099251843261416140314373902823825887287312384"],
  ["6045", "14393021282093964823515103571879059829014892578125"],
  ["6046", "14424004756993213273642093027875941298036624449536"],
  ["6047", "14455049788443476641517888815170120097680509975327"],
  ["6048", "14486156488532463070149260883035229974690380382208"],
  ["6049", "14517324969533394896403221571434601386045669583649"],
  ["6050", "14548555343905284942140292492675781250000000000000"],
  ["6051", "14579847724293213171054020969450110054050317073651"],
  ["6052", "14611202223528603711640227130464154169840439918592"],
  ["6053", "14642618954629502246719883023917420299274924193973"],
  ["6054", "14674098030800853769939945715622201680942655627264"],
  ["6055", "14705639565434780709676887292826445936927490234375"],
  ["6056", "14737243672110861420768085997019130133923198140416"],
  ["6057", "14768910464596409044496664359399831684764109154057"],
  ["6058", "14800640056846750737255782211510160098993776140288"],
  ["6059", "14832432563005507268318814790984744370046819013979"],
  ["6060", "14864288097404872987142268858713948160000000000000"],
  ["6061", "14896206774565896160628712789149918278209192695581"],
  ["6062", "14928188709198759680777419990262583822317335273472"],
  ["6063", "14960234016203062143150848753993550578916267537903"],
  ["6064", "14992342810668099296585505732194328969110873964544"],
  ["6065", "15024515207873145864576164677201959394351806640625"],
  ["6066", "15056751323287737738762836880630936071339890384896"],
  ["6067", "15089051272571954544950314888876573518651768443587"],
  ["6068", "15121415171576702582090536569462917382755286253568"],
  ["6069", "15153843136343998134658442448959396349464019224709"],
  ["6070", "15186335283107251158852425440966182070000000000000"],
  ["6071", "15218891728291549343050898631860321477023029329111"],
  ["6072", "15251512588513942542956933692834897298302175281152"],
  ["6073", "15284197980583727591863349739483639339715000579033"],
  ["6074", "15316948021502733486471060065015547210819701940224"],
  ["6075", "15349762828465606948693912130360305309295654296875"],
  ["6076", "15382642518860098363883684504177798504911759998976"],
  ["6077", "15415587210267348095909333108346212282868662727917"],
  ["6078", "15448597020462173179525008140105479145376143106048"],
  ["6079", "15481672067413354390461792410908783703490101531839"],
  ["6080", "15514812469283923693678541564417146880000000000000"],
  ["6081", "15548018344431452070207636712193574674948489090241"],
  ["6082", "15581289811408337723031890455746793667402041925632"],
  ["6083", "15614626988962094662429278047873233490239054601363"],
  ["6084", "15648029996035641671222596584982800607334633570304"],
  ["6085", "15681498951767591650371586615502377609298095703125"],
  ["6086", "15715033975492541345345482397764252803763603398656"],
  ["6087", "15748635186741361453714389244238326140121032091047"],
  ["6088", "15782302705241487114398318947790554697421687881728"],
  ["6089", "15816036650917208779013147200079414526743273651369"],
  ["6090", "15849837143889963465753190182471001290000000000000"],
  ["6091", "15883704304478626396250531136195720894085873694971"],
  ["6092", "15917638253199803015851661701119399541888004390912"],
  ["6093", "15951639110768121397752437151693252049049271688893"],
  ["6094", "15985706998096525031432779354614785063654001270784"],
  ["6095", "16019842036296565995832996325709790586741943359375"],
  ["6096", "16054044346678698517714022673768633029360338599936"],
  ["6097", "16088314050752572915644320986772693592455465816977"],
  ["6098", "16122651270227329930056620341363868224011834564608"],
  ["6099", "16157056127011895439818104599776294605152163699299"],
  ["6100", "16191528743215275565758100000000000000000000000000"],
  ["6101", "16226069241146852161597748744916021512420968459301"],
  ["6102", "16260677743316678692726592854766975186555983060992"],
  ["6103", "16295354372435776503271430464841378086380288725623"],
  ["6104", "16330099251416431471903245026889711408160559857664"],
  ["6105", "16364912503372491056828446508790822069166259765625"],
  ["6106", "16399794251619661730411102682584480199566909218816"],
  ["6107", "16434744619675806803873277946415541576068746389707"],
  ["6108", "16469763731261244642521036841433124258173773938688"],
  ["6109", "16504851710299047271944109500490530490352544191629"],
  ["6110", "16540008680915339375637656701834477310000000000000"],
  ["6111", "16575234767439597684495012998091807308617606299231"],
  ["6112", "16610530094404950758620727548994615789818973519872"],
  ["6113", "16645894786548479161913663805667149448860461395553"],
  ["6114", "16681328968811516029870361075166518137643950546944"],
  ["6115", "16716832766339948031059303236560944964578857421875"],
  ["6116", "16752406304484516722717182484380806851286108471296"],
  ["6117", "16788049708801120300918688942026049959801853493237"],
  ["6118", "16823763105051115745771800316895780675638517587968"],
  ["6119", "16859546619201621362090989460859128011818136044359"],
  ["6120", "16895400377425819716001211754448158720000000000000"],
  ["6121", "16931324506103260967925978651061129833511351730761"],
  ["6122", "16967319131820166602413268498755229081208230551552"],
  ["6123", "17003384381369733555253470902119842377808428182683"],
  ["6124", "17039520381752438738344006395492076750324444954624"],
  ["6125", "17075727260176343962755709071643650531768798828125"],
  ["6126", "17112005144057401260456506047270351006416723173376"],
  ["6127", "17148354161019758605148374248390180161439728011567"],
  ["6128", "17184774438896066032674001965342305733474881896448"],
  ["6129", "17221266105727782161450029958714368029563892573489"],
  ["6130", "17257829289765481113384194594449040530000000000000"],
  ["6131", "17294464119469159835734143548830598350438704869891"],
  ["6132", "17331170723508545824366143052267324419149012140032"],
  ["6133", "17367949230763405248872344435004706780155914371013"],
  ["6134", "17404799770323851480005726898366496770724369096704"],
  ["6135", "17441722471490654019892282962064868925618896484375"],
  ["6136", "17478717463775547835480462931786328329458397741056"],
  ["6137", "17515784876901543095688344991885945824226396028697"],
  ["6138", "17552924840803235312709448155848379125066512048128"],
  ["6139", "17590137485627115887938556302439488777076163495019"],
  ["6140", "17627422941731883062979372888416829440000000000000"],
  ["6141", "17664781339688753276196277659530653863720225432621"],
  ["6142", "17702212810281772925272908780569191006764483469312"],
  ["6143", "17739717484508130536240746272622850226758376044543"],
  ["6144", "17777295493578469339441325481801776830649607389184"],
  ["6145", "17814946968917200252886162508580074367418212890625"],
  ["6146", "17852672042162815273478927100998367127873618190336"],
  ["6147", "17890470845168201276564852458374671071493052828627"],
  ["6148", "17928343510000954224272825705192358989249546027008"],
  ["6149", "17966290168943693783116057477694049806140138924949"],
  ["6150", "18004310954494378351317684118652343750000000000000"],
  ["6151", "18042405999366620496328111399053403474999976734951"],
  ["6152", "18080575436490002803001364479258502566549380923392"],
  ["6153", "18118819399010394132898164986843001271767084087273"],
  ["6154", "18157138020290266295183912623993056602159586648064"],
  ["6155", "18195531433909011129590205624309139690174560546875"],
  ["6156", "18233999773663258001908991657363644776301216137216"],
  ["6157", "18272543173567191712488898429629176603984706895357"],
  ["6158", "18311161767852870818203751252676259307940518617088"],
  ["6159", "18349855690970546368363743244076100994450973379279"],
  ["6160", "18388625077588981055040182593477672960000000000000"],
  ["6161", "18427470062595768778275200466100837678727689692881"],
  ["6162", "18466390781097654626648262628640820121043036086272"],
  ["6163", "18505387368420855273671787768556315504848248183203"],
  ["6164", "18544459960111379790488635737156434173220140089344"],
  ["6165", "18583608691935350875344689580053090008927001953125"],
  ["6166", "18622833699879326500310216225648049870899792797696"],
  ["6167", "18662135120150621975724152083620503406358983912887"],
  ["6168", "18701513089177632432835921561114628930296105402368"],
  ["6169", "18740967743610155725119858634740283825310989774009"],
  ["6170", "18780499220319715748737764121836817370000000000000"],
  ["6171", "18820107656399886182625594174953388678854046822411"],
  ["6172", "18859793189166614648680738779412489550119359741952"],
  ["6173", "18899555956158547292526812665390156921346780816333"],
  ["6174", "18939396095137353785333345053410266246749651329024"],
  ["6175", "18979313744088052747168219035755097866058349609375"],
  ["6176", "19019309041219337592361176156283954308483462987776"],
  ["6177", "19059382124963902797357166887769987030418690765217"],
  ["6178", "19099533133978770591538793219356694508358306766848"],
  ["6179", "19139762207145618071497555457344026748331363425139"],
  ["6180", "19180069483571104739234081610484039680000000000000"],
  ["6181", "19220455102587200464767984376542070866793486239541"],
  ["6182", "19260919203751513873638457770306058550199385874432"],
  ["6183", "19301461926847621159777192834748621140004301270663"],
  ["6184", "19342083411885395324235659656908694600984878383104"],
  ["6185", "19382783799101335840249271068506850401607666015625"],
  ["6186", "19423563228958898745121411948585965344696319123456"],
  ["6187", "19464421842148827159410786961821884226927739536347"],
  ["6188", "19505359779589482233906008861822423415250671894528"],
  ["6189", "19546377182427174524871819163972943672872686048669"],
  ["6190", "19587474192036495798051803047438328590000000000000"],
  ["6191", "19628650950020651261912930781040214573892925660271"],
  ["6192", "19669907598211792230617728783140522490579975667712"],
  ["6193", "19711244278671349217210354621623651889552979630193"],
  ["6194", "19752661133690365457503321836826147685508857667584"],
  ["6195", "19794158305789830865152092428060386898582763671875"],
  ["6196", "19835735937721016418405227183464129564256015220736"],
  ["6197", "19877394172465808979018256753527022569348031510277"],
  ["6198", "19919133153237046543819909471044846698303162769408"],
  ["6199", "19960953023478853929419805404679083763974809760599"],
  ["6200", "20002853926866978890547200000000000000000000000000"],
  ["6201", "20044836007309128672510834911112761068028806400601"],
  ["6202", "20086899408945306998270428257955099791160281505792"],
  ["6203", "20129044276148151490610811559358856360418820778923"],
  ["6204", "20171270753523271529910195990233528194899287998464"],
  ["6205", "20213578985909586547994526393005148453272705078125"],
  ["6206", "20255969118379664758570357638975823682280870895616"],
  ["6207", "20298441296240062324729164484805687275522901171007"],
  ["6208", "20340995665031662964016473003107571111995258175488"],
  ["6209", "20383632370530017991559678984433187575742371076929"],
  ["6210", "20426351558745686801748896410965992610000000000000"],
  ["6211", "20469153375924577788965657190268231911538857376531"],
  ["6212", "20512037968548289707854761810706141138135570972672"],
  ["6213", "20555005483334453473635059438946181371879988400853"],
  ["6214", "20598056067237074402945415224424967352771794591744"],
  ["6215", "20641189867446874895722602206194732520560302734375"],
  ["6216", "20684407031391637558608335234283436116898895364096"],
  ["6217", "20727707706736548770383144720932738071220720202537"],
  ["6218", "20771092041384542689925268827036951855318897696768"],
  ["6219", "20814560183476645707193223866050758881568394913659"],
  ["6220", "20858112281392321337731194271812075520000000000000"],
  ["6221", "20901748483749815561196865428388270884478979104061"],
  ["6222", "20945468939406502604411804999448325973716010852352"],
  ["6223", "20989273797459231169434981122040008694337768979983"],
  ["6224", "21033163207244671107160488945259341285008473063424"],
  ["6225", "21077137318339660536941040498389303684234619140625"],
  ["6226", "21121196280561553412739256765905715897721131442176"],
  ["6227", "21165340243968567536309285128550562396540261488867"],
  ["6228", "21209569358860133017911750000706770541074865717248"],
  ["6229", "21253883775777241185065529554823862096194378586789"],
  ["6230", "21298283645502793939840336873891311830000000000000"],
  ["6231", "21342769119061953565194569713186340391179647699191"],
  ["6232", "21387340347722492980863379282985831266616011128832"],
  ["6233", "21431997482995146449302395084878805506869775800313"],
  ["6234", "21476740676633960732193029845997245734178113429504"],
  ["6235", "21521570080636646698015775998149986202303466796875"],
  ["6236", "21566485847244931381198392942747952710345719545856"],
  ["6237", "21611488128944910493346372527800440236467423113997"],
  ["6238", "21656577078467401387063558740392673446728215420928"],
  ["6239", "21701752848788296472871286587176042923421255812319"],
  ["6240", "21747015593128917089734894496765706240000000000000"],
  ["6241", "21792365464956367829706954331797367137260238877921"],
  ["6242", "21837802617983891317197053244997798375947728986112"],
  ["6243", "21883327206171223443378452153223975837946992945843"],
  ["6244", "21928939383724949056242436536275585779570504105984"],
  ["6245", "21974639305098858106811666593637317677305908203125"],
  ["6246", "22020427124994302252024325512413046971843081691136"],
  ["6247", "22066302998360551914801356713826151624360912361927"],
  ["6248", "22112267080395153801809573454031321493760332791808"],
  ["6249", "22158319526544288879433917057865959237301640706249"],
  ["6250", "22204460492503130808472633361816406250000000000000"],
  ["6251", "22250690134216204838069630635138649056643671956251"],
  ["6252", "22297008607877747159398776336007884006240332808192"],
  ["6253", "22343416069932064719615384543029476068108259300573"],
  ["6254", "22389912677073895496590640781678697530883215908864"],
  ["6255", "22436498586248769234945206240501552601624755859375"],
  ["6256", "22483173954653368643898739043457484074156625494016"],
  ["6257", "22529938939735891057452566312870439845832489716657"],
  ["6258", "22576793699196410557423237222331393290967240613888"],
  ["6259", "22623738390987240559845184100816827285859751784579"],
  ["6260", "22670773173313296865261215908507893760000000000000"],
  ["6261", "22717898204632461173420066061568057591697773850181"],
  ["6262", "22765113643655945062900714637717255919594626179072"],
  ["6263", "22812419649348654436183703447082303863497792548503"],
  ["6264", "22859816380929554430690161303760929550415266054144"],
  ["6265", "22907303997872034796309756083065021527799072265625"],
  ["6266", "22954882659904275739939289797762138449537368170496"],
  ["6267", "23002552527009614237554152974067904744375372862187"],
  ["6268", "23050313759426910814335355054910559332865742471168"],
  ["6269", "23098166517650916793375348404347722006801415963309"],
  ["6270", "23146110962432642013486364733219600670000000000000"],
  ["6271", "23194147254779723016635484412427711332413763075711"],
  ["6272", "23242275555956791705531161186889172886470631882752"],
  ["6273", "23290496027485844471886427250489343914483353173633"],
  ["6274", "23338808831146611795884506490495685060638062157824"],
  ["6275", "23387214128976928317373066959159076213836669921875"],
  ["6276", "23435712083273103379313847280871333672514880536576"],
  ["6277", "23484302856590292044014895755525421843182400682517"],
  ["6278", "23532986611742866582673165372894022603281006747648"],
  ["6279", "23581763511804788438755712809159021516292552558439"],
  ["6280", "23630633720109980665748254735445524480000000000000"],
  ["6281", "23679597400252700839800340429595784590516253748841"],
  ["6282", "23728654716087914447796905746717573143293735903232"],
  ["6283", "23777805831731668751386479971514869590084648459963"],
  ["6284", "23827050911561467127496823946313194483230026235904"],
  ["6285", "23876390120216643885869285143284511293526611328125"],
  ["6286", "23925823622598739564143663027914544624490951008256"],
  ["6287", "23975351583871876701025886143495893753883557261647"],
  ["6288", "24024974169463136088071310833630874492301190627328"],
  ["6289", "24074691545062933500616960411646134866700537285969"],
  ["6290", "24124503876625396908396532882714419890000000000000"],
  ["6291", "24174411330368744166372515026605189449583179585571"],
  ["6292", "24224414072775661186320250756603025966952991424512"],
  ["6293", "24274512270593680589699322183498932862653576491493"],
  ["6294", "24324706090835560842348112732932872044077298704384"],
  ["6295", "24374995700779665871537932990004492821751708984375"],
  ["6296", "24425381267970345165923601677231363257826369601536"],
  ["6297", "24475862960218314358927886310878648606420455883577"],
  ["6298", "24526440945601036296097720626669734148096704094208"],
  ["6299", "24577115392463102586970628819172528599208846261899"],
  ["6300", "24627886469416615641990300000000000000000000000000"],
  ["6301", "24678754345341571195010770048624902560638037901901"],
  ["6302", "24729719189386241311929182206346787817353182830592"],
  ["6303", "24780781170967557885987612349023517544159910152223"],
  ["6304", "24831940459771496620284959867848993618358922379264"],
  ["6305", "24883197225753461498040420489983199444801025390625"],
  ["6306", "24934551639138669741150573182479540194275231932416"],
  ["6307", "24986003870422537257582629503967630228963383032307"],
  ["6308", "25037554090371064578146910399196984569613713932288"],
  ["6309", "25089202470021223283192132472088529714431380002229"],
  ["6310", "25140949180681342919767603223636583910000000000000"],
  ["6311", "25192794393931498409796942602114218252260853613831"],
  ["6312", "25244738281623897949808466484820071743470249705472"],
  ["6313", "25296781015883271402767886393325253501548021126153"],
  ["6314", "25348922769107259182559498838095545472650906476544"],
  ["6315", "25401163713966801631662557193737464945682373046875"],
  ["6316", "25453504023406528892570038923207727605805163216896"],
  ["6317", "25505943870645151273497541298395262516664502391837"],
  ["6318", "25558483429175850108930559505794288077863487725568"],
  ["6319", "25611122872766669115558922179797304560568867422959"],
  ["6320", "25663862375460906244147680972709560320000000000000"],
  ["6321", "25716702111577506027894272750183095443048263237361"],
  ["6322", "25769642255711452427822295393650472859847670833152"],
  ["6323", "25822682982734162175762760997767511539942243897283"],
  ["6324", "25875824467793878615474213471112602318999570612224"],
  ["6325", "25929066886316066042453621182699501514434814453125"],
  ["6326", "25982410414003804542990479345502972522225862270976"],
  ["6327", "26035855226838185333017081291434517197128818846167"],
  ["6328", "26089401501078706597308442670301067618441177858048"],
  ["6329", "26143049413263669829585887898495379946265749840089"],
  ["6330", "26196799140210576674078833891765595130000000000000"],
  ["6331", "26250650859016526269099832240655740470237118888491"],
  ["6332", "26304604747058613093188457527361700303159408197632"],
  ["6333", "26358660981994325314380156439071366874990479749613"],
  ["6334", "26412819741761943643156699705616402001130568802304"],
  ["6335", "26467081204580940689635406678719529293441162109375"],
  ["6336", "26521445548952380825554840576538893297632365510656"],
  ["6337", "26575912953659320551615201040853248332772502479297"],
  ["6338", "26630483597767209370732169695362183542019645513728"],
  ["6339", "26685157660624291167763493852457983916141944969619"],
  ["6340", "26739935321862006096268123392723927040000000000000"],
  ["6341", "26794816761395392972858246136591804209490012283221"],
  ["6342", "26849802159423492179705097741313339556353010982912"],
  ["6343", "26904891696429749075759953288930194135347640767143"],
  ["6344", "26960085553182417917252239282529740897375993462784"],
  ["6345", "27015383910734966288027236739013263547740478515625"],
  ["6346", "27070786950426480040286378457144275094453430951936"],
  ["6347", "27126294853882068746293676350052003259726172575227"],
  ["6348", "27181907803013271661612347961903607938875924676608"],
  ["6349", "27237625980018464200436244939393363912989890927549"],
  ["6350", "27293449567383264923581220300292968750000000000000"],
  ["6351", "27349378747880943039702105833829555325225118737551"],
  ["6352", "27405413704572826420301505881372260124974479572992"],
  ["6353", "27461554620808710129097149081079807379212533833873"],
  ["6354", "27517801680227265466315075417056125500517959409664"],
  ["6355", "27574155066756449528476472092444252210340576171875"],
  ["6356", "27630614964613915284246508347026569597879442210816"],
  ["6357", "27687181558307422166914056363557727273628741617957"],
  ["6358", "27743855032635247184071722853501573484019926130688"],
  ["6359", "27800635572686596545066153782341258963843270229879"],
  ["6360", "27857523363842017806789112987448770560000000000000"],
  ["6361", "27914518591773812538380374157902981166456761167481"],
  ["6362", "27971621442446449505414004785900484970564489551872"],
  ["6363", "28028832102116978374140160263777763970943784633803"],
  ["6364", "28086150757335443936355046289423470613563067858944"],
  ["6365", "28143577594945300855472248156272805552530517578125"],
  ["6366", "28201112802083828934369166342409238282628232503296"],
  ["6367", "28258756566182548905582839076819407018545419291487"],
  ["6368", "28316509074967638744429974249822314057206981459968"],
  ["6369", "28374370516460350505626555149391401379539813792609"],
  ["6370", "28432341078977427683982927045775371970000000000000"],
  ["6371", "28490420951131523099750814613768464957897094089011"],
  ["6372", "28548610321831617309199263575451173113503575703552"],
  ["6373", "28606909380283437540997043766486111601092401650933"],
  ["6374", "28665318315989877158979595076379035614478150426624"],
  ["6375", "28723837318751415651879142387770116329193115234375"],
  ["6376", "28782466578666539150597150742073900586391228645376"],
  ["6377", "28841206286132161473598837488906407360331476479817"],
  ["6378", "28900056631844045701010004134993187273027827048448"],
  ["6379", "28959017806797226277996996994911650601016584931739"],
  ["6380", "29018090002286431648011152561353441280000000000000"],
  ["6381", "29077273409906507416479630756867112818053307618141"],
  ["6382", "29136568221552840045525086902526986836371876012032"],
  ["6383", "29195974629421781080297181341940112861700580169263"],
  ["6384", "29255492826011071907499474191719092364133693128704"],
  ["6385", "29315123004120269046695801652283700949117431640625"],
  ["6386", "29374865356851169974980779705878386907886315053056"],
  ["6387", "29434720077608239485599630852275615634051369266947"],
  ["6388", "29494687360099036581103079787046568186381628080128"],
  ["6389", "29554767398334641901623614612790915852392143363269"],
  ["6390", "29614960386630085688859961290596435190000000000000"],
  ["6391", "29675266519604776286357170587517380893238751470871"],
  ["6392", "29735685992182929176670268756288205132777035661312"],
  ["6393", "29796218999593996555999975596096936984428346272793"],
  ["6394", "29856865737373097446889546388300976472117340381184"],
  ["6395", "29917626401361448349572347478748004020311279296875"],
  ["6396", "29978501187706794432560328989137074290227817742336"],
  ["6397", "30039490292863841263064112283890797210550822936877"],
  ["6398", "30100593913594687077835964396579979483949642539008"],
  ["6399", "30161812246969255595027486631318505202905989203199"],
  ["6400", "30223145490365729367654400000000000000000000000000"],
  ["6401", "30284593841470983679261366036050502296800378963201"],
  ["6402", "30346157498281020982380337839794637999691871035392"],
  ["6403", "30407836659101405880376492959848469147981640845523"],
  ["6404", "30469631522547700653276356899432197746695879000064"],
  ["6405", "30531542287545901328173283656412316020313720703125"],
  ["6406", "30593569153332874294806017761507793860308008329216"],
  ["6407", "30655712319456793466906620770700801153967475973607"],
  ["6408", "30717971985777577989914604095751722448799125209088"],
  ["6409", "30780348352467330495654669421103407169001686967529"],
  ["6410", "30842841620010775904576017756643411210000000000000"],
  ["6411", "30905451989205700776151748414048253794448911011131"],
  ["6412", "30968179661163393208037429870033393367631393718272"],
  ["6413", "31031024837309083284588485593049802259427443571453"],
  ["6414", "31093987719382384075336599461075903930020102201344"],
  ["6415", "31157068509437733184025907387425568841507568359375"],
  ["6416", "31220267409844834848810304199202313300068528029696"],
  ["6417", "31283584623289102594213757679450405923853684061137"],
  ["6418", "31347020352772102435456084989458982668959071674368"],
  ["6419", "31410574801611996635747210432339355616256069572259"],
  ["6420", "31474248173443988017153487703192453120000000000000"],
  ["6421", "31538040672220764825640234395186857727362120130661"],
  ["6422", "31601952502212946150895191594952449966486794493952"],
  ["6423", "31665983868009527901538186905134585367293536934583"],
  ["6424", "31730134974518329336322845177023521889682953601024"],
  ["6425", "31794406026966440151936757622148096561431884765625"],
  ["6426", "31858797230900668128007086798876173193924131659776"],
  ["6427", "31923308792187987329919152238672025556673084083467"],
  ["6428", "31987940917015986870056109186997588787721402318848"],
  ["6429", "32052693811893320228068401086185541000472922333389"],
  ["6430", "32117567683650155130782235022232730430000000000000"],
  ["6431", "32182562739438623992356898394637933002715634437791"],
  ["6432", "32247679186733274915301304548413831459521987346432"],
  ["6433", "32312917233331523252960725030515099288862510218913"],
  ["6434", "32378277087354103734085236499418328574957351215104"],
  ["6435", "32443758957245523150091981126741166988094482421875"],
  ["6436", "32509363051774513605633910583873460836185151635456"],
  ["6437", "32575089580034486333088255404888602445720518124597"],
  ["6438", "32640938751443986071578533659784944059533186326528"],
  ["6439", "32706910775747146011144485459651488710075546966919"],
  ["6440", "32773005863014143302674892847935651840000000000000"],
  ["6441", "32839224223641655134218818109890385434479661648521"],
  ["6442", "32905566068353315374291367455770177727926313459712"],
  ["6443", "32972031608200171782790661402707323805781603508443"],
  ["6444", "33038621054561143790143267995708472362456091459584"],
  ["6445", "33105334619143480845295930270143987330284423828125"],
  ["6446", "33172172513983221333171995066736445041109081972736"],
  ["6447", "33239134951445652062211526465667072190002917468527"],
  ["6448", "33306222144225768322614663709287700536657505681408"],
  ["6449", "33373434305348734515908360533328583669948605588849"],
  ["6450", "33440771648170345356457220324707031250000000000000"],
  ["6451", "33508234386377487645539719470346238253704033078851"],
  ["6452", "33575822733988602618611689656086021597405005217792"],
  ["6453", "33643536905354148866379508718085654237021991687173"],
  ["6454", "33711377115157065830306028941361967063572233150464"],
  ["6455", "33779343578413237873172851441551860742884521484375"],
  ["6456", "33847436510471958925323135456915951764195682287616"],
  ["6457", "33915656127016397707209712018288017625306746599257"],
  ["6458", "33984002644064063528873852555402083184292559167488"],
  ["6459", "34052476277967272666980624539074411312095644715179"],
  ["6460", "34121077245413615320037348250360463360000000000000"],
  ["6461", "34189805763426423142422251210326345635097967644781"],
  ["6462", "34258662049365237357850999697749753870577010204672"],
  ["6463", "34327646320926277452909370127176495232333108439103"],
  ["6464", "34396758796142910451280906856584963236874361503744"],
  ["6465", "34465999693386120769298997242732251497183837890625"],
  ["6466", "34535369231364980653453379463351833844524001796096"],
  ["6467", "34604867629127121200481683779023957468565607200787"],
  ["6468", "34674495106059203960677193514026268681550606368768"],
  ["6469", "34744251881887393125044598095074024222894699261909"],
  ["6470", "34814138176677828296936096999856971270000000000000"],
  ["6471", "34884154210837097848800800433954173253494955862311"],
  ["6472", "34954300205112712864680959975339413128437775204352"],
  ["6473", "35024576380593581669089150300559148098649610248233"],
  ["6474", "35094982958710484942901111436053277374647132135424"],
  ["6475", "35165520161236551426899549762277305126190185546875"],
  ["6476", "35236188210287734213604785237554052482313723314176"],
  ["6477", "35306987328323287628028722003215243743729602157117"],
  ["6478", "35377917738146244697989209681869541848610351669248"],
  ["6479", "35448979662903895214622453286835545992350376545039"],
  ["6480", "35520173326088264383731720723187630080000000000000"],
  ["6481", "35591498951536592068611188379761164877777163847441"],
  ["6482", "35662956763431812624984357287133630414832590200832"],
  ["6483", "35734546986303035328697064749321520092420580398563"],
  ["6484", "35806269845026025396805709246992026485983495061504"],
  ["6485", "35878125564823685602701899757665674094942626953125"],
  ["6486", "35950114371266538485915334443963850181477227257856"],
  ["6487", "36022236490273209157237307924716236017626059552247"],
  ["6488", "36094492148110908699807841065970242842468368252928"],
  ["6489", "36166881571395918166810022409920626009556820280569"],
  ["6490", "36239404987094073176415745999785534490000000000000"],
  ["6491", "36312062622521249104627626457978529359017757316171"],
  ["6492", "36384854705343846876662468733847883403774092378112"],
  ["6493", "36457781463579279357522266956058194858542572974093"],
  ["6494", "36530843125596458342399304303658582891250998697984"],
  ["6495", "36604039920116282147562523749300183345823974609375"],
  ["6496", "36677372076212123802372937928217164061712775643136"],
  ["6497", "36750839823310319843076445247753994721789216670177"],
  ["6498", "36824443391190659709023018674691332741027162103808"],
  ["6499", "36898183009986875741961833421677851602601954584499"],
  ["6500", "36972058910187133789062500000000000000000000000000"],
  ["6501", "37046071322634524410313169814000529241983545584501"],
  ["6502", "37120220478527554690946881642974284114525530120192"],
  ["6503", "37194506609420640659548118989610971707490096858823"],
  ["6504", "37268929947224600312492150370304288026370573860864"],
  ["6505", "37343490724207147245370327182189909468873291015625"],
  ["6506", "37418189172993384892055116804896592966673216086016"],
  ["6507", "37493025526566301372059252081980253894924463994907"],
  ["6508", "37568000018267264946843982278145815503769476005888"],
  ["6509", "37643112881796520085732015023931390082359407972829"],
  ["6510", "37718364351213684142081343639819634510000000000000"],
  ["6511", "37793754660938244640376759577037727232724345568431"],
  ["6512", "37869284045750057174896455522896277787659387011072"],
  ["6513", "37944952740789843920611730994684673392349139736753"],
  ["6514", "38020760981559692756978418988172091322162197766144"],
  ["6515", "38096709003923557005279259454945994247098388671875"],
  ["6516", "38172797044107755780177053057439843791928605802496"],
  ["6517", "38249025338701474956139036791845612734560749210437"],
  ["6518", "38325394124657268749393531677461177932980433543168"],
  ["6519", "38401903639291561916080521786674695830030517361559"],
  ["6520", "38478554120285152567258433433024593920000000000000"],
  ["6521", "38555345805683715601429993345881980737759465783961"],
  ["6522", "38632278933898306755250655139569341830788965834752"],
  ["6523", "38709353743705867273083694332442740132771332091883"],
  ["6524", "38786570474249729196066683587911898806027838029824"],
  ["6525", "38863929365040121271354671734841167926788330078125"],
  ["6526", "38941430655954675482206002479552250696825155608576"],
  ["6527", "39019074587238934199577321546024609939532741200767"],
  ["6528", "39096861399506857955894934275149743698391123099648"],
  ["6529", "39174791333741333841670289478329194519914812066689"],
  ["6530", "39252864631294684525627979575601557730000000000000"],
  ["6531", "39331081533889177899015261754129329540355710347091"],
  ["6532", "39409442283617537344762720059560768609358532575232"],
  ["6533", "39487947122943452632166303980793749849378351208213"],
  ["6534", "39566596294702091437761595208296714642458076667904"],
  ["6535", "39645390042100611493061770837675247762825927734375"],
  ["6536", "39724328608718673359831348353900651555926893920256"],
  ["6537", "39803412238508953833568415267828388461222354049897"],
  ["6538", "39882641175797659975868664285618802826229221859328"],
  ["6539", "39962015665285043776345173373719624083703377804219"],
  ["6540", "40041535952045917444778489037469040640000000000000"],
  ["6541", "40121202281530169334172190560419395124575302973821"],
  ["6542", "40201014899563280495389732854454697879765620416512"],
  ["6543", "40280974052346841864048985947970230695858165169743"],
  ["6544", "40361079986459072080351509991089693897264814096384"],
  ["6545", "40441332948855335942524225983404947189000244140625"],
  ["6546", "40521733186868663494551764231325851596510450753536"],
  ["6547", "40602280948210269748878394819113613024977231041827"],
  ["6548", "40682976480970075044759067130331067346974259806208"],
  ["6549", "40763820033617226042939708685068375732605500690149"],
  ["6550", "40844811855000617357347557263183593750000000000000"],
  ["6551", "40925952194349413824472924465225648566156130980151"],
  ["6552", "41007241301273573411124413520973648783177093742592"],
  ["6553", "41088679425764370761240239290922537942906716860473"],
  ["6554", "41170266818194921382438924018862395930058453131264"],
  ["6555", "41252003729320706472993268484228836363319091796875"],
  ["6556", "41333890410280098389912124771436720146567361724416"],
  ["6557", "41415927112594886758815123920240716857811788660557"],
  ["6558", "41498114088170805226286139246586141460627123724288"],
  ["6559", "41580451589298058855391894127714152530834991240479"],
  ["6560", "41662939868651852165052751528759132160000000000000"],
  ["6561", "41745579179292917813953351511015323088870709282081"],
  ["6562", "41828369774668045929681392404747155442688572137472"],
  ["6563", "41911311908610614083783481252165867574280647964403"],
  ["6564", "41994405835341117913427609529286860782303962988544"],
  ["6565", "42077651809467702390362441039110707838321533203125"],
  ["6566", "42161050085986693737864230233228976110952292048896"],
  ["6567", "42244600920283131996362821064837277448384420590087"],
  ["6568", "42328304568131304238438808803535602790015401197568"],
  ["6569", "42412161285695278433884580051503557770398588371209"],
  ["6570", "42496171329529437965522579491949238570000000000000"],
  ["6571", "42580334956579016796474785674438961383794264395611"],
  ["6572", "42664652424180635289578012398114804797964814385152"],
  ["6573", "42749123990062836679640286993191997056538662965533"],
  ["6574", "42833749912346624199234192023792768966306223284224"],
  ["6575", "42918530449545998858723692641411721706390380859375"],
  ["6576", "43003465860568497881221608008414485169699580542976"],
  ["6577", "43088556404715733793175521885241059214332461714417"],
  ["6578", "43173802341683934171280564633712464387568164610048"],
  ["6579", "43259203931564482046418136532319041884744843398339"],
  ["6580", "43344761434844456965320280427895930880000000000000"],
  ["6581", "43430475112407176710660050361961045694896338436741"],
  ["6582", "43516345225532739680268861909498540994186662469632"],
  ["6583", "43602372035898567926182449553411690456561133147863"],
  ["6584", "43688555805579950854217696489538848045691048034304"],
  ["6585", "43774896797050589584783242815320380770064697265625"],
  ["6586", "43861395273183141975627419100218962160114503622656"],
  ["6587", "43948051497249768307227693868126335964334512117547"],
  ["6588", "44034865732922677631526465541532674035105795145728"],
  ["6589", "44121838244274674784718671904486170249647884037869"],
  ["6590", "44208969295779708064797333136626877790000000000000"],
  ["6591", "44296259152313417574563787954136489415554313121471"],
  ["6592", "44383708079153684230810026365601393800018145574912"],
  ["6593", "44471316341981179440381167011836664980649540595393"],
  ["6594", "44559084206879915443826772008960541042364289654784"],
  ["6595", "44647011940337796327350337653739442087352294921875"],
  ["6596", "44735099809247169703766945279739821311029659303936"],
  ["6597", "44823348080905379063179702972422426881757721083477"],
  ["6598", "44911757023015316794086255761294301885502446788608"],
  ["6599", "45000326903685977875627289307891625785716458405799"],
  ["6600", "45089057991433014241689600000000000000000000000000"],
  ["6601", "45177950555179289817576952745425834084971253765801"],
  ["6602", "45267004864255436229962596633111064906395344084992"],
  ["6603", "45356221188400409190837957995731641831919362192123"],
  ["6604", "45445599797762045556172680266436274760547422961664"],
  ["6605", "45535140962897621060001830373364242642042236328125"],
  ["6606", "45624844954774408724656742259328107631600871202816"],
  ["6607", "45714712044770237947856619450857619447435631096207"],
  ["6608", "45804742504674054267378670430973706855590750322688"],
  ["6609", "45894936606686479804025202893895155270134659018129"],
  ["6610", "45985294623420374383606755777674413810000000000000"],
  ["6611", "46075816827901397338661001282812150229064473285731"],
  ["6612", "46166503493568569990627802891512002258226613583872"],
  ["6613", "46257354894274838813201469703706919324811993622053"],
  ["6614", "46348371304287639277581902202616145577304009170944"],
  ["6615", "46439552998289460380346979854676995889017333984375"],
  ["6616", "46530900251378409854669196736537989346201012535296"],
  ["6617", "46622413339068780065600207665699634045010181839737"],
  ["6618", "46714092537291614590147604091646343367390357331968"],
  ["6619", "46805938122395275482868896281227883997656726790859"],
  ["6620", "46897950371146011227708336105921822720000000000000"],
  ["6621", "46990129560728525376802873009740201705175216197261"],
  ["6622", "47082475968746545876984194505234816058581768855552"],
  ["6623", "47174989873223395084704461811606814712863313369183"],
  ["6624", "47267671552602560470114011013638854542987439898624"],
  ["6625", "47360521285748266011019950383342802524566650390625"],
  ["6626", "47453539351946044277455245268154232979354150117376"],
  ["6627", "47546726030903309207588543210508074097359474198067"],
  ["6628", "47640081602749929575705653723999224185653924200448"],
  ["6629", "47733606348038803152994259411369424254494335039989"],
  ["6630", "47827300547746431561864097869568917030000000000000"],
  ["6631", "47921164483273495824535517087420357371933862616391"],
  ["6632", "48015198436445432606629970802265116183635827884032"],
  ["6633", "48109402689513011156496684543700726794378486717513"],
  ["6634", "48203777525152910941010387855425083836256361160704"],
  ["6635", "48298323226468299978575673450590581031697998046875"],
  ["6636", "48393040076989413870074209822242264138236408365056"],
  ["6637", "48487928360674135528491700098671013570920894255197"],
  ["6638", "48582988361908575607962146704158629909836136112128"],
  ["6639", "48678220365507653632967648658929483575550753481519"],
  ["6640", "48773624656715679828432626128456253440000000000000"],
  ["6641", "48869201521206937651452035111898501142799052259121"],
  ["6642", "48964951245086267025393803942685828640520915853312"],
  ["6643", "49060874114889648277116392561395703436374609751043"],
  ["6644", "49156970417584786778043045312423403638720177373184"],
  ["6645", "49253240440571698289834978311801765350450439453125"],
  ["6646", "49349684471683295015406413234205477063053953294336"],
  ["6647", "49446302799185972356025040672972666220207197295127"],
  ["6648", "49543095711780196375242168038199705803903371051008"],
  ["6649", "49640063498601091970397479274917914977472292231449"],
  ["6650", "49737206449219031752444006506347656250000000000000"],
  ["6651", "49834524853640225634839587036550898562173128441451"],
  ["6652", "49932019002307311132251752982772414933327929147392"],
  ["6653", "50029689186099944369823675150677346236064793353773"],
  ["6654", "50127535696335391803749457615863958285893035352064"],
  ["6655", "50225558824769122653907754832760254423206787109375"],
  ["6656", "50323758863595402049303358957607024505592496521216"],
  ["6657", "50422136105447884887067081445993395851501151801857"],
  ["6658", "50520690843400210405764929867649622103913603801088"],
  ["6659", "50619423370966597473768258272221459969203425805779"],
  ["6660", "50718333982102440593437247339856936960000000000000"],
  ["6661", "50817422971204906621870748959935639174580302079381"],
  ["6662", "50916690633113532208976208800469018278787559350272"],
  ["6663", "51016137263110821953614059859903940717269287209703"],
  ["6664", "51115763156922847278571659932577227305950688313344"],
  ["6665", "51215568610719846025122526369202843365006103515625"],
  ["6666", "51315553921116822767927302474832422181416719261696"],
  ["6667", "51415719385174149851033571359020799746602343459387"],
  ["6668", "51516065300398169145732315036758206507008149946368"],
  ["6669", "51616591964741794531029499074406840650147997120509"],
  ["6670", "51717299676605115097491946082709013870000000000000"],
  ["6671", "51818188734835999075227344879224329588177935688911"],
  ["6672", "51919259438730698486758926175611978596648277245952"],
  ["6673", "52020512088034454525556020191308909792451243802833"],
  ["6674", "52121946982942103660982396654673184307800639873024"],
  ["6675", "52223564424098684470424973225872218608856201171875"],
  ["6676", "52325364712600045199366164463005973289582016331776"],
  ["6677", "52427348149993452050163830054473695148587739151717"],
  ["6678", "52529515038278198200303468156727955152368849870848"],
  ["6679", "52631865679906213550887987307619967165654901491639"],
  ["6680", "52734400377782675206131078531834183680000000000000"],
  ["6681", "52837119435266618684620897916746745751855347386041"],
  ["6682", "52940023156171549863121459114730454314456876818432"],
  ["6683", "53043111844766057653679824921777607125586722417163"],
  ["6684", "53146385805774427414807877292630526897191968047104"],
  ["6685", "53249845344377255097508135879707247576046142578125"],
  ["6686", "53353490766212062126913786427296122213304960147456"],
  ["6687", "53457322377373911020313772114077701275835610962847"],
  ["6688", "53561340484416021742334493216324877550806292758528"],
  ["6689", "53665545394350388798050353261442914992362650635169"],
  ["6690", "53769937414648399064796083157149625090000000000000"],
  ["6691", "53874516853241450363454468615872815898358534886771"],
  ["6692", "53979284018521570769993800547167545256335179251712"],
  ["6693", "54084239219342038668030062963440129682790533136693"],
  ["6694", "54189382765018003543189568336319901996007229251584"],
  ["6695", "54294714965327107520048446252954121221458740234375"],
  ["6696", "54400236130510107642426087653627104553822884724736"],
  ["6697", "54505946571271498897810343884732599162050420176777"],
  ["6698", "54611846598780137986692977274569807060956680593408"],
  ["6699", "54717936524669867837594557933999624203953216667099"],
  ["6700", "54824216661040142868558700000000000000000000000000"],
  ["6701", "54930687320456654995896229577909449822263219507101"],
  ["6702", "55037348815951960390960576197957384574434496929792"],
  ["6703", "55144201461026106985736379683860921492531520845423"],
  ["6704", "55251245569647262728024004936132212267494842302464"],
  ["6705", "55358481456252344587003358260600211203883056640625"],
  ["6706", "55465909435747648309961100523818490195656989679616"],
  ["6707", "55573529823509478930966054591818611342714261277507"],
  ["6708", "55681342935384782032278307207389254462576932159488"],
  ["6709", "55789349087691775759278208684027467893081556103429"],
  ["6710", "55897548597220583589702177542232909110000000000000"],
  ["6711", "56005941781233867857972921486211749069202610163031"],
  ["6712", "56114528957467464035412390916637153134037457436672"],
  ["6713", "56223310444131015767126486498191184351382889227353"],
  ["6714", "56332286559908610666351248149502004201016352415744"],
  ["6715", "56441457623959416867050959198103914431326904296875"],
  ["6716", "56550823955918320335559306344499774082677364228096"],
  ["6717", "56660385875896562942055443507609461406278465949037"],
  ["6718", "56770143704482381292667515579158294517139627040768"],
  ["6719", "56880097762741646322996906597209781009663213860159"],
  ["6720", "56990248372218503653857185859391979520000000000000"],
  ["6721", "57100595854936014710022435034720509859540287370561"],
  ["6722", "57211140533396798602780349399598367842764787556352"],
  ["6723", "57321882730583674777086216918887851760565164766483"],
  ["6724", "57432822769960306424114590017217895615898975207424"],
  ["6725", "57543960975471844660006176539225876331329345703125"],
  ["6726", "57655297671545573471608188580551605368752331186176"],
  ["6727", "57766833183091555430007100584417957445496967075367"],
  ["6728", "57878567835503278172653481341862798868841389621248"],
  ["6729", "57990501954658301654879278307446064771318407253289"],
  ["6730", "58102635866918906171608646946859648330000000000000"],
  ["6731", "58214969899132741150064132668631996843662607245691"],
  ["6732", "58327504378633474714270728259359891145032657272832"],
  ["6733", "58440239633241444022161045640932888979051400746813"],
  ["6734", "58553175991264306376085557199357553377199820693504"],
  ["6735", "58666313781497691107532578898355218396273193359375"],
  ["6736", "58779653333225852236863384887215162602348510969856"],
  ["6737", "58893194976222321908868561341751302184591022740497"],
  ["6738", "59006939040750564604952425839951658521250313084928"],
  ["6739", "59120885857564632132753057670342818500586989998819"],
  ["6740", "59235035757909819394006204101533450240000000000000"],
  ["6741", "59349389073523320931462047806168863615249025504421"],
  ["6742", "59463946136634888255664541331939957068794183770112"],
  ["6743", "59578707279967487952403735746662153706716221252343"],
  ["6744", "59693672836737960571652252354090726288604197289984"],
  ["6745", "59808843140657680298797768682386009353697509765625"],
  ["6746", "59924218525933215408984112788303305859232005595136"],
  ["6747", "60039799327266989505374283297575865285422900228427"],
  ["6748", "60155585879857943542149436515903331682130023415808"],
  ["6749", "60271578519402198633058606395770866677544696212749"],
  ["6750", "60387777582093719646334648132324218750000000000000"],
  ["6751", "60504183404624979586792621687031952461262741462751"],
  ["6752", "60620796324187624765927557601196627956686695432192"],
  ["6753", "60737616678473140760829274062850831701922305167073"],
  ["6754", "60854644805673519162732641330510645791296395812864"],
  ["6755", "60971881044481925116022417295977588711610107421875"],
  ["6756", "61089325734093365648512506186199590539405102678016"],
  ["6757", "61206979214205358793820221161441647474544120023157"],
  ["6758", "61324841825018603506656860863997043617489983397888"],
  ["6759", "61442913907237650371856639808710183237667064411079"],
  ["6760", "61561195802071573107966742884002037760000000000000"],
  ["6761", "61679687851234640866222005150209013710988062036681"],
  ["6762", "61798390396946991325728449581185868441994355843072"],
  ["6763", "61917303781935304585680647395603459288049910175003"],
  ["6764", "62036428349433477855438598166513070844457353478144"],
  ["6765", "62155764443183300943290559981871428428800048828125"],
  ["6766", "62275312407435132544728993555145020021596899434496"],
  ["6767", "62395072586948577331067518354159902866871859808687"],
  ["6768", "62515045326993163839227513528354795671623636615168"],
  ["6769", "62635230973349023163523731669852126605203441509809"],
  ["6770", "62755629872307568450279029242605137170000000000000"],
  ["6771", "62876242370672175196099053856630591109224885742211"],
  ["6772", "62997068815758862350638465451317707005323747786752"],
  ["6773", "63118109555396974224691005883336366868787036760133"],
  ["6774", "63239364937929863204436469391073337225059597901824"],
  ["6775", "63360835312215573272678364929126203060150146484375"],
  ["6776", "63482521027627524337906800433503130289010246680576"],
  ["6777", "63604422434055197372021858691134753734835118469017"],
  ["6778", "63726539881904820357553474646424892328807991451648"],
  ["6779", "63848873722100055045214564683172021583939466824939"],
  ["6780", "63971424306082684522624899672606228480000000000000"],
  ["6781", "64094191985813301595043955377829621768734706695341"],
  ["6782", "64217177113771997978951716151944900394578017247232"],
  ["6783", "64340380042959054309317150761930166268509832206463"],
  ["6784", "64463801126895630961394822613191420133845871099904"],
  ["6785", "64587440719624459687890840641020958926949462890625"],
  ["6786", "64711299175710536072340101675233701211611412832256"],
  ["6787", "64835376850241812799537520173368048488120240088147"],
  ["6788", "64959674098829893743866686856347132210450245091328"],
  ["6789", "65084191277610728876370143968724230222042436072469"],
  ["6790", "65208928743245309991406211623906936390000000000000"],
  ["6791", "65333886852920367253738046983392651182216538612071"],
  ["6792", "65459065964349066566901365858377589892703177408512"],
  ["6793", "65584466435771707763698004712447383717794834597993"],
  ["6794", "65710088625956423619663249985744224942793833488384"],
  ["6795", "65835932894199879690355611154358272662205810546875"],
  ["6796", "65961999600327974973318463984034724841032867905536"],
  ["6797", "66088289104696543395563741034947754852633397950077"],
  ["6798", "66214801768192056127428597624593728274779047518208"],
  ["6799", "66341537952232324723656733159124224757699945368399"],
  ["6800", "66468498018767205092556800000000000000000000000000"],
  ["6801", "66595682330279302294091084843025887862475158808401"],
  ["6802", "66723091249784676167748400950949659818768172654592"],
  ["6803", "66850725140833547791055883499201069551016656818723"],
  ["6804", "66978584367511006769585134767336899718985247883264"],
  ["6805", "67106669294437719359308920936670124880958251953125"],
  ["6806", "67234980286770637422165377837722782332143587516416"],
  ["6807", "67363517710203708215687439129879418391985638538807"],
  ["6808", "67492281930968585017555957090257812630690005516288"],
  ["6809", "67621273315835338585935743439682982009478215228729"],
  ["6810", "67750492232113169456454515441076280410000000000000"],
  ["6811", "67879939047651121076685490869880930239028072200331"],
  ["6812", "68009614130838793778995134377665889652228302569472"],
  ["6813", "68139517850607059592618317249109053669096710552653"],
  ["6814", "68269650576428777895823912589486036602614043500544"],
  ["6815", "68400012678319511909034608574909957725589599609375"],
  ["6816", "68530604526838246029765483551208667278525276880896"],
  ["6817", "68661426493088104010246648479816765584694085538337"],
  ["6818", "68792478948717067978596024500728788469067026669568"],
  ["6819", "68923762265918698304409086213735406211742494569459"],
  ["6820", "69055276817432854309633164670174904320000000000000"],
  ["6821", "69187022976546415825594668018606220518181595303861"],
  ["6822", "69319001117094005597048342259476186241709605937152"],
  ["6823", "69451211613458712534118459636341290001780570283783"],
  ["6824", "69583654840572815813002587824842316434883659956224"],
  ["6825", "69716331173918509826309359275747835636138916015625"],
  ["6826", "69849240989528629983902426825309084305476914814976"],
  ["6827", "69982384663987379365123559005233799999380903832667"],
  ["6828", "70115762574431056223268596366119693989813103362048"],
  ["6829", "70249375098548782343190758572519273463097944706589"],
  ["6830", "70383222614583232252906561035266591630000000000000"],
  ["6831", "70517305501331363290080369417613301399590460234991"],
  ["6832", "70651624138145146524264390486426346322339804741632"],
  ["6833", "70786178904932298535771668478523118396333577296113"],
  ["6834", "70920970182157014052060427415492468096760071266304"],
  ["6835", "71055998350840699442508871628402234895614013671875"],
  ["6836", "71191263792562707072460329146956789111754017734656"],
  ["6837", "71326766889461070517419395566272396002539623505797"],
  ["6838", "71462508024233240638280509528815133756936136777728"],
  ["6839", "71598487580136822518471165049526867046625403356119"],
  ["6840", "71734705940990313263892740569080791040000000000000"],
  ["6841", "71871163491173840666542699843890856763499338709721"],
  ["6842", "72007860615629902732702695572280652403539408166912"],
  ["6843", "72144797699864108076577883014428877139256283673643"],
  ["6844", "72281975129945917180273527789682380543962889846784"],
  ["6845", "72419393292509384520995769528895973300303955078125"],
  ["6846", "72557052574753901566364181121953111438033023655936"],
  ["6847", "72694953364444940638724541931875529752926423841727"],
  ["6848", "72833096049914799649351022547275509503347400900608"],
  ["6849", "72971481020063347703427758414674415726702428634049"],
  ["6850", "73110108664358771576700570031738281250000000000000"],
  ["6851", "73248979372838323064690368292098544267248686044051"],
  ["6852", "73388093536109067205360565052466706746274576596992"],
  ["6853", "73527451545348631376131591043548960420533336300373"],
  ["6854", "73667053792305955266136405868154160259192950513664"],
  ["6855", "73806900669302041724611668023199492705091552734375"],
  ["6856", "73946992569230708486320016647386514354075196194816"],
  ["6857", "74087329885559340774899701033479799007321977324457"],
  ["6858", "74227913012329644785038578853705246247542246514688"],
  ["6859", "74368742344158402044370289529129338200416023056379"],
  ["6860", "74509818276238224655991195229318594560000000000000"],
  ["6861", "74651141204338311422497468617444627225211305153981"],
  ["6862", "74792711524805204852442493658629139329061345615872"],
  ["6863", "74934529634563549050115533586049699772041400860303"],
  ["6864", "75076595931116848489543408470486050781410774482944"],
  ["6865", "75218910812548227673617713764913116193765869140625"],
  ["6866", "75361474677521191679250900696775962325748448567296"],
  ["6867", "75504287925280387589465329457049058268297453637987"],
  ["6868", "75647350955652366813320196787422771652044645203968"],
  ["6869", "75790664169046348294582030795309749493989437539109"],
  ["6870", "75934227966454982610045237631158448470000000000000"],
  ["6871", "76078042749455116958409977044136518441110030555511"],
  ["6872", "76222108920208561040625436790939077669498810007552"],
  ["6873", "76366426881462853832607369408622365109053725837433"],
  ["6874", "76510997036552031251239548976298178545996313370624"],
  ["6875", "76655819789397394714569600182585418224334716796875"],
  ["6876", "76800895544508280597110447286238611915449487589376"],
  ["6877", "76946224706982830581159426406696304189706283666317"],
  ["6878", "77091807682508762905047901009751601984409173352448"],
  ["6879", "77237644877364144509235017461480079598261455398239"],
  ["6880", "77383736698418164081160035111303905280000000000000"],
  ["6881", "77530083553131905999768463532962852103650932364641"],
  ["6882", "77676685849559125180628038300540338546796867756032"],
  ["6883", "77823543996347022822551366005893497092290946515763"],
  ["6884", "77970658402737023056642869134198327550836373192704"],
  ["6885", "78118029478565550498688461906182238299337158203125"],
  ["6886", "78265657634264808705807189269314843293052677677056"],
  ["6887", "78413543280863559538284862876102043023911283493447"],
  ["6888", "78561686829987903427510530126016598531686036144128"],
  ["6889", "78710088693862060550937415168839181624072556349769"],
  ["6890", "78858749285309152914990774171615971690000000000000"],
  ["6891", "79007669017751987346845911139401137573590440297371"],
  ["6892", "79156848305213839396000405151784007244652124045312"],
  ["6893", "79306287562319238146565405033240551483679728979293"],
  ["6894", "79455987204294751941201653215936301263802118365184"],
  ["6895", "79605947646969775017626706879082440511156005859375"],
  ["6896", "79756169306777315058620631359648172837296024846336"],
  ["6897", "79906652600754781656458248325500306910244738403377"],
  ["6898", "80057397946544775692696829284215242524566731563008"],
  ["6899", "80208405762395879634248933669229218260428360509699"],
  ["6900", "80359676467163448746670900000000000000000000000000"],
  ["6901", "80511210480310403225598308454787493650738787669701"],
  ["6902", "80663008221908021247260543623862549446913555259392"],
  ["6903", "80815070112636732939007397227763131527892854112023"],
  ["6904", "80967396573786915270781462189975845658695055704064"],
  ["6905", "81119988027259687868470881646469488712330322265625"],
  ["6906", "81272844895567709750077829256186892877498680713216"],
  ["6907", "81425967601835976985638910547253120790887046880107"],
  ["6908", "81579356569802621281834488993623408563939954393088"],
  ["6909", "81733012223819709492224755066514684007526752394029"],
  ["6910", "81886934988854044054051171644581687710000000000000"],
  ["6911", "82041125290487964352542744896754523920986175397631"],
  ["6912", "82195583554920149013667386072290843874767532982272"],
  ["6913", "82350310208966419126269446544254942249856341597953"],
  ["6914", "82505305680060542394535325955660102501555898425344"],
  ["6915", "82660570396255038221729871412241982060867919921875"],
  ["6916", "82816104786221983726147104352612255790688366493696"],
  ["6917", "82971909279253820690219631005715856282237524607637"],
  ["6918", "83127984305264163443731912217424831986299340218368"],
  ["6919", "83284330294788607682083388893090107045151084918759"],
  ["6920", "83440947678985540220548280361282437120000000000000"],
  ["6921", "83597836889636949685479694616128929494222055997161"],
  ["6922", "83754998359149238143406511641935098217659807997952"],
  ["6923", "83912432520554033668972323864518169971721213921083"],
  ["6924", "84070139807509003852666541209209006639246710145024"],
  ["6925", "84228120654298670249298592276151478290557861328125"],
  ["6926", "84386375495835223768166977769683466597601117003776"],
  ["6927", "84544904767659341005875757540567113870938968469967"],
  ["6928", "84703708905941001522751878417991816491356649422848"],
  ["6929", "84862788347480306063817576422946302236547863399889"],
  ["6930", "85022143529708295725272913966090586930000000000000"],
  ["6931", "85181774890687772067444340241999484342001937584291"],
  ["6932", "85341682869114118175155991237941601104860054290432"],
  ["6933", "85501867904316120666481274579546491737309500365413"],
  ["6934", "85662330436256792650833113838143133339432728879104"],
  ["6935", "85823070905534197637352056925570510256282958984375"],
  ["6936", "85984089753382274394552283802211430700599744659456"],
  ["6937", "86145387421671662762186379923229434248005580551097"],
  ["6938", "86306964352910530416290573646841645181325991190528"],
  ["6939", "86468820990245400588372968227283685450723309553419"],
  ["6940", "86630957777461980739708132014262435840000000000000"],
  ["6941", "86793375158985992191702244080494827857000107875021"],
  ["6942", "86956073579884000713293826699742414774462573043712"],
  ["6943", "87119053485864248066355930899918810863756081014943"],
  ["6944", "87282315323277484510066476719716955807506271043584"],
  ["6945", "87445859539117802265214285803122459104332275390625"],
  ["6946", "87609686581023469939409180574495059525341423476736"],
  ["6947", "87773796897277767914165361447959699905991852135027"],
  ["6948", "87938190936809824694828111338999220382656687505408"],
  ["6949", "88102869149195454224314715163729716344109205495349"],
  ["6950", "88267831984657994161641321032714843750000000000000"],
  ["6951", "88433079894069145126208309471684587152670678185351"],
  ["6952", "88598613328949810908817577231515188065704756641792"],
  ["6953", "88764432741470939650395983084648166854979970753673"],
  ["6954", "88930538584454365989400044445125914013611115454464"],
  ["6955", "89096931311373654178877815694947580817713623046875"],
  ["6956", "89263611376354942174164721750850519654008793071616"],
  ["6957", "89430579234177786692190963663248015926828007705757"],
  ["6958", "89597835340276009243378956903252372053504377151488"],
  ["6959", "89765380150738543137110107464832555847764417741679"],
  ["6960", "89933214122310281461741075987546767360000000000000"],
  ["6961", "90101337712392926040150525791301717409123347431281"],
  ["6962", "90269751379045837361798197008571605692772912668672"],
  ["6963", "90438455580986885492278995901810345305730643265603"],
  ["6964", "90607450777593301961355635964760263293741767327744"],
  ["6965", "90776737428902532630454215526349223886466064453125"],
  ["6966", "90946315995613091540607965305225933499102982660096"],
  ["6967", "91116186939085415741835248702060306565835608947287"],
  ["6968", "91286350721342721104938747565882561799941959712768"],
  ["6969", "91456807805071859116713616730301693570694501208409"],
  ["6970", "91627558653624174659553241785781787770000000000000"],
  ["6971", "91798603731016364776442086335614141898004286128811"],
  ["6972", "91969943501931338422325967376155272317753047908352"],
  ["6973", "92141578431719077202850950446656766054266995034733"],
  ["6974", "92313508986397497101462909810943829674908002279424"],
  ["6975", "92485735632653311195860653162656724452972412109375"],
  ["6976", "92658258837842893364796365188104627231180955058176"],
  ["6977", "92831079069993142986217979776346543534524918743617"],
  ["6978", "93004196797802350627748946735260598266823979573248"],
  ["6979", "93177612490641064730501715555443365905487783211539"],
  ["6980", "93351326618552959287222116061147054080000000000000"],
  ["6981", "93525339652255702515762673699466650873156540393941"],
  ["6982", "93699652063141826528883755745984067751072212344832"],
  ["6983", "93874264323279598001382303847414488922238549345063"],
  ["6984", "94049176905413889835548768079834357987571090325504"],
  ["6985", "94224390282967053825953718075153629482271728515625"],
  ["6986", "94399904930039794324566467758980173549503570681856"],
  ["6987", "94575721321412042907208911850264785505063625178747"],
  ["6988", "94751839932543834042348634497464509231330049916928"],
  ["6989", "94928261239576181763236213267773476801282327467069"],
  ["6990", "95104985719331957344392505165597890990000000000000"],
  ["6991", "95282013849316767983452565434248978823018355942671"],
  ["6992", "95459346107719836489373714591148721453664003162112"],
  ["6993", "95636982973414881978016134462042068888050500280593"],
  ["6994", "95814924925961001576105239914140479880974099881984"],
  ["6995", "95993172445603553134585939542139442307371826171875"],
  ["6996", "96171726013275038952379765735011734219344771547136"],
  ["6997", "96350586110595990511556722345733339566794525536677"],
  ["6998", "96529753219875854224934566601005046364524916727808"],
  ["6999", "96709227824113878197119110923944892383094178090999"],
  ["7000", "96889010407000000000000000000000000000000000000000"],
  ["7001", "97069101452915735463717288695316325813101822091001"],
  ["7002", "97249501446935068484115017337866962540179828744192"],
  ["7003", "97430210874825341847698852395129543610906196725323"],
  ["7004", "97611230223048149075115732728376840200604681764864"],
  ["7005", "97792559978760227284174334373057784299561767578125"],
  ["7006", "97974200629814351073426040187655030381696285270016"],
  ["7007", "98156152664760227427326974730166065583867770301407"],
  ["7008", "98338416572845391644002539362324229954784762789888"],
  ["7009", "98520992844016104286636757847210402917753283599329"],
  ["7010", "98703881968918249159509618596362291010000000000000"],
  ["7011", "98887084438898232309706476238223109410478235754931"],
  ["7012", "99070600746003882055524452321142032790855532675072"],
  ["7013", "99254431382985350042601652731502299552832666363253"],
  ["7014", "99438576843296013328795897801266640413844733190144"],
  ["7015", "99623037621093377498840540099651273413724365234375"],
  ["7016", "99807814211239980809805824551126721598286249066496"],
  ["7017", "99992907109304299368395125796853978816941267156937"],
];
