export const kanjiParts: {
  [key: string]: {
    parts: string[];
    cutConnect: boolean;
    cutOneStroke: boolean;
  }[];
} = {
  亜: [
    {
      parts: ["中", "工"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  哀: [
    {
      parts: ["衣", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  挨: [
    {
      parts: ["払", "矢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  愛: [
    {
      parts: ["爫", "冖", "心", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["受", "心", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曖: [
    {
      parts: ["日", "愛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悪: [
    {
      parts: ["亜", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["忠", "工"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  握: [
    {
      parts: ["扌", "屋"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["払", "尸", "一", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  圧: [
    {
      parts: ["厂", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  扱: [
    {
      parts: ["扌", "及"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宛: [
    {
      parts: ["宀", "夕", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嵐: [
    {
      parts: ["山", "風"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  安: [
    {
      parts: ["宀", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  案: [
    {
      parts: ["安", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宋", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暗: [
    {
      parts: ["日", "音"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  以: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衣: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  位: [
    {
      parts: ["亻", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  囲: [
    {
      parts: ["囗", "井"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  医: [
    {
      parts: ["匚", "矢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  依: [
    {
      parts: ["亻", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  委: [
    {
      parts: ["禾", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  威: [
    {
      parts: ["戌", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  為: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畏: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胃: [
    {
      parts: ["田", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尉: [
    {
      parts: ["尸", "示", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  異: [
    {
      parts: ["田", "共"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["早", "｜", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  移: [
    {
      parts: ["禾", "多"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  萎: [
    {
      parts: ["艹", "委"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  偉: [
    {
      parts: ["亻", "韋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  椅: [
    {
      parts: ["木", "奇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彙: [
    {
      parts: ["彑", "冖", "果"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  意: [
    {
      parts: ["音", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  違: [
    {
      parts: ["辶", "韋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  維: [
    {
      parts: ["糸", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慰: [
    {
      parts: ["心", "尉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遺: [
    {
      parts: ["辶", "貴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  緯: [
    {
      parts: ["糸", "韋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  域: [
    {
      parts: ["土", "或"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  育: [
    {
      parts: ["亠", "厶", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  一: [
    {
      parts: ["ー"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  壱: [
    {
      parts: ["士", "冖", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逸: [
    {
      parts: ["辶", "免"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  茨: [
    {
      parts: ["艹", "次"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  芋: [
    {
      parts: ["艹", "于"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  引: [
    {
      parts: ["弓", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  印: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  因: [
    {
      parts: ["口", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  困: [
    {
      parts: ["囚", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  咽: [
    {
      parts: ["口", "因"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  姻: [
    {
      parts: ["女", "因"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["如", "大"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  員: [
    {
      parts: ["口", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  院: [
    {
      parts: ["阝", "完"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  淫: [
    {
      parts: ["氵", "爫", "壬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "爫", "ノ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陰: [
    {
      parts: ["阝", "今", "云"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飲: [
    {
      parts: ["飠", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隠: [
    {
      parts: ["阝", "爫", "ヨ", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  韻: [
    {
      parts: ["音", "員"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  右: [
    {
      parts: ["ナ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宇: [
    {
      parts: ["宀", "于"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  羽: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唄: [
    {
      parts: ["口", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鬱: [
    {
      parts: ["林", "缶", "冖", "鬯", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鬯: [
    {
      parts: ["凵", "㐅", "丶", "丶", "丶", "丶", "匕"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  畝: [
    {
      parts: ["亠", "田", "久"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浦: [
    {
      parts: ["氵", "甫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  運: [
    {
      parts: ["辶", "軍"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["連", "冖"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雲: [
    {
      parts: ["雨", "云"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  永: [
    {
      parts: ["水", "丶", "一"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  泳: [
    {
      parts: ["氵", "永"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  英: [
    {
      parts: ["艹", "央"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  映: [
    {
      parts: ["日", "央"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  栄: [
    {
      parts: ["ツ", "冖", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  営: [
    {
      parts: ["ツ", "冖", "呂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詠: [
    {
      parts: ["言", "永"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  影: [
    {
      parts: ["景", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鋭: [
    {
      parts: ["金", "ソ", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衛: [
    {
      parts: ["行", "韋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  易: [
    {
      parts: ["日", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  疫: [
    {
      parts: ["疒", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  益: [
    {
      parts: ["丷", "一", "八", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  液: [
    {
      parts: ["氵", "夜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  駅: [
    {
      parts: ["馬", "尺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悦: [
    {
      parts: ["忄", "兑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兑: [
    {
      parts: ["丷", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  越: [
    {
      parts: ["走", "戉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謁: [
    {
      parts: ["言", "日", "匂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閲: [
    {
      parts: ["門", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["問", "ソ", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  円: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  延: [
    {
      parts: ["廴", "丿", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沿: [
    {
      parts: ["氵", "八", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  炎: [
    {
      parts: ["火", "火"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  怨: [
    {
      parts: ["夗", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夗: [
    {
      parts: ["夕", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宴: [
    {
      parts: ["安", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  媛: [
    {
      parts: ["女", "爫", "一", "友"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  援: [
    {
      parts: ["扌", "爫", "一", "友"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  園: [
    {
      parts: ["囗", "袁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["回", "衣", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  煙: [
    {
      parts: ["火", "垔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  猿: [
    {
      parts: ["犭", "袁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遠: [
    {
      parts: ["辶", "袁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鉛: [
    {
      parts: ["金", "八", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塩: [
    {
      parts: ["土", "ノ", "一", "口", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  演: [
    {
      parts: ["氵", "寅"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["油", "穴", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "只", "宀", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  縁: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  艶: [
    {
      parts: ["豊", "色"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  汚: [
    {
      parts: ["氵", "亐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  王: [
    {
      parts: ["一", "土"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["一", "干"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["一", "工"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["二", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["三", "丨"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  凹: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  央: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  応: [
    {
      parts: ["广", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  往: [
    {
      parts: ["彳", "主"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "住"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  押: [
    {
      parts: ["扌", "甲"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旺: [
    {
      parts: ["日", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吐", "一", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  欧: [
    {
      parts: ["区", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殴: [
    {
      parts: ["区", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  桜: [
    {
      parts: ["木", "ツ", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  翁: [
    {
      parts: ["公", "羽"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奥: [
    {
      parts: ["ノ", "冂", "米", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  横: [
    {
      parts: ["木", "黄"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["柚", "共"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  岡: [
    {
      parts: ["冂", "䒑", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  屋: [
    {
      parts: ["尸", "至"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  億: [
    {
      parts: ["亻", "意"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["位", "日", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憶: [
    {
      parts: ["忄", "意"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  臆: [
    {
      parts: ["月", "意"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虞: [
    {
      parts: ["虍", "呉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乙: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俺: [
    {
      parts: ["亻", "大", "电"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卸: [
    {
      parts: ["午", "一", "亠", "卩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  音: [
    {
      parts: ["立", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恩: [
    {
      parts: ["因", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  温: [
    {
      parts: ["氵", "日", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  穏: [
    {
      parts: ["禾", "爫", "ヨ", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  下: [
    {
      parts: ["一", "卜"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  化: [
    {
      parts: ["亻", "匕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  火: [
    {
      parts: ["ソ", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  加: [
    {
      parts: ["力", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  可: [
    {
      parts: ["丁", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仮: [
    {
      parts: ["亻", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  何: [
    {
      parts: ["亻", "可"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  花: [
    {
      parts: ["艹", "化"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  佳: [
    {
      parts: ["亻", "圭"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  価: [
    {
      parts: ["仙", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "山", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "覀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  果: [
    {
      parts: ["田", "木"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["日", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["呆", "十"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["口", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  河: [
    {
      parts: ["氵", "可"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汀", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  苛: [
    {
      parts: ["艹", "可"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  科: [
    {
      parts: ["禾", "斗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  架: [
    {
      parts: ["加", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["力", "呆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夏: [
    {
      parts: ["一", "自", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "白", "夂"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["百", "一", "夂"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  家: [
    {
      parts: ["宀", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  荷: [
    {
      parts: ["艹", "何"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["苛", "亻"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  華: [
    {
      parts: ["艹", "卄", "干", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["芋", "卄", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  菓: [
    {
      parts: ["艹", "果"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["苗", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["草", "丨", "人"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  貨: [
    {
      parts: ["化", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "旨", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  渦: [
    {
      parts: ["氵", "冎", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  過: [
    {
      parts: ["冎", "口", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嫁: [
    {
      parts: ["女", "家"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暇: [
    {
      parts: ["日", "叚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  禍: [
    {
      parts: ["礻", "冎", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  靴: [
    {
      parts: ["革", "化"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寡: [
    {
      parts: ["宀", "一", "ノ", "且", "分"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "一", "ノ", "且", "刀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  歌: [
    {
      parts: ["可", "可", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吹", "丁", "可"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  箇: [
    {
      parts: ["竹", "固"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  稼: [
    {
      parts: ["禾", "家"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  課: [
    {
      parts: ["言", "果"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "呆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蚊: [
    {
      parts: ["虫", "文"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  牙: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瓦: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  我: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  画: [
    {
      parts: ["一", "由", "凵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["山", "三", "凵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  芽: [
    {
      parts: ["艹", "牙"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賀: [
    {
      parts: ["加", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["力", "員"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雅: [
    {
      parts: ["牙", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  餓: [
    {
      parts: ["飠", "我"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  介: [
    {
      parts: ["𠆢", "ノ", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  回: [
    {
      parts: ["囗", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  灰: [
    {
      parts: ["厂", "火"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仄", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仄: [
    {
      parts: ["厂", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  会: [
    {
      parts: ["𠆢", "云"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  快: [
    {
      parts: ["忄", "ユ", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戒: [
    {
      parts: ["戈", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  改: [
    {
      parts: ["己", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  怪: [
    {
      parts: ["忄", "又", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拐: [
    {
      parts: ["扌", "口", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悔: [
    {
      parts: ["忄", "毎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  海: [
    {
      parts: ["氵", "毎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  界: [
    {
      parts: ["田", "介"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  皆: [
    {
      parts: ["比", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  械: [
    {
      parts: ["木", "戒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  絵: [
    {
      parts: ["糸", "会"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  開: [
    {
      parts: ["門", "开"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  階: [
    {
      parts: ["阝", "皆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塊: [
    {
      parts: ["土", "鬼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  楷: [
    {
      parts: ["木", "皆"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["柏", "比"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  解: [
    {
      parts: ["角", "刀", "牛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  潰: [
    {
      parts: ["氵", "貴"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泪", "中", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["沖", "一", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["沖", "三", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泪", "六", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  壊: [
    {
      parts: ["土", "十", "罒", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  懐: [
    {
      parts: ["忄", "十", "罒", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  諧: [
    {
      parts: ["言", "皆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貝: [
    {
      parts: ["目", "八"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["只", "二"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  外: [
    {
      parts: ["夕", "卜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  劾: [
    {
      parts: ["亥", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  害: [
    {
      parts: ["宀", "龶", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  崖: [
    {
      parts: ["山", "厂", "圭"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["岸", "十", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  涯: [
    {
      parts: ["氵", "厂", "圭"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "厂", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "圧", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汗", "厂", "十", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  街: [
    {
      parts: ["行", "圭"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慨: [
    {
      parts: ["忄", "既"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蓋: [
    {
      parts: ["艹", "去", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  該: [
    {
      parts: ["言", "亥"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  概: [
    {
      parts: ["木", "既"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  骸: [
    {
      parts: ["骨", "亥"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  垣: [
    {
      parts: ["土", "亘"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柿: [
    {
      parts: ["木", "市"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  各: [
    {
      parts: ["夂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  角: [
    {
      parts: ["ク", "月", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ク", "冂", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拡: [
    {
      parts: ["払", "广"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "広"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  革: [
    {
      parts: ["廿", "中", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  格: [
    {
      parts: ["木", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  核: [
    {
      parts: ["木", "亥"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殻: [
    {
      parts: ["士", "冗", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["売", "一", "殳"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  郭: [
    {
      parts: ["亠", "口", "子", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  覚: [
    {
      parts: ["ツ", "冖", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  較: [
    {
      parts: ["車", "交"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隔: [
    {
      parts: ["阝", "鬲"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閣: [
    {
      parts: ["門", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["問", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  確: [
    {
      parts: ["石", "冖", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  獲: [
    {
      parts: ["犭", "艹", "隹", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嚇: [
    {
      parts: ["口", "赤", "赤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  穫: [
    {
      parts: ["稚", "艹", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  学: [
    {
      parts: ["ツ", "冖", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  岳: [
    {
      parts: ["丘", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  楽: [
    {
      parts: ["白", "丶", "丶", "丶", "丶", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  額: [
    {
      parts: ["客", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  顎: [
    {
      parts: ["口", "号", "一", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  掛: [
    {
      parts: ["扌", "卦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  潟: [
    {
      parts: ["氵", "臼", "勹", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  括: [
    {
      parts: ["扌", "舌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  活: [
    {
      parts: ["氵", "舌"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "ノ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  喝: [
    {
      parts: ["口", "日", "匂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  渇: [
    {
      parts: ["氵", "日", "匂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  割: [
    {
      parts: ["害", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  葛: [
    {
      parts: ["艹", "曷"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  滑: [
    {
      parts: ["氵", "骨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  褐: [
    {
      parts: ["衤", "日", "匂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  轄: [
    {
      parts: ["車", "害"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  株: [
    {
      parts: ["木", "朱"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["林", "ノ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  釜: [
    {
      parts: ["父", "王", "丷"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["八", "丷", "金"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  鎌: [
    {
      parts: ["金", "兼"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "䒑", "コ", "｜", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刈: [
    {
      parts: ["㐅", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  干: [
    {
      parts: ["一", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刊: [
    {
      parts: ["干", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  甘: [
    {
      parts: ["廿", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  汗: [
    {
      parts: ["氵", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  缶: [
    {
      parts: ["午", "凵"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "一", "山"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  完: [
    {
      parts: ["宀", "元"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肝: [
    {
      parts: ["月", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  官: [
    {
      parts: ["宀", "㠯"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冠: [
    {
      parts: ["冖", "元", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巻: [
    {
      parts: ["龹", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  看: [
    {
      parts: ["ノ", "三", "右"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "二", "ノ", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陥: [
    {
      parts: ["阝", "ク", "旧"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乾: [
    {
      parts: ["十", "早", "乞"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["古", "一", "十", "乞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勘: [
    {
      parts: ["甚", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  患: [
    {
      parts: ["忠", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["串", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貫: [
    {
      parts: ["員", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寒: [
    {
      parts: ["宀", "卄", "二", "八", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "十", "土", "八", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  喚: [
    {
      parts: ["口", "ク", "冂", "儿", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堪: [
    {
      parts: ["土", "甚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  換: [
    {
      parts: ["扌", "ク", "冂", "儿", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敢: [
    {
      parts: ["丁", "耳", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  棺: [
    {
      parts: ["木", "官"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  款: [
    {
      parts: ["士", "示", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  間: [
    {
      parts: ["問", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["門", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閑: [
    {
      parts: ["門", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "｜", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勧: [
    {
      parts: ["ノ", "二", "隹", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寛: [
    {
      parts: ["宀", "艹", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幹: [
    {
      parts: ["十", "早", "𠆢", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["古", "一", "十", "𠆢", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  感: [
    {
      parts: ["戌", "口", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漢: [
    {
      parts: ["氵", "艹", "口", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慣: [
    {
      parts: ["忄", "貫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  管: [
    {
      parts: ["竹", "官"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  関: [
    {
      parts: ["門", "ソ", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "ソ", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  歓: [
    {
      parts: ["ノ", "二", "隹", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  監: [
    {
      parts: ["臣", "ノ", "二", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  緩: [
    {
      parts: ["糸", "爫", "一", "友"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憾: [
    {
      parts: ["忄", "感"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  還: [
    {
      parts: ["哀", "冂", "｜", "一", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  館: [
    {
      parts: ["飠", "官"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  環: [
    {
      parts: ["王", "哀", "冂", "｜", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  簡: [
    {
      parts: ["竹", "間"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  観: [
    {
      parts: ["ノ", "二", "隹", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  韓: [
    {
      parts: ["十", "早", "韋"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["古", "一", "十", "韋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  艦: [
    {
      parts: ["舟", "監"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鑑: [
    {
      parts: ["金", "監"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丸: [
    {
      parts: ["九", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["メ", "乙"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  含: [
    {
      parts: ["今", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["合", "フ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  岸: [
    {
      parts: ["山", "厂", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  岩: [
    {
      parts: ["山", "石"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  玩: [
    {
      parts: ["王", "元"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  眼: [
    {
      parts: ["目", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頑: [
    {
      parts: ["元", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  顔: [
    {
      parts: ["彦", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  願: [
    {
      parts: ["原", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  企: [
    {
      parts: ["へ", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伎: [
    {
      parts: ["亻", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  危: [
    {
      parts: ["厃", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ク", "厄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  机: [
    {
      parts: ["木", "几"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  気: [
    {
      parts: ["气", "メ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  岐: [
    {
      parts: ["山", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  希: [
    {
      parts: ["㐅", "布"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  忌: [
    {
      parts: ["己", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  汽: [
    {
      parts: ["氵", "气"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奇: [
    {
      parts: ["大", "可"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  祈: [
    {
      parts: ["礻", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  季: [
    {
      parts: ["禾", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "李"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紀: [
    {
      parts: ["糸", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  軌: [
    {
      parts: ["車", "九"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  既: [
    {
      parts: ["艮", "旡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  記: [
    {
      parts: ["言", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  起: [
    {
      parts: ["走", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飢: [
    {
      parts: ["飠", "几"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鬼: [
    {
      parts: ["ノ", "田", "儿", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["白", "｜", "儿", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  帰: [
    {
      parts: ["リ", "ヨ", "冖", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  基: [
    {
      parts: ["其", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寄: [
    {
      parts: ["宀", "奇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  規: [
    {
      parts: ["夫", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亀: [
    {
      parts: ["ク", "电", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ク", "甲", "电"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  喜: [
    {
      parts: ["吉", "ソ", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幾: [
    {
      parts: ["幺", "幺", "戈", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  揮: [
    {
      parts: ["扌", "軍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  期: [
    {
      parts: ["其", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  棋: [
    {
      parts: ["木", "其"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貴: [
    {
      parts: ["中", "一", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["中", "三", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["中", "二", "日", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "六", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["責", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["貢", "巾"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  棄: [
    {
      parts: ["亠", "ム", "廿", "｜", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  毀: [
    {
      parts: ["臼", "土", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旗: [
    {
      parts: ["方", "ノ", "一", "其"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  器: [
    {
      parts: ["口", "口", "口", "口", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["品", "口", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畿: [
    {
      parts: ["幺", "幺", "戈", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  輝: [
    {
      parts: ["光", "軍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  機: [
    {
      parts: ["木", "幾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  騎: [
    {
      parts: ["馬", "奇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  技: [
    {
      parts: ["扌", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宜: [
    {
      parts: ["宀", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  偽: [
    {
      parts: ["亻", "為"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  欺: [
    {
      parts: ["其", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  義: [
    {
      parts: ["⺷", "我"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  疑: [
    {
      parts: ["ヒ", "矢", "マ", "疋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  儀: [
    {
      parts: ["亻", "義"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["住", "丶", "我"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戯: [
    {
      parts: ["虚", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  擬: [
    {
      parts: ["扌", "疑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  犠: [
    {
      parts: ["牛", "義"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  議: [
    {
      parts: ["言", "義"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "䒑", "一", "我"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  菊: [
    {
      parts: ["艹", "勹", "米"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吉: [
    {
      parts: ["士", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  喫: [
    {
      parts: ["口", "契"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吐", "一", "刀", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詰: [
    {
      parts: ["言", "吉"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  却: [
    {
      parts: ["去", "卩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  客: [
    {
      parts: ["宀", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脚: [
    {
      parts: ["月", "却"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逆: [
    {
      parts: ["屰", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虐: [
    {
      parts: ["虍", "Ｅ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  九: [
    {
      parts: ["ノ", "乙"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  久: [
    {
      parts: ["ノ", "ス"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ク", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  及: [
    {
      parts: ["乃", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弓: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丘: [
    {
      parts: ["斤", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  旧: [
    {
      parts: ["丨", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  休: [
    {
      parts: ["亻", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吸: [
    {
      parts: ["口", "及"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朽: [
    {
      parts: ["木", "丂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  臼: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  求: [
    {
      parts: ["一", "氺", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  究: [
    {
      parts: ["穴", "九"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泣: [
    {
      parts: ["氵", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  急: [
    {
      parts: ["ク", "ヨ", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  級: [
    {
      parts: ["糸", "及"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  糾: [
    {
      parts: ["糸", "丩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宮: [
    {
      parts: ["宀", "呂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  救: [
    {
      parts: ["求", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  球: [
    {
      parts: ["王", "求"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  給: [
    {
      parts: ["糸", "合"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嗅: [
    {
      parts: ["口", "自", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  窮: [
    {
      parts: ["穴", "身", "弓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  牛: [
    {
      parts: ["ノ", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  去: [
    {
      parts: ["土", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["云", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巨: [
    {
      parts: ["匚", "コ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  居: [
    {
      parts: ["尸", "古"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拒: [
    {
      parts: ["扌", "巨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拠: [
    {
      parts: ["扌", "処"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  挙: [
    {
      parts: ["兴", "手"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虚: [
    {
      parts: ["虍", "业"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  許: [
    {
      parts: ["言", "午"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "ノ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  距: [
    {
      parts: ["⻊", "巨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  魚: [
    {
      parts: ["ク", "田", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  御: [
    {
      parts: ["彳", "卸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漁: [
    {
      parts: ["氵", "魚"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "ク", "口", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凶: [
    {
      parts: ["凵", "㐅"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  共: [
    {
      parts: ["十", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丩: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  叫: [
    {
      parts: ["口", "丩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  狂: [
    {
      parts: ["犭", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  京: [
    {
      parts: ["亠", "口", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["六", "口", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  享: [
    {
      parts: ["亠", "口", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  供: [
    {
      parts: ["亻", "共"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  協: [
    {
      parts: ["十", "力", "力", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  況: [
    {
      parts: ["氵", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  峡: [
    {
      parts: ["山", "夹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  挟: [
    {
      parts: ["扌", "夹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  狭: [
    {
      parts: ["犭", "夹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恐: [
    {
      parts: ["工", "凡", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恭: [
    {
      parts: ["共", "㣺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胸: [
    {
      parts: ["月", "勹", "凶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脅: [
    {
      parts: ["力", "力", "力", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  強: [
    {
      parts: ["弓", "ム", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["弘", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  教: [
    {
      parts: ["孝", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  郷: [
    {
      parts: ["乡", "艮", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  境: [
    {
      parts: ["土", "竟"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  橋: [
    {
      parts: ["木", "喬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  矯: [
    {
      parts: ["矢", "喬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鏡: [
    {
      parts: ["金", "竟"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  競: [
    {
      parts: ["立", "兄", "立", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  響: [
    {
      parts: ["郷", "音"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  驚: [
    {
      parts: ["敬", "馬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仰: [
    {
      parts: ["亻", "卬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暁: [
    {
      parts: ["日", "十", "艹", "一", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  業: [
    {
      parts: ["业", "羊", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["业", "䒑", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凝: [
    {
      parts: ["冫", "疑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曲: [
    {
      parts: ["由", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "艹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  局: [
    {
      parts: ["コ", "句"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "フ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  極: [
    {
      parts: ["木", "丂", "口", "又", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  玉: [
    {
      parts: ["王", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巾: [
    {
      parts: ["冂", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斤: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  均: [
    {
      parts: ["土", "勹", "冫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  近: [
    {
      parts: ["斤", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  金: [
    {
      parts: ["全", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  菌: [
    {
      parts: ["艹", "禾", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勤: [
    {
      parts: ["艹", "口", "龶", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  琴: [
    {
      parts: ["王", "王", "今"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  筋: [
    {
      parts: ["竹", "月", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  僅: [
    {
      parts: ["亻", "堇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  禁: [
    {
      parts: ["林", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  緊: [
    {
      parts: ["臣", "又", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  錦: [
    {
      parts: ["金", "白", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謹: [
    {
      parts: ["言", "艹", "口", "龶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "艹", "口", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  襟: [
    {
      parts: ["衤", "禁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吟: [
    {
      parts: ["口", "今"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  銀: [
    {
      parts: ["金", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  区: [
    {
      parts: ["匚", "㐅"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  句: [
    {
      parts: ["勹", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  苦: [
    {
      parts: ["艹", "古"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  駆: [
    {
      parts: ["馬", "区"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  具: [
    {
      parts: ["貝", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旦", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  惧: [
    {
      parts: ["忄", "具"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  愚: [
    {
      parts: ["禺", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  空: [
    {
      parts: ["穴", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  偶: [
    {
      parts: ["亻", "禺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遇: [
    {
      parts: ["辶", "禺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隅: [
    {
      parts: ["阝", "禺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  串: [
    {
      parts: ["中", "口"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  屈: [
    {
      parts: ["尸", "出"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  掘: [
    {
      parts: ["扌", "屈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  窟: [
    {
      parts: ["穴", "屈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  熊: [
    {
      parts: ["能", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  繰: [
    {
      parts: ["糸", "品", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  君: [
    {
      parts: ["尹", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尹: [
    {
      parts: ["ナ", "コ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  訓: [
    {
      parts: ["言", "川"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勲: [
    {
      parts: ["動", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["千", "黒", "力"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  薫: [
    {
      parts: ["艹", "重", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "千", "黒"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  軍: [
    {
      parts: ["冖", "車"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  郡: [
    {
      parts: ["君", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  群: [
    {
      parts: ["君", "羊"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兄: [
    {
      parts: ["口", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刑: [
    {
      parts: ["开", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  形: [
    {
      parts: ["开", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  系: [
    {
      parts: ["ノ", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  径: [
    {
      parts: ["彳", "又", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  茎: [
    {
      parts: ["艹", "又", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  係: [
    {
      parts: ["亻", "系"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  型: [
    {
      parts: ["刑", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  契: [
    {
      parts: ["龶", "刀", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  計: [
    {
      parts: ["言", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恵: [
    {
      parts: ["十", "日", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "思"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  啓: [
    {
      parts: ["戸", "攵", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  掲: [
    {
      parts: ["扌", "日", "匂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  渓: [
    {
      parts: ["氵", "爫", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  経: [
    {
      parts: ["糸", "又", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蛍: [
    {
      parts: ["ツ", "冖", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敬: [
    {
      parts: ["艹", "句", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  景: [
    {
      parts: ["日", "京"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  軽: [
    {
      parts: ["車", "又", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  傾: [
    {
      parts: ["化", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["伯", "ヒ", "二", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  携: [
    {
      parts: ["推", "乃"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  継: [
    {
      parts: ["糸", "米", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詣: [
    {
      parts: ["言", "旨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慶: [
    {
      parts: ["广", "コ", "｜", "｜", "乛", "心", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憬: [
    {
      parts: ["忄", "景"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  稽: [
    {
      parts: ["禾", "尤", "ヒ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["化", "尤", "一", "𠆢", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "尤", "ヒ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憩: [
    {
      parts: ["舌", "息"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "一", "口", "ノ", "口", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  警: [
    {
      parts: ["敬", "言"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鶏: [
    {
      parts: ["爫", "夫", "鳥"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  芸: [
    {
      parts: ["艹", "云"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  迎: [
    {
      parts: ["卬", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鯨: [
    {
      parts: ["魚", "京"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隙: [
    {
      parts: ["阝", "小", "日", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  劇: [
    {
      parts: ["豦", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豦: [
    {
      parts: ["虍", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虍: [
    {
      parts: ["卜", "丿", "乛", "七"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  撃: [
    {
      parts: ["車", "殳", "手"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  激: [
    {
      parts: ["泊", "放"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  桁: [
    {
      parts: ["木", "行"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  欠: [
    {
      parts: ["ノ", "乛", "人"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  穴: [
    {
      parts: ["宀", "八"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "儿"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  血: [
    {
      parts: ["ノ", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  決: [
    {
      parts: ["氵", "ユ", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  結: [
    {
      parts: ["糸", "吉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  傑: [
    {
      parts: ["休", "舛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  潔: [
    {
      parts: ["氵", "龶", "刀", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  月: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  犬: [
    {
      parts: ["大", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  件: [
    {
      parts: ["亻", "牛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  見: [
    {
      parts: ["兄", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["目", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  券: [
    {
      parts: ["龹", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肩: [
    {
      parts: ["戸", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  建: [
    {
      parts: ["聿", "廴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  研: [
    {
      parts: ["石", "开"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  県: [
    {
      parts: ["目", "L", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旧", "示"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  倹: [
    {
      parts: ["亻", "㑒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兼: [
    {
      parts: ["ソ", "二", "｜", "｜", "コ", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  剣: [
    {
      parts: ["㑒", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拳: [
    {
      parts: ["龹", "手"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  軒: [
    {
      parts: ["車", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  健: [
    {
      parts: ["亻", "建"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  険: [
    {
      parts: ["阝", "㑒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  圏: [
    {
      parts: ["口", "巻"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["因", "ソ", "一", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堅: [
    {
      parts: ["臣", "又", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  検: [
    {
      parts: ["木", "㑒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嫌: [
    {
      parts: ["女", "兼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  献: [
    {
      parts: ["南", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  絹: [
    {
      parts: ["糸", "口", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遣: [
    {
      parts: ["中", "一", "㠯", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  権: [
    {
      parts: ["木", "ノ", "二", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憲: [
    {
      parts: ["宀", "龶", "罒", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賢: [
    {
      parts: ["臣", "又", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謙: [
    {
      parts: ["言", "兼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鍵: [
    {
      parts: ["金", "建"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "コ", "二", "廴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  繭: [
    {
      parts: ["艹", "冂", "｜", "糸", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  顕: [
    {
      parts: ["日", "业", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  験: [
    {
      parts: ["馬", "㑒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  懸: [
    {
      parts: ["県", "系", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  元: [
    {
      parts: ["一", "兀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幻: [
    {
      parts: ["幺", "𠃌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  玄: [
    {
      parts: ["亠", "幺"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  言: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弦: [
    {
      parts: ["弓", "玄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  限: [
    {
      parts: ["阝", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  原: [
    {
      parts: ["厂", "白", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  現: [
    {
      parts: ["王", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舷: [
    {
      parts: ["舟", "玄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  減: [
    {
      parts: ["氵", "咸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  源: [
    {
      parts: ["氵", "原"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泊", "厂", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  厳: [
    {
      parts: ["ツ", "厂", "敢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  己: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戸: [
    {
      parts: ["一", "尸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  古: [
    {
      parts: ["十", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  呼: [
    {
      parts: ["口", "乎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乎: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  固: [
    {
      parts: ["囗", "古"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["回", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  股: [
    {
      parts: ["月", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虎: [
    {
      parts: ["虍", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  孤: [
    {
      parts: ["子", "瓜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弧: [
    {
      parts: ["弓", "瓜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  故: [
    {
      parts: ["古", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  枯: [
    {
      parts: ["木", "古"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  個: [
    {
      parts: ["亻", "固"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  庫: [
    {
      parts: ["广", "車"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  湖: [
    {
      parts: ["汁", "口", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["氵", "胡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雇: [
    {
      parts: ["戸", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誇: [
    {
      parts: ["言", "大", "一", "丂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鼓: [
    {
      parts: ["十", "豆", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吉", "䒑", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  錮: [
    {
      parts: ["金", "固"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "回"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  顧: [
    {
      parts: ["雇", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  五: [
    {
      parts: ["二", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  互: [
    {
      parts: ["彑", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  午: [
    {
      parts: ["ノ", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  呉: [
    {
      parts: ["口", "L", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  後: [
    {
      parts: ["彳", "幺", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  娯: [
    {
      parts: ["女", "呉"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["如", "L", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悟: [
    {
      parts: ["忄", "吾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  碁: [
    {
      parts: ["其", "石"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  語: [
    {
      parts: ["言", "吾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誤: [
    {
      parts: ["言", "呉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  護: [
    {
      parts: ["誰", "艹", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  口: [
    {
      parts: ["ロ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["丨", "コ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  工: [
    {
      parts: ["エ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "｜"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  公: [
    {
      parts: ["八", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勾: [
    {
      parts: ["勹", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  孔: [
    {
      parts: ["子", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  功: [
    {
      parts: ["工", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巧: [
    {
      parts: ["工", "丂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  広: [
    {
      parts: ["广", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  甲: [
    {
      parts: ["口", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["日", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  交: [
    {
      parts: ["六", "乂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["文", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亠", "父"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  光: [
    {
      parts: ["⺌", "兀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  向: [
    {
      parts: ["ノ", "冂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  后: [
    {
      parts: ["ノ", "ノ", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  好: [
    {
      parts: ["女", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  江: [
    {
      parts: ["氵", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  考: [
    {
      parts: ["耂", "丂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  行: [
    {
      parts: ["彳", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坑: [
    {
      parts: ["土", "亢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  孝: [
    {
      parts: ["耂", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抗: [
    {
      parts: ["扌", "亢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  攻: [
    {
      parts: ["工", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  更: [
    {
      parts: ["二", "史"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  効: [
    {
      parts: ["交", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幸: [
    {
      parts: ["辛", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "ソ", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拘: [
    {
      parts: ["扌", "句"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肯: [
    {
      parts: ["止", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  侯: [
    {
      parts: ["亻", "ユ", "矢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  厚: [
    {
      parts: ["厂", "日", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恒: [
    {
      parts: ["忄", "亘"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["怛", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旧", "八", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  洪: [
    {
      parts: ["氵", "共"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  皇: [
    {
      parts: ["白", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紅: [
    {
      parts: ["糸", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  荒: [
    {
      parts: ["艹", "亡", "儿", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  郊: [
    {
      parts: ["交", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  香: [
    {
      parts: ["禾", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["杏", "ノ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  候: [
    {
      parts: ["侯", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  校: [
    {
      parts: ["木", "交"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  耕: [
    {
      parts: ["耒", "井"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  航: [
    {
      parts: ["舟", "亢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貢: [
    {
      parts: ["工", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  降: [
    {
      parts: ["阝", "夂", "ヰ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  高: [
    {
      parts: ["亠", "口", "冂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  康: [
    {
      parts: ["广", "隶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  控: [
    {
      parts: ["扌", "空"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  梗: [
    {
      parts: ["木", "更"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  黄: [
    {
      parts: ["共", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  喉: [
    {
      parts: ["口", "侯"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慌: [
    {
      parts: ["忄", "荒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  港: [
    {
      parts: ["氵", "巷"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  硬: [
    {
      parts: ["石", "更"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  絞: [
    {
      parts: ["糸", "交"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  項: [
    {
      parts: ["工", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  溝: [
    {
      parts: ["汁", "十", "再"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["氵", "冓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鉱: [
    {
      parts: ["金", "広"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  構: [
    {
      parts: ["木", "冓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  綱: [
    {
      parts: ["糸", "岡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酵: [
    {
      parts: ["酉", "孝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  稿: [
    {
      parts: ["禾", "高"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "亠", "口", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  興: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衡: [
    {
      parts: ["行", "ク", "田", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鋼: [
    {
      parts: ["金", "岡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  講: [
    {
      parts: ["言", "冓"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "十", "再"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  購: [
    {
      parts: ["貝", "冓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乞: [
    {
      parts: ["ノ", "一", "乙"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  号: [
    {
      parts: ["口", "丂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  合: [
    {
      parts: ["𠆢", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拷: [
    {
      parts: ["扌", "考"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  剛: [
    {
      parts: ["岡", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  傲: [
    {
      parts: ["仁", "放"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["倣", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豪: [
    {
      parts: ["亠", "口", "冖", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  克: [
    {
      parts: ["古", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  告: [
    {
      parts: ["⺧", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  谷: [
    {
      parts: ["八", "𠆢", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刻: [
    {
      parts: ["亥", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  国: [
    {
      parts: ["囗", "玉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  黒: [
    {
      parts: ["里", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  穀: [
    {
      parts: ["士", "冖", "禾", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["士", "冖", "ノ", "机", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酷: [
    {
      parts: ["酉", "告"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  獄: [
    {
      parts: ["犭", "言", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  骨: [
    {
      parts: ["冎", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  駒: [
    {
      parts: ["馬", "句"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  込: [
    {
      parts: ["辶", "入"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頃: [
    {
      parts: ["ヒ", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  今: [
    {
      parts: ["𠆢", "ラ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["囗", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昆: [
    {
      parts: ["比", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恨: [
    {
      parts: ["忄", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  根: [
    {
      parts: ["木", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  婚: [
    {
      parts: ["女", "昏"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["如", "氏", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  混: [
    {
      parts: ["氵", "昆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  痕: [
    {
      parts: ["疒", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紺: [
    {
      parts: ["糸", "甘"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  魂: [
    {
      parts: ["云", "鬼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  墾: [
    {
      parts: ["貇", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  懇: [
    {
      parts: ["貇", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  左: [
    {
      parts: ["ナ", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  佐: [
    {
      parts: ["亻", "左"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沙: [
    {
      parts: ["氵", "少"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  査: [
    {
      parts: ["木", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  砂: [
    {
      parts: ["石", "少"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唆: [
    {
      parts: ["口", "夋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  差: [
    {
      parts: ["⺷", "ノ", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詐: [
    {
      parts: ["言", "乍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鎖: [
    {
      parts: ["金", "小", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  座: [
    {
      parts: ["广", "坐"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["庄", "人", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  挫: [
    {
      parts: ["扌", "坐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  才: [
    {
      parts: ["オ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  再: [
    {
      parts: ["王", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  災: [
    {
      parts: ["く", "く", "く", "火"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妻: [
    {
      parts: ["十", "一", "コ", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  采: [
    {
      parts: ["爫", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  砕: [
    {
      parts: ["石", "九", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宰: [
    {
      parts: ["宀", "辛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  栽: [
    {
      parts: ["十", "戈", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彩: [
    {
      parts: ["采", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["爫", "杉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  採: [
    {
      parts: ["扌", "采"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  済: [
    {
      parts: ["氵", "斉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  祭: [
    {
      parts: ["月", "又", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斎: [
    {
      parts: ["斉", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  細: [
    {
      parts: ["糸", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["紅", "H"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  菜: [
    {
      parts: ["艹", "采"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  最: [
    {
      parts: ["日", "取"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  裁: [
    {
      parts: ["十", "戈", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  債: [
    {
      parts: ["亻", "責"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "十", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "六", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "土", "一", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  催: [
    {
      parts: ["仙", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塞: [
    {
      parts: ["宀", "十", "土", "八", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "卄", "二", "八", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  歳: [
    {
      parts: ["止", "戌", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  載: [
    {
      parts: ["十", "戈", "車"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  際: [
    {
      parts: ["阝", "祭"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  埼: [
    {
      parts: ["土", "奇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  在: [
    {
      parts: ["一", "ノ", "｜", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "仕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  材: [
    {
      parts: ["木", "才"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  剤: [
    {
      parts: ["斉", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  財: [
    {
      parts: ["貝", "才"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  罪: [
    {
      parts: ["罒", "非"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  崎: [
    {
      parts: ["山", "奇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  作: [
    {
      parts: ["亻", "乍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  削: [
    {
      parts: ["肖", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昨: [
    {
      parts: ["日", "乍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柵: [
    {
      parts: ["木", "冊"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  索: [
    {
      parts: ["十", "冖", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  策: [
    {
      parts: ["竹", "朿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酢: [
    {
      parts: ["酉", "乍"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["昨", "兀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  搾: [
    {
      parts: ["扌", "穴", "乍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  錯: [
    {
      parts: ["金", "昔"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "亠", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  咲: [
    {
      parts: ["口", "关"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冊: [
    {
      parts: ["冂", "艹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  札: [
    {
      parts: ["木", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刷: [
    {
      parts: ["尸", "巾", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刹: [
    {
      parts: ["杀", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["利", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拶: [
    {
      parts: ["扌", "く", "く", "く", "夕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殺: [
    {
      parts: ["㐅", "木", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  察: [
    {
      parts: ["宀", "祭"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宗", "月", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  撮: [
    {
      parts: ["扌", "最"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  擦: [
    {
      parts: ["扌", "察"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雑: [
    {
      parts: ["九", "椎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  皿: [
    {
      parts: ["冂", "｜", "亠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  三: [
    {
      parts: ["二", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  山: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  参: [
    {
      parts: ["ム", "大", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  桟: [
    {
      parts: ["木", "㦮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蚕: [
    {
      parts: ["天", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  惨: [
    {
      parts: ["忄", "参"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  産: [
    {
      parts: ["立", "ノ", "生"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  傘: [
    {
      parts: ["𠆢", "人", "人", "人", "人", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  散: [
    {
      parts: ["卄", "一", "月", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  算: [
    {
      parts: ["竹", "目", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酸: [
    {
      parts: ["酉", "夋"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["唆", "二", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["唆", "兀", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賛: [
    {
      parts: ["夫", "夫", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["替", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  残: [
    {
      parts: ["歹", "㦮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斬: [
    {
      parts: ["車", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暫: [
    {
      parts: ["斬", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["昌", "一", "十", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  士: [
    {
      parts: ["十", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  子: [
    {
      parts: ["了", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  支: [
    {
      parts: ["十", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  止: [
    {
      parts: ["上", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  氏: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仕: [
    {
      parts: ["亻", "士"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  史: [
    {
      parts: ["丈", "冂"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  司: [
    {
      parts: ["𠃌", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  四: [
    {
      parts: ["匹", "｜"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["囗", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["囗", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  市: [
    {
      parts: ["亠", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  矢: [
    {
      parts: ["ノ", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旨: [
    {
      parts: ["ヒ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  死: [
    {
      parts: ["歹", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  糸: [
    {
      parts: ["幺", "小"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  幺: [
    {
      parts: ["く", "ム"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  至: [
    {
      parts: ["一", "ム", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伺: [
    {
      parts: ["亻", "司"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  志: [
    {
      parts: ["士", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  私: [
    {
      parts: ["禾", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仏", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  使: [
    {
      parts: ["亻", "吏"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刺: [
    {
      parts: ["朿", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  始: [
    {
      parts: ["女", "台"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["如", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  姉: [
    {
      parts: ["女", "市"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  枝: [
    {
      parts: ["木", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  祉: [
    {
      parts: ["礻", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肢: [
    {
      parts: ["月", "支"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  姿: [
    {
      parts: ["次", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  思: [
    {
      parts: ["田", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["志", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  指: [
    {
      parts: ["扌", "旨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  施: [
    {
      parts: ["方", "ノ", "一", "也"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  師: [
    {
      parts: ["ノ", "㠯", "一", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恣: [
    {
      parts: ["次", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紙: [
    {
      parts: ["糸", "氏"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脂: [
    {
      parts: ["月", "旨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  視: [
    {
      parts: ["礻", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["祝", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紫: [
    {
      parts: ["此", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詞: [
    {
      parts: ["言", "司"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  歯: [
    {
      parts: ["止", "凵", "米"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嗣: [
    {
      parts: ["口", "冂", "卄", "司"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  試: [
    {
      parts: ["言", "式"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詩: [
    {
      parts: ["言", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "一", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  資: [
    {
      parts: ["次", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飼: [
    {
      parts: ["飠", "司"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誌: [
    {
      parts: ["言", "志"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "一", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雌: [
    {
      parts: ["此", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  摯: [
    {
      parts: ["執", "手"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賜: [
    {
      parts: ["貝", "日", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  諮: [
    {
      parts: ["言", "次", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  示: [
    {
      parts: ["二", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "丁", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  字: [
    {
      parts: ["宀", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寺: [
    {
      parts: ["土", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  次: [
    {
      parts: ["冫", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  耳: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  自: [
    {
      parts: ["ノ", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["白", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  似: [
    {
      parts: ["亻", "以"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  児: [
    {
      parts: ["旧", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  事: [
    {
      parts: ["一", "口", "コ", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  侍: [
    {
      parts: ["亻", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["付", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "丨", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  治: [
    {
      parts: ["氵", "台"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  持: [
    {
      parts: ["扌", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  時: [
    {
      parts: ["日", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吐", "一", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  滋: [
    {
      parts: ["氵", "䒑", "幺", "幺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慈: [
    {
      parts: ["䒑", "幺", "幺", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辞: [
    {
      parts: ["舌", "辛"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["位", "一", "口", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  磁: [
    {
      parts: ["石", "䒑", "幺", "幺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  餌: [
    {
      parts: ["飠", "耳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  璽: [
    {
      parts: ["爾", "玉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鹿: [
    {
      parts: ["广", "コ", "｜", "｜", "乛", "比"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  式: [
    {
      parts: ["戈", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  識: [
    {
      parts: ["言", "音", "戈"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  軸: [
    {
      parts: ["車", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  七: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  𠮟: [
    {
      parts: ["口", "七"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  失: [
    {
      parts: ["ノ", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  室: [
    {
      parts: ["宀", "至"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  疾: [
    {
      parts: ["疒", "矢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  執: [
    {
      parts: ["幸", "丸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  湿: [
    {
      parts: ["氵", "日", "业"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嫉: [
    {
      parts: ["女", "疾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漆: [
    {
      parts: ["氵", "木", "𠆢", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "𠆢", "𠆢", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  質: [
    {
      parts: ["斤", "斤", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  実: [
    {
      parts: ["宀", "三", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "夫", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  芝: [
    {
      parts: ["艹", "之"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  写: [
    {
      parts: ["冖", "与"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  社: [
    {
      parts: ["礻", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  車: [
    {
      parts: ["三", "中"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["申", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亘", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旦", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舎: [
    {
      parts: ["𠆢", "土", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["合", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  者: [
    {
      parts: ["耂", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "一", "右"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  射: [
    {
      parts: ["身", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  捨: [
    {
      parts: ["扌", "舎"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["拾", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  赦: [
    {
      parts: ["亦", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斜: [
    {
      parts: ["余", "斗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  煮: [
    {
      parts: ["者", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遮: [
    {
      parts: ["庶", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謝: [
    {
      parts: ["言", "射"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  邪: [
    {
      parts: ["牙", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蛇: [
    {
      parts: ["虫", "宀", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勺: [
    {
      parts: ["勹", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尺: [
    {
      parts: ["尸", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["八", "コ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  借: [
    {
      parts: ["亻", "昔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酌: [
    {
      parts: ["酉", "勺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  釈: [
    {
      parts: ["采", "尺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  爵: [
    {
      parts: ["爫", "罒", "艮", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  若: [
    {
      parts: ["艹", "右"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弱: [
    {
      parts: ["弓", "冫", "弓", "冫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寂: [
    {
      parts: ["宀", "叔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  手: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  主: [
    {
      parts: ["丶", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  守: [
    {
      parts: ["宀", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朱: [
    {
      parts: ["ノ", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["牛", "𠆢"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  取: [
    {
      parts: ["耳", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  狩: [
    {
      parts: ["犭", "守"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  首: [
    {
      parts: ["䒑", "自"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "百", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殊: [
    {
      parts: ["歹", "朱"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  珠: [
    {
      parts: ["王", "朱"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酒: [
    {
      parts: ["氵", "酉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腫: [
    {
      parts: ["月", "重"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["胆", "壬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["肚", "ノ", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  種: [
    {
      parts: ["禾", "重"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "壬", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "壬", "口", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["任", "旦", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["但", "壬", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["伸", "一", "𠆢", "ノ", "三"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  趣: [
    {
      parts: ["走", "取"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寿: [
    {
      parts: ["三", "ノ", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "ナ", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  受: [
    {
      parts: ["爫", "冖", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  呪: [
    {
      parts: ["口", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  授: [
    {
      parts: ["扌", "受"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  需: [
    {
      parts: ["雨", "而"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  儒: [
    {
      parts: ["亻", "需"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  樹: [
    {
      parts: ["村", "十", "豆"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["村", "吉", "䒑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  収: [
    {
      parts: ["丩", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  囚: [
    {
      parts: ["口", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  州: [
    {
      parts: ["川", "丶", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舟: [
    {
      parts: ["丹", "丶", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  秀: [
    {
      parts: ["禾", "乃"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  周: [
    {
      parts: ["冂", "土", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "司", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["同", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宗: [
    {
      parts: ["宀", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拾: [
    {
      parts: ["扌", "合"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  秋: [
    {
      parts: ["禾", "火"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  臭: [
    {
      parts: ["自", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "旦", "一", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  修: [
    {
      parts: ["亻", "｜", "攵", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  袖: [
    {
      parts: ["衤", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  終: [
    {
      parts: ["糸", "冬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "人", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  羞: [
    {
      parts: ["⺷", "ノ", "丑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  習: [
    {
      parts: ["羽", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  週: [
    {
      parts: ["辻", "同"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["周", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  就: [
    {
      parts: ["京", "尤"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吠", "亠", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衆: [
    {
      parts: ["血", "ノ", "ノ", "亻", "ノ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  集: [
    {
      parts: ["隹", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  愁: [
    {
      parts: ["秋", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酬: [
    {
      parts: ["酉", "州"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  醜: [
    {
      parts: ["酉", "鬼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蹴: [
    {
      parts: ["⻊", "就"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吠", "止", "京"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  襲: [
    {
      parts: ["龍", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  十: [
    {
      parts: ["丨", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  汁: [
    {
      parts: ["氵", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  充: [
    {
      parts: ["亠", "ム", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  住: [
    {
      parts: ["亻", "主"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柔: [
    {
      parts: ["矛", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  重: [
    {
      parts: ["壬", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["千", "亘"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["車", "ノ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["千", "里"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["申", "ノ", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  従: [
    {
      parts: ["彳", "䒑", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  渋: [
    {
      parts: ["氵", "止", "丶", "丶", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  銃: [
    {
      parts: ["金", "充"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  獣: [
    {
      parts: ["ツ", "田", "一", "吠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  縦: [
    {
      parts: ["糸", "従"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  叔: [
    {
      parts: ["上", "小", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  祝: [
    {
      parts: ["礻", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宿: [
    {
      parts: ["宀", "亻", "百"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "伯", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  淑: [
    {
      parts: ["氵", "叔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粛: [
    {
      parts: ["コ", "一", "米", "ノ", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  縮: [
    {
      parts: ["糸", "宿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "｜", "｜", "亻", "百"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "｜", "｜", "伯", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塾: [
    {
      parts: ["亠", "口", "子", "丸", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  熟: [
    {
      parts: ["亠", "口", "子", "丸", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  出: [
    {
      parts: ["山", "山"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["山", "凵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  述: [
    {
      parts: ["ホ", "丶", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "八", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  術: [
    {
      parts: ["行", "ホ", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俊: [
    {
      parts: ["仏", "儿", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "夋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  春: [
    {
      parts: ["三", "人", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瞬: [
    {
      parts: ["目", "舜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旬: [
    {
      parts: ["句", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["勹", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巡: [
    {
      parts: ["く", "く", "く", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盾: [
    {
      parts: ["ノ", "ノ", "十", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "ノ", "古", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  准: [
    {
      parts: ["冫", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殉: [
    {
      parts: ["歹", "旬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  純: [
    {
      parts: ["糸", "屯"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  循: [
    {
      parts: ["彳", "盾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  順: [
    {
      parts: ["川", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  準: [
    {
      parts: ["氵", "隹", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  潤: [
    {
      parts: ["氵", "閏"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汗", "閂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遵: [
    {
      parts: ["尊", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  処: [
    {
      parts: ["夂", "几"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  初: [
    {
      parts: ["衤", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  所: [
    {
      parts: ["戸", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  書: [
    {
      parts: ["コ", "吉", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  庶: [
    {
      parts: ["广", "廿", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暑: [
    {
      parts: ["日", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["早", "右", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["早", "ナ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  署: [
    {
      parts: ["罒", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  緒: [
    {
      parts: ["糸", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  諸: [
    {
      parts: ["言", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "一", "ノ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  女: [
    {
      parts: ["く", "ノ", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  如: [
    {
      parts: ["女", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  助: [
    {
      parts: ["且", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  序: [
    {
      parts: ["广", "予"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  叙: [
    {
      parts: ["余", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  徐: [
    {
      parts: ["彳", "余"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  除: [
    {
      parts: ["阝", "余"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  小: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  升: [
    {
      parts: ["ノ", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  少: [
    {
      parts: ["小", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  召: [
    {
      parts: ["刀", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  匠: [
    {
      parts: ["匚", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  床: [
    {
      parts: ["广", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抄: [
    {
      parts: ["扌", "少"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肖: [
    {
      parts: ["小", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尚: [
    {
      parts: ["小", "冂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  招: [
    {
      parts: ["扌", "召"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  承: [
    {
      parts: ["了", "三", "フ", "ノ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["子", "二", "フ", "ノ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昇: [
    {
      parts: ["日", "升"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["早", "ノ", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  松: [
    {
      parts: ["木", "公"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沼: [
    {
      parts: ["氵", "召"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昭: [
    {
      parts: ["日", "召"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宵: [
    {
      parts: ["宀", "肖"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  将: [
    {
      parts: ["爿", "爫", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  消: [
    {
      parts: ["氵", "肖"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  症: [
    {
      parts: ["疒", "正"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  祥: [
    {
      parts: ["礻", "羊"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  称: [
    {
      parts: ["禾", "尓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  笑: [
    {
      parts: ["竹", "夭"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唱: [
    {
      parts: ["口", "昌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  商: [
    {
      parts: ["亠", "ソ", "冂", "儿", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["立", "｜", "｜", "儿", "口"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  渉: [
    {
      parts: ["氵", "歩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  章: [
    {
      parts: ["立", "早"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["音", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紹: [
    {
      parts: ["糸", "召"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  訟: [
    {
      parts: ["言", "公"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勝: [
    {
      parts: ["月", "龹", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  掌: [
    {
      parts: ["尚", "手"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  晶: [
    {
      parts: ["日", "日", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["品", "一", "一", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  焼: [
    {
      parts: ["火", "十", "艹", "一", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  焦: [
    {
      parts: ["隹", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  硝: [
    {
      parts: ["石", "肖"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粧: [
    {
      parts: ["米", "庄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詔: [
    {
      parts: ["言", "召"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  証: [
    {
      parts: ["言", "正"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["訂", "一", "亠"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  象: [
    {
      parts: ["ク", "冂", "｜", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  傷: [
    {
      parts: ["但", "ノ", "一", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奨: [
    {
      parts: ["将", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  照: [
    {
      parts: ["昭", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詳: [
    {
      parts: ["言", "羊"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "ソ", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彰: [
    {
      parts: ["章", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  障: [
    {
      parts: ["阝", "章"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["陪", "一", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  憧: [
    {
      parts: ["忄", "童"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["恒", "十", "亠", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衝: [
    {
      parts: ["行", "重"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "但", "壬", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仁", "千", "日", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仁", "壬", "口", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "伸", "ノ", "三", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "任", "旦", "一", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賞: [
    {
      parts: ["尚", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ツ", "冖", "員"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  償: [
    {
      parts: ["亻", "賞"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "尚", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  礁: [
    {
      parts: ["石", "焦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鐘: [
    {
      parts: ["金", "童"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "音", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "旦", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  上: [
    {
      parts: ["卜", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  丈: [
    {
      parts: ["ナ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  冗: [
    {
      parts: ["冖", "几"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  条: [
    {
      parts: ["夂", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  状: [
    {
      parts: ["冫", "｜", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乗: [
    {
      parts: ["亻", "工", "十", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["千", "卄", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["禾", "十", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  城: [
    {
      parts: ["土", "成"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浄: [
    {
      parts: ["氵", "争"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  剰: [
    {
      parts: ["乗", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  常: [
    {
      parts: ["ツ", "冖", "吊"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尚", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  情: [
    {
      parts: ["忄", "青"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  場: [
    {
      parts: ["坦", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畳: [
    {
      parts: ["田", "冖", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["胃", "冖", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蒸: [
    {
      parts: ["艹", "烝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  烝: [
    {
      parts: ["氶", "一", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  縄: [
    {
      parts: ["糸", "日", "电"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["糸", "甲", "电"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  㐮: [
    {
      parts: ["衣", "八", "井", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  壌: [
    {
      parts: ["土", "㐮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嬢: [
    {
      parts: ["女", "㐮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  錠: [
    {
      parts: ["金", "定"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["釘", "宀", "一", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  譲: [
    {
      parts: ["言", "㐮"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "六", "一", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  醸: [
    {
      parts: ["酉", "㐮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  色: [
    {
      parts: ["ク", "巴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拭: [
    {
      parts: ["扌", "式"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  食: [
    {
      parts: ["𠆢", "良"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  植: [
    {
      parts: ["木", "直"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殖: [
    {
      parts: ["歹", "直"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飾: [
    {
      parts: ["飠", "ノ", "一", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  触: [
    {
      parts: ["角", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嘱: [
    {
      parts: ["口", "属"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  織: [
    {
      parts: ["糸", "音", "戈"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  職: [
    {
      parts: ["耳", "音", "戈"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  辱: [
    {
      parts: ["辰", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尻: [
    {
      parts: ["尸", "九"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  心: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  申: [
    {
      parts: ["中", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伸: [
    {
      parts: ["亻", "申"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仲", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  臣: [
    {
      parts: ["巨", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  芯: [
    {
      parts: ["艹", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  身: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辛: [
    {
      parts: ["立", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亠", "ソ", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  侵: [
    {
      parts: ["亻", "ヨ", "冖", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  信: [
    {
      parts: ["亻", "言"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  津: [
    {
      parts: ["氵", "聿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "コ", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  神: [
    {
      parts: ["礻", "申"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唇: [
    {
      parts: ["辰", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  娠: [
    {
      parts: ["女", "辰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  振: [
    {
      parts: ["扌", "辰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浸: [
    {
      parts: ["氵", "ヨ", "冖", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  真: [
    {
      parts: ["十", "具"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["古", "三", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  針: [
    {
      parts: ["金", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  深: [
    {
      parts: ["沐", "冖", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紳: [
    {
      parts: ["糸", "申"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  進: [
    {
      parts: ["隹", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  森: [
    {
      parts: ["木", "林"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  診: [
    {
      parts: ["言", "𠆢", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寝: [
    {
      parts: ["宀", "冫", "｜", "ヨ", "冖", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慎: [
    {
      parts: ["忄", "真"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["怛", "十", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["恒", "一", "八", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  新: [
    {
      parts: ["立", "析"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辛", "𠆢", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  審: [
    {
      parts: ["宀", "番"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宋", "ノ", "ソ", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  震: [
    {
      parts: ["雨", "辰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  薪: [
    {
      parts: ["艹", "新"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  親: [
    {
      parts: ["立", "木", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辛", "𠆢", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  人: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刃: [
    {
      parts: ["刀", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仁: [
    {
      parts: ["亻", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尽: [
    {
      parts: ["尺", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  迅: [
    {
      parts: ["乙", "辻"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  甚: [
    {
      parts: ["卄", "三", "儿", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陣: [
    {
      parts: ["阝", "車"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尋: [
    {
      parts: ["ヨ", "工", "口", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腎: [
    {
      parts: ["臣", "又", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  須: [
    {
      parts: ["彡", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  図: [
    {
      parts: ["口", "丶", "丶", "メ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  水: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吹: [
    {
      parts: ["口", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  垂: [
    {
      parts: ["壬", "卄", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["壬", "工", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  炊: [
    {
      parts: ["火", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  帥: [
    {
      parts: ["ノ", "㠯", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粋: [
    {
      parts: ["米", "九", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["枠", "ソ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  衰: [
    {
      parts: ["衣", "口", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  推: [
    {
      parts: ["扌", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酔: [
    {
      parts: ["酉", "九", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遂: [
    {
      parts: ["ソ", "豕", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  睡: [
    {
      parts: ["目", "垂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["唾", "二"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  穂: [
    {
      parts: ["禾", "恵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "十", "一", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  錘: [
    {
      parts: ["金", "垂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  随: [
    {
      parts: ["阝", "有", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  髄: [
    {
      parts: ["骨", "有", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  枢: [
    {
      parts: ["木", "区"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  崇: [
    {
      parts: ["山", "宗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  数: [
    {
      parts: ["米", "女", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["枚", "ソ", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  据: [
    {
      parts: ["扌", "居"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  杉: [
    {
      parts: ["木", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  裾: [
    {
      parts: ["衤", "居"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寸: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瀬: [
    {
      parts: ["氵", "頼"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泊", "束", "二", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  是: [
    {
      parts: ["旦", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["日", "下", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  井: [
    {
      parts: ["廾", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "ノ", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  世: [
    {
      parts: ["廿", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  正: [
    {
      parts: ["一", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["工", "一", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  生: [
    {
      parts: ["牛", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "一", "土"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  成: [
    {
      parts: ["ノ", "戈", "𠃌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  西: [
    {
      parts: ["一", "口", "儿"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  声: [
    {
      parts: ["士", "尸", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  制: [
    {
      parts: ["ノ", "二", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  姓: [
    {
      parts: ["女", "生"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  征: [
    {
      parts: ["彳", "正"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  性: [
    {
      parts: ["忄", "生"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  青: [
    {
      parts: ["龶", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斉: [
    {
      parts: ["文", "ノ", "｜", "二"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  政: [
    {
      parts: ["正", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  星: [
    {
      parts: ["日", "生"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旦", "牛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  牲: [
    {
      parts: ["牛", "生"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  省: [
    {
      parts: ["少", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凄: [
    {
      parts: ["冫", "妻"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逝: [
    {
      parts: ["折", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  清: [
    {
      parts: ["氵", "青"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "二", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盛: [
    {
      parts: ["成", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  婿: [
    {
      parts: ["女", "疋", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  晴: [
    {
      parts: ["日", "青"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["明", "龶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吐", "一", "一", "月"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  勢: [
    {
      parts: ["坴", "丸", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  聖: [
    {
      parts: ["耳", "呈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誠: [
    {
      parts: ["言", "成"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  精: [
    {
      parts: ["米", "青"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  製: [
    {
      parts: ["制", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誓: [
    {
      parts: ["折", "言"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  静: [
    {
      parts: ["青", "争"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  請: [
    {
      parts: ["言", "青"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "二", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  整: [
    {
      parts: ["束", "攵", "正"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["枚", "口", "正"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  醒: [
    {
      parts: ["酉", "星"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吽", "兀", "一", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  税: [
    {
      parts: ["禾", "ソ", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "ソ", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夕: [
    {
      parts: ["タ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斥: [
    {
      parts: ["斤", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  石: [
    {
      parts: ["一", "ノ", "口"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  赤: [
    {
      parts: ["一", "亦"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "ノ", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昔: [
    {
      parts: ["十", "亠", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "｜", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  析: [
    {
      parts: ["木", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  席: [
    {
      parts: ["广", "廿", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脊: [
    {
      parts: ["二", "二", "人", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隻: [
    {
      parts: ["隹", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  惜: [
    {
      parts: ["忄", "昔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戚: [
    {
      parts: ["ノ", "戈", "上", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["戌", "亠", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["戌", "卜", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  責: [
    {
      parts: ["龶", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["六", "二", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["六", "三", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吉", "三", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  跡: [
    {
      parts: ["⻊", "亦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  積: [
    {
      parts: ["禾", "責"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "六", "二", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "六", "三", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "三", "土", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["債", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "一", "𠆢", "六", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "一", "𠆢", "龶", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "一", "𠆢", "吉", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "一", "𠆢", "土", "日", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "一", "𠆢", "十", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  績: [
    {
      parts: ["糸", "責"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  籍: [
    {
      parts: ["竹", "耒", "昔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  切: [
    {
      parts: ["七", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  折: [
    {
      parts: ["扌", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拙: [
    {
      parts: ["扌", "出"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  窃: [
    {
      parts: ["穴", "切"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  接: [
    {
      parts: ["扌", "妾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["拉", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  設: [
    {
      parts: ["言", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雪: [
    {
      parts: ["雨", "ヨ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  摂: [
    {
      parts: ["扌", "耳", "丶", "丶", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  節: [
    {
      parts: ["竹", "即"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  説: [
    {
      parts: ["言", "ソ", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舌: [
    {
      parts: ["千", "口"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "古"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  絶: [
    {
      parts: ["糸", "色"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  千: [
    {
      parts: ["チ"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  川: [
    {
      parts: ["ノ", "｜", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  仙: [
    {
      parts: ["亻", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  占: [
    {
      parts: ["卜", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  先: [
    {
      parts: ["ノ", "十", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "元", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宣: [
    {
      parts: ["宀", "亘"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  専: [
    {
      parts: ["十", "日", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泉: [
    {
      parts: ["白", "水"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浅: [
    {
      parts: ["氵", "㦮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  洗: [
    {
      parts: ["氵", "先"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "ノ", "兀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  染: [
    {
      parts: ["氵", "九", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  扇: [
    {
      parts: ["戸", "羽"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  栓: [
    {
      parts: ["木", "全"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旋: [
    {
      parts: ["方", "ノ", "一", "疋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  船: [
    {
      parts: ["舟", "八", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戦: [
    {
      parts: ["単", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  煎: [
    {
      parts: ["前", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  羨: [
    {
      parts: ["⺷", "氵", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["⺷", "次", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腺: [
    {
      parts: ["月", "泉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  詮: [
    {
      parts: ["言", "全"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "𠆢", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["訂", "𠆢", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  践: [
    {
      parts: ["⻊", "㦮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  箋: [
    {
      parts: ["竹", "戈", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  銭: [
    {
      parts: ["金", "㦮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  銑: [
    {
      parts: ["金", "先"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "ノ", "兀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  潜: [
    {
      parts: ["氵", "替"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  線: [
    {
      parts: ["糸", "泉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遷: [
    {
      parts: ["覀", "大", "己", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "山", "辻", "大", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  選: [
    {
      parts: ["己", "己", "共", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["己", "己", "辻", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  薦: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  繊: [
    {
      parts: ["糸", "十", "戈", "业"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鮮: [
    {
      parts: ["魚", "羊"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  全: [
    {
      parts: ["𠆢", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  前: [
    {
      parts: ["䒑", "月", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  善: [
    {
      parts: ["羊", "䒑", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  然: [
    {
      parts: ["月", "犬", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  禅: [
    {
      parts: ["礻", "単"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漸: [
    {
      parts: ["氵", "斬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "旦", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  膳: [
    {
      parts: ["月", "善"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  繕: [
    {
      parts: ["糸", "善"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["紅", "ソ", "二", "ソ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["結", "䒑", "一", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["結", "ソ", "二", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  狙: [
    {
      parts: ["犭", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  阻: [
    {
      parts: ["阝", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  祖: [
    {
      parts: ["礻", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  租: [
    {
      parts: ["禾", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  素: [
    {
      parts: ["龶", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["玄", "二", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  措: [
    {
      parts: ["扌", "昔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粗: [
    {
      parts: ["米", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  組: [
    {
      parts: ["糸", "且"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "上", "一", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "二", "亠", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "三", "｜", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  疎: [
    {
      parts: ["了", "一", "亠", "束"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  訴: [
    {
      parts: ["言", "斥"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["訂", "ノ", "ノ", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塑: [
    {
      parts: ["朔", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遡: [
    {
      parts: ["朔", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["逆", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  礎: [
    {
      parts: ["石", "楚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  双: [
    {
      parts: ["又", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  壮: [
    {
      parts: ["爿", "士"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  早: [
    {
      parts: ["日", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旦", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "一", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  争: [
    {
      parts: ["ク", "コ", "一", "亅"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "子", "コ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  走: [
    {
      parts: ["土", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "下", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奏: [
    {
      parts: ["三", "人", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "大", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "夫", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "夫", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "大", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  相: [
    {
      parts: ["木", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  荘: [
    {
      parts: ["艹", "壮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  草: [
    {
      parts: ["艹", "早"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["芋", "冂", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  送: [
    {
      parts: ["ソ", "天", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "大", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  倉: [
    {
      parts: ["合", "戸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["𠆢", "二", "尸", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  捜: [
    {
      parts: ["扌", "申", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["技", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  挿: [
    {
      parts: ["扌", "千", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "ノ", "一", "申"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["押", "千"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  桑: [
    {
      parts: ["又", "双", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巣: [
    {
      parts: ["単", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ツ", "果"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  掃: [
    {
      parts: ["扌", "ヨ", "冖", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曹: [
    {
      parts: ["十", "由", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["昌", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "曲", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曽: [
    {
      parts: ["ソ", "田", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "昌", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  爽: [
    {
      parts: ["大", "㐅", "㐅", "㐅", "㐅"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  窓: [
    {
      parts: ["穴", "ム", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "公", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  創: [
    {
      parts: ["倉", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  喪: [
    {
      parts: ["土", "口", "口", "レ", "ノ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  痩: [
    {
      parts: ["疒", "申", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["疒", "支", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  葬: [
    {
      parts: ["艹", "死", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  装: [
    {
      parts: ["壮", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  僧: [
    {
      parts: ["亻", "曽"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["佃", "ソ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "ソ", "山", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "ソ", "田", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["但", "ソ", "｜", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  想: [
    {
      parts: ["相", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  層: [
    {
      parts: ["尸", "曽"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  総: [
    {
      parts: ["糸", "公", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遭: [
    {
      parts: ["曹", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "由", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "昌", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  槽: [
    {
      parts: ["木", "曹"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["柚", "十", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["椙", "卄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  踪: [
    {
      parts: ["⻊", "宗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  操: [
    {
      parts: ["扌", "品", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  燥: [
    {
      parts: ["火", "品", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  霜: [
    {
      parts: ["雨", "相"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["雪", "朴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  騒: [
    {
      parts: ["馬", "又", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  藻: [
    {
      parts: ["艹", "氵", "品", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "汁", "品", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  造: [
    {
      parts: ["告", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "ノ", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  像: [
    {
      parts: ["亻", "象"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  増: [
    {
      parts: ["土", "曽"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["坦", "ソ", "｜", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憎: [
    {
      parts: ["忄", "曽"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["怛", "ソ", "｜", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蔵: [
    {
      parts: ["艹", "ノ", "戈", "臣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  贈: [
    {
      parts: ["貝", "曽"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  臓: [
    {
      parts: ["月", "蔵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  即: [
    {
      parts: ["艮", "卩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  束: [
    {
      parts: ["木", "口"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["末", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "中", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  足: [
    {
      parts: ["口", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "下", "人"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  促: [
    {
      parts: ["亻", "足"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  則: [
    {
      parts: ["貝", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  息: [
    {
      parts: ["自", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  捉: [
    {
      parts: ["扌", "足"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["打", "冂", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  速: [
    {
      parts: ["束", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "口", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  側: [
    {
      parts: ["亻", "則"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "只", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  測: [
    {
      parts: ["氵", "則"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泪", "八", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俗: [
    {
      parts: ["亻", "谷"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  族: [
    {
      parts: ["方", "ノ", "一", "矢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  属: [
    {
      parts: ["尸", "ノ", "虫", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賊: [
    {
      parts: ["貝", "十", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "二", "八", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  続: [
    {
      parts: ["糸", "売"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "士", "｜", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卒: [
    {
      parts: ["亠", "人", "人", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  率: [
    {
      parts: ["玄", "丶", "丶", "丶", "丶", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  存: [
    {
      parts: ["一", "ノ", "｜", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "仔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  村: [
    {
      parts: ["木", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  孫: [
    {
      parts: ["子", "系"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尊: [
    {
      parts: ["酋", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  損: [
    {
      parts: ["扌", "員"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遜: [
    {
      parts: ["孫", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  他: [
    {
      parts: ["亻", "也"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  多: [
    {
      parts: ["夕", "夕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  汰: [
    {
      parts: ["氵", "太"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  打: [
    {
      parts: ["扌", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妥: [
    {
      parts: ["爫", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唾: [
    {
      parts: ["口", "垂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "丁", "壬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "ノ", "二", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堕: [
    {
      parts: ["陏", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  惰: [
    {
      parts: ["忄", "左", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  駄: [
    {
      parts: ["馬", "太"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  太: [
    {
      parts: ["大", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  対: [
    {
      parts: ["文", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  体: [
    {
      parts: ["亻", "本"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["休", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  耐: [
    {
      parts: ["而", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  待: [
    {
      parts: ["彳", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "侍"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["行", "｜", "一", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  怠: [
    {
      parts: ["台", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胎: [
    {
      parts: ["月", "台"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  退: [
    {
      parts: ["艮", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  帯: [
    {
      parts: ["廿", "｜", "冖", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泰: [
    {
      parts: ["三", "人", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "大", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "夫", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堆: [
    {
      parts: ["土", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  袋: [
    {
      parts: ["代", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逮: [
    {
      parts: ["隶", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  替: [
    {
      parts: ["夫", "夫", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貸: [
    {
      parts: ["代", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隊: [
    {
      parts: ["阝", "㒸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㒸: [
    {
      parts: ["丷", "豕"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  滞: [
    {
      parts: ["氵", "帯"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "凵", "冖", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  態: [
    {
      parts: ["能", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戴: [
    {
      parts: ["十", "戈", "異"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  大: [
    {
      parts: ["一", "人"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ナ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  代: [
    {
      parts: ["亻", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  台: [
    {
      parts: ["ム", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  第: [
    {
      parts: ["竹", "弔", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  題: [
    {
      parts: ["是", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  滝: [
    {
      parts: ["氵", "竜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泣", "电"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宅: [
    {
      parts: ["宀", "乇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  択: [
    {
      parts: ["扌", "尺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沢: [
    {
      parts: ["氵", "尺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卓: [
    {
      parts: ["卜", "早"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["占", "一", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拓: [
    {
      parts: ["扌", "石"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  託: [
    {
      parts: ["言", "乇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  濯: [
    {
      parts: ["氵", "ヨ", "ヨ", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  諾: [
    {
      parts: ["言", "若"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  濁: [
    {
      parts: ["氵", "蜀"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["沖", "罒", "勹", "一", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  但: [
    {
      parts: ["亻", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  達: [
    {
      parts: ["幸", "一", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "羊", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "一", "羊"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "立", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脱: [
    {
      parts: ["月", "ソ", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奪: [
    {
      parts: ["大", "隹", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  棚: [
    {
      parts: ["木", "朋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誰: [
    {
      parts: ["言", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丹: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旦: [
    {
      parts: ["日", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  担: [
    {
      parts: ["扌", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  単: [
    {
      parts: ["ツ", "日", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ツ", "旦", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  炭: [
    {
      parts: ["山", "灰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胆: [
    {
      parts: ["月", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  探: [
    {
      parts: ["扌", "冖", "儿", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  淡: [
    {
      parts: ["氵", "炎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  短: [
    {
      parts: ["矢", "豆"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["知", "二", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嘆: [
    {
      parts: ["口", "艹", "口", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  端: [
    {
      parts: ["立", "山", "而"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  綻: [
    {
      parts: ["糸", "定"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "｜", "｜", "一", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "｜", "｜", "下", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誕: [
    {
      parts: ["言", "延"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["証", "廴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鍛: [
    {
      parts: ["金", "段"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  団: [
    {
      parts: ["囗", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  男: [
    {
      parts: ["田", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  断: [
    {
      parts: ["米", "L", "斤"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弾: [
    {
      parts: ["弓", "単"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["引", "ツ", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暖: [
    {
      parts: ["日", "爫", "一", "友"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  談: [
    {
      parts: ["言", "炎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  壇: [
    {
      parts: ["坦", "亠", "回"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  地: [
    {
      parts: ["土", "也"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  池: [
    {
      parts: ["氵", "也"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  知: [
    {
      parts: ["矢", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  値: [
    {
      parts: ["亻", "直"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "旧", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "古", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["但", "｜", "一", "十"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  恥: [
    {
      parts: ["耳", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  致: [
    {
      parts: ["至", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遅: [
    {
      parts: ["尸", "羊", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "辻", "ソ", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "辻", "䒑", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  痴: [
    {
      parts: ["疒", "知"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  稚: [
    {
      parts: ["禾", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "椎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  置: [
    {
      parts: ["罒", "直"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  緻: [
    {
      parts: ["糸", "致"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  竹: [
    {
      parts: ["ケ", "ケ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畜: [
    {
      parts: ["玄", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逐: [
    {
      parts: ["辶", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蓄: [
    {
      parts: ["艹", "畜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  築: [
    {
      parts: ["竹", "工", "凡", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  秩: [
    {
      parts: ["禾", "失"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  窒: [
    {
      parts: ["穴", "至"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "公", "一", "土"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "公", "三", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  茶: [
    {
      parts: ["艹", "𠆢", "十", "八"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  着: [
    {
      parts: ["⺷", "ノ", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "吉", "ノ", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "三", "ノ", "｜", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  嫡: [
    {
      parts: ["女", "啇"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["姑", "亠", "ソ", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  中: [
    {
      parts: ["口", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["巾", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["十", "冂"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  仲: [
    {
      parts: ["亻", "中"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虫: [
    {
      parts: ["中", "一", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "冂", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沖: [
    {
      parts: ["氵", "中"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宙: [
    {
      parts: ["宀", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  忠: [
    {
      parts: ["中", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抽: [
    {
      parts: ["扌", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  注: [
    {
      parts: ["氵", "主"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汗", "丶", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "丶", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昼: [
    {
      parts: ["尺", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柱: [
    {
      parts: ["木", "主"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衷: [
    {
      parts: ["衣", "一", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酎: [
    {
      parts: ["酉", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鋳: [
    {
      parts: ["金", "寿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  駐: [
    {
      parts: ["馬", "主"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  著: [
    {
      parts: ["艹", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["若", "十", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貯: [
    {
      parts: ["貝", "宀", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丁: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弔: [
    {
      parts: ["弓", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  庁: [
    {
      parts: ["广", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兆: [
    {
      parts: ["儿", "丶", "丶", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  町: [
    {
      parts: ["田", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  長: [
    {
      parts: ["三", "亠", "レ", "ノ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "上", "レ", "ノ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  挑: [
    {
      parts: ["扌", "兆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  帳: [
    {
      parts: ["巾", "長"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  張: [
    {
      parts: ["弓", "長"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["引", "三", "レ", "ノ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彫: [
    {
      parts: ["周", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  眺: [
    {
      parts: ["目", "兆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  釣: [
    {
      parts: ["金", "勺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頂: [
    {
      parts: ["丁", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鳥: [
    {
      parts: ["烏", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朝: [
    {
      parts: ["十", "早", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "明", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["胡", "一", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  脹: [
    {
      parts: ["月", "長"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貼: [
    {
      parts: ["貝", "占"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  超: [
    {
      parts: ["走", "召"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腸: [
    {
      parts: ["胆", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  跳: [
    {
      parts: ["⻊", "兆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  徴: [
    {
      parts: ["彳", "山", "王", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仙", "王", "攵"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仕", "山", "一", "攵"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仁", "山", "十", "攵"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  嘲: [
    {
      parts: ["口", "朝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  潮: [
    {
      parts: ["湖", "一", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["氵", "朝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "早", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "明", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "胡", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汗", "十", "冂", "一", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  澄: [
    {
      parts: ["氵", "登"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  調: [
    {
      parts: ["言", "周"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "同"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["詞", "ノ", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["詰", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  聴: [
    {
      parts: ["恥", "十", "罒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  懲: [
    {
      parts: ["徴", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["悠", "ノ", "三", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  直: [
    {
      parts: ["十", "目", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "自", "L"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["旧", "十", "二"],
      cutConnect: true,
      cutOneStroke: true,
    },
    {
      parts: ["旦", "十", "一", "｜"],
      cutConnect: true,
      cutOneStroke: true,
    },
    {
      parts: ["亘", "｜", "一", "｜"],
      cutConnect: true,
      cutOneStroke: true,
    },
  ],
  勅: [
    {
      parts: ["束", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  捗: [
    {
      parts: ["扌", "歩"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["抄", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沈: [
    {
      parts: ["氵", "冘"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  珍: [
    {
      parts: ["王", "𠆢", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朕: [
    {
      parts: ["月", "ソ", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["月", "䒑", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陳: [
    {
      parts: ["阝", "東"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賃: [
    {
      parts: ["任", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鎮: [
    {
      parts: ["針", "具"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["金", "真"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  追: [
    {
      parts: ["ノ", "㠯", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  椎: [
    {
      parts: ["木", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  墜: [
    {
      parts: ["隊", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  通: [
    {
      parts: ["甬", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["マ", "冂", "一", "辻"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  痛: [
    {
      parts: ["疒", "甬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塚: [
    {
      parts: ["土", "冖", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漬: [
    {
      parts: ["氵", "責"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["清", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["泪", "龶", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坪: [
    {
      parts: ["土", "平"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  爪: [
    {
      parts: ["ノ", "八", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鶴: [
    {
      parts: ["冖", "隹", "鳥"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  低: [
    {
      parts: ["亻", "氏", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  呈: [
    {
      parts: ["口", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  廷: [
    {
      parts: ["壬", "廴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弟: [
    {
      parts: ["ソ", "弔", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  定: [
    {
      parts: ["宀", "一", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "下", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  底: [
    {
      parts: ["广", "氏", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抵: [
    {
      parts: ["扌", "氏", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  邸: [
    {
      parts: ["氏", "一", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亭: [
    {
      parts: ["亠", "口", "冖", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貞: [
    {
      parts: ["占", "二", "八"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["卜", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  帝: [
    {
      parts: ["亠", "ソ", "冖", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["立", "｜", "｜", "巾"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  訂: [
    {
      parts: ["言", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  庭: [
    {
      parts: ["广", "廷"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["庄", "ノ", "廴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逓: [
    {
      parts: ["ノ", "ノ", "二", "巾", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "ノ", "一", "辻", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  停: [
    {
      parts: ["亻", "亭"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "｜", "冂", "冖", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  偵: [
    {
      parts: ["亻", "貞"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "占", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堤: [
    {
      parts: ["土", "是"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["坦", "龰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  提: [
    {
      parts: ["扌", "是"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["担", "龰"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  程: [
    {
      parts: ["禾", "呈"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["和", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  艇: [
    {
      parts: ["舟", "廷"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  締: [
    {
      parts: ["糸", "帝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  諦: [
    {
      parts: ["言", "帝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泥: [
    {
      parts: ["氵", "尼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  的: [
    {
      parts: ["白", "勺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  笛: [
    {
      parts: ["竹", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  摘: [
    {
      parts: ["扌", "啇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  滴: [
    {
      parts: ["氵", "啇"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  適: [
    {
      parts: ["辶", "啇"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "亠", "ソ", "冂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敵: [
    {
      parts: ["啇", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["故", "亠", "ソ", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  溺: [
    {
      parts: ["氵", "弓", "弓", "ノ", "ノ", "ノ", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  迭: [
    {
      parts: ["辶", "失"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  哲: [
    {
      parts: ["折", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鉄: [
    {
      parts: ["金", "失"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  徹: [
    {
      parts: ["彳", "育", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仏", "亠", "月", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仁", "亠", "厶", "冂", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  撤: [
    {
      parts: ["扌", "育", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["払", "亠", "月", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  天: [
    {
      parts: ["一", "大"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["二", "人"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  典: [
    {
      parts: ["冂", "共"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  店: [
    {
      parts: ["广", "占"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  点: [
    {
      parts: ["占", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  展: [
    {
      parts: ["尸", "卄", "一", "レ", "ノ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  添: [
    {
      parts: ["沃", "㣺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  転: [
    {
      parts: ["車", "云"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塡: [
    {
      parts: ["土", "眞"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["坦", "ヒ", "一", "｜", "八"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  田: [
    {
      parts: ["口", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["日", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["山", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伝: [
    {
      parts: ["亻", "云"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仏", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殿: [
    {
      parts: ["尸", "共", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  電: [
    {
      parts: ["雨", "电"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["雪", "｜", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["雷", "乚"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  斗: [
    {
      parts: ["丶", "丶", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  吐: [
    {
      parts: ["口", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妬: [
    {
      parts: ["女", "石"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["如", "一", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  徒: [
    {
      parts: ["彳", "走"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  途: [
    {
      parts: ["余", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "𠆢", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  都: [
    {
      parts: ["者", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  渡: [
    {
      parts: ["氵", "度"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "广", "L", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塗: [
    {
      parts: ["氵", "余", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汗", "𠆢", "八", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賭: [
    {
      parts: ["貝", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "二", "八", "右", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "二", "八", "ナ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  土: [
    {
      parts: ["十", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["丨", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奴: [
    {
      parts: ["女", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  努: [
    {
      parts: ["奴", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  度: [
    {
      parts: ["广", "廿", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  怒: [
    {
      parts: ["奴", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  刀: [
    {
      parts: ["𠃌", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冬: [
    {
      parts: ["夂", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  灯: [
    {
      parts: ["火", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  当: [
    {
      parts: ["⺌", "ヨ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  投: [
    {
      parts: ["扌", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豆: [
    {
      parts: ["二", "口", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "冂", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  東: [
    {
      parts: ["束", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["末", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["木", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "申", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  到: [
    {
      parts: ["至", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逃: [
    {
      parts: ["辶", "兆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  倒: [
    {
      parts: ["亻", "到"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仏", "一", "土", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "ム", "十", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凍: [
    {
      parts: ["冫", "東"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唐: [
    {
      parts: ["广", "十", "コ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  島: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  桃: [
    {
      parts: ["木", "兆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  討: [
    {
      parts: ["言", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  透: [
    {
      parts: ["秀", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "ノ", "𠆢", "乃"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  党: [
    {
      parts: ["ツ", "冖", "兄"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尚", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悼: [
    {
      parts: ["忄", "卓"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["恒", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["怛", "卜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盗: [
    {
      parts: ["次", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陶: [
    {
      parts: ["阝", "勹", "缶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塔: [
    {
      parts: ["土", "艹", "合"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  搭: [
    {
      parts: ["拾", "艹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  棟: [
    {
      parts: ["木", "東"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["林", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  湯: [
    {
      parts: ["氵", "旦", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  痘: [
    {
      parts: ["疒", "豆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  登: [
    {
      parts: ["癶", "豆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  答: [
    {
      parts: ["竹", "合"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  等: [
    {
      parts: ["竹", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  筒: [
    {
      parts: ["竹", "同"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  統: [
    {
      parts: ["糸", "充"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  稲: [
    {
      parts: ["禾", "爫", "旧"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  踏: [
    {
      parts: ["⻊", "沓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  糖: [
    {
      parts: ["米", "唐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頭: [
    {
      parts: ["豆", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謄: [
    {
      parts: ["月", "龹", "言"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  藤: [
    {
      parts: ["艹", "月", "龹", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  闘: [
    {
      parts: ["門", "豆", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["問", "二", "ソ", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "一", "口", "ソ", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  騰: [
    {
      parts: ["月", "龹", "馬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  同: [
    {
      parts: ["｜", "司"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "一", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  洞: [
    {
      parts: ["氵", "同"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胴: [
    {
      parts: ["月", "同"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  動: [
    {
      parts: ["重", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堂: [
    {
      parts: ["尚", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  童: [
    {
      parts: ["立", "里"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["音", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  道: [
    {
      parts: ["辶", "首"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  働: [
    {
      parts: ["亻", "動"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["任", "旦", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["但", "壬", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仲", "力", "三", "一", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["仲", "力", "二", "二", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "千", "日", "力"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["伸", "ノ", "三", "力"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  銅: [
    {
      parts: ["金", "同"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  導: [
    {
      parts: ["道", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瞳: [
    {
      parts: ["目", "童"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["暗", "一", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  峠: [
    {
      parts: ["山", "上", "下"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  匿: [
    {
      parts: ["匚", "若"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  特: [
    {
      parts: ["牛", "寺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["牡", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  得: [
    {
      parts: ["彳", "旦", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["付", "ノ", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["但", "ノ", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "ノ", "口", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  督: [
    {
      parts: ["叔", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  徳: [
    {
      parts: ["彳", "十", "罒", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["彳", "古", "｜", "｜", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仁", "｜", "冂", "｜", "｜", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  篤: [
    {
      parts: ["竹", "馬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  毒: [
    {
      parts: ["龶", "毋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  独: [
    {
      parts: ["犭", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  読: [
    {
      parts: ["言", "売"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "一", "冖", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  栃: [
    {
      parts: ["木", "厉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凸: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  突: [
    {
      parts: ["穴", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  届: [
    {
      parts: ["尸", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  屯: [
    {
      parts: ["ノ", "凵", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豚: [
    {
      parts: ["月", "豕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頓: [
    {
      parts: ["屯", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貪: [
    {
      parts: ["今", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["合", "フ", "二", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["𠆢", "三", "フ", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鈍: [
    {
      parts: ["金", "屯"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曇: [
    {
      parts: ["日", "雲"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丼: [
    {
      parts: ["井", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  那: [
    {
      parts: ["刀", "二", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奈: [
    {
      parts: ["大", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  内: [
    {
      parts: ["冂", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  梨: [
    {
      parts: ["利", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謎: [
    {
      parts: ["言", "辶", "米"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["言", "迷"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "ソ", "𠆢", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鍋: [
    {
      parts: ["金", "冎", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  南: [
    {
      parts: ["十", "冂", "䒑", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "冂", "ソ", "干"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幸", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  軟: [
    {
      parts: ["車", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吹", "丰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  難: [
    {
      parts: ["艹", "唯", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "冂", "天", "一", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "冂", "三", "人", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  二: [
    {
      parts: ["ニ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尼: [
    {
      parts: ["尸", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弐: [
    {
      parts: ["三", "弋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  匂: [
    {
      parts: ["勹", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肉: [
    {
      parts: ["内", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虹: [
    {
      parts: ["虫", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  日: [
    {
      parts: ["口", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  入: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乳: [
    {
      parts: ["爫", "子", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尿: [
    {
      parts: ["尸", "水"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  任: [
    {
      parts: ["亻", "壬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妊: [
    {
      parts: ["女", "壬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  忍: [
    {
      parts: ["刃", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  認: [
    {
      parts: ["言", "忍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寧: [
    {
      parts: ["宀", "心", "罒", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  熱: [
    {
      parts: ["坴", "丸", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  年: [
    {
      parts: ["ノ", "一", "ヰ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  念: [
    {
      parts: ["今", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  捻: [
    {
      parts: ["扌", "念"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粘: [
    {
      parts: ["米", "占"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  燃: [
    {
      parts: ["火", "然"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悩: [
    {
      parts: ["忄", "ツ", "凶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  納: [
    {
      parts: ["糸", "内"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  能: [
    {
      parts: ["ム", "月", "ヒ", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脳: [
    {
      parts: ["月", "ツ", "凶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  農: [
    {
      parts: ["曲", "辰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  濃: [
    {
      parts: ["氵", "農"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["油", "｜", "辰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  把: [
    {
      parts: ["扌", "巴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  波: [
    {
      parts: ["氵", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  派: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  破: [
    {
      parts: ["石", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  覇: [
    {
      parts: ["覀", "革", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  馬: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  婆: [
    {
      parts: ["波", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  罵: [
    {
      parts: ["罒", "馬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拝: [
    {
      parts: ["扌", "一", "丰"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "二", "二", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  杯: [
    {
      parts: ["木", "不"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  背: [
    {
      parts: ["北", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肺: [
    {
      parts: ["月", "市"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俳: [
    {
      parts: ["亻", "非"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  配: [
    {
      parts: ["酉", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  排: [
    {
      parts: ["扌", "非"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敗: [
    {
      parts: ["貝", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  廃: [
    {
      parts: ["广", "発"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  輩: [
    {
      parts: ["非", "車"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  売: [
    {
      parts: ["士", "冖", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  倍: [
    {
      parts: ["位", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  梅: [
    {
      parts: ["木", "毎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  培: [
    {
      parts: ["土", "立", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陪: [
    {
      parts: ["阝", "立", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  媒: [
    {
      parts: ["女", "某"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  買: [
    {
      parts: ["罒", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["員", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賠: [
    {
      parts: ["貝", "立", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  白: [
    {
      parts: ["ノ", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伯: [
    {
      parts: ["亻", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "ノ", "旧"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拍: [
    {
      parts: ["扌", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旧", "一", "ノ", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泊: [
    {
      parts: ["氵", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  迫: [
    {
      parts: ["辶", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  剝: [
    {
      parts: ["彑", "氺", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舶: [
    {
      parts: ["舟", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  博: [
    {
      parts: ["十", "専", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旧", "一", "十", "寸", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  薄: [
    {
      parts: ["艹", "油", "一", "丶", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "氵", "専", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  麦: [
    {
      parts: ["龶", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漠: [
    {
      parts: ["氵", "莫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  縛: [
    {
      parts: ["糸", "専", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["紬", "一", "寸", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  爆: [
    {
      parts: ["火", "暴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  箱: [
    {
      parts: ["竹", "相"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  箸: [
    {
      parts: ["竹", "者"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畑: [
    {
      parts: ["火", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肌: [
    {
      parts: ["月", "几"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  八: [
    {
      parts: ["ハ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  ハ: [
    {
      parts: ["ノ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  鉢: [
    {
      parts: ["金", "本"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  発: [
    {
      parts: ["癶", "二", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  髪: [
    {
      parts: ["镸", "彡", "友"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伐: [
    {
      parts: ["亻", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抜: [
    {
      parts: ["扌", "友"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  罰: [
    {
      parts: ["罒", "言", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閥: [
    {
      parts: ["門", "伐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  反: [
    {
      parts: ["厂", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  半: [
    {
      parts: ["丷", "二", "丨"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "十"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  氾: [
    {
      parts: ["氵", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  犯: [
    {
      parts: ["犭", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  帆: [
    {
      parts: ["巾", "凡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  汎: [
    {
      parts: ["氵", "凡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  伴: [
    {
      parts: ["亻", "半"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  判: [
    {
      parts: ["半", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坂: [
    {
      parts: ["土", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  阪: [
    {
      parts: ["阝", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  板: [
    {
      parts: ["木", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  版: [
    {
      parts: ["片", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  班: [
    {
      parts: ["王", "リ", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["刊", "一", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畔: [
    {
      parts: ["田", "半"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "十", "䒑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  般: [
    {
      parts: ["舟", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  販: [
    {
      parts: ["貝", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  斑: [
    {
      parts: ["王", "文", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飯: [
    {
      parts: ["飠", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  搬: [
    {
      parts: ["扌", "般"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["投", "舟"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  煩: [
    {
      parts: ["火", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頒: [
    {
      parts: ["分", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  範: [
    {
      parts: ["竹", "車", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  繁: [
    {
      parts: ["敏", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  藩: [
    {
      parts: ["艹", "氵", "番"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "汁", "ノ", "米", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "汁", "ノ", "ソ", "𠆢", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["苦", "汁", "ノ", "ソ", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  晩: [
    {
      parts: ["日", "免"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  番: [
    {
      parts: ["ノ", "米", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "ソ", "杏", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["禾", "ソ", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蛮: [
    {
      parts: ["亦", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盤: [
    {
      parts: ["般", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  比: [
    {
      parts: ["ヒ", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  皮: [
    {
      parts: ["ノ", "支"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  妃: [
    {
      parts: ["女", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  否: [
    {
      parts: ["不", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  批: [
    {
      parts: ["扌", "比"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彼: [
    {
      parts: ["彳", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  披: [
    {
      parts: ["扌", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肥: [
    {
      parts: ["月", "巴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  非: [
    {
      parts: ["三", "ノ", "三", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  卑: [
    {
      parts: ["ノ", "田", "ノ", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["白", "｜", "ノ", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "早", "｜", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飛: [
    {
      parts: ["乙", "丶", "丶", "乙", "丶", "丶", "亻", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["乙", "丶", "丶", "升", "乚", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  疲: [
    {
      parts: ["疒", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  秘: [
    {
      parts: ["禾", "必"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  被: [
    {
      parts: ["衤", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悲: [
    {
      parts: ["非", "皮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  扉: [
    {
      parts: ["戸", "非"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  費: [
    {
      parts: ["弗", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  碑: [
    {
      parts: ["石", "卑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  罷: [
    {
      parts: ["罒", "能"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  避: [
    {
      parts: ["尸", "口", "辛", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "叶", "立", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "口", "立", "辻"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尾: [
    {
      parts: ["尸", "毛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  眉: [
    {
      parts: ["尸", "｜", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  美: [
    {
      parts: ["⺷", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "三", "｜", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "十", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  備: [
    {
      parts: ["亻", "艹", "厂", "用"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  微: [
    {
      parts: ["彳", "山", "兀", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "仙", "兀", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鼻: [
    {
      parts: ["自", "田", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["畠", "一", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  膝: [
    {
      parts: ["月", "木", "𠆢", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肘: [
    {
      parts: ["月", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  匹: [
    {
      parts: ["匚", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  必: [
    {
      parts: ["心", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泌: [
    {
      parts: ["氵", "必"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  筆: [
    {
      parts: ["竹", "聿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  姫: [
    {
      parts: ["女", "臣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  百: [
    {
      parts: ["一", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "ノ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "ノ", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  氷: [
    {
      parts: ["水", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  表: [
    {
      parts: ["衣", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俵: [
    {
      parts: ["亻", "表"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["依", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  票: [
    {
      parts: ["覀", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  評: [
    {
      parts: ["言", "平"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "一", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漂: [
    {
      parts: ["氵", "票"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汗", "山", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汀", "覀", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  標: [
    {
      parts: ["木", "票"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["杣", "干", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["杠", "巾", "示"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  苗: [
    {
      parts: ["艹", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  秒: [
    {
      parts: ["禾", "少"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  病: [
    {
      parts: ["疒", "丙"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  描: [
    {
      parts: ["扌", "苗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  猫: [
    {
      parts: ["犭", "苗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  品: [
    {
      parts: ["口", "口", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浜: [
    {
      parts: ["氵", "兵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汀", "ノ", "｜", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貧: [
    {
      parts: ["分", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["八", "召", "二", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賓: [
    {
      parts: ["宀", "一", "少", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "一", "少", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "丁", "ノ", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頻: [
    {
      parts: ["歩", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敏: [
    {
      parts: ["毎", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瓶: [
    {
      parts: ["并", "瓦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  不: [
    {
      parts: ["一", "↑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夫: [
    {
      parts: ["二", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  父: [
    {
      parts: ["八", "メ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  付: [
    {
      parts: ["亻", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  布: [
    {
      parts: ["ナ", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  扶: [
    {
      parts: ["扌", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  府: [
    {
      parts: ["广", "付"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  怖: [
    {
      parts: ["忄", "布"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  阜: [
    {
      parts: ["ノ", "㠯", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  附: [
    {
      parts: ["阝", "付"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  訃: [
    {
      parts: ["言", "卜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  負: [
    {
      parts: ["ク", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  赴: [
    {
      parts: ["走", "卜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浮: [
    {
      parts: ["氵", "爫", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  婦: [
    {
      parts: ["女", "ヨ", "冖", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  符: [
    {
      parts: ["竹", "付"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  富: [
    {
      parts: ["宀", "畐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  普: [
    {
      parts: ["並", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腐: [
    {
      parts: ["府", "肉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敷: [
    {
      parts: ["一", "由", "丶", "放"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "巾", "丶", "放"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  膚: [
    {
      parts: ["虍", "胃"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賦: [
    {
      parts: ["貝", "武"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  譜: [
    {
      parts: ["言", "普"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  侮: [
    {
      parts: ["亻", "毎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  武: [
    {
      parts: ["一", "弋", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  部: [
    {
      parts: ["立", "口", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舞: [
    {
      parts: ["ノ", "三", "｜", "｜", "｜", "｜", "舛"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["午", "亠", "｜", "｜", "舛"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  封: [
    {
      parts: ["圭", "寸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  風: [
    {
      parts: ["ノ", "虱"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["几", "ノ", "虫"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["几", "イ", "口", "一", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  伏: [
    {
      parts: ["亻", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  服: [
    {
      parts: ["月", "卩", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  副: [
    {
      parts: ["畐", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幅: [
    {
      parts: ["巾", "畐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  復: [
    {
      parts: ["彳", "复"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  福: [
    {
      parts: ["礻", "畐"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腹: [
    {
      parts: ["月", "复"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  複: [
    {
      parts: ["衤", "复"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  覆: [
    {
      parts: ["覀", "復"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  払: [
    {
      parts: ["扌", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沸: [
    {
      parts: ["氵", "弗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仏: [
    {
      parts: ["亻", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  物: [
    {
      parts: ["牛", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粉: [
    {
      parts: ["米", "分"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紛: [
    {
      parts: ["糸", "分"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "八", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  雰: [
    {
      parts: ["雨", "分"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  噴: [
    {
      parts: ["口", "賁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "卄", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["唄", "十", "卄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  墳: [
    {
      parts: ["土", "賁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憤: [
    {
      parts: ["忄", "賁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奮: [
    {
      parts: ["大", "隹", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  分: [
    {
      parts: ["八", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  文: [
    {
      parts: ["亠", "メ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  聞: [
    {
      parts: ["門", "耳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丙: [
    {
      parts: ["一", "内"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  平: [
    {
      parts: ["干", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兵: [
    {
      parts: ["丘", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  併: [
    {
      parts: ["亻", "并"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  並: [
    {
      parts: ["䒑", "业"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  柄: [
    {
      parts: ["木", "丙"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陛: [
    {
      parts: ["阝", "比", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閉: [
    {
      parts: ["門", "才"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "｜", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塀: [
    {
      parts: ["土", "屏"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幣: [
    {
      parts: ["敝", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弊: [
    {
      parts: ["敝", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蔽: [
    {
      parts: ["艹", "敝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  餅: [
    {
      parts: ["飠", "并"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  米: [
    {
      parts: ["ソ", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  壁: [
    {
      parts: ["尸", "口", "辛", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "叶", "立", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  璧: [
    {
      parts: ["尸", "口", "辛", "玉"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["尸", "叶", "立", "玉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  癖: [
    {
      parts: ["疒", "尸", "口", "辛"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["疒", "尸", "叶", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  別: [
    {
      parts: ["冂", "万", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "ノ", "フ", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蔑: [
    {
      parts: ["艹", "罒", "戌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  片: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辺: [
    {
      parts: ["辶", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  返: [
    {
      parts: ["辶", "反"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  変: [
    {
      parts: ["亦", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  偏: [
    {
      parts: ["亻", "扁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遍: [
    {
      parts: ["扁", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  編: [
    {
      parts: ["糸", "扁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弁: [
    {
      parts: ["ム", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  便: [
    {
      parts: ["亻", "更"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "史"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勉: [
    {
      parts: ["免", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  歩: [
    {
      parts: ["止", "少"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["卜", "｜", "丁", "八", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  保: [
    {
      parts: ["亻", "呆"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["休", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "冂", "｜", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  哺: [
    {
      parts: ["口", "甫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "月", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  捕: [
    {
      parts: ["扌", "甫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  補: [
    {
      parts: ["衤", "甫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舗: [
    {
      parts: ["舎", "甫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["哺", "𠆢", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  母: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  募: [
    {
      parts: ["艹", "日", "大", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "旦", "人", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  墓: [
    {
      parts: ["艹", "日", "大", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "旦", "人", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慕: [
    {
      parts: ["艹", "日", "大", "㣺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "旦", "人", "㣺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暮: [
    {
      parts: ["艹", "昌", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "旦", "人", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  簿: [
    {
      parts: ["竹", "氵", "専", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["竹", "油", "一", "寸", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["笛", "氵", "一", "寸", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  方: [
    {
      parts: ["｜", "万"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  包: [
    {
      parts: ["勹", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  芳: [
    {
      parts: ["艹", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  邦: [
    {
      parts: ["三", "ノ", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "ナ", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奉: [
    {
      parts: ["三", "人", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "大", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "夫", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "大", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "夫", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宝: [
    {
      parts: ["宀", "玉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抱: [
    {
      parts: ["扌", "包"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  放: [
    {
      parts: ["方", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  法: [
    {
      parts: ["氵", "去"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "一", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泡: [
    {
      parts: ["氵", "包"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胞: [
    {
      parts: ["月", "包"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俸: [
    {
      parts: ["亻", "奉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  倣: [
    {
      parts: ["亻", "放"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  峰: [
    {
      parts: ["山", "夂", "丰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  砲: [
    {
      parts: ["石", "包"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  崩: [
    {
      parts: ["山", "朋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  訪: [
    {
      parts: ["言", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  報: [
    {
      parts: ["幸", "卩", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蜂: [
    {
      parts: ["虫", "夂", "丰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豊: [
    {
      parts: ["曲", "豆"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  飽: [
    {
      parts: ["飠", "包"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  褒: [
    {
      parts: ["哀", "亻", "十", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  縫: [
    {
      parts: ["糸", "逢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "人", "辻", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "人", "丰", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亡: [
    {
      parts: ["亠", "L"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  乏: [
    {
      parts: ["ノ", "之"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  忙: [
    {
      parts: ["忄", "亡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坊: [
    {
      parts: ["土", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妨: [
    {
      parts: ["女", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  忘: [
    {
      parts: ["心", "亡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  防: [
    {
      parts: ["阝", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  房: [
    {
      parts: ["戸", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肪: [
    {
      parts: ["月", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  某: [
    {
      parts: ["甘", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冒: [
    {
      parts: ["日", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["昌", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "口", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  剖: [
    {
      parts: ["立", "口", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紡: [
    {
      parts: ["糸", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "亠", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  望: [
    {
      parts: ["亡", "月", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  傍: [
    {
      parts: ["亻", "亠", "ソ", "冖", "方"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["位", "｜", "｜", "方"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  帽: [
    {
      parts: ["巾", "冒"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  棒: [
    {
      parts: ["木", "奉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貿: [
    {
      parts: ["ノ", "レ", "丶", "刀", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "レ", "丶", "召", "二", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  貌: [
    {
      parts: ["豸", "白", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["豸", "ノ", "兄", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暴: [
    {
      parts: ["日", "共", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["早", "六", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  膨: [
    {
      parts: ["月", "十", "豆", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["月", "吉", "䒑", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謀: [
    {
      parts: ["言", "某"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "甘", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頰: [
    {
      parts: ["大", "人", "人", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  北: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  "木":[{"parts":["十","𠆢"],"cutConnect":true,"cutOneStroke":false},{"parts":["オ","乀"],"cutConnect":false,"cutOneStroke":false},{"parts":["亠","↑"],"cutConnect":false,"cutOneStroke":true}],
  朴: [
    {
      parts: ["木", "卜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  牧: [
    {
      parts: ["牛", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  睦: [
    {
      parts: ["目", "坴"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吐", "二", "儿", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吐", "二", "元", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  僕: [
    {
      parts: ["亻", "业", "䒑", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "业", "ソ", "天", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "业", "ソ", "三", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "｜", "｜", "ソ", "三", "ソ", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亻", "｜", "｜", "ソ", "二", "ソ", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  墨: [
    {
      parts: ["黒", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  撲: [
    {
      parts: ["扌", "业", "䒑", "夫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "业", "ソ", "天", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "业", "ソ", "三", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "｜", "｜", "ソ", "三", "ソ", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "｜", "｜", "ソ", "二", "ソ", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  没: [
    {
      parts: ["氵", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勃: [
    {
      parts: ["一", "字", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "冖", "子", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  堀: [
    {
      parts: ["土", "屈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  本: [
    {
      parts: ["木", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["キ", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  奔: [
    {
      parts: ["大", "十", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  翻: [
    {
      parts: ["番", "羽"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凡: [
    {
      parts: ["几", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盆: [
    {
      parts: ["分", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  麻: [
    {
      parts: ["广", "林"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["床", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  摩: [
    {
      parts: ["麻", "手"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  磨: [
    {
      parts: ["麻", "石"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  魔: [
    {
      parts: ["麻", "鬼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  毎: [
    {
      parts: ["ノ", "一", "毋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妹: [
    {
      parts: ["女", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  枚: [
    {
      parts: ["木", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昧: [
    {
      parts: ["日", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["味", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "一", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  埋: [
    {
      parts: ["土", "里"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["坦", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幕: [
    {
      parts: ["艹", "日", "大", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  膜: [
    {
      parts: ["月", "艹", "日", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["胆", "艹", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  枕: [
    {
      parts: ["木", "冘"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  又: [
    {
      parts: ["ヌ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  末: [
    {
      parts: ["一", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["キ", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["テ", "ト"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抹: [
    {
      parts: ["扌", "末"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  万: [
    {
      parts: ["一", "ノ", "𠃌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  満: [
    {
      parts: ["氵", "卄", "両"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慢: [
    {
      parts: ["忄", "日", "罒", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["怛", "冂", "｜", "｜", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漫: [
    {
      parts: ["氵", "日", "罒", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["浸", "｜", "｜", "亠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  未: [
    {
      parts: ["一", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["キ", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["テ", "ト"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  味: [
    {
      parts: ["口", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  魅: [
    {
      parts: ["鬼", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  岬: [
    {
      parts: ["山", "甲"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  密: [
    {
      parts: ["宀", "必", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蜜: [
    {
      parts: ["宀", "必", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脈: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妙: [
    {
      parts: ["女", "少"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  民: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  眠: [
    {
      parts: ["目", "民"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  矛: [
    {
      parts: ["予", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  務: [
    {
      parts: ["矛", "攵", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  無: [
    {
      parts: ["ノ", "三", "｜", "｜", "｜", "｜", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["午", "亠", "｜", "｜", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "一", "共", "｜", "｜", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["午", "六", "｜", "｜", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夢: [
    {
      parts: ["艹", "罒", "冖", "夕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  霧: [
    {
      parts: ["雨", "務"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  娘: [
    {
      parts: ["女", "良"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  名: [
    {
      parts: ["夕", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  命: [
    {
      parts: ["𠆢", "一", "叩"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["合", "卩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  明: [
    {
      parts: ["日", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  迷: [
    {
      parts: ["辶", "米"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "ソ", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冥: [
    {
      parts: ["冖", "日", "六"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盟: [
    {
      parts: ["明", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  銘: [
    {
      parts: ["金", "名"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鳴: [
    {
      parts: ["口", "鳥"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  滅: [
    {
      parts: ["氵", "戌", "火"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  免: [
    {
      parts: ["ク", "兄", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  面: [
    {
      parts: ["三", "ノ", "罒"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "H", "二", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  綿: [
    {
      parts: ["糸", "白", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  麺: [
    {
      parts: ["麦", "面"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  茂: [
    {
      parts: ["艹", "戊"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  模: [
    {
      parts: ["木", "莫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  毛: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妄: [
    {
      parts: ["亡", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  盲: [
    {
      parts: ["亡", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  耗: [
    {
      parts: ["耒", "毛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  猛: [
    {
      parts: ["犭", "子", "皿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  網: [
    {
      parts: ["糸", "冂", "䒑", "亡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  目: [
    {
      parts: ["一", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "三"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  黙: [
    {
      parts: ["黒", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吠", "土", "一", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吠", "十", "二", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  門: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紋: [
    {
      parts: ["糸", "文"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  問: [
    {
      parts: ["門", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  匁: [
    {
      parts: ["人", "刀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冶: [
    {
      parts: ["冫", "台"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夜: [
    {
      parts: ["亠", "亻", "夂", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  野: [
    {
      parts: ["里", "予"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弥: [
    {
      parts: ["弓", "尓"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["引", "ノ", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  厄: [
    {
      parts: ["厂", "㔾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  役: [
    {
      parts: ["彳", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  約: [
    {
      parts: ["糸", "勺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "ノ", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  訳: [
    {
      parts: ["言", "尺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  薬: [
    {
      parts: ["艹", "楽"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["草", "丶", "丶", "丶", "丶", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  躍: [
    {
      parts: ["⻊", "ヨ", "ヨ", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["唯", "止", "ヨ", "ヨ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  闇: [
    {
      parts: ["門", "音"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["間", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  由: [
    {
      parts: ["口", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["日", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  油: [
    {
      parts: ["氵", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  喩: [
    {
      parts: ["口", "𠆢", "一", "月", "く", "く"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  愉: [
    {
      parts: ["忄", "俞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  諭: [
    {
      parts: ["言", "俞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  輸: [
    {
      parts: ["車", "俞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  癒: [
    {
      parts: ["疒", "俞", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唯: [
    {
      parts: ["口", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "亻", "丶", "三"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  友: [
    {
      parts: ["ナ", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  有: [
    {
      parts: ["ナ", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  勇: [
    {
      parts: ["マ", "男"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幽: [
    {
      parts: ["山", "幺", "幺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  悠: [
    {
      parts: ["亻", "｜", "攵", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  郵: [
    {
      parts: ["垂", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  湧: [
    {
      parts: ["氵", "勇"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "マ", "口", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  猶: [
    {
      parts: ["犭", "酋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  裕: [
    {
      parts: ["衤", "谷"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  遊: [
    {
      parts: ["方", "ノ", "一", "子", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雄: [
    {
      parts: ["ナ", "ム", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誘: [
    {
      parts: ["言", "秀"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "ノ", "𠆢", "乃"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  憂: [
    {
      parts: ["一", "ノ", "月", "冖", "心", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  融: [
    {
      parts: ["鬲", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  優: [
    {
      parts: ["亻", "憂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  与: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  予: [
    {
      parts: ["マ", "了"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  余: [
    {
      parts: ["𠆢", "二", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["𠆢", "一", "ホ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  誉: [
    {
      parts: ["ツ", "一", "八", "言"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  預: [
    {
      parts: ["予", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  幼: [
    {
      parts: ["幺", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  用: [
    {
      parts: ["月", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  羊: [
    {
      parts: ["ソ", "二", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "三", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妖: [
    {
      parts: ["女", "夭"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  洋: [
    {
      parts: ["氵", "羊"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "ソ", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  要: [
    {
      parts: ["覀", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  容: [
    {
      parts: ["宀", "谷"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "𠆢", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  庸: [
    {
      parts: ["广", "聿", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["广", "コ", "十", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  揚: [
    {
      parts: ["扌", "昜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["担", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  揺: [
    {
      parts: ["扌", "爫", "二", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["扌", "爫", "干", "凵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  葉: [
    {
      parts: ["艹", "世", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陽: [
    {
      parts: ["阝", "昜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  溶: [
    {
      parts: ["氵", "容"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["浴", "宀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腰: [
    {
      parts: ["月", "要"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["肝", "山", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  様: [
    {
      parts: ["木", "三", "丷", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["木", "羊", "丶", "丶", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瘍: [
    {
      parts: ["疒", "昜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昜: [
    {
      parts: ["旦", "勿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  踊: [
    {
      parts: ["⻊", "甬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  甬: [
    {
      parts: ["マ", "用"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  窯: [
    {
      parts: ["穴", "羔"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  羔: [
    {
      parts: ["⺷", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  養: [
    {
      parts: ["⺷", "食"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  "⺷": [
    {
      parts: ["丷", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["Y", "三"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  擁: [
    {
      parts: ["推", "亠", "乡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  謡: [
    {
      parts: ["言", "爫", "二", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["言", "爫", "干", "凵"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "爫", "一", "凵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曜: [
    {
      parts: ["日", "ヨ", "ヨ", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["唯", "一", "ヨ", "ヨ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  抑: [
    {
      parts: ["扌", "卬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沃: [
    {
      parts: ["氵", "夭"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夭: [
    {
      parts: ["ノ", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浴: [
    {
      parts: ["氵", "谷"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  欲: [
    {
      parts: ["谷", "欠"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["吹", "八", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  翌: [
    {
      parts: ["羽", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  翼: [
    {
      parts: ["羽", "異"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  拉: [
    {
      parts: ["扌", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  裸: [
    {
      parts: ["衤", "果"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  羅: [
    {
      parts: ["罒", "維"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "｜", "玄", "小", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  来: [
    {
      parts: ["一", "米"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["未", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雷: [
    {
      parts: ["雨", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["雪", "丨", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頼: [
    {
      parts: ["束", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  絡: [
    {
      parts: ["糸", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["幻", "小", "人", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  落: [
    {
      parts: ["艹", "洛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酪: [
    {
      parts: ["酉", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酉: [
    {
      parts: ["西", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["日", "兀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辣: [
    {
      parts: ["辛", "束"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乱: [
    {
      parts: ["舌", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卵: [
    {
      parts: ["卯", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  覧: [
    {
      parts: ["臣", "ノ", "一", "一", "見"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  濫: [
    {
      parts: ["氵", "監"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  藍: [
    {
      parts: ["艹", "監"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  欄: [
    {
      parts: ["棟", "門"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["林", "間"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吏: [
    {
      parts: ["丈", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "史"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  利: [
    {
      parts: ["禾", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  里: [
    {
      parts: ["日", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["田", "土"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["旦", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  理: [
    {
      parts: ["一", "埋"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["王", "里"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  痢: [
    {
      parts: ["疒", "利"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  裏: [
    {
      parts: ["衣", "里"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["亠", "旦", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["亠", "口", "表"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["哀", "一", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  履: [
    {
      parts: ["尸", "復"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  璃: [
    {
      parts: ["王", "离"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  離: [
    {
      parts: ["离", "隹"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  离: [
    {
      parts: ["亠", "凶", "ム", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陸: [
    {
      parts: ["阝", "坴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坴: [
    {
      parts: ["土", "儿", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "兀", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["元", "｜", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "十", "儿", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  立: [
    {
      parts: ["亠", "䒑"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  律: [
    {
      parts: ["彳", "聿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慄: [
    {
      parts: ["忄", "栗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  略: [
    {
      parts: ["田", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柳: [
    {
      parts: ["木", "卯"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  流: [
    {
      parts: ["氵", "㐬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  留: [
    {
      parts: ["ノ", "レ", "丶", "刀", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "レ", "丶", "召", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  竜: [
    {
      parts: ["立", "电"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["音", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  粒: [
    {
      parts: ["米", "立"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隆: [
    {
      parts: ["阝", "夂", "生"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  硫: [
    {
      parts: ["石", "㐬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  侶: [
    {
      parts: ["亻", "呂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  旅: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虜: [
    {
      parts: ["虍", "男"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  慮: [
    {
      parts: ["虍", "思"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  了: [
    {
      parts: ["ア"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  両: [
    {
      parts: ["一", "山", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  良: [
    {
      parts: ["ノ", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  料: [
    {
      parts: ["米", "斗"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  涼: [
    {
      parts: ["氵", "京"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  猟: [
    {
      parts: ["犭", "鼡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陵: [
    {
      parts: ["阝", "坴"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  量: [
    {
      parts: ["旦", "里"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["日", "亘", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旦", "亘", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["昌", "一", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  僚: [
    {
      parts: ["亻", "大", "丶", "丶", "日", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  領: [
    {
      parts: ["令", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寮: [
    {
      parts: ["宀", "尞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  療: [
    {
      parts: ["疒", "尞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瞭: [
    {
      parts: ["目", "尞"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  糧: [
    {
      parts: ["米", "量"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "桓", "日", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "椙", "一", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  カ: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  緑: [
    {
      parts: ["糸", "录"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  林: [
    {
      parts: ["木", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["朴", "一", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  厘: [
    {
      parts: ["厂", "里"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["圧", "田"],
      cutConnect: false,
      cutOneStroke: true,
    },
    {
      parts: ["圧", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  倫: [
    {
      parts: ["亻", "侖"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "𠆢", "冂", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  輪: [
    {
      parts: ["車", "侖"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隣: [
    {
      parts: ["阝", "米", "舛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  臨: [
    {
      parts: ["臣", "ノ", "一", "品"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瑠: [
    {
      parts: ["王", "留"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  涙: [
    {
      parts: ["氵", "戻"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  累: [
    {
      parts: ["田", "糸"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["玄", "冂", "一", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  塁: [
    {
      parts: ["田", "丶", "丶", "丶", "丶", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  類: [
    {
      parts: ["米", "大", "頁"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["柏", "ソ", "大", "二", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  令: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  礼: [
    {
      parts: ["礻", "乚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冷: [
    {
      parts: ["冫", "令"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  励: [
    {
      parts: ["厉", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戻: [
    {
      parts: ["戸", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  例: [
    {
      parts: ["亻", "列"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鈴: [
    {
      parts: ["金", "令"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  零: [
    {
      parts: ["雨", "令"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  霊: [
    {
      parts: ["雨", "二", "业"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隷: [
    {
      parts: ["士", "示", "隶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  齢: [
    {
      parts: ["歯", "令"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  麗: [
    {
      parts: ["一", "冂", "丶", "一", "冂", "丶", "鹿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  暦: [
    {
      parts: ["厂", "林", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  歴: [
    {
      parts: ["厂", "林", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  列: [
    {
      parts: ["歹", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  劣: [
    {
      parts: ["少", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  烈: [
    {
      parts: ["列", "灬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  裂: [
    {
      parts: ["列", "衣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  恋: [
    {
      parts: ["亦", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  連: [
    {
      parts: ["辶", "車"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辻", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  廉: [
    {
      parts: ["广", "兼"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  練: [
    {
      parts: ["糸", "東"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["紳", "一", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  錬: [
    {
      parts: ["金", "東"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["針", "日", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  呂: [
    {
      parts: ["口", "ノ", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  炉: [
    {
      parts: ["火", "戸"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賂: [
    {
      parts: ["貝", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  路: [
    {
      parts: ["⻊", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  露: [
    {
      parts: ["雨", "路"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  老: [
    {
      parts: ["耂", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  労: [
    {
      parts: ["ツ", "冖", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弄: [
    {
      parts: ["王", "廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["干", "开"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  郎: [
    {
      parts: ["良", "阝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朗: [
    {
      parts: ["良", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  浪: [
    {
      parts: ["氵", "良"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  廊: [
    {
      parts: ["广", "郎"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  楼: [
    {
      parts: ["木", "米", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["林", "ソ", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  漏: [
    {
      parts: ["氵", "屚"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  屚: [
    {
      parts: ["尸", "雨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  籠: [
    {
      parts: ["竹", "龍"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  六: [
    {
      parts: ["亠", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  録: [
    {
      parts: ["金", "录"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  录: [
    {
      parts: ["彐", "氺"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  麓: [
    {
      parts: ["林", "鹿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  論: [
    {
      parts: ["言", "侖"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "𠆢", "一", "冂", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  和: [
    {
      parts: ["禾", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  話: [
    {
      parts: ["言", "舌"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["計", "ノ", "口"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  賄: [
    {
      parts: ["貝", "有"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  脇: [
    {
      parts: ["月", "力", "力", "力"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  惑: [
    {
      parts: ["或", "心"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  枠: [
    {
      parts: ["木", "九", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  湾: [
    {
      parts: ["氵", "亦", "弓"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  腕: [
    {
      parts: ["月", "宛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  キ: [
    {
      parts: ["十", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  垔: [
    {
      parts: ["覀", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["丁", "巾", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  俞: [
    {
      parts: ["𠆢", "一", "月", "刂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  或: [
    {
      parts: ["戈", "口", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  昏: [
    {
      parts: ["氏", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沓: [
    {
      parts: ["水", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  韋: [
    {
      parts: ["力", "一", "口", "ヰ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  叚: [
    {
      parts: ["コ", "｜", "二", "コ", "又"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  耂: [
    {
      parts: ["土", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  云: [
    {
      parts: ["二", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夂: [
    {
      parts: ["フ", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  寅: [
    {
      parts: ["宙", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "一", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["穴", "三", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["宀", "六", "二", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  頁: [
    {
      parts: ["一", "ノ", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["百", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "白", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "ノ", "只"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "自", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  呆: [
    {
      parts: ["口", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  开: [
    {
      parts: ["廾", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  廾: [
    {
      parts: ["ノ", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["ナ", "丨"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  敝: [
    {
      parts: ["小", "冂", "小", "攵"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丨: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丶: [
    {
      parts: ["、"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ノ: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  イ: [
    {
      parts: ["ノ", "丨"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  ウ: [
    {
      parts: ["∣", "ワ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  サ: [
    {
      parts: ["一", "リ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  シ: [
    {
      parts: ["ン", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ソ: [
    {
      parts: ["丶", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ツ: [
    {
      parts: ["ソ", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  卜: [
    {
      parts: ["ト"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["丨", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  ナ: [
    {
      parts: ["一", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  礻: [
    {
      parts: ["ネ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  衤: [
    {
      parts: ["礻", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  マ: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ム: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乂: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ユ: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ヨ: [
    {
      parts: ["コ", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  刂: [
    {
      parts: ["リ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  リ: [
    {
      parts: ["丨", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  儿: [
    {
      parts: ["ル"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ワ: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ン: [
    {
      parts: ["丶", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  殳: [
    {
      parts: ["几", "又"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  电: [
    {
      parts: ["日", "乚"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  厉: [
    {
      parts: ["厂", "万"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㐬: [
    {
      parts: ["亠", "ム", "川"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朋: [
    {
      parts: ["月", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  爫: [
    {
      parts: ["ノ", "ツ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亠: [
    {
      parts: ["一", "∣"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  广: [
    {
      parts: ["厂", "∣"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  宀: [
    {
      parts: ["ウ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冖", "∣"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  䒑: [
    {
      parts: ["ソ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  业: [
    {
      parts: ["䒑", "∣", "∣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亥: [
    {
      parts: ["亠", "く", "ノ", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  L: [
    {
      parts: ["一", "丨"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  Y: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  "∣": [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  "⺌": [
    {
      parts: ["丷", "∣"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  "⺧": [
    {
      parts: ["ノ", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  "⻊": [
    {
      parts: ["口", "止"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "下", "亠"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  ヰ: [
    {
      parts: ["｜", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㐅: [
    {
      parts: ["メ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㔾: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㠯: [
    {
      parts: ["コ", "コ", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㣺: [
    {
      parts: ["小", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丂: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丷: [
    {
      parts: ["ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丿: [
    {
      parts: ["ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  力: [
    {
      parts: ["カ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乃: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  之: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乍: [
    {
      parts: ["ケ", "二"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ノ", "下", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  乚: [
    {
      parts: ["し"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乛: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  也: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  于: [
    {
      parts: ["干"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亐: [
    {
      parts: ["一", "ち"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  亘: [
    {
      parts: ["一", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["二", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亢: [
    {
      parts: ["亠", "几"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亦: [
    {
      parts: ["亠", "小", "丿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  亻: [
    {
      parts: ["イ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  佃: [
    {
      parts: ["亻", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["仁", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  侖: [
    {
      parts: ["𠆢", "一", "冂", "卄"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兀: [
    {
      parts: ["π"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  关: [
    {
      parts: ["ソ", "天"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  兴: [
    {
      parts: ["ツ", "一", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  其: [
    {
      parts: ["共", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冂: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冎: [
    {
      parts: ["冂", "｜", "一", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冖: [
    {
      parts: ["ワ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冫: [
    {
      parts: ["ン"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  几: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  凵: [
    {
      parts: ["L", "丨"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  勹: [
    {
      parts: ["𠃌", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  勿: [
    {
      parts: ["勹", "ノ", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  匕: [
    {
      parts: ["ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  匚: [
    {
      parts: ["一", "L"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  卩: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卬: [
    {
      parts: ["ノ", "レ", "卩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卯: [
    {
      parts: ["卬", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  厂: [
    {
      parts: ["ノ", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  厃: [
    {
      parts: ["ク", "厂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  厶: [
    {
      parts: ["ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  叩: [
    {
      parts: ["口", "卩"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  只: [
    {
      parts: ["口", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吊: [
    {
      parts: ["口", "巾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吠: [
    {
      parts: ["口", "犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吾: [
    {
      parts: ["五", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  咸: [
    {
      parts: ["戌", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  啇: [
    {
      parts: ["亠", "ソ", "冂", "古"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["辛", "｜", "｜", "口"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  喬: [
    {
      parts: ["夭", "口", "冂", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  囗: [
    {
      parts: ["口"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  圭: [
    {
      parts: ["土", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["十", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坐: [
    {
      parts: ["人", "人", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  壬: [
    {
      parts: ["ノ", "士"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["千", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夋: [
    {
      parts: ["ム", "儿", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  夹: [
    {
      parts: ["夫", "ソ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  妾: [
    {
      parts: ["立", "女"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  宋: [
    {
      parts: ["宀", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尓: [
    {
      parts: ["ノ", "一", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尞: [
    {
      parts: ["大", "丶", "丶", "日", "小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尤: [
    {
      parts: ["犬"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  尸: [
    {
      parts: ["コ", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  屏: [
    {
      parts: ["尸", "并"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巴: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  巷: [
    {
      parts: ["卄", "一", "八", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "｜", "八", "己"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  庄: [
    {
      parts: ["广", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["圧", "｜"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  廴: [
    {
      parts: ["フ", "フ", "乀"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  廿: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弗: [
    {
      parts: ["弓", "ノ", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弘: [
    {
      parts: ["弓", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彐: [
    {
      parts: ["三", "丨"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  彑: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彡: [
    {
      parts: ["ノ", "ノ", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彦: [
    {
      parts: ["亠", "ソ", "厂", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["立", "ノ", "彡"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  彳: [
    {
      parts: ["ノ", "イ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  忄: [
    {
      parts: ["小"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  怛: [
    {
      parts: ["忄", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戈: [
    {
      parts: ["弋", "ノ"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  戉: [
    {
      parts: ["戊", "ノ"],
      cutConnect: false,
      cutOneStroke: true,
    },
  ],
  戊: [
    {
      parts: ["ノ", "戈"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  扌: [
    {
      parts: ["オ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  攵: [
    {
      parts: ["ノ", "一", "乂"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ケ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  昌: [
    {
      parts: ["日", "日"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旦", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["口", "口", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朔: [
    {
      parts: ["屰", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  杀: [
    {
      parts: ["メ", "ホ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  杏: [
    {
      parts: ["木", "口"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["古", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柏: [
    {
      parts: ["木", "白"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  柚: [
    {
      parts: ["木", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  栗: [
    {
      parts: ["覀", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["丁", "巾", "干", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  椙: [
    {
      parts: ["木", "昌"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  楚: [
    {
      parts: ["林", "疋"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  此: [
    {
      parts: ["止", "ヒ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  氵: [
    {
      parts: ["シ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  氺: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  沐: [
    {
      parts: ["氵", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["汁", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  泪: [
    {
      parts: ["氵", "目"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  洛: [
    {
      parts: ["氵", "各"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  灬: [
    {
      parts: ["丶", "丶", "丶", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  烏: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  爾: [
    {
      parts: ["一", "巾", "八", "メ", "メ", "メ", "メ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  犭: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  瓜: [
    {
      parts: ["爪", "一", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  甫: [
    {
      parts: ["十", "月", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  疒: [
    {
      parts: ["广", "冫"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  癶: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  眞: [
    {
      parts: ["ヒ", "貝", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["旨", "一", "L", "八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  禺: [
    {
      parts: ["甲", "冂", "一", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  禾: [
    {
      parts: ["ノ", "木"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["千", "𠆢"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  竟: [
    {
      parts: ["立", "兄", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["音", "儿"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  罒: [
    {
      parts: ["口", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["山", "冂", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  而: [
    {
      parts: ["一", "ノ", "冂", "｜", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  耒: [
    {
      parts: ["二", "木"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "未"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "｜", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  胡: [
    {
      parts: ["古", "月"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舜: [
    {
      parts: ["爫", "冖", "舛"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  艮: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  艹: [
    {
      parts: ["サ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  莫: [
    {
      parts: ["艹", "日", "大"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["艹", "旦", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  蜀: [
    {
      parts: ["罒", "勹", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  袁: [
    {
      parts: ["哀", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["土", "口", "ノ", "レ", "ノ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  覀: [
    {
      parts: ["西"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["干", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豕: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  賁: [
    {
      parts: ["十", "艹", "貝"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辰: [
    {
      parts: ["厂", "二", "レ", "ノ", "乀"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  辶: [
    {
      parts: ["辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辻: [
    {
      parts: ["辶", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  逢: [
    {
      parts: ["夂", "二", "辻"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["夂", "丰", "辶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  酋: [
    {
      parts: ["ソ", "酉"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閂: [
    {
      parts: ["門", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  閏: [
    {
      parts: ["門", "王"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "土"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "干"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["閂", "工"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  阝: [
    {
      parts: ["β"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  陏: [
    {
      parts: ["阝", "有"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  隶: [
    {
      parts: ["コ", "一", "氺"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  隹: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  龍: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  龶: [
    {
      parts: ["三", "∣"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["土", "一"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["十", "二"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  龹: [
    {
      parts: ["火", "二"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["ソ", "夫"],
      cutConnect: true,
      cutOneStroke: false,
    },
    {
      parts: ["䒑", "大"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  𠃌: [
    {
      parts: ["フ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  𠆢: [
    {
      parts: ["へ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["人"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["八"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  辶: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  Ｅ: [
    {
      parts: ["三", "丨"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  貇: [
    {
      parts: ["豸", "艮"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  豸: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  卄: [
    {
      parts: ["サ"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["廾"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  气: [
    {
      parts: ["ノ", "二", "乙"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  卦: [
    {
      parts: ["圭", "卜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  乡: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  冓: [
    {
      parts: ["十", "十", "再"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["土", "十", "土", "冂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  歹: [
    {
      parts: ["一", "夕"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  朿: [
    {
      parts: ["十", "冂", "𠆢"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  龰: [
    {
      parts: ["卜", "人"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  冘: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  弋: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  镸: [
    {
      parts: ["三", "亠", "ム"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["云", "二", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  并: [
    {
      parts: ["ソ", "开"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畐: [
    {
      parts: ["一", "口", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  毋: [
    {
      parts: [],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鬲: [
    {
      parts: ["一", "口", "冂", "儿", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  鼡: [
    {
      parts: ["ツ", "用"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  复: [
    {
      parts: ["ノ", "一", "日", "夂"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  汀: [
    {
      parts: ["氵", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  吽: [
    {
      parts: ["口", "牛"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["叶", "ノ", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  杣: [
    {
      parts: ["木", "山"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丰: [
    {
      parts: ["十", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["三", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["一", "キ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  姑: [
    {
      parts: ["女", "古"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["如", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  牡: [
    {
      parts: ["牛", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  紬: [
    {
      parts: ["糸", "由"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  畠: [
    {
      parts: ["白", "田"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["昌", "ノ", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  杠: [
    {
      parts: ["木", "工"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  桓: [
    {
      parts: ["木", "亘"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㑒: [
    {
      parts: ["𠆢", "一", "口", "人"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  扁: [
    {
      parts: ["戸", "冂", "十", "｜"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  段: [
    {
      parts: ["ノ", "｜", "二", "ノ", "殳"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  聿: [
    {
      parts: ["コ", "丰"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  㦮: [
    {
      parts: ["二", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  曷: [
    {
      parts: ["日", "勹", "人", "L"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  戌: [
    {
      parts: ["ノ", "一", "戈"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  且: [
    {
      parts: ["月", "一"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["冂", "三"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  屰: [
    {
      parts: ["䒑", "凵", "ノ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  雨: [
    {
      parts: ["一", "巾", "丶", "丶", "丶", "丶"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  虱: [
    {
      parts: ["乙", "ノ", "虫"],
      cutConnect: false,
      cutOneStroke: false,
    },
    {
      parts: ["乙", "イ", "口", "一", "丶"],
      cutConnect: true,
      cutOneStroke: false,
    },
  ],
  茄: [
    {
      parts: ["艹", "加"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  李: [
    {
      parts: ["木", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  仔: [
    {
      parts: ["亻", "子"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  丑: [
    {
      parts: ["刀", "二"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  肚: [
    {
      parts: ["月", "土"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  舛: [
    {
      parts: ["夕", "ヰ"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  釘: [
    {
      parts: ["金", "丁"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  坦: [
    {
      parts: ["土", "旦"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  叶: [
    {
      parts: ["口", "十"],
      cutConnect: false,
      cutOneStroke: false,
    },
  ],
  唖: [{ parts: ["口", "亜"], cutConnect: false, cutOneStroke: false }],
  娃: [{ parts: ["女", "圭"], cutConnect: false, cutOneStroke: false }],
  阿: [{ parts: ["阝", "可"], cutConnect: false, cutOneStroke: false }],
  姶: [{ parts: ["女", "合"], cutConnect: false, cutOneStroke: false }],
  葵: [{ parts: ["艹", "癶", "天"], cutConnect: false, cutOneStroke: false }],
  咳: [{ parts: ["口", "亥"], cutConnect: false, cutOneStroke: false }],
  茜: [{ parts: ["艹", "西"], cutConnect: false, cutOneStroke: false }],
  穐: [{ parts: ["禾", "亀"], cutConnect: false, cutOneStroke: false }],
  渥: [{ parts: ["氵", "屋"], cutConnect: false, cutOneStroke: false }],
  旭: [{ parts: ["九", "日"], cutConnect: false, cutOneStroke: false }],
  葦: [{ parts: ["艹", "韋"], cutConnect: false, cutOneStroke: false }],
};
