import { FC } from "react";
import { ModeType } from "../Menu/ModeSelector";
import { Pos } from "../gridToolType";
import { Rect } from "react-konva";

type BlackListCellProps = {
  mode: ModeType | null;
  size: number;
  blackList: Pos[];
  setBlackList: React.Dispatch<React.SetStateAction<Pos[]>>;
  gridOffsetX: number;
  gridOffsetY: number;
};

export const BlackListCell: FC<BlackListCellProps> = ({
  mode,
  size,
  blackList,
  setBlackList,
  gridOffsetX,
  gridOffsetY,
}) => {
  return (
    <>
      {blackList.map((black, i) => {
        return (
          <Rect
            key={"black-" + i}
            x={black.x * size + gridOffsetX}
            y={black.y * size + gridOffsetY}
            width={size}
            height={size}
            fill="#000"
            onClick={(e) => {
              if (mode !== "square") {
                return;
              }
              const newBlackList = [...blackList];
              newBlackList.splice(i, 1);
              setBlackList(newBlackList);
            }}
          />
        );
      })}
    </>
  );
};
