import { DataSource } from "./generalSearch/dataSource/dataSource";
export const dataSource = new DataSource();
const onAllLoad = () => {
    dataSource.set("all", [
        ...new Set((dataSource.get("neologd_user") ?? [])
            .concat(dataSource.get("naist_jdic") ?? [])
            .concat(dataSource.get("unidic") ?? [])
            .concat(dataSource.get("buta") ?? [])
            .concat(dataSource.get("common") ?? [])
            .concat(dataSource.get("english") ?? [])
            .concat(dataSource.get("cefr") ?? [])
            .concat(dataSource.get("common_hepburn") ?? [])
            .concat(dataSource.get("pref") ?? [])
            // .concat(dataSource.get('poke') ?? [])
            .concat(dataSource.get("nicoime") ?? [])
            .concat(dataSource.get("nico_intersection_pixiv") ?? [])
            .concat(dataSource.get("hatena") ?? [])
            // .concat(dataSource.get('idolmaster') ?? [])
            // .concat(dataSource.get('kaikei') ?? [])
            // .concat(dataSource.get('mildic') ?? [])
            // .concat(dataSource.get('gundam') ?? [])
            // .concat(dataSource.get('dqdic') ?? [])
            .concat(dataSource.get("food_illust") ?? [])
            .concat(dataSource.get("animal_koto") ?? [])
            .concat(dataSource.get("juku_skk_l") ?? [])
            .concat(dataSource.get("jukugo_on_wikipedia") ?? [])
            .concat(dataSource.get("illust1") ?? [])
            .concat(dataSource.get("illust2") ?? [])
            .concat(dataSource.get("illust3") ?? [])
            .concat(dataSource.get("illust4") ?? [])
            .concat(dataSource.get("illust5") ?? [])
            .concat(dataSource.get("custom") ?? [])),
    ].sort());
};
export const onLoad = (self, key, hasAll) => {
    console.log("onLoad", key);
    if (key === "all") {
        onAllLoad();
        self.postMessage(Object.keys(dataSource.source).filter((key) => dataSource.get(key) !== null));
        return;
    }
    const reader = new FileReader();
    reader.onload = function () {
        const text = reader.result;
        dataSource.set(key, [
            ...new Set(text
                .split("\n")
                .map((word) => word.split(/[\s,]/)[0])
                .filter((word) => word.length > 0 &&
                word.charAt(0) !== "#" &&
                word.charAt(0) !== ";" &&
                word.charAt(0) !== "!")),
        ].sort());
        if (hasAll) {
            onAllLoad();
        }
        self.postMessage(Object.keys(dataSource.source).filter((key) => dataSource.get(key) !== null));
    };
    fetch(process.env.PUBLIC_URL + "/dictionary/" + key + ".txt")
        .then((response) => {
        return response.blob();
    })
        .then((blob) => {
        reader.readAsText(blob);
    });
};
export const onLoadText = (self, key, text) => {
    console.log("onloadText", key, text);
    dataSource.set(key, [...new Set(text.split("\n"))].sort());
    console.log("onloadText", key, dataSource.get(key));
    self.postMessage(Object.keys(dataSource.source).filter((key) => dataSource.get(key) !== null));
};
export const onLoadCustom = (self, text, hasAll) => {
    const words = [
        ...new Set(text
            .split("\n")
            .map((word) => word.split(/[\s,]/)[0])
            .filter((word) => word.length > 0 &&
            word.charAt(0) !== "#" &&
            word.charAt(0) !== ";" &&
            word.charAt(0) !== "!")),
    ].sort();
    dataSource.set("custom", words);
    if (hasAll) {
        onAllLoad();
    }
    self.postMessage(Object.keys(dataSource.getAll() ?? {}).filter((key) => dataSource.get(key) !== null));
};
export const onLoadCustomArr = (self, items, hasAll) => {
    const words = [
        ...new Set(items
            .map((word) => word.split(/[\s]/)[0])
            .filter((word) => word.length > 0 &&
            word.charAt(0) !== "#" &&
            word.charAt(0) !== ";" &&
            word.charAt(0) !== "!")),
    ].sort();
    dataSource.set("custom", words);
    if (hasAll) {
        onAllLoad();
    }
    self.postMessage(Object.keys(dataSource.getAll() ?? {}).filter((key) => dataSource.get(key) !== null));
};
export const onUnload = (self, key, hasAll) => {
    dataSource.delete(key);
    if (hasAll) {
        onAllLoad();
    }
};
