import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import enigmajs from "enigma";
import { FC, useState } from "react";
import { PlainText } from "../../../../common/PlainText";
import { ConvertProps } from "./ConvertTemplate";

const options = ["I", "II", "III", "IV", "V"];
const positions = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export const EnigmaConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  var rotoren: { [key: string]: enigmajs.Rotor } = {
    I: new enigmajs.Rotor("EKMFLGDQVZNTOWYHXUSPAIBRCJ", "Q", "I"),
    II: new enigmajs.Rotor("AJDKSIRUXBLHWTMCQGZNPYFVOE", "E", "II"),
    III: new enigmajs.Rotor("BDFHJLCPRTXVZNYEIWGAKMUSQO", "V", "III"),
    IV: new enigmajs.Rotor("ESOVPZJAYQUIRHXLNFTGKDCMWB", "J", "IV"),
    V: new enigmajs.Rotor("VZBRGITYUPSDNHLXAWMJQOFECK", "Z", "V"),
  };

  var umkehrwalzen: enigmajs.Umkehrwalze[] = [];
  umkehrwalzen.push(new enigmajs.Umkehrwalze("EJMZALYXVBWFCRQUONTSPIKHGD"));
  umkehrwalzen.push(new enigmajs.Umkehrwalze("YRUHQSLDPXNGOKMIEBFZCWVJAT"));
  umkehrwalzen.push(new enigmajs.Umkehrwalze("FVPJIAOYEDRZXWGCTKUQSBNMHL"));

  const eintrittswalzen: enigmajs.Eintrittswalze[] = [];
  eintrittswalzen.push(
    new enigmajs.Eintrittswalze("ABCDEFGHIJKLMNOPQRSTUVWXYZ")
  );

  const [rotor1, setRotor1] = useState<string | null>("I");
  const [rotor2, setRotor2] = useState<string | null>("II");
  const [rotor3, setRotor3] = useState<string | null>("III");
  const [inputRotor1, setInputRotor1] = useState<string>();
  const [inputRotor2, setInputRotor2] = useState<string>();
  const [inputRotor3, setInputRotor3] = useState<string>();
  const [position1, setPosition1] = useState<string | null>("A");
  const [position2, setPosition2] = useState<string | null>("A");
  const [position3, setPosition3] = useState<string | null>("A");
  const [inputPosition1, setInputPosition1] = useState<string>();
  const [inputPosition2, setInputPosition2] = useState<string>();
  const [inputPosition3, setInputPosition3] = useState<string>();
  const [plugboard, setPlugboard] = useState<string>("");

  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <PlainText>ホイール: UKW-B</PlainText>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={2}>
          <Autocomplete
            value={rotor1}
            onChange={(event: any, newValue: string | null) => {
              setRotor1(newValue);
            }}
            inputValue={inputRotor1}
            onInputChange={(event, newInputValue) => {
              setInputRotor1(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{ maxWidth: 150 }}
            renderInput={(params) => <TextField {...params} label="Rotor1" />}
            autoHighlight
            autoSelect
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            value={rotor2}
            onChange={(event: any, newValue: string | null) => {
              setRotor2(newValue);
            }}
            inputValue={inputRotor2}
            onInputChange={(event, newInputValue) => {
              setInputRotor2(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{ maxWidth: 150 }}
            renderInput={(params) => <TextField {...params} label="Rotor2" />}
            autoHighlight
            autoSelect
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            value={rotor3}
            onChange={(event: any, newValue: string | null) => {
              setRotor3(newValue);
            }}
            inputValue={inputRotor3}
            onInputChange={(event, newInputValue) => {
              setInputRotor3(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{ maxWidth: 150 }}
            renderInput={(params) => <TextField {...params} label="Rotor3" />}
            autoHighlight
            autoSelect
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={2}>
          <Autocomplete
            value={position1}
            onChange={(event: any, newValue: string | null) => {
              setPosition1(newValue);
            }}
            inputValue={inputPosition1}
            onInputChange={(event, newInputValue) => {
              setInputPosition1(newInputValue);
            }}
            id="controllable-states-demo"
            options={positions}
            sx={{ maxWidth: 150 }}
            renderInput={(params) => (
              <TextField {...params} label="Position1" />
            )}
            autoHighlight
            autoSelect
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            value={position2}
            onChange={(event: any, newValue: string | null) => {
              setPosition2(newValue);
            }}
            inputValue={inputPosition2}
            onInputChange={(event, newInputValue) => {
              setInputPosition2(newInputValue);
            }}
            id="controllable-states-demo"
            options={positions}
            sx={{ maxWidth: 150 }}
            renderInput={(params) => (
              <TextField {...params} label="Position2" />
            )}
            autoHighlight
            autoSelect
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            value={position3}
            onChange={(event: any, newValue: string | null) => {
              setPosition3(newValue);
            }}
            inputValue={inputPosition3}
            onInputChange={(event, newInputValue) => {
              setInputPosition3(newInputValue);
            }}
            id="controllable-states-demo"
            options={positions}
            sx={{ maxWidth: 150 }}
            renderInput={(params) => (
              <TextField {...params} label="Position3" />
            )}
            autoHighlight
            autoSelect
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <PlainText>リング設定: AAA</PlainText>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <TextField
          label="プラグボード配線"
          variant="outlined"
          value={plugboard}
          onChange={(e) => {
            setPlugboard(e.target.value);
          }}
          sx={{ ml: 1, width: "300px" }}
          placeholder="AB CD EF"
        />
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => {
              if (!rotor1 || !rotor2 || !rotor3) {
                return;
              }
              if (!position1 || !position2 || !position3) {
                return;
              }
              const rotors = [
                rotoren[rotor1],
                rotoren[rotor2],
                rotoren[rotor3],
              ];

              const steckerbrett = new enigmajs.Steckerbrett(plugboard);
              const enigma = new enigmajs.Enigma(
                rotors,
                umkehrwalzen[1],
                steckerbrett,
                eintrittswalzen[0]
              );
              enigma.setPositions(position1 + position2 + position3);
              enigma.setRingSettings("AAA");
              setConverted(enigma.string(pattern));
            }}
            disabled={
              !rotor1 ||
              !rotor2 ||
              !rotor3 ||
              !position1 ||
              !position2 ||
              !position3
            }
          >
            変換
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
