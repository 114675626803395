import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Title } from "../../common/Title";
import { useMemo, useState } from "react";
import { PlainText } from "../../common/PlainText";
import { Helmet } from "react-helmet";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Link } from "react-router-dom";
import { textLength } from "@langue-de-chat-llc/enigmastudio-algorithm";
export const TextCounter = () => {
  const [text, setText] = useState("");

  const lineCount = useMemo(() => {
    return text.split("\n").length;
  }, [text]);
  const textCount = useMemo(() => {
    return textLength(text);
  }, [text]);
  const lineCountNonSpace = useMemo(() => {
    return text.split("\n").filter((e) => e.replace(/\s/g, "").length > 0)
      .length;
  }, [text]);

  const textCountNonSpace = useMemo(() => {
    return textLength(text.replace(/\s/g, ""));
  }, [text]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>文字数カウント - EnigmaStudio</title>
        <meta
          name="description"
          content="入力した文章の文字数をカウントします。"
        />
      </Helmet>
      <Title>文字数カウント</Title>
      <PlainText>
        <span style={{ fontSize: "1rem" }}>
          入力した文章の文字数をカウントします。
          <br />
          入力したデータはブラウザ内でのみ処理され、外部に送信されることはありません。
        </span>
      </PlainText>
      <Box>
        <Box maxWidth={"100%"}>
          <TextField
            label="テキストボックス"
            variant="outlined"
            sx={{
              width: "100%",
              mt: 2,
            }}
            multiline
            rows={12}
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            inputProps={{}}
          />
        </Box>
        <Stack>
          <Typography
            variant="body2"
            sx={{
              color: "#ab47bc",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            {lineCount.toLocaleString()}行 {textCount.toLocaleString()}文字
            <br />
            {lineCountNonSpace.toLocaleString()}行{" "}
            {textCountNonSpace.toLocaleString()}文字（空白・空行を除く）
          </Typography>
          <Box maxWidth={"100%"} sx={{ mt: 2 }}>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                setText("");
              }}
              disabled={text.trimEnd() === ""}
            >
              リセット
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(text);
              }}
              startIcon={
                <ContentCopyIcon
                  style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                />
              }
            >
              コピー
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                navigator.clipboard.readText().then((clipText) => {
                  setText(clipText);
                });
              }}
              startIcon={
                <ContentPasteIcon
                  style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                />
              }
            >
              ペースト
            </Button>
          </Box>
          <Box maxWidth={"100%"} sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: "gray",
                my: 0,
                mb: 0.5,
                py: 0,
              }}
            >
              ※ ペーストは、ブラウザの設定によっては動作しない場合があります。
            </Typography>
          </Box>
          <Box maxWidth={"100%"} sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: "gray",
                my: 0,
                mb: 0.5,
                py: 0,
              }}
            >
              原稿の文字数はこちら→{" "}
              <Link to="/writing-counter">原稿カウンター</Link>
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
