export const riddledPrefData = [
  ["ほっかいどう", "＊＊＊＊＊う"],
  ["とうきょう", "＊＊＊＊う"],
  ["ほっかいどう", "＊＊＊＊ど＊"],
  ["ほっかいどう", "＊＊＊い＊＊"],
  ["おおさか", "しずおか、ふくおか、＊＊＊か"],
  ["しずおか", "おおさか、ふくおか、＊＊＊か"],
  ["ふくおか", "おおさか、しずおか、＊＊＊か"],
  ["いばらき", "ながさき、みやざき、＊＊＊き"],
  ["ながさき", "いばらき、みやざき、＊＊＊き"],
  ["みやざき", "いばらき、ながさき、＊＊＊き"],
  ["ひょうご", "＊＊＊ご"],
  ["やまなし", "＊＊＊し"],
  ["おおいた", "にいがた、やまがた、＊＊＊た"],
  ["にいがた", "おおいた、やまがた、＊＊＊た"],
  ["やまがた", "おおいた、にいがた、＊＊＊た"],
  ["やまぐち", "＊＊＊ち"],
  ["きょうと", "くまもと、＊＊＊と"],
  ["くまもと", "きょうと、＊＊＊と"],
  [
    "おかやま",
    "かごしま、さいたま、とくしま、ひろしま、ふくしま、わかやま、＊＊＊ま",
  ],
  [
    "かごしま",
    "おかやま、さいたま、とくしま、ひろしま、ふくしま、わかやま、＊＊＊ま",
  ],
  [
    "さいたま",
    "おかやま、かごしま、とくしま、ひろしま、ふくしま、わかやま、＊＊＊ま",
  ],
  [
    "とくしま",
    "おかやま、かごしま、さいたま、ひろしま、ふくしま、わかやま、＊＊＊ま",
  ],
  [
    "ひろしま",
    "おかやま、かごしま、さいたま、とくしま、ふくしま、わかやま、＊＊＊ま",
  ],
  [
    "ふくしま",
    "おかやま、かごしま、さいたま、とくしま、ひろしま、わかやま、＊＊＊ま",
  ],
  [
    "わかやま",
    "おかやま、かごしま、さいたま、とくしま、ひろしま、ふくしま、＊＊＊ま",
  ],
  ["とうきょう", "＊＊＊ょ＊"],
  ["あおもり", "とっとり、＊＊＊り"],
  ["とっとり", "あおもり、＊＊＊り"],
  ["いしかわ", "おきなわ、かながわ、＊＊＊わ"],
  ["おきなわ", "いしかわ、かながわ、＊＊＊わ"],
  ["かながわ", "いしかわ、おきなわ、＊＊＊わ"],
  ["ふくい", "＊＊い"],
  ["おおいた", "＊＊い＊"],
  ["きょうと", "ひょうご、＊＊う＊"],
  ["ひょうご", "きょうと、＊＊う＊"],
  ["しずおか", "ふくおか、＊＊お＊"],
  ["ふくおか", "しずおか、＊＊お＊"],
  ["いしかわ", "＊＊か＊"],
  ["かながわ", "にいがた、やまがた、＊＊が＊"],
  ["にいがた", "かながわ、やまがた、＊＊が＊"],
  ["やまがた", "かながわ、にいがた、＊＊が＊"],
  ["ほっかいどう", "＊＊か＊＊＊"],
  ["とちぎ", "みやぎ、＊＊ぎ"],
  ["みやぎ", "とちぎ、＊＊ぎ"],
  ["とうきょう", "＊＊き＊＊"],
  ["やまぐち", "＊＊ぐ＊"],
  ["おおさか", "ながさき、＊＊さ＊"],
  ["ながさき", "おおさか、＊＊さ＊"],
  ["みやざき", "＊＊ざ＊"],
  ["かごしま", "とくしま、ひろしま、ふくしま、＊＊し＊"],
  ["とくしま", "かごしま、ひろしま、ふくしま、＊＊し＊"],
  ["ひろしま", "かごしま、とくしま、ふくしま、＊＊し＊"],
  ["ふくしま", "かごしま、とくしま、ひろしま、＊＊し＊"],
  ["あきた", "＊＊た"],
  ["さいたま", "＊＊た＊"],
  ["あいち", "こうち、＊＊ち"],
  ["こうち", "あいち、＊＊ち"],
  ["いわて", "＊＊て"],
  ["とっとり", "＊＊と＊"],
  ["おきなわ", "やまなし、＊＊な＊"],
  ["やまなし", "おきなわ、＊＊な＊"],
  ["しまね", "＊＊ね"],
  ["ながの", "＊＊の"],
  ["ぐんま", "とやま、＊＊ま"],
  ["とやま", "ぐんま、＊＊ま"],
  ["えひめ", "＊＊め"],
  ["あおもり", "くまもと、＊＊も＊"],
  ["くまもと", "あおもり、＊＊も＊"],
  ["おかやま", "わかやま、＊＊や＊"],
  ["わかやま", "おかやま、＊＊や＊"],
  ["いばらき", "＊＊ら＊"],
  ["かがわ", "＊＊わ"],
  ["あいち", "＊い＊"],
  ["さいたま", "にいがた、＊い＊＊"],
  ["にいがた", "さいたま、＊い＊＊"],
  ["こうち", "＊う＊"],
  ["とうきょう", "＊う＊＊＊"],
  ["みえ", "＊え"],
  ["あおもり", "おおいた、おおさか、＊お＊＊"],
  ["おおいた", "あおもり、おおさか、＊お＊＊"],
  ["おおさか", "あおもり、おおいた、＊お＊＊"],
  ["さが", "しが、＊が"],
  ["しが", "さが、＊が"],
  ["かがわ", "ながの、＊が＊"],
  ["ながの", "かがわ、＊が＊"],
  ["おかやま", "わかやま、＊か＊＊"],
  ["わかやま", "おかやま、＊か＊＊"],
  ["ながさき", "＊が＊＊"],
  ["あきた", "＊き＊"],
  ["おきなわ", "＊き＊＊"],
  ["ふくい", "＊く＊"],
  ["とくしま", "ふくおか、ふくしま、＊く＊＊"],
  ["ふくおか", "とくしま、ふくしま、＊く＊＊"],
  ["ふくしま", "とくしま、ふくおか、＊く＊＊"],
  ["かごしま", "＊ご＊＊"],
  ["いしかわ", "＊し＊＊"],
  ["しずおか", "＊ず＊＊"],
  ["とちぎ", "＊ち＊"],
  ["とっとり", "＊っ＊＊"],
  ["ほっかいどう", "＊っ＊＊＊＊"],
  ["かながわ", "＊な＊＊"],
  ["ちば", "＊ば"],
  ["いばらき", "＊ば＊＊"],
  ["えひめ", "＊ひ＊"],
  ["ぎふ", "＊ふ"],
  ["しまね", "＊ま＊"],
  ["くまもと", "やまがた、やまぐち、やまなし、＊ま＊＊"],
  ["やまがた", "くまもと、やまぐち、やまなし、＊ま＊＊"],
  ["やまぐち", "くまもと、やまがた、やまなし、＊ま＊＊"],
  ["やまなし", "くまもと、やまがた、やまぐち、＊ま＊＊"],
  ["とやま", "みやぎ、＊や＊"],
  ["みやぎ", "とやま、＊や＊"],
  ["みやざき", "＊や＊＊"],
  ["きょうと", "ひょうご、＊ょ＊＊"],
  ["ひょうご", "きょうと、＊ょ＊＊"],
  ["なら", "＊ら"],
  ["ひろしま", "＊ろ＊＊"],
  ["いわて", "＊わ＊"],
  ["ぐんま", "＊ん＊"],
  ["あいち", "あきた、あ＊＊"],
  ["あきた", "あいち、あ＊＊"],
  ["あおもり", "あ＊＊＊"],
  ["いわて", "い＊＊"],
  ["いしかわ", "いばらき、い＊＊＊"],
  ["いばらき", "いしかわ、い＊＊＊"],
  ["えひめ", "え＊＊"],
  ["おおいた", "おおさか、おかやま、おきなわ、お＊＊＊"],
  ["おおさか", "おおいた、おかやま、おきなわ、お＊＊＊"],
  ["おかやま", "おおいた、おおさか、おきなわ、お＊＊＊"],
  ["おきなわ", "おおいた、おおさか、おかやま、お＊＊＊"],
  ["かがわ", "か＊＊"],
  ["かごしま", "かながわ、か＊＊＊"],
  ["かながわ", "かごしま、か＊＊＊"],
  ["ぎふ", "ぎ＊"],
  ["きょうと", "き＊＊＊"],
  ["ぐんま", "ぐ＊＊"],
  ["くまもと", "く＊＊＊"],
  ["こうち", "こ＊＊"],
  ["さが", "さ＊"],
  ["さいたま", "さ＊＊＊"],
  ["しが", "し＊"],
  ["しまね", "し＊＊"],
  ["しずおか", "し＊＊＊"],
  ["ちば", "ち＊"],
  ["とちぎ", "とやま、と＊＊"],
  ["とやま", "とちぎ、と＊＊"],
  ["とくしま", "とっとり、と＊＊＊"],
  ["とっとり", "とくしま、と＊＊＊"],
  ["とうきょう", "と＊＊＊＊"],
  ["なら", "な＊"],
  ["ながの", "な＊＊"],
  ["ながさき", "な＊＊＊"],
  ["にいがた", "に＊＊＊"],
  ["ひょうご", "ひろしま、ひ＊＊＊"],
  ["ひろしま", "ひょうご、ひ＊＊＊"],
  ["ふくい", "ふ＊＊"],
  ["ふくおか", "ふくしま、ふ＊＊＊"],
  ["ふくしま", "ふくおか、ふ＊＊＊"],
  ["ほっかいどう", "ほ＊＊＊＊＊"],
  ["みえ", "み＊"],
  ["みやぎ", "み＊＊"],
  ["みやざき", "み＊＊＊"],
  ["やまがた", "やまぐち、やまなし、や＊＊＊"],
  ["やまぐち", "やまがた、やまなし、や＊＊＊"],
  ["やまなし", "やまがた、やまぐち、や＊＊＊"],
  ["わかやま", "わ＊＊＊"],
];
