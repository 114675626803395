import { useNavigate } from "react-router-dom";

export const useCommonRouter = () => {
  const navigate = useNavigate();

  return {
    push: (path: string) => navigate(path),
    replace: (path: string) => navigate(path, { replace: true }),
    goBack: () => navigate(-1),
  };
};
