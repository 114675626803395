"use client";
import { FC, useState } from "react";
import { CommonAppProp } from "../../../../../../_lib/eniverse/imaginate/mainView/CommponAppProp";

export const NazoConnectMapGroupView: FC<CommonAppProp> = ({
  currentNode,
  updateNode,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return <> </>;
};
