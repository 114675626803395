import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FC, useMemo } from "react";
import { useStatePersist } from "use-state-persist";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { puzsqThemeOptions } from "../siteConfig/themeOptions";
import { CommonAppBar } from "./CommonAppBar";
import { CommonFooter } from "./CommonFooter";
import { DrawerState } from "./drawerState";
import { Helmet } from "react-helmet";

export const drawerWidth = 240;

const Wrapper: FC<{
  appbar?: boolean;
  drawer: DrawerState;
  children: React.ReactNode;
}> = ({ appbar = true, drawer, children }) => {
  const [themeMode, setThemeMode] = useStatePersist<"light" | "dark">(
    "themeMode",
    "light"
  );

  const theme = useMemo(() => {
    return createTheme(puzsqThemeOptions(themeMode));
  }, [themeMode]);

  const { drawerOpen, handleDrawerOpen, handleDrawerClose } = drawer;

  const { user } = useFirebaseLogin();
  console.log(user);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>Puzsq CreatorHub</title>
      </Helmet>

      <Box sx={{ flexGrow: 1 }} onClick={handleDrawerClose}>
        {appbar && (
          <CommonAppBar
            open={drawerOpen}
            handleDrawerOpen={handleDrawerOpen}
            themeMode={themeMode}
            setThemeMode={setThemeMode}
            user={user}
          />
        )}
        {children}
        <CommonFooter />
      </Box>
    </ThemeProvider>
  );
};

export default Wrapper;
