import { Image } from "@react-pdf/renderer";
import { getImageUrl } from "./getImageUrl";

type ImageComponentsProps = {
  url: string;
  width: number;
  height: number;
};

export const ImageComponents = (props: ImageComponentsProps) => {
  return (
    <Image
      src={() => getImageUrl(props.url)}
      style={{ width: props.width, height: props.height }}
    />
  );
};
