export const longestShiritori = (words: string[]): string[] => {
  let longest: string[] = [];
  const memo = new Map<string, string[]>();

  // 単語の最初と最後の文字に基づいてマップを作成
  const wordMap = new Map<string, string[]>();
  for (const word of words) {
    const firstChar = word[0];
    if (!wordMap.has(firstChar)) {
      wordMap.set(firstChar, []);
    }
    wordMap.get(firstChar)!.push(word);
  }

  // DFSで最長のしりとりを見つける（メモ化あり）
  function dfs(path: string[], lastChar: string): string[] {
    const memoKey = path.join(",") + "|" + lastChar;
    if (memo.has(memoKey)) {
      return memo.get(memoKey)!;
    }

    let localLongest: string[] = [...path];

    const nextWords = wordMap.get(lastChar) || [];
    for (const nextWord of nextWords) {
      if (!path.includes(nextWord)) {
        const newPath = dfs([...path, nextWord], nextWord[nextWord.length - 1]);
        if (newPath.length > localLongest.length) {
          localLongest = newPath;
        }
      }
    }

    memo.set(memoKey, localLongest);
    if (localLongest.length > longest.length) {
      longest = localLongest;
    }
    return localLongest;
  }

  // すべての単語を開始点として探索
  for (const word of words) {
    dfs([word], word[word.length - 1]);
  }

  return longest;
};
