import { Text } from "@react-pdf/renderer";
import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { StackData, StackTitleData } from "../stackData";

export const Title: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "title"}>
      <Text style={{ fontSize: 20, fontWeight: "bold" }}>
        {(contents as StackTitleData).text}
      </Text>
    </If>
  );
};
