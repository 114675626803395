import { Container } from "@mui/system";
import { FC } from "react";
import { useParams } from "react-router";
import { useSiteConfig } from "../../../_lib/eniverse/EniverseProvider";
import {
  CommonMain,
  MainProps,
} from "../../../_lib/eniverse/imaginate/CommonMain";
import { EmptyMain } from "../../../_lib/eniverse/imaginate/EmptyMain";
import { ImaginateProvider } from "../../../_lib/eniverse/imaginate/ImaginateProvider";
import { InnerImaginate } from "../Studio/InnerImaginate/InnerImaginate";
import { IroiroMain } from "./Project/iroiro/IroiroMain";
import { getProjectPath } from "./Projects";
import { PanTokigamiMain } from "./Project/pan-tokigami/PanTokigamiMain";

const mainComponentTable: Record<string, FC<MainProps>> = {
  dev: CommonMain,
  "p-and-r": CommonMain,
  iroiro: IroiroMain,
  "pan-tokigami": PanTokigamiMain,
};

export const ProjectContainer: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [{ serviceId }] = useSiteConfig();

  if (!projectId) {
    return <Container>Project not found</Container>;
  }

  return (
    <ImaginateProvider initialImaginateConfig={{ projectId }}>
      <InnerImaginate
        initialPath={getProjectPath(serviceId, projectId)}
        mainComponent={mainComponentTable[projectId] || EmptyMain}
      />
    </ImaginateProvider>
  );
};
