import { FC } from "react";

type IfProps = {
  condition: any;
  children: React.ReactNode;
};

export const If: FC<IfProps> = ({ condition, children }) => {
  return <>{condition ? children : null}</>;
};
