import { Chip, Grid, Typography } from "@mui/material";
import { deleteObject, StorageReference } from "firebase/storage";
import { FC, useContext, useState } from "react";
import { GenericDialog } from "../../common/GenericDialog";
import { getFileChip } from "./getFileChip";
import { CloudItemProps, DocFile, Metadata } from "./cloudType";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";

export type CloudItemListProps = CloudItemProps & {
  storageTable: { [key: string]: StorageReference };
  onLoadSelect: (doc: DocFile & Metadata, item: StorageReference) => void;
};

export const CloudItemLoad: FC<CloudItemListProps> = (props) => {
  const { fileListData, setFileListData, storageTable, onLoadSelect } = props;

  const [deleteKey, setDeleteKey] = useState<string | null>(null);
  const { enabledUserDictionary } = useContext(EnigmaStudioContext);

  return (
    <Grid container>
      {storageTable && fileListData?.files && (
        <>
          <Grid item xs={12}>
            {Object.keys(fileListData.files).some(
              (e) =>
                fileListData.files[e].type === "text" &&
                !fileListData.files[e].separator &&
                !enabledUserDictionary.find(
                  (d) => d.key === fileListData.files[e].fileName
                )
            ) && (
              <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
                クラウド辞書:
              </Typography>
            )}
            {Object.keys(fileListData.files)
              .filter(
                (e) =>
                  fileListData.files[e].type === "text" &&
                  !fileListData.files[e].separator &&
                  !enabledUserDictionary.find(
                    (d) => d.key === fileListData.files[e].fileName
                  )
              )
              .map((key) => {
                const file = fileListData.files[key];
                if (!file) return null;
                const item = storageTable[file.fileName];
                const { color, variant } = getFileChip("unload");
                if (!item) {
                  return null;
                }
                return (
                  <Chip
                    key={key}
                    label={file.name}
                    onClick={() => {
                      onLoadSelect(file as DocFile & Metadata, item);
                    }}
                    onDelete={() => {
                      setDeleteKey(key);
                    }}
                    color={color}
                    variant={variant}
                    sx={{
                      mb: 1,
                      mr: 1,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
          </Grid>
          <Grid item xs={12}>
            {Object.keys(fileListData.files).some(
              (e) =>
                fileListData.files[e].type === "text" &&
                fileListData.files[e].separator &&
                !enabledUserDictionary.find(
                  (d) => d.key === fileListData.files[e].fileName
                )
            ) && (
              <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
                クラウド区切り付き辞書:
              </Typography>
            )}
            {Object.keys(fileListData.files)
              .filter(
                (e) =>
                  fileListData.files[e].type === "text" &&
                  fileListData.files[e].separator &&
                  !enabledUserDictionary.find(
                    (d) => d.key === fileListData.files[e].fileName
                  )
              )
              .map((key) => {
                const file = fileListData.files[key];
                if (!file) return null;
                if (file.type !== "text") return null;
                const item = storageTable[file.fileName];
                const { color, variant } = getFileChip("unload");
                if (!item) {
                  return null;
                }
                return (
                  <Chip
                    key={key}
                    label={file.name}
                    onClick={() => {
                      onLoadSelect(file as DocFile & Metadata, item);
                    }}
                    onDelete={() => {
                      setDeleteKey(key);
                    }}
                    color={color}
                    variant={variant}
                    sx={{
                      mb: 1,
                      mr: 1,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
          </Grid>

          <GenericDialog
            open={deleteKey !== null}
            title="削除確認"
            description={`『${
              fileListData.files[deleteKey ?? ""]?.name ?? ""
            }』をクラウドから削除しますか？`}
            agreeText="削除"
            disagreeText="キャンセル"
            onAgree={() => {
              if (!deleteKey) return;
              const file = fileListData.files[deleteKey];
              const deleteItem = storageTable[file.fileName];
              deleteObject(deleteItem).then(() => {
                if (setFileListData) {
                  const newFiles = { ...fileListData.files };
                  delete newFiles[deleteKey];
                  setFileListData({ files: newFiles });
                }
              });
              setDeleteKey(null);
            }}
            onDisagree={() => {
              setDeleteKey(null);
            }}
            onClose={() => {
              setDeleteKey(null);
            }}
          />
        </>
      )}
    </Grid>
  );
};
