export const getFileChip = (
  type: "unload" | "loaded" | "selected"
): {
  variant: "filled" | "outlined";
  color: "default" | "primary";
} => {
  const variant =
    type === "unload" ? "filled" : type === "loaded" ? "outlined" : "filled";

  const color =
    type === "unload" ? "default" : type === "loaded" ? "default" : "primary";
  return {
    variant,
    color,
  };
};
