import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import { FC } from "react";

export const IroiroTop: FC = () => {
  return (
    <Container>
      <Typography variant="h4">色々な運営管理ページ</Typography>
      <Typography variant="body1">
        わんどもちさたけが色々やるためのページ
      </Typography>
    </Container>
  );
};
