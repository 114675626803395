import { Button } from "@mui/material";
import { KonvaEventObject } from "konva/lib/Node";
import { FC, useCallback } from "react";
import { Rect } from "react-konva";
import { useStatePersist } from "use-state-persist";
import { ModeType } from "./Menu/ModeSelector";
import { Pos, RectPos } from "./gridToolType";

export const useArtboard = (col: number, row: number) => {
  const [artboard, setArtboard] = useStatePersist<RectPos>(
    "grid-tool-art-board",
    {
      x: 0,
      y: 0,
      width: col,
      height: row,
    }
  );
  return { artboard, setArtboard };
};

export const useArtboardHandler = ({
  artboard,
  setArtboard,
  dragging,
  setDragging,
  size,
  posToCoord,
  initialCursor,
  setInitialCursor,
}: {
  artboard: RectPos;
  setArtboard: React.Dispatch<React.SetStateAction<RectPos>>;
  dragging: boolean;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  size: number;
  posToCoord: (x: number, y: number) => Pos;
  initialCursor: Pos;
  setInitialCursor: React.Dispatch<React.SetStateAction<Pos>>;
}) => {
  /**
   * Artboard Handling
   * **/
  const handleArtboardDragStart = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      const coords = e.target.getRelativePointerPosition();
      setInitialCursor({ x: coords.x, y: coords.y });
      const newArtboard = {
        ...posToCoord(coords.x, coords.y),
        width: 1,
        height: 1,
      };
      setArtboard(newArtboard);
      setDragging(true);
    },
    [setArtboard]
  );

  const handleArtboardDragMove = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      if (!dragging) {
        return;
      }
      const coords = e.target.getRelativePointerPosition();

      // カーソル座標を基にdiffを計算
      const diffX = coords.x - initialCursor.x;
      const diffY = coords.y - initialCursor.y;

      // 新しい座標リストを生成
      const newArtboard = { ...artboard };
      newArtboard.width = Math.ceil(diffX / size + 0.0001);
      newArtboard.height = Math.ceil(diffY / size + 0.0001);
      setArtboard(newArtboard);
    },
    [initialCursor, dragging, artboard, setArtboard]
  );

  const handleArtboardDragEnd = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      setDragging(false);
      const newArtboard = { ...artboard };
      const node = e.target;
      setArtboard(newArtboard);
    },
    [artboard, setArtboard]
  );

  return {
    handleArtboardDragStart,
    handleArtboardDragMove,
    handleArtboardDragEnd,
  };
};

type ArtboardMenuProps = {
  mode: ModeType | null;
  setArtboard: React.Dispatch<React.SetStateAction<RectPos>>;
  col: number;
  row: number;
  whiteList: Pos[];
  blackList: Pos[];
};

export const ArtboardMenu: FC<ArtboardMenuProps> = ({
  mode,
  setArtboard,
  col,
  row,
  whiteList,
  blackList,
}) => {
  if (mode !== "artboard") {
    return <></>;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setArtboard({
            x: 0,
            y: 0,
            width: col,
            height: row,
          });
        }}
        sx={{ mr: 2 }}
      >
        ボードを初期化
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          const minX = Math.min(
            ...whiteList.map((e) => e.x),
            ...blackList.map((e) => e.x),
            col
          );
          const minY = Math.min(
            ...whiteList.map((e) => e.y),
            ...blackList.map((e) => e.y),
            row
          );
          const maxX = Math.max(
            ...whiteList.map((e) => e.x),
            ...blackList.map((e) => e.x),
            0
          );
          const maxY = Math.max(
            ...whiteList.map((e) => e.y),
            ...blackList.map((e) => e.y),
            0
          );
          if (minX >= maxX || minY >= maxY) {
            setArtboard({
              x: 0,
              y: 0,
              width: col,
              height: row,
            });
          } else {
            setArtboard({
              x: minX,
              y: minY,
              width: maxX - minX + 1,
              height: maxY - minY + 1,
            });
          }
        }}
        sx={{ mr: 2 }}
      >
        着色済みマスで初期化
      </Button>
    </>
  );
};

type ArtboardRangeProps = {
  mode: ModeType | null;
  maxWidth: number;
  maxHeight: number;
  scale: number;
  handleArtboardDragStart: (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => void;
  handleArtboardDragMove: (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => void;
  handleArtboardDragEnd: (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => void;
};

export const ArtboardRange: FC<ArtboardRangeProps> = ({
  mode,
  maxWidth,
  maxHeight,
  scale,
  handleArtboardDragStart,
  handleArtboardDragMove,
  handleArtboardDragEnd,
}) => {
  if (mode !== "artboard") {
    return <></>;
  }

  return (
    <Rect
      x={0}
      y={0}
      width={maxWidth / scale}
      height={maxHeight / scale}
      onMouseDown={handleArtboardDragStart}
      onMouseMove={handleArtboardDragMove}
      onMouseUp={handleArtboardDragEnd}
      onMouseLeave={handleArtboardDragEnd}
      onMouseOut={handleArtboardDragEnd}
      onTouchStart={handleArtboardDragStart}
      onTouchMove={handleArtboardDragMove}
      onTouchEnd={handleArtboardDragEnd}
    />
  );
};
