import { SprintAnswerEvent, Question } from "../events";

export const abcaddition: SprintAnswerEvent = {
  id: "abcaddition",
  title: "アルファベットの足し引き",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「A + B」→「C」のように
      <br />
      アルファベットの足し算と引き算の計算を行います。
    </>
  ),
  descriptions: [
    <p key={0}>
      「A + B」→「C」「W - J」→「M」のように
      <br />
      アルファベットの足し算と引き算の計算を行います。
      <br />
      例えば「A + B」→「C」の場合、
      <br />
      A=1, B=2, C=3として計算します。
      <br />
      全部で10問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () => {
    const questions: Question[] = [];

    const gen = (): Question => {
      const a = Math.floor(Math.random() * 26) + 1;
      const b = Math.floor(Math.random() * 26) + 1;
      const sign = Math.random() > 0.5 ? "+" : "-";
      const answer = sign === "+" ? a + b : a - b;
      if (answer < 1 || answer > 26) return gen();

      const answers = [
        ...new Set(
          [" ABCDEFGHIJKLMNOPQRSTUVWXYZ", " abcdefghijklmnopqrstuvwxyz"].map(
            (e) => {
              return e[answer];
            }
          )
        ),
      ];
      const question = {
        problemText: `${String.fromCharCode(
          a + 64
        )} ${sign} ${String.fromCharCode(b + 64)}`,
        answers,
      };
      return question;
    };

    for (let i = 0; i < 10; i++) {
      questions.push(gen());
    }
    return questions;
  },
  shareButton: true,
  answerButton: true,
  hashTag: "アルファベットの足し引き",
};
