import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./CustomNode.default.module.css";
import { CustomData } from "./types";
import { TypeIcon } from "../TypeIcon";

type Props = {
  node: NodeModel<CustomData>;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  onMenu: (e: React.MouseEvent, id: NodeModel["id"]) => void;
  onAdd: (id: NodeModel["id"]) => void;
  onCopy: (id: NodeModel["id"]) => void;
  onSelect: (node: NodeModel) => void;
};

export const CustomNode: React.FC<Props> = (props) => {
  const { droppable, data } = props.node;
  const indent = props.depth * 24;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };
  const handleSelect = () => props.onSelect(props.node);
  const dragOverProps = useDragOver(
    props.node.id,
    props.isOpen,
    props.onToggle
  );
  const { id } = props.node;

  const [hover, setHover] = useState(false);

  return (
    <div
      className={`tree-node ${styles.root}  ${
        props.isSelected ? styles.isSelected : ""
      }`}
      style={{ paddingInlineStart: indent }}
      {...dragOverProps}
      onClick={handleSelect}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ""
        }`}
      >
        {props.node.droppable && (
          <div onClick={handleToggle}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      <div>
        <TypeIcon droppable={droppable || false} fileType={data?.fileType} />
      </div>
      <div className={styles.labelGridItem}>
        <Typography variant="body2">{`${props.node.text.substring(
          0,
          hover ? 18 : 20
        )}`}</Typography>
      </div>
      {hover && (
        <>
          <div className={styles.actionButton}>
            <IconButton
              size="small"
              onClick={(e) => {
                props.onMenu(e, id);
                return false;
              }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
            {props.node.droppable ? (
              <IconButton
                size="small"
                onClick={() => {
                  props.onAdd(id);
                  return false;
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={() => {
                  props.onCopy(id);
                  return false;
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};
