import { CLASSES, ALL } from "./regex_common";

class CustomArray<T> extends Array<T> {
  n?: number;

  constructor(n?: number, ...elements: T[]) {
    super(...elements);
    this.n = n;
  }
}

function processGrouping(pattern: string[]): any[] {
  let tree: any[] = [];
  let stack: any[][] = [tree];
  let n = 1;
  while (pattern.length) {
    let chr = pattern.shift();
    if (chr === "\\") {
      let next = pattern.shift();
      if (next === "(" || next === ")") {
        stack[0].push(next);
      } else {
        stack[0].push(chr, next);
      }
    } else if (chr === "(") {
      let inner = new CustomArray<any>();
      stack[0].push(inner);
      stack.unshift(inner);

      let next = pattern.shift(); // no warnings
      if (next === "?") {
        next = pattern.shift();
        if (next === ":") {
          // just create a group
        } else {
          throw new Error("Invalid group");
        }
      } else if (next === "(" || next === ")") {
        pattern.unshift(next);
      } else {
        inner.n = n++;
        inner.push(next);
      }
    } else if (chr === ")") {
      stack.shift();
    } else {
      stack[0].push(chr);
    }
  }

  if (stack.length > 1) throw new Error("missmatch paren");

  return tree;
}

function processSelect(tree: any[]): any[] {
  let candidates: any[][] = [[]];

  while (tree.length) {
    let chr = tree.shift();
    if (chr === "\\") {
      let next = tree.shift();
      if (next === "|") {
        candidates[0].push(next);
      } else {
        candidates[0].push(chr, next);
      }
    } else if (chr === "[") {
      candidates[0].push(chr);
      while (tree.length) {
        chr = tree.shift();
        candidates[0].push(chr);
        if (chr === "\\") {
          let next = tree.shift(); // no warnings
          candidates[0].push(next);
        } else if (chr === "]") {
          break;
        }
      }
    } else if (chr === "|") {
      candidates.unshift([]);
    } else {
      candidates[0].push(chr);
    }
  }

  for (let i = 0, it; (it = candidates[i]); i++) {
    tree.push(it);
    for (let j = 0, len = it.length; j < len; j++) {
      if (it[j] instanceof Array) {
        processSelect(it[j]);
      }
    }
  }

  // 入れ子、 奇数段が pattern 偶数段が candidates,
  return [tree];
}

const REFERENCE: { [key: string]: string } = {};
// 必要な型を定義
type Character = string;
type Candidate = Character | CustomArray<Character>;
type Reference = { [key: string]: string };
type ClassSet = { [key: string]: Character[] };

// processOthers関数の変換
function processOthers(
  tree: CustomArray<Candidate>,
  REFERENCE: Reference,
  CLASSES: ClassSet,
  ALL: Character[]
): string {
  let ret = "";
  let candidates: CustomArray<Candidate> = [];
  tree = tree.slice(0);

  function choice(): string {
    const randomIndex = Math.floor(candidates.length * Math.random());
    let ret: Candidate = candidates[randomIndex];

    console.log(ret);
    if (Array.isArray(ret)) {
      ret = processOthers(ret, REFERENCE, CLASSES, ALL);
    }

    if (typeof ret === "string" && candidates.n) {
      REFERENCE["" + candidates.n] = ret;
    }

    return ret || "";
  }

  while (tree.length) {
    let chr = tree.shift() as Character;
    switch (chr) {
      case "^":
      case "$":
        // do nothing
        break;
      case "*":
        for (let i = 0, len = Math.random() * 10; i < len; i++) {
          ret += choice();
        }
        candidates = [];
        break;
      case "+":
        for (let i = 0, len = Math.random() * 10 + 1; i < len; i++) {
          ret += choice();
        }
        candidates = [];
        break;
      case "{":
        let brace = "";
        while (tree.length) {
          chr = tree.shift() as Character;
          if (chr === "}") {
            break;
          } else {
            brace += chr;
          }
        }

        if (chr !== "}") throw new Error("Mismatched brace: " + chr);

        const dd = brace.split(/,/);
        const min = +dd[0];
        const max = dd.length === 1 ? min : +dd[1] || 10;
        for (
          let i = 0, len = Math.floor(Math.random() * (max - min + 1)) + min;
          i < len;
          i++
        ) {
          ret += choice();
        }
        candidates = [];
        break;
      case "?":
        if (Math.random() < 0.5) {
          ret += choice();
        }
        candidates = [];
        break;
      case "\\":
        ret += choice();
        const escaped = tree.shift() as Character;

        if (escaped.match(/^[1-9]$/)) {
          candidates = [REFERENCE[escaped] || ""];
        } else {
          if (escaped === "b" || escaped === "B") {
            throw new Error("\\b and \\B are not supported");
          }
          candidates = CLASSES[escaped] || [];
        }

        if (!candidates) candidates = [escaped];
        break;
      case "[":
        ret += choice();

        let sets: Character[] = [],
          negative = false;
        while (tree.length) {
          chr = tree.shift() as Character;
          if (chr === "\\") {
            const next = tree.shift() as Character;
            sets = sets.concat(CLASSES[next] || [next]);
          } else if (chr === "]") {
            break;
          } else if (chr === "^") {
            if (sets.length === 0) {
              negative = true;
            } else {
              sets.push(chr);
            }
          } else if (chr === "-") {
            const next = tree.shift() as Character;
            const before = sets[sets.length - 1];
            if (!before) {
              sets.push(chr);
            } else {
              for (
                let i = before.charCodeAt(0) + 1, len = next.charCodeAt(0);
                i < len;
                i++
              ) {
                sets.push(String.fromCharCode(i));
              }
            }
          } else {
            sets.push(chr);
          }
        }

        if (chr !== "]") throw new Error("Mismatched bracket: " + chr);

        if (negative) {
          const neg: { [key: string]: boolean } = {};
          sets.forEach((s) => (neg[s] = true));

          candidates = ALL.filter((c) => !neg[c]);
        } else {
          candidates = sets;
        }
        break;
      case ".":
        ret += choice();
        candidates = ALL;
        break;
      default:
        ret += choice();
        candidates = [chr];
    }
  }
  return ret + choice();
}

export const regexEnumerate = (pattern: string | RegExp): string => {
  if (pattern instanceof RegExp) pattern = pattern.source;

  let tree: any[];
  tree = processGrouping(pattern.split(""));
  console.log(tree, REFERENCE);
  tree = processSelect(tree);
  console.log(tree);
  return processOthers(tree, REFERENCE, CLASSES, ALL);
};
