import { listTerminateCharacter, listPlaceholderCharacter } from "../../../param/generalSearchQuery";
export const rotateRegex = /rotate\(\s*([^\s,()]*?)\s*,\s*([^\s,()]+)\s*,\s*([0-9-~&]+)\s*(?:,\s*([^()]*)\s*)?\s*\)/g;
// 1つのリストで文字を前後させる変換関数
export const rotateConvert = (input, listDefinitionsTable, _trace) => {
    const regex = rotateRegex;
    const replaced = input.replace(regex, (match, stringPart, listName, _shift, option) => {
        const listContent = listName
            .split("&")
            .map((listName) => listDefinitionsTable[listName]?.content ?? listName)
            .join(listTerminateCharacter);
        const contents = listContent.split(listTerminateCharacter);
        option = option ?? "";
        const loop = option.includes("l");
        const shifts = [];
        _shift.split("&").forEach((__shift) => {
            if (__shift.includes("~")) {
                // a~b -> a to b
                const [a, b] = __shift.split("~").map((e) => parseInt(e));
                for (let i = a; i <= b; i++) {
                    shifts.push(i);
                }
            }
            else {
                shifts.push(parseInt(__shift));
            }
        });
        if (!listContent) {
            return "";
        }
        const stringArr = stringPart.split("");
        const ret = shifts
            .map((shift) => {
            return stringArr
                .map((c) => {
                const nexts = [];
                contents.forEach((content) => {
                    let searchIndex = content.indexOf(c);
                    while (searchIndex !== -1) {
                        const next = loop
                            ? (searchIndex + shift) % content.length
                            : searchIndex + shift;
                        if (next >= 0 &&
                            next < content.length &&
                            content[next] !== listPlaceholderCharacter) {
                            nexts.push(content[next]);
                        }
                        searchIndex = content.indexOf(c, searchIndex + 1);
                    }
                });
                return "[" + nexts.join("") + "]";
            })
                .join("");
        })
            .join("\n");
        return ret;
    });
    return replaced.split("\n");
};
