export const answerKitDataTable = {
  default: {
    label: "利用するアイテムを選択してください",
    form: [
      [
        { formType: "category-label", label: "テキスト" },
        {
          formType: "button",
          setType: "text",
          label: "テキスト",
        },
        {
          formType: "button",
          setType: "markdown",
          label: "マークダウン",
        },
      ],
      [
        { formType: "category-label", label: "画像" },
        {
          formType: "button",
          setType: "image",
          label: "画像",
        },
      ],
      [
        { formType: "category-label", label: "その他" },
        {
          formType: "button",
          setType: "hint",
          label: "ヒント",
        },
        {
          formType: "button",
          setType: "tweet",
          label: "ツイート",
        },
        {
          formType: "button",
          setType: "movie",
          label: "動画",
        },
      ],
    ],
    summaryView: [],
  },
  text: {
    label: "テキスト",
    form: [[{ formType: "text", setField: "message", label: "テキスト" }]],
    summary: [[{ formType: "text", setField: "message", label: "テキスト" }]],
  },
  image: {
    label: "画像",
    form: [[{ formType: "text", setField: "image", label: "画像" }]],
    summary: [[{ formType: "image", setField: "image", label: "画像" }]],
  },
  hint: {
    label: "ヒント",
    form: [
      [
        {
          formType: "text",
          setField: "buttonMessage",
          label: "ボタンメッセージ",
        },
      ],
      [{ formType: "text", setField: "hintId", label: "ヒントID" }],
      [{ formType: "tab", setField: "list", label: "リスト" }],
    ],
    summary: [
      [
        {
          formType: "text",
          setField: "buttonMessage",
          label: "ボタンメッセージ",
        },
      ],
      [{ formType: "text", setField: "hintId", label: "ヒントID" }],
      [{ formType: "tab", setField: "list", label: "リスト" }],
    ],
  },
  markdown: {
    label: "マークダウン",
    form: [[{ formType: "text", setField: "body", label: "本文" }]],
    summary: [[{ formType: "text", setField: "body", label: "本文" }]],
  },
  tweet: {
    label: "ツイート",
    form: [[{ formType: "text", setField: "tweetId", label: "ツイートID" }]],
    summary: [[{ formType: "text", setField: "tweetId", label: "ツイートID" }]],
  },
  movie: {
    label: "動画",
    form: [[{ formType: "text", setField: "src", label: "動画URL" }]],
    summary: [[{ formType: "movie", setField: "src", label: "動画URL" }]],
  },
};
