import { SnackbarProvider } from "notistack";
import { useStatePersist } from "use-state-persist";
import { eniverseFirebaseConfig } from ".";
import "./App.css";
import { EniverseProvider } from "./_lib/eniverse/EniverseProvider";
import { EnigmaStudioRouter } from "./components/EnigmaHouse/router/EnigmaStudioRouter";
import { studioThemeOptions } from "./components/EnigmaHouse/common/Theme";
import { EnigmaStudioContextProvider } from "./components/EnigmaHouse/context/EnigmaStudioContext";
import { LogicPuzzleAppAuth } from "./components/LogicPuzzleApp/auth/LogicPuzzleAppAuth";
import { siteConfig as puzsqSiteConfig } from "./components/LogicPuzzleApp/siteConfig/siteConfig";
import { PuzsqStudioRouter } from "./components/LogicPuzzleApp/router/PuzsqStudioRouter";

export const proKey = "pro12kln";
export const proPlusKey = "proplus12fwe";
export const debugKey = "debug12lzl";

function App() {
  const [themeMode, setThemeMode] = useStatePersist<"light" | "dark">(
    "themeMode",
    "light"
  );

  const siteConfigs: { [key: string]: any } = {
    enigmahouse: {
      serviceId: "enigmahouse",
      siteName: "EnigmaStudio",
      hashedPassword: "",
      themeOptions: studioThemeOptions(themeMode),
    },
    puzsq: puzsqSiteConfig(themeMode),
  };

  return (
    <EniverseProvider
      initialSiteConfig={
        siteConfigs[process.env.REACT_APP_SERVICE_LABEL_NAME ?? "enigmahouse"]
      }
      initialFirebaseConfig={eniverseFirebaseConfig}
    >
      {process.env.REACT_APP_SERVICE_LABEL_NAME === "enigmahouse" && (
        <EnigmaStudioContextProvider>
          <SnackbarProvider
            maxSnack={10}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <EnigmaStudioRouter />
          </SnackbarProvider>
        </EnigmaStudioContextProvider>
      )}
      {process.env.REACT_APP_SERVICE_LABEL_NAME === "puzsq" && (
        <LogicPuzzleAppAuth initialFirebaseConfig={eniverseFirebaseConfig}>
          <PuzsqStudioRouter />
        </LogicPuzzleAppAuth>
      )}
    </EniverseProvider>
  );
}

export default App;
