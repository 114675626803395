import { Combination, Permutation } from "js-combinatorics";
import Mexp from "math-expression-evaluator";
import { getLastString } from "../../wordGenerator/getLastWord";
import { expandBracketedString } from "../../wordGenerator/bracketedString";
import { kanaToRoman } from "../../wordGenerator/kanaToRoman";
import { replaceSign } from "../../wordGenerator/replaceSign";
import { romajiToHira } from "../../wordGenerator/romajiToHira";
import { textLength } from "../../wordGenerator/textLength";
import { pairConvert, pairRegex } from "./patternFunction/pairConvert";
import { rotateConvert, rotateRegex } from "./patternFunction/rotateConvert";
const reverseConvert = (input, _lists, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.reverse.regex;
    const replaced = input.replace(regex, (_, stringPart) => {
        return stringPart.split("").reverse().join("");
    });
    return replaced;
};
const sortConvert = (input, _, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.sort.regex;
    const replaced = input.replace(regex, (_, stringPart) => {
        return stringPart.split("").sort().join("");
    });
    return replaced;
};
const romaConvert = (input, _, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.roma.regex;
    const replaced = input.replace(regex, (_, stringPart, option) => {
        option = option ?? "";
        const longSound = option.includes("l");
        const kunrei = option.includes("k");
        const typing = option.includes("t");
        const type = kunrei
            ? typing
                ? "kunreiTyping"
                : "kunrei"
            : typing
                ? "hepburnTyping"
                : "hepburn";
        return kanaToRoman(stringPart, type, {
            longSound,
            bmp: true,
        });
    });
    return replaced;
};
const kanaConvert = (input, _lists, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.kana.regex;
    const replaced = input.replace(regex, (_, stringPart) => {
        return romajiToHira(stringPart);
    });
    return replaced;
};
const calculateConvert = (input, _lists, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.calc.regex;
    const mexp = new Mexp();
    try {
        const replaced = input.replace(regex, (_, stringPart) => {
            const replacedLine = replaceSign(stringPart);
            return "" + mexp.eval(replacedLine, [], {});
        });
        return replaced;
    }
    catch (e) {
        return "";
    }
};
const listContentConvert = (input, lists, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.listContent.regex;
    const replaced = input.replace(regex, (_, stringPart) => {
        if (!stringPart || !lists || !lists[stringPart]) {
            return "";
        }
        const listContent = stringPart
            .split("&")
            .map((listName) => lists[listName]?.content.replace(/;#/g, "") ?? "")
            .join("");
        return listContent;
    });
    return replaced;
};
const traceConvert = (input, _, trace, functions = replacedPatternFunctions) => {
    const regex = functions.trace.regex;
    const replaced = input.replace(regex, (_, stringPart) => {
        const index = parseInt(stringPart);
        return index > 0
            ? getLastString(trace[index - 1] ?? "")
            : getLastString(trace[trace.length + index - 1] ?? "");
    });
    return replaced;
};
const subStrConvert = (input, _, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.substr.regex;
    const replaced = input.replace(regex, (_, stringPart, option) => {
        const [_min, _max] = option.includes("~")
            ? option.split("~").map((e) => parseInt(e))
            : [parseInt(option), parseInt(option)];
        const [min, max] = [
            _min <= 0 ? textLength(stringPart) + _min : _min,
            _max <= 0 ? textLength(stringPart) + _max : _max,
        ].sort((a, b) => a - b);
        const result = [];
        for (let i = min; i <= max; i++) {
            for (let j = 0; j <= stringPart.length - i; j++) {
                result.push(stringPart.substring(j, j + i));
            }
        }
        if (result.length === 1) {
            return result[0];
        }
        return `[${result.join("|")}]`;
    });
    return replaced;
};
const subSeqConvert = (input, _, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.subseq.regex;
    const replaced = input.replace(regex, (_, stringPart, option) => {
        const [_min, _max] = option.includes("~")
            ? option.split("~").map((e) => parseInt(e))
            : [parseInt(option), parseInt(option)];
        const [min, max] = [
            _min <= 0 ? textLength(stringPart) + _min : _min,
            _max <= 0 ? textLength(stringPart) + _max : _max,
        ].sort((a, b) => a - b);
        let result = [];
        for (let i = min; i <= max; i++) {
            const comb = [...new Combination(stringPart, i)].map((e) => e.join(""));
            result = result.concat(comb);
        }
        return `[${result.join("|")}]`;
    });
    return replaced;
};
const permutationConvert = (input, _, _trace, functions = replacedPatternFunctions) => {
    const regex = functions.perm.regex;
    const replaced = input.replace(regex, (_, stringPart, option) => {
        const textLength = (s) => s.length;
        const [_min, _max] = !option || option === ""
            ? [textLength(stringPart), textLength(stringPart)]
            : option.includes("~")
                ? option.split("~").map((e) => parseInt(e))
                : [parseInt(option), parseInt(option)];
        const [min, max] = [
            _min <= 0 ? textLength(stringPart) + _min : _min,
            _max <= 0 ? textLength(stringPart) + _max : _max,
        ].sort((a, b) => a - b);
        let result = [];
        for (let i = min; i <= max; i++) {
            const comb = [...new Permutation(stringPart, i)].map((e) => e.join(""));
            result = result.concat(comb);
        }
        return `[${result.join("|")}]`;
    });
    return replaced;
};
export const replacedPatternFunctions = {
    listContent: {
        regex: /<([^\(\)\<\>\[\]]+)>/g,
        func: listContentConvert,
    },
    rotate: {
        regex: rotateRegex,
        func: rotateConvert,
    },
    pair: {
        regex: pairRegex,
        func: pairConvert,
    },
    reverse: { regex: /reverse\(\s*([^()]*?)\s*\)/g, func: reverseConvert },
    sort: { regex: /sort\(\s*([^()]*?)\s*\)/g, func: sortConvert },
    kana: { regex: /kana\(\s*([^()]*?)\s*\)/g, func: kanaConvert },
    roma: {
        regex: /roma\(\s*([^()]*?)\s*(?:,\s*([^()]*)\s*)?\)/g,
        func: romaConvert,
    },
    trace: { regex: /trace\(\s*([^()]*?)\s*\)/g, func: traceConvert },
    substr: {
        regex: /substr\(\s*([^()]*?)\s*(?:,\s*([^()]*)\s*)\)/g,
        func: subStrConvert,
    },
    subseq: {
        regex: /subseq\(\s*([^()]*?)\s*(?:,\s*([^()]*)\s*)\)/g,
        func: subSeqConvert,
    },
    perm: {
        regex: /perm\(\s*([^()]*?)\s*(?:,\s*([^()]*)\s*)?\)/g,
        func: permutationConvert,
    },
    calc: {
        regex: /@{([^{}]*?)}/g,
        func: calculateConvert,
    },
};
const searchPatternFunctions = {
    listContent: {
        regex: /@<([^()>]+)>/g,
        func: listContentConvert,
    },
    trace: { regex: /@trace\(\s*([^()]*?)\s*\)/g, func: traceConvert },
};
const processInnermostFunction = (str, listDefinitionsTable, trace, functions = replacedPatternFunctions) => {
    for (const { regex, func } of Object.values(functions)) {
        const match = str.match(regex);
        if (match) {
            const [fullMatch, ...args] = match;
            const replaced = func(fullMatch, listDefinitionsTable, trace, functions);
            if (Array.isArray(replaced)) {
                // replacedがstring[]の場合、各文字列でfullMatchを置き換える
                const replacedStrings = replaced.map((r) => str.replace(fullMatch, r));
                return [replacedStrings, true];
            }
            else {
                // replacedがstringの場合
                return [[str.replace(fullMatch, replaced)], true];
            }
        }
    }
    return [[str], false];
};
export const processNestedFunctions = (str, listDefinitionsTable, trace) => {
    let modified = false;
    let strs = [str];
    do {
        const ret = strs.map((str) => processInnermostFunction(str, listDefinitionsTable, trace ?? [], replacedPatternFunctions));
        if (ret.length === 0) {
            return [];
        }
        modified = ret.map((e) => e[1]).reduce((a, b) => a || b);
        strs = ret.map((e) => e[0]).flat();
        strs = strs.map((st) => expandBracketedString(st)).flat();
    } while (modified);
    return strs;
};
export const processSearchPatternFunctions = (str, listDefinitionsTable) => {
    let modified = false;
    let strs = [str];
    do {
        const ret = strs.map((str) => processInnermostFunction(str, listDefinitionsTable, [], searchPatternFunctions));
        if (ret.length === 0) {
            return [];
        }
        modified = ret.map((e) => e[1]).reduce((a, b) => a || b);
        strs = ret.map((e) => e[0]).flat();
    } while (modified);
    return strs;
};
