import { Button } from "@mui/material";
import { FC } from "react";
import { ModeType } from "../Menu/ModeSelector";
import { Pos, RectPos, WhiteLineCandidate } from "../gridToolType";
import { Rect } from "konva/lib/shapes/Rect";
import React from "react";

type WhiteSquareMenuProps = {
  mode: ModeType | null;
  artboard: RectPos;
  whiteList: Pos[];
  blackList: Pos[];
  setWhiteList: React.Dispatch<React.SetStateAction<Pos[]>>;
  setWhiteLineCandidateList: React.Dispatch<
    React.SetStateAction<WhiteLineCandidate[][]>
  >;
  setWordCandidateList: React.Dispatch<React.SetStateAction<number[][]>>;
};

export const WhiteSquareMenu: FC<WhiteSquareMenuProps> = ({
  mode,
  artboard,
  whiteList,
  blackList,
  setWhiteList,
  setWhiteLineCandidateList,
  setWordCandidateList,
}) => {
  if (mode !== "white-square") {
    return <></>;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setWhiteList([]);
          setWhiteLineCandidateList([]);
          setWordCandidateList([]);
        }}
        sx={{ mr: 2 }}
      >
        白マスを初期化
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          const _whiteList = [...whiteList];
          for (let i = artboard.x; i < artboard.x + artboard.width; i++) {
            for (let j = artboard.y; j < artboard.y + artboard.height; j++) {
              if (
                !blackList.some((b) => b.x === i && b.y === j) &&
                !whiteList.some((w) => w.x === i && w.y === j)
              ) {
                _whiteList.push({ x: i, y: j });
              }
            }
          }
          setWhiteList(_whiteList);
        }}
      >
        盤面の黒マス以外を白マスに
      </Button>
    </>
  );
};
