import DescriptionIcon from "@mui/icons-material/Description";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  FormControlLabel,
  Switch,
  ToggleButton,
  Tooltip,
} from "@mui/material";
import React, { FC } from "react";
import { useStatePersist } from "use-state-persist";
import { HelpLink } from "../HelpLink";

type Props = {
  listDefinition: React.ReactNode;
};

export const GeneralSearchOption: FC<Props> = ({ listDefinition }) => {
  const [displayHelpLink, setDisplayHelpLink] = useStatePersist(
    "general-search-display-help-link",
    true
  );
  const [displayList, setDisplayList] = useStatePersist(
    "general-search-display-list",
    false
  );
  const [displayTraceNumber, setDisplayTraceNumber] = useStatePersist(
    "enigma-studio-function-search-display-trace-number",
    false
  );

  const [displayTooltip, setDisplayTooltip] = useStatePersist(
    "general-search-display-tooltip",
    true
  );
  const [displaySettings, setDisplaySettings] = useStatePersist(
    "general-search-display-settings",
    false
  );
  const [replaceFullsizeSymbolToHalfsize, setReplaceFullsizeSymbolToHalfSize] =
    useStatePersist(
      "general-search-replace-fullsize-symbol-to-halfsize",
      false
    );

  return (
    <Box mt={2} mb={3}>
      <Tooltip title={displayTooltip && "リスト定義"} arrow>
        <ToggleButton
          value="list"
          selected={displayList}
          size="small"
          color="primary"
          onChange={() => {
            setDisplayList(!displayList);
          }}
          sx={{ mr: 1 }}
        >
          <FormatListBulletedIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={displayTooltip && "設定オプション"} arrow>
        <ToggleButton
          value="list"
          selected={displaySettings}
          size="small"
          color="primary"
          onChange={() => {
            setDisplaySettings(!displaySettings);
          }}
          sx={{ mr: 1 }}
        >
          <SettingsIcon />
        </ToggleButton>
      </Tooltip>

      <Tooltip title={displayTooltip && "ヘルプ(リンク集を表示)"} arrow>
        <ToggleButton
          value="help"
          selected={displayHelpLink}
          size="small"
          color="primary"
          onChange={() => {
            setDisplayHelpLink(!displayHelpLink);
          }}
          sx={{ mr: 1 }}
        >
          <DescriptionIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={"説明表示ON/OFF"} arrow>
        <ToggleButton
          value="list"
          selected={displayTooltip}
          size="small"
          color="primary"
          onChange={() => {
            setDisplayTooltip(!displayTooltip);
          }}
          sx={{ mr: 1 }}
        >
          <LiveHelpIcon />
        </ToggleButton>
      </Tooltip>
      {displayList && listDefinition}
      {displayHelpLink && <HelpLink />}
      {displaySettings && (
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={displayTraceNumber}
                onChange={(e) => setDisplayTraceNumber(e.target.checked)}
              />
            }
            sx={{ ml: 0, mt: 1 }}
            label="トレース番号表示"
          />
          <FormControlLabel
            control={
              <Switch
                checked={replaceFullsizeSymbolToHalfsize}
                onChange={(e) =>
                  setReplaceFullsizeSymbolToHalfSize(e.target.checked)
                }
              />
            }
            sx={{ ml: 0, mt: 1 }}
            label="正規表現で全角記号を半角に自動変換"
          />
        </Box>
      )}
    </Box>
  );
};
