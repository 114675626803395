import { Grid, Button } from "@mui/material";
import { FC } from "react";
import { ConvertProps } from "./ConvertTemplate";

export const RegexConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  return (
    <>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`^${pattern}$`);
          }}
        >
          ^XXX$
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`${pattern.replace(/^\((.*)\)$/, "$1")}`);
          }}
        >
          (XXX)→XXX
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`(${pattern.split("\n").join("|")})`);
          }}
        >
          (XXX|YYY|ZZZ|...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`([${pattern.split("\n").join("][")}])`);
          }}
        >
          ([XXX][YYY][ZZZ]...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`((${pattern.split("\n").join(").*(")}))`);
          }}
        >
          ((XXX).*(YYY).*(ZZZ)...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`([${pattern.split("\n").join("].*[")}])`);
          }}
        >
          ([XXX].*[YYY].*[ZZZ]...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`((${pattern.split("\n").join(")?(")})?)`);
          }}
        >
          ((XXX)?(YYY)?(ZZZ)?...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`([${pattern.split("\n").join("]?[")}]?)`);
          }}
        >
          ([XXX]?[YYY]?[ZZZ]?...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`((${pattern.split("\n").join(")?.*(")})?)`);
          }}
        >
          ((XXX)?.*(YYY)?.*(ZZZ)?...)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(`([${pattern.split("\n").join("]?.*[")}]?)`);
          }}
        >
          ([XXX]?.*[YYY]?.*[ZZZ]?...)
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              `${pattern
                .split("\n")
                .map((e) => `(?=.*${e})`)
                .join("")}`
            );
          }}
        >
          (?=.*XXX)(?=.*YYY)(?=.*ZZZ)
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              `${pattern
                .split("\n")
                .map((e) => `(?=.*[${e}])`)
                .join("")}`
            );
          }}
        >
          (?=.*[XXX])(?=.*[YYY])(?=.*[ZZZ])
        </Button>
      </Grid>
    </>
  );
};
