import { useEffect, useState } from "react";
import { useStatePersist } from "use-state-persist";
import { WordObj } from "./gridToolType";

export const useHistory = ({
  dragging,
  rawWordList,
  wordList,
}: {
  dragging: boolean;
  rawWordList: string;
  wordList: WordObj[];
}) => {
  const [history, setHistory] = useStatePersist<
    { raw: string; word: WordObj[] }[]
  >("grid-tool-history", []);
  const [historyIndex, setHistoryIndex] = useStatePersist<number>(
    "grid-tool-history-index",
    -1
  );
  const [isUndoRedoAction, setIsUndoRedoAction] = useState<boolean>(false); // Undo or Redo 操作かどうか

  // rawWordList または wordList が変更されたら、履歴に追加
  useEffect(() => {
    if (dragging) {
      return;
    }
    if (isUndoRedoAction) {
      setIsUndoRedoAction(false);
      return;
    }
    if (historyIndex < history.length - 1) {
      const newHistory = history.slice(0, historyIndex + 1);
      setHistory(newHistory);
    }
    setHistory((prev) => [
      ...prev,
      { raw: rawWordList, word: structuredClone(wordList) },
    ]);
    setHistoryIndex((prev) => prev + 1);
  }, [rawWordList, wordList]);

  return {
    history,
    setHistory,
    historyIndex,
    setHistoryIndex,
    isUndoRedoAction,
    setIsUndoRedoAction,
  };
};
