import { processNestedFunctions } from "../patternFunctions";
export const traceGrouping = async ({ query, listDefinitionsTable, result, input, }) => {
    const table = {};
    for (const content of result) {
        const keys = processNestedFunctions(query.groupKey, listDefinitionsTable, content.trace);
        const values = processNestedFunctions(query.groupValue, listDefinitionsTable, content.trace);
        keys.forEach((key) => {
            table[key] = table[key] ?? {
                trace: [],
                currentWords: new Set(),
            };
            content.trace.forEach((e, i) => {
                const set = (table[key].trace[i] = table[key].trace[i] ?? new Set());
                set.add(e);
            });
            values.forEach((value) => {
                table[key].currentWords.add({
                    word: value,
                    estimation: content.currentEstimation,
                });
            });
        });
    }
    return Object.values(table).map((e) => {
        const currentWord = Array.from(e.currentWords)
            .map((e) => e.word)
            .join("/");
        const maxEstimation = Math.max(...Array.from(e.currentWords).map((e) => e.estimation ?? 1));
        const newe = {
            trace: Array.from(e.trace).map((e2, i2) => (query.enableTrace ?? []).includes(i2)
                ? Array.from(e2).join("/")
                : Array.from(e2)[0]),
            currentWord,
        };
        if (input.estimation) {
            newe.currentEstimation = maxEstimation;
        }
        newe.trace.push(currentWord);
        return newe;
    });
};
