import { Menu as MenuIcon } from "@mui/icons-material";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { User, signOut } from "firebase/auth";
import { FC, useState } from "react";
import { MaterialUISwitch } from "./MaterialUISwitch";
import { drawerWidth } from "./Wrapper";
import { useFirebaseConfig } from "../../../_lib/eniverse/EniverseProvider";
import { useCommonRouter } from "../../../_lib/router/cra/useCommonRouter";
import { logout } from "../auth/sso/logout";

type CommonAppBarProps = {
  open: boolean;
  handleDrawerOpen: () => void;
  themeMode: "light" | "dark";
  setThemeMode: (themeMode: "light" | "dark") => void;
  user?: User | null;
};

export const CommonAppBar: FC<CommonAppBarProps> = ({
  open,
  handleDrawerOpen,
  themeMode,
  setThemeMode,
  user,
}) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [{ firebaseAuth }] = useFirebaseConfig();
  const router = useCommonRouter();

  return (
    <AppBar position="static" open={open}>
      <Toolbar variant="dense">
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            userSelect: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            router.push("/");
          }}
        >
          Puzsq CreatorHub
        </Typography>
        <MaterialUISwitch
          sx={{ m: 1 }}
          checked={themeMode === "dark"}
          onChange={() => {
            setThemeMode(themeMode === "dark" ? "light" : "dark");
          }}
        />
        {/*<Button color="inherit">Login</Button>*/}
        {user ? (
          <>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: "auto" }}>
              {user.photoURL ? (
                <Avatar alt="user" src={user.photoURL} />
              ) : (
                <Avatar alt="user">{user.displayName?.charAt(0)}</Avatar>
              )}
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography
                sx={{ p: 2 }}
                variant="h6"
                color={themeMode === "dark" ? "text.primary" : "text.secondary"}
              >
                {user.displayName}
              </Typography>
              <Typography sx={{ p: 2 }} variant="caption">
                {user.email}
              </Typography>
              <MenuItem
                onClick={async () => {
                  if (!firebaseAuth) {
                    return;
                  }
                  await logout();
                }}
                disabled={!firebaseAuth}
              >
                ログアウト
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            color="inherit"
            onClick={() =>
              (window.location.href = `https://logicpuzzle.app/login/${process.env.REACT_APP_LOGIN_URL}`)
            }
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
