"use client";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { FC, useEffect, useMemo, useState } from "react";
import { MainProps } from "../../../../_lib/eniverse/imaginate/CommonMain";
import { useTreeStore } from "../../../../_lib/eniverse/imaginate/treeStore/useTreeStore";
import { CustomData } from "../../../../_lib/eniverse/imaginate/treeView/types";
import { InnerHeader } from "./InnerHeader";
import { InnerMainContainer } from "./InnerMainContainer";
import { InnerSidebar } from "./InnerSidebar";
import { Grid } from "@mui/material";

type InnerImaginateProps = {
  initialPath: string;
  mainComponent: FC<MainProps>;
  initialTree?: NodeModel<CustomData>[];
};

export const InnerImaginate: FC<InnerImaginateProps> = ({
  initialPath,
  mainComponent,
  initialTree,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    console.log(drawerOpen, "handleDrawerOpen");
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [selectedNode, setSelectedNode] =
    useState<NodeModel<CustomData> | null>(null);

  const { tree, localTree, updateTree, handleAdd } = useTreeStore({
    path: initialPath,
    initialTree,
  });

  const [selectedNodeId, setSelectedNodeId] = useState("");
  useEffect(() => {
    if (selectedNodeId) {
      const node = tree.find((n) => n.id === 1);
      if (node) {
        setSelectedNode(node);
      }
    }
  }, [selectedNodeId, tree]);

  const parentNode = useMemo(() => {
    if (selectedNode) {
      return tree.find((node) => node.id === selectedNode.parent) || null;
    }
    return null;
  }, [tree, selectedNode]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <InnerSidebar
            drawerOpen={drawerOpen}
            handleDrawerClose={handleDrawerClose}
            treeData={localTree}
            setTreeData={updateTree}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            treeCommand={{
              addNode: handleAdd,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9} xl={10}>
          <InnerHeader
            parentNode={parentNode}
            currentNode={selectedNode}
            handleParentClick={() => {
              setSelectedNode(parentNode);
            }}
          />
          <InnerMainContainer
            currentNode={selectedNode}
            drawerOpen={drawerOpen}
            updateNode={(node) => {
              const newTree = [...localTree];
              const index = newTree.findIndex((n) => n.id === node.id);
              newTree[index] = node;
              updateTree(newTree);
              setSelectedNode(node);
            }}
            treeData={localTree}
            setSelectedNode={setSelectedNode}
            treeCommand={{
              addNode: handleAdd,
            }}
            mainComponent={mainComponent}
          />
        </Grid>
      </Grid>
    </>
  );
};
