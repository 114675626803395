import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { FC } from "react";
import { BasicFormProps } from "../FormProps";
import { TraceGroupingQuery } from "@langue-de-chat-llc/enigmastudio-algorithm";

export type TraceGroupingFormProps = BasicFormProps<TraceGroupingQuery> & {
  traceCount: number;
};

export const TraceGroupingForm: FC<TraceGroupingFormProps> = ({
  index,
  query,
  updateQuery,
  isDemo,
  traceCount,
}) => {
  const { groupKey, groupValue, enableTrace } = query;
  return (
    <>
      <TextField
        label="グループKey"
        variant="outlined"
        value={groupKey || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "groupKey", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />
      <TextField
        label="グループValue"
        variant="outlined"
        value={groupValue || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "groupValue", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />
      <Box sx={{ mb: 2 }}>
        トレースをグループ化
        {Array.from({ length: traceCount }).map((_, i) => (
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                size="small"
                checked={(enableTrace ?? []).includes(i + 1)}
                onChange={(e) => {
                  if (e.target.checked) {
                    updateQuery(index, "enableTrace", [
                      ...(enableTrace ?? []),
                      i + 1,
                    ]);
                  } else {
                    updateQuery(
                      index,
                      "enableTrace",
                      (enableTrace ?? []).filter((v) => v !== i + 1)
                    );
                  }
                }}
              />
            }
            label={i + 1}
            labelPlacement="start"
            key={i}
          />
        ))}
      </Box>
    </>
  );
};
