import { useStatePersist } from "use-state-persist";
import { WordObj } from "../gridToolType";
import { useEffect } from "react";

export const useWordList = () => {
  const [rawWordList, setRawWordList] = useStatePersist<string>(
    "grid-tool-raw-word-list",
    ""
  );
  const [wordList, setWordList] = useStatePersist<WordObj[]>(
    "grid-tool-word-list",
    []
  );

  useEffect(() => {
    const newRawWordArray = rawWordList.split("\n");

    // 各行（単語）とその行数（y座標）をペアとして扱う
    const currentWordPairs = wordList.map((wordObj, index) => ({
      word: wordObj.word,
      index: wordObj.index,
    }));
    const newWordPairs = newRawWordArray.map((word, index) => ({
      word,
      index,
    }));

    // 追加された単語と行数のペアを見つける
    const addedWords = newWordPairs.filter(
      (newPair) =>
        !currentWordPairs.some(
          (currentPair) =>
            currentPair.word === newPair.word &&
            currentPair.index === newPair.index
        )
    );

    // 削除された単語と行数のペアを見つける
    const deletedWords = currentWordPairs.filter(
      (currentPair) =>
        !newWordPairs.some(
          (newPair) =>
            newPair.word === currentPair.word &&
            newPair.index === currentPair.index
        )
    );

    // 既存のwordListをコピーして作業する
    let newWordList = [...wordList];

    // 単語が削除された場合の処理
    for (const { word: deletedWord, index } of deletedWords) {
      newWordList = newWordList.filter(
        (wordObj) => !(wordObj.word === deletedWord && wordObj.index === index)
      );
    }

    // 単語が追加された場合の処理
    for (const { word: addedWord, index } of addedWords) {
      // 同じ行（y座標）で削除された単語を探す
      const replacedWordObj = wordList.find(
        (wordObj) => wordObj.index === index
      );

      if (replacedWordObj) {
        // 単語が変更されたとみなし、前の単語の属性を引き継ぐ
        newWordList.push({
          word: addedWord,
          x: replacedWordObj.x,
          y: replacedWordObj.y,
          index: replacedWordObj.index,
          direction: replacedWordObj.direction,
        });
      } else {
        // 新規追加された単語
        newWordList.push({
          word: addedWord,
          x: ((index / 50) | 0) * 8,
          y: index % 50,
          index,
          direction: "right", // 仮の値
        });
      }
    }

    // index正規化
    newWordList = newWordList.map((wordObj, index) => ({
      ...wordObj,
      index,
    }));

    setWordList(newWordList);
  }, [rawWordList]);

  return {
    rawWordList,
    setRawWordList,
    wordList,
    setWordList,
  };
};
