"use client";
import { Alert, Box, Button, Snackbar, Stack } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { FC, useCallback, useState } from "react";
import { functions } from "../../../..";
import { loadSpreadsheet } from "../../../../components/CommonStudio/spreadSheet/loadSpreadSheet";
import { useSiteConfig } from "../../EniverseProvider";
import { StackEditList } from "../../StackSpace/StackEditList";
import { StackSpaceConfigView } from "../../StackSpace/StackSpaceConfigView";
import { StackViewTab } from "../../StackSpace/components/ViewTab";
import { lpEditorDataTable } from "../../StackSpace/editItems2/data/lpEditorData";
import { useDocWithLocalState } from "../../storekit/firestore/pure/useDocWithLocal";
import { CommonAppProp } from "./CommponAppProp";

const defaultContents = {
  list: [],
};

export const StackSpaceView: FC<CommonAppProp> = (props) => {
  const { currentNode, project } = props;
  const [value, setValue] = useState(0);

  const [{ serviceId }] = useSiteConfig();
  const path = `/workspace/${serviceId}/project/${project}/contents/${currentNode.data?.contentsId}`;
  const configDataPath = `/workspace/${serviceId}/project/${project}/contents/${currentNode.data?.contentsId}/data/config`;

  const {
    localData: localContents,
    resetData,
    saveData,
    setLocalData,
  } = useDocWithLocalState<any>({
    path,
    initialData: { ...defaultContents },
  });

  const {
    localData: localConfigData,
    setLocalData: setLocalConfigData,
    saveData: saveConfigData,
  } = useDocWithLocalState<any>({
    path: configDataPath,
    initialData: {},
  });

  const [changed, setChanged] = useState(false);

  const setContents = (newContents: any) => {
    setChanged(true);
    setLocalData(newContents);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const release = httpsCallable(functions, "workspace-release");
  const onRelease = useCallback(() => {
    release({
      serviceId,
      project,
      contentId: currentNode.data?.contentsId ?? "",
    }).then((result) => {
      // 
      console.log(result);
    });
  }, []);

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          保存しました
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          onRelease();
        }}
        sx={{ my: 1 }}
      >
        リリース
      </Button>
      <Box sx={{ my: 2 }} />
      <StackViewTab value={value} handleChange={handleChange} />
      <Box sx={{ my: 2 }} />

      {value === 0 && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const kankanko = await loadSpreadsheet(
                localConfigData.sheetUrl,
                "kankanko"
              );
              const popup = await loadSpreadsheet(
                localConfigData.sheetUrl,
                "popup"
              );
              setContents((prev: any) => ({
                ...prev,
                rawSheet: {
                  kankanko: kankanko?.json ?? null,
                  popup: popup?.json ?? null,
                },
              }));
              window.setTimeout(async () => {
                console.log(localContents, "localContents");
                await saveData();
                handleClick();
              }, 3000);
            }}
            sx={{ my: 1 }}
          >
            シート更新
          </Button>
          <StackSpaceConfigView
            config={localConfigData}
            setConfig={setLocalConfigData}
            saveConfigData={saveConfigData}
          />
        </>
      )}
      {value === 1 && (
        <>
          <Box sx={{ my: 2 }} />
          <Stack direction={"row"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                setChanged(false);
                await saveData();
                handleClick();
              }}
              sx={{ my: 1 }}
              disabled={!changed}
            >
              下書き保存
            </Button>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resetData();
            }}
            sx={{ my: 1 }}
          >
            リセット
          </Button>
          {localContents && (
            <StackEditList
              contents={localContents}
              setContents={setContents}
              editorDataTable={lpEditorDataTable}
              config={localConfigData}
            />
          )}
        </>
      )}
      {value === 1 && <></>}
    </>
  );
};
