"use client";
import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { GoogleLogin } from "../../../_lib/eniverse/siteKit/auth/GoogleLogin";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { If } from "../../../_lib/eniverse/util/Condition";

type Props = {
  children?: React.ReactNode;
  loginEnabled?: boolean;
};

export const ContentsLogin: FC<Props> = ({ loginEnabled, children }) => {
  const { user } = useFirebaseLogin();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  if (!loginEnabled) {
    return <>{children}</>;
  }
  return (
    <>
      <If condition={!user}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <GoogleLogin loading={loading} setLoading={setLoading} />
        </Box>
      </If>
      <If condition={user}>{children}</If>
    </>
  );
};
