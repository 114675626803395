import {
  FunctionSearchProgress,
  GeneralSearchInput,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

import {
  Box,
  Button,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useStatePersist } from "use-state-persist";
import { useFirebaseLogin } from "../../../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { useDoc } from "../../../../../_lib/eniverse/storekit/firestore/pure/useDoc";
import { If } from "../../../../../_lib/eniverse/util/Condition";
import { useWorker } from "../../../../../hooks/useWorker";
import { PlainText } from "../../../common/PlainText";
import { a11yProps } from "../../../common/TabPanel";
import { Title } from "../../../common/Title";
import { DemoModeMessage } from "../../../commonStudio/DemoModeMessage";
import { GeneralSearchResultList } from "../../../commonStudio/GeneralSearchResult";
import { EnigmaStudioContext } from "../../../context/EnigmaStudioContext";
import { CloudDoc } from "../../CloudItem/cloudType";
import { presetItems } from "./list/presetItems";
import { ListDefininition } from "./ListDefininition";
import { QueryContainer } from "./QueryContainer";
import { CommandSelector } from "./tool/CommandSelector";
import { GeneralSeachDictionarySelector } from "./tool/GeneralSearchDictionarySelector";
import { jsonFromGeneralSearchObj } from "./tool/generalSearchObject";
import { GeneralSearchOption } from "./tool/GeneralSearchOption";
import { JsonView } from "./tool/JsonView";
import { QueryEstimation } from "./tool/QueryEstimation";
import { useGeneralSearchQuery } from "./tool/useGeneralSearchQuery";
import { useListDefinition } from "./tool/useListDefinition";

export const GeneralSearch: FC = () => {
  const { enableLoad, setEnableLoad, plan } = useContext(EnigmaStudioContext);
  const isDemo = plan === "normal";
  useEffect(() => {
    if (!enableLoad) {
      setEnableLoad(true);
    }
  }, [enableLoad, setEnableLoad]);

  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState<{ s: string; v?: number }[]>([]);

  const {
    queries,
    setQueries,
    queryJson,
    setQueryJson,
    sequencialQueries,
    traceCounter,
    estimation,
    setEstimation,
    isEstimation,
    resetEstimation,
    setQueryFromJson,
    jsonError,
    setJsonError,
    removeQuery,
    updateQuery,
    deleteQueryField,
  } = useGeneralSearchQuery();

  const { user } = useFirebaseLogin();
  const fileListPath = `users/${user?.uid ?? "guest"}/wordSearch/fileList`;
  const [fileListData, setFileListData, fileListLoading] = useDoc<CloudDoc>({
    path: fileListPath,
    initialData: {
      files: {},
    },
  });

  const [pattern, setPattern] = useStatePersist<string>(
    "word-list-pattern",
    ""
  );
  const [patternHistory, setPatternHistory] = useStatePersist<string[]>(
    "word-list-pattern-history",
    []
  );
  const [patternHistoryIndex, setPatternHistoryIndex] = useStatePersist<number>(
    "word-list-pattern-history-index",
    -1
  );

  const {
    editableItems,
    handleAdd,
    handleDelete,
    inputName,
    setInputName,
    inputValue,
    setInputValue,
  } = useListDefinition();

  const [progress, setProgress] = useState<FunctionSearchProgress>({
    startTime: 0,
    currentTime: 0,
    queryProgress: [],
  });

  const { generalSearch, abort } = useWorker();

  const [dictionaries, setDictionaries] = useStatePersist<string[]>(
    "enigma-studio-dictionaries",
    []
  );

  const [inputTabValue, setInputTabValue] = useState("dictionary");

  const onSearch = useCallback(() => {
    setResult([]);
    setSearching(true);

    const input = {
      queries,
      estimation: isEstimation ? estimation : undefined,
      inputDictionary: ["dictionary", "cloudFile"].includes(inputTabValue)
        ? dictionaries
        : "free",
      inputDictionaryFree: pattern,
      listDefinitions: presetItems.concat(editableItems).map((item: any) => ({
        name: item.name,
        content: item.value,
        loop: true,
      })),
    };

    generalSearch(
      { input: input as GeneralSearchInput },
      (result) => {
        if (result) {
          setResult(result);
          setSearching(false);
        }
      },
      (progress) => {
        if (progress) {
          setProgress(progress);
        }
      }
    );
  }, [
    queries,
    isEstimation,
    estimation,
    inputTabValue,
    dictionaries,
    pattern,
    editableItems,
    generalSearch,
  ]);

  const onSearchStop = useCallback(() => {
    abort();
  }, [abort]);

  //Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [displayTraceNumber] = useStatePersist(
    "enigma-studio-function-search-display-trace-number",
    false
  );

  const [displayTooltip] = useStatePersist(
    "general-search-display-tooltip",
    true
  );

  const setConverted = useCallback(
    (text: string) => {
      if (patternHistory[patternHistoryIndex - 1] === text) {
        return;
      }
      setPatternHistory((prev) => {
        let _patternHistoryIndex = patternHistoryIndex;
        if (prev[_patternHistoryIndex] !== pattern) {
          prev = prev.slice(0, _patternHistoryIndex + 1);
          prev.push(pattern);
          _patternHistoryIndex++;
        }
        if (prev[_patternHistoryIndex] !== text) {
          prev = prev.slice(0, _patternHistoryIndex + 1);
          prev.push(text);
        }
        setPatternHistoryIndex(prev.length - 1);
        return prev;
      });
      setPattern(text);
    },
    [
      patternHistory,
      patternHistoryIndex,
      setPatternHistory,
      setPattern,
      pattern,
      setPatternHistoryIndex,
    ]
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>汎用検索</Title>
      <PlainText>
        選択した辞書の入力文字列に対して文字列変換、絞り込み(フィルタ)など、複数の文字列処理を組み合わせて検索を行います。
      </PlainText>
      <DemoModeMessage isDemo={isDemo} search={true} />
      <GeneralSearchOption
        listDefinition={
          <ListDefininition
            editableItems={editableItems}
            handleDelete={handleDelete}
            handleAdd={handleAdd}
            inputName={inputName}
            setInputName={setInputName}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        }
      />
      <Divider>
        <Typography variant="caption" sx={{ ml: 1 }}>
          ① 処理作成
        </Typography>
      </Divider>
      <Button
        variant="contained"
        onClick={() => {
          setQueryJson(
            jsonFromGeneralSearchObj({
              queries: [],
              version: "2",
            })
          );
          setQueries([]);
          setJsonError(false);
          resetEstimation();
        }}
        disabled={isDemo}
      >
        新規作成
      </Button>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="フォームツール" {...a11yProps(0)} />
          <Tab label="JSON入出力" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <>
          <Box sx={{ my: 2 }}>
            <QueryEstimation
              estimation={estimation}
              setEstimation={setEstimation}
              isEstimation={isEstimation}
              isDemo={isDemo}
            />
            <QueryContainer
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              removeQuery={removeQuery}
              displayTraceNumber={displayTraceNumber}
              queries={queries}
              traceCounter={traceCounter}
              setQueries={setQueries}
              setQuery={setQueryJson}
              sequencialQueries={sequencialQueries}
              displayTooltip={displayTooltip}
              isDemo={isDemo}
            />
          </Box>
          <CommandSelector
            queries={queries}
            setQueries={setQueries}
            setQueryJson={setQueryJson}
            setJsonError={setJsonError}
            isDemo={isDemo}
            isEstimation={isEstimation}
            estimation={estimation}
          />
        </>
      )}
      <If condition={tabValue === 1}>
        <JsonView
          json={queryJson}
          setJson={setQueryJson}
          queries={queries}
          setQueries={setQueries}
          jsonError={jsonError}
          setJsonError={setJsonError}
          isDemo={isDemo}
          setQueryFromJson={setQueryFromJson}
        />
      </If>

      <Box sx={{ mt: 2 }} />
      <Divider>
        <Typography variant="caption" sx={{ ml: 1 }}>
          ② 入力操作
        </Typography>
      </Divider>
      <GeneralSeachDictionarySelector
        inputTabValue={inputTabValue}
        setInputTabValue={setInputTabValue}
        dictionaries={dictionaries}
        setDictionaries={setDictionaries}
        fileListData={fileListData}
        setFileListData={setFileListData}
        fileListLoading={fileListLoading}
      />
      <Box>
        <Button
          variant="contained"
          onClick={onSearch}
          disabled={
            searching ||
            (inputTabValue === "dictionary" && dictionaries.length === 0)
          }
        >
          検索
        </Button>
        <Button
          variant="contained"
          onClick={onSearchStop}
          disabled={!searching}
          sx={{ ml: 2 }}
        >
          停止
        </Button>
      </Box>
      <Divider>
        <Typography variant="caption" sx={{ ml: 1 }}>
          ③ 結果表示
        </Typography>
      </Divider>
      <GeneralSearchResultList
        items={result}
        loading={searching}
        progress={progress}
        queries={sequencialQueries}
        setFreeInput={(text: string) => {
          setInputTabValue("free");
          setConverted(text);
        }}
        setFileListData={setFileListData}
        fileListData={fileListData}
      />
    </Container>
  );
};
