import { checkReplacementCondition } from "../checkReplacementCondition";
import { calcEstimateValue } from "../estimation";
import { createRegex } from "../exRegexp";
import { progress, sendProgress } from "../generalSearchProgress";
import { processNestedFunctions } from "../patternFunctions";
export const regexMatchCompareFilter = async ({ query, input, listDefinitionsTable, result, workerContext, }) => {
    const pattern = (query.pattern ?? "") === "" ? "^(.*)$" : query.pattern;
    const reg = createRegex(pattern, listDefinitionsTable);
    result = result.filter((e) => {
        return e.currentWord.match(reg);
    });
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount = result.length;
    {
        const list = [];
        let i = 0;
        for (const e of result) {
            ++i;
            progress.get().queryProgress[progress.get().queryProgress.length - 1].currentWordCount = i;
            if (i % 1000 === 0) {
                await sendProgress(workerContext);
            }
            const matches = e.currentWord.match(reg);
            if (!matches) {
                continue;
            }
            const flag = matches.every((match) => {
                const replacements = query.replacements.map((replacement) => {
                    const replaced = match.replace(reg, replacement ?? "");
                    return processNestedFunctions(replaced, listDefinitionsTable, e.trace ?? []);
                });
                return checkReplacementCondition({
                    input,
                    query,
                    replacements,
                    e,
                });
            });
            if (input.estimation) {
                const newEstimation = calcEstimateValue(e.currentEstimation ?? 1, query, flag);
                if (!input.estimation.filterEnabled ||
                    newEstimation > input.estimation.filterValue) {
                    const newE = {
                        ...e,
                        currentEstimation: newEstimation,
                    };
                    list.push(newE);
                }
            }
            else {
                if (flag) {
                    list.push(e);
                }
            }
        }
        result = list;
    }
    return result;
};
