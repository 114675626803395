import { Question } from "../events";
import { riddledCapitalData } from "../rawData/capital";
import { createTake } from "../take";

export const riddledCapital = {
  id: "riddledCapital",
  title: "穴あき県庁所在地ゲーム",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「＊＊＊ろ」→「さっぽろ」のように、
      <br />
      ひらがなの一部が隠された状態から県庁所在地名をひらがなで答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「＊＊＊ろ」→「さっぽろ」のように、
      <br />
      ひらがなの一部が隠された状態から県庁所在地名をひらがなで答えます。
      <br />
      複数あるものは伏字のある都道府県名のみ答えてください。
      <br />
      「市」は省略します。東京の首都は「とうきょう」としています。
      <br />
      全部で20問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () => {
    const questions: Question[] = [];
    riddledCapitalData.forEach((e) => {
      const question = {
        problemText: e[1],
        answers: [e[0]],
      };
      questions.push(question);
    });
    return createTake(questions, 20);
  },
  shareButton: true,
  answerButton: true,
  hashTag: "穴あき県庁所在地",
};