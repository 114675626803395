import {
  EstimationInput,
  GeneralSearchQuery,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import { FilterQueryBase } from "@langue-de-chat-llc/enigmastudio-algorithm/dist/param/generalSearchFilter";
import { useMemo, useState } from "react";

export const initializeEstimation = () => {
  return {
    initialValue: 1.0,
    filterValue: 0,
    filterEnabled: true,
  } as EstimationInput;
};

export type EstimationHook = {
  estimation: EstimationInput;
  setEstimation: (value: EstimationInput) => void;
  isEstimation: boolean;
  resetEstimation: () => void;
};

export const useEstimation = (
  queries: GeneralSearchQuery[]
): EstimationHook => {
  const [estimation, setEstimation] = useState<EstimationInput>(
    initializeEstimation()
  );
  const isEstimation = useMemo(() => {
    return queries.some((query) => {
      return (query as FilterQueryBase)?.unmatchEstimationOperator;
    });
  }, [queries]);

  const resetEstimation = () => {
    setEstimation(initializeEstimation);
  };

  return {
    estimation,
    setEstimation,
    isEstimation,
    resetEstimation,
  };
};
