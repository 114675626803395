"use client";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { Container, Stack, TextField, Typography, styled } from "@mui/material";
import { FC, useRef, useState } from "react";
import { drawerWidth } from "./InnerSidebar";
import { MainProps } from "../../../../_lib/eniverse/imaginate/CommonMain";
import {
  CustomData,
  TreeCommand,
} from "../../../../_lib/eniverse/imaginate/treeView/types";
import { TypeIcon } from "../../../../_lib/eniverse/imaginate/TypeIcon";

type MainContainerProps = {
  currentNode: NodeModel<CustomData> | null;
  drawerOpen: boolean;
  updateNode: (node: NodeModel<CustomData>) => void;
  treeData: NodeModel<CustomData>[];
  setSelectedNode: (node: NodeModel<CustomData>) => void;
  treeCommand: TreeCommand;
  mainComponent: FC<MainProps>;
};

export const InnerMainContainer: FC<MainContainerProps> = (props) => {
  const { currentNode, drawerOpen, treeData, setSelectedNode, treeCommand } =
    props;
  const [titleEdit, setTitleEdit] = useState(false);
  const [title, setTitle] = useState(currentNode?.text ?? "");
  const inputRef = useRef<HTMLInputElement>(null);

  if (!currentNode) {
    return <MainWrapper open={drawerOpen}></MainWrapper>;
  }

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        spacing={2}
        sx={{
          verticalAlign: "bottom",
          mb: 2,
        }}
        useFlexGap
      >
        <TypeIcon
          droppable={currentNode.droppable ?? false}
          fileType={currentNode.data?.fileType}
          sx={{
            fontSize: 60,
          }}
        />
        {titleEdit ? (
          <TextField
            id="standard-basic"
            variant="standard"
            fullWidth
            value={title}
            ref={(input) => input && input.focus()}
            autoFocus
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => {
              if (title === currentNode.text) {
                setTitleEdit(false);
                return;
              }
              if (title === "") setTitle(currentNode.text);
              setTitleEdit(false);
              props.updateNode({
                ...currentNode,
                text: title,
              });
            }}
            sx={{
              mt: "auto",
              mb: "0",
              verticalAlign: "bottom",
              input: {
                fontSize: "2rem",
              },
            }}
          />
        ) : (
          <Typography
            variant="h1"
            sx={{
              mt: "auto",
              mb: "0",
              verticalAlign: "bottom",
              cursor: "pointer",
              fontSize: "2rem",
              color: "primary.main",
            }}
            onClick={() => {
              setTitleEdit(true);
              setTitle(currentNode.text);
              //focus
              setTimeout(() => {
                console.log(inputRef.current);
                inputRef.current?.focus();
              }, 100);
            }}
          >
            {currentNode.text}
          </Typography>
        )}
      </Stack>
      <props.mainComponent
        currentNode={currentNode}
        updateNode={props.updateNode}
        treeData={treeData}
        setSelectedNode={setSelectedNode}
        treeCommand={treeCommand}
      />
    </Container>
  );
};

const MainWrapper = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));
