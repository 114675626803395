import { splitGrapheme } from "./splitGrapheme";
// 再帰関数で文字列を展開
const expand = (parts) => {
    const part = parts.shift();
    if (!part)
        return [""];
    let options;
    if (part.includes("|")) {
        options = part.slice(1, -1).split("|");
    }
    else {
        options = splitGrapheme(part.slice(1, -1));
    }
    const expanded = [];
    for (const option of options) {
        const rest = expand(parts.slice()); // 残りの部分を再帰的に処理
        for (const suffix of rest) {
            expanded.push(option + suffix);
        }
    }
    return expanded;
};
const singleRegex = /\[[^\]\[()<>]*\]/;
const globalRegex = /\[[^\]\[()<>]*\]/g;
const singleExpandBracketedString = (input, matches) => {
    // 元の文字列の[]部分を取り除き、展開
    const baseParts = input.split(singleRegex);
    let results = [""];
    for (let i = 0; i < baseParts.length; i++) {
        const nextResults = [];
        for (const result of results) {
            if (i < matches.length) {
                const expandedList = expand([matches[i]]);
                if (expandedList.length === 0) {
                    return [];
                }
                for (const expanded of expandedList) {
                    nextResults.push(result + baseParts[i] + expanded);
                }
            }
            else {
                nextResults.push(result + baseParts[i]);
            }
        }
        results = nextResults;
    }
    return results;
};
export const expandBracketedString = (input) => {
    // 正規表現で[]内の文字を検出
    const matches = input.match(globalRegex);
    // マッチしない場合、入力をそのまま返す
    if (!matches) {
        return [input];
    }
    const results = singleExpandBracketedString(input, matches);
    if (results.every((result) => result.match(globalRegex) === null)) {
        return results;
    }
    else {
        const nextResults = [];
        for (const result of results) {
            nextResults.push(...expandBracketedString(result));
        }
        return nextResults;
    }
};
