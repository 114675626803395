import { NodeModel } from "@minoru/react-dnd-treeview";
import { FC } from "react";
import { CustomData, TreeCommand } from "./treeView/types";

export type MainProps = {
  currentNode: NodeModel<CustomData>;
  updateNode: (node: NodeModel<CustomData>) => void;
  treeData: NodeModel<CustomData>[];
  setSelectedNode: (node: NodeModel<CustomData>) => void;
  treeCommand: TreeCommand;
};

export const EmptyMain: FC<MainProps> = (props) => {
  return <></>;
};
