import { NodeModel } from "@minoru/react-dnd-treeview";
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { CustomData } from "../../imaginate/treeView/types";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { FileTypeIcon } from "../PuzsqTypeIcon";

type FolderViewProps = {
  addApp: () => void;
  currentNode: NodeModel<CustomData>;
  treeData: NodeModel<CustomData>[];
  setSelectedNode: (node: NodeModel<CustomData>) => void;
};

export const PuzsqContestProblemFolderView: FC<FolderViewProps> = (props) => {
  const { addApp, treeData, setSelectedNode, currentNode } = props;
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        verticalAlign: "bottom",
        mb: 2,
      }}
      useFlexGap
      flexWrap={"wrap"}
    >
      {treeData &&
        treeData
          .filter((node) => node.parent === currentNode.id)
          .map((node) => {
            return (
              <Card sx={{ width: 240 }} key={node.id}>
                <CardActionArea
                  onClick={() => {
                    setSelectedNode(node);
                  }}
                >
                  <CardContent>
                    <FileTypeIcon
                      fileType={node.data?.fileType || "app"}
                      sx={{
                        fontSize: 80,
                      }}
                    />
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        mt: 1,
                      }}
                    >
                      {node.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
      {currentNode.data?.addable !== false && (
        <Card
          sx={{
            width: 240,
            bgcolor: "divider",
          }}
          elevation={0}
          variant="outlined"
        >
          <CardActionArea
            onClick={() => {
              addApp();
            }}
          >
            <CardContent>
              <LibraryAddIcon
                sx={{
                  fontSize: 80,
                }}
              />
              <Typography gutterBottom variant="h6" component="div">
                アイテムを追加
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </Stack>
  );
};
