import AppsIcon from "@mui/icons-material/Apps";
import CalculateIcon from "@mui/icons-material/Calculate";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SchemaIcon from "@mui/icons-material/Schema";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import HubIcon from "@mui/icons-material/Hub";
import LayersIcon from "@mui/icons-material/Layers";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import React, { FC } from "react";
import {
  FileTypeIcon as PuzsqFileTypeIcon,
  TypeIcon as PuzsqTypeIcon,
} from "../puzsqView/PuzsqTypeIcon";

type Props = {
  droppable: boolean;
  fileType?: string;
  sx?: any;
};

type FileTypeProps = {
  fileType: string;
  sx?: any;
};

export const FileTypeIcon: FC<FileTypeProps> = (props) => {
  const { fileType, sx = {} } = props;
  if (fileType.startsWith("puzsq")) {
    return <PuzsqFileTypeIcon {...props} />;
  }
  switch (fileType) {
    case "folder":
      return <FolderIcon sx={sx} />;
    case "image-library":
      return <PhotoLibraryIcon sx={sx} />;
    case "image":
      return <ImageIcon sx={sx} />;
    case "csv":
      return <ListAltIcon sx={sx} />;
    case "text":
      return <DescriptionIcon sx={sx} />;
    case "general-search":
      return <SchemaIcon sx={sx} />;
    case "word-generator":
      return <MicrowaveIcon sx={sx} />;
    case "calculator":
      return <CalculateIcon sx={sx} />;
    case "gridKit":
      return <Grid4x4Icon sx={sx} />;
    case "canvas":
      return <ShapeLineIcon sx={sx} />;
    case "rich-text":
      return <NewspaperIcon sx={sx} />;
    case "stack-space":
      return <LayersIcon sx={sx} />;
    case "app":
      return <AppsIcon sx={sx} />;
    case "viz":
      return <HubIcon sx={sx} />;
    default:
      return null;
  }
};

export const TypeIcon: FC<Props> = (props) => {
  const { sx } = props;

  if (!props.fileType) {
    return <AppsIcon sx={sx} />;
  }
  if (props.fileType.startsWith("puzsq")) {
    return <PuzsqTypeIcon {...props} />;
  }
  const ret = <FileTypeIcon fileType={props.fileType} sx={sx} />;
  if (ret) {
    return ret;
  }
  if (props.droppable) {
    return <FolderIcon sx={sx} />;
  }
  return null;
};
