import { KonvaEventObject } from "konva/lib/Node";
import { FC } from "react";
import { Rect } from "react-konva";
import { ModeType } from "../Menu/ModeSelector";

type SquareViewProps = {
  mode: ModeType | null;
  maxWidth: number;
  maxHeight: number;
  scale: number;
  handleWhiteListStart: (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => void;
  handleWhiteListMove: (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => void;
  handleWhiteListEnd: (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => void;
  handleSquareClick: (e: KonvaEventObject<MouseEvent>) => void;
};

export const SquareView: FC<SquareViewProps> = ({
  mode,
  maxWidth,
  maxHeight,
  scale,
  handleWhiteListStart,
  handleWhiteListMove,
  handleWhiteListEnd,
  handleSquareClick,
}) => {
  return (
    <>
      {["square", "white-square"].includes(mode ?? "") && (
        <Rect
          x={0}
          y={0}
          width={maxWidth / scale}
          height={maxHeight / scale}
          onMouseDown={
            mode === "white-square" ? handleWhiteListStart : undefined
          }
          onMouseMove={
            mode === "white-square" ? handleWhiteListMove : undefined
          }
          onMouseUp={mode === "white-square" ? handleWhiteListEnd : undefined}
          onMouseLeave={
            mode === "white-square" ? handleWhiteListEnd : undefined
          }
          onMouseOut={mode === "white-square" ? handleWhiteListEnd : undefined}
          onTouchStart={
            mode === "white-square" ? handleWhiteListStart : undefined
          }
          onTouchMove={
            mode === "white-square" ? handleWhiteListMove : undefined
          }
          onTouchEnd={mode === "white-square" ? handleWhiteListEnd : undefined}
          onClick={handleSquareClick}
        />
      )}
    </>
  );
};
