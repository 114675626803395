export const country2continent = {
  id: "country2continent",
  title: "大陸どこゲーム",
  category: "ランダム出題",
  addMenu: false,
  menuDescription: (
    <>
      「日本」→「アジア」のように
      <br />
      国名から大陸を答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「日本」→「アジア」のように
      <br />
      国名から大陸を答えます。
      <br />
      全部で10問出題されます。
      <br />
    </p>,
  ],
  questions: [
    {
      problemText: "日本",
      answers: ["アジア"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "アメリカ",
      answers: ["アメリカ大陸"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "ブラジル",
      answers: ["アメリカ大陸"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "イギリス",
      answers: ["ヨーロッパ"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "フランス",
      answers: ["ヨーロッパ"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "ドイツ",
      answers: ["ヨーロッパ"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "イタリア",
      answers: ["ヨーロッパ"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "エジプト",
      answers: ["アフリカ"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "南アフリカ",
      answers: ["アフリカ"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "中国",
      answers: ["アジア"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "インド",
      answers: ["アジア"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "オーストラリア",
      answers: ["オセアニア"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
    {
      problemText: "ニュージーランド",
      answers: ["オセアニア"],
      choices: [
        "アジア",
        "アフリカ",
        "ヨーロッパ",
        "オセアニア",
        "アメリカ大陸",
      ],
    },
  ],
  submit: false,
  retry: true,
  shareButton: true,
  hashTag: "大陸どこゲーム",
};
