import { View } from "@react-pdf/renderer";
import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { StackData, StackVerticalSpaceData } from "../stackData";

export const VerticalSpace: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "verticalspace"}>
      <View style={{ width: (contents as StackVerticalSpaceData).space }} />
    </If>
  );
};
