import { TextField } from "@mui/material";
import { FC, useState } from "react";

type EditJsonItemProps = {
  content: any;
  setContent: any;
  editorData: any;
  selected: boolean;
  syncContentParent: any;
};

export const EditJsonForm: FC<EditJsonItemProps> = (props) => {
  const { content, setContent, selected } = props;
  const [jsonError, setJsonError] = useState(false);
  const [text, setText] = useState(JSON.stringify(content, null, 2));
  return selected ? (
    <TextField
      label="JSON表示"
      variant="outlined"
      sx={{ width: "100%", mt: 2 }}
      multiline
      rows={10}
      value={text}
      error={jsonError}
      onChange={(e) => {
        try {
          setText(e.target.value);
          setContent(JSON.parse(e.target.value));
          setJsonError(false);
        } catch (error) {
          console.log(error);
          setJsonError(true);
          setText(e.target.value);
        }
      }}
    />
  ) : (
    <></>
  );
};
