import { getIdToken, getIdTokenResult, User } from "firebase/auth";
import { useEffect, useState } from "react";

export type UserClaims = {
  stripeRole?: string | null;
  manualRole?: string | null;
  workspace?: string[] | null;
};

export const useUserClaims = (
  user: User | null | undefined
): [UserClaims, boolean] => {
  const [value, setValue] = useState<UserClaims>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (user === undefined || user === null) {
      setValue({});
      return;
    }

    const fetchToken = async () => {
      setLoading(true);
      await getIdToken(user, true);
      const decodedToken = await getIdTokenResult(user);

      if (decodedToken.claims) {
        setValue(decodedToken.claims as UserClaims);
      }
      setLoading(false);
    };
    fetchToken();
  }, [user]);

  return [value, loading];
};
