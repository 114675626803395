import { estimateFilter, filter } from "../commonLoop";
export const identicalTraceFilter = async ({ query, input, result, workerContext, }) => {
    const { leftTraceIndex = 1, rightTraceIndex = 1, leftTraceType = "current", rightTraceType = "previous", compare = "nonIdentical", } = query;
    const filterFunc = (e) => {
        let flag = false;
        const leftTrace = leftTraceType === "current"
            ? e.currentWord
            : leftTraceType === "previous"
                ? e.trace[e.trace.length - leftTraceIndex - 1]
                : e.trace[leftTraceIndex - 1];
        const rightTrace = rightTraceType === "current"
            ? e.currentWord
            : rightTraceType === "previous"
                ? e.trace[e.trace.length - rightTraceIndex - 1]
                : e.trace[rightTraceIndex - 1];
        if (compare === "identical") {
            flag =
                leftTrace !== undefined &&
                    rightTrace !== undefined &&
                    leftTrace === rightTrace;
        }
        else if (compare === "nonIdentical") {
            flag =
                leftTrace !== undefined &&
                    rightTrace !== undefined &&
                    leftTrace !== rightTrace;
        }
        return flag;
    };
    if (input.estimation) {
        return estimateFilter(workerContext, result, filterFunc, input.estimation, query);
    }
    return filter(workerContext, result, filterFunc);
};
