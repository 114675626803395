import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirebaseConfig } from "../../../EniverseProvider";

type DocArg<T> = {
  path: string;
  initialData?: T;
};

const useDocWithLocalState = <T>({ path, initialData }: DocArg<T>) => {
  const [serverData, setServerData] = useState<T>();
  const [localData, setLocalData] = useState<T>(initialData as T);
  const [{ cloudFirestore }] = useFirebaseConfig();

  useEffect(() => {
    if (!cloudFirestore) {
      return;
    }
    const fetchData = async () => {
      const docSnapshot = await getDoc(doc(cloudFirestore, path));
      if (docSnapshot.exists()) {
        const data = docSnapshot.data() as T;
        setServerData(data);
        setLocalData(data);
      }
    };
    fetchData();
  }, []);

  const resetData = () => {
    if (!serverData) {
      return;
    }
    setLocalData(serverData);
  };

  const saveData = async () => {
    if (!cloudFirestore) {
      return;
    }
    if (localData) {
      await setDoc(doc(cloudFirestore, path), localData);
      setServerData(localData);
    }
  };

  return { serverData, localData, resetData, saveData, setLocalData };
};

export { useDocWithLocalState };
