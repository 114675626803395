"use client";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { randomKeyGen } from "../../util/keygen";
import { CustomData, TreeCommandOption } from "../treeView/types";
import { getLastId } from "../treeView/util";
import { onSnapshot, doc, Timestamp, setDoc } from "firebase/firestore";
import { useState, useEffect, useCallback, FC } from "react";
import { useFirebaseConfig } from "../../EniverseProvider";

const defaultTree = [
  {
    id: 1,
    parent: 0,
    droppable: true,
    text: "ホーム",
  },
];

type Props = {
  path: string;
  initialTree?: NodeModel<CustomData>[];
  defaultAppType?: string;
};

export const getLastNameId = (
  treeData: NodeModel<CustomData>[],
  name: string,
  separator: string
) => {
  return Number(
    treeData
      .filter((node) => node.text.split(separator)[0] === name)
      .map((node) => node.text.split(separator)[1])
      .sort((a, b) => Number(b) - Number(a))[0] ?? 0
  );
};

export const useTreeStore = ({ path, initialTree, defaultAppType }: Props) => {
  const [{ cloudFirestore }] = useFirebaseConfig();
  const [tree, setTree] = useState<NodeModel<CustomData>[]>(
    initialTree ?? defaultTree
  );
  const [treeUpdatedAt, setTreeUpdatedAt] = useState<any>(null);
  const [localTree, setLocalTree] = useState<NodeModel<CustomData>[]>(
    initialTree ?? defaultTree
  );
  const [localTreeUpdatedAt, setLocalTreeUpdatedAt] = useState<any>(null);

  useEffect(() => {
    if (!cloudFirestore) return;
    console.log("useTreeStore", path + "/trees/main");
    const unsub = onSnapshot(
      doc(cloudFirestore, path + "/trees/main"),
      (doc) => {
        if (!doc.exists()) {
          updateTree(localTree);
          return;
        }
        const { updatedAt, tree } = doc.data();
        if (
          !updatedAt ||
          !localTreeUpdatedAt ||
          !localTreeUpdatedAt?.getTime?.() ||
          (updatedAt as Timestamp).toDate().getTime() >
            localTreeUpdatedAt.getTime()
        ) {
          setTree(tree);
          setTreeUpdatedAt((updatedAt as Timestamp).toDate());
          setLocalTree(tree);
          setLocalTreeUpdatedAt((updatedAt as Timestamp).toDate());
        }
      }
    );
    return unsub;
  }, [localTreeUpdatedAt, path]);

  const updateTree = useCallback(
    async (tree: any) => {
      if (!cloudFirestore) return;
      // update firestore
      const time = Date.now();
      setLocalTree(tree);
      setLocalTreeUpdatedAt(time);

      if (
        treeUpdatedAt === null ||
        JSON.stringify(localTree) !== JSON.stringify(tree)
      ) {
        await setDoc(
          doc(cloudFirestore, path + "/trees/main"),
          {
            updatedAt: Timestamp.fromDate(new Date()),
            tree,
          },
          {
            merge: true,
          }
        );
        setTree(tree);
        setTreeUpdatedAt(Date.now());
      }
    },
    [cloudFirestore, localTree, path, treeUpdatedAt]
  );

  const handleAdd = useCallback(
    async (
      id: NodeModel["id"],
      treeData: NodeModel<CustomData>[],
      option?: TreeCommandOption
    ) => {
      const lastId = Number(getLastId(treeData));
      const targetNode = treeData.find((n) => n.id === id);
      if (!targetNode) return;
      const yymmdd = new Date().toISOString().slice(2, 10).replace(/\-/g, "");
      const yymmddLastId = getLastNameId(treeData, yymmdd, "_");

      await updateTree([
        ...treeData,
        {
          parent: Number(targetNode.id),
          id: lastId + 1,
          droppable: false,
          text:
            (typeof option?.name === "function"
              ? option?.name?.()
              : option?.name) ??
            yymmdd + "_" + ("" + (yymmddLastId + 1)).padStart(3, "0"),
          data: {
            fileType: option?.type ?? defaultAppType ?? "app",
            contentsId: randomKeyGen(16),
          },
        },
      ]);
    },
    [updateTree]
  );

  return {
    handleAdd,
    tree,
    localTree,
    updateTree,
  };
};
