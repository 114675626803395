import { PaletteMode, ThemeOptions } from "@mui/material";
import { BackgroundImage } from "../../../_lib/eniverse/siteKit/uiKit/BgImage/BackgroundImage";

export const puzsqThemeOptions = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    primary: {
      main: "#0d2139",
    },
    secondary: {
      main: "#009688",
    },
    background: {
      default: "#eee",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
        },
        "html, body, #root": {
          height: "100%",
        },
        ul: {
          listStyle: "none",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          verticalAlign: "middle",
        },
      },
    },
  },
});
