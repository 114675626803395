import { Button, ToggleButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FC, ReactNode, useState, useMemo } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { AnagramProgress } from "@langue-de-chat-llc/enigmastudio-algorithm";

const renderRow = (props: ListChildComponentProps<string[] | ReactNode[]>) => {
  const { index, style, data } = props;

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      sx={{ my: 0, py: 0 }}
    >
      <ListItemText
        primary={data[index]}
        sx={{ my: 0, py: 0, whiteSpace: "nowrap" }}
      />
    </ListItem>
  );
};

type ResultProps = {
  items: string[] | ReactNode[];
  loading?: boolean;
  row?: (props: ListChildComponentProps<string[] | ReactNode[]>) => JSX.Element;
  progress: AnagramProgress | null;
};

export const AnagramResultList: FC<ResultProps> = ({
  items,
  loading,
  row,
  progress,
}) => {
  const [download, setDownload] = useState("");
  const handleDownload = () => {
    setDownload(
      "data:text/plain;charset=utf-8," + encodeURIComponent(items.join("\n"))
    );
  };

  const memory = useMemo(() => {
    return (window.performance as any).memory;
  }, [progress]);

  const [displaySearchingKey, setDisplaySearchingKey] = useState(false);
  const [displayMemory, setDisplayMemory] = useState(false);
  const [displayProgress, setDisplayProgress] = useState(false);
  return (
    <>
      <Typography mt={2} variant="h4">
        結果
      </Typography>
      {loading ? (
        progress && (
          <Typography mt={2} variant="body1">
            <Box>
              <Typography
                mt={2}
                variant="body1"
                onClick={() => setDisplayMemory(!displayMemory)}
                sx={{
                  cursor: "pointer",
                }}
              >
                {((progress.currentTime - progress.startTime) / 1000).toFixed(
                  1
                )}
                秒 検索中 {progress.foundCount}件
              </Typography>
              {displayMemory && (
                <>
                  <Typography
                    mt={2}
                    variant="body1"
                    onClick={() => setDisplayMemory(!displayMemory)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    探索: {progress.searchCount} キャッシュヒット:{" "}
                    {progress.hitCount} ヒット率:{" "}
                    {((progress.hitCount / progress.searchCount) * 100).toFixed(
                      2
                    )}
                    %
                  </Typography>
                  <Typography
                    onClick={() => setDisplayMemory(!displayMemory)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Total:{" "}
                    {((memory.totalJSHeapSize ?? 0) / 1024 / 1024).toFixed(2)}
                    MB, Used:
                    {((memory.usedJSHeapSize ?? 0) / 1024 / 1024).toFixed(2)}MB
                    / Limit:{" "}
                    {((memory.jsHeapSizeLimit ?? 0) / 1024 / 1024).toFixed(2)}
                    MB
                  </Typography>
                </>
              )}

              <Typography
                mt={2}
                variant="body1"
                onClick={() => setDisplaySearchingKey(!displaySearchingKey)}
                sx={{
                  cursor: "pointer",
                }}
              >
                {displaySearchingKey ? (
                  <>SearchKey: {progress.searchingKeys.join(" ")}...</>
                ) : (
                  <>Search: {progress.searchingWords.join(" ")}...</>
                )}
              </Typography>
              <Typography mt={2} variant="body1"></Typography>
            </Box>
          </Typography>
        )
      ) : (
        <>
          <Typography mt={2} variant="body1">
            {!(loading || !progress || progress.startTime === 0) && (
              <ToggleButton
                value="monitor"
                selected={displayProgress}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayProgress(!displayProgress);
                }}
                sx={{ mr: 1 }}
              >
                <MonitorHeartIcon />
              </ToggleButton>
            )}
            {items.length}件
          </Typography>
          {displayProgress && progress && (
            <Typography mt={2} variant="body1">
              <Box>
                <Typography
                  mt={2}
                  variant="body1"
                  onClick={() => setDisplayMemory(!displayMemory)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {((progress.currentTime - progress.startTime) / 1000).toFixed(
                    1
                  )}
                  秒
                </Typography>
                <Typography
                  mt={2}
                  variant="body1"
                  onClick={() => setDisplayMemory(!displayMemory)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  探索: {progress.searchCount} キャッシュヒット:{" "}
                  {progress.hitCount} ヒット率:{" "}
                  {((progress.hitCount / progress.searchCount) * 100).toFixed(
                    2
                  )}
                  %
                </Typography>
                <Typography
                  onClick={() => setDisplayMemory(!displayMemory)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Total:{" "}
                  {((memory.totalJSHeapSize ?? 0) / 1024 / 1024).toFixed(2)}
                  MB, Used:
                  {((memory.usedJSHeapSize ?? 0) / 1024 / 1024).toFixed(2)}
                  MB / Limit:{" "}
                  {((memory.jsHeapSizeLimit ?? 0) / 1024 / 1024).toFixed(2)}
                  MB
                </Typography>
                <Typography mt={2} variant="body1"></Typography>
              </Box>
            </Typography>
          )}
          {items.length > 0 && (
            <Box>
              <a
                href={download}
                download="download.txt"
                onClick={handleDownload}
              >
                ダウンロード
              </a>
              <Button
                variant="contained"
                sx={{ mt: 2, ml: 2, mr: 2 }}
                onClick={() => {
                  navigator.clipboard.writeText(items.join("\n"));
                }}
              >
                クリップボードにコピー
              </Button>
            </Box>
          )}
        </>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          bgcolor: "background.paper",
        }}
      >
        <FixedSizeList
          height={600}
          width={"100%"}
          itemSize={24}
          itemCount={items.length}
          overscanCount={20}
          itemData={items}
        >
          {row ?? renderRow}
        </FixedSizeList>
      </Box>
    </>
  );
};
