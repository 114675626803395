import { KonvaEventObject } from "konva/lib/Node";
import { useCallback } from "react";
import { WordObj } from "../gridToolType";

export const useTextDrag = ({
  wordList,
  setWordList,
  size,
  getPosFromEvent,
  initialCursor,
  initialPos,
  setInitialCursor,
  setInitialPos,
  setInitialPosList,
  dragging,
  setDragging,
}: {
  wordList: WordObj[];
  setWordList: React.Dispatch<React.SetStateAction<WordObj[]>>;
  size: number;
  getPosFromEvent: (e: KonvaEventObject<DragEvent>) => { x: number; y: number };
  initialCursor: { x: number; y: number };
  initialPos: { x: number; y: number };
  setInitialCursor: React.Dispatch<
    React.SetStateAction<{ x: number; y: number }>
  >;
  setInitialPos: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>;
  setInitialPosList: React.Dispatch<
    React.SetStateAction<{ x: number; y: number }[]>
  >;
  dragging: boolean;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
}): {
  handleTextDragStart: (index: number, e: KonvaEventObject<DragEvent>) => void;
  handleTextDragMove: (index: number, e: KonvaEventObject<DragEvent>) => void;
  handleTextDragEnd: (index: number, e: any) => void;
} => {
  /**
   * text drag handling
   * **/

  const handleTextDragStart = useCallback(
    (index: number, e: KonvaEventObject<DragEvent>) => {
      const coords = getPosFromEvent(e);
      setInitialCursor({ x: coords.x, y: coords.y });
      setInitialPos({ x: wordList[index].x, y: wordList[index].y });

      const node = e.target;
      const newInitialPosList = [];

      for (
        let charIndex = 0;
        charIndex < wordList[index].word.length;
        charIndex++
      ) {
        const labelNode = node.getStage()?.findOne(`#${index}-${charIndex}`);
        if (labelNode) {
          newInitialPosList.push({ x: labelNode.x(), y: labelNode.y() });
        }
      }
      setInitialPosList(newInitialPosList);
      setDragging(true);
    },
    [setInitialCursor, wordList]
  );

  const handleTextDragMove = useCallback(
    (index: number, e: KonvaEventObject<DragEvent>) => {
      if (!dragging) {
        return;
      }
      const coords = getPosFromEvent(e);
      const node = e.target;

      // カーソル座標を基にdiffを計算
      const diffX = coords.x - initialCursor.x;
      const diffY = coords.y - initialCursor.y;

      // 新しい座標リストを生成
      const newWordList = [...wordList];
      newWordList[index].x = initialPos.x + (diffX / size) * 2;
      newWordList[index].y = initialPos.y + (diffY / size) * 2;

      // すべてのラベルにこの変位を適用する
      for (
        let charIndex = 0;
        charIndex < newWordList[index].word.length;
        charIndex++
      ) {
        const labelNode = node.getStage()?.findOne(`#${index}-${charIndex}`);
        if (labelNode) {
          labelNode.opacity(0.5); // 透明度を変更
        }
      }
      setWordList(newWordList);
    },
    [initialPos, initialCursor, dragging, wordList, setWordList]
  );

  const handleTextDragEnd = useCallback(
    (index: number, e: any) => {
      setDragging(false);
      const newWordList = [...wordList];
      const node = e.target;

      newWordList[index].x = Math.round(newWordList[index].x);
      newWordList[index].y = Math.round(newWordList[index].y);

      // 全ての関連するラベルの透明度を元に戻す
      for (
        let charIndex = 0;
        charIndex < newWordList[index].word.length;
        charIndex++
      ) {
        let labelNode = node.getStage()?.findOne(`#${index}-${charIndex}`);
        if (labelNode) {
          labelNode.opacity(1); // 透明度を元に戻す
        }
      }

      setWordList(newWordList);
      setInitialPosList([]);
    },
    [setWordList, wordList]
  );

  return {
    handleTextDragStart,
    handleTextDragMove,
    handleTextDragEnd,
  };
};
