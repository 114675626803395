import { View } from "@react-pdf/renderer";
import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { PdfViewItem } from "../PdfViewItem";
import { StackData, StackVerticalData } from "../stackData";

export const Vertical: FC<{ content: StackData }> = ({ content }) => {
  return (
    <If condition={content.type === "vertical"}>
      <View
        style={{
          flexDirection: "column",
          width: (content as StackVerticalData).width,
          height: (content as StackVerticalData).height,
        }}
      >
        {(content as StackVerticalData).list?.map((child: StackData) => (
          <PdfViewItem
            content={child}
            key={child.keyId ?? `${content._index}_${child._index}`}
          />
        )) ?? <></>}
      </View>
    </If>
  );
};
