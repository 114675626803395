export class DataSource {
    source = {};
    get(key) {
        return this.source[key] ?? null;
    }
    getArray(key) {
        return [
            ...new Set(key.reduce((acc, cur) => {
                return acc.concat(this.source[cur] ?? []);
            }, [])),
        ];
    }
    getAll() {
        return this.source.all;
    }
    set(key, value) {
        this.source[key] = value;
    }
    delete(key) {
        delete this.source[key];
    }
}
