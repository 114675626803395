import { Button, Container, Grid, TextField } from "@mui/material";
import { FC, useCallback } from "react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { googleSheetApiKey } from "../../../../api/env/api";
import { Title } from "../../common/Title";

export const SheetSearch: FC = () => {
  const { id, sheetName } = useParams<{ id: string; sheetName: string }>();
  const [data, setData] = useState<string[]>([]);
  const [allData, setAllData] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const apiKey =
        process.env.REACT_APP_GOOGLE_SHEETS_API_KEY ?? googleSheetApiKey; // APIキー
      const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${sheetName}?key=${apiKey}`;

      try {
        const response = await fetch(sheetUrl);
        const json = await response.json();
        const rows = json.values;
        // ここでデータを適切な形式に変換します

        if (rows.length > 0) {
          // 最初の行を列ヘッダーとして使用
          const headers = rows[0] as string[];
          setColumns(
            headers.map((field: string) => ({
              field: field,
              headerName: field,
              width: 150,
            }))
          );

          // 残りの行をデータとして使用
          const dataRows = rows.slice(1).map((row: string[], index: number) => {
            const rowData: { [key: string]: string } = {};
            headers.forEach((header: string, i) => {
              rowData[header] = row[i];
            });
            return rowData;
          });

          setData(dataRows);
          setAllData(dataRows);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, sheetName]);

  const [searchInput, setSearchInput] = useState<string>("");

  const handleSearch = useCallback(() => {
    // 検索処理を実装してください
    setData(
      allData.filter((e) =>
        Object.values(e).some((e2) => new RegExp(searchInput).test(e2))
      )
    );
  }, [data, searchInput]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Container>
      <Title>{sheetName}</Title>
      <Grid>
        <Grid item>
          <TextField
            fullWidth
            label="キーワード"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            sx={{ width: 400, mt: 1, mr: 2 }}
          />
          <Button variant="contained" onClick={handleSearch} sx={{ mt: 1 }}>
            検索
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        rows={data}
        columns={columns}
        sx={{
          mt: 2,
          height: 800,
          width: "100%",
        }}
      />
    </Container>
  );
};
