import { Question } from "../events";

export const weekday = {
  id: "weekday",
  title: "カレンダー計算",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「2021年1月1日は何曜日？」→「金曜日」のように、
      <br />
      2000年-2099年の日付から曜日を答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「2021年1月1日は何曜日？」→「金曜日」のように、
      <br />
      2000年-2099年の日付から曜日を答えます。
      <br />
      全部で10問出題されます。
      <br />
      日〜土を1〜7として答えてください。
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () => {
    const questions: Question[] = [];

    const dates = [];
    for (let i = 0; i < 10; i++) {
      const date = new Date();
      date.setTime(
        new Date(2000, 0, 1).getTime() +
          1000 * 60 * 60 * 24 * Math.floor(Math.random() * 36524)
      );
      dates.push(date);
    }
    const now = new Date();
    // sort by abs diff from now
    dates.sort(
      (a, b) =>
        Math.abs(now.getTime() - a.getTime()) -
        Math.abs(now.getTime() - b.getTime())
    );

    for (let i = 0; i < 10; i++) {
      const date = dates[i];
      const question = {
        problemText: `${date.getFullYear()}年${
          date.getMonth() + 1
        }月${date.getDate()}日は何曜日？`,
        answers: [(1 + date.getDay()).toString()],
        errors: ["1", "2", "3", "4", "5", "6", "7"].filter(
          (x) => x !== (1 + date.getDay()).toString()
        ),
        guideText:
          "1.日曜日 2.月曜日 3.火曜日 4.水曜日 5.木曜日 6.金曜日 7.土曜日",
        type: "numeric" as const,
      };
      questions.push(question);
    }
    return questions;
  },
  shareButton: true,
  answerButton: false,
  hashTag: "カレンダー計算",
};
