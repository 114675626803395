//imagenateのMain.tsxから分離しうることを考慮して別ファイルとして立てる
//TODO Main.tsxをベースとして作成出来るようにする

import { FC } from "react";
import { randomKeyGen } from "../util/keygen";
import { MainProps } from "../imaginate/CommonMain";
import { If } from "../util/Condition";
import { PuzsqContestFolderView } from "./mainView/PuzsqContestFolderView";
import { PuzsqContestApp } from "./mainView/PuzsqContestApp";
import { PuzsqContestProblemFolderView } from "./mainView/PuzsqContestProblemFolderView";
import { PuzsqContestManagerView } from "./mainView/PuzsqContestManager";
import { PuzsqContestProblemItemView } from "./mainView/PuzsqContestProblemItem";

export const PuzsqContestMain: FC<MainProps> = (props) => {
  const { currentNode, updateNode, treeData, setSelectedNode, treeCommand } =
    props;

  if (!currentNode) {
    return <></>;
  }

  const createDocument = (type: string) => {
    if (type.endsWith("folder")) {
      updateNode({
        ...currentNode,
        droppable: true,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    } else {
      updateNode({
        ...currentNode,
        droppable: false,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    }
  };

  const workspace = "puzsq";
  const project = "dev";
  //Main.tsxから呼ぶ限りfileTypeがpuzsq-contest-***であることが前提のためこの分岐は実行されない

  if (!currentNode.data || currentNode.data.fileType === "") {
    return (
      <>
        {/*<If condition={!currentNode.droppable}>
          <App createDocument={createDocument} />
        </If>
        <If condition={currentNode.droppable}>
          <FolderView
            currentNode={currentNode}
            treeData={treeData}
            addApp={() => {
              treeCommand.addNode(currentNode.id, treeData);
            }}
            setSelectedNode={setSelectedNode}
          />
        </If>*/}
      </>
    );
  }

  return (
    <>
      <If condition={currentNode.data.fileType === "puzsq-contest-app"}>
        <PuzsqContestApp createDocument={createDocument} />
      </If>
      <If condition={currentNode.data.fileType === "puzsq-contest-folder"}>
        <PuzsqContestFolderView
          currentNode={currentNode}
          treeData={treeData}
          addApp={() => {
            treeCommand.addNode(currentNode.id, treeData, {
              type: "puzsq-contest-app",
            });
          }}
          setSelectedNode={setSelectedNode}
        />
      </If>
      <If
        condition={currentNode.data.fileType === "puzsq-contest-problem-folder"}
      >
        <PuzsqContestProblemFolderView
          currentNode={currentNode}
          treeData={treeData}
          addApp={() => {
            treeCommand.addNode(currentNode.id, treeData, {
              type: "puzsq-contest-problem-item",
            });
          }}
          setSelectedNode={setSelectedNode}
        />
      </If>
      <If condition={currentNode.data.fileType === "puzsq-contest-manager"}>
        <PuzsqContestManagerView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={project}
        />
      </If>
      <If
        condition={currentNode.data.fileType === "puzsq-contest-problem-item"}
      >
        <PuzsqContestProblemItemView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={project}
        />
      </If>
    </>
  );
};
