import { Grid } from "@mui/material";
import { FC, useRef } from "react";
import { GridKitView } from "./View/GridKitView";

export const GridKit: FC = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  return (
    <Grid
      container
      sx={{
        height: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "lightblue",
          height: "24px",
        }}
      >
        menu zone
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          height: "calc(100dvh - 96px)",
        }}
      >
        <Grid
          item
          sx={{
            backgroundColor: "lightgreen",
            height: "calc(100dvh - 96px)",
            width: "200px",
          }}
        >
          left zone
        </Grid>
        <Grid
          item
          container
          sx={{
            width: "calc(100vw - 600px)",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              height: "48px",
            }}
          >
            center top
          </Grid>
          <Grid
            sx={{
              width: "100%",
              height: "calc(100dvh - 196px - 48px)",
            }}
            ref={mainRef}
          >
            <GridKitView maxWidth={mainRef.current?.clientWidth ?? 640} maxHeight={
              mainRef.current?.clientHeight ?? 480
            } scale={1} />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              height: "100px",
            }}
          >
            center bottom
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "400px",
            backgroundColor: "lightcoral",
            height: "calc(100dvh - 96px)",
          }}
        >
          right zone
        </Grid>
      </Grid>
    </Grid>
  );
};
