import { Grid, Button } from "@mui/material";
import { Permutation, CartesianProduct } from "js-combinatorics";
import { FC, useContext } from "react";
import { factorizeNumbersInString } from "../../../../../../lib/mathematics/factorizationConvert";
import Mexp from "math-expression-evaluator";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { ConvertProps } from "./ConvertTemplate";
import { replaceSign } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const Calculator: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;
  const mexp = new Mexp();
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;

  return (
    <Grid>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            pattern
              .split("\n")
              .map((line) => {
                const replacedLine = replaceSign(line);
                try {
                  return line + " = " + mexp.eval(replacedLine, [], {});
                } catch (e) {
                  return line;
                }
              })
              .join("\n")
          );
        }}
      >
        計算
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(factorizeNumbersInString(pattern));
        }}
      >
        素因数分解
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            pattern.replace(/[0-9]+/g, (line) => {
              try {
                return BigInt(line).toString(2);
              } catch (e) {
                return line;
              }
            })
          );
        }}
      >
        10進数→2進数
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            pattern.replace(/[0-9]+/g, (line) => {
              try {
                return BigInt(line).toString(16);
              } catch (e) {
                return line;
              }
            })
          );
        }}
      >
        10進数→16進数
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            pattern.replace(/[0-9]+/g, (line) => {
              try {
                return BigInt("0b" + line).toString(10);
              } catch (e) {
                return line;
              }
            })
          );
        }}
      >
        2進数→10進数
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(
            pattern.replace(/[0-9]+/g, (line) => {
              try {
                return BigInt("0x" + line).toString(10);
              } catch (e) {
                return line;
              }
            })
          );
        }}
      >
        16進数→10進数
      </Button>
      <>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            const perm = new Permutation(pattern.split("\n"));
            setConverted(perm.toArray().join("\n"));
          }}
          disabled={plan === "normal"}
        >
          順列
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .map((line) => {
                  const perm = new Permutation(line);
                  return perm
                    .toArray()
                    .map((line) => line.join(""))
                    .join(",");
                })
                .join("\n")
            );
          }}
          disabled={plan === "normal"}
        >
          行順列
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            const cp = new CartesianProduct(
              ...pattern
                .split("\n")
                .map((line) =>
                  line.match(/[,|/]/) ? line.split(/[,|/]/) : line
                )
            );
            setConverted(cp.toArray().join("\n"));
          }}
          disabled={plan === "normal"}
        >
          直積
        </Button>
      </>
    </Grid>
  );
};
