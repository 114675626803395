import { Box, Button, Container, TextField } from "@mui/material";
import Konva from "konva";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Label, Layer, Line, Rect, Stage, Text } from "react-konva";
import { useStatePersist } from "use-state-persist";
import { Title } from "../../common/Title";
import { splitGrapheme } from "@langue-de-chat-llc/enigmastudio-algorithm";

type WordObj = {
  word: string;
  x: number;
  y: number;
  index: number;
  direction: "horizontal" | "vertical";
};

export const AATool: FC = () => {
  const [rawWordList, setRawWordList] = useStatePersist<string>(
    "aa-tool-raw-word-list",
    ""
  );
  const [wordList, setWordList] = useStatePersist<WordObj[]>(
    "aa-tool-word-list",
    []
  );

  const maxWidth = 868;
  const maxHeight = 868;

  const col = 32;
  const row = 32;
  const size = 48;
  const scale = 0.5;
  const offsetX = 100;
  const offsetY = 100;

  const [history, setHistory] = useStatePersist<
    { raw: string; word: WordObj[] }[]
  >("aa-tool-history", []);
  const [historyIndex, setHistoryIndex] = useStatePersist<number>(
    "aa-tool-history-index",
    -1
  );
  const [isUndoRedoAction, setIsUndoRedoAction] = useState<boolean>(false); // Undo or Redo 操作かどうか

  // rawWordList または wordList が変更されたら、履歴に追加
  useEffect(() => {
    if (dragging) {
      return;
    }
    if (isUndoRedoAction) {
      setIsUndoRedoAction(false);
      return;
    }
    if (historyIndex < history.length - 1) {
      const newHistory = history.slice(0, historyIndex + 1);
      setHistory(newHistory);
    }
    setHistory((prev) => [
      ...prev,
      { raw: rawWordList, word: structuredClone(wordList) },
    ]);
    setHistoryIndex((prev) => prev + 1);
  }, []);

  // Undo 処理
  const undo = useCallback(() => {
    if (historyIndex > 0) {
      setIsUndoRedoAction(true);
      const prevState = history[historyIndex - 1];
      setRawWordList(prevState.raw);
      setWordList(prevState.word);
      setHistoryIndex((prev) => prev - 1);
    }
  }, [history, historyIndex, rawWordList, wordList]);

  // Redo 処理
  const redo = useCallback(() => {
    if (historyIndex < history.length - 1) {
      setIsUndoRedoAction(true);
      const nextState = history[historyIndex + 1];
      setRawWordList(nextState.raw);
      setWordList(nextState.word);
      setHistoryIndex((prev) => prev + 1);
    }
  }, [history, historyIndex, rawWordList, wordList]);

  useEffect(() => {
    const newRawWordArray = rawWordList.split("\n");

    // 各行（単語）とその行数（y座標）をペアとして扱う
    const currentWordPairs = wordList.map((wordObj, index) => ({
      word: wordObj.word,
      index: wordObj.index,
    }));
    const newWordPairs = newRawWordArray.map((word, index) => ({
      word,
      index,
    }));

    // 追加された単語と行数のペアを見つける
    const addedWords = newWordPairs.filter(
      (newPair) =>
        !currentWordPairs.some(
          (currentPair) =>
            currentPair.word === newPair.word &&
            currentPair.index === newPair.index
        )
    );

    // 削除された単語と行数のペアを見つける
    const deletedWords = currentWordPairs.filter(
      (currentPair) =>
        !newWordPairs.some(
          (newPair) =>
            newPair.word === currentPair.word &&
            newPair.index === currentPair.index
        )
    );

    // 既存のwordListをコピーして作業する
    let newWordList = [...wordList];

    // 単語が削除された場合の処理
    for (const { word: deletedWord, index } of deletedWords) {
      newWordList = newWordList.filter(
        (wordObj) => !(wordObj.word === deletedWord && wordObj.index === index)
      );
    }

    // 単語が追加された場合の処理
    for (const { word: addedWord, index } of addedWords) {
      // 同じ行（y座標）で削除された単語を探す
      const replacedWordObj = wordList.find(
        (wordObj) => wordObj.index === index
      );

      if (replacedWordObj) {
        // 単語が変更されたとみなし、前の単語の属性を引き継ぐ
        newWordList.push({
          word: addedWord,
          x: replacedWordObj.x,
          y: replacedWordObj.y,
          index: replacedWordObj.index,
          direction: replacedWordObj.direction,
        });
      } else {
        // 新規追加された単語
        newWordList.push({
          word: addedWord,
          x: 1, // 仮の値
          y: index + 1,
          index,
          direction: "horizontal", // 仮の値
        });
      }
    }

    setWordList(newWordList);
  }, [rawWordList]);

  const [initialCursor, setInitialCursor] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [beforeCursor, setBeforeCursor] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [dragging, setDragging] = useState<boolean>(false);
  const handleDragStart = (e: any) => {
    setDragging(true);
    setInitialCursor({ x: e.evt.clientX, y: e.evt.clientY });
    setBeforeCursor({ x: e.evt.clientX, y: e.evt.clientY });
  };

  const handleDragMove = useCallback(
    (index: number, e: any) => {
      const node = e.target;

      // カーソル座標を基にdiffを計算
      const diffX = e.evt.clientX - beforeCursor.x;
      const diffY = e.evt.clientY - beforeCursor.y;

      // 新しい座標リストを生成
      const newWordList = [...wordList];
      newWordList[index].x += (diffX * 2) / size;
      newWordList[index].y += (diffY * 2) / size;

      // すべてのラベルにこの変位を適用する
      for (
        let charIndex = 0;
        charIndex < newWordList[index].word.length;
        charIndex++
      ) {
        let labelNode;
        if (newWordList[index].direction === "horizontal") {
          labelNode = node
            .getStage()
            ?.findOne(`#${index}-${charIndex}-horizontal`);
        } else {
          labelNode = node
            .getStage()
            ?.findOne(`#${index}-${charIndex}-vertical`);
        }
        if (labelNode) {
          labelNode.x(labelNode.x() + diffX);
          labelNode.y(labelNode.y() + diffY);
          labelNode.opacity(0.5); // 透明度を変更
        }
      }
      // 前回のカーソル座標を更新
      setBeforeCursor({ x: e.evt.clientX, y: e.evt.clientY });

      setWordList(newWordList);
    },
    [
      wordList,
      setWordList,
      size,
      setRawWordList,
      rawWordList,
      setBeforeCursor,
      beforeCursor,
    ]
  );
  const handleDragEnd = (index: number, e: any) => {
    setDragging(false);
    const newWordList = [...wordList];
    const node = e.target;

    // カーソル座標を基にdiffを計算
    const diffX = e.evt.clientX - beforeCursor.x;
    const diffY = e.evt.clientY - beforeCursor.y;

    // 新しい座標リストを生成
    newWordList[index].x += (diffX * 2) / size;
    newWordList[index].y += (diffY * 2) / size;

    newWordList[index].x = Math.round(newWordList[index].x);
    newWordList[index].y = Math.round(newWordList[index].y);

    // 全ての関連するラベルの透明度を元に戻す
    for (
      let charIndex = 0;
      charIndex < newWordList[index].word.length;
      charIndex++
    ) {
      let labelNode;
      if (newWordList[index].direction === "horizontal") {
        labelNode = node
          .getStage()
          ?.findOne(`#${index}-${charIndex}-horizontal`);
      } else {
        labelNode = node.getStage()?.findOne(`#${index}-${charIndex}-vertical`);
      }
      if (labelNode) {
        labelNode.opacity(1); // 透明度を元に戻す
      }
    }

    setWordList(newWordList);
  };

  const [blackList, setBlackList] = useState<
    {
      x: number;
      y: number;
    }[]
  >([]);

  const [imageData, setImageData] = useState<string>();
  const stageRef = useRef<Konva.Stage>(null);

  const calcCanvasRate = () => {
    if (!stageRef.current) {
      return;
    }
    const stage = stageRef.current;
    const ctx = stage.toCanvas().getContext("2d");
    if (!ctx) {
      return; // getContextがnullまたはundefinedの場合、処理を中断
    }
    const data = Array.from(Array(row).keys()).map((r) =>
      Array.from(Array(col).keys()).map((c) => {
        const pixel = ctx.getImageData(
          c * size * scale + 2 + offsetX * scale,
          r * size * scale + 2 + offsetY * scale,
          size * scale - 4,
          size * scale - 4
        );
        return Math.round(
          (pixel.data.reduce((acc, cur, index) => {
            if (index % 4 !== 3) {
              acc += 255 - cur;
            }
            return acc;
          }, 0) /
            ((pixel.data.length / 4) * 3) /
            255) *
            100
        );
      })
    );

    setImageData(data.map((row) => row.join("\t")).join("\n"));
  };

  useEffect(() => {
    calcCanvasRate();
  }, [rawWordList, wordList]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>AATool</Title>
      <Box>
        <Button variant="contained" onClick={undo} disabled={historyIndex <= 0}>
          元に戻す
        </Button>
        <Button
          variant="contained"
          onClick={redo}
          disabled={historyIndex >= history.length - 1}
        >
          やり直し
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            const ok = window.confirm("盤面をリセットしますか？");
            if (!ok) {
              return;
            }
            setRawWordList("");
            setWordList([]);
            setHistory([]);
            setHistoryIndex(-1);
          }}
        >
          リセット
        </Button>
      </Box>
      <Box>
        <TextField
          label="単語リスト"
          variant="outlined"
          sx={{ width: "80vw", mt: 2 }}
          rows={13}
          multiline
          value={rawWordList}
          onChange={(event) => {
            setRawWordList(event.target.value);
          }}
        />
      </Box>
      <Box>
        <TextField
          label="tier"
          variant="outlined"
          sx={{ width: "80vw", mt: 2 }}
          rows={13}
          multiline
          value={imageData}
        />
      </Box>
      <Stage
        width={maxWidth}
        height={maxHeight}
        scaleX={scale}
        scaleY={scale}
        ref={stageRef}
      >
        <Layer>
          <Rect
            x={0}
            y={0}
            width={maxWidth / scale}
            height={maxHeight / scale}
            fill="#fff"
            onClick={(e) => {
              const cursor = e.target.getStage()?.getPointerPosition();
              if (!cursor) {
                return;
              }
              const newBlackList = [...blackList];
              const x = Math.floor((cursor.x - 50) / size / scale);
              const y = Math.floor((cursor.y - 50) / size / scale);

              newBlackList.push({ x, y });
              setBlackList(newBlackList);
            }}
          />
          {blackList.map((black) => {
            return (
              <Rect
                x={black.x * size + 100}
                y={black.y * size + 100}
                width={size}
                height={size}
                fill="#000"
                onClick={(e) => {
                  const newBlackList = [...blackList];
                  const index = newBlackList.findIndex(
                    (black) => black.x === black.x && black.y === black.y
                  );
                  newBlackList.splice(index, 1);
                  setBlackList(newBlackList);
                }}
              />
            );
          })}
          {wordList.map((wordObj, index) => {
            if (wordObj.word.length === 0) {
              return null;
            }

            return splitGrapheme(wordObj.word).map((char, charIndex) => {
              let x, y;

              if (wordObj.direction === "horizontal") {
                x = (wordObj.x + charIndex) * size;
                y = wordObj.y * size;
              } else {
                // vertical
                x = wordObj.x * size;
                y = (wordObj.y + charIndex) * size;
              }

              return (
                <Label
                  key={`${index}-${charIndex}`}
                  x={x + 100} // 画面上での座標
                  y={y + 100} // 画面上での座標
                  width={size}
                  height={size}
                  draggable // ドラッグ可能にする
                  onDragStart={(e) => handleDragStart(e)}
                  onDragMove={(e) => handleDragMove(index, e)} // Handle drag move
                  onDragEnd={(e) => handleDragEnd(index, e)} // ドラッグが終了したときの処理
                  onDblClick={() => {
                    const newWordList = [...wordList];
                    newWordList[index].direction =
                      newWordList[index].direction === "horizontal"
                        ? "vertical"
                        : "horizontal";
                    setWordList(newWordList);
                  }}
                >
                  <Text
                    fontFamily="Arial"
                    fontSize={size * 0.9}
                    text={char}
                    fill={"#555"}
                    align="center"
                    verticalAlign="bottom"
                    width={size}
                    height={size}
                    fontStyle="bold"
                  />
                </Label>
              );
            });
          })}
          {Array.from(Array(row + 1).keys()).map((r) =>
            Array.from(Array(col).keys()).map((c) => (
              <Line
                points={[
                  c * size + 100,
                  r * size + 100,
                  c * size + 100 + size,
                  r * size + 100,
                ]}
                stroke={"#ccc"}
                strokeWidth={2}
              />
            ))
          )}
          {Array.from(Array(row).keys()).map((r) =>
            Array.from(Array(col + 1).keys()).map((c) => (
              <Line
                points={[
                  c * size + 100,
                  r * size + 100,
                  c * size + 100,
                  r * size + 100 + size,
                ]}
                stroke={"#ccc"}
                strokeWidth={2}
              />
            ))
          )}
        </Layer>
      </Stage>
    </Container>
  );
};
