import { Button, Checkbox, Grid, IconButton, TextField } from "@mui/material";
import { Container, Box } from "@mui/system";
import { FC, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Title } from "../../common/Title";
import DeleteIcon from "@mui/icons-material/Delete";
import { kanjiParts } from "../../../../data/kanji/kanji_parts";
import { splitGrapheme } from "@langue-de-chat-llc/enigmastudio-algorithm";

type KanjiPartsUnit = {
  parts: string[];
  cutConnect: boolean;
  cutOneStroke: boolean;
};
type KanjiPartsUnitForm = {
  parts: string;
  cutConnect: boolean;
  cutOneStroke: boolean;
};

export const KanjiInput: FC = () => {
  const [inputName, setInputName] = useState("");
  const [inputValue, setInputValue] = useState<KanjiPartsUnitForm[]>([
    {
      parts: "",
      cutConnect: false,
      cutOneStroke: false,
    },
  ]);
  const [onHoldName, setOnHoldName] = useState("");
  const outputJson = useMemo(() => {
    const normalizedInputName = splitGrapheme(inputName.trim())[0] ?? "";
    return (
      JSON.stringify({
        [normalizedInputName]: inputValue.map((item) => ({
          parts: splitGrapheme(item.parts),
          cutConnect: item.cutConnect,
          cutOneStroke: item.cutOneStroke,
        })),
      }).slice(1, -1) + ","
    );
  }, [inputName, inputValue]);

  const load = (newInputName?: string) => {
    const normalizedInputName = splitGrapheme(newInputName ?? inputName)[0] ?? "";
    const json = kanjiParts[normalizedInputName];
    if (json) {
      const value = json.map((item) => ({
        parts: item.parts.join(""),
        cutConnect: item.cutConnect,
        cutOneStroke: item.cutOneStroke,
      }));
      setInputValue(value);
    } else {
      setInputValue([
        {
          parts: "",
          cutConnect: false,
          cutOneStroke: false,
        },
      ]);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>漢字入力</Title>
      <Box mt={0}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <TextField
              fullWidth
              label="漢"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              sx={{ width: 400 }}
            />
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => {
                const newInputName = inputName.substring(1).trim();
                setInputName(newInputName);
                load(newInputName);
              }}
            >
              先頭削除
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => {
                const holdedName = splitGrapheme(inputName.trim())[0];
                if (holdedName) {
                  setOnHoldName(onHoldName + holdedName);
                }
                const newInputName = inputName.substring(1).trim();
                setInputName(newInputName);
                load(newInputName);
              }}
            >
              先頭保留
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => load()}
            >
              読み込み
            </Button>
          </Grid>
          {inputValue.map((item, i) => (
            <Grid item xs={12}>
              <IconButton
                edge="start"
                size="small"
                onClick={() =>
                  setInputValue((prev) => {
                    const newValue = [...prev];
                    newValue.splice(i, 1);
                    return newValue;
                  })
                }
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
              <TextField
                label="パーツ"
                value={inputValue[i].parts}
                onChange={(e) => {
                  setInputValue((prev) => {
                    const newValue = [...prev];
                    newValue[i].parts = e.target.value;
                    return newValue;
                  });
                }}
              />
              <Checkbox
                key={"check-cut-connect" + i}
                checked={inputValue[i].cutConnect}
                onChange={(e) => {
                  setInputValue((prev) => {
                    const newValue = [...prev];
                    newValue[i].cutConnect = e.target.checked;
                    return newValue;
                  });
                }}
                aria-label="連結・部首相当を分解"
              />
              連結・部首相当を分解
              <Checkbox
                key={"check-one-stroke-" + i}
                checked={inputValue[i].cutOneStroke}
                onChange={(e) => {
                  setInputValue((prev) => {
                    const newValue = [...prev];
                    newValue[i].cutOneStroke = e.target.checked;
                    return newValue;
                  });
                }}
              />
              一画を分解
            </Grid>
          ))}
          <Grid item xs={1}>
            <IconButton
              size="small"
              onClick={() => {
                setInputValue([
                  ...inputValue,
                  {
                    parts: "",
                    cutConnect: false,
                    cutOneStroke: false,
                  },
                ]);
              }}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
        <Box maxWidth={"80%"}>
          <TextField
            label="JSON"
            variant="outlined"
            sx={{ width: "80vw", mt: 2 }}
            multiline
            rows={10}
            value={outputJson}
            contentEditable={false}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ mt: 2, ml: 2, mr: 2 }}
          onClick={() => {
            navigator.clipboard.writeText(outputJson);
          }}
        >
          クリップボードにコピー
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="保留リスト"
          value={onHoldName}
          onChange={(e) => setOnHoldName(e.target.value)}
          sx={{ width: 400 }}
          contentEditable={false}
        />
      </Box>
    </Container>
  );
};
