import { Container } from "@mui/system";
import { WordList } from "./WordList";
import { Title } from "../../common/Title";

export const WordGeneratorContainer = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>ワードジェネレータ</Title>
      <WordList />
    </Container>
  );
};
