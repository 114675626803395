import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { SvgComponents } from "../../pdf/svg/SvgComponents";
import { StackData, StackSvgData } from "../stackData";

export const Svg: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "svg"}>
      <SvgComponents {...(contents as StackSvgData)} />
    </If>
  );
};
