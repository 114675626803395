"use client";
import { useContext, useState } from "react";
import {
  EniverseFirebaseConfig,
  FirebaseContext,
} from "./config/firebaseConfig";
import {
  SiteConfigProps,
  SiteContext,
} from "./config/siteConfig";

type EniverseProviderProps = {
  initialSiteConfig: SiteConfigProps;
  initialFirebaseConfig: EniverseFirebaseConfig;
  children: React.ReactNode;
};

export function EniverseProvider({
  initialSiteConfig,
  initialFirebaseConfig,
  children,
}: EniverseProviderProps) {
  const [siteConfig, setSiteConfig] = useState<SiteConfigProps>(
    initialSiteConfig
  );
  const [firebaseConfig, setFirebaseConfig] =
    useState<EniverseFirebaseConfig>(initialFirebaseConfig);
  return (
    <SiteContext.Provider value={[siteConfig, setSiteConfig]}>
      <FirebaseContext.Provider value={[firebaseConfig, setFirebaseConfig]}>
        {children}
      </FirebaseContext.Provider>
    </SiteContext.Provider>
  );
}

export function useSiteConfig() {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error("useSiteConfig must be used within a SiteProvider");
  }
  return context;
}

export function useFirebaseConfig() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebaseConfig must be used within a FirebaseProvider");
  }
  return context;
}
