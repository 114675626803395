import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import { FC, useState } from "react";
import { MdContainer } from "../siteKit/uiKit/Container/Container";

type Props = {
  config: {
    sheetUrl: string;
  };
  setConfig: (config: { sheetUrl: string }) => void;
  saveConfigData: () => Promise<void>;
};
export const StackSpaceConfigView: FC<Props> = ({
  config,
  setConfig,
  saveConfigData,
}) => {
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          保存しました
        </Alert>
      </Snackbar>
      <Box sx={{ my: 2 }} />
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          setChanged(false);
          await saveConfigData();
          handleClick();
        }}
        sx={{ my: 1 }}
        disabled={!changed}
      >
        下書き保存
      </Button>
      <Box sx={{ my: 4 }} />
      <TextField
        key={"sheetUrl"}
        label="スプレッドシートURL"
        value={config.sheetUrl}
        onChange={(e) => {
          setConfig({ ...config, sheetUrl: e.target.value });
          setChanged(true);
        }}
      />
    </>
  );
};
