type QuestionPosition = {
  visible: boolean;
  top?: number;
  left?: number;
  width?: number;
  height?: number;
};

export const questionPosition: QuestionPosition[] = [
  {
    visible: false,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.16,
    height: 0.16,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.16,
    height: 0.16,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.15,
    height: 0.15,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.41,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.12,
    height: 0.12,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.15,
    height: 0.15,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.01,
    left: 0.012,
    width: 0.13,
    height: 0.13,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.15,
    height: 0.15,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.01,
    width: 0.15,
    height: 0.15,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.89,
    width: 0.17,
    height: 0.17,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.015,
    left: 0.015,
    width: 0.18,
    height: 0.18,
  },
  {
    visible: true,
    top: 0.01,
    left: 0.0245,
    width: 0.218,
    height: 0.218,
  },
];
