import { Question, QuestionFormType, SprintAnswerEvent } from "../events";
import { createTake } from "../take";

export const misezan: SprintAnswerEvent = {
  id: "misezan",
  title: "一桁の見せ算",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      6 見せ 9 のように2つの数字を見せ合い
      <br />
      「どう思うか」を考える、「見せ算」の練習問題です。
    </>
  ),
  descriptions: [
    <p key={0}>
      M-1 グランプリ2023でさや香によって発表された、
      <br />
      6 見せ 9 =
      11のように、2つの数字を見せ合い「どう思うか」を考える「見せ算」の練習問題です。
      <br />
      一桁同士の見せ算の眼(答え)を入力してください。
      <br />
      全部で20問出題されます。
      <br />
    </p>,
  ],
  descriptions2: [
    <p key={0}>
      【計算のルール】
      <br />
      M-1 2023で披露されたルールに対応しています。
      見る数と見られる数は入れ替えても同じ答えとしています。
      <br />
      <br />
      〈基本のルール〉
      <br />
      1見せ1=0
      <br />
      同じ数字を見せると0になる。
      <br />
      （自分と同じ格好の人を見ると気まずくなりその場を離れるため。）
      <br />
      1見せ2=2
      <br />
      小さい数字に大きい数字を見せると大きい数字が残る。
      <br />
      （大きい人を見るとこわくて逃げたくなるため。）
      <br />
      <br />
      〈似た形状の数字の組についての応用ルール〉
      <br />
      6見せ9=11
      <br />
      （お互いが生き別れの兄弟と勘違いして近づいてしまうため。）
      <br />
      2見せ5=1.1
      <br />
      （お互いが生き別れの兄弟と勘違いして近寄るがよく見ると全然違うことに気付きびっくりして携帯「.」を落としてしまうため。）
      <br />
      <br />
      (他の数については基本ルールを適用してください。)
    </p>,
  ],
  questions: [],
  submit: false,
  hintOpenTime: 10,
  retry: true,
  shareButton: true,
  createQuestions: () => {
    const questions: Question[] = [];
    const questions2: Question[] = [];

    for (let i = 1; i <= 9; i++) {
      for (let j = i; j <= 9; j++) {
        const randomSwap = Math.random() > 0.5;
        const problemText1 = randomSwap
          ? `${i} 見せ ${j} = ？`
          : `${j} 見せ ${i} = ？`;
        const problemText2 = randomSwap
          ? `${j} 見せ ${i} = ？`
          : `${i} 見せ ${j} = ？`;
        const answer =
          i === j
            ? String(0)
            : i === 2 && j === 5
            ? "1.1"
            : i === 6 && j === 9
            ? "11"
            : String(Math.max(i, j));

        const question = {
          problemText: problemText1,
          answers: [answer],
          type: "decimal" as QuestionFormType,
        };

        if ((i === 2 && j === 5) || (i === 6 && j === 9)) {
          questions2.push({
            problemText: problemText2,
            answers: [answer],
            type: "decimal" as QuestionFormType,
          });
        }
        questions.push(question);
      }
    }
    return createTake(createTake(questions, 18).concat(questions2), 20);
  },
  hashTag: "一桁の見せ算",
};
