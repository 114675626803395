import { SprintAnswerEvent } from "../events";
import { rawDepacos } from "../rawData/depacos2023";
import { createTake } from "../take";

export const depacos2023: SprintAnswerEvent = {
  id: "depacos2023",
  title: "2023年デパコスのベストセラー",
  category: "ランダム出題",
  addMenu: false,
  menuDescription: (
    <>
      SNSでバズった、記録的なセールスを残した…など、各デパコスブランドで2023年にいちばん人気があったコスメを徹底リサーチ。
      <br />
      PR担当者のコメントとともにご紹介！
    </>
  ),
  descriptions: [
    <p key={0}>
      SNSでバズった、記録的なセールスを残した…など、
      <br />
      各デパコスブランドで2023年にいちばん人気があったコスメを徹底リサーチ。
      <br />
      PR担当者のコメントとともにご紹介！
      <br />
      ネタ元:{" "}
      <a href="https://www.elle.com/jp/beauty/makeup-skincare/g46135936/2023-best-seller-cosmetics-231215-hns/">
        ELLE 23/12/15
      </a>
      <br />
      <br />
      説明からブランド名を答えてください。
      <br />
      全27問の中から10問出題されます。
      <br />
      <br />
      ！を入力することで解答を表示できます。(参考記録扱い)
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  hintOpenTime: 5,
  createQuestions: () =>
    createTake(
      rawDepacos.map((e) => ({
        problemText: `${e.title}`,
        // hint: e.description,
        answers: e.brand.split(","),
      })),
      10
    ),
  answerButton: true,
  shareButton: false,
  textStyle: { variant: "h6" },
};