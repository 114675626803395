import { FC, Fragment, useMemo } from "react";
import { useDoc } from "../../../_lib/eniverse/storekit/firestore/pure/useDoc";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { PlainText } from "../common/PlainText";
import { useMultipleDoc } from "../../../_lib/eniverse/storekit/firestore/pure/useMultipleDoc";
import { Title } from "../common/Title";
import { Container } from "@mui/material";
import {
  CommonMenuItem,
  MenuItem,
} from "../Studio/Menu/CommonMenuItem";
import { useSiteConfig } from "../../../_lib/eniverse/EniverseProvider";

type UserEnv = {
  project: string[];
};

type Project = {
  name: string;
  menu?: MenuItem[];
  id: string;
};
export const getProjectPath = (service: string, pj: string) =>
  `workspace/${service}/project/${pj}`;

export const Projects: FC<{}> = () => {
  const { user } = useFirebaseLogin();
  const [{ serviceId }] = useSiteConfig();
  const [userEnv] = useDoc<UserEnv>({
    path: user?.uid ? `workspace/${serviceId}/userEnv/${user?.uid}` : null,
  });
  const pathList = useMemo(
    () => userEnv?.project.map((pj) => getProjectPath(serviceId, pj)),
    [serviceId, userEnv?.project]
  );

  const [dataTable] = useMultipleDoc<Project>({
    pathList,
  });

  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      {userEnv ? (
        (userEnv?.project?.length ?? 0) > 0 ? (
          userEnv.project.map((pj) => {
            const pjPath = getProjectPath(serviceId, pj);
            return dataTable[pjPath] && dataTable[pjPath].data ? (
              <Fragment key={pj}>
                <Title>{dataTable[pjPath].data.name}</Title>
                {(dataTable[pjPath].data?.menu ?? []).map((item, i) => {
                  const newItem = {
                    ...item,
                    path: `/projects/${pj}/${item.path}`,
                  };
                  return <CommonMenuItem menuItem={newItem} key={i} />;
                })}
              </Fragment>
            ) : (
              <PlainText key={pj}>Loading... {pj}</PlainText>
            );
          })
        ) : (
          <>
            <PlainText>
              このアカウントに割り当てられたプロジェクトは存在しません。
            </PlainText>
            <PlainText>ログインしているアカウントをご確認ください。</PlainText>
          </>
        )
      ) : (
        <PlainText>プロジェクト機能の利用にはログインが必要です。</PlainText>
      )}
    </Container>
  );
};
