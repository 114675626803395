// @ts-nocheck
import QuadraticSeive from "quadraticsievefactorization/QuadraticSieveFactorization";
import isPrime from "quadraticsievefactorization/isPrime";

const primeFactors = (n: bigint): bigint[] => {
  const factors: bigint[] = [];
  // 2で割れるだけ割る
  while (n % 2n === 0n) {
    factors.push(2n);
    n = n / 2n;
  }

  // 奇数で割っていく
  for (let i = 3n; i * i <= n; i += 2n) {
    while (n % i === 0n) {
      factors.push(i);
      n = n / i;
    }
  }

  // 残った数が素数の場合
  if (n > 2) {
    factors.push(n);
  }

  return factors;
};

export default function quadraticSeive(int: bigint) {
  const ret = [];
  while (int !== 1n) {
    const f = BigInt(isPrime(int) ? int : QuadraticSeive(int));
    ret.push(f);
    int /= f;
  }
  return ret;
}

export const factorizeNumbersInString = (input: string): string => {
  return input
    .split("\n")
    .map((line) => {
      try {
        const num = BigInt(line);
        const factors = num < 1000000 ? primeFactors(num) : quadraticSeive(num);
        return `${line} = ${factors
          .sort((a, b) => (a < b ? -1 : 1))
          .map((e) => e.toString(10))
          .join(" * ")}`;
      } catch (e) {
        return line;
      }
    })
    .join("\n");
};
