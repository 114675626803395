import {
  Container,
  Stack
} from "@mui/material";
import { FC } from "react";
import { CommonBreadcrumbs } from "../../common/CommonBreadcrumbs";
import { Title } from "../../common/Title";
import { CommonMenuItem, MenuItem } from "./CommonMenuItem";

export type CommonMenuProps = {
  title: string;
  menuItems: MenuItem[];
  breadcrumbs?: {
    title: string;
    path: string;
  }[];
};

export const CommonMenu: FC<CommonMenuProps> = ({
  title,
  menuItems,
  breadcrumbs,
}) => {
  return (
    <>
      <CommonBreadcrumbs breadcrumbs={breadcrumbs} />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Title>{title}</Title>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {menuItems.map((item, i) => (
            <CommonMenuItem menuItem={item} key={item.path} />
          ))}
        </Stack>
      </Container>
    </>
  );
};
