import { FC, useCallback, useEffect, useState } from "react";
import { CommonAppProp } from "../../../../../_lib/eniverse/imaginate/mainView/CommponAppProp";
import { ListResult, ref, listAll, getDownloadURL } from "firebase/storage";
import { useFirebaseConfig } from "../../../../../_lib/eniverse/EniverseProvider";
import { CustomData } from "../../../../../_lib/eniverse/imaginate/treeView/types";
import { NodeModel } from "@minoru/react-dnd-treeview";

type PanTokigamiItemViewProps = CommonAppProp & {
  treeData: NodeModel<CustomData>[];
};
const rootPath = "https://storage.googleapis.com/enigma-workstudio.appspot.com";
export const KachidokiProblemView: FC<PanTokigamiItemViewProps> = (props) => {
  const { currentNode, updateNode, treeData } = props;
  const [selectedFiles, setSelectedFiles] = useState<File[] | undefined>([]);
  const [storageList, setStorageList] = useState<ListResult | null>(null);
  const [{ cloudStorage }] = useFirebaseConfig();

  console.log(treeData, currentNode);
  const imageLibrary = treeData.find(
    (e) =>
      e.data?.fileType === "image-library" && currentNode.parent === e.parent
  );
  const getStorageList = useCallback(() => {
    console.log(imageLibrary);
    const path = imageLibrary?.data?.contentsId;
    if (!path) return;
    if (!cloudStorage) return;
    const storageRef = ref(cloudStorage, "" + path);
    listAll(storageRef).then((res) => {
      res.items.forEach((item) => {
        getDownloadURL(item).then((url) => console.log(url));
      });
      setStorageList(res);
    });
  }, [cloudStorage, imageLibrary]);

  useEffect(() => {
    getStorageList();
  }, [currentNode, getStorageList]);

  console.log(storageList, storageList?.items);
  return (
    <>
      {storageList?.items.map((e) => {
        return (
          <p key={e.name}>
            {rootPath}/{imageLibrary?.data?.contentsId}/
            {encodeURIComponent(e.name)}
          </p>
        );
      })}
    </>
  );
};
