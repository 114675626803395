export const pokemon7 = `モクロー
フクスロー
ジュナイパー
ニャビー
ニャヒート
ガオガエン
アシマリ
オシャマリ
アシレーヌ
ツツケラ
ケララッパ
ドデカバシ
ヤングース
デカグース
アゴジムシ
デンヂムシ
クワガノン
マケンカニ
ケケンカニ
オドリドリ
アブリー
アブリボン
イワンコ
ルガルガン
ヨワシ
ヒドイデ
ドヒドイデ
ドロバンコ
バンバドロ
シズクモ
オニシズクモ
カリキリ
ラランテス
ネマシュ
マシェード
ヤトウモリ
エンニュート
ヌイコグマ
キテルグマ
アマカジ
アママイコ
アマージョ
キュワワー
ヤレユータン
ナゲツケサル
コソクムシ
グソクムシャ
スナバァ
シロデスナ
ナマコブシ
タイプ：ヌル
シルヴァディ
メテノ
ネッコアラ
バクガメス
トゲデマル
ミミッキュ
ハギギシリ
ジジーロン
ダダリン
ジャラコ
ジャランゴ
ジャラランガ
カプ・コケコ
カプ・テテフ
カプ・ブルル
カプ・レヒレ
コスモッグ
コスモウム
ソルガレオ
ルナアーラ
ウツロイド
マッシブーン
フェローチェ
デンジュモク
テッカグヤ
カミツルギ
アクジキング
ネクロズマ
マギアナ
マーシャドー
ベベノム
アーゴヨン
ツンデツンデ
ズガドーン
ゼラオラ`;
