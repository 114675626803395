import { Dispatch, SetStateAction, FC } from "react";
import {
  WordObj,
  eightDirection,
  fourDirection,
  twoDirection,
} from "../gridToolType";
import { Label, Text } from "react-konva";
import { ModeType } from "../Menu/ModeSelector";
import { KonvaEventObject } from "konva/lib/Node";
import { directionToPos } from "../direction";
import { splitGrapheme } from "@langue-de-chat-llc/enigmastudio-algorithm";

type WordListViewProps = {
  displayText: boolean;
  wordList: WordObj[];
  setWordList: Dispatch<SetStateAction<WordObj[]>>;
  size: number;
  mode: ModeType | null;
  handleTextDrag: {
    handleTextDragStart: (
      index: number,
      e: KonvaEventObject<DragEvent>
    ) => void;
    handleTextDragMove: (index: number, e: KonvaEventObject<DragEvent>) => void;
    handleTextDragEnd: (index: number, e: any) => void;
  };
  gridOffsetX: number;
  gridOffsetY: number;
  directionValue: string;
};

export const WordListView: FC<WordListViewProps> = ({
  displayText,
  wordList,
  setWordList,
  size,
  mode,
  handleTextDrag,
  gridOffsetX,
  gridOffsetY,
  directionValue,
}) => {
  const { handleTextDragStart, handleTextDragMove, handleTextDragEnd } =
    handleTextDrag;

  return (
    <>
      {displayText &&
        wordList.map((wordObj, index) => {
          if (wordObj.word.length === 0) {
            return null;
          }

          return splitGrapheme(wordObj.word).map((char, charIndex) => {
            let x, y;

            if (wordObj.direction === "right") {
              x = (wordObj.x + charIndex) * size;
              y = wordObj.y * size;
            } else if (wordObj.direction === "right-down") {
              x = (wordObj.x + charIndex) * size;
              y = (wordObj.y + charIndex) * size;
            } else if (wordObj.direction === "down") {
              x = wordObj.x * size;
              y = (wordObj.y + charIndex) * size;
            } else if (wordObj.direction === "left-down") {
              x = (wordObj.x - charIndex) * size;
              y = (wordObj.y + charIndex) * size;
            } else if (wordObj.direction === "left") {
              x = (wordObj.x - charIndex) * size;
              y = wordObj.y * size;
            } else if (wordObj.direction === "left-up") {
              x = (wordObj.x - charIndex) * size;
              y = (wordObj.y - charIndex) * size;
            } else if (wordObj.direction === "up") {
              x = wordObj.x * size;
              y = (wordObj.y - charIndex) * size;
            } else if (wordObj.direction === "right-up") {
              x = (wordObj.x + charIndex) * size;
              y = (wordObj.y - charIndex) * size;
            } else {
              x = (wordObj.x + charIndex) * size;
              y = wordObj.y * size;
            }

            return (
              <Label
                key={`${index}-${charIndex}`}
                x={x + gridOffsetX} // 画面上での座標
                y={y + gridOffsetY} // 画面上での座標
                width={size}
                height={size}
                draggable={mode === "text"} // ドラッグ可能にする
                onDragStart={
                  mode === "text"
                    ? (e) => {
                        handleTextDragStart(index, e);
                        e.cancelBubble = false;
                        return false;
                      }
                    : undefined
                }
                onDragMove={
                  mode === "text"
                    ? (e) => {
                        handleTextDragMove(index, e);
                        e.cancelBubble = false;
                        return false;
                      }
                    : undefined
                } // Handle drag move
                onDragEnd={
                  mode === "text"
                    ? (e) => {
                        handleTextDragEnd(index, e);
                        e.cancelBubble = false;
                        return false;
                      }
                    : undefined
                } // ドラッグが終了したときの処理
                onDblClick={(e) => {
                  if (mode !== "text") {
                    return;
                  }
                  e.cancelBubble = false;
                  const newWordList = [...wordList];
                  const beforeDir = newWordList[index].direction;
                  if (directionValue === "two") {
                    const nextDirectionIndex =
                      (twoDirection.indexOf(newWordList[index].direction) + 1) %
                      2;
                    newWordList[index].direction =
                      twoDirection[nextDirectionIndex];
                    const dir = newWordList[index].direction;
                    newWordList[index].x =
                      newWordList[index].x -
                      charIndex *
                        (directionToPos(dir).x - directionToPos(beforeDir).x);
                    newWordList[index].y =
                      newWordList[index].y -
                      charIndex *
                        (directionToPos(dir).y - directionToPos(beforeDir).y);
                  }
                  if (directionValue === "four") {
                    const nextDirectionIndex =
                      (fourDirection.indexOf(newWordList[index].direction) +
                        1) %
                      4;
                    newWordList[index].direction =
                      fourDirection[nextDirectionIndex];
                    const dir = newWordList[index].direction;
                    newWordList[index].x =
                      newWordList[index].x -
                      charIndex *
                        (directionToPos(dir).x - directionToPos(beforeDir).x);
                    newWordList[index].y =
                      newWordList[index].y -
                      charIndex *
                        (directionToPos(dir).y - directionToPos(beforeDir).y);
                  }
                  if (directionValue === "eight") {
                    const nextDirectionIndex =
                      (eightDirection.indexOf(newWordList[index].direction) +
                        1) %
                      8;
                    newWordList[index].direction =
                      eightDirection[nextDirectionIndex];
                    const dir = newWordList[index].direction;
                    newWordList[index].x =
                      newWordList[index].x -
                      charIndex *
                        (directionToPos(dir).x - directionToPos(beforeDir).x);
                    newWordList[index].y =
                      newWordList[index].y -
                      charIndex *
                        (directionToPos(dir).y - directionToPos(beforeDir).y);
                  }
                  setWordList(newWordList);
                }}
              >
                <Text
                  fontFamily="Arial"
                  fontSize={size * 0.9}
                  text={char}
                  fill={mode === "text" || mode === "save" ? "#333" : "#888"}
                  align="center"
                  verticalAlign="bottom"
                  width={size}
                  height={size}
                  fontStyle="bold"
                />
              </Label>
            );
          });
        })}
    </>
  );
};
