export const rawDepacos = [
  {
    brand: "シャネル,しゃねる",
    title:
      "ル ブラン ブライトニング コンパクト 12g ￥10,230、リフィル 12g ￥7,590",
    description:
      "「今年の上期にリニューアル発売された、XXXのベストセラーファンデーションです。パウダータイプでも粉感を感じさせず、水をたたえたようなツヤのある仕上がりはそのままに、新配合されたガーデニア オイルが肌のうるおいと透明感を保ちます。ソフト ピンクのコンパクトも、バッグに入れて持ち運びたいという声が多く、とても好評です」",
  },
  {
    brand: "ディオール,でぃおーる",
    title: "XXX アディクト リップ マキシマイザー 001 ￥4,620",
    description:
      "「数々の伝説を生んだXXXを代表するアイテム『リップ マキシマイザー』が、2023年に誕生から16年目にして初のリニューアル。90%自然由来成分配合、洗練されたクチュール仕様のケース、幅広いシェード展開、うるおい効果など、さらに魅力を高めて登場しました。なかでも王道ピンクの『001』は、23色のシェードのなかでもマストアイテムとして2023年1月の発売以来、いちばん人気。マスク着用が解禁された今年、ツヤのある仕上がりのリップスティックやプランパーの人気が戻ったことを実感しています」",
  },
  {
    brand: "エルメス,えるめす",
    title: "オンブル ドゥ XXX パレット クワテュール 03 ￥14,300",
    description:
      "「組み合わせを変えるたびに、新たな可能性に出合えるアイシャドウ。『03 オンブル・フォーヴ』は、透明感のある発色で重ね塗りして濃淡を調整することが可能なので、思い描いた色を楽しめます。原材料の72％～98％が天然由来成分で構成されているのも魅力。ソフトで軽やかなつけ心地で塗りやすく、長時間密着して落ちにくいと評判です」",
  },
  {
    brand: "ルナソル,るなそる",
    title: "アイカラーレーション 15",
    description:
      "「2022年に限定からの定番復刻で話題となり、2023年に入ってからもアイカラーレーション15の人気が継続。現在、XXXのアイシャドウのなかでいちばん人気のカラーです。ダイヤモンドのような鮮烈な透明感と輝きでありながらまぶたに寄り添い、その人自身の美しさを引き立てる仕上がりで、『塗る宝石』『尊い』というコメントをいただきました。華やかにも洗練された印象にも仕上げられるため、『絶対に1人1つは持っていて間違いない』とお客さま同士でおすすめしていただくなど、うれしいお声をいただいています」",
  },
  {
    brand: "イヴ・サンローラン,いゔさんろーらん",
    title: "クチュール ミニ クラッチ #400 ￥9,900",
    description:
      "「2023年8月の全国発売を前に、『ダイヤモンドのようなきらめき』とSNSでもバズり話題になっていたアイシャドウパレット。どのシェードも捨て色のない絶妙な配色ですが、センシュアルなローズ系のシェード『#400』は特に大人気で、一時は完売入荷待ちの状態が続いてしまったほど！ 登場して間もなく、YSLのアイコンアイテムとなりました」",
  },
  {
    brand: "コスメデコルテ,こすめでこるて",
    title: "リポソーム アドバンスト リペアセラム 50ml ￥12,100",
    description:
      "「1滴に1兆個の“多重層バイオリポソーム”がダイレクトに肌に成り代わるように溶け込み、うるおいに満ちた、ハリ・ツヤあふれる若々しい印象に導くエイジングケア美容液。発売後2年で222万本を突破。発売から24カ月連続（2021年10月〜2023年9月)で全国百貨店にて美容液の売り上げNo.1です。（Beauté Research調べ）」",
  },
  {
    brand: "ナーズ,なーず",
    title: "アフターグロー センシュアルシャイン リップスティック 888 ￥4,400",
    description:
      "「予想の2倍の売り上げを記録したリップスティックです。美容家、ヘア＆メイクアップ・アーティスト、著名人の方に、発売当初から愛用いただき、YouTubeなどSNSでもご紹介いただきました。“美容垢”のSNSでは『ちゅやんとした仕上がり』 『うるつや感半端ない』『この色みを超えるリップに出合ったことがない』など、反響をいただいています！」",
  },
  {
    brand: "M·A·C,まっく",
    title: "ラスターガラス リップスティック ビジネス カジュアル ￥4,400",
    description:
      "「ブランド内のリップスティックカテゴリーで、2023年売り上げNo.1のリップスティック。シアーなツヤ発色と、植物性オイルとヒアルロン酸による高い保湿力で、ちゅるんとした、うるおいのある唇を叶える大人気アイテムです。なかでも『ビジネス カジュアル』は、『顔色がパッと明るく見える』『パーソナルカラーやシチュエーションを問わず使える』『上品だけどかしこまりすぎていない』などと、その絶妙な発色で特に人気のカラーです」",
  },
  {
    brand: "ジルスチュアート　ビューティ,じるすちゅあーとびゅーてぃ",
    title: "ブルームクチュール アイズ　ジュエルドブーケ 04 ￥6,380",
    description:
      "「『ブルームクチュール アイズ』シリーズより登場した発色の濃密さと、上品な輝きが特長の新ラインとなるジュエルドブーケ。『04 calandiva garnet』はシリーズ史上、予約数が最多の人気カラーです。ミステリアスで濃密な雰囲気がありながら、やわらかさもあるボルドーモーヴは、一見華やかな印象のパレットですが、肌なじみがよく使いやすい色味とお客さまからも好評です」",
  },
  {
    brand: "RMK,あーるえむけー",
    title: "ピュア コンプレクション ブラッシュ 10 ￥3,630",
    description:
      "「粉っぽさを感じさせないシルキーな感触で、しっとり肌と一体化するブラッシュ。この『10 サハラ ベージュ』は、素肌まで美しく魅せる透明感のある発色とリアルな血色感で、指名買いが殺到。再入荷しても、すぐ品切れてしまうほどの人気カラーです。甘さを添える上品なツヤと色づきでどんなメイクにも合いやすく、美肌映えするととても好評です」",
  },
  {
    brand: "ランコム,らんこむ",
    title: "ジェニフィック アドバンスト N 30ｍl ￥11,990、 50ml ￥16,940",
    description:
      "「15年にもおよぶ美肌菌研究により誕生した、大人気の美容液。洗顔後にプラスするだけで肌コンディションを底上げしてバリア機能を高め、肌ダメージからの回復をサポートします。リピート率も高く、現在も5秒（※1）に1本売れているXXXNo.1美容液（※2）。さらに、ベストコスメ受賞歴は驚異の198冠（雑誌・WEB等のメディアにおける2009年~2023年11月のベストコスメ相当賞受賞総数／リニューアル前を含む)という、名実ともにブランドを代表するベストセラー製品です」",
  },
  {
    brand: "クリスチャン ルブタン ビューティ,くりすちゃんるぶたんびゅーてぃ",
    title: "ルビベル ルージュXXX 001O 4ml ￥10,450",
    description:
      "「エモリエント効果の高いホホバオイルが配合された、軽くてさらりとしたテクスチャーのビューティリップオイル。甘美でセンシュアルなぽってりとしたリップを演出できる、人気のアイテムです。アイコニックなルージュルブタンカラーで唇を包み込み、輝きを与え、柔らかくうるおいのあふれる唇になれます。単色使いはもちろん、リップスティックに重ねるとより大胆な輝きと立体感をプラスできるので、ギフトにもおすすめです」",
  },
  {
    brand: "スック,すっく",
    title: "ザ ファンデーション SPF30・PA+++ほか 全24色 各30g ￥14,300",
    description:
      "「“カバー力があるのに美しいツヤ肌仕上がり”というポイントが、まず美容賢者の方々の間で話題となり、今は多くのお客さまからご支持をいただいています。昨年大ヒットした『ザ リクイド ファンデーション』と比較すると売り上げが130％と、好調に推移しています」",
  },
  {
    brand: "ポール ＆ ジョー ボーテ,ぽーるじょーぼーて",
    title:
      "プロテクティング ファンデーション プライマー 01 SPF50＋・PA++++ 30ml ￥3,850",
    description:
      "「ブランドの人気TOP3は全てプライマーが独占していますが、なかでもいちばん人気となったアイテムがこちら。春夏に特におすすめできる、崩れにくくUVカット効果の高いタイプで、コロナ禍に崩れにくさから人気が加速しましたが、今も人気をキープ中。保湿力も評価いただき、シーズンを問わず多くの方にご使用いただいています。トーンアップや透明感、ツヤなどのメイク効果もあり、求める要素が詰まっているプライマーとして大好評です」",
  },
  {
    brand: "ポーラ,ぽーら",
    title: "B.A ミルク フォーム 84g ￥13,200",
    description:
      "「マスクフリーの生活が始まるとともに、もたつき・ハリのなさなどが気になるという声が増える中、“肌を引き締める”という、通常の乳液とは異なる価値に多くの方からご支持をいただいています。そして発売10日間で約3.5万個、１カ月で約7.4万個を販売したヒットアイテムとなりました。『保湿だけにとどまらない新感覚の使い心地に、乳液の既成概念を覆したよう』など、うれしい声もいただいています」",
  },
  {
    brand: "ヴァレンティノ ビューティ,ゔぁれんてぃのびゅーてぃ",
    title: "リキロッソ 全12色 ￥5,940",
    description:
      "「リップにもチークにも使える2in1アイテム。マットカラーの『リキロッソ』は、うるおい感のある軽やかなテクスチャーで人気です。ヘア＆メイクアップ・アーティストや美容家の方々にもご紹介いただいたことで、一時は人気色が完売するほど好評でした」",
  },
  {
    brand: "スリー,すりー",
    title: "バランシング クレンジング オイル N 185ml ￥4,840",
    description:
      "「2023年春にリニューアルした、ブランドを代表するクレンジングオイル。植物の恵みをたっぶり配合し、高い洗浄力と美容液のようなトリートメント力を絶妙にペアリングして進化しました。メイクはするっと落ちるのに洗い上がりはしっとり。1日の疲れまでも洗い流してくれるような心地よい精油の香りが大好評で、『1日の終わりに欠かせない』『メイク落としが楽しみになる香り』などの声をたくさんいただいています」",
  },
  {
    brand: "ジバンシイ,じぱんしい",
    title:
      "プリズム・リーブル・スキンケアリング・コレクター ブルー 11ml ¥4,620",
    description:
      "「ブランドを代表する『プリズム・リーブル』シリーズに、2023年新たに加わったフェイスカラー。多くのプロのヘア＆メイクアップ・アーティストや美容ジャーナリスト、インフルエンサーからも熱い支持を獲得したほか、多数のベストコスメを受賞しました。全3色のうち、肌の黄みを抑え透明感を与える『ブルー』がいちばんの人気色です。またメイクアップ効果だけでなく、自然由来成分が95%※も高配合された、やさしい処方となっている点も魅力です」",
  },
  {
    brand: "アルビオン,あるびおん",
    title:
      "薬用スキンコンディショナー エッセンシャル N ［医薬部外品］110ml ￥3,850、165ml ￥5,500ほか",
    description:
      "「毎年、新製品が出てもなお、不動の人気が続く”スキコン”は、来年50周年を迎えるロングセラー化粧水。季節・肌質・年代を問わず肌のコンディションを整え、すこやかに保つという頼もしさで支持されています。『使っていれば肌の調子がいい』『肌が荒れそうになったら必ず手に取る』という方も多く、まさに“肌のお守り”化粧水と言えます。最近は男性の愛用者も増え、お母さまと息子さまで使っているという声もいただきます」",
  },
  {
    brand: "トム フォード ビューティ,とむふぉーどびゅーてぃ",
    title: "アイ カラー クォード 42A ￥12,650",
    description:
      "「濡れたようなツヤ感をまぶたに宿すアイシャドウパレット。なかでも、上質なローズ シェード『42A ヘイズィ センシュアリティ』が、肌色を選ばず使いやすいと評判。2023年5月に発売して以来、多くの方にお買い求めいただき、日本ではもちろん、現在グローバル全体で品切れ中というほど人気があります。2024年1月に一部在庫が復活する予定ですので、ぜひ店頭にてお試しいただきたいです」",
  },
  {
    brand: "シュウ ウエムラ,しゅううえむら",
    title:
      "アルティム８∞ スブリム ビューティ クレンジング オイルn 450ml ￥14,850",
    description:
      "「ブランドを代表する不動の人気アイテムと言えるクレンジングオイルが、2023年9月、8つの美肌効果を携えてリニューアル。洗浄力、なめらかで摩擦レスな使い心地、ダブル洗顔不要といった長年愛されてきたこだわりはそのままに、椿の花びらエキスを新たに配合し、スキンケア成分は約94％へとアップしました。そんなリニューアルの反響もあり、10月の定期お届け便出荷件数が歴代1位に！ 多くの美容家・メイクアップアーティストの方々からもご支持をいただいています」",
  },
  {
    brand: "ヘレナ ルビンスタイン,へれなるびんすたいん",
    title: "リプラスティ R.C. クリーム 14.8g ￥22,990、49.75g ￥64,900ほか",
    description:
      "「昨年に引き続き、黒のバンデージ『リプラスティ R.C. クリーム』が不動の人気No.1。2023年は新製品『リプラスティ R.C. アイ クリーム』の発売に際して、独自の先進成分、プロキシレンと細胞の概日リズムに関する新発見を発表したところ、その反響が大きく、このクリームも改めてご注目いただきました。夜のスキンケアとして、黒クリームとアイクリームをルーティンにお使いの方が増えています。なかには『美容施術後に使うことで、より美容施術の効果を実感できる』という声も。美容施術をサポートするスキンケアとしても支持されています」",
  },
  {
    brand: "アディクション ビューティ,あでぃくしょんびゅーてぃ",
    title:
      "XXX　ザ ファンデーション　リフトグロウ SPF20・PA++ 全11色 各30ml ￥6,600",
    description:
      "「リフトアップしたかのような仕上がりになると大好評のファンデーション。“顔全体がツヤツヤするのではなく、頬骨の高いところなどに光が宿る”という、メリハリのあるツヤ感が支持されました。発売前からSNSでも話題になり、発売から2カ月で想定の約2倍の売り上げを記録しました。店頭のタッチアップを受け、美しいツヤとカバー力を実感して、気に入っていただけることも多いです」",
  },
  {
    brand: "アルマーニ ビューティ,あるまーにびゅーてぃ",
    title: "パワー ファブリック ファンデーション ＋ SPF20 全8色 30ml ￥8,800",
    description:
      "「“フェイスマエストロ”をコンセプトに、こだわり抜いた肌づくりを提案し続けてきたブランドを代表するリキッドファンデーション。今年はグローバル アルマーニ ビューティ アンバサダーに『NewJeans』のハニを起用したビジュアルでも話題になり、標準色『#3.5』を中心に、昨年よりも＋68％の数を売り上げています」",
  },
  {
    brand: "エスティ ローダー,えすてぃろーだー",
    title: "アドバンス ナイト リペア SMR コンプレックス 50ml ￥17,050",
    description:
      "「年間ベストセラーはやはり、アイコン美容液です！ 寝不足のときでも8時間ぐっすり眠ったような肌のツヤ、ハリや弾力を実感でき、発売以来変わらない、とろみあるテクスチャーには『肌にすっとなじんで心地よいです』という声が。『調子のいい肌でずっといられるのは、このお守り美容液があるから』と根強い支持を集めています。ぜひ、この肌の調子の違いをご体感いただきたいです」",
  },
  {
    brand: "ラ・メール,らめーる",
    title:
      "ザ・モイスチャライジング ソフトクリーム 15ml ￥15,620、30ml ￥29,150ほか",
    description:
      "「2023年3月、発売以来初めてリニューアルしたエイジングケアクリーム。独自の美容保湿成分であるミラクル ブロス™による新たな作用や、新デリバリーシステムが搭載されているやわらかなテクスチャーが特徴。さらに進化したソフトクリームで、多くのビューティアワードをいただきました」",
  },
  {
    brand: "KANEBO,かねぼう",
    title: "ラディアント スキン リファイナー 200ml ￥4,950",
    description:
      "「これまで大ヒットとなっている“素肌化けファンデ”『ライブリースキン ウェア』、継続補水 UV 美容液『ヴェイル オブ デイ』を大きく上回るスマッシュヒットで、一気にファンを増やしたふき取り化粧水。その気持ちよさ、肌への効果に『もう手放せない』という方が続出しました。同時に発売した『4 レイヤリングコットン』とともに、リピート購入がとても多く、毎日使いたいという高い評価をいただいています」",
  },
];
