import { View } from "@react-pdf/renderer";
import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { StackData, StackSpaceData } from "../stackData";

export const Space: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "space"}>
      <View style={{ height: (contents as StackSpaceData).space }} />
    </If>
  );
};
