import { splitGrapheme, symbolRegexReplacementReplaceTable } from "@langue-de-chat-llc/enigmastudio-algorithm";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import { useStatePersist } from "use-state-persist";

type ReplacementInputProps = {
  replacement: string;
  onReplacementChange: (newReplacement: string) => void;
  disabled?: boolean;
  label?: string;
  sx?: any;
  onBlur?: () => void;
};
export const ReplacementInput: FC<ReplacementInputProps> = (props) => {
  const { replacement, onReplacementChange, disabled, label, sx, onBlur } =
    props;

  const [replaceFullsizeSymbolToHalfsize] = useStatePersist(
    "general-search-replace-fullsize-symbol-to-halfsize",
    false
  );

  return (
    <TextField
      label={label || "置換後文字列"}
      variant="outlined"
      value={replacement}
      onChange={(e) => onReplacementChange(e.target.value)}
      onCompositionEnd={(e) => {
        if (replaceFullsizeSymbolToHalfsize) {
          const newReplacement = splitGrapheme(replacement as string)
            .map((c: string) =>
              symbolRegexReplacementReplaceTable[c]
                ? symbolRegexReplacementReplaceTable[c]
                : c
            )
            .join("");
          onReplacementChange(newReplacement);
        }
      }}
      sx={{
        ml: 1,
        mb: 2,
        width: "300px",
        ...(sx ? sx : {}),
      }}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};
