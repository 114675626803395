import {
  dictionaries,
  DictionaryKey,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  Box,
  Button,
  Chip,
  FormControl,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useContext } from "react";
import { useWorker } from "../../../../../../hooks/useWorker";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { getFileChip } from "../../../CloudItem/getFileChip";

export const DictionaryLoader = ({
  setSelectedDictionaries,
}: {
  selectedDictionaries: string[];
  setSelectedDictionaries: (value: string[]) => void;
  displayLabel?: boolean;
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const worker = useWorker();

  const handleToggle = useCallback(
    (value: string) => () => {
      const key = value as DictionaryKey;
      const currentIndex = ctx.enabledDictionary.indexOf(key);
      const newChecked = [...ctx.enabledDictionary];

      if (currentIndex === -1) {
        newChecked.push(key);
        ctx.setLoaded(false);
        ctx.setLoadProgress(
          ((ctx.enabledDictionary.length / newChecked.length) * 100) | 0
        );
        worker.load(
          key,
          ctx.enabledDictionary.includes("all"),
          (result: string[] | undefined) => {
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress =
              (((result?.length ?? 0) / newChecked.length) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          }
        );
      } else {
        newChecked.splice(currentIndex, 1);
        worker.unload(key, ctx.enabledDictionary.includes("all"));
      }

      ctx.setEnabledDictionary(newChecked);
    },
    [ctx, worker]
  );

  const handleOn = useCallback(
    (_dictionaries: DictionaryKey[]) => {
      worker.unload("all", false);
      (Object.keys(dictionaries) as DictionaryKey[]).forEach(
        (item: DictionaryKey) => {
          if (!_dictionaries.includes(item)) {
            worker.unload(item, false);
          }
        }
      );
      const newChecked = _dictionaries;
      let count = 0;
      _dictionaries.forEach((item: DictionaryKey) => {
        if (!ctx.enabledDictionary.includes(item)) {
          ++count;
          ctx.setLoaded(false);
          ctx.setLoadProgress(
            ((ctx.enabledDictionary.length / count) * 100) | 0
          );
          worker.load(item, false, (result: string[] | undefined) => {
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress = (((result?.length ?? 0) / count) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          });
        }
      });

      if (_dictionaries.includes("all")) {
        worker.load("all", true, (result: string[] | undefined) => {
          if (
            result?.sort().join(",") === ctx.enabledDictionary.sort().join(",")
          ) {
            ctx.setLoaded(true);
          }
          const progress =
            (((result?.length ?? 0) / newChecked.length) * 100) | 0;
          ctx.setLoadProgress(Math.min(progress, 200 - progress));
        });
      }
      ctx.setEnabledDictionary(newChecked);
    },
    [ctx, worker]
  );

  const handleOff = useCallback(
    (dictionaries: DictionaryKey[]) => {
      worker.unload("all", false);
      dictionaries.forEach((item: DictionaryKey) => {
        worker.unload(item, false);
      });
      ctx.setEnabledDictionary([]);
    },
    [ctx, worker]
  );

  return (
    <Stack>
      <Box sx={{ mt: 2 }}>
        {ctx.loadProgress < 100 && (
          <LinearProgress
            variant="determinate"
            value={ctx.loadProgress}
            sx={{ mb: 1 }}
          />
        )}
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          デフォルト辞書
        </Typography>
        <Box>
          {Object.keys(dictionaries)
            .map((e) => e as DictionaryKey)
            .filter((item) => !["all", "custom"].includes(item))
            .filter((item) => !ctx.enabledDictionary.includes(item))
            .map((item: DictionaryKey, i) => {
              const fileChipType = "unload";
              const { color, variant } = getFileChip(fileChipType);
              return (
                <Fragment key={item}>
                  <Chip
                    key={item}
                    variant={variant}
                    color={color}
                    label={dictionaries[item].name}
                    sx={{
                      mb: 1,
                      mr: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleToggle(dictionaries[item].key)();
                    }}
                  />
                </Fragment>
              );
            })}
        </Box>

        {ctx.enabledDictionary.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleOff(Object.keys(dictionaries) as DictionaryKey[]);
              }}
              sx={{ ml: 1 }}
            >
              一括読み込み解除
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
};
