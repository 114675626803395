import { createContext } from "react";

export type ImaginateConfigProps = {
  projectId: string;
};

export const defaultImaginateConfig: ImaginateConfigProps = {
  projectId: "",
};

export const ImaginateContext = createContext<
  [ImaginateConfigProps, React.Dispatch<React.SetStateAction<ImaginateConfigProps>>]
>([
  defaultImaginateConfig,
  () => {
    return defaultImaginateConfig;
  },
]);
