import { Box, Container } from "@mui/material";
import { FC } from "react";
import { PlainText } from "../common/PlainText";
import { Subtitle, Title } from "../common/Title";

export const ProPlan: FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>EnigmaStudio Proのご案内</Title>

      <Box sx={{ mt: 2 }}>
        <Subtitle>EnigmaStudio Pro</Subtitle>
        <PlainText>
          Fanboxで月額500円以上の支援をすることで、EnigmaStudio
          Proをご利用いただけます。
        </PlainText>
        <PlainText>
          <a href="https://wand.fanbox.cc/posts/6628879" target="_blank">
            EnigmaStudio Pro
          </a>
        </PlainText>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Subtitle>EnigmaStudio Pro + 方眼ツール</Subtitle>
        <PlainText>
          Fanboxで、月額1000円以上の支援をすることで、EnigmaStudio Proに加えて、
          方眼ツールをご利用いただけます。
        </PlainText>
        <PlainText>
          <a href="https://wand.fanbox.cc/posts/6628886" target="_blank">
            EnigmaStudio Pro+方眼ツール
          </a>
        </PlainText>
      </Box>
    </Container>
  );
};
