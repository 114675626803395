import { serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { FC, useCallback, useState } from "react";
import { useFirebaseConfig } from "../../../../_lib/eniverse/EniverseProvider";
import { ConvertTimestampRecursively } from "../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { CloudItemProps, DocFile } from "./cloudType";
import { TextDragDropZone } from "./TextDragDropZone";

export type SelectedFile = {
  doc: ConvertTimestampRecursively<DocFile>;
  file: File;
  existing?: boolean;
};

export type CloudItemNewProps = CloudItemProps & {
  userId: string;
  onClose: () => void;
};

export const CloudItemNew: FC<CloudItemNewProps> = (props) => {
  const { userId, fileListData, setFileListData, onClose } = props;
  const [selectedFile, setSelectedFile] = useState<SelectedFile | undefined>(
    undefined
  );
  const [{ cloudStorage }] = useFirebaseConfig();

  const handleSelectedFiles = useCallback(
    (_selectedFile: SelectedFile | undefined) => {
      setSelectedFile(_selectedFile);
    },
    [setSelectedFile]
  );

  const handleUpload = useCallback(
    ({ success, failed }: { success: () => void; failed: () => void }) => {
      if (!selectedFile) return;
      if (!userId) return;
      if (!cloudStorage) return;
      const path = "enigma-studio/" + userId;
      const tasks: Promise<any>[] = [];
      const storageRef = ref(cloudStorage, `${path}/${selectedFile.file.name}`);
      tasks.push(uploadBytes(storageRef, selectedFile.file));
      Promise.all(tasks)
        .then(() => {
          success();
          onClose();
        })
        .catch((error) => {
          failed();
        });

      if (setFileListData) {
        setFileListData({
          files: {
            ...fileListData?.files,
            [selectedFile.file.name]: {
              ...selectedFile.doc,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
            },
          },
        });
      }
    },
    [
      selectedFile,
      userId,
      cloudStorage,
      setFileListData,
      onClose,
      fileListData?.files,
    ]
  );

  return (
    <>
      <TextDragDropZone
        selectedFile={selectedFile}
        onSelectedFile={handleSelectedFiles}
        handleUpload={handleUpload}
        {...props}
      />
    </>
  );
};
