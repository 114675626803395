import { Box, Button, Container, TextField } from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { useWorker } from "../../../../hooks/useWorker";
import { DictionaryRadioSelector } from "../../commonStudio/DictionaryRadioSelector";
import { PlainText } from "../../common/PlainText";
import { ResultList } from "../../common/Result";
import { Title } from "../../common/Title";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { DictionaryKey } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const Chimata: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  ctx.setEnableLoad(true);
  const [keyword, setKeyword] = useState("");
  const [dictionary, setDictionary] = useState("");
  const [result, setResult] = useState<string[]>([]);
  const [searching, setSearching] = useState(false);
  const { chimataSearch } = useWorker();
  const onSearch = useCallback(
    (diff: number) => {
      setSearching(true);
      setResult([]);
      chimataSearch(
        { keyword, addSize: diff, dictionary: dictionary as DictionaryKey },
        (result) => {
          if (result) {
            setResult(result);
            setSearching(false);
          }
        }
      );
    },
    [keyword, dictionary, chimataSearch]
  );

  const handleChangeDictionary = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDictionary((event.target as HTMLInputElement).value);
    },
    []
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>チマタグラム検索</Title>
      <PlainText>
        入力した単語に〇〇文字足したり引いて、並び替えてできる単語を出力します。
      </PlainText>
      <TextField
        label="Query"
        variant="outlined"
        sx={{ width: "80vw", sx: { mt: 2 } }}
        value={keyword}
        onChange={(event) => {
          setKeyword(event.target.value);
        }}
      />
      <DictionaryRadioSelector
        dictionary={dictionary}
        onChange={handleChangeDictionary}
      />
      <Box>
        <Button
          variant="contained"
          onClick={() => onSearch(-2)}
          sx={{ mx: 0.5 }}
        >
          -2
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(-1)}
          sx={{ mx: 0.5 }}
        >
          -1
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(0)}
          sx={{ mx: 0.5 }}
        >
          0
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(1)}
          sx={{ mx: 0.5 }}
        >
          +1
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(2)}
          sx={{ mx: 0.5 }}
        >
          +2
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(3)}
          sx={{ mx: 0.5 }}
        >
          +3
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(4)}
          sx={{ mx: 0.5 }}
        >
          +4
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(5)}
          sx={{ mx: 0.5 }}
        >
          +5
        </Button>
        <Button
          variant="contained"
          onClick={() => onSearch(6)}
          sx={{ mx: 0.5 }}
        >
          +6
        </Button>
      </Box>
      <ResultList items={result} loading={searching} />
    </Container>
  );
};
