import { createTake } from "../take";
import { word5alphabet } from "../word5alphabet";

export const num2abc = {
  id: "num2abc",
  title: "アルファベット何番目ゲーム",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「19 20 1 18 20」→「start」のように
      <br />
      数字をアルファベット順でに変換した単語を答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「19 20 1 18 20」→「start」のように
      <br />
      数字をアルファベット順でに変換した単語をを半角英語小文字で答えます。
      <br />
      全部で10問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () =>
    createTake(
      word5alphabet.map((e) => ({
        problemText: e[0],
        answers: [e[1]],
      })),
      10
    ),
  shareButton: true,
  hashTag: "アルファベット何番目ゲーム",
};