import { listAll, ref, StorageReference } from "firebase/storage";
import { useCallback, useEffect, useState } from "react";
import { useFirebaseConfig } from "../../../../_lib/eniverse/EniverseProvider";
import { CloudDoc } from "./cloudType";

type CloudProps = {
  userId?: string | null;
  fileListData?: CloudDoc;
};
export const useCloud = ({ userId, fileListData }: CloudProps) => {
  const [{ cloudStorage }] = useFirebaseConfig();
  const [storageTable, setStorageTable] = useState<
    Record<string, StorageReference>
  >({});
  const getStorageList = useCallback(() => {
    if (!userId) return;
    const path = "enigma-studio/" + userId;
    if (!path) return;
    if (!cloudStorage) return;
    const storageRef = ref(cloudStorage, path);
    listAll(storageRef).then((res) => {
      const _storageTable: Record<string, StorageReference> = {};
      res.items.forEach((item) => {
        _storageTable[item.name] = item;
      });
      setStorageTable(_storageTable);
    });
  }, [cloudStorage, userId]);

  useEffect(() => {
    getStorageList();
  }, [userId, getStorageList, fileListData]);

  return { storageTable };
};
