import { useState } from "react";
import { useStatePersist } from "use-state-persist";

export const useListDefinition = () => {
  const [editableItems, setEditableItems] = useStatePersist<
    { name: string; value: string }[]
  >("enigma-studio-function-search-editable-items", []);
  const handleAdd = () => {
    setEditableItems([
      ...editableItems,
      { name: inputName, value: inputValue },
    ]);
    setInputName("");
    setInputValue("");
  };

  const handleDelete = (index: number) => {
    setEditableItems(editableItems.filter((_, i) => i !== index));
  };

  const [inputName, setInputName] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  return {
    editableItems,
    inputName,
    inputValue,
    setInputName,
    setInputValue,
    handleAdd,
    handleDelete,
  };
};
