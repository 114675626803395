import { FC } from "react";
import { PlainText } from "../common/PlainText";

type Props = {
  isDemo: boolean;
  search?: boolean;
};

export const DemoModeMessage: FC<Props> = ({ isDemo, search }) => {
  return isDemo ? (
    <PlainText color="error">
      現在はデモモードで動作しています。{search && "検索の実行はできますが、"}
      フォームの変更はできません。
      <br />
      機能を利用するには、{" "}
      <a
        href="https://wand.fanbox.cc/posts/6628879"
        target="_blank"
        rel="noreferrer"
      >
        EnigmaStudio Pro
      </a>
      への更新が必要です。
    </PlainText>
  ) : (
    <></>
  );
};
