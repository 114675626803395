// Param型のオブジェクトを初期化する関数を定義します。
const createParam = () => ({
    e: [], // 各エッジの終端を保持する配列
    h: [], // 各ノードのエッジリストのヘッドを保持する配列
    nex: [], // 同じ始点を持つ次のエッジを保持する配列
    cnt: 1, // 現在のエッジの数（1から開始）
    st: [], // 探索の状態を保持する配列
    mat: [], // マッチングの結果を保持する配列
});
// 二部グラフにエッジを追加する関数を定義します。
const add = (param, a, b) => {
    param.e[param.cnt] = b; // エッジの終端を設定
    param.nex[param.cnt] = param.h[a]; // 現在のヘッドにあるエッジを次のエッジに設定
    param.h[a] = param.cnt; // 新しいエッジをヘッドに設定
    ++param.cnt; // エッジの数を増やす
};
// 指定されたノードxから増加パスを見つける関数を定義します。
const findE = (param, x) => {
    for (let i = param.h[x] ?? 0; i !== 0; i = param.nex[i] ?? 0) {
        const endd = param.e[i] ?? 0;
        if ((param.st[endd] ?? 0) === 0) {
            param.st[endd] = 1;
            if ((param.mat[endd] ?? 0) === 0 || findE(param, param.mat[endd])) {
                param.mat[endd] = x;
                return true;
            }
        }
    }
    return false;
};
// キーワードに基づいてマッチングする単語を見つける関数を定義します。
export const checkWordMatching = (word, keywords, length) => {
    // 与えられた単語リストから条件に合う単語のみをフィルタリングします。
    if (word.length !== length) {
        return {
            match: false,
            matchWords: [],
        };
    }
    const param = createParam();
    keywords.forEach((key, i) => {
        for (let j = 0; j < word.length; ++j) {
            const c = word.charAt(j);
            // キーワードに含まれる文字かワイルドカード(*)であればエッジを追加します。
            if (key.includes(c) || key === "*") {
                add(param, i + 1, keywords.length + j + 1);
            }
        }
    });
    // マッチングを試みます。
    let matchCount = 0;
    const matchWords = [];
    for (let i = 1; i <= keywords.length; ++i) {
        param.st.fill(0);
        if (findE(param, i)) {
            ++matchCount;
            matchWords.push(keywords[i - 1]);
        }
    }
    // マッチングの数が単語の長さと等しいかを確認します。
    return {
        match: matchCount === word.length,
        matchWords: matchWords,
    };
};
// キーワードに基づいてマッチングする単語を見つける関数を定義します。
export const wordMatching = (words, keywords, length) => {
    // 与えられた単語リストから条件に合う単語のみをフィルタリングします。
    const result = words
        .map((word) => {
        const result = checkWordMatching(word, keywords, length);
        // マッチングの数が単語の長さと等しいかを確認します。
        return {
            word: word,
            ...result,
        };
    })
        .filter((e) => e.match);
    return result;
};
export const wordAllDifferents = (chars) => {
    const param = createParam();
    const leftSize = chars.length;
    const allChars = [
        ...new Set(chars.reduce((acc, val) => acc.concat(val), [])),
    ];
    chars.forEach((char, i) => {
        char.forEach((c) => {
            add(param, i + 1, leftSize + allChars.indexOf(c) + 1);
        });
    });
    let matchCount = 0;
    for (let i = 1; i <= chars.length; ++i) {
        param.st.fill(0);
        if (findE(param, i)) {
            ++matchCount;
        }
    }
    return matchCount === chars.length;
};
