import { CandidateCharactersFilter } from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FC } from "react";

type CandidateCharactersFormProps = {
  index: number;
  query: CandidateCharactersFilter;
  updateQuery: (index: number, field: string, value: any) => void;
  deleteQueryField: (index: number, field: string) => void;
  isDemo: boolean;
};

export const CandidateCharactersForm: FC<CandidateCharactersFormProps> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  return (
    <>
      <TextField
        label="文字列"
        variant="outlined"
        value={query.characters || ""}
        onChange={(e) => updateQuery(index, "characters", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
        disabled={isDemo}
      />
      <FormControl variant="outlined" sx={{ ml: 1, width: "200px", mb: 2 }}>
        <InputLabel>候補タイプ</InputLabel>
        <Select
          value={query.candidateType || ""}
          onChange={(e) => updateQuery(index, "candidateType", e.target.value)}
          disabled={isDemo}
        >
          <MenuItem value="candidate">から構成される</MenuItem>
          <MenuItem value="included">を全て含む</MenuItem>
          <MenuItem value="excluded">を含まない</MenuItem>
          <MenuItem value="supersequence">がこの順で含まれる</MenuItem>
          <MenuItem value="subsequence">からこの順で取り出す</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
