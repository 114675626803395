import { Alert, Box, Button, CssBaseline, LinearProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FC, useContext, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useStatePersist } from "use-state-persist";
import { debugKey, proKey, proPlusKey } from "../../../App";
import { GameinDrawer } from "../commonGamein/GameinDrawer";
import { StudioDrawer } from "../commonStudio/StudioDrawer";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import { CommonAppBar } from "./CommonAppBar";
import { CommonFooter } from "./CommonFooter";
import { gameThemeOptions, studioThemeOptions } from "./Theme";
import { ContentsLogin } from "../auth/ContentsLogin";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { DrawerState } from "./drawerState";

export const drawerWidth = 240;

const Wrapper: FC<{
  appbar?: boolean;
  drawer: DrawerState;
  children: React.ReactNode;
}> = ({ appbar = true, drawer, children }) => {
  const [themeMode, setThemeMode] = useStatePersist<"light" | "dark">(
    "themeMode",
    "light"
  );
  const { pathname } = useLocation();
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const service = ctx.service;

  const theme = useMemo(() => {
    return service === "gamein"
      ? createTheme(gameThemeOptions(themeMode))
      : createTheme(studioThemeOptions(themeMode));
  }, [service, themeMode]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { drawerOpen, handleDrawerOpen, handleDrawerClose } = drawer;
  const planKey =
    sessionStorage.getItem("planKey") ?? searchParams.get("plan") ?? "normal";

  const loginEnabled = JSON.parse(searchParams.get("login") ?? "false");

  const [planKeyRecord] = useStatePersist<{
    [key: string]: string;
  }>("hasPlanKey", {});

  useEffect(() => {
    if (
      [
        "https://enigma-workstudio.firebaseapp.com",
        "https://enigma-workstudio.web.app",
      ].includes(window.location.origin)
    ) {
      window.location.href = "https://studio.enigmahouse.jp/" + pathname;
    }
    if (
      ["https://studio.enigmahouse.jp"].includes(window.location.origin) &&
      service === "gamein"
    ) {
      window.location.href = "https://gamein.enigmahouse.jp/" + pathname;
    } else if (
      ["https://gamein.enigmahouse.jp"].includes(window.location.origin)
    ) {
      if (service === "studio") {
        window.location.href = "https://gamein.enigmahouse.jp/g/";
      }
    }
  }, [window.location.origin, pathname, service]);

  const { user } = useFirebaseLogin();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {service === "gamein" && (
        <Helmet>
          <title>EnigmaGAMEIN</title>
        </Helmet>
      )}
      <ContentsLogin loginEnabled={loginEnabled}>
        <Box sx={{ flexGrow: 1 }} onClick={handleDrawerClose}>
          {appbar && (
            <CommonAppBar
              open={drawerOpen}
              handleDrawerOpen={handleDrawerOpen}
              plan={plan}
              service={service}
              themeMode={themeMode}
              setThemeMode={setThemeMode}
              user={user}
            />
          )}
          {service === "gamein" ? (
            <GameinDrawer
              open={drawerOpen}
              handleDrawerClose={handleDrawerClose}
              drawerWidth={drawerWidth}
              theme={theme}
              planKey={planKey}
              plan={plan}
            />
          ) : (
            <StudioDrawer
              open={drawerOpen}
              handleDrawerClose={handleDrawerClose}
              drawerWidth={drawerWidth}
              theme={theme}
              planKey={planKey}
              plan={plan}
            />
          )}
          <LinearProgress variant="determinate" value={ctx.loadProgress} />
          {service === "studio" && (
            <>
              {["normal", "pro"].includes(plan) &&
                planKeyRecord["pro+"] === proPlusKey && (
                  <Alert
                    sx={{ mx: 2, my: 2 }}
                    severity="info"
                    onClick={() => {
                      setSearchParams({ plan: proPlusKey });
                      window.location.reload();
                    }}
                    action={
                      <Button color="inherit" size="small">
                        Update
                      </Button>
                    }
                  >
                    EnigmaStudio Pro+を利用できます。
                  </Alert>
                )}
              {["normal"].includes(plan) && planKeyRecord["pro"] === proKey && (
                <Alert
                  sx={{ mx: 2, my: 2 }}
                  severity="info"
                  onClick={() => {
                    setSearchParams({ plan: proKey });
                    window.location.reload();
                  }}
                  action={
                    <Button color="inherit" size="small">
                      Update
                    </Button>
                  }
                >
                  EnigmaStudio Proを利用できます。
                </Alert>
              )}
            </>
          )}
          {children}
          <CommonFooter />
        </Box>
      </ContentsLogin>
    </ThemeProvider>
  );
};

export default Wrapper;
