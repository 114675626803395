import { SprintAnswerEvent } from "../events";
import { rawRytran } from "../rawData/rytran";
import { createTake } from "../take";

export const rytran: SprintAnswerEvent = {
  id: "rytran",
  title: "リートラン",
  category: "ランダム出題",
  addMenu: false,
  menuDescription: (
    <>
      「リートラン」→「ファミレス」のように
      <br />
      略称の省略された部分からもとの略称を答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「リートラン」→「ファミレス」のように
      <br />
      略称の省略された部分からもとの略称を答えます。
      すべてひらがなで出題/解答判定します。
      <br />
      全{rawRytran.split('\n').length}問から全部で20問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () => {
    const data = rawRytran.split("\n").map((e) => e.split(","));
    return createTake<string[]>(data, 20).map((e) => {
      return {
        problemText: e[0],
        answers: [e[1]],
      };
    });
  },
  shareButton: false,
  answerButton: true,
  hashTag: "リートラン",
};
