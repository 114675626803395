"use client";
import { FC, useState } from "react";
import { NazoConnectMapGroupView } from "./NazoConnectMapGroupView";
import { CommonAppProp } from "../../../../../../_lib/eniverse/imaginate/mainView/CommponAppProp";
import { LgContainer } from "../../../../../../_lib/eniverse/siteKit/uiKit/Container";
import { StackViewTab } from "../../../../../../_lib/eniverse/StackSpace/components/ViewTab";

export const NazoConnectMapView: FC<CommonAppProp> = (props) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <LgContainer>
        <StackViewTab
          value={value}
          handleChange={handleChange}
          labels={["団体", "個人", "つながり"]}
        />
        {value === 0 && <NazoConnectMapGroupView {...props} />}
        {value === 1 && <></>}
        {value === 2 && <div>コネクション</div>}
      </LgContainer>
    </>
  );
};
