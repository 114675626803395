export const city = `さっぽろ
はこだて
おたる
あさひかわ
むろらん
くしろ
おびひろ
きたみ
ゆうばり
いわみざわ
あばしり
るもい
とまこまい
わっかない
びばい
あしべつ
えべつ
あかびら
もんべつ
しべつ
なよろ
みかさ
ねむろ
ちとせ
たきかわ
すながわ
うたしない
ふかがわ
ふらの
のぼりべつ
えにわ
だて
きたひろしま
いしかり
ほくと
あおもり
ひろさき
はちのへ
くろいし
ごしょがわら
とわだ
みさわ
むつ
つがる
ひらかわ
もりおか
みやこ
おおふなと
はなまき
きたかみ
くじ
とおの
いちのせき
りくぜんたかた
かまいし
にのへ
はちまんたい
おうしゅう
たきざわ
せんだい
いしのまき
しおがま
けせんぬま
しろいし
なとり
かくだ
たがじょう
いわぬま
とめ
くりはら
ひがしまつしま
おおさき
とみや
あきた
のしろ
よこて
おおだて
おが
ゆざわ
かづの
ゆりほんじょう
かたがみ
だいせん
きたあきた
にかほ
せんぼく
やまがた
よねざわ
つるおか
さかた
しんじょう
さがえ
かみのやま
むらやま
ながい
てんどう
ひがしね
おばなざわ
なんよう
ふくしま
あいづわかまつ
こおりやま
いわき
しらかわ
すかがわ
きたかた
そうま
にほんまつ
たむら
みなみそうま
だて
もとみや
みと
ひたち
つちうら
こが
いしおか
ゆうき
りゅうがさき
しもつま
じょうそう
ひたちおおた
たかはぎ
きたいばらき
かさま
とりで
うしく
つくば
ひたちなか
かしま
いたこ
もりや
ひたちおおみや
なか
ちくせい
ばんどう
いなしき
かすみがうら
さくらがわ
かみす
なめがた
ほこた
つくばみらい
おみたま
うつのみや
あしかが
とちぎ
さの
かぬま
にっこう
おやま
もおか
おおたわら
やいた
なすしおばら
さくら
なすからすやま
しもつけ
まえばし
たかさき
きりゅう
いせさき
おおた
ぬまた
たてばやし
しぶかわ
ふじおか
とみおか
あんなか
みどり
さいたま
かわごえ
くまがや
かわぐち
ぎょうだ
ちちぶ
ところざわ
はんのう
かぞ
ほんじょう
ひがしまつやま
かすかべ
さやま
はにゅう
こうのす
ふかや
あげお
そうか
こしがや
わらび
とだ
いるま
あさか
しき
わこう
にいざ
おけがわ
くき
きたもと
やしお
ふじみ
みさと
はすだ
さかど
さって
つるがしま
ひだか
よしかわ
ふじみの
しらおか
ちば
ちょうし
いちかわ
ふなばし
たてやま
きさらづ
まつど
のだ
もばら
なりた
さくら
とうがね
あさひ
ならしの
かしわ
かつうら
いちはら
ながれやま
やちよ
あびこ
かもがわ
かまがや
きみつ
ふっつ
うらやす
よつかいどう
そでがうら
やちまた
いんざい
しろい
とみさと
みなみぼうそう
そうさ
かとり
さんむ
いすみ
おおあみしらさと
はちおうじ
たちかわ
むさしの
みたか
おうめ
ふちゅう
あきしま
ちょうふ
まちだ
こがねい
こだいら
ひの
ひがしむらやま
こくぶんじ
くにたち
ふっさ
こまえ
ひがしやまと
きよせ
ひがしくるめ
むさしむらやま
たま
いなぎ
はむら
あきるの
にしとうきょう
よこはま
かわさき
さがみはら
よこすか
ひらつか
かまくら
ふじさわ
おだわら
ちがさき
ずし
みうら
はだの
あつぎ
やまと
いせはら
えびな
ざま
みなみあしがら
あやせ
にいがた
ながおか
さんじょう
かしわざき
しばた
おぢや
かも
とおかまち
みつけ
むらかみ
つばめ
いといがわ
みょうこう
ごせん
じょうえつ
あがの
さど
うおぬま
みなみうおぬま
たいない
とやま
たかおか
うおづ
ひみ
なめりかわ
くろべ
となみ
おやべ
なんと
いみず
かなざわ
ななお
こまつ
わじま
すず
かが
はくい
かほく
はくさん
のみ
ののいち
ふくい
つるが
おばま
おおの
かつやま
さばえ
あわら
えちぜん
さかい
こうふ
ふじよしだ
つる
やまなし
おおつき
にらさき
みなみあるぷす
ほくと
かい
ふえふき
うえのはら
こうしゅう
ちゅうおう
ながの
まつもと
うえだ
おかや
いいだ
すわ
すざか
こもろ
いな
こまがね
なかの
おおまち
いいやま
ちの
しおじり
さく
ちくま
とうみ
あづみの
ぎふ
おおがき
たかやま
たじみ
せき
なかつがわ
みの
みずなみ
はしま
えな
みのかも
とき
かかみがはら
かに
やまがた
みずほ
ひだ
もとす
ぐじょう
げろ
かいづ
しずおか
はままつ
ぬまづ
あたみ
みしま
ふじのみや
いとう
しまだ
ふじ
いわた
やいづ
かけがわ
ふじえだ
ごてんば
ふくろい
しもだ
すその
こさい
いず
おまえざき
きくがわ
いずのくに
まきのはら
なごや
とよはし
おかざき
いちのみや
せと
はんだ
かすがい
とよかわ
つしま
へきなん
かりや
とよた
あんじょう
にしお
がまごおり
いぬやま
とこなめ
こうなん
こまき
いなざわ
しんしろ
とうかい
おおぶ
ちた
ちりゅう
おわりあさひ
たかはま
いわくら
とよあけ
にっしん
たはら
あいさい
きよす
きたなごや
やとみ
みよし
あま
ながくて
つ
よっかいち
いせ
まつさか
くわな
すずか
なばり
おわせ
かめやま
とば
くまの
いなべ
しま
いが
おおつ
ひこね
ながはま
おうみはちまん
くさつ
もりやま
りっとう
こうか
やす
こなん
たかしま
ひがしおうみ
まいばら
きょうと
ふくちやま
まいづる
あやべ
うじ
みやづ
かめおか
じょうよう
むこう
ながおかきょう
やわた
きょうたなべ
きょうたんご
なんたん
きづがわ
おおさか
さかい
きしわだ
とよなか
いけだ
すいた
いずみおおつ
たかつき
かいづか
もりぐち
ひらかた
いばらき
やお
いずみさの
とんだばやし
ねやがわ
かわちながの
まつばら
だいとう
いずみ
みのお
かしわら
はびきの
かどま
せっつ
たかいし
ふじいでら
ひがしおおさか
せんなん
しじょうなわて
かたの
おおさかさやま
はんなん
こうべ
ひめじ
あまがさき
あかし
にしのみや
すもと
あしや
いたみ
あいおい
とよおか
かこがわ
あこう
にしわき
たからづか
みき
たかさご
かわにし
おの
さんだ
かさい
たんばささやま
やぶ
たんば
みなみあわじ
あさご
あわじ
しそう
かとう
たつの
なら
やまとたかだ
やまとこおりやま
てんり
かしはら
さくらい
ごじょう
ごせ
いこま
かしば
かつらぎ
うだ
わかやま
かいなん
はしもと
ありだ
ごぼう
たなべ
しんぐう
きのかわ
いわで
とっとり
よなご
くらよし
さかいみなと
まつえ
はまだ
いずも
ますだ
おおだ
やすぎ
ごうつ
うんなん
おかやま
くらしき
つやま
たまの
かさおか
いばら
そうじゃ
たかはし
にいみ
びぜん
せとうち
あかいわ
まにわ
みまさか
あさくち
ひろしま
くれ
たけはら
みはら
おのみち
ふくやま
ふちゅう
みよし
しょうばら
おおたけ
ひがしひろしま
はつかいち
あきたかた
えたじま
しものせき
うべ
やまぐち
はぎ
ほうふ
くだまつ
いわくに
ひかり
ながと
やない
みね
しゅうなん
さんようおのだ
とくしま
なると
こまつしま
あなん
よしのがわ
あわ
みま
みよし
たかまつ
まるがめ
さかいで
ぜんつうじ
かんおんじ
さぬき
ひがしかがわ
みとよ
まつやま
いまばり
うわじま
やわたはま
にいはま
さいじょう
おおず
いよ
しこくちゅうおう
せいよ
とうおん
こうち
むろと
あき
なんこく
とさ
すさき
すくも
とさしみず
しまんと
こうなん
かみ
きたきゅうしゅう
ふくおか
おおむた
くるめ
のおがた
いいづか
たがわ
やながわ
やめ
ちくご
おおかわ
ゆくはし
ぶぜん
なかま
おごおり
ちくしの
かすが
おおのじょう
むなかた
だざいふ
こが
ふくつ
うきは
みやわか
かま
あさくら
みやま
いとしま
なかがわ
さが
からつ
とす
たく
いまり
たけお
かしま
おぎ
うれしの
かんざき
ながさき
させぼ
しまばら
いさはや
おおむら
ひらど
まつうら
つしま
いき
ごとう
さいかい
うんぜん
みなみしまばら
くまもと
やつしろ
ひとよし
あらお
みなまた
たまな
やまが
きくち
うと
かみあまくさ
うき
あそ
あまくさ
こうし
おおいた
べっぷ
なかつ
ひた
さいき
うすき
つくみ
たけた
ぶんごたかだ
きつき
うさ
ぶんごおおの
ゆふ
くにさき
みやざき
みやこのじょう
のべおか
にちなん
こばやし
ひゅうが
くしま
さいと
えびの
かごしま
かのや
まくらざき
あくね
いずみ
いぶすき
にしのおもて
たるみず
さつませんだい
ひおき
そお
きりしま
いちきくしきの
みなみさつま
しぶし
あまみ
みなみきゅうしゅう
いさ
あいら
なは
ぎのわん
いしがき
うらそえ
なご
いとまん
おきなわ
とみぐすく
うるま
みやこじま
なんじょう`;
