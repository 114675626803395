export const wordList = `1/2/3/5/4/4/2/2/2/2
2/3/5/7/11/13/17/19/23/29/31/37/41/43/47/53/59/61/67/71/73/79/83/89/97
a/b/o/ab
abcdefghijklmnopqrstuvwxyz
ace/jack/queen/king/joker
aries/taurus/gemini/cancer/leo/virgo/libra/scorpio/sagittarius/capricorn/aquarius/pisces
au/ag/cu
black/gray/white
century/year/month/day/hour/minute/second
century/year/month/week/day/hour/minute/second
first/second/third
gold/silver/bronze
jack/queen/king
january/february/march/april/may/june/july/august/september/october/november/december
king/queen/bishop/knight/rook/pawn
mercury/venus/earth/mars/jupiter/saturn/uranus/neptune
north/east/west/south
one/two/three/four/five/six/seven/eight/nine/ten
qwertyuiopasdfghjklzxcvbnm
red/green/blue
red/orange/yellow/green/aquablue/blue/purple
red/orange/yellow/green/aquablue/blue/violet
red/orange/yellow/green/blue/indigo/purple
red/orange/yellow/green/blue/indigo/violet
red/orange/yellow/green/skyblue/blue/purple
red/orange/yellow/green/skyblue/blue/violet
red/blue/purple
red/blue/violet
second/minute/hour/day/month/year
second/minute/hour/day/week/month/year
spade/heart/diamond/club
spring/summer/autumn/winter
sun/mon/tue/wed/thu/fri/sat
sunday/monday/tuesday/wednesday/thursday/friday/saturday
year/month/day/hour/minute/second
year/month/week/day/hour/minute/second
yen/doller/euro/pound
st/nd/rd/th
あい/まい/みー/まいん
あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん
あか/あお/むらさき
あか/おれんじ/き/みどり/あお/あい/むらさき
あか/おれんじ/き/みどり/そらいろ/あお/むらさき
あか/おれんじ/き/みどり/みずいろ/あお/むらさき
あか/だいだい/き/みどり/あお/あい/むらさき
あか/だいだい/き/みどり/そらいろ/あお/むらさき
あか/だいだい/き/みどり/みずいろ/あお/むらさき
あか/みどり/あおい
あかさたなはまやらわ/いきしちにひみり/うくすつぬふむゆる/えけせてねへめれ/おこそとのほもよろを
いお/えうろぱ/がにめで/かりすと
いし/はさみ/かみ
いち/に/さん/よん/ご/ろく/なな/はち/きゅう/じゅう
いろはにほへと/ちりぬるを/わかよたれそ/つねならむ/うゐのおくやま/けふこえて/あさきゆめみし/ゑひもせす
いろはにほへとちりぬるをわかよたれそつねならむうゐのおくやまけふこえてあさきゆめみしゑひもせす
いんく/にし/にく/さんご/しし/しご/ごご/ごく/ろっく/はっぱ/くに/くし
うえ/した/ひだり/みぎ
えひめ/かがわ/こうち/とくしま
えびす/だいこくてん/びしゃもんてん/べんざいてん/ふくろくじゅ/じゅろうじん/ほてい
えー/びー/しー/でぃー/いー/えふ/じー/えいち/あい/じぇー/けー/える/えむ/えぬ/おー/ぴー/きゅー/あーる/えす/てぃー/ゆー/ぶい/だぶりゅー/えっくす/わい/ぜっと
えーす/じゃっく/くいーん/きんぐ/じょーかー
えん/どる/ゆーろ
えん/どる/ゆーろ/ぽんど
おととい/きのう/きょう/あした/あさって
おはよう/こんにちは/こんばんは
おひつじ/おうし/ふたご/かに/しし/おとめ/てんびん/さそり/いて/やぎ/みずがめ/うお
おや/ひとさし/なか/くすり/こ
おやゆび/ひとさしゆび/なかゆび/くすりゆび/こゆび
おりおん/おおいぬ/こいぬ
か/げん/じょう/じょ
かち/まけ/あいこ
がぎぐげござじずぜぞだぢづでどばびぶべぼぱぴぷぺぽ
き/しょう/てん/けつ
き/ど/あい/らく
きたざとしばさぶろう/つだうめこ/しぶさわえいいち
きたざとしばさぶろう/むらさきしきぶ/つだうめこ/しぶさわえいいち
きみがよは/ちよにやちよに/さざれいしの/いわおとなりて/こけのむすまで
きみがよはちよにやちよにさざれいしのいわおとなりてこけのむすまで
きりつ/れい/ちゃくせき
きん/ぎん/どう
ぎふ/さが/しが/ちば/なら/みえ
くろ/はい/しろ
ぐー/ちょき/ぱー
こくご/さんすう/りか/しゃかい/えいご
こと/はくちょう/わし
ことざ/はくちょうざ/わしざ
こぶた/たぬき/きつね/ねこ
ごーるど/しるばー/ぶろんず
さとう/しお/す/しょうゆ/みそ
さとう/しお/す/せうゆ/みそ
さんでー/まんでー/ちゅーずでー/うぇんずでー/さーずでー/ふらいでー/さたでー
しかく/きゅうかく/ちょうかく/みかく/しょっかく
しゅ/は/り
しゅん/か/しゅう/とう
しょう/ちく/ばい
しょう/ちゅう/こう/だい
しょう/ちゅう/だい
しりうす/おおいぬ/ぷろきおん/こいぬ/べてるぎうす/おりおん
じゃっく/くいーん/きんぐ
じょう/げ/さ/ゆう
すい/きん/ち/か/もく/ど/てん/かい
すいせい/きんせい/ちきゅう/かせい/もくせい/どせい/てんのうせい/かいおうせい
すいへいりーべぼくのふねななまがりしっぷすくらーくか
すぷりんぐ/さまー/おーたむ/うぃんたー
すぺーど/はーと/だいあ/くらぶ
すぺーど/はーと/だいあ/くろーばー
すぺーど/はーと/だいあもんど/くらぶ
すぺーど/はーと/だいあもんど/くろーばー
すぺーど/はーと/だいや/くらぶ
すぺーど/はーと/だいや/くろーばー
すぺーど/はーと/だいやもんど/くらぶ
すぺーど/はーと/だいやもんど/くろーばー
せき/とう/おう/りょく/せい/らん/し
せり/なずな/ごぎょう/はこべら/ほとけのざ/すずな/すずしろ
ぜん/ご/さ/ゆう
たす/ひく/かける/わる
ちーず/ねずみ/ねこ
ついたち/ふつか/みっか/よっか/いつか/むいか/なのか/ようか/ここのか/とおか/はつか
とう/ざい/なん/ぼく
とん/なん/しゃー/ぺー/はく/はつ/ちゅん
ど/れ/み/ふぁ/そ/ら/し
どーなつ/れもん/みんな/ふぁいと/あおいそら/らっぱ/しあわせ
にち/げつ/か/すい/もく/きん/ど
にちようび/げつようび/かようび/すいようび/もくようび/きんようび/どようび
ね/うし/とら/う/たつ/み/うま/ひつじ/さる/とり/いぬ/い
のぐちひでよ/ひぐちいちよう/ふくざわゆきち
のぐちひでよ/むらさきしきぶ/ひぐちいちよう/ふくざわゆきち
のーす/いーすと/うえすと/さうす
はぎ/おばな/くず/なでしこ/おみなえし/ふじばかま/ききょう
はる/なつ/あき/ふゆ
ばすと/うえすと/ひっぷ
ひ/つき/ひ/みず/き/かね/つち
ひがし/にし/みなみ/きた
ひと/ふた/みっ/よっ/いつ/むっ/なな/やっ/ここの
ひとつ/ふたつ/みっつ/よっつ/いつつ/むっつ/ななつ/やっつ/ここのつ/とお
ひとなみにおごれや
ひとよひとよにひとみごろ
びょう/ふん/じ/にち/しゅう/つき/ねん
びょう/ふん/じ/にち/つき/ねん
ふ/きょう/けい/ぎん/きん/かく/ひ/おう
ふ/きょう/けいま/ぎん/きん/かく/ひしゃ/おう
ふぁーすと/せかんど/さーど
ふくおか/さが/ながさき/おおいた/くまもと/みやざき/かごしま
ふじ/たか/なすび
ふじ/たか/なすび/おうぎ/たばこ/ざとう
ふじさんろくおーむなく
ふひょう/きょうしゃ/けいま/ぎんしょう/きんしょう/かくぎょう/ひしゃ/おう
ぶらっく/ぐれー/ほわいと
べが/でねぶ/あるたいる
べてるぎうす/しりうす/ぷろきおん
ほっかいどう/ほんしゅう/しこく/きゅうしゅう
ほっぷ/すてっぷ/じゃんぷ
まゆげ/まつげ/ひげ/はなげ
まゆげ/め/はな/くち/みみ/かみのけ
まん/おく/ちょう/けい/がい/じょ/じょう/こう/かん/せい/さい/ごく/ごうがしゃ/あそうぎ/なゆた/ふかしぎ/むりょうたいすう
みぎて/ひだりて/みぎあし/ひだりあし
む/す/め/ふ/さ/ほ/せ
むすめふさほせ
むつき/きさらぎ/やよい/うづき/さつき/みなつき/ふづき/はつき/ながつき/かんなづき/しもつき/しわす
むつき/きさらぎ/やよい/うづき/さつき/みなつき/ふみづき/はつき/ながつき/かんなづき/しもつき/しわす
むつき/きさらぎ/やよい/うづき/さつき/みなづき/ふづき/はづき/ながづき/かんなづき/しもつき/しわす
むつき/きさらぎ/やよい/うづき/さつき/みなづき/ふみづき/はづき/ながづき/かんなづき/しもつき/しわす
めいじ/たいしょう/しょうわ/へいせい/れいわ
わ/さ/せき/しょう
わん/つー/すりー/ふぉー/ふぁいぶ/しっくす/せぶん/えいと/ないん/てん
`;
