import { NodeModel } from "@minoru/react-dnd-treeview";

export const isYymmddStyleName = (text: string) => {
  return text.match(/\d{6}_\d{1,4}/);
};

export const getLastId = (treeData: NodeModel[]) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } else if (a.id > b.id) {
      return -1;
    }

    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }

  return 0;
};
