import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
} from "@mui/material";
import { FC, useCallback, useContext } from "react";
import { useWorker } from "../../../hooks/useWorker";
import { readFileAsText } from "../../../lib/readFileAsText";
import { PlainText } from "../common/PlainText";
import { Subtitle, Title } from "../common/Title";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import {
  DictionaryKey,
  dictionaries,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

const Dictionary: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  const worker = useWorker();

  const onChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        const file = files[0];
        const newChecked = [...ctx.enabledDictionary];
        readFileAsText(file).then((text) => {
          worker.loadCustom(text, (result: string[] | undefined) => {
            console.log("load", result?.length, newChecked.length);
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress =
              (((result?.length ?? 0) / newChecked.length) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          });
        });
      }
    },
    [ctx, worker]
  );

  const handleToggle = useCallback(
    (value: string) => () => {
      const key = value as DictionaryKey;
      const currentIndex = ctx.enabledDictionary.indexOf(key);
      const newChecked = [...ctx.enabledDictionary];

      if (currentIndex === -1) {
        newChecked.push(key);
        ctx.setLoaded(false);
        ctx.setLoadProgress(
          ((ctx.enabledDictionary.length / newChecked.length) * 100) | 0
        );
        worker.load(
          key,
          ctx.enabledDictionary.includes("all"),
          (result: string[] | undefined) => {
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress =
              (((result?.length ?? 0) / newChecked.length) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          }
        );
      } else {
        newChecked.splice(currentIndex, 1);
        worker.unload(key, ctx.enabledDictionary.includes("all"));
      }

      ctx.setEnabledDictionary(newChecked);
    },
    [ctx, worker]
  );

  const handleOn = useCallback(
    (_dictionaries: DictionaryKey[]) => {
      worker.unload("all", false);
      (Object.keys(dictionaries) as DictionaryKey[]).forEach(
        (item: DictionaryKey) => {
          if (!_dictionaries.includes(item)) {
            worker.unload(item, false);
          }
        }
      );
      const newChecked = _dictionaries;
      let count = 0;
      _dictionaries.forEach((item: DictionaryKey) => {
        if (!ctx.enabledDictionary.includes(item)) {
          ++count;
          ctx.setLoaded(false);
          ctx.setLoadProgress(
            ((ctx.enabledDictionary.length / count) * 100) | 0
          );
          worker.load(item, false, (result: string[] | undefined) => {
            console.log("load", result?.length, count);
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress = (((result?.length ?? 0) / count) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          });
        }
      });

      if (_dictionaries.includes("all")) {
        worker.load("all", true, (result: string[] | undefined) => {
          console.log("load", result?.length, newChecked.length);
          if (
            result?.sort().join(",") === ctx.enabledDictionary.sort().join(",")
          ) {
            ctx.setLoaded(true);
          }
          const progress =
            (((result?.length ?? 0) / newChecked.length) * 100) | 0;
          ctx.setLoadProgress(Math.min(progress, 200 - progress));
        });
      }
      ctx.setEnabledDictionary(newChecked);
    },
    [ctx, worker]
  );

  const handleOff = useCallback(
    (dictionaries: DictionaryKey[]) => {
      worker.unload("all", false);
      dictionaries.forEach((item: DictionaryKey) => {
        worker.unload(item, false);
      });
      ctx.setEnabledDictionary([]);
    },
    [ctx, worker]
  );

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Title>辞書読み込み</Title>
        <PlainText>検索に使用する辞書を選択してください</PlainText>
        <Box sx={{ mt: 1 }}>
          <Button
            variant="contained"
            onClick={() => {
              handleOff(Object.keys(dictionaries) as DictionaryKey[]);
              handleOn(["buta", "cefr", "common", "illust1"]);
            }}
          >
            推奨設定
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleOn(Object.keys(dictionaries) as DictionaryKey[]);
            }}
            sx={{ ml: 1 }}
          >
            一括ON
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleOff(Object.keys(dictionaries) as DictionaryKey[]);
            }}
            sx={{ ml: 1 }}
          >
            一括OFF
          </Button>
        </Box>
        <List
          sx={{ width: "100%", maxWidth: 480, bgcolor: "background.paper" }}
          subheader={<ListSubheader>読み込む辞書</ListSubheader>}
        >
          {Object.keys(dictionaries)
            .map((e) => e as DictionaryKey)
            .map((item: DictionaryKey, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText
                  id={dictionaries[item].id}
                  primary={
                    dictionaries[item].name +
                    (dictionaries[item].words !== -1
                      ? " (" + dictionaries[item].words + "語)"
                      : "")
                  }
                />
                <Switch
                  edge="end"
                  onChange={handleToggle(dictionaries[item].key)}
                  disabled={dictionaries[item].disabled()}
                  checked={
                    ctx.enabledDictionary.indexOf(
                      dictionaries[item].key as DictionaryKey
                    ) !== -1
                  }
                  inputProps={{
                    "aria-labelledby": dictionaries[item].id,
                  }}
                />
              </ListItem>
            ))}
        </List>
        <PlainText>一括検索はロード済みの辞書のみ一括検索</PlainText>
        <br />
        <Subtitle>カスタム辞書選択</Subtitle>
        <Box>
          <input name="file" type="file" onChange={onChangeFile} />
        </Box>

        <PlainText>ステータス</PlainText>
        <PlainText>
          {ctx.loaded ? "ロード完了" : `ロード中 ${ctx.loadProgress}%`}
        </PlainText>
      </Container>
    </>
  );
};

export default Dictionary;
