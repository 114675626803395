import { SprintAnswerEvent } from "../events";
import { createRotation } from "../rotation";

export const seizaAdvance: SprintAnswerEvent = {
  id: "seizaAdvance",
  title: "星座なんでしょうゲーム",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「おひつじの1個後は？」→「おうし」のように
      <br />
      十二星座をランダムに前後にずらしたものを答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「おひつじの1個後は？」→「おうし」のように
      <br />
      十二星座をランダムに前後にずらしたものを答えます。
      <br />
      全部で10問出題されます。星座名を答えてください
      <br />
      <br />
      【星座の順番】
      <br />
      「おひつじ」→「おうし」→「ふたご」→「かに」→
      <br />
      「しし」→「おとめ」→「てんびん」→「さそり」→
      <br />
      「いて」→「やぎ」→「みずがめ」→「うお」→「おひつじ」→...
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () =>
    createRotation(
      [
        "おひつじ",
        "おうし",
        "ふたご",
        "かに",
        "しし",
        "おとめ",
        "てんびん",
        "さそり",
        "いて",
        "やぎ",
        "みずがめ",
        "うお",
      ],
      10
    ),
  shareButton: true,
  hashTag: "星座なんでしょうゲーム",
};