import { PaletteMode, ThemeOptions } from "@mui/material";
export const studioThemeOptions = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    primary: {
      main: "#673AB7",
    },
    secondary: {
      main: "#f50057",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
        },
        "html, body, #root": {
          height: "100%",
        },
        ul: {
          listStyle: "none",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          verticalAlign: "middle",
        },
      },
    },
  },
});

export const gameThemeOptions = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    primary: {
      main: "#ff9800",
      contrastText: "#212121",
    },
    secondary: {
      main: "#388e3c",
    },
  },
});
