import { progress } from "../generalSearchProgress";
import { regexReplace } from "../regexReplace";
import { anagramDictionaryMatchTransform } from "./anagramDictionaryMatchFilter";
export const parallelTransform = async ({ query, input, words, listDefinitionsTable, result, workerContext, }) => {
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount = 0;
    const ret = [];
    for (const process of query.processes) {
        if (process.type === "regexReplace") {
            const list = await regexReplace(workerContext, process, listDefinitionsTable, result);
            if (process.matchEstimationOperator) {
                list.forEach((e) => {
                    const currentEstimation = e.currentEstimation ?? 1;
                    let nextEstimation;
                    if (process.matchEstimationOperator === "add") {
                        nextEstimation =
                            currentEstimation + parseFloat(process.matchEstimationValue ?? "0");
                    }
                    else {
                        nextEstimation =
                            currentEstimation * parseFloat(process.matchEstimationValue ?? "1");
                    }
                    e.currentEstimation = nextEstimation;
                    e.trace.push(e.currentWord);
                });
            }
            ret.push(list);
        }
        if (process.type === "anagramDictionaryMatch") {
            const list = await anagramDictionaryMatchTransform({
                workerContext,
                input,
                words,
                query: process,
                result,
            });
            if (process.matchEstimationOperator) {
                list.forEach((e) => {
                    const currentEstimation = e.currentEstimation ?? 1;
                    let nextEstimation;
                    if (process.matchEstimationOperator === "add") {
                        nextEstimation =
                            currentEstimation +
                                parseFloat(process.matchEstimationValue ?? "0");
                    }
                    else {
                        nextEstimation =
                            currentEstimation *
                                parseFloat(process.matchEstimationValue ?? "1");
                    }
                    e.currentEstimation = nextEstimation;
                    e.trace.push(e.currentWord);
                });
            }
            ret.push(list);
        }
    }
    result = ret.flat();
    result.forEach((e) => {
        e.trace.push(e.currentWord);
    });
    return result;
};
