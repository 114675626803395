import { useCallback, useContext, useMemo, useState } from "react";
import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";
import { GameinTop } from "../Gamein/GameinTop";
import { HighAndLow } from "../Gamein/HighAndLow/HighAndLow";
import SprintAnswerGame from "../Gamein/SprintAnswer/SprintAnswerGame";
import SprintAnswerMenu from "../Gamein/SprintAnswer/SprintAnswerMenu";
import Dictionary from "../Studio/Dictionary";
import { Anagram } from "../Studio/DictionaryTools/Anagram";
import { Chimata } from "../Studio/DictionaryTools/Chimata";
import { CommonSearch } from "../Studio/DictionaryTools/CommonSearch";
import { GeneralSearch } from "../Studio/DictionaryTools/GeneralSearch/GeneralSearch";
import { GridTool } from "../Studio/GridKit/GridTool/GridTool";
import { AATool } from "../Studio/Lab/AATool";
import { Counter } from "../Studio/Lab/Counter";
import ImageSlicer from "../Studio/Lab/ImageSlicer";
import { KanjiSearch } from "../Studio/Lab/Kanji";
import { KanjiInput } from "../Studio/Lab/KanjiInput";
import { NumToWord } from "../Studio/Lab/NumToWord";
import { RegexInfinitySearch } from "../Studio/Lab/RegexInfinitySearch";
import { SheetSearch } from "../Studio/Lab/SheetSearch";
import { CommonMenu } from "../Studio/Menu/CommonMenu";
import {
  oldToolItems,
  searchMenuItems,
  toolMenuItems,
} from "../Studio/Menu/menuItems";
import { OldPlan } from "../Studio/OldPlan";
import { ProPlan } from "../Studio/ProPlan";
import { StudioTop } from "../Studio/StudioTop";
import { Caesar } from "../Studio/Tools/Caesar";
import { CryptoArithmetic } from "../Studio/Tools/CryptoArithmetic";
import { NthChar } from "../Studio/Tools/NthChar";
import { WordMatching } from "../Studio/WordMatching";
import { CommonBreadcrumbs } from "../common/CommonBreadcrumbs";
import Wrapper from "../common/Wrapper";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";

import { FC } from "react";
import { ProjectContainer } from "../Workspace/ProjectContainer";
import { Projects } from "../Workspace/Projects";
import { FunctionCaller } from "../../CommonStudio/admin/FunctionCaller";
import { ContestPdfBuilder } from "../../LogicPuzzleApp/pStudio/contestPdfBuilder/ContestPdfBuilder";
import { Kobuta } from "../Studio/DictionaryManager/Kobuta";
import { GridKit } from "../Studio/GridKit/GridKit";
import { TextCounter } from "../Studio/Tools/TextCounter";
import { WordGeneratorContainer } from "../Studio/Tools/WordGeneratorContainer";
import { WritingCounter } from "../Studio/Tools/WritingCounter";
import { checkOldPlan } from "../auth/checkOldPlan";
import { Payment } from "../payment/Payment";
import { TweetLinkCreator } from "../Studio/Tools/TweetLinkCreator";

type Props = {};

export const EnigmaStudioRouter: FC<Props> = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  }, [drawerOpen]);
  const drawerState = useMemo(() => {
    return {
      drawerOpen,
      handleDrawerOpen,
      handleDrawerClose,
    };
  }, [drawerOpen, handleDrawerOpen, handleDrawerClose]);
  const param = new URLSearchParams(window.location.search);
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const planKey = param.get("plan") ?? "normal";
  const oldPlan = checkOldPlan(planKey);

  if (oldPlan !== "normal") {
    return <OldPlan />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Wrapper drawer={drawerState}>
              <StudioTop />
            </Wrapper>
          }
        />
        <Route
          path="/dictionary-settings"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/search-menu", title: "検索メニュー" },
                ]}
              />
              <Dictionary />
            </Wrapper>
          }
        />
        <Route
          path="/search-menu"
          element={
            <Wrapper drawer={drawerState}>
              <CommonMenu
                title={"EnigmaStudio 辞書検索"}
                menuItems={searchMenuItems.menuItems}
                breadcrumbs={[{ title: "EnigmaStudio", path: "/" }]}
              />
            </Wrapper>
          }
        />
        <Route
          path="/tool-menu"
          element={
            <Wrapper drawer={drawerState}>
              <CommonMenu
                title={"ツール"}
                menuItems={toolMenuItems.menuItems}
                breadcrumbs={[{ title: "EnigmaStudio", path: "/" }]}
              />
            </Wrapper>
          }
        />
        <Route
          path="/oldtool-menu"
          element={
            <Wrapper drawer={drawerState}>
              <CommonMenu
                title={"旧ツール"}
                menuItems={oldToolItems.menuItems}
                breadcrumbs={[{ title: "EnigmaStudio", path: "/" }]}
              />
            </Wrapper>
          }
        />
        <Route
          path="/common-search"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/search-menu", title: "検索メニュー" },
                ]}
              />
              <CommonSearch />
            </Wrapper>
          }
        />
        <Route
          path="/chimata"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/search-menu", title: "検索メニュー" },
                ]}
              />
              <Chimata />
            </Wrapper>
          }
        />
        <Route
          path="/anagram"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/search-menu", title: "検索メニュー" },
                ]}
              />
              <Anagram />
            </Wrapper>
          }
        />
        <Route
          path="/lab/counter"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              {["debug"].includes(plan) && <Counter />}
            </Wrapper>
          }
        />
        <Route
          path="/word-matching"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/search-menu", title: "検索メニュー" },
                ]}
              />
              <WordMatching />
            </Wrapper>
          }
        />
        <Route
          path="/cryptoarithmetic"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <CryptoArithmetic />
            </Wrapper>
          }
        />
        <Route
          path="/caesar"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <Caesar />
            </Wrapper>
          }
        />
        <Route
          path="/nth-char"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <NthChar />
            </Wrapper>
          }
        />
        <Route
          path="/num-to-word"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <NumToWord />
            </Wrapper>
          }
        />
        <Route
          path="/tweet-link"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <TweetLinkCreator />
            </Wrapper>
          }
        />
        <Route
          path="/word-generator"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <WordGeneratorContainer />
            </Wrapper>
          }
        />
        <Route
          path="/word-generator-lite"
          element={
            <Wrapper drawer={drawerState}>
              <WordGeneratorContainer />
            </Wrapper>
          }
        />
        <Route
          path="/admin"
          element={
            <Wrapper drawer={drawerState}>
              <FunctionCaller />
            </Wrapper>
          }
        />
        <Route
          path="/image-slicer"
          element={
            <Wrapper drawer={drawerState}>
              <ImageSlicer />
            </Wrapper>
          }
        />
        <Route
          path="/payment"
          element={
            <Wrapper drawer={drawerState}>
              <Payment />
            </Wrapper>
          }
        />
        {["pro+", "biz", "biz-trial", "debug"].includes(plan) && (
          <Route
            path="/grid-tool"
            element={
              <Wrapper drawer={drawerState}>
                <GridTool />
              </Wrapper>
            }
          />
        )}
        {["pro+", "biz", "biz-trial", "debug"].includes(plan) && (
          <Route
            path="/gridkit"
            element={
              <Wrapper drawer={drawerState}>
                <GridKit />
              </Wrapper>
            }
          />
        )}
        <Route
          path="/aa-tool"
          element={
            <Wrapper drawer={drawerState}>
              <AATool />
            </Wrapper>
          }
        />
        <Route
          path="/general-search"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/search-menu", title: "検索メニュー" },
                ]}
              />
              <GeneralSearch />
            </Wrapper>
          }
        />
        <Route
          path="/kanji-search"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <KanjiSearch />
            </Wrapper>
          }
        />
        <Route
          path="/kanji-input"
          element={
            <Wrapper drawer={drawerState}>
              <KanjiInput />
            </Wrapper>
          }
        />
        <Route
          path="/text-counter"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <TextCounter />
            </Wrapper>
          }
        />
        <Route
          path="/writing-counter"
          element={
            <Wrapper drawer={drawerState}>
              <CommonBreadcrumbs
                breadcrumbs={[
                  { path: "/", title: "EnigmaStudio" },
                  { path: "/tool-menu", title: "ツールメニュー" },
                ]}
              />
              <WritingCounter />
            </Wrapper>
          }
        />
        <Route
          path="/dict/kobuta"
          element={
            <Wrapper drawer={drawerState}>
              <Kobuta />
            </Wrapper>
          }
        />
        <Route
          path="/puzsq/pdf-builder"
          element={
            <Wrapper drawer={drawerState}>
              <ContestPdfBuilder />
            </Wrapper>
          }
        />
        <Route
          path="/puzsq/projects/:projectId"
          element={
            <Wrapper drawer={drawerState}>
              <ProjectContainer />
            </Wrapper>
          }
        />

        <Route path="/sheet-search/:id/:sheetName" element={<SheetSearch />} />
        <Route
          path="/infinity-search"
          element={
            <Wrapper drawer={drawerState}>
              <RegexInfinitySearch />
            </Wrapper>
          }
        />

        <Route
          path="/pro-plan"
          element={
            <Wrapper drawer={drawerState}>
              <ProPlan />
            </Wrapper>
          }
        />
        <Route
          path="/g"
          element={
            <Wrapper drawer={drawerState}>
              <GameinTop />
            </Wrapper>
          }
        />
        <Route
          path="/g/sprint-answer/:eventId"
          element={
            <Wrapper drawer={drawerState}>
              <SprintAnswerGame />
            </Wrapper>
          }
        />
        <Route
          path="/g/sprint-answer"
          element={
            <Wrapper drawer={drawerState}>
              <SprintAnswerMenu />
            </Wrapper>
          }
        />
        <Route
          path="/g/hikakin-marriage"
          element={
            <Wrapper appbar={false} drawer={drawerState}>
              <HighAndLow drawer={drawerState} />
            </Wrapper>
          }
        />
        <Route
          path="/projects"
          element={
            <Wrapper drawer={drawerState}>
              <Projects />
            </Wrapper>
          }
        />
        <Route
          path="/projects/:projectId"
          element={
            <Wrapper drawer={drawerState}>
              <ProjectContainer />
            </Wrapper>
          }
        />
        <Route
          path="*"
          element={
            <Wrapper drawer={drawerState}>
              <></>
            </Wrapper>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
