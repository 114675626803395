import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { WordObj } from "../gridToolType";
import { ModeType } from "../Menu/ModeSelector";

type TextMenuProps = {
  mode: ModeType | null;
  undo: () => void;
  redo: () => void;
  historyIndex: number;
  history: { raw: string; word: WordObj[] }[];
  setRawWordList: Dispatch<SetStateAction<string>>;
  setWordList: Dispatch<SetStateAction<WordObj[]>>;
  setHistory: Dispatch<SetStateAction<any>>;
  setHistoryIndex: Dispatch<SetStateAction<number>>;
  rawWordList: string;
  displayText: boolean;
  setDisplayText: Dispatch<SetStateAction<boolean>>;
  directionValue: string;
  handleDirectionChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TextMenu: FC<TextMenuProps> = ({
  mode,
  undo,
  redo,
  historyIndex,
  history,
  setRawWordList,
  setWordList,
  setHistory,
  setHistoryIndex,
  rawWordList,
  displayText,
  setDisplayText,
  directionValue,
  handleDirectionChange,
}) => {
  if (mode !== "text") {
    return <></>;
  }

  return (
    <>
      <Box>
        <Button
          variant="contained"
          onClick={undo}
          disabled={historyIndex <= 0}
          sx={{ mr: 2 }}
        >
          元に戻す
        </Button>
        <Button
          variant="contained"
          onClick={redo}
          disabled={historyIndex >= history.length - 1}
          sx={{ mr: 2 }}
        >
          やり直し
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            const ok = window.confirm("盤面をリセットしますか？");
            if (!ok) {
              return;
            }
            setRawWordList("");
            setWordList([]);
            setHistory([]);
            setHistoryIndex(-1);
          }}
          sx={{ mr: 2 }}
        >
          単語を初期化
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const ok = window.confirm("配置を初期化しますか？");
            if (!ok) {
              return;
            }
            setWordList((prev) =>
              prev.map((w, i) => ({
                ...w,
                x: ((i / 50) | 0) * 8,
                y: i % 50,
                direction: "right",
              }))
            );
            setHistory([]);
            setHistoryIndex(-1);
          }}
          sx={{ mr: 2 }}
        >
          配置リセット
        </Button>
      </Box>
      <Box>
        <TextField
          label="単語リスト"
          variant="outlined"
          sx={{ width: "80vw", my: 2 }}
          rows={13}
          multiline
          value={rawWordList}
          onChange={(event) => {
            setRawWordList(event.target.value);
          }}
        />
      </Box>

      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={displayText}
              onChange={(e) => setDisplayText(e.target.checked)}
            />
          }
          sx={{ ml: 0, mt: 1 }}
          label="テキストを表示"
        />
      </Box>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={directionValue}
          onChange={handleDirectionChange}
        >
          <FormControlLabel value="two" control={<Radio />} label="2方向" />
          <FormControlLabel value="four" control={<Radio />} label="4方向" />
          <FormControlLabel value="eight" control={<Radio />} label="8方向" />
        </RadioGroup>
      </FormControl>
    </>
  );
};
