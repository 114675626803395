import {
  Typography,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";

export const HelpLink = (props: { children?: React.ReactNode }) => {
  return (
    <Box sx={{ mt: 1, bgcolor: "background.paper", maxWidth: 800 }}>
      <Typography variant="h6" color={"text.primary"}>
        ヘルプ(リンク集)
      </Typography>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={
                <Link
                  href="https://note.com/1220oz_an/n/nd88c1db24de4"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                >
                  最先端の検索ツール「汎用検索」を使おう(操作説明編)｜フライパン職人
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={
                <Link
                  href="https://note.com/1220oz_an/n/n5b3eb30cdb34"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                >
                  最先端の検索ツール「汎用検索」を使おう(活用編・part1)｜フライパン職人
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={
                <Link
                  href="https://langue-de-chat.llc/enigma-studio-general-search"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                >
                  汎用検索マニュアル
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};
