import { Document, Font, Page, View } from "@react-pdf/renderer";
import { useMemo } from "react";
import { PdfViewItem } from "../stackSpace/PdfViewItem";
import { StackData } from "../stackSpace/stackData";
import { PdfData } from "../stackSpace/stackSpaceData";
import NotoSansJpBold from "./data/fonts/NotoSansJP-Bold.ttf";
import NotoSansJpLight from "./data/fonts/NotoSansJP-Light.ttf";
import NotoSansJPRegular from "./data/fonts/NotoSansJP-Regular.ttf";
import OpenSansBold from "./data/fonts/OpenSans-Bold.ttf";
import OpenSansLight from "./data/fonts/OpenSans-Light.ttf";
import OpenSansRegular from "./data/fonts/OpenSans-Regular.ttf";
import { styles } from "./style";

Font.register({
  family: "NotoSansJP",
  fonts: [
    {
      src: NotoSansJPRegular,
    },
    {
      src: NotoSansJpBold,
      fontWeight: "bold",
    },
    {
      src: NotoSansJpLight,
      fontWeight: "light",
    },
  ],
  //https://react-pdf.org/advanced#page-wrapping
  hyphenationCallback: (word: any) => [word],
});
Font.register({
  family: "OpenSans",
  fonts: [
    {
      src: OpenSansRegular,
    },
    {
      src: OpenSansBold,
      fontWeight: "bold",
    },
    {
      src: OpenSansLight,
      fontWeight: "light",
    },
  ],
});

export type PdfConfig = {
  showAuthor: boolean;
  language: "ja" | "en";
  showProblem: boolean;
  showRule: boolean;
  showExample: boolean;
};

export const PDF = (props: { data: PdfData }) => {
  const pageData = useMemo(() => {
    const result: StackData[][] = [[]];
    let page = 0;
    for (let i = 0; i < props.data.pages.list.length; i++) {
      const pageData = props.data.pages.list[i];
      switch (pageData.type) {
        case "pageDivider":
          page++;
          result.push([]);
          break;
        default:
          result[page].push(pageData);
      }
    }
    return result;
  }, [props.data.pages.list]);
  return (
    <Document title={props.data.title}>
      {pageData.map((page, index) => (
        <Page size="A4" style={styles.pageja} key={index}>
          <View style={styles.header}>
            {pageCountHandler(
              props.data.header.list,
              index,
              pageData.length
            ).map((content) => (
              <PdfViewItem content={content} />
            ))}
          </View>
          <View style={styles.body}>
            {page.map((content) => (
              <PdfViewItem content={content} />
            ))}
          </View>
          <View style={styles.footer}>
            {pageCountHandler(
              props.data.footer.list,
              index,
              pageData.length
            ).map((content) => (
              <PdfViewItem content={content} />
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

const pageCountHandler = (
  stackData: StackData[],
  page: number,
  total: number
) => {
  const stackDataCopy = JSON.parse(JSON.stringify(stackData)) as StackData[];
  stackDataCopy.forEach((data) => {
    if (data.type === "text") {
      data.text = data.text.replace("@@PAGE_COUNT@@", (page + 1).toString());
      data.text = data.text.replace("@@PAGE_TOTAL@@", total.toString());
    } else if (data.type === "horizon") {
      data.list = pageCountHandler(data.list, page, total) as StackData[];
    }
  });
  return stackDataCopy;
};
