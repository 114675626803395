import { city } from "../lib/wordlist/city";
import { town } from "../lib/wordlist/town";
import { village } from "../lib/wordlist/village";

export const smallWordList: { [key: string]: string } = {
  あいう: `あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん`,
  濁音半濁音: `がぎぐげござじずぜぞだぢづでどばびぶべぼぱぴぷぺぽ`,
  五十音各段ひらがな: `あかさたなはまやらわ
いきしちにひみり
うくすつぬふむゆる
えけせてねへめれ
おこそとのほもよろ`,
  五十音濁音半濁各段ひらがな: `がざだばぱ
ぎじぢびぴ
ぐずづぶぷ
げぜでべぺ
ごぞどぼぽ`,
  五十音表ひらがな: `んわらやまはなたさかあ
　　り　みひにちしきい
　　るゆむふぬつすくう
　　れ　めへねてせけえ
　をろよもほのとそこお`,
  五十音表濁半: `ぱばだざがんわらやまはなたさかあ
ぴびぢじぎ　　り　みひにちしきい
ぷぶづずぐ　　るゆむふぬつすくう
ぺべでぜげ　　れ　めへねてせけえ
ぽぼどぞご　をろよもほのとそこお`,
  いろは: `いろはにほへとちりぬるをわかよたれそつねならむうゐのおくやまけふこえてあさきゆめみしゑひもせす`,
  ABC: `abcdefghijklmnopqrstuvwxyz`,
  えーびーしー: `えー
びー
しー
でぃー
いー
えふ
じー
えいち
あい
じぇー
けー
える
えむ
えぬ
おー
ぴー
きゅー
あーる
えす
てぃー
ゆー
ぶい
だぶりゅー
えっくす
わい
ぜっと`,
  干支: `子丑寅卯辰巳午未申酉戌亥`,
  えと: `ね
うし
とら
う
たつ
み
うま
ひつじ
さる
とり
いぬ
い`,
  十二星座ひらがな: `おひつじ
おうし
ふたご
かに
しし
おとめ
てんびん
さそり
いて
やぎ
みずがめ
うお`,
  十二星座en: `aries
taurus
gemini
cancer
leo
virgo
libra
scorpio
sagittarius
capricorn
aquarius
pisces`,
  八十八星座ひらがな: `あんどろめだ
いっかくじゅう
いて
いるか
いんでぃあん
うお
うさぎ
うしかい
うみへび
えりだぬす
おうし
おおいぬ
おおかみ
おおぐま
おとめ
おひつじ
おりおん
がか
かしおぺあ
かじき
かに
かみのけ
かめれおん
からす
かんむり
きょしちょう
ぎょしゃ
きりん
くじゃく
くじら
けふぇうす
けんたうるす
けんびきょう
こいぬ
こうま
こぎつね
こぐま
こじし
こっぷ
こと
こんぱす
さいだん
さそり
さんかく
しし
じょうぎ
たて
ちょうこくぐ
ちょうこくしつ
つる
てーぶるさん
てんびん
とかげ
とけい
とびうお
とも
はえ
はくちょう
はちぶんぎ
はと
ふうちょう
ふたご
ぺがすす
へび
へびつかい
へるくれす
ぺるせうす
ほ
ぼうえんきょう
ほうおう
ぽんぷ
みずがめ
みずへび
みなみじゅうじ
みなみのうお
みなみのかんむり
みなみのさんかく
や
やぎ
やまねこ
らりるれろ
りゅう
りゅうこつ
りょうけん
れちくる
ろ
ろくぶんぎ
わし`,
  一から十ひらがな: `いち
に
さん
よん
ご
ろく
なな
はち
きゅう
じゅう`,
  数字ひらがな: `いち
に
さん
よん
し
ご
ろく
なな
はち
きゅう
じゅう
ひゃく
せん
まん
おく
ちょう
けい
がい
じょ
じょう
こう
かん
せい
さい
ごく
ごうがしゃ
あそうぎ
なゆた
ふかしぎ
むりょうたいすう`,
  OneToTen: `one
two
three
four
five
six
seven
eight
nine
ten`,
  ワントゥテン: `ワン
ツー
スリー
フォー
ファイブ
シックス
セブン
エイト
ナイン
テン`,
  都道府県ひらがな: `ほっかいどう
あおもり
いわて
あきた
みやぎ
やまがた
ふくしま
いばらき
とちぎ
ぐんま
さいたま
ちば
とうきょう
かながわ
にいがた
とやま
いしかわ
ふくい
やまなし
ながの
ぎふ
しずおか
あいち
しが
きょうと
おおさか
ひょうご
なら
みえ
わかやま
とっとり
しまね
おかやま
ひろしま
やまぐち
とくしま
かがわ
えひめ
こうち
ふくおか
さが
ながさき
おおいた
くまもと
みやざき
かごしま
おきなわ`,
  県庁所在地ひらがな: `さっぽろ
あおもり
もりおか
せんだい
あきた
やまがた
ふくしま
みと
うつのみや
まえばし
さいたま
ちば
とうきょう
よこはま
にいがた
とやま
かなざわ
ふくい
こうふ
ながの
ぎふ
しずおか
なごや
つ
おおつ
きょうと
おおさか
こうべ
なら
わかやま
とっとり
まつえ
おかやま
ひろしま
やまぐち
とくしま
たかまつ
まつやま
こうち
ふくおか
さが
ながさき
くまもと
おおいた
みやざき
かごしま
なは`,
  日本の市: city,
  日本の町: town,
  日本の村: village,
  春夏秋冬_訓: `はる
なつ
あき
ふゆ`,
  春夏秋冬_音: `しゅん
か
しゅう
とう`,
  春夏秋冬_英: `spring
summer
autumn
winter`,
  四季_英語_よみ: `すぷりんぐ
さまー
おーたむ
うぃんたー`,
};
