"use client";
import {
  DndProvider,
  MultiBackend,
  NodeModel,
  Tree,
  getBackendOptions,
  getDescendants,
} from "@minoru/react-dnd-treeview";
import {
  Box,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { SidebarMenu } from "../../../../_lib/eniverse/imaginate/SidebarMenu";
import { CustomDragPreview } from "../../../../_lib/eniverse/imaginate/treeView/CustomDragPreview";
import { CustomNode } from "../../../../_lib/eniverse/imaginate/treeView/CustomNode";
import { Placeholder } from "../../../../_lib/eniverse/imaginate/treeView/Placeholder";
import {
  CustomData,
  TreeCommand,
} from "../../../../_lib/eniverse/imaginate/treeView/types";
import {
  getLastId,
  isYymmddStyleName,
} from "../../../../_lib/eniverse/imaginate/treeView/util";
import { randomKeyGen } from "../../../../_lib/eniverse/util/keygen";
import styles from "../../../../_lib/eniverse/imaginate/treeView/App.module.css";

type SidebarProps = {
  children?: React.ReactNode;
  drawerOpen: boolean;
  handleDrawerClose: () => void;
  treeData: NodeModel<CustomData>[];
  setTreeData: (tree: NodeModel<CustomData>[]) => void;
  selectedNode: NodeModel<CustomData> | null;
  setSelectedNode: (node: NodeModel<CustomData>) => void;
  treeCommand: TreeCommand;
};

export const InnerSidebar: React.FC<SidebarProps> = ({
  drawerOpen,
  handleDrawerClose,
  treeData,
  setTreeData,
  selectedNode,
  setSelectedNode,
  treeCommand,
}) => {
  const theme = useTheme();
  const { addNode } = treeCommand;
  const handleDrop = (newTree: NodeModel[]) =>
    setTreeData(newTree as NodeModel<CustomData>[]);
  const handleSelect = (node: NodeModel) =>
    setSelectedNode(node as NodeModel<CustomData>);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuId, setMenuId] = useState<NodeModel["id"] | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    id: NodeModel["id"]
  ) => {
    setAnchorEl(event.target as HTMLElement);
    setMenuId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuId(null);
  };

  const handleAdd = (id: NodeModel["id"]) => {
    addNode(id, treeData);
  };

  const handleCopy = (id: NodeModel["id"]) => {
    const lastId = Number(getLastId(treeData));
    const targetNode = treeData.find((n) => n.id === id);
    if (!targetNode) return;
    const descendants = getDescendants(treeData, id).map(
      (node) => node as NodeModel<CustomData>
    );

    const partialTree = descendants.map((node: NodeModel<CustomData>) => ({
      ...node,
      id: Number(node.id) + lastId,
      parent: Number(node.parent) + lastId,
    }));

    const newNode = {
      ...targetNode,
      id: Number(targetNode.id) + lastId,
    };
    if (!newNode.data) return;
    if (isYymmddStyleName(newNode.text)) {
      const yymmdd = new Date().toISOString().slice(2, 10).replace(/-/g, "");

      // yymmdd_lastId
      const yymmddLastId =
        treeData
          .filter((node) => node.text.includes(yymmdd))
          .map((node) => Number(node.text.split("_")[1]))
          .sort((a, b) => Number(b) - Number(a))[0] ?? 0;
      const newId = yymmdd + "_" + ("" + (yymmddLastId + 1)).padStart(3, "0");
      newNode.text = newId;
    } else {
      newNode.text = newNode.text + "_copy";
    }
    newNode.data.refContentsId = newNode.data.contentsId;
    newNode.data.contentsId = randomKeyGen(16);

    partialTree.forEach((node) => {
      if (!node.data) return;
      node.data.refContentsId = node.data.contentsId;
      node.data.contentsId = randomKeyGen(16);
    });

    setTreeData([...treeData, newNode, ...partialTree]);
  };

  const handleDelete = (id: NodeModel["id"]) => {
    const deletedNode = treeData.find((node) => node.id === id);
    if (!deletedNode) return;
    const parentNode = treeData.find((node) => node.id === deletedNode.parent);
    if (!parentNode) return;
    const deleteIds = [
      id,
      ...getDescendants(treeData, id).map((node) => node.id),
    ];
    const newTree = treeData.filter((node) => !deleteIds.includes(node.id));

    setTreeData(newTree);
    setSelectedNode(parentNode);
  };

  return (
    <>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Box className={styles.app}>
          <Tree
            tree={treeData}
            rootId={0}
            render={(node, { depth, isOpen, onToggle }) => (
              <CustomNode
                node={node}
                depth={depth}
                isOpen={isOpen}
                isSelected={node.id === selectedNode?.id}
                onToggle={onToggle}
                onSelect={handleSelect}
                onMenu={handleClick}
                onAdd={handleAdd}
                onCopy={handleCopy}
              />
            )}
            dragPreviewRender={(monitorProps) => (
              <CustomDragPreview monitorProps={monitorProps} />
            )}
            onDrop={handleDrop}
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
              placeholder: styles.placeholderContainer,
            }}
            sort={false}
            insertDroppableFirst={false}
            canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
              if (dragSource?.parent === dropTargetId) {
                return true;
              }
            }}
            dropTargetOffset={10}
            placeholderRender={(node, { depth }) => (
              <Placeholder node={node} depth={depth} />
            )}
          />
        </Box>
      </DndProvider>
      <SidebarMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        menuId={menuId}
      />
    </>
  );
};

export const drawerWidth = 300;
