import { estimateFilter, filter } from "../commonLoop";
import { createRegex } from "../exRegexp";
export const regexFilter = async ({ query, input, listDefinitionsTable, result, workerContext, }) => {
    const reg = createRegex(query.pattern, listDefinitionsTable);
    const filterFunc = (e) => {
        return ((query.notMatch && !e.currentWord.match(reg)) ||
            (!query.notMatch && !!e.currentWord.match(reg)));
    };
    if (input.estimation) {
        return estimateFilter(workerContext, result, filterFunc, input.estimation, query);
    }
    return filter(workerContext, result, filterFunc);
};
