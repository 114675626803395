import { useMemo } from "react";
import { CrossPoint, WhiteLineObj } from "../gridToolType";

export const useLine = (
  whiteList:
    | {
        x: number;
        y: number;
      }[]
) => {
  const whiteLines = useMemo(() => {
    let lines: WhiteLineObj[] = [];

    // ソートして隣接する白マスを検出しやすくする
    let sortedByX = [...whiteList].sort((a, b) => a.x - b.x || a.y - b.y);
    let sortedByY = [...whiteList].sort((a, b) => a.y - b.y || a.x - b.x);

    let count = 0;
    // 縦方向の白マスを検出（sortedByXを使用）
    for (let i = 0; i < sortedByX.length; i++) {
      let length = 1;
      while (
        i + length < sortedByX.length &&
        sortedByX[i + length].x === sortedByX[i].x &&
        sortedByX[i + length].y === sortedByX[i].y + length
      ) {
        length++;
      }
      if (length > 1) {
        lines.push({
          x: sortedByX[i].x,
          y: sortedByX[i].y,
          index: count++,
          length,
          direction: "down",
        });
        i += length - 1;
      }
    }

    // 横方向の白マスを検出（sortedByYを使用）
    for (let i = 0; i < sortedByY.length; i++) {
      let length = 1;
      while (
        i + length < sortedByY.length &&
        sortedByY[i + length].y === sortedByY[i].y &&
        sortedByY[i + length].x === sortedByY[i].x + length
      ) {
        length++;
      }
      if (length > 1) {
        lines.push({
          x: sortedByY[i].x,
          y: sortedByY[i].y,
          index: count++,
          length,
          direction: "right",
        });
        i += length - 1;
      }
    }
    return lines;
  }, [whiteList]);

  const crossPointList: CrossPoint[] = useMemo(() => {
    let crossPoints: CrossPoint[] = [];
    let downLines = whiteLines.filter((line) => line.direction === "down");
    let rightLines = whiteLines.filter((line) => line.direction === "right");

    downLines.forEach((downLine) => {
      rightLines.forEach((rightLine) => {
        // 縦線のy範囲と横線のx範囲をチェック
        if (
          downLine.x >= rightLine.x &&
          downLine.x < rightLine.x + rightLine.length &&
          rightLine.y >= downLine.y &&
          rightLine.y < downLine.y + downLine.length
        ) {
          crossPoints.push({
            x: downLine.x,
            y: rightLine.y,
            rightIndex: rightLine.index,
            downIndex: downLine.index,
          });
        }
      });
    });

    return crossPoints;
  }, [whiteLines]);

  const rightIndexCrossPointTable: { [key: number]: CrossPoint[] } =
    useMemo(() => {
      const ret: { [key: number]: CrossPoint[] } = {};
      for (const crossPoint of crossPointList) {
        if (!ret[crossPoint.rightIndex]) {
          ret[crossPoint.rightIndex] = [];
        }
        ret[crossPoint.rightIndex].push(crossPoint);
      }
      return ret;
    }, [crossPointList]);

  const downIndexCrossPointTable: { [key: number]: CrossPoint[] } =
    useMemo(() => {
      const ret: { [key: number]: CrossPoint[] } = {};
      for (const crossPoint of crossPointList) {
        if (!ret[crossPoint.downIndex]) {
          ret[crossPoint.downIndex] = [];
        }
        ret[crossPoint.downIndex].push(crossPoint);
      }
      return ret;
    }, [crossPointList]);

  return {
    whiteLines,
    crossPointList,
    rightIndexCrossPointTable,
    downIndexCrossPointTable,
  };
};
