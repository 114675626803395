import { Question } from "./events";

export const createRotation = (
  data: string[],
  problemCount: number
): Question[] => {
  const questions: Question[] = [];
  const shuffled = [...Array(data.length)]
    .map((_, i) => [i, Math.random()])
    .sort((a, b) => a[1] - b[1])
    .map((e) => e[0]);

  const shuffledNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    .map((e, i) => [e, Math.random()])
    .sort((a, b) => a[1] - b[1])
    .map((e) => e[0]);

  const isAdvance = [...Array(problemCount)].map((_, i) => Math.random() > 0.5);

  const answers = [...Array(problemCount)].map((_, i) => {
    const index =
      (shuffled[i] + shuffledNumber[i] * (isAdvance[i] ? 1 : -1) + 12) % 12;
    return data[index];
  });

  for (let i = 0; i < 10; i++) {
    const question = {
      id: i,
      problemText: `${data[shuffled[i]]}の${shuffledNumber[i]}${
        isAdvance[i] ? "個後" : "個前"
      }は？`,
      answers: [answers[i]],
    };
    questions.push(question);
  }
  return questions;
};
