export const word4kana = [
  ["1 2 1 2", "あいあい"],
  ["1 2 2 43", "あいいろ"],
  ["1 2 11 18", "あいさつ"],
  ["1 2 25 40", "あいのり"],
  ["1 2 43 46", "あいろん"],
  ["1 5 2 43", "あおいろ"],
  ["1 5 5 22", "あおおに"],
  ["1 5 33 12", "あおむし"],
  ["1 5 35 40", "あおもり"],
  ["1 6 2 43", "あかいろ"],
  ["1 6 5 22", "あかおに"],
  ["1 6 13 40", "あかすり"],
  ["1 6 32 15", "あかみそ"],
  ["1 7 6 46", "あきかん"],
  ["1 8 14 41", "あくせる"],
  ["1 12 1 20", "あしあと"],
  ["1 12 5 20", "あしおと"],
  ["1 14 43 39", "あせろら"],
  ["1 22 31 41", "あにまる"],
  ["1 24 35 24", "あねもね"],
  ["1 28 40 6", "あふりか"],
  ["1 31 35 40", "あまもり"],
  ["1 32 35 25", "あみもの"],
  ["1 34 28 20", "あめふと"],
  ["1 34 40 6", "あめりか"],
  ["1 36 20 40", "あやとり"],
  ["1 40 8 2", "ありくい"],
  ["1 40 25 13", "ありのす"],
  ["1 44 35 40", "あわもり"],
  ["1 46 6 9", "あんかけ"],
  ["1 46 10 3", "あんこう"],
  ["1 46 19 21", "あんてな"],
  ["1 46 21 2", "あんない"],
  ["1 46 31 46", "あんまん"],
  ["1 46 32 18", "あんみつ"],
  ["2 6 36 7", "いかやき"],
  ["2 12 6 44", "いしかわ"],
  ["2 16 31 4", "いたまえ"],
  ["2 16 40 1", "いたりあ"],
  ["2 16 44 40", "いたわり"],
  ["2 20 25 10", "いとのこ"],
  ["2 20 31 7", "いとまき"],
  ["2 23 6 7", "いぬかき"],
  ["2 24 33 40", "いねむり"],
  ["2 25 12 12", "いのしし"],
  ["2 35 3 20", "いもうと"],
  ["2 35 30 40", "いもほり"],
  ["2 35 33 12", "いもむし"],
  ["2 36 30 46", "いやほん"],
  ["2 39 13 20", "いらすと"],
  ["2 46 6 46", "いんかん"],
  ["2 46 11 18", "いんさつ"],
  ["3 2 41 13", "ういるす"],
  ["3 2 43 3", "ういろう"],
  ["3 2 46 8", "ういんく"],
  ["3 8 42 42", "うくれれ"],
  ["3 9 18 9", "うけつけ"],
  ["3 11 32 32", "うさみみ"],
  ["3 32 3 12", "うみうし"],
  ["3 32 25 27", "うみのひ"],
  ["3 39 21 2", "うらない"],
  ["3 39 23 13", "うらぬす"],
  ["3 46 19 46", "うんてん"],
  ["4 1 10 46", "えあこん"],
  ["4 8 13 19", "えくすて"],
  ["4 8 42 1", "えくれあ"],
  ["4 28 44 46", "えふわん"],
  ["4 46 6 2", "えんかい"],
  ["4 46 15 3", "えんそう"],
  ["4 46 15 8", "えんそく"],
  ["4 46 20 18", "えんとつ"],
  ["4 46 36 13", "えんやす"],
  ["5 1 12 13", "おあしす"],
  ["5 3 4 46", "おうえん"],
  ["5 3 6 46", "おうかん"],
  ["5 3 11 31", "おうさま"],
  ["5 3 12 46", "おうしん"],
  ["5 4 6 7", "おえかき"],
  ["5 5 2 16", "おおいた"],
  ["5 5 6 32", "おおかみ"],
  ["5 5 11 6", "おおさか"],
  ["5 5 20 43", "おおとろ"],
  ["5 5 35 40", "おおもり"],
  ["5 6 36 31", "おかやま"],
  ["5 6 40 21", "おかりな"],
  ["5 7 21 44", "おきなわ"],
  ["5 12 2 42", "おしいれ"],
  ["5 12 41 10", "おしるこ"],
  ["5 12 46 10", "おしんこ"],
  ["5 20 3 20", "おとうと"],
  ["5 20 18 2", "おとつい"],
  ["5 20 20 2", "おととい"],
  ["5 20 35 42", "おともれ"],
  ["5 22 5 46", "おにおん"],
  ["5 27 16 12", "おひたし"],
  ["5 32 1 2", "おみあい"],
  ["5 32 10 12", "おみこし"],
  ["5 32 31 2", "おみまい"],
  ["5 33 42 18", "おむれつ"],
  ["5 35 39 12", "おもらし"],
  ["5 40 27 34", "おりひめ"],
  ["5 46 14 46", "おんせん"],
  ["6 2 11 18", "かいさつ"],
  ["6 2 14 46", "かいせん"],
  ["6 2 15 3", "かいそう"],
  ["6 2 19 46", "かいてん"],
  ["6 2 27 35", "かいひも"],
  ["6 2 35 25", "かいもの"],
  ["6 2 44 42", "かいわれ"],
  ["6 8 19 41", "かくてる"],
  ["6 8 35 17", "かくもち"],
  ["6 12 18 7", "かしつき"],
  ["6 13 19 39", "かすてら"],
  ["6 16 6 21", "かたかな"],
  ["6 16 10 40", "かたこり"],
  ["6 16 23 7", "かたぬき"],
  ["6 16 35 32", "かたもみ"],
  ["6 17 46 10", "かちんこ"],
  ["6 22 6 31", "かにかま"],
  ["6 22 32 15", "かにみそ"],
  ["6 24 18 7", "かねつき"],
  ["6 24 35 17", "かねもち"],
  ["6 31 7 40", "かまきり"],
  ["6 31 34 12", "かまめし"],
  ["6 32 11 31", "かみさま"],
  ["6 32 15 40", "かみそり"],
  ["6 32 21 40", "かみなり"],
  ["6 32 25 9", "かみのけ"],
  ["6 34 33 12", "かめむし"],
  ["6 35 12 6", "かもしか"],
  ["6 35 21 13", "かもなす"],
  ["6 39 5 9", "からおけ"],
  ["6 39 6 11", "からかさ"],
  ["6 39 10 46", "からこん"],
  ["6 44 3 15", "かわうそ"],
  ["6 44 14 32", "かわせみ"],
  ["6 44 33 7", "かわむき"],
  ["6 46 5 9", "かんおけ"],
  ["6 46 7 40", "かんきり"],
  ["6 46 9 40", "かんけり"],
  ["6 46 10 3", "かんこう"],
  ["6 46 10 8", "かんこく"],
  ["6 46 11 2", "かんさい"],
  ["6 46 14 18", "かんせつ"],
  ["6 46 14 46", "かんせん"],
  ["6 46 16 2", "かんたい"],
  ["6 46 19 2", "かんてい"],
  ["6 46 19 39", "かんてら"],
  ["6 46 20 3", "かんとう"],
  ["6 46 20 8", "かんとく"],
  ["6 46 23 12", "かんぬし"],
  ["6 46 33 40", "かんむり"],
  ["6 46 42 7", "かんれき"],
  ["7 7 32 32", "ききみみ"],
  ["7 12 34 46", "きしめん"],
  ["7 18 18 7", "きつつき"],
  ["7 40 35 17", "きりもち"],
  ["7 46 2 43", "きんいろ"],
  ["7 46 4 46", "きんえん"],
  ["7 46 6 8", "きんかく"],
  ["7 46 14 2", "きんせい"],
  ["7 46 20 42", "きんとれ"],
  ["7 46 22 8", "きんにく"],
  ["7 46 38 3", "きんよう"],
  ["8 3 10 3", "くうこう"],
  ["8 3 28 8", "くうふく"],
  ["8 12 6 18", "くしかつ"],
  ["8 18 12 16", "くつした"],
  ["8 25 2 17", "くのいち"],
  ["8 31 25 32", "くまのみ"],
  ["8 31 35 20", "くまもと"],
  ["8 35 25 13", "くものす"],
  ["8 39 3 46", "くらうん"],
  ["8 40 5 24", "くりおね"],
  ["8 42 38 46", "くれよん"],
  ["8 43 2 43", "くろいろ"],
  ["8 43 5 22", "くろおに"],
  ["8 43 6 32", "くろかみ"],
  ["8 43 24 10", "くろねこ"],
  ["8 43 28 24", "くろふね"],
  ["8 43 31 34", "くろまめ"],
  ["8 46 42 46", "くんれん"],
  ["9 2 6 46", "けいかん"],
  ["9 2 11 18", "けいさつ"],
  ["9 2 16 2", "けいたい"],
  ["9 2 40 46", "けいりん"],
  ["9 2 42 46", "けいれん"],
  ["9 18 1 18", "けつあつ"],
  ["9 18 4 7", "けつえき"],
  ["9 46 9 18", "けんけつ"],
  ["9 46 10 3", "けんこう"],
  ["10 3 4 46", "こうえん"],
  ["10 3 6 46", "こうかん"],
  ["10 3 10 3", "こうこう"],
  ["10 3 12 46", "こうしん"],
  ["10 3 13 2", "こうすい"],
  ["10 3 19 2", "こうてい"],
  ["10 3 19 18", "こうてつ"],
  ["10 3 32 46", "こうみん"],
  ["10 3 35 40", "こうもり"],
  ["10 3 35 46", "こうもん"],
  ["10 3 38 3", "こうよう"],
  ["10 8 26 8", "こくはく"],
  ["10 10 21 18", "ここなつ"],
  ["10 13 35 13", "こすもす"],
  ["10 31 2 23", "こまいぬ"],
  ["10 31 18 21", "こまつな"],
  ["10 43 10 43", "ころころ"],
  ["10 46 2 43", "こんいろ"],
  ["10 46 15 34", "こんそめ"],
  ["10 46 19 21", "こんてな"],
  ["11 2 4 46", "さいえん"],
  ["11 2 7 46", "さいきん"],
  ["11 2 9 18", "さいけつ"],
  ["11 2 10 43", "さいころ"],
  ["11 2 14 2", "さいせい"],
  ["11 2 14 46", "さいせん"],
  ["11 2 16 31", "さいたま"],
  ["11 2 30 3", "さいほう"],
  ["11 6 32 17", "さかみち"],
  ["11 7 2 6", "さきいか"],
  ["11 11 6 31", "ささかま"],
  ["11 11 25 26", "ささのは"],
  ["11 18 4 2", "さつえい"],
  ["11 20 2 35", "さといも"],
  ["11 28 39 46", "さふらん"],
  ["11 33 39 2", "さむらい"],
  ["11 46 6 8", "さんかく"],
  ["11 46 11 2", "さんさい"],
  ["11 46 12 46", "さんしん"],
  ["11 46 13 3", "さんすう"],
  ["11 46 22 46", "さんにん"],
  ["11 46 39 46", "さんらん"],
  ["12 2 16 9", "しいたけ"],
  ["12 12 20 3", "ししとう"],
  ["12 12 31 2", "ししまい"],
  ["12 17 22 46", "しちにん"],
  ["12 17 40 46", "しちりん"],
  ["12 18 42 46", "しつれん"],
  ["12 31 3 31", "しまうま"],
  ["12 31 40 13", "しまりす"],
  ["12 34 21 44", "しめなわ"],
  ["12 35 18 7", "しもつき"],
  ["12 39 16 7", "しらたき"],
  ["12 40 1 41", "しりある"],
  ["12 43 1 40", "しろあり"],
  ["12 43 2 43", "しろいろ"],
  ["12 43 8 31", "しろくま"],
  ["12 43 24 10", "しろねこ"],
  ["12 43 32 15", "しろみそ"],
  ["12 46 6 2", "しんかい"],
  ["12 46 10 46", "しんこん"],
  ["12 46 11 18", "しんさつ"],
  ["12 46 12 18", "しんしつ"],
  ["12 46 17 8", "しんちく"],
  ["12 46 37 3", "しんゆう"],
  ["12 46 43 3", "しんろう"],
  ["13 2 4 2", "すいえい"],
  ["13 2 14 2", "すいせい"],
  ["13 2 14 46", "すいせん"],
  ["13 2 15 3", "すいそう"],
  ["13 2 20 3", "すいとう"],
  ["13 2 29 2", "すいへい"],
  ["13 2 32 46", "すいみん"],
  ["13 2 38 3", "すいよう"],
  ["13 3 42 18", "すうれつ"],
  ["13 6 46 8", "すかんく"],
  ["13 7 36 7", "すきやき"],
  ["13 8 4 1", "すくえあ"],
  ["13 9 43 8", "すけろく"],
  ["13 19 2 23", "すていぬ"],
  ["13 19 24 10", "すてねこ"],
  ["13 39 2 33", "すらいむ"],
  ["14 2 3 17", "せいうち"],
  ["14 2 16 2", "せいたい"],
  ["14 2 20 46", "せいとん"],
  ["14 2 28 8", "せいふく"],
  ["14 2 37 3", "せいゆう"],
  ["14 2 42 2", "せいれい"],
  ["14 6 2 12", "せかいし"],
  ["14 7 14 18", "せきせつ"],
  ["14 7 19 2", "せきてい"],
  ["14 7 26 46", "せきはん"],
  ["14 46 10 3", "せんこう"],
  ["14 46 13 2", "せんすい"],
  ["14 46 14 2", "せんせい"],
  ["14 46 15 3", "せんそう"],
  ["14 46 16 8", "せんたく"],
  ["14 46 20 3", "せんとう"],
  ["14 46 23 7", "せんぬき"],
  ["15 3 5 46", "そうおん"],
  ["15 3 12 7", "そうしき"],
  ["15 3 15 28", "そうそふ"],
  ["15 3 21 46", "そうなん"],
  ["15 3 34 46", "そうめん"],
  ["15 18 4 46", "そつえん"],
  ["15 33 40 4", "そむりえ"],
  ["16 2 2 46", "たいいん"],
  ["16 2 6 2", "たいかい"],
  ["16 2 15 3", "たいそう"],
  ["16 2 28 3", "たいふう"],
  ["16 2 30 3", "たいほう"],
  ["16 2 36 7", "たいやき"],
  ["16 2 38 3", "たいよう"],
  ["16 2 40 8", "たいりく"],
  ["16 2 44 46", "たいわん"],
  ["16 6 31 18", "たかまつ"],
  ["16 8 1 46", "たくあん"],
  ["16 9 3 31", "たけうま"],
  ["16 9 25 10", "たけのこ"],
  ["16 10 36 7", "たこやき"],
  ["16 17 3 5", "たちうお"],
  ["16 18 31 7", "たつまき"],
  ["16 19 35 25", "たてもの"],
  ["16 31 33 12", "たまむし"],
  ["16 39 25 34", "たらのめ"],
  ["16 46 11 46", "たんさん"],
  ["16 46 12 46", "たんしん"],
  ["16 46 19 2", "たんてい"],
  ["17 4 25 44", "ちえのわ"],
  ["17 8 16 46", "ちくたん"],
  ["17 8 40 46", "ちくりん"],
  ["17 17 5 36", "ちちおや"],
  ["17 17 25 27", "ちちのひ"],
  ["17 40 20 40", "ちりとり"],
  ["17 46 17 39", "ちんちら"],
  ["18 3 7 46", "つうきん"],
  ["18 3 36 8", "つうやく"],
  ["18 9 34 46", "つけめん"],
  ["18 9 35 25", "つけもの"],
  ["18 17 25 10", "つちのこ"],
  ["18 21 31 38", "つなまよ"],
  ["18 34 7 40", "つめきり"],
  ["18 40 6 44", "つりかわ"],
  ["19 1 39 2", "てあらい"],
  ["19 25 10 3", "てのこう"],
  ["19 25 27 39", "てのひら"],
  ["19 46 2 46", "てんいん"],
  ["19 46 19 7", "てんてき"],
  ["19 46 33 13", "てんむす"],
  ["20 3 6 2", "とうかい"],
  ["20 3 10 3", "とうこう"],
  ["20 3 19 46", "とうてん"],
  ["20 3 30 8", "とうほく"],
  ["20 3 32 46", "とうみん"],
  ["20 3 34 2", "とうめい"],
  ["20 3 43 3", "とうろう"],
  ["20 8 12 31", "とくしま"],
  ["20 21 6 2", "となかい"],
  ["20 25 11 31", "とのさま"],
  ["20 39 46 8", "とらんく"],
  ["20 40 22 8", "とりにく"],
  ["20 42 2 46", "とれいん"],
  ["20 42 46 6", "とれんか"],
  ["20 46 6 17", "とんかち"],
  ["20 46 6 18", "とんかつ"],
  ["21 2 6 2", "ないかい"],
  ["21 6 38 12", "なかよし"],
  ["21 21 8 11", "ななくさ"],
  ["21 21 22 46", "ななにん"],
  ["21 21 28 12", "ななふし"],
  ["21 25 26 21", "なのはな"],
  ["21 31 26 33", "なまはむ"],
  ["21 32 25 40", "なみのり"],
  ["21 39 9 46", "ならけん"],
  ["21 40 7 46", "なりきん"],
  ["21 46 10 18", "なんこつ"],
  ["21 46 14 2", "なんせい"],
  ["21 46 20 3", "なんとう"],
  ["22 8 31 46", "にくまん"],
  ["22 17 38 3", "にちよう"],
  ["22 30 46 12", "にほんし"],
  ["22 44 20 40", "にわとり"],
  ["22 46 12 46", "にんしん"],
  ["22 46 22 8", "にんにく"],
  ["24 8 16 2", "ねくたい"],
  ["24 46 31 18", "ねんまつ"],
  ["25 3 32 15", "のうみそ"],
  ["25 3 34 46", "のうめん"],
  ["25 32 6 2", "のみかい"],
  ["25 39 24 10", "のらねこ"],
  ["25 40 6 4", "のりかえ"],
  ["25 40 35 17", "のりもち"],
  ["25 40 35 25", "のりもの"],
  ["26 2 4 21", "はいえな"],
  ["26 2 7 46", "はいきん"],
  ["26 2 12 46", "はいしん"],
  ["26 2 16 18", "はいたつ"],
  ["26 2 26 2", "はいはい"],
  ["26 2 28 46", "はいふん"],
  ["26 2 37 3", "はいゆう"],
  ["26 6 2 12", "はかいし"],
  ["26 8 11 2", "はくさい"],
  ["26 8 14 2", "はくせい"],
  ["26 12 5 7", "はしおき"],
  ["26 17 3 4", "はちうえ"],
  ["26 17 22 46", "はちにん"],
  ["26 17 25 13", "はちのす"],
  ["26 17 31 7", "はちまき"],
  ["26 17 32 18", "はちみつ"],
  ["26 18 24 18", "はつねつ"],
  ["26 18 37 34", "はつゆめ"],
  ["26 21 8 15", "はなくそ"],
  ["26 21 33 10", "はなむこ"],
  ["26 21 38 34", "はなよめ"],
  ["26 24 18 7", "はねつき"],
  ["26 26 5 36", "ははおや"],
  ["26 26 25 27", "ははのひ"],
  ["26 31 21 13", "はまなす"],
  ["26 36 5 7", "はやおき"],
  ["26 39 31 7", "はらまき"],
  ["26 40 14 46", "はりせん"],
  ["26 40 18 9", "はりつけ"],
  ["26 40 36 31", "はりやま"],
  ["26 41 11 34", "はるさめ"],
  ["26 41 31 7", "はるまき"],
  ["26 46 6 17", "はんかち"],
  ["26 46 14 46", "はんせん"],
  ["26 46 20 3", "はんとう"],
  ["27 7 22 8", "ひきにく"],
  ["27 10 3 7", "ひこうき"],
  ["27 31 44 40", "ひまわり"],
  ["27 34 11 31", "ひめさま"],
  ["27 36 1 14", "ひやあせ"],
  ["27 43 12 31", "ひろしま"],
  ["28 3 14 46", "ふうせん"],
  ["28 3 20 3", "ふうとう"],
  ["28 6 27 42", "ふかひれ"],
  ["28 8 5 6", "ふくおか"],
  ["28 8 12 31", "ふくしま"],
  ["28 8 18 3", "ふくつう"],
  ["28 8 32 32", "ふくみみ"],
  ["28 8 43 3", "ふくろう"],
  ["28 20 31 7", "ふとまき"],
  ["28 20 35 35", "ふともも"],
  ["28 21 25 40", "ふなのり"],
  ["28 32 7 40", "ふみきり"],
  ["28 39 13 10", "ふらすこ"],
  ["28 39 46 13", "ふらんす"],
  ["28 40 6 9", "ふりかけ"],
  ["28 43 12 7", "ふろしき"],
  ["28 46 12 46", "ふんしん"],
  ["28 46 13 2", "ふんすい"],
  ["28 46 33 7", "ふんむき"],
  ["29 2 7 46", "へいきん"],
  ["29 2 16 2", "へいたい"],
  ["30 2 8 12", "ほいくし"],
  ["30 3 13 2", "ほうすい"],
  ["30 3 14 7", "ほうせき"],
  ["30 3 16 2", "ほうたい"],
  ["30 3 19 2", "ほうてい"],
  ["30 8 14 2", "ほくせい"],
  ["30 8 20 3", "ほくとう"],
  ["30 8 40 8", "ほくりく"],
  ["30 12 2 35", "ほしいも"],
  ["30 17 7 13", "ほちきす"],
  ["30 44 2 20", "ほわいと"],
  ["31 2 16 9", "まいたけ"],
  ["31 2 21 13", "まいなす"],
  ["31 2 31 2", "まいまい"],
  ["31 4 1 12", "まえあし"],
  ["31 6 43 22", "まかろに"],
  ["31 6 43 46", "まかろん"],
  ["31 7 35 25", "まきもの"],
  ["31 13 6 39", "ますから"],
  ["31 18 16 9", "まつたけ"],
  ["31 18 36 31", "まつやま"],
  ["31 21 2 16", "まないた"],
  ["31 24 7 46", "まねきん"],
  ["31 34 31 7", "まめまき"],
  ["31 34 35 17", "まめもち"],
  ["31 39 6 13", "まらかす"],
  ["31 39 15 46", "まらそん"],
  ["31 41 21 13", "まるなす"],
  ["31 41 35 17", "まるもち"],
  ["31 46 11 18", "まんさつ"],
  ["31 46 35 13", "まんもす"],
  ["32 4 9 46", "みえけん"],
  ["32 9 24 10", "みけねこ"],
  ["32 11 2 41", "みさいる"],
  ["32 13 10 46", "みすこん"],
  ["32 15 6 18", "みそかつ"],
  ["32 15 12 41", "みそしる"],
  ["32 25 33 12", "みのむし"],
  ["32 26 41 13", "みはるす"],
  ["32 32 1 6", "みみあか"],
  ["32 32 6 7", "みみかき"],
  ["32 32 14 46", "みみせん"],
  ["32 32 21 40", "みみなり"],
  ["33 4 16 2", "むえたい"],
  ["33 12 20 40", "むしとり"],
  ["33 12 38 9", "むしよけ"],
  ["33 39 11 7", "むらさき"],
  ["34 7 12 10", "めきしこ"],
  ["34 46 6 2", "めんかい"],
  ["34 46 14 18", "めんせつ"],
  ["35 8 14 2", "もくせい"],
  ["35 8 16 46", "もくたん"],
  ["35 8 38 3", "もくよう"],
  ["35 17 18 7", "もちつき"],
  ["35 25 30 12", "ものほし"],
  ["35 27 6 46", "もひかん"],
  ["35 32 25 7", "もみのき"],
  ["35 35 2 43", "ももいろ"],
  ["35 40 5 6", "もりおか"],
  ["35 46 12 46", "もんしん"],
  ["36 7 2 35", "やきいも"],
  ["36 7 20 40", "やきとり"],
  ["36 7 22 8", "やきにく"],
  ["36 8 13 3", "やくすう"],
  ["36 12 25 7", "やしのき"],
  ["36 12 25 32", "やしのみ"],
  ["36 18 26 12", "やつはし"],
  ["36 24 3 39", "やねうら"],
  ["36 31 2 35", "やまいも"],
  ["36 31 21 12", "やまなし"],
  ["36 31 24 10", "やまねこ"],
  ["36 31 25 27", "やまのひ"],
  ["36 40 2 6", "やりいか"],
  ["37 3 15 3", "ゆうそう"],
  ["37 3 36 9", "ゆうやけ"],
  ["37 3 42 2", "ゆうれい"],
  ["37 7 6 7", "ゆきかき"],
  ["37 7 36 31", "ゆきやま"],
  ["38 3 6 2", "ようかい"],
  ["38 3 6 46", "ようかん"],
  ["38 3 14 2", "ようせい"],
  ["38 3 14 18", "ようせつ"],
  ["38 3 18 3", "ようつう"],
  ["38 3 21 12", "ようなし"],
  ["38 8 12 18", "よくしつ"],
  ["38 10 26 31", "よこはま"],
  ["38 28 6 12", "よふかし"],
  ["39 2 5 46", "らいおん"],
  ["39 8 14 7", "らくせき"],
  ["39 8 39 2", "らくらい"],
  ["39 8 43 13", "らくろす"],
  ["39 46 5 3", "らんおう"],
  ["39 46 16 46", "らんたん"],
  ["40 35 10 46", "りもこん"],
  ["42 2 20 3", "れいとう"],
  ["42 2 34 46", "れいめん"],
  ["42 46 10 46", "れんこん"],
  ["43 3 15 8", "ろうそく"],
  ["43 8 22 46", "ろくにん"],
  ["43 10 35 10", "ろこもこ"],
  ["44 6 36 31", "わかやま"],
  ["44 16 1 34", "わたあめ"],
  ["44 40 10 32", "わりこみ"],
  ["44 46 16 46", "わんたん"],
];
