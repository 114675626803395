export const replaceSign = (line) => {
    return line
        .replaceAll("×", "*")
        .replaceAll("＊", "*")
        .replaceAll("÷", "/")
        .replaceAll("／", "/")
        .replaceAll("＋", "+")
        .replaceAll("－", "-")
        .replaceAll("＝", "=")
        .replaceAll("＜", "<")
        .replaceAll("＞", ">")
        .replaceAll("（", "(")
        .replaceAll("）", ")")
        .replaceAll("０", "0")
        .replaceAll("１", "1")
        .replaceAll("２", "2")
        .replaceAll("３", "3")
        .replaceAll("４", "4")
        .replaceAll("５", "5")
        .replaceAll("６", "6")
        .replaceAll("７", "7")
        .replaceAll("８", "8")
        .replaceAll("９", "9")
        .replaceAll("π", "pi")
        .replaceAll("√", "root")
        .replaceAll("Σ", "Sigma")
        .replaceAll("％", "Mod")
        .replaceAll("%", "Mod");
};
