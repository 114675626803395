"use client";
import { Auth } from "firebase/auth";
import { Firestore } from "firebase/firestore";
import { Functions } from "firebase/functions";
import { FirebaseStorage } from "firebase/storage";
import { createContext, Dispatch, SetStateAction } from "react";

export type EniverseFirebaseConfig = {
  cloudFirestore: Firestore | null;
  cloudFunctions: Functions | null;
  firebaseAuth: Auth | null;
  cloudStorage: FirebaseStorage | null;
};

export const defaultFirebaseConfig: EniverseFirebaseConfig = {
  cloudFirestore: null,
  cloudFunctions: null,
  firebaseAuth: null,
  cloudStorage: null,
};

export const FirebaseContext = createContext<
  [EniverseFirebaseConfig, Dispatch<SetStateAction<EniverseFirebaseConfig>>]
>([
  defaultFirebaseConfig,
  () => {
    return defaultFirebaseConfig;
  },
]);
