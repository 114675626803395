import {
  DictionaryKey,
  dictionaries,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { FC, useContext } from "react";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type DictionarySelectorProps = {
  labelName: string;
  dictionary: string[] | string;
  setDictionary: (value: string[]) => void;
  isDemo: boolean;
  onBlur?: () => void;
};

export const DictionarySelector: FC<DictionarySelectorProps> = ({
  labelName,
  dictionary,
  setDictionary,
  isDemo,
  onBlur,
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const { multiSelect } = ctx;

  return (
    <FormControl variant="outlined">
      <InputLabel id="select-dictionary-label">{labelName}</InputLabel>
      <Select
        labelId="select-dictionary-label"
        multiple={multiSelect}
        value={
          multiSelect
            ? typeof dictionary === "string"
              ? dictionary.split(",")
              : dictionary ?? []
            : dictionary?.[0] ?? []
        }
        onChange={(e) =>
          multiSelect
            ? setDictionary(
                typeof e.target.value === "string"
                  ? e.target.value.split(",")
                  : e.target.value ?? []
              )
            : setDictionary([e.target.value as string])
        }
        onBlur={onBlur}
        sx={{
          ml: 1,
          width: "200px",
        }}
        disabled={isDemo}
        error={(typeof dictionary === "string"
          ? dictionary.split(",")
          : dictionary ?? []
        ).some(
          (dictionary) =>
            !ctx.enabledDictionary.includes(dictionary as DictionaryKey) &&
            !ctx.enabledUserDictionary.find(
              (e) => "user:" + e.key === dictionary
            )
        )}
        id="multiple-chip"
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {(typeof selected === "string"
              ? selected.split(",")
              : selected
            ).map((value) =>
              value.startsWith("user:") ? (
                <Chip
                  key={value}
                  label={
                    ctx.enabledUserDictionary.find(
                      (e) => "user:" + e.key === value
                    )?.name || "読み込み中"
                  }
                />
              ) : (
                <Chip
                  key={value}
                  label={dictionaries?.[value as DictionaryKey]?.name ?? ""}
                />
              )
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {ctx.enabledDictionary
          .sort(
            (a, b) =>
              (dictionaries?.[a as DictionaryKey]?.index ?? 0) -
              (dictionaries?.[b as DictionaryKey]?.index ?? 0)
          )
          .map((key) => (
            <MenuItem value={key} key={key}>
              {dictionaries?.[key as DictionaryKey]?.name ?? ""}
            </MenuItem>
          ))}
        {ctx.enabledUserDictionary.map((e) => (
          <MenuItem value={"user:" + e.key} key={"user:" + e.key}>
            {e.name}
          </MenuItem>
        ))}
        {ctx.enabledDictionary.length === 0 &&
          ctx.enabledUserDictionary.length === 0 && (
            <MenuItem value="" key="">
              辞書を有効化してください
            </MenuItem>
          )}
      </Select>
    </FormControl>
  );
};
