export const loadSpreadsheet = async (inputUrl: string, sheetName?: string) => {
  // sheet v4
  const apiKey = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY; // APIキー
  // check is url
  try {
    const url = new URL(inputUrl);
    const id = url.pathname.split("/")[3];
    const sheet = url.pathname.split("/")[5];
    const range = url.searchParams.get("range");
    const key = url.searchParams.get("key");
    const gid = url.searchParams.get("gid") ?? url.hash.split("=")[1];

    if (!id) {
      return null;
    }
    if (!sheetName) {
      const baseUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}?key=${apiKey}`;
      const response = await fetch(baseUrl);
      if (!response?.ok) {
        return null;
      }
      const json = await response.json();
      if (!json.sheets) {
        return null;
      }
      console.log("load!");

      // gid(SheetId) to sheetTitle
      sheetName = json.sheets.find(
        (e: any) => e.properties.sheetId === Number(gid)
      ).properties.title;
    }
    const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${sheetName}?key=${apiKey}`;
    const sheetResponse = await fetch(sheetUrl);
    const sheetJson = await sheetResponse.json();
    const sheetValuesCsv = sheetJson.values
      .map((e: string[]) => e.join(","))
      .join("\n");

    return {
      id: id,
      url: url.href,
      sheetTitle: sheetName,
      value: sheetValuesCsv,
      json: JSON.stringify(sheetJson),
    };
  } catch (e) {
    return null;
  }
};
