export const splitIgnoringBraces = (str: string): string[] => {
  // まず{}で囲まれた部分を識別するためのフラグをfalseに設定します。
  let inBraces = false;

  // 分割ポイントを記録するための配列です。
  const points: number[] = [-1]; // 文字列の開始前を初期値として含めます。

  // 文字列を一文字ずつ検討していきます。
  for (let i = 0; i < str.length; i++) {
    switch (str[i]) {
      case "{":
        inBraces = true;
        break;
      case "}":
        inBraces = false;
        break;
      case ",":
        // もし{}の内側でなければ、その位置は分割ポイントです。
        if (!inBraces) points.push(i);
        break;
    }
  }

  // 文字列の終わりを分割ポイントに加えます。
  points.push(str.length);

  // 分割ポイントを基にして部分文字列を作成し、配列に格納します。
  const result: string[] = [];
  for (let i = 0; i < points.length - 1; i++) {
    // 分割ポイントの直後から次の分割ポイントまでの文字列を取得します。
    const part = str.substring(points[i] + 1, points[i + 1]);
    result.push(part);
  }

  return result;
};
