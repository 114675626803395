import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { ImageComponents } from "../../pdf/image/ImageComponents";
import { StackData, StackImageUrlData } from "../stackData";

export const Image: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "imageurl"}>
      <ImageComponents {...(contents as StackImageUrlData)} />
    </If>
  );
};
