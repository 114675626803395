import { EstimationInput } from "@langue-de-chat-llc/enigmastudio-algorithm";
import { Box, TextField, Checkbox } from "@mui/material";
import { FC } from "react";

export type QueryEstimationProps = {
  isEstimation: boolean;
  isDemo: boolean;
  estimation: EstimationInput;
  setEstimation: (estimation: EstimationInput) => void;
};

export const QueryEstimation: FC<QueryEstimationProps> = (props) => {
  const { isEstimation, isDemo, estimation, setEstimation } = props;
  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <TextField
        label="初期評価値"
        value={estimation.initialValue.toString()}
        inputProps={{
          inputMode: "decimal",
          type: "number",
          step: "1",
        }}
        onChange={(e) => {
          setEstimation({
            ...estimation,
            initialValue: parseFloat(e.target.value),
          });
        }}
        size="small"
        sx={{
          ml: 1,
          mt: 1,
          mb: 0.5,
          pt: 1,
          pb: 0,
          fontSize: "0.8rem",
        }}
        disabled={!isEstimation || isDemo}
      />
      <Box
        sx={{
          pt: 1,
          px: 1,
          display: "inline-block",
        }}
      >
        <TextField
          label="評価フィルタしきい値"
          value={
            estimation.filterEnabled ? estimation.filterValue.toString() : ""
          }
          inputProps={{
            inputMode: "decimal",
            type: "number",
            step: "0.1",
          }}
          onChange={(e) => {
            setEstimation({
              ...estimation,
              filterValue: parseFloat(e.target.value),
            });
          }}
          size="small"
          sx={{
            ml: 1,
            mt: 1,
            my: 0,
            mb: 0.5,
            py: 0,
            fontSize: "0.8rem",
          }}
          disabled={!isEstimation || isDemo || !estimation.filterEnabled}
        />
        <Checkbox
          checked={estimation.filterEnabled}
          onChange={(e) => {
            setEstimation({
              ...estimation,
              filterEnabled: e.target.checked,
            });
          }}
          disabled={!isEstimation || isDemo}
        />
      </Box>
    </Box>
  );
};
