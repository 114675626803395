import { Box } from "@mui/material";
import { getBlob, StorageReference } from "firebase/storage";
import { FC, useCallback, useContext, useEffect } from "react";
import { ConvertTimestampRecursively } from "../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { useWorker } from "../../../../hooks/useWorker";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { CloudItemLoad } from "./CloudItemLoad";
import { CloudDoc, DocFile, Metadata } from "./cloudType";
import { useCloud } from "./useCloud";

type Props = {
  userId?: string;
  selectedDictionaries: string[];
  setSelectedDictionaries: (dictionaries: string[]) => void;
  tabValue: string;
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
  fileListLoading: boolean;
  storageTable: Record<string, StorageReference>;
};

export const CloudItemLoadWrapper: FC<Props> = (props) => {
  const {
    userId: _userId,
    selectedDictionaries,
    fileListData,
    setFileListData,
    fileListLoading,
  } = props;
  const { loadText } = useWorker();
  const { enabledUserDictionary, setEnabledUserDictionary } =
    useContext(EnigmaStudioContext);

  const userId = _userId ?? "guest";
  const { storageTable } = useCloud({
    userId,
    fileListData,
  });

  const onLoadText = useCallback(
    async (doc: DocFile & Metadata, textRef: StorageReference) => {
      if (!textRef) return;
      if (!doc) return;
      if (doc.type !== "text") return;
      const key = textRef.name;
      const dictKey = "user:" + key;
      const blob = await getBlob(textRef);
      const text = await blob.text();
      loadText(dictKey, text);
      const name = doc.name;
      setEnabledUserDictionary([
        ...enabledUserDictionary,
        {
          key,
          name,
          doc,
        },
      ]);
    },
    [enabledUserDictionary, loadText, setEnabledUserDictionary]
  );

  const onLoadSelect = useCallback(
    (doc: DocFile & Metadata, textRef: StorageReference) => {
      const key = textRef.name;
      onLoadText(doc, storageTable[key]);
    },
    [onLoadText, storageTable]
  );

  useEffect(() => {
    if (!storageTable) return;
    if (!selectedDictionaries) return;
    if (fileListData?.files === undefined) return;
    selectedDictionaries.forEach((key) => {
      if (key.startsWith("user:")) {
        const fileName = key.replace("user:", "");
        if (!enabledUserDictionary.find((d) => d.key === fileName)) {
          const item = storageTable[fileName];
          if (item) {
            onLoadText(
              fileListData?.files[fileName] as DocFile & Metadata,
              item
            );
          }
        }
      }
    });
  }, [
    selectedDictionaries,
    storageTable,
    onLoadText,
    fileListData?.files,
    enabledUserDictionary,
  ]);

  if (fileListLoading) {
    return <div>loading</div>;
  }

  return (
    <Box
      sx={{
        mt: 1,
      }}
    >
      <CloudItemLoad
        fileListData={fileListData}
        setFileListData={setFileListData}
        storageTable={storageTable}
        onLoadSelect={onLoadSelect}
      />
    </Box>
  );
};
