import {
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStatePersist } from "use-state-persist";
import { googleSheetApiKey } from "../../../../../../api/env/api";
import { ConvertProps } from "./ConvertTemplate";

export const SpleadSheetLoader: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  const [spreadsheetInput, setSpreadsheetInput] = useState<string>("");
  const [spreadsheetInputHistory, setSpreadsheetInputHistory] = useStatePersist<
    {
      url: string;
      title: string;
      sheetTitle: string;
    }[]
  >("word-list-spreadsheet-input-history", []);

  const loadSpreadsheet = useCallback(
    async (inputUrl: string) => {
      // sheet v4
      const apiKey =
        process.env.REACT_APP_GOOGLE_SHEETS_API_KEY ?? googleSheetApiKey; // APIキー
      // check is url
      try {
        const url = new URL(inputUrl);
        const id = url.pathname.split("/")[3];
        const sheet = url.pathname.split("/")[5];
        const range = url.searchParams.get("range");
        const key = url.searchParams.get("key");
        const gid = url.searchParams.get("gid") ?? url.hash.split("=")[1];

        console.log(id, sheet, range, key, gid);
        if (!id) {
          return;
        }
        const baseUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}?key=${apiKey}`;
        const response = await fetch(baseUrl);
        if (!response?.ok) {
          return;
        }
        const json = await response.json();
        if (!json.sheets) {
          return;
        }
        console.log("load!");

        // gid(SheetId) to sheetTitle
        const sheetName = json.sheets.find(
          (e: any) => e.properties.sheetId === Number(gid)
        ).properties.title;
        const title = json.properties.title;
        const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${sheetName}?key=${apiKey}`;
        const sheetResponse = await fetch(sheetUrl);
        const sheetJson = await sheetResponse.json();
        const sheetValuesCsv = sheetJson.values
          .map((e: string[]) => e.join(","))
          .join("\n");
        setConverted(sheetValuesCsv);
        setSpreadsheetInput("");
        setSpreadsheetInputHistory((prev) => {
          const index = prev.findIndex((e) => e.url === url.href);
          if (index !== -1) {
            prev.splice(index, 1);
          }
          prev.unshift({
            url: url.href,
            title: title,
            sheetTitle: sheetName,
          });
          return prev;
        });
      } catch (e) {
        return;
      }
    },
    [setConverted, setSpreadsheetInputHistory]
  );

  return (
    <>
      <Grid item sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="スプレッドシートURL"
          value={spreadsheetInput}
          onChange={(e) => setSpreadsheetInput(e.target.value)}
          sx={{ width: 400, mt: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            loadSpreadsheet(spreadsheetInput);
          }}
        >
          読み込み
        </Button>
      </Grid>

      <List>
        {spreadsheetInputHistory.map((e, i) => (
          <>
            <ListItem
              key={i}
              onClick={() => {
                loadSpreadsheet(e.url);
              }}
              sx={{
                width: 400,
              }}
              dense
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setSpreadsheetInputHistory((prev) => {
                      return prev.filter((_, j) => i !== j);
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemButton>
                <ListItemText primary={e.title + "/" + e.sheetTitle} />
              </ListItemButton>
            </ListItem>
            <Divider
              sx={{
                width: 400,
              }}
            />
          </>
        ))}
      </List>
    </>
  );
};
