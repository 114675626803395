import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { useWorker } from "../../../../hooks/useWorker";
import { RegexDialog } from "../../commonStudio/RegexDialog";
import { ResultList } from "../../common/Result";
import { Title } from "../../common/Title";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { DictionaryKey, dictionaries } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const NumToWord: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  ctx.setEnableLoad(true);
  const [value, setValue] = useState(0);
  const [dictionary, setDictionary] = useState("");
  const [result, setResult] = useState<string[]>([]);
  const [searching, setSearching] = useState(false);
  const worker = useWorker();
  const [pattern, setPattern] =
    useState<string>(`＃あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん
＃＃＃＃＃＃がぎぐげござじずぜぞだぢづでど＃＃＃＃＃ばびぶべぼ
＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃ぱぴぷぺぽ
＃ぁぃぅぇぉ＃＃＃＃＃＃＃＃＃＃＃＃っ＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃ゃゅょ
＃アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン
＃＃＃＃＃＃ガギグゲゴザジズゼゾダヂヅデド＃＃＃＃＃バビブベボ
＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃パピプペポ
＃ァィゥェォヵ＃＃ヶ＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃ャュョ
＃ABCDEFGHIJKLMNOPQRSTUVWXYZ
＃abcdefghijklmnopqrstuvwxyz
0123456789
`);

  console.log(ctx.enabledDictionary);
  const onSearch = useCallback(() => {
    setSearching(true);
    worker.numToWordSearch(
      value,
      pattern,
      dictionary as DictionaryKey,
      (result) => {
        if (result) {
          setResult(result);
          setSearching(false);
        }
      }
    );
  }, [value, pattern, dictionary, worker, ctx.enabledDictionary]);

  const handleChangeDictionary = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value);
      setDictionary((event.target as HTMLInputElement).value);
    },
    []
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>数字to単語検索</Title>
      <Box sx={{ width: "100vw" }}>
        <TextField
          label="Number"
          type="number"
          variant="outlined"
          sx={{ width: "10em" }}
          value={value}
          onChange={(event) => {
            setValue(parseInt(event.target.value));
          }}
        />
        {false && <RegexDialog />}
      </Box>
      <Box>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel id="demo-row-radio-buttons-group-label">辞書</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={dictionary}
            onChange={handleChangeDictionary}
          >
            {ctx.enabledDictionary
              .sort((a, b) => dictionaries[a].index - dictionaries[b].index)
              .map((key) => (
                <FormControlLabel
                  value={key}
                  control={<Radio />}
                  label={dictionaries[key as DictionaryKey].name}
                  key={key}
                />
              ))}
          </RadioGroup>
        </FormControl>

        <TextField
          label="変換テーブル"
          variant="outlined"
          sx={{ width: "80vw", my: 2 }}
          multiline
          value={pattern}
          onChange={(event) => {
            setPattern(event.target.value);
          }}
        />
      </Box>
      <Box>
        <Button variant="contained" onClick={onSearch} disabled={!ctx.loaded}>
          検索
        </Button>
      </Box>
      <ResultList items={result} loading={searching} />
    </Container>
  );
};
