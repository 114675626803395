import {
  Box,
  Container
} from "@mui/material";
import {
  FC
} from "react";
import { PlainText } from "../../common/PlainText";
import { Title } from "../../common/Title";

export const Counter: FC = () => {


  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>文字種カウンタ</Title>
      <PlainText>2つのテキストの文字種の差分を出力します</PlainText>

      <Box>

      </Box>
    </Container>
  );
};
