import { smallWordList } from "../../data/words";
import { answerJa, answerEn } from "./answer";
import { city } from "./city";
import { constellation } from "./constellation";
import { pokemon1 } from "./pokemon1";
import { pokemon2 } from "./pokemon2";
import { pokemon3 } from "./pokemon3";
import { pokemon4 } from "./pokemon4";
import { pokemon5 } from "./pokemon5";
import { pokemon6 } from "./pokemon6";
import { pokemon7 } from "./pokemon7";
import { pokemon8 } from "./pokemon8";
import { pokemon9 } from "./pokemon9";
import { pokemongo } from "./pokemongo";
import { pokemonlegends } from "./pokemonlegends";
import { town } from "./town";
import { village } from "./village";
import { wordList } from "./wordList";

const playingCardSymbols = "♡♢♤♧";
const diceSymbols = "⚀⚁⚂⚃⚄⚅";
const chessSymbols = "♔♕♖♗♘♙♚♛♜♝♞♟";

export const categorizedWordListFree: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  言語: {
    あいう: smallWordList.あいう,
    濁音半濁音: smallWordList.濁音半濁音,
    五十音各段ひらがな: smallWordList.五十音各段ひらがな,
    五十音濁音半濁各段ひらがな: smallWordList.五十音濁音半濁各段ひらがな,
    五十音表ひらがな: smallWordList.五十音表ひらがな,
    五十音表濁半: smallWordList.五十音表濁半,
    いろは: smallWordList.いろは,
    ABC: smallWordList.ABC,
    えーびーしー: smallWordList.えーびーしー,
  },
  暦: {
    干支: smallWordList.干支,
    えと: smallWordList.えと,
    春夏秋冬_訓: smallWordList.春夏秋冬_訓,
    春夏秋冬_音: smallWordList.春夏秋冬_音,
    春夏秋冬_英: smallWordList.春夏秋冬_英,
    四季_英語_よみ: smallWordList.四季_英語_よみ,
    曜日: `にち
げつ
か
すい
もく
きん
ど`,
    曜日よみ替え: `ひ
つき
ひ
みず
き
かね
つち`,
    曜日英語: `sunday
monday
tuesday
wednesday
thursday
friday
saturday`,
    曜日英語よみ: `さんでー
まんでー
ちゅーずでー
うぇんずでー
さーずでー
ふらいでー
さたでー`,
    日付: `ついたち
ふつか
みっか
よっか
いつか
むいか
なのか
ようか
ここのか
とおか
はつか`,
    月の異名: `むつき
きさらぎ
やよい
うづき
さつき
みなづき
ふみづき
はづき
ながつき
かんなづき
しもつき
しわす`,
    month: `january
february
march
april
may
june
july
august
september
october
november
december`,
    元号: `めいじ
たいしょう
しょうわ
へいせい
れいわ`,
  },
  方向方角: {
    前後左右: `ぜん
ご
さ
ゆう`,
    上下左右: `じょう
げ
さ
ゆう`,
    東西南北: `とう
ざい
なん
ぼく`,
    方角ひらがな: `きた
ひがし
にし
みなみ`,
    方角英語: `north
east
west
south`,
    方角英語よみ: `のーす
いーすと
うえすと
さうす`,
  },
  数: {
    一から十ひらがな: smallWordList.一から十ひらがな,
    数字ひらがな: smallWordList.数字ひらがな,
    OneToTen: smallWordList.OneToTen,
    ワントゥテン: smallWordList.ワントゥテン,
    九九単語: `にし
にく
さんご
しし
しご
ごご
ごく
ろっく
はっぱ
くに
くし`,
    四則演算: `たす
ひく
かける
わる
わ
さ
せき
しょう
か
げん
じょう
じょ`,
  },
  自然科学: {
    十二星座ひらがな: smallWordList.十二星座ひらがな,
    十二星座en: smallWordList.十二星座en,
    八十八星座ひらがな: smallWordList.八十八星座ひらがな,
    "88星座英語": constellation,
    惑星: `すいせい
きんせい
ちきゅう
かせい
もくせい
どせい
てんのうせい
かいおうせい`,
    planet: `mercury
venus
earth
mars
jupiter
saturn
uranus
neptune`,
    指: `おやゆび
ひとさしゆび
なかゆび
くすりゆび
こゆび`,
    元素記号: `H
He
Li
Be
B
C
N
O
F
Ne
Na
Mg
Al
Si
P
S
Cl
Ar
K
Ca
Sc
Ti
V
Cr
Mn
Fe
Co
Ni
Cu
Zn
Ga
Ge
As
Se
Br
Kr
Rb
Sr
Y
Zr
Nb
Mo
Tc
Ru
Rh
Pd
Ag
Cd
In
Sn
Sb
Te
I
Xe
Cs
Ba
La
Ce
Pr
Nd
Pm
Sm
Eu
Gd
Tb
Dy
Ho
Er
Tm
Yb
Lu
Hf
Ta
W
Re
Os
Ir
Pt
Au
Hg
Tl
Pb
Bi
Po
At
Rn
Fr
Ra
Ac
Th
Pa
U
Np
Pu
Am
Cm
Bk
Cf
Es
Fm
Md
No
Lr
Rf
Db
Sg
Bh
Hs
Mt
Ds
Rg
Cn
Nh
Fl
Mc
Lv
Ts
Og`,
    元素: `すいそ
へりうむ
りちうむ
べりりうむ
ほうそ
たんそ
ちっそ
さんそ
ふっそ
ねおん
なとりうむ
まぐねしうむ
あるみにうむ
けいそ
りん
いおう
えんそ
あるごん
かりうむ
かるしうむ
すかんじうむ
ちたん
ばなじうむ
くろむ
まんがん
てつ
こばると
にっける
どう
あえん
がりうむ
げるまにうむ
ひそ
せれん
しゅうそ
くりぷとん
るびじうむ
すとろんちうむ
いっとりうむ
じるこにうむ
におぶ
もりぶでん
てくねちうむ
るてにうむ
ろじうむ
ぱらじうむ
ぎん
かどみうむ
いんじうむ
すず
あんちもん
てるる
ようそ
きせのん
せしうむ
ばりうむ
らんたん
せりうむ
ぷらせおじむ
ねおじむ
ぷろめちうむ
さまりうむ
ゆうろぴうむ
がどりにうむ
てるびうむ
じすぷろしうむ
ほるみうむ
えるびうむ
つりうむ
いってるびうむ
るてちうむ
はふにうむ
たんたる
たんぐすてん
れにうむ
おすみうむ
いりじうむ
はっきん
きん
すいぎん
たりうむ
なまり
びすます
ぽろにうむ
あすたちん
らどん
ふらんしうむ
らじうむ
あくちにうむ
とりうむ
ぷろとあくちにうむ
うらん
ねぷつにうむ
ぷるとにうむ
あめりしうむ
きゅりうむ
ばーくりうむ
かりほるにうむ
あいんすたいにうむ
ふぇるみうむ
めんでれびうむ
のーべりうむ
ろーれんしうむ
らざほーじうむ
どぶにうむ
しーぼーぎうむ
ぼーりうむ
はっしうむ
まいとねりうむ
だーむすたちうむ
れんとげにうむ
こぺるにしうむ
にほにうむ
ふれろびうむ
もすこびうむ
りばもりうむ
てねしん
おがねそん`,
    元素en: `hydrogen
helium
lithium
beryllium
boron
carbon
nitrogen
oxygen
fluorine
neon
sodium
magnesium
aluminium
silicon
phosphorus
sulfur
chlorine
argon
potassium
calcium
scandium
titanium
vanadium
chromium
manganese
iron
cobalt
nickel
copper
zinc
gallium
germanium
arsenic
selenium
bromine
krypton
rubidium
strontium
yttrium
zirconium
niobium
molybdenum
technetium
ruthenium
rhodium
palladium
silver
cadmium
indium
tin
antimony
tellurium
iodine
xenon
caesium
barium
lanthanum
cerium
praseodymium
neodymium
promethium
samarium
europium
gadolinium
terbium
dysprosium
holmium
erbium
thulium
ytterbium
lutetium
hafnium
tantalum
tungsten
rhenium
osmium
iridium
platinum
gold
mercury
thallium
lead
bismuth
polonium
astatine
radon
francium
radium
actinium
thorium
protactinium
uranium
neptunium
plutonium
americium
curium
berkelium
californium
einsteinium
fermium
mendelevium
nobelium
lawrencium
rutherfordium
dubnium
seaborgium
bohrium
hassium
meitnerium
darmstadtium
roentgenium
copernicium
nihonium
flerovium
moscovium
livermorium
tennessine
oganesson`,
  },

  地理: {
    都道府県ひらがな: smallWordList.都道府県ひらがな,
    県庁所在地ひらがな: smallWordList.県庁所在地ひらがな,
    日本の市: city,
    日本の町: town,
    日本の村: village,
    山手線の駅名: `おおさき
しながわ
たかなわげーとうぇい
たまち
はままつちょう
しんばし
ゆうらくちょう
とうきょう
かんだ
あきはばら
おかちまち
うえの
うぐいすだに
にっぽり
にしにっぽり
たばた
こまごめ
すがも
おおつか
いけぶくろ
めじろ
たかだのばば
しんおおくぼ
しんじゅく
よよぎ
はらじゅく
しぶや
えびす
めぐろ
ごたんだ`,
  },
  色: {
    虹: `あか
だいだい
き
みどり
あお
あい
むらさき`,
    rainbow: `red
orange
yellow
green
blue
indigo
violet/purple`,
    rainbowよみ: `れっど
おれんじ
いえろー
ぐりーん
ぶるー
いんでぃご
ばいおれっと`,
  },
  音楽: {
    ドレミ: `ど
れ
み
ふぁ
そ
ら
し`,
    ドレミの歌: `どーなつ
れもん
みんな
ふぁいと
あおいそら
らっぱ
しあわせ`,
  },
  生活: {
    小中大: `しょう
ちゅう
だい`,
    小中高大: `しょう
ちゅう
こう
だい`,
    五教科: `こくご
さんすう
りか
しゃかい
えいご`,
    松竹梅: `しょう
ちく
ばい`,
    表彰台: `きん
ぎん
どう
GOLD
SILVER
BRONZE
ゴールド
シルバー
ブロンズ
ファースト
セカンド
サード
Au
Ag
Cu`,
    春の七草: `せり
なずな
ごぎょう
はこべら
ほとけのざ
すずな
すずしろ`,
    秋の七草: `はぎ
おばな
くず
なでしこ
おみなえし
ふじばかま
ききょう`,
    たべっ子どうぶつ: `こうもり
くま
らくだ
ねこ
めうし
しか
いぬ
あひる
わし
ぞう
きつね
おっとせい
やぎ
たか
めんどり
かば
みみずく
うま
ひょう
らいおん
おおやまねこ
いんこ
おしどり
さる
おうし
ぱんだ
おうむ
くじゃく
ぺんぎん
ぺりかん
はと
ほっきょくぐま
やまあらし
ねずみ
うさぎ
あらいぐま
さい
おんどり
ひつじ
すずめ
りす
ばく
とら
かめ
いのしし
おおかみ`,
    たべっ子どうぶつ_EN: `bat
bear
camel
cat
cow
deer
dog
duck
eagle
elephant
fox
furseal
goat
hawk
hen
hippo
horn-owl
horse
leopard
lion
lynx
macaw
m, duck
monkey
ox
panda
parrot
peafowl
penguin
pelican
pigeon
polar bear
porcupine
mouse
rabbit
raccoon
rhinoceros
rooster
sheep
sparrow
squirrel
tapir
tiger
tortoise
wildboar
wolf`,
  },
  ゲーム: {
    トランプ: `すぺーど
はーと
だいや
くらぶ
えーす
じゃっく
くいーん
きんぐ
じょーかー`,
    トランプ_英: `spade
heart
diamond
club
ace
jack
queen
king
joker`,
    字牌: `とん
なん
しゃー
ぺー
はく
はつ
ちゅん`,
  },

  キャラクター: {
    ポケモン第1世代: pokemon1,
    ポケモン第2世代: pokemon2,
    ポケモン第3世代: pokemon3,
    ポケモン第4世代: pokemon4,
    ポケモン第5世代: pokemon5,
    ポケモン第6世代: pokemon6,
    ポケモン第7世代: pokemon7,
    Pokémon_GO: pokemongo,
    ポケモン第8世代: pokemon8,
    Pokémon_LEGENDS_アルセウス: pokemonlegends,
    ポケモン第9世代: pokemon9,
  },
  記号: {
    トランプ記号: playingCardSymbols,
    サイコロ記号: diceSymbols,
    チェス記号: chessSymbols,
  },
};

export const categorizedWordListFull: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  ...categorizedWordListFree,
  ことば: {
    除外: `いらん
うす
うせ
かっと
かれ
きえ
けし
しょうきょ
じょきょ
すて
とり
どり
とる
どる
とれ
どれ
ない
なし
のー
ぬき
むし
れす
ろす`,
    答え: answerJa,
    Answer: answerEn,
  },
  一覧: {
    題材リスト: wordList,
  },
};
