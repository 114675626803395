import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
} from "@mui/material";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { StackEditItem } from "./StackEditItem";
import EditSideMenu from "./components/EditSideMenu";
import { Container, Draggable } from "../react-smooth-dnd";

type StackEditListProps = {
  contents: any;
  setContents: React.Dispatch<React.SetStateAction<any>>;
  editorDataTable: any;
  config?: {
    sheetUrl: string;
  };
};

export const StackEditList: FC<StackEditListProps> = ({
  contents,
  setContents,
  editorDataTable,
}) => {
  const [selectedContentsIndex, setSelectedContentsIndex] = useState(-1);
  const [hoveredContentsIndex, setHoveredContentsIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [version, setVersion] = useState(0);

  const onDrop = ({
    removedIndex,
    addedIndex,
  }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    if (removedIndex === null || addedIndex === null) {
      return;
    }
    setContents((prev: any) => {
      const items = prev.list;
      const result = Array.from(items);
      const [removed] = result.splice(removedIndex, 1);
      result.splice(addedIndex, 0, removed);
      return { ...prev, list: result };
    });
    setSelectedContentsIndex(addedIndex);
    setAnchorEl(null);
    setVersion((prev) => prev + 1);
  };

  const onSelect = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, contentIndex: number) => {
      setAnchorEl(
        e.currentTarget.closest(
          ".edit-card"
        ) as React.SetStateAction<HTMLElement | null>
      );
      setSelectedContentsIndex(contentIndex);
    },
    []
  );

  const onClose = () => {
    setAnchorEl(null);
  };

  const addFirstContents = () => {
    setContents((prev: any) => {
      const items = prev.list;
      const result = Array.from(items);
      result.unshift({ type: null });
      return {
        ...prev,
        list: result,
      };
    });
    setAnchorEl(null);
    setVersion((prev) => prev + 1);
  };

  const addContents = useCallback(() => {
    setContents((prev: any) => {
      const insertIndex = selectedContentsIndex + 1;
      const items = prev.list;
      const result = Array.from(items);
      result.splice(insertIndex, 0, { type: null });
      return {
        ...prev,
        list: result,
      };
    });
    setAnchorEl(null);
    setVersion((prev) => prev + 1);
  }, [selectedContentsIndex, setContents]);

  const copyContents = () => {
    const insertIndex = selectedContentsIndex + 1;
    setContents((prev: any) => {
      const items = prev.list;
      const result = Array.from(items);
      result.splice(
        insertIndex,
        0,
        JSON.parse(JSON.stringify(result[selectedContentsIndex]))
      );
      return {
        ...prev,
        list: result,
      };
    });
    setVersion((prev) => prev + 1);
  };

  const deleteContents = useCallback(() => {
    setContents((prev: any) => {
      const items = prev.list;
      const result = Array.from(items);
      result.splice(selectedContentsIndex, 1);
      return {
        ...prev,
        list: result,
      };
    });
    setSelectedContentsIndex(
      selectedContentsIndex + 1 < contents.list.length
        ? selectedContentsIndex
        : selectedContentsIndex - 1
    );
    setAnchorEl(null);
    setVersion((prev) => prev + 1);
  }, [contents.list.length, selectedContentsIndex, setContents]);

  const upContents = useCallback(() => {
    const index = selectedContentsIndex;
    if (index === 0) return;
    setContents((prev: any) => {
      const items = prev.list;
      const result = Array.from(items);
      result.splice(index - 1, 0, result.splice(index, 1)[0]);
      return {
        ...prev,
        list: result,
      };
    });
    setSelectedContentsIndex(index - 1);
    setAnchorEl(null);
    setVersion((prev) => prev + 1);
  }, [selectedContentsIndex, setContents]);

  const downContents = useCallback(() => {
    const index = selectedContentsIndex;
    if (index === contents.list.length - 1) return;
    setContents((prev: any) => {
      const items = prev.list;
      const result = Array.from(items);
      result.splice(index + 1, 0, result.splice(index, 1)[0]);
      return {
        ...prev,
        list: result,
      };
    });
    setAnchorEl(null);
    setVersion((prev) => prev + 1);
  }, [selectedContentsIndex, contents.list.length, setContents]);

  useEffect(() => {
    if (
      anchorEl !==
      (document.querySelectorAll(".edit-card")[
        selectedContentsIndex
      ] as HTMLElement)
    ) {
      if (selectedContentsIndex >= 0) {
        setAnchorEl(
          document.querySelectorAll(".edit-card")[
            selectedContentsIndex
          ] as HTMLElement
        );
      } else {
        setAnchorEl(null);
      }
    }
  }, [anchorEl]);

  const setContentsItem = useCallback(
    (index: number, content: any, version: number) => {
      setContents((prev: any) => {
        const items = prev.list;
        const result = Array.from(items);
        result[index] = content;
        return {
          ...prev,
          list: result,
        };
      });
      setVersion(version);
    },
    []
  );

  return (
    <>
      <EditSideMenu
        anchorEl={anchorEl}
        onClose={onClose}
        items={{
          addContents,
          copyContents,
          deleteContents,
          upContents: selectedContentsIndex > 0 ? upContents : null,
          downContents:
            selectedContentsIndex < (contents?.list?.length ?? 0) - 1
              ? downContents
              : null,
        }}
      />
      <Card
        sx={{
          mx: 0,
          my: 1,
          py: 0,
          width: 160,
          height: 32,
        }}
      >
        <CardContent
          sx={{
            py: 0,
            mx: "auto 0",
            width: 32,
            height: 32,
          }}
        >
          <IconButton aria-label="add" size="small" onClick={addFirstContents}>
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
        </CardContent>
      </Card>
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
        {contents &&
          contents.list &&
          contents.list.map((content: any, index: number) => {
            return (
              <Fragment key={version + "-" + index}>
                <Draggable>
                  <Card
                    sx={{
                      my: 0.5,
                      ...(selectedContentsIndex === index && {
                        borderLeft: "solid 3px",
                        borderLeftColor: "InfoText",
                      }),
                    }}
                    className="edit-card"
                  >
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 0,
                        py: 0,
                        cursor: "move",
                      }}
                      className="drag-handle"
                      onMouseDown={(e) => {
                        onSelect(e, index);
                      }}
                      onMouseEnter={() => {
                        setHoveredContentsIndex(index);
                      }}
                      onMouseLeave={() => {
                        setHoveredContentsIndex(-1);
                      }}
                    >
                      <DragIndicatorIcon
                        sx={{
                          transform: "rotate(90deg)",
                          opacity:
                            selectedContentsIndex === index ||
                            hoveredContentsIndex === index
                              ? 1
                              : 0,
                        }}
                      />
                    </CardActions>

                    <CardActionArea
                      onClick={(e) => {
                        if (selectedContentsIndex === index) return;
                        onSelect(e, index);
                      }}
                      sx={{
                        cursor:
                          hoveredContentsIndex === index
                            ? "pointer"
                            : "default",
                      }}
                      aria-disabled={selectedContentsIndex === index}
                      disableRipple={selectedContentsIndex === index}
                      disableTouchRipple={selectedContentsIndex === index}
                    >
                      <CardContent
                        sx={{
                          pt: 0,
                          pb: 1,
                        }}
                      >
                        <StackEditItem
                          content={content}
                          setContentHeavy={setContentsItem}
                          selected={selectedContentsIndex === index}
                          version={version}
                          index={index}
                          key={"edit-item-" + version + "-" + index}
                          editorDataTable={editorDataTable}
                        />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Draggable>
              </Fragment>
            );
          })}
      </Container>
    </>
  );
};
