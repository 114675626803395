import { convertFlat } from "../commonLoop";
import { createSortedTable } from "../table/dictionaryTable";
export const anagramDictionaryMatchTransform = async ({ query, input, result, workerContext, }) => {
    // anagram match
    // アナグラムして辞書にある単語に変換します。
    const dictKey = query.dictionary ||
        (input.inputDictionary !== "free" ? input.inputDictionary : "");
    if (!dictKey) {
        return [];
    }
    const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
    const dict = createSortedTable(dictKeyArr);
    if (!dict) {
        return [];
    }
    return await convertFlat(workerContext, result, (e) => {
        const key = e.currentWord.split("").sort().join("");
        const ret = (dict[key] ?? []).map((e2) => {
            const newe = JSON.parse(JSON.stringify(e));
            newe.currentWord = e2;
            return newe;
        });
        return ret;
    });
};
