import { listPlaceholderCharacter, listTerminateCharacter, } from "../../../param/generalSearchQuery";
export const pairRegex = /pair\(\s*([^\s,()]*?)\s*,\s*([^\s,()]+)\s*,\s*([^\s,()]+)\s*(?:,\s*([^()]*)\s*)?\s*\)/g;
const stringToCharArr = (str) => {
    if (str.match(/[^{}\[\]]/)) {
        if (str.match(/\|/)) {
            return str.split("|");
        }
        return Array.from(str);
    }
    const { c2Arr } = Array.from(str).reduce((a, b) => {
        if (a.bracketDepth === 0 && b === "|") {
            if (!a.hasPipe) {
                const joinedC2Arr = a.c2Arr.join("");
                return {
                    bracketDepth: 0,
                    hasPipe: true,
                    c2Arr: [joinedC2Arr, ""],
                };
            }
            else {
                return {
                    ...a,
                    c2Arr: [...a.c2Arr, ""],
                };
            }
        }
        if (!a.hasPipe && a.bracketDepth === 0) {
            a.c2Arr.push("");
        }
        if (b === "{") {
            a.bracketDepth += 1;
        }
        else if (b === "}") {
            a.bracketDepth -= 1;
        }
        else if (b === "[") {
            a.bracketDepth += 1;
        }
        else if (b === "]") {
            a.bracketDepth -= 1;
        }
        a.c2Arr[a.c2Arr.length - 1] += b;
        return a;
    }, {
        hasPipe: false,
        bracketDepth: 0,
        c2Arr: [],
    });
    console.log(c2Arr);
    return c2Arr;
};
// 2つのListの共通位置の文字列に置き換える変換関数
export const pairConvert = (input, listDefinitionsTable, _trace) => {
    const regex = pairRegex;
    const replaced = input.replace(regex, (_, stringPart, listName1, listName2, option) => {
        const listContent1 = listName1
            .split("&")
            .map((listName) => listDefinitionsTable[listName]?.content ?? listName)
            .join(";");
        const listContent2 = listName2
            .split("&")
            .map((listName) => listDefinitionsTable[listName]?.content ?? listName)
            .join(";");
        option = option ?? "";
        if (!listContent1 || !listContent2) {
            return "";
        }
        const isAny = option.includes("a");
        const isLeastOnce = option.includes("o");
        const leastCounter = parseInt(option);
        if (!isNaN(leastCounter)) {
        }
        const contents1 = listContent1.split(listTerminateCharacter);
        const contents1Arr = contents1.map((str) => {
            if (str.match(/\|/)) {
                return str.split("|");
            }
            return Array.from(str);
        });
        const contents2 = listContent2.split(listTerminateCharacter);
        const contents2Arr = contents2.map((c2Line) => {
            return stringToCharArr(c2Line);
        });
        const pairTable = createPairTable(contents1Arr, contents2Arr);
        const stringArr = Array.from(stringPart);
        const isSingleKey = Object.keys(pairTable).every((key) => key.length === 1);
        if (isSingleKey) {
            const ret = convertSingleCharFromPairTable(pairTable, stringArr, isAny);
            if (isLeastOnce && ret === stringPart) {
                return "[]";
            }
            return ret;
        }
        else {
            const trieTree = createTrieTree(pairTable);
            const ret = convertMultiCharFromTrieTree(trieTree, stringArr, isAny);
            if (isLeastOnce && ret === stringPart) {
                return "[]";
            }
            return ret;
        }
    });
    return replaced;
};
const createPairTable = (contents1Arr, contents2Arr) => {
    const pairTable = {};
    contents1Arr.forEach((content1, i) => {
        content1.forEach((c1, j) => {
            const c2 = contents2Arr[i][j];
            if (c2 === listPlaceholderCharacter) {
                return;
            }
            if (!pairTable[c1]) {
                pairTable[c1] = [];
            }
            pairTable[c1].push(c2);
        });
    });
    return pairTable;
};
const createTrieTree = (pairTable) => {
    const trieTree = {};
    Object.keys(pairTable).forEach((key) => {
        let current = trieTree;
        key.split("").forEach((c) => {
            if (!current[c]) {
                current[c] = {};
            }
            current = current[c];
        });
        current[""] = pairTable[key];
    });
    return trieTree;
};
// longest match
const convertMultiCharFromTrieTree = (trieTree, stringArr, isAny) => {
    let ret = "";
    let current = trieTree;
    let i = 0;
    while (i < stringArr.length) {
        const c = stringArr[i];
        if (current[c]) {
            current = current[c];
            i += 1;
        }
        else {
            if (current[""]) {
                const nexts = current[""];
                if (isAny) {
                    ret += nexts[0];
                }
                else {
                    if (nexts.length === 1) {
                        ret += nexts[0];
                    }
                    else {
                        ret += "[" + nexts.join("|") + "]";
                    }
                }
            }
            else {
                ret += c;
                i += 1;
            }
            current = trieTree;
        }
    }
    if (current[""]) {
        const nexts = current[""];
        if (isAny) {
            ret += nexts[0];
        }
        else {
            if (nexts.length === 1) {
                ret += nexts[0];
            }
            else {
                ret += "[" + nexts.join("|") + "]";
            }
        }
    }
    return ret;
};
const convertSingleCharFromPairTable = (pairTable, stringArr, isAny) => {
    return stringArr
        .map((c) => {
        const nexts = pairTable[c];
        if (isAny && !nexts) {
            return c;
        }
        if (!nexts) {
            return "[]";
        }
        if (nexts.length === 1) {
            return nexts[0];
        }
        return "[" + nexts.join("|") + "]";
    })
        .join("");
};
