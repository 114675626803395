import { workerStatus } from "..";
import { calcEstimateValue } from "./estimation";
import { progress, sendProgress } from "./generalSearchProgress";
const loop = async (workerContext, result, run) => {
    let i = 0;
    for (const e of result) {
        if (workerStatus.abort) {
            break;
        }
        ++i;
        progress.get().queryProgress[progress.get().queryProgress.length - 1].currentWordCount = i;
        if (i % 1000 === 0) {
            await sendProgress(workerContext);
        }
        run(e, i);
    }
};
// use loop
export const filter = async (workerContext, result, condition) => {
    const list = [];
    return loop(workerContext, result, (e, i) => {
        if (condition(e, i)) {
            list.push(e);
        }
    })
        .then(() => {
        return list;
    })
        .catch(() => {
        return list;
    });
};
export const estimateFilter = async (workerContext, result, condition, estimationInput, estimationFilter) => {
    const list = [];
    return loop(workerContext, result, (e, i) => {
        const newEstimation = calcEstimateValue(e.currentEstimation ?? 1, estimationFilter, condition(e, i));
        if (!estimationInput.filterEnabled ||
            newEstimation > estimationInput.filterValue) {
            const newE = {
                ...e,
                currentEstimation: newEstimation,
            };
            list.push(newE);
        }
    }).then(() => {
        return list;
    });
};
export const convertFlat = async (workerContext, result, convert) => {
    const list = [];
    return loop(workerContext, result, (e, i) => {
        list.push(convert(e, i));
    })
        .then(() => {
        const _result = list.flat();
        _result.forEach((e) => {
            e.trace.push(e.currentWord);
        });
        return _result;
    })
        .catch(() => {
        return [];
    });
};
