//https://github.com/diegomura/react-pdf/issues/1234#issuecomment-823446382
import {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
  Text,
  Tspan,
} from "@react-pdf/renderer";
import { useState } from "react";

type ComponentType = React.ElementType;

// https://dev.to/qausim/convert-html-inline-styles-to-a-style-object-for-react-components-2cbi
const formatStringToCamelCase = (str: string) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

//https://react-pdf.org/svg#presentation-attributes
const presentationAttributes = [
  "color",
  "dominant-baseline",
  "fill",
  "fill-opacity",
  "fill-rule",
  "stroke",
  "stroke-width",
  "stroke-opacity",
  "stroke-linecap",
  "stroke-linejoin",
  "stroke-dasharray",
  "transform",
  "text-anchor",
  "visibility",
];

const getPresentationAttributes = (node: Element) => {
  const attributes: any = {};
  presentationAttributes.forEach((attr) => {
    const value = node.getAttribute(attr);
    if (value) {
      const camelCaseAttr = formatStringToCamelCase(attr);
      attributes[camelCaseAttr] = value;
    }
  });
  return attributes;
};

const getStyleObjectFromString = (str: string | null) => {
  const style: any = {};
  if (!str) return {};

  str.split(";").forEach((el) => {
    const [property, value] = el.split(":");
    if (!property) return;
    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};

function parseIntAttributes(attr: string | null) {
  if (!attr) return null;
  if (attr.includes("px")) return attr;

  return Number(attr);
}

export function SvgXmlToComponent(props: {
  xml: string;
  width?: number;
  height?: number;
}) {
  const { xml, width, height } = props;
  if (!xml || xml === "") return null;
  const document = new DOMParser().parseFromString(xml, "image/svg+xml");

  function renderNode(node: Element) {
    let Component: ComponentType;
    let componentProps = {};
    let innerText: string | null = null;
    switch (node.tagName.toUpperCase()) {
      case "SVG":
        Component = Svg;
        const originalHeight = Number(node.getAttribute("height"));
        const originalWidth = Number(node.getAttribute("width"));

        const newWidth = width
          ? width
          : height
          ? height * (originalWidth / originalHeight)
          : originalWidth;
        const newHeight = height
          ? height
          : width
          ? width * (originalHeight / originalWidth)
          : originalHeight;
        const viewBox = node.getAttribute("viewBox");
        let transformOrigin = "0 0";

        componentProps = {
          height: newHeight,
          width: newWidth,
          viewBox,

          style: {
            //transform: newTransform,
            fontSize: "12px",
            transformOrigin,
            display: "block",
            margin: "auto",
          },
        };
        break;
      case "RECT":
        Component = Rect;
        componentProps = {
          x: parseIntAttributes(node.getAttribute("x")),
          y: parseIntAttributes(node.getAttribute("y")),
          width: parseIntAttributes(node.getAttribute("width")),
          height: parseIntAttributes(node.getAttribute("height")),
          rx: parseIntAttributes(node.getAttribute("rx")),
          ry: parseIntAttributes(node.getAttribute("ry")),
        };
        break;
      case "CLIPPATH":
        Component = ClipPath;
        break;
      case "DEFS":
        Component = Defs;
        break;
      case "G":
        Component = G;
        componentProps = {
          "data-z-index": node.getAttribute("data-z-index"),
        };
        break;
      case "TEXT":
        innerText = node.textContent;
        Component = Text;
        componentProps = {
          x: parseIntAttributes(node.getAttribute("x")),
          "data-z-index": node.getAttribute("data-z-index"),
          style: getStyleObjectFromString(node.getAttribute("style")),
          y: parseIntAttributes(node.getAttribute("y")),
        };
        break;
      case "PATH":
        Component = Path;
        componentProps = {
          "data-z-index": node.getAttribute("data-z-index"),
          d: node.getAttribute("d"),
        };
        break;
      case "TSPAN":
        innerText = node.textContent;
        componentProps = {
          x: parseIntAttributes(node.getAttribute("x")),
          y: parseIntAttributes(node.getAttribute("y")),
          fontWeight: node.getAttribute("fontWeight"),
        };
        Component = Tspan;
        break;
      case "DESC":
        return null;
      default:
        throw new Error(`unsupported type ${node.tagName}`);
    }
    const attributes = getPresentationAttributes(node);
    if (attributes["strokeDasharray"]) {
      attributes["strokeDasharray"] = handleDashArray(
        attributes["strokeDasharray"]
      );
    }

    if (node.children && Array.from(node.children).length > 0) {
      return (
        <Component {...componentProps} {...attributes}>
          {Array.from(node.children).map(renderNode)}
        </Component>
      );
    }
    if (innerText) {
      return (
        <Component {...componentProps} {...attributes}>
          {innerText}
        </Component>
      );
    }

    return <Component {...componentProps} {...attributes} />;
  }
  const node = renderNode(document.documentElement);
  console.log(node);
  return node;
}

const handleDashArray = (dashArray: string | null | undefined) => {
  if (!dashArray) return undefined;
  return dashArray
    .split(/[,\s]+/)
    .map((s) => {
      return s === "0" ? "0.00001" : s;
    })
    .join(", ");
};
