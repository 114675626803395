import { FC, useEffect, useState } from "react";
import { Layer, Line, Rect } from "react-konva";
import { createBoard } from "../../../../../lib/puzzle/board";
import { NumberText } from "./uiElements/NumberText";
import { Field } from "../../../../../lib/puzzle/nurikabe";

type Props = {
  width: number;
  height: number;
};

const problem = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [2, 0, 0, 3, 0, 5, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
  [0, 0, 0, 2, 0, 0, 0, 1, 0, 7],
  [0, 4, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0, 3, 0, 0, 0],
  [0, 0, 0, 0, 0, 5, 0, 0, 0, 0],
];

const mizunashi4 = [
  [0, 1, 0, 0, 0, 1, 0, 6, 0, 0],
  [0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
  [0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 4, 0, 0],
  [0, 0, 0, 0, 4, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0, 6, 0, 0, 0, 0],
  [0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 5, 0, 0, 0, 0, 0, 6],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];
const flatProblem = mizunashi4.flat();

export const PuzzleView: FC<Props> = (props) => {
  const { width, height } = props;
  const cellSize = 48;
  const board = createBoard(10, 10);
  const boardWidth = board.cols * cellSize;
  const boardHeight = board.rows * cellSize;
  const [cx, setCx] = useState(-1);
  const [cy, setCy] = useState(-1);
  const [field] = useState(new Field());
  useEffect(() => {
    field.init(mizunashi4);
    let flag = true;
    while (flag) {
      flag = field.update();
    }
    console.log(field, field.result);
  }, [field]);

  return (
    <Layer>
      <Rect
        x={width / 2 - boardWidth / 2}
        y={height / 2 - boardHeight / 2}
        width={boardWidth}
        height={boardHeight}
        stroke={"black"}
        strokeWidth={cellSize * 0.1}
        fill={"white"}
      />
      {[...Array(board.cols)].map((_, i) => (
        <Line
          x={width / 2}
          y={height / 2}
          key={i}
          stroke={"black"}
          strokeWidth={cellSize * 0.02}
          points={[
            -boardWidth / 2 + cellSize * i,
            -boardHeight / 2,
            -boardWidth / 2 + cellSize * i,
            +boardHeight / 2,
          ]}
        />
      ))}
      {[...Array(board.rows)].map((_, i) => (
        <Line
          x={width / 2}
          y={height / 2}
          key={i}
          stroke={"black"}
          strokeWidth={cellSize * 0.02}
          points={[
            -boardWidth / 2,
            -boardHeight / 2 + cellSize * i,
            +boardWidth / 2,
            -boardHeight / 2 + cellSize * i,
          ]}
        />
      ))}
      {[...Array(board.cols)].map((_, x) =>
        [...Array(board.rows)]
          .map(
            (_, y) =>
              flatProblem[y * board.cols + x] !== 0 && (
                <NumberText
                  x={x}
                  y={y}
                  num={flatProblem[y * board.cols + x]}
                  key={x + "," + y}
                  width={width}
                  height={height}
                  boardWidth={boardWidth}
                  boardHeight={boardHeight}
                  cellSize={cellSize}
                />
              )
          )
          .filter((e) => e)
      )}
      {field.result.map((e, i) => {
        const x = i % board.cols;
        const y = Math.floor(i / board.cols);
        return e > 0 && flatProblem[i] === 0 ? (
          <Rect
            key={x + "," + y}
            x={
              width / 2 - boardWidth / 2 + (i % 10) * cellSize + 0.45 * cellSize
            }
            y={
              height / 2 -
              boardHeight / 2 +
              Math.floor(i / 10) * cellSize +
              0.45 * cellSize
            }
            width={0.1 * cellSize}
            height={0.1 * cellSize}
            fill={"green"}
          />
        ) : e < 0 ? (
          <Rect
            key={x + "," + y}
            x={width / 2 - boardWidth / 2 + x * cellSize}
            y={height / 2 - boardHeight / 2 + y * cellSize}
            width={cellSize}
            height={cellSize}
            fill={"black"}
          />
        ) : (
          <></>
        );
      })}
      {(field.rooms[10]?.prediction ?? []).map((e, i) => {
        const x = i % board.cols;
        const y = Math.floor(i / board.cols);
        return e === 1 ? (
          <Rect
            key={x + "," + y}
            x={
              width / 2 - boardWidth / 2 + (i % 10) * cellSize + 0.45 * cellSize
            }
            y={
              height / 2 -
              boardHeight / 2 +
              Math.floor(i / 10) * cellSize +
              0.45 * cellSize
            }
            width={0.1 * cellSize}
            height={0.1 * cellSize}
            fill={"blue"}
          />
        ) : (
          <></>
        );
      })}

      {(field.rooms[11]?.prediction ?? []).map((e, i) => {
        const x = i % board.cols;
        const y = Math.floor(i / board.cols);
        return e === 1 ? (
          <Rect
            key={x + "," + y}
            x={
              width / 2 - boardWidth / 2 + (i % 10) * cellSize + 0.45 * cellSize
            }
            y={
              height / 2 -
              boardHeight / 2 +
              Math.floor(i / 10) * cellSize +
              0.45 * cellSize
            }
            width={0.1 * cellSize}
            height={0.1 * cellSize}
            fill={"red"}
          />
        ) : (
          <></>
        );
      })}
    </Layer>
  );
};
