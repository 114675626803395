export const pokemon9 = `ニャオハ
ニャローテ
マスカーニャ
ホゲータ
アチゲータ
ラウドボーン
クワッス
ウェルカモ
ウェーニバル
グルトン
パフュートン
タマンチュラ
ワナイダー
マメバッタ
エクスレッグ
パモ
パモット
パーモット
ワッカネズミ
イッカネズミ
パピモッチ
バウッツェル
ミニーブ
オリーニョ
オリーヴァ
イキリンコ
コジオ
ジオヅム
キョジオーン
カルボウ
グレンアルマ
ソウブレイズ
ズピカ
ハラバリー
カイデン
タイカイデン
オラチフ
マフィティフ
シルシュルー
タギングル
アノクサ
アノホラグサ
ノノクラゲ
リククラゲ
ガケガニ
カプサイジ
スコヴィラン
シガロコ
ベラカス
ヒラヒナ
クエスパトラ
カヌチャン
ナカヌチャン
デカヌチャン
ウミディグダ
ウミトリオ
オトシドリ
ナミイルカ
イルカマン
ブロロン
ブロロローム
モトトカゲ
ミミズズ
キラーメ
キラフロル
ボチ
ハカドッグ
カラミンゴ
アルクジラ
ハルクジラ
ミガルーサ
ヘイラッシャ
シャリタツ
コノヨザル
ドオー
リキキリン
ノココッチ
ドドゲザン
イダイナキバ
サケブシッポ
アラブルタケ
ハバタクカミ
チヲハウハネ
スナノケガワ
テツノワダチ
テツノツツミ
テツノカイナ
テツノコウベ
テツノドクガ
テツノイバラ
セビエ
セゴール
セグレイブ
コレクレー
サーフゴー
チオンジェン
パオジアン
ディンルー
イーユイ
トドロクツキ
テツノブジン
コライドン
ミライドン
ウネルミナモ
テツノイサハ
イイネイヌ
マシマシラ
キチギキス
オーガポン
テラパゴス
カミッチュ
ブリジュラス
タケルライコ
テツノカシラ
チャデス`;
