import { FC } from "react";
import { Horizon } from "./component/Horizon";
import { Image } from "./component/ImageUrl";
import { Space } from "./component/Space";
import { SubTitle } from "./component/SubTitle";
import { Svg } from "./component/Svg";
import { Text } from "./component/Text";
import { Title } from "./component/Title";
import { StackData } from "./stackData";
import { Vertical } from "./component/Vertical";
import { VerticalSpace } from "./component/VerticalSpace";

export const PdfViewItem: FC<{ content: StackData }> = ({ content }) => {
  return (
    <>
      <Horizon content={content} />
      <Vertical content={content} />
      <Svg contents={content} />
      <Title contents={content} />
      <SubTitle contents={content} />
      <Text contents={content} />
      <Image contents={content} />
      <Space contents={content} />
      <VerticalSpace contents={content} />
    </>
  );
};
