import { Box, Button, Container, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useStatePersist } from "use-state-persist";
import { useWorker } from "../../../../hooks/useWorker";
import { PlainText } from "../../common/PlainText";
import { ResultList } from "../../common/Result";
import { Title } from "../../common/Title";
import { CryptoArithmeticSearchProgress } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const CryptoArithmetic = () => {
  const [input1, setInput1] = useStatePersist<string>(
    "crypto-arithmetics-input1",
    ""
  );
  const [solutionCount, setSolutionCount] = useState<number>(100);
  const [result, setResult] = useState<string[]>([]);
  const [searching, setSearching] = useState(false);
  const worker = useWorker();
  const [progress, setProgress] = useState<CryptoArithmeticSearchProgress>({
    startTime: 0,
    currentTime: 0,
    tryCount: 0,
    maxCount: 1,
  });
  const onSearch = useCallback(() => {
    setSearching(true);
    setResult([]);
    worker.cryptoArithmetic(
      input1,
      solutionCount,
      (result) => {
        if (result) {
          setResult(result);
          setSearching(false);
        }
      },
      (progress) => {
        setProgress(progress);
      }
    );
  }, [worker, progress]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>覆面算ソルバー</Title>
      <PlainText>
        改行区切りで複数の数式を入力可能。四則演算、括弧、等号、不等号に対応。
        等号、不等号は1つの数式に1つまで
      </PlainText>
      <TextField
        label="数式"
        variant="outlined"
        sx={{ width: "80vw", mt: 2 }}
        multiline
        value={input1}
        onChange={(event) => {
          setInput1(event.target.value);
        }}
      />
      <TextField
        type="number"
        label="解の数の上限"
        variant="outlined"
        sx={{ mt: 2 }}
        value={solutionCount}
        onChange={(event) => {
          setSolutionCount(parseInt(event.target.value));
        }}
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => onSearch()}>
          Go
        </Button>
      </Box>
      <ResultList
        items={result}
        loading={searching}
        loadingMessage={
          "計算中 " +
          ((progress.tryCount / progress.maxCount) * 100).toFixed(2) +
          "%"
        }
      />
    </Container>
  );
};
