import { Arrow, Text } from "react-konva";
type Props = {
  x: number;
  y: number;
  num: number;
  boardWidth: number;
  boardHeight: number;
  cellSize: number;
  width: number;
  height: number;
};

export const NumberText = (props: Props) => {
  const { x, y, num, boardWidth, boardHeight, cellSize, width, height} = props;
  return (
    <>
      <Text
        text={"" + (num === -2 ? "?" : num)}
        x={width / 2- boardWidth / 2 + x * cellSize}
        y={height / 2- boardHeight / 2 + y * cellSize}
        fontFamily={"Helvetica"}
        fontSize={cellSize * 0.85}
        align={"center"}
        verticalAlign={"bottom"}
        width={cellSize}
        height={cellSize}
      />
    </>
  );
};
