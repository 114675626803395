import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  pageja: {
    padding: 30,
    fontSize: 11,
    fontFamily: "NotoSansJP",
  },
  pageen: {
    padding: 30,
    fontSize: 11,
    fontFamily: "OpenSans",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  rule: {
    fontSize: 10,

    fontWeight: "light",
  },
  problemTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  svg: {
    fontFamily: "OpenSans",
    fontSize: 10,
  },
  header: { marginBottom: 10 },
  body: {
    flexGrow: 1,
  },

  footer: { marginTop: 10 },
});
