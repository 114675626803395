import { Container } from "@mui/material";
import { FC } from "react";
import { getAllValues } from "../../../../data/kobuta";
import { Title } from "../../common/Title";
import { GeneralSearchResultList } from "../../commonStudio/GeneralSearchResult";

export const Kobuta: FC = () => {
  const { words } = getAllValues();
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>仔豚プロジェクト</Title>

      <GeneralSearchResultList
        items={words}
        loading={false}
        queries={[]}
      />
    </Container>
  );
};
