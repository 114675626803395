import { FC, useMemo } from "react";
import { useSiteConfig } from "../../../../../_lib/eniverse/EniverseProvider";
import { MainProps } from "../../../../../_lib/eniverse/imaginate/CommonMain";
import { useImaginateConfig } from "../../../../../_lib/eniverse/imaginate/ImaginateProvider";
import { Apps } from "../../../../../_lib/eniverse/imaginate/mainView/Apps";
import { FolderView } from "../../../../../_lib/eniverse/imaginate/mainView/FolderView";
import { If } from "../../../../../_lib/eniverse/util/Condition";
import { randomKeyGen } from "../../../../../_lib/eniverse/util/keygen";
import { PanTokigamiTop } from "./PanTokigamiTop";
import { panTokigamiAppsData } from "./panTokigamiAppsData";
import { KachidokiManagerView } from "./KachidokiManagerView";
import { ImageLibraryView } from "../../../../../_lib/eniverse/imaginate/mainView/ImageLibraryView";
import { KachidokiProblemView } from "./KachidokiProblemView";

export const PanTokigamiMain: FC<MainProps> = (props) => {
  const { currentNode, updateNode, treeData, setSelectedNode, treeCommand } =
    props;

  const [{ serviceId }] = useSiteConfig();
  const [{ projectId }] = useImaginateConfig();
  const workspaceId = serviceId;

  if (!currentNode) {
    return <PanTokigamiTop />;
  }

  const createDocument = (type: string) => {
    if (type.endsWith("folder")) {
      updateNode({
        ...currentNode,
        droppable: true,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    } else {
      updateNode({
        ...currentNode,
        droppable: false,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    }
  };

  if (!currentNode.data || currentNode.data.fileType === "") {
    return (
      <>
        <If condition={!currentNode.droppable}>
          <Apps
            createDocument={createDocument}
            appsData={panTokigamiAppsData}
          />
        </If>
        <If condition={currentNode.droppable}>
          <FolderView
            currentNode={currentNode}
            treeData={treeData}
            addApp={() => {
              treeCommand.addNode(currentNode.id, treeData);
            }}
            setSelectedNode={setSelectedNode}
          />
        </If>
      </>
    );
  }

  return (
    <>
      <If condition={currentNode.data.fileType === "app"}>
        <Apps createDocument={createDocument} appsData={panTokigamiAppsData} />
      </If>
      <If condition={currentNode.data.fileType === "kachidoki-manager"}>
        <KachidokiManagerView
          currentNode={currentNode}
          treeData={treeData}
          addApp={async ({ type, name }: { type: string; name: string }) => {
            return await treeCommand.addNode(currentNode.id, treeData, {
              type,
              name,
            });
          }}
          setSelectedNode={setSelectedNode}
        />
      </If>
      <If condition={currentNode.data.fileType === "image-library"}>
        <ImageLibraryView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspaceId}
          project={projectId}
        />
      </If>
      <If condition={currentNode.data.fileType === "kachidoki-setter"}>
        <KachidokiProblemView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspaceId}
          project={projectId}
          treeData={treeData}
        />
      </If>
    </>
  );
};
