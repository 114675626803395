import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { FC } from "react";

type Props = {
  charTypeValue: string;
  handleCharTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CharTypeSelector: FC<Props> = ({
  charTypeValue,
  handleCharTypeChange,
}) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={charTypeValue}
        onChange={handleCharTypeChange}
      >
        <FormControlLabel
          value="hiragana"
          control={<Radio />}
          label="ひらがな"
        />
        <FormControlLabel
          value="katakana"
          control={<Radio />}
          label="カタカナ"
        />
        <FormControlLabel
          value="alphabet"
          control={<Radio />}
          label="アルファベット"
        />
      </RadioGroup>
    </FormControl>
  );
};
