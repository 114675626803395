import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import StartIcon from "@mui/icons-material/Start";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { useSound } from "use-sound";
import { useStatePersist } from "use-state-persist";
import { db, firebaseApp } from "../../../..";
import tokohalImage from "../../../../static/images/icon/halnanndesu.jpg";
import wandImage from "../../../../static/images/icon/wand.jpg";
import questionImage from "./images/question.png";
import cursorSound from "./sounds/cursor.mp3";
import startSound from "./sounds/gong-played1.mp3";
import finishSound from "./sounds/gong-played2.mp3";
import nextSound from "./sounds/hyoushigi1.mp3";
import failSound from "./sounds//stupid3.mp3";
import successSound from "./sounds/success.mp3";
import clearSound from "./sounds/trumpet1.mp3";
import gold from "./images/gold.png";
import silver from "./images/silver.png";
import { createTake } from "../SprintAnswer/data/take";
import { hikakinImages } from "./data/hikakinImages";
import { questionPosition } from "./data/questionPositon";
import { DrawerState } from "../../common/drawerState";
type HighAndLowProps = {
  drawer: DrawerState;
};

const gamePhase = {
  title: "title",
  playing: "playing",
  end: "end",
};
type GamePhase = (typeof gamePhase)[keyof typeof gamePhase];

type CardState = {
  cardStatus: "success" | "fail" | "normal";
  numberHidden: boolean;
  in: boolean;
  visible: boolean;
  prev?: number;
  current: number;
  combo: number;
  choosed: "high" | "low" | null;
};

export const hikakinOpen = () => true;

export const comboMessage = (combo: number) => {
  if (combo === 100) {
    return "ゲームを完全制覇した！";
  }
  if (combo >= 50) {
    return "金の盾に到達した";
  }
  if (combo >= 25) {
    return "銀の盾に到達した";
  }
  if (combo >= 10) {
    return "ゲームをクリアした！";
  }
  return "";
};

export const tweet = (combo: number) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `HIKAKIN結婚報告ハイ&ローで${
      combo === 100 ? "フルコンボ達成！！" : `${combo}コンボ達成！`
    }
${comboMessage(combo)}

https://x.com/halnanndesu/status/1754796410015506869  
#ヒカキンハイロー`
  )}`;
};

export const hikakinGameInfo = {
  title: "HIKAKIN結婚報告ハイ＆ロー",
  gameId: "hikakin-marriage",
};
export const HighAndLow: FC<HighAndLowProps> = (props) => {
  const ref = useRef(null);
  const [gamePhase, setGamePhase] = useState<GamePhase>("title");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  const currentQuestionPosition = useMemo(() => {
    return questionPosition[currentImageIndex];
  }, [currentImageIndex]);
  console.log("currentQuestionPosition", currentQuestionPosition);
  const [checked, setChecked] = useState<CardState[]>([]);

  const [enableSound, setEnableSound] = useStatePersist<boolean>(
    `enigma-gamein-${hikakinGameInfo.gameId}-game-enable-sound`,
    true
  );
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(db, "gamein", hikakinGameInfo.gameId, "counter"),
        orderBy("count", "desc"),
        limit(1)
      ),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        console.log(doc);
        setData(doc.data());
      }
    );
    return unsub;
  }, []);

  const addGameRecord = () => {
    axios.post(
      `https://us-central1-${firebaseApp.options.projectId}.cloudfunctions.net/addGameRecord`,
      {
        gameId: hikakinGameInfo.gameId,
        type: "counter",
      }
    );
  };
  // functions

  const [playStart] = useSound(startSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [playFinish] = useSound(finishSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [playFail] = useSound(failSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [playSuccess] = useSound(successSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [playNext] = useSound(nextSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [playClear] = useSound(clearSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [playCursor] = useSound(cursorSound, {
    volume: 0.7,
    soundEnabled: enableSound,
  });

  const [bestCombo, setBestCombo] = useStatePersist<number | null>(
    `enigma-gamein-${hikakinGameInfo.gameId}-best-combo`,
    null
  );

  useEffect(() => {
    handleReset();
  }, []);

  useEffect(() => {
    hikakinImages.forEach((image, i) => {
      if (i - currentImageIndex > 5 || i - currentImageIndex < -5) return;
    });
  }, [currentImageIndex]);

  const middleSize = useMediaQuery("(min-width:600px)");
  const calcHeight = (rate: number) => {
    console.log("middleSize", middleSize);
    return middleSize
      ? `calc(min(100vw - 48px, 852px) * 0.5625 * ${rate})`
      : `calc(100vw * 0.5625 * ${rate})`;
  };

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const preload = useCallback((number?: number) => {
    if (number === undefined) return;

    const image = hikakinImages[number];
    if (!image) return;
    const img = new Image();
    img.src = image; // プリロードする
    img.onload = () => {
      console.log("loaded", number);
    };
  }, []);

  const takeCount = 100;
  const handleStart = useCallback(() => {
    const checked: CardState[] = [];
    checked.push({
      cardStatus: "normal",
      numberHidden: false,
      in: true,
      visible: true,
      current: 0,
      combo: 0,
      choosed: null,
    });
    const numbers = createTake(
      Array.from(new Array(hikakinImages.length - 1)).map((_, i) => i + 1),
      takeCount
    );

    for (let i = 0; i < takeCount; i++) {
      const data: CardState = {
        cardStatus: "normal",
        numberHidden: true,
        in: false,
        visible: false,
        current: numbers[i],
        combo: i + 1,
        choosed: null,
      };
      if (i > 0) {
        data.prev = numbers[i - 1];
      }
      checked.push(data);
    }
    checked[1].numberHidden = false;
    preload(checked[1].current);
    preload(checked[2].current);
    setChecked(checked);
    window.setTimeout(() => {
      setChecked((prev) => {
        const next = [...prev];
        next[1].in = true;
        next[1].visible = true;
        return next;
      });
      playSuccess();
    }, 500);
    setCurrentImageIndex(1);
    addGameRecord();
    setData((prev: any) => {
      return {
        ...prev,
        count: prev.count + 1,
      };
    });
    playStart();
    setGamePhase("playing");
  }, [playStart, playSuccess, preload, takeCount]);

  const handleClear = useCallback(() => {
    playClear();
    setGamePhase("end");
    setOpen(true);
    setBestCombo((prev) =>
      Math.max(prev ?? 0, checked[currentImageIndex].combo)
    );
  }, [checked, currentImageIndex, playClear, setBestCombo]);

  const handleNext = useCallback(() => {
    playCursor();
    window.setTimeout(() => {
      setChecked((prev) => {
        const next = [...prev];
        next[2].in = true;
        next[2].visible = true;
        return next;
      });
      setCurrentImageIndex(2);
      preload(checked[3].current);
      playNext();
    }, 500);
  }, [checked, playCursor, playNext]);

  const handleSuccess = useCallback(
    (e: number) => {
      playCursor();
      preload(checked[e + 1]?.current);
      setChecked((prev) => {
        const next = [...prev];
        next[e].numberHidden = false;
        return next;
      });
      window.setTimeout(() => {
        setChecked((prev) => {
          const next = [...prev];
          next[e].cardStatus = "success";
          return next;
        });
        playSuccess();
      }, 500);
      window.setTimeout(() => {
        if (e === takeCount) {
          handleClear();
          return;
        }
        setCurrentImageIndex(e + 1);
        playNext();
        setChecked((prev) => {
          const next = [...prev];
          next[e + 1].in = true;
          next[e + 1].visible = true;
          return next;
        });
      }, 1500);
    },
    [checked, handleClear, playCursor, playNext, playSuccess]
  );

  const handleFail = useCallback(
    (e: number) => {
      playCursor();
      setChecked((prev) => {
        const next = [...prev];
        next[e].numberHidden = false;
        return next;
      });

      window.setTimeout(() => {
        setChecked((prev) => {
          const next = [...prev];
          next[e].cardStatus = "fail";
          return next;
        });
        playFail();
      }, 500);

      window.setTimeout(() => {
        setGamePhase("end");
        setOpen(true);
        playFinish();
      }, 1500);
      setBestCombo((prev) =>
        Math.max(prev ?? 0, checked[currentImageIndex].combo)
      );
    },
    [checked, currentImageIndex, playCursor, playFail, playFinish, setBestCombo]
  );

  const handleHigh = useCallback(() => {
    checked[currentImageIndex].choosed = "high";
    if (
      checked[currentImageIndex].current >
      (checked[currentImageIndex]?.prev ?? 0)
    ) {
      handleSuccess(currentImageIndex);
    } else {
      handleFail(currentImageIndex);
    }
  }, [checked, currentImageIndex, handleFail, handleSuccess]);

  const handleLow = useCallback(() => {
    checked[currentImageIndex].choosed = "low";
    if (
      checked[currentImageIndex].current <
      (checked[currentImageIndex]?.prev ?? 0)
    ) {
      handleSuccess(currentImageIndex);
    } else {
      handleFail(currentImageIndex);
    }
  }, [checked, currentImageIndex, handleFail, handleSuccess]);

  const handleReset = () => {
    const checked: CardState[] = [];
    checked.push({
      cardStatus: "normal",
      numberHidden: false,
      in: true,
      visible: true,
      combo: 0,
      current: 0,
      choosed: null,
    });
    setChecked(checked);
    setGamePhase("title");
    setCurrentImageIndex(0);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
        if (
          gamePhase === "playing" &&
          currentImageIndex >= 2 &&
          checked[currentImageIndex].numberHidden &&
          checked[currentImageIndex].cardStatus === "normal" &&
          checked[currentImageIndex].visible
        ) {
          event.preventDefault();
          handleLow();
        }
      }
      if (event.key === "ArrowRight" || event.key === "ArrowUp") {
        if (
          gamePhase === "playing" &&
          currentImageIndex >= 2 &&
          checked[currentImageIndex].numberHidden &&
          checked[currentImageIndex].cardStatus === "normal" &&
          checked[currentImageIndex].visible
        ) {
          if (gamePhase === "playing") {
            event.preventDefault();
            handleHigh();
          }
        }
      }
      if (
        event.key === "ArrowRight" ||
        event.key === "Enter" ||
        event.key === " "
      ) {
        event.preventDefault();
        if (currentImageIndex === 1) {
          handleNext();
        } else if (currentImageIndex === 0) {
          handleStart();
        }
      }
      if (event.key === "Escape") {
        event.preventDefault();
        handleClose();
        handleReset();
      }
    },
    [
      checked,
      currentImageIndex,
      gamePhase,
      handleHigh,
      handleLow,
      handleNext,
      handleStart,
    ]
  );
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Helmet>
        <title>HIKAKIN結婚報告ハイ&ロー - EnigmaGAMEIN</title>
      </Helmet>
      {checked && checked[currentImageIndex] && (
        <Container
          maxWidth="md"
          sx={{
            mx: "auto",
            px: 0,
            width: "min(100vw,900px)",
            textAlign: "center",
          }}
          ref={containerRef}
        >
          <Stack direction={"row"}>
            <SportsEsportsIcon
              sx={{ mt: 1, ml: 1, fontSize: "30px" }}
              onClick={() => {
                props.drawer.handleDrawerOpen();
              }}
            />
            <h3
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              HIKAKIN結婚報告ハイ＆ロー
            </h3>
          </Stack>
          {gamePhase === "end" && (
            <Dialog
              onClose={handleClose}
              open={open}
              sx={{
                minWidth: "350px",
              }}
            >
              <DialogTitle
                mx={{
                  minWidth: "350px",
                  width: "100%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                {checked[currentImageIndex].combo === takeCount ? (
                  <H1Outline>完全制覇</H1Outline>
                ) : checked[currentImageIndex].combo >= 10 ? (
                  <H1Outline>クリア</H1Outline>
                ) : (
                  <H1Outline>終了</H1Outline>
                )}
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Stack>
                  {checked[currentImageIndex].combo === takeCount ? (
                    <Typography
                      variant="h5"
                      sx={{ textAlign: "center", mb: 3 }}
                    >
                      フルコンボ達成！！
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      sx={{ textAlign: "center", mb: 3 }}
                    >
                      {checked[currentImageIndex].combo}コンボ達成！
                    </Typography>
                  )}

                  {checked[currentImageIndex].combo >= 50 ? (
                    <img
                      src={gold}
                      alt="hikakin"
                      style={{ width: "50%", margin: "auto" }}
                    />
                  ) : checked[currentImageIndex].combo >= 25 ? (
                    <img
                      src={silver}
                      alt="hikakin"
                      style={{ width: "50%", margin: "auto" }}
                    />
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="contained"
                    startIcon={<TwitterIcon />}
                    sx={{ mx: "auto", textAlign: "center", mt: 3, mb: 3 }}
                    color="info"
                    size="large"
                    onClick={() => {
                      window.open(
                        tweet(checked[currentImageIndex].combo),
                        "_blank"
                      );
                    }}
                  >
                    結果をPost
                  </Button>

                  <Button
                    variant="contained"
                    startIcon={<ReplayIcon />}
                    sx={{ mx: "auto", textAlign: "center", mt: 2, mb: 3 }}
                    color="error"
                    size="large"
                    onClick={() => {
                      handleClose();
                      playCursor();
                      handleReset();
                    }}
                  >
                    もう一度
                  </Button>
                </Stack>
              </DialogContent>
            </Dialog>
          )}
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: `calc(${calcHeight(1)} + 12.5rem)`,
              mb: 2,
            }}
          >
            {[currentImageIndex - 1, currentImageIndex].map(
              (e, i) =>
                e >= 0 &&
                e !== undefined &&
                checked[e].visible && (
                  <Slide
                    in={checked[e].in}
                    direction="left"
                    container={containerRef.current}
                    key={"slide:" + e}
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        mt: 0,
                        mb: 2,
                        width: "100%",
                        textAlign: "left",
                        bgcolor:
                          checked[e].cardStatus === "success"
                            ? "#B9F6CA"
                            : checked[e].cardStatus === "fail"
                            ? "#FF8A80"
                            : "white",
                      }}
                      key={e}
                    >
                      <CardMedia
                        component="img"
                        src={hikakinImages[checked[e].current]}
                        ref={ref}
                        sx={{
                          mb: 0,
                          width: "100%",
                          minHeight: calcHeight(1),
                        }}
                      />
                      <Fade
                        in={checked[e].numberHidden}
                        timeout={{
                          appear: 0,
                          enter: 0,
                          exit: 300,
                        }}
                      >
                        <CardMedia
                          component="img"
                          src={questionImage}
                          ref={ref}
                          sx={{
                            position: "relative",
                            top: calcHeight(
                              -1 +
                                (questionPosition[checked[e].current].top ?? 0)
                            ),
                            left: `calc(clamp(min(100vw,552px),100%, 852px) * ${
                              questionPosition[checked[e].current].left ?? 0
                            })`,
                            width: `calc(clamp(min(100vw,552px),100%, 852px)  * 0.5625 * ${
                              questionPosition[checked[e].current].width ?? 0
                            })`,
                            height: calcHeight(
                              questionPosition[checked[e].current].height ?? 0
                            ),
                            opacity: questionPosition[checked[e].current]
                              .visible
                              ? 1
                              : 0,
                          }}
                        />
                      </Fade>
                      <h3
                        style={{
                          margin: "0",
                          marginTop: calcHeight(
                            -(questionPosition[checked[e].current].height ?? 0)
                          ),
                          marginLeft: "0.5rem",
                          width: "100%",
                        }}
                      >
                        【現在
                        <span style={{ fontWeight: "bold" }}>
                          {checked[e].combo}
                        </span>
                        コンボ】
                        {bestCombo && <>（ベスト: {bestCombo}）</>}
                      </h3>
                      {e >= 1 && (
                        <CardContent sx={{ textAlign: "center" }}>
                          <Typography variant="h3" className="game">
                            {checked[e].prev && checked[e].prev + " →"}{" "}
                            {checked[e].numberHidden
                              ? "??"
                              : checked[e].current}
                          </Typography>
                        </CardContent>
                      )}
                      <CardContent sx={{ textAlign: "center" }}>
                        {e === 0 && (
                          <Button
                            variant="contained"
                            startIcon={<PlayArrowIcon />}
                            sx={{ mx: "auto", minWidth: "35%", my: 5 }}
                            color="error"
                            size="large"
                            onClick={() => {
                              handleStart();
                            }}
                          >
                            スタート
                          </Button>
                        )}
                        {e === 1 && (
                          <Button
                            variant="contained"
                            startIcon={<StartIcon />}
                            sx={{ mx: "auto", width: "35%" }}
                            color="success"
                            size="large"
                            onClick={() => {
                              handleNext();
                            }}
                          >
                            次へ
                          </Button>
                        )}
                        {e >= 2 && (
                          <>
                            <Button
                              variant="contained"
                              startIcon={<KeyboardDoubleArrowDownIcon />}
                              sx={{ ml: 1, mr: 4, width: "35%" }}
                              color="info"
                              size="large"
                              onClick={() => {
                                if (checked[e].choosed !== null) {
                                  return;
                                }
                                handleLow();
                              }}
                              disabled={checked[e].choosed === "high"}
                              disableRipple={
                                checked[e].choosed !== null ||
                                gamePhase === "end"
                              }
                            >
                              Low
                            </Button>
                            <Button
                              variant="contained"
                              startIcon={<KeyboardDoubleArrowUpIcon />}
                              sx={{ ml: 4, mr: 1, width: "35%" }}
                              color="error"
                              size="large"
                              onClick={() => {
                                if (checked[e].choosed !== null) {
                                  return;
                                }
                                handleHigh();
                              }}
                              disabled={checked[e].choosed === "low"}
                              disableRipple={
                                checked[e].choosed !== null ||
                                gamePhase === "end"
                              }
                            >
                              High
                            </Button>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Slide>
                )
            )}
          </Box>
          {gamePhase === "end" && (
            <Card sx={{ width: "50%", my: 2, mx: "auto" }}>
              <CardContent>
                <Stack>
                  <Button
                    variant="contained"
                    startIcon={<ReplayIcon />}
                    sx={{ mx: "auto", textAlign: "center", mb: 3 }}
                    color="error"
                    size="large"
                    onClick={() => {
                      handleClose();
                      playCursor();
                      handleReset();
                    }}
                  >
                    もう一度
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<TwitterIcon />}
                    sx={{ mx: "auto", textAlign: "center", mb: 1 }}
                    color="info"
                    onClick={() => {
                      window.open(
                        tweet(checked[currentImageIndex].combo),
                        "_blank"
                      );
                    }}
                  >
                    結果をPost
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          )}
          <Card
            sx={{
              textAlign: "left",
            }}
          >
            <CardHeader
              avatar={
                <AvatarGroup>
                  <Avatar
                    alt="常春"
                    src={tokohalImage}
                    onClick={() => {
                      window.open("https://x.com/halnanndesu", "_blank");
                    }}
                  />
                  <Avatar
                    alt="わんど"
                    src={wandImage}
                    onClick={() => {
                      window.open("https://x.com/wand_125", "_blank");
                    }}
                  />
                </AvatarGroup>
              }
              title={<>企画: 常春 / 制作: わんど</>}
              subheader={
                data?.count &&
                `総プレイ数 ${(data?.count ?? 0).toLocaleString()}回`
              }
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                HIKAKINさん、どこを撮っても絵になる <br />
                元動画 →{" "}
                <a
                  href="https://www.youtube.com/watch?v=nOzW1zieR7w"
                  target="_blank"
                >
                  https://www.youtube.com/watch?v=nOzW1zieR7w
                </a>
                <br />
                プレイ動画 →{" "}
                <a
                  href="https://twitter.com/halnanndesu/status/1754796410015506869"
                  target="_blank"
                >
                  https://twitter.com/halnanndesu/status/1754796410015506869
                </a>
                <br />
                <br />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                【遊び方】
                <br />
                100種類のヒカキンの結婚報告画像が表示されます。 <br />
                前の画像よりも番号が大きいか小さいかを選んでください <br />
                まずは10コンボ目指して挑戦してみてください！
              </Typography>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            startIcon={<SportsEsportsIcon />}
            sx={{ mx: "auto", textAlign: "center", mt: 6, mb: 3 }}
            size="large"
            onClick={() => {
              navigate("/g");
            }}
          >
            ゲーム一覧へ
          </Button>
        </Container>
      )}
    </>
  );
};

const H1Outline = styled.h1`
  --x: 0 0 var(--text-outline--width, 1px) var(--text-outline--color, #fff);
  --y: var(--x), var(--x), var(--x), var(--x), var(--x), var(--x); /* 6 */
  text-shadow: var(--y), var(--y), var(--y), var(--y), var(--y), var(--y); /* 6 * 6 = 36 */

  color: #c62828;
  font-size: 4rem;
  --text-outline--width: 2px;
  text-align: center;
  margin: auto;
`;
