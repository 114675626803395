import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useCallback, useState } from "react";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { useFirebaseConfig } from "../../../_lib/eniverse/EniverseProvider";
import { httpsCallable } from "firebase/functions";
import { Button } from "@mui/material";

type PurchaseButtonProps = {
  label: string;
};

export const EnigmaStudioSubscriptionButton = (props: PurchaseButtonProps) => {
  const { user } = useFirebaseLogin();
  const [{ cloudFunctions }] = useFirebaseConfig();
  const [loading, setLoading] = useState(false);

  const showPayment = useCallback(async () => {
    if (!user) {
      return;
    }
    if (loading) {
      return;
    }
    if (!cloudFunctions) {
      console.log("cloudFunctions is not ready");
      return;
    }
    setLoading(true);
    const createPaymentSession = httpsCallable(
      cloudFunctions,
      "stripe-createEnigmaStudioSubscription"
    );

    const ret = await createPaymentSession({
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    }).catch((err: any) => {
      console.log(err.message);
      setLoading(false);
    });
    if (!ret) {
      setLoading(false);
      return;
    }

    const db = getFirestore();
    const docRef = doc(
      db,
      "stripe-customers",
      user.uid,
      "checkout_sessions",
      (ret.data as any).docId
    );
    onSnapshot(docRef, (snap: any) => {
      const { error, url } = snap.data();
      if (error) {
        console.log(`An error occured: ${error.message}`);
        setLoading(false);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  }, [user, loading, cloudFunctions]);

  return (
    <Button
      variant="contained"
      onClick={() => showPayment()}
      disabled={loading}
    >
      決済ページを開く
    </Button>
  );
};
