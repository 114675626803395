import {
  CellsByAddressType,
  GridSheet,
  constructInitialCells,
} from "@gridsheet/react-core";
import { Box, Button } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useDoc } from "../../storekit/firestore/pure/useDoc";
import { CommonAppProp } from "./CommponAppProp";
import { useDocOnce } from "../../storekit/firestore/pure/useDocOnce";

export const GridSheetView: FC<CommonAppProp> = (props) => {
  const { currentNode, updateNode, workspace, project } = props;
  console.log("GridCellView props", props);

  const [cells, setCells] = useState<CellsByAddressType>({});
  // FIXME: fix path
  const path = `/workspace/${workspace}/project/${project}/contents/${currentNode.data?.contentsId}`;
  const [serverContents, setServerContents, serverContentsLoading] =
    useDocOnce<CellsByAddressType>({
      path,
      initialData: undefined,
    });
  const [activeSaveButton, setActiveSaveButton] = useState<boolean>(false);

  const save = useCallback(
    (cells: CellsByAddressType) => {
      console.log("save", cells);
      Object.keys(cells).forEach((key) => {
        if (cells[key].value === undefined) {
          delete cells[key];
        }
        if (!cells[key]) {
          return;
        }
        Object.keys(cells[key]).forEach((key2) => {
          if ((cells[key] as { [key: string]: any })[key2] === undefined) {
            delete (cells[key] as { [key: string]: any })[key2];
          }
        });
      });
      setServerContents(cells);
      setActiveSaveButton(false);
    },
    [setServerContents]
  );

  if (serverContentsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Box sx={{ mb: 2 }}>
        <Button
          onClick={() => {
            save(cells);
          }}
          variant="contained"
          disabled={!activeSaveButton}
        >
          保存
        </Button>
      </Box>
      <GridSheet
        sheetName={"Sheet1"}
        initialCells={constructInitialCells({
          cells: serverContents ?? undefined,
          ensured: {
            numRows: 20,
            numCols: 20,
          },
        })}
        additionalFunctions={{}}
        options={{
          onChange: (cells) => {
            setCells(cells.getObject());
            setActiveSaveButton(true);
          },
          onSave: (cells) => {
            save(cells.getObject());
          },
        }}
      />
    </div>
  );
};
