import { createRotation } from "../rotation";

export const etoAdvance = {
  id: "etoAdvance",
  title: "干支なんでしょうゲーム",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「ねずみの1個後は？」→「うし」のように
      <br />
      十二支をランダムに前後にずらしたものを答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「ねずみの1個後は？」→「うし」のように
      <br />
      十二支をランダムに前後にずらしたものを答えます。[
      <a href="https://www.youtube.com/watch?v=YcD0g-qNXEY" target="_blank">
        元ネタ(ダンビラムーチョ)
      </a>
      ]
      <br />
      全部で10問出題されます。動物名を答えてください
      <br />
      <br />
      【十二支の順番】
      <br />
      「ねずみ」→「うし」→「とら」→「うさぎ」→
      <br />
      「たつ」→「へび」→「うま」→「ひつじ」→
      <br />
      「さる」→「とり」→「いぬ」→「いのしし」→「ねずみ」→...
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () =>
    createRotation(
      [
        "ねずみ",
        "うし",
        "とら",
        "うさぎ",
        "たつ",
        "へび",
        "うま",
        "ひつじ",
        "さる",
        "とり",
        "いぬ",
        "いのしし",
      ],
      10
    ),
  shareButton: true,
  hashTag: "干支なんでしょうゲーム",
};
