import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import { FC } from "react";

export const PanTokigamiTop: FC = () => {
  return (
    <Container>
      <Typography variant="h4">フライパン職人を解神にする会
      </Typography>
    </Container>
  );
};
