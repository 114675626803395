import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet";
import twttr from "twitter-text";
import { PlainText } from "../../common/PlainText";
import { Title } from "../../common/Title";

export const TweetLinkCreator = () => {
  const [text, setText] = useState("");
  const parseTweet = twttr.parseTweet(text);
  const [openExternalBrowser, setOpenExternalBrowser] = useState(false);
  const [openCopySnackbar, setOpenCopySnackbar] = useState(false);

  const linkMessage = parseTweet.valid
    ? `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}${
        openExternalBrowser ? "&openExternalBrowser=1" : ""
      }`
    : "";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>ツイートリンク作成 - EnigmaStudio</title>
        <meta
          name="description"
          content="Twitterの文字数制限を考慮したツイートリンクを作成します。"
        />
      </Helmet>
      <Title>ツイートリンク作成</Title>
      <PlainText>
        <span style={{ fontSize: "1rem" }}>
          入力した文言を𝕏にPostするためのリンクを作成します。
          <br />
          文字数もTwitter準拠でカウントします。
        </span>
      </PlainText>
      <Box>
        <Box maxWidth={"100%"} sx={{ mt: 2 }}>
          <Button
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            onClick={() => {
              setText("");
            }}
            disabled={text.trimEnd() === ""}
          >
            リセット
          </Button>
          <Button
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(text);
            }}
            startIcon={
              <ContentCopyIcon
                style={{ fontSize: "1rem", marginRight: "0.5rem" }}
              />
            }
          >
            コピー
          </Button>
          <Button
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            onClick={() => {
              navigator.clipboard.readText().then((clipText) => {
                setText(clipText);
              });
            }}
            startIcon={
              <ContentPasteIcon
                style={{ fontSize: "1rem", marginRight: "0.5rem" }}
              />
            }
          >
            ペースト
          </Button>
        </Box>

        <Box maxWidth={"100%"}>
          <TextField
            label="投稿させたい文言を入力"
            variant="outlined"
            fullWidth
            autoFocus
            sx={{
              mt: 2,
              cursor: "pointer",
            }}
            multiline
            rows={6}
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            error={text !== "" && !parseTweet.valid}
            inputProps={{}}
          />
        </Box>
        <Stack>
          <Typography
            variant="body2"
            sx={{
              color: text === "" || parseTweet.valid ? "#ab47bc" : "#f44336",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            {parseTweet.weightedLength}文字 / 280文字
          </Typography>
        </Stack>
        <Box maxWidth={"100%"} sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={openExternalBrowser}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setOpenExternalBrowser(event.target.checked);
                }}
              />
            }
            label="LINEで開いた際に外部ブラウザで開く (&openExternalBrowser=1)"
            labelPlacement="end"
          />
        </Box>
        <Box maxWidth={"100%"} sx={{ mt: 2 }}>
          <TextField
            label="ツイートリンク"
            variant="outlined"
            sx={{
              width: "100%",
              mt: 2,
            }}
            multiline
            rows={4}
            value={linkMessage}
            inputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box maxWidth={"100%"} sx={{ mt: 2 }}>
          <Button
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(linkMessage);
              setOpenCopySnackbar(true);
            }}
            disabled={!parseTweet.valid}
            startIcon={
              <IosShareIcon
                style={{ fontSize: "1rem", marginRight: "0.5rem" }}
              />
            }
          >
            リンクをコピー
          </Button>
          <Button
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            onClick={() => {
              window.open(linkMessage, "_blank");
            }}
            disabled={!parseTweet.valid}
            startIcon={
              <ContentCopyIcon
                style={{ fontSize: "1rem", marginRight: "0.5rem" }}
              />
            }
          >
            リンクを開く
          </Button>
        </Box>

        <Box maxWidth={"100%"} sx={{ mt: 2 }}>
          <Typography
            variant="body2"
            sx={{
              color: "gray",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            ※ ペーストは、ブラウザの設定によっては動作しない場合があります。
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "gray",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            ※ x.com/intent/post
            はスマホからアプリが立ち上がらないためtwitter.com/intent/tweetを使用しています。
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={openCopySnackbar}
        onClose={() => setOpenCopySnackbar(false)}
        autoHideDuration={2000}
        message="コピーしました"
      />
    </Container>
  );
};
