import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useMemo } from "react";
import { useStatePersist } from "use-state-persist";
import { DictionarySelector } from "../../../../commonStudio/DictionarySelector";
import { RegexInputWithCompletion } from "../../../../commonStudio/RegexInputWithCompletion";
import { TraceFormProps } from "../FormProps";
import { RegexMatchCompareFilter } from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  symbolRegexReplacementReplaceTable,
  splitGrapheme,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

export const RegexMatchCompareForm: FC<
  TraceFormProps<RegexMatchCompareFilter>
> = ({ index, query, updateQuery, displayTraceNumber, queries, isDemo }) => {
  const nextTraceCounter = useMemo(() => {
    const counter: number[] = [];
    let count = 1;
    let plus = 0;
    queries.forEach((query, i) => {
      counter.push(count + plus);
      if (query.enabled ?? true) {
        if (
          query.type === "regexReplace" ||
          query.type === "parallelTransform" ||
          (query.type === "dictionaryMatch" && query.anagramMatch) ||
          query.type === "keyValueMatch"
        ) {
          count += 1 + plus;
          plus = 0;
        }
        if (query.type === "regexMatchCompare") {
          plus += (
            (query.trace ?? []).filter(
              (_: any, i: number) => i < query.replacements.length
            ) ?? []
          ).reduce((sum: number, trace: boolean) => sum + (trace ? 1 : 0), 0);
        }
      }
    });
    counter.push(count + plus);
    return counter;
  }, [queries]);

  const [replaceFullsizeSymbolToHalfsize] = useStatePersist(
    "general-search-replace-fullsize-symbol-to-halfsize",
    false
  );

  return (
    <>
      <RegexInputWithCompletion
        pattern={query.pattern}
        onPatternChange={(newPattern) =>
          updateQuery(index, "pattern", newPattern)
        }
        disabled={isDemo}
      />
      <FormControl variant="outlined" sx={{ ml: 1, width: "200px", mb: 2 }}>
        <InputLabel>比較方法</InputLabel>
        <Select
          value={query.compare || ""}
          onChange={(e) => updateQuery(index, "compare", e.target.value)}
          disabled={isDemo}
        >
          <MenuItem value="equal">すべて等しい</MenuItem>
          <MenuItem value="notEqual">等しくないものがある</MenuItem>
          <MenuItem value="allDifferent">すべて異なる</MenuItem>
          <MenuItem value="anagramEqual">アナグラム一致</MenuItem>
          <MenuItem value="dictionaryMatch">すべて辞書に含まれる</MenuItem>
          <MenuItem value="dictionaryAnagramMatch">
            すべてアナグラムで辞書に含まれる
          </MenuItem>
          <MenuItem value="include">上の文字列が下の文字列に含まれる</MenuItem>
          <MenuItem value="exclude">
            上の文字列が下の文字列に含まれない
          </MenuItem>
          <MenuItem value="less-equal">上の文字列＜＝下の文字列</MenuItem>
          <MenuItem value="less">上の文字列＜下の文字列</MenuItem>
        </Select>
      </FormControl>
      {(query.compare === "dictionaryMatch" ||
        query.compare === "dictionaryAnagramMatch") && (
        <DictionarySelector
          labelName="辞書"
          dictionary={query.dictionary}
          setDictionary={(value) => updateQuery(index, "dictionary", value)}
          isDemo={isDemo}
        />
      )}
      <Paper
        variant="outlined"
        sx={{
          ml: 1,
          p: 2,
          backgroundColor: "palette.background.paper",
          backendOpacity: query.enabled === false ? 0.4 : 1,
        }}
      >
        {(query.replacements ?? []).map((match: any, mIndex: number) => (
          <Box key={mIndex} mb={3}>
            <Button
              variant="text"
              color="secondary"
              disabled={isDemo}
              onClick={() => {
                const updatedMatches = [...(query.replacements || [])];
                updatedMatches.splice(mIndex, 1);
                updateQuery(index, "replacements", updatedMatches);
              }}
            >
              削除
            </Button>
            <TextField
              label="比較文字列"
              variant="outlined"
              value={match || ""}
              disabled={isDemo}
              onChange={(e) => {
                const updatedMatches = [...(query.replacements || [])];
                updatedMatches[mIndex] = e.target.value;
                updateQuery(index, "replacements", updatedMatches);
              }}
              onCompositionEnd={(e) => {
                if (replaceFullsizeSymbolToHalfsize) {
                  const replacement = splitGrapheme(match as string)
                    .map((c: string) =>
                      symbolRegexReplacementReplaceTable[c]
                        ? symbolRegexReplacementReplaceTable[c]
                        : c
                    )
                    .join("");
                  const updatedMatches = [...(query.replacements || [])];
                  updatedMatches[mIndex] = replacement;
                  updateQuery(index, "replacements", updatedMatches);
                }
              }}
              sx={{ ml: 1, width: "300px" }}
            />
            <FormControlLabel
              disabled={isDemo}
              control={
                <Checkbox
                  checked={(query.trace ?? [])[mIndex] || false}
                  onChange={(e) => {
                    const updatedMatches = [...(query.trace || [])];
                    updatedMatches[mIndex] = e.target.checked;
                    updateQuery(index, "trace", updatedMatches);
                  }}
                />
              }
              sx={{ ml: 1 }}
              label={`トレース${
                displayTraceNumber && (query.trace ?? [])[mIndex]
                  ? ": [" +
                    (nextTraceCounter[index] +
                      ((query.trace ?? []) as boolean[]).filter(
                        (t, i) => i <= mIndex && t
                      ).length) +
                    "]"
                  : ""
              }`}
            />
          </Box>
        ))}
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            const updatedMatches = [...(query.replacements || []), ""];
            updateQuery(index, "replacements", updatedMatches);
          }}
          disabled={isDemo}
        >
          比較文字列を追加
        </Button>
      </Paper>
    </>
  );
};
