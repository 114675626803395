export const pokemon6 = `ハリマロン
ハリボーグ
ブリガロン
フォッコ
テールナー
マフォクシー
ケロマツ
ゲコガシラ
ゲッコウガ
ホルビー
ホルード
ヤヤコマ
ヒノヤコマ
ファイアロー
コフキムシ
コフーライ
ビビヨン
シシコ
カエンジシ
フラベベ
フラエッテ
フラージェス
メェークル
ゴーゴート
ヤンチャム
ゴロンダ
トリミアン
ニャスパー
ニャオニクス
ヒトツキ
ニダンギル
ギルガルド
シュシュプ
フレフワン
ペロッパフ
ペロリーム
マーイーカ
カラマネロ
カメテテ
ガメノデス
クズモー
ドラミドロ
ウデッポウ
ブロスター
エリキテル
エレザード
チゴラス
ガチゴラス
アマルス
アマルルガ
ニンフィア
ルチャブル
デデンネ
メレシー
ヌメラ
ヌメイル
ヌメルゴン
クレッフィ
ボクレー
オーロット
バケッチャ
パンプジン
カチコール
クレベース
オンバット
オンバーン
ゼルネアス
イベルタル
ジガルデ
ディアンシー
フーパ
ボルケニオン`;
