export const answerJa = `あっぱれ
ちぇっくめいと
くりあ
これくと
こんぷりーと
こねくと
どりーむ
えんど
えんでぃんぐ
えすけーぷ
えくせれんと
えくすとら
えくすとらくりあ
ふぁんたすてぃっく
ふぁいなる
ふぃなーれ
ふぃにっしゅ
げーむくりあ
げーむせっと
ごーる
ぐれーと
らすと
まじっく
まじかる
みらくる
ぱーふぇくと
せいこう
さくせす
うぃん
あんさー
あんろっく
ぐっどえんど
はっぴーえんど
とぅるーえんど
こたえ
あたり
えいこう
おおあたり
おつかれ
おつかれさま
おまけ
おまけせいかい
おみごと
おめでとう
おわり
かいけつ
かんぜんむけつ
かんぺき
さいしゅう
さいしゅうかい
さいてきかい
しょうり
せいかい
だいしょうり
だいせいかい
だいせいこう
てんさい
てんさいてき
ゆうしょう`

export const answerEn = `checkmate
clear
collect
complete
connect
dream
end
ending
escape
excellent
extra
extraclear
fantastic
final
finale
finish
gameclear
gameset
goal
great
last
magic
magical
miracle
perfect
success
win
answer
unlock
good end
happy end
true end`;