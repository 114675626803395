export const romajiToHiraTable = {
    " ": "　",
    "!": "！",
    '"': "”",
    "#": "＃",
    $: "＄",
    "%": "％",
    "&": "＆",
    "'": "’",
    "(": "（",
    ")": "）",
    "*": "＊",
    "+": "＋",
    ",": "、",
    "-": "ー",
    ".": "。",
    "/": "・",
    ":": "：",
    ";": "；",
    "<": "＜",
    "=": "＝",
    ">": "＞",
    "?": "？",
    "@": "＠",
    "[": "「",
    "\\": "￥",
    "]": "」",
    "^": "＾",
    _: "＿",
    "`": "‘",
    "{": "｛",
    "|": "｜",
    "}": "｝",
    "~": "～",
    0: "０",
    1: "１",
    2: "２",
    3: "３",
    4: "４",
    5: "５",
    6: "６",
    7: "７",
    8: "８",
    9: "９",
    a: "あ",
    b: {
        a: "ば",
        e: "べ",
        i: "び",
        o: "ぼ",
        u: "ぶ",
        y: {
            a: "びゃ",
            e: "びぇ",
            i: "びぃ",
            o: "びょ",
            u: "びゅ",
        },
    },
    c: {
        a: "か",
        e: "せ",
        h: {
            a: "ちゃ",
            e: "ちぇ",
            i: "ち",
            o: "ちょ",
            u: "ちゅ",
        },
        i: "し",
        o: "こ",
        u: "く",
        y: {
            a: "ちゃ",
            e: "ちぇ",
            i: "ちぃ",
            o: "ちょ",
            u: "ちゅ",
        },
    },
    d: {
        a: "だ",
        e: "で",
        h: {
            a: "でゃ",
            e: "でぇ",
            i: "でぃ",
            o: "でょ",
            u: "でゅ",
        },
        i: "ぢ",
        o: "ど",
        u: "づ",
        w: {
            a: "どぁ",
            e: "どぇ",
            i: "どぃ",
            o: "どぉ",
            u: "どぅ",
        },
        y: {
            a: "ぢゃ",
            e: "ぢぇ",
            i: "ぢぃ",
            o: "ぢょ",
            u: "ぢゅ",
        },
    },
    e: "え",
    f: {
        a: "ふぁ",
        e: "ふぇ",
        i: "ふぃ",
        o: "ふぉ",
        u: "ふ",
        w: {
            a: "ふぁ",
            e: "ふぇ",
            i: "ふぃ",
            o: "ふぉ",
            u: "ふぅ",
        },
        y: {
            a: "ふゃ",
            e: "ふぇ",
            i: "ふぃ",
            o: "ふょ",
            u: "ふゅ",
        },
    },
    g: {
        a: "が",
        e: "げ",
        i: "ぎ",
        o: "ご",
        u: "ぐ",
        w: {
            a: "ぐぁ",
            e: "ぐぇ",
            i: "ぐぃ",
            o: "ぐぉ",
            u: "ぐぅ",
        },
        y: {
            a: "ぎゃ",
            e: "ぎぇ",
            i: "ぎぃ",
            o: "ぎょ",
            u: "ぎゅ",
        },
    },
    h: {
        a: "は",
        e: "へ",
        i: "ひ",
        o: "ほ",
        u: "ふ",
        y: {
            a: "ひゃ",
            e: "ひぇ",
            i: "ひぃ",
            o: "ひょ",
            u: "ひゅ",
        },
    },
    i: "い",
    j: {
        a: "じゃ",
        e: "じぇ",
        i: "じ",
        o: "じょ",
        u: "じゅ",
        y: {
            a: "じゃ",
            e: "じぇ",
            i: "じぃ",
            o: "じょ",
            u: "じゅ",
        },
    },
    k: {
        a: "か",
        e: "け",
        i: "き",
        o: "こ",
        u: "く",
        w: {
            a: "くぁ",
        },
        y: {
            a: "きゃ",
            e: "きぇ",
            i: "きぃ",
            o: "きょ",
            u: "きゅ",
        },
    },
    l: {
        a: "ぁ",
        e: "ぇ",
        i: "ぃ",
        k: {
            a: "ヵ",
            e: "ヶ",
        },
        o: "ぉ",
        t: {
            s: {
                u: "っ",
            },
            u: "っ",
        },
        u: "ぅ",
        w: {
            a: "ゎ",
        },
        y: {
            a: "ゃ",
            e: "ぇ",
            i: "ぃ",
            o: "ょ",
            u: "ゅ",
        },
    },
    m: {
        a: "ま",
        e: "め",
        i: "み",
        o: "も",
        u: "む",
        y: {
            a: "みゃ",
            e: "みぇ",
            i: "みぃ",
            o: "みょ",
            u: "みゅ",
        },
    },
    n: {
        a: "な",
        e: "ね",
        i: "に",
        n: "ん",
        o: "の",
        u: "ぬ",
        y: {
            a: "にゃ",
            e: "にぇ",
            i: "にぃ",
            o: "にょ",
            u: "にゅ",
        },
    },
    o: "お",
    p: {
        a: "ぱ",
        e: "ぺ",
        i: "ぴ",
        o: "ぽ",
        u: "ぷ",
        y: {
            a: "ぴゃ",
            e: "ぴぇ",
            i: "ぴぃ",
            o: "ぴょ",
            u: "ぴゅ",
        },
    },
    q: {
        a: "くぁ",
        e: "くぇ",
        i: "くぃ",
        o: "くぉ",
        u: "く",
        w: {
            a: "くぁ",
            e: "くぇ",
            i: "くぃ",
            o: "くぉ",
            u: "くぅ",
        },
        y: {
            a: "くゃ",
            e: "くぇ",
            i: "くぃ",
            o: "くょ",
            u: "くゅ",
        },
    },
    r: {
        a: "ら",
        e: "れ",
        i: "り",
        o: "ろ",
        u: "る",
        y: {
            a: "りゃ",
            e: "りぇ",
            i: "りぃ",
            o: "りょ",
            u: "りゅ",
        },
    },
    s: {
        a: "さ",
        e: "せ",
        h: {
            a: "しゃ",
            e: "しぇ",
            i: "し",
            o: "しょ",
            u: "しゅ",
        },
        i: "し",
        o: "そ",
        u: "す",
        w: {
            a: "すぁ",
            e: "すぇ",
            i: "すぃ",
            o: "すぉ",
            u: "すぅ",
        },
        y: {
            a: "しゃ",
            e: "しぇ",
            i: "しぃ",
            o: "しょ",
            u: "しゅ",
        },
    },
    t: {
        a: "た",
        e: "て",
        h: {
            a: "てゃ",
            e: "てぇ",
            i: "てぃ",
            o: "てょ",
            u: "てゅ",
        },
        i: "ち",
        o: "と",
        s: {
            a: "つぁ",
            e: "つぇ",
            i: "つぃ",
            o: "つぉ",
            u: "つ",
        },
        u: "つ",
        w: {
            a: "とぁ",
            e: "とぇ",
            i: "とぃ",
            o: "とぉ",
            u: "とぅ",
        },
        y: {
            a: "ちゃ",
            e: "ちぇ",
            i: "ちぃ",
            o: "ちょ",
            u: "ちゅ",
        },
    },
    u: "う",
    v: {
        a: "ゔぁ",
        e: "ゔぇ",
        i: "ゔぃ",
        o: "ゔぉ",
        u: "ゔ",
        y: {
            a: "ゔゃ",
            e: "ゔぇ",
            i: "ゔぃ",
            o: "ゔょ",
            u: "ゔゅ",
        },
    },
    w: {
        a: "わ",
        e: "うぇ",
        h: {
            a: "うぁ",
            e: "うぇ",
            i: "うぃ",
            o: "うぉ",
            u: "う",
        },
        i: "うぃ",
        o: "を",
        u: "う",
        y: {
            e: "ゑ",
            i: "ゐ",
        },
    },
    x: {
        a: "ぁ",
        e: "ぇ",
        i: "ぃ",
        k: {
            a: "ヵ",
            e: "ヶ",
        },
        n: "ん",
        o: "ぉ",
        t: {
            s: {
                u: "っ",
            },
            u: "っ",
        },
        u: "ぅ",
        w: {
            a: "ゎ",
        },
        y: {
            a: "ゃ",
            e: "ぇ",
            i: "ぃ",
            o: "ょ",
            u: "ゅ",
        },
    },
    y: {
        a: "や",
        e: "いぇ",
        i: "い",
        o: "よ",
        u: "ゆ",
    },
    z: {
        a: "ざ",
        e: "ぜ",
        i: "じ",
        o: "ぞ",
        u: "ず",
        y: {
            a: "じゃ",
            e: "じぇ",
            i: "じぃ",
            o: "じょ",
            u: "じゅ",
        },
    },
};
