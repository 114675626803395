import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { compress } from "../../../../../../lib/compress";
import { sequenceTypes } from "../recipe/data/generalSearchRecipe";
import { GeneralSearchQuery } from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  generalSearchObjFromJson,
  jsonFromGeneralSearchObj,
} from "./generalSearchObject";

type Props = {
  json: string;
  setJson: (query: string) => void;
  queries: GeneralSearchQuery[];
  setQueries: (queries: GeneralSearchQuery[]) => void;
  jsonError: boolean;
  setJsonError: (jsonError: boolean) => void;
  isDemo: boolean;
  setQueryFromJson: (json: string) => boolean;
};

export const JsonView: FC<Props> = ({
  json,
  setJson,
  queries,
  setQueries,
  jsonError,
  isDemo,
  setQueryFromJson,
}) => {
  const [outputUrl, setOutputUrl] = useState("");
  useEffect(() => {
    setOutputUrl("");
  }, [queries]);
  return (
    <>
      <Box maxWidth={"100%"}>
        <TextField
          label="JSON表示"
          variant="outlined"
          sx={{ width: "100%", mt: 2 }}
          multiline
          rows={10}
          value={json}
          error={jsonError}
          onChange={(e) => {
            const ret = setQueryFromJson(e.target.value);
            if (ret) {
              setOutputUrl("");
            }
          }}
          disabled={isDemo}
        />
        <TextField
          label="出力URL"
          variant="outlined"
          sx={{ width: "100%", mt: 2 }}
          value={outputUrl}
          error={jsonError}
          aria-readonly
          onFocus={async (e) => {
            const compressed = await compress(json);
            setOutputUrl(
              `${window.location.origin}/general-search?q=${compressed}`
            );
          }}
          disabled={isDemo}
        />
      </Box>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        disabled={jsonError}
        onClick={() => {
          setJson(jsonFromGeneralSearchObj(generalSearchObjFromJson(json)));
        }}
      >
        JSONを整形
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        disabled={jsonError}
        onClick={() => {
          const obj = generalSearchObjFromJson(json);
          const shortQuery = obj.queries
            .map((query) => {
              const newQuery = { ...query };
              if (newQuery.enabled === true) {
                delete newQuery.enabled;
              }
              return newQuery;
            })
            .map((query) => {
              if (
                query.type === "sequence" &&
                sequenceTypes[query.sequenceType].singleDirection
              ) {
                query.processes =
                  sequenceTypes[query.sequenceType].defaultQuery.processes;
              }
              return query;
            })
            .filter((query) => query.enabled !== false);
          setJson(
            jsonFromGeneralSearchObj({ ...obj, queries: shortQuery }, true)
          );
          setQueries(shortQuery);
        }}
      >
        JSONを短縮
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          navigator.clipboard.writeText(json);
        }}
      >
        クリップボードにコピー
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        onClick={async () => {
          navigator.clipboard.writeText(outputUrl);
        }}
        disabled={outputUrl === ""}
      >
        出力URLをクリップボードにコピー
      </Button>
    </>
  );
};
