export type Direction =
  | "left"
  | "down"
  | "up"
  | "right"
  | "left-up"
  | "left-down"
  | "right-up"
  | "right-down"
  | "horizontal"
  | "vertical";

export const directionToPos = (direction: Direction) => {
  if (direction === "right") {
    return { x: 1, y: 0 };
  }
  if (direction === "right-down") {
    return { x: 1, y: 1 };
  }
  if (direction === "down") {
    return { x: 0, y: 1 };
  }
  if (direction === "left-down") {
    return { x: -1, y: 1 };
  }
  if (direction === "left") {
    return { x: -1, y: 0 };
  }
  if (direction === "left-up") {
    return { x: -1, y: -1 };
  }
  if (direction === "up") {
    return { x: 0, y: -1 };
  }
  if (direction === "right-up") {
    return { x: 1, y: -1 };
  }
  return { x: 1, y: 0 };
};