import { Button } from "@mui/material";
import { FC } from "react";
import { Pos, RectPos, WordObj } from "./gridToolType";
import { ModeType } from "./Menu/ModeSelector";

type SaveMenuProps = {
  mode: ModeType | null;
  wordList: WordObj[];
  blackList: Pos[];
  whiteList: Pos[];
  artboard: RectPos;
  cursorText: {
    [key: string]: string;
  };
  cursorTextKana: {
    [key: string]: string;
  };
  setWordList: React.Dispatch<React.SetStateAction<WordObj[]>>;
  setBlackList: React.Dispatch<React.SetStateAction<Pos[]>>;
  setWhiteList: React.Dispatch<React.SetStateAction<Pos[]>>;
  setArtboard: React.Dispatch<React.SetStateAction<RectPos>>;
  setCursorText: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  setCursorTextKana: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  setRawWordList: React.Dispatch<React.SetStateAction<string>>;
  wordListToGrid: (wordList: WordObj[]) => string[][];
  col: number;
  row: number;
};

export const SaveMenu: FC<SaveMenuProps> = ({
  mode,
  wordList,
  blackList,
  whiteList,
  artboard,
  cursorText,
  cursorTextKana,
  setWordList,
  setBlackList,
  setWhiteList,
  setArtboard,
  setCursorText,
  setCursorTextKana,
  setRawWordList,
  wordListToGrid,
  col,
  row,
}) => {
  if (mode !== "save") {
    return <></>;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          const blob = new Blob(
            [
              JSON.stringify({
                wordList,
                blackList,
                whiteList,
                artboard,
                cursorText,
                cursorTextKana,
              }),
            ],
            {
              type: "application/json",
            }
          );
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "wordsearch.json";
          a.click();
        }}
        sx={{ mr: 2 }}
      >
        json保存
      </Button>
      <Button
        variant="contained"
        sx={{ mr: 2 }}
        onClick={() => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "application/json";
          input.onchange = (e: any) => {
            if (!e.target.files) {
              return;
            }
            const file = e.target?.files?.[0];
            if (!file) {
              return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              const result = e.target?.result;
              if (!result) {
                return;
              }
              const json = JSON.parse(result as string);
              setWordList(json.wordList ?? []);
              setBlackList(json.blackList ?? []);
              setWhiteList(json.whiteList ?? []);
              setArtboard(
                json.artboard ?? {
                  x: 0,
                  y: 0,
                  width: col,
                  height: row,
                }
              );
              setCursorText(json.cursorText ?? {});
              setCursorTextKana(json.cursorTextKana ?? {});
              setRawWordList(json.wordList.map((w: any) => w.word).join("\n"));
            };
            reader.readAsText(file);
          };
          input.click();
        }}
      >
        json読み込み
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          const grid = wordListToGrid(wordList);
          const csv = grid.map((row) => row.join(",")).join("\n");
          const blob = new Blob([csv], {
            type: "text/csv",
          });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "wordsearch.csv";
          a.click();
        }}
        sx={{ mr: 2 }}
      >
        CSV出力
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          // to clipboard
          const grid = wordListToGrid(wordList);
          const tsv = grid.map((row) => row.join("\t")).join("\n");
          navigator.clipboard.writeText(tsv);
        }}
        sx={{ mr: 2 }}
      >
        タブ区切りでクリップボード出力
      </Button>
    </>
  );
};
