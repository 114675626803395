import { FC, useCallback } from "react";
import { ConvertProps } from "./ConvertTemplate";
import { Button } from "@mui/material";

const deployUrl =
  "https://script.google.com/macros/s/AKfycbw-OVf6RvXwKsKY4lSuUP7DOHJwoIVmQxyF4EJDmIxmMQCoigLBi10s3_6H5w_QalW6/exec";

export const TranslateConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  const convert = useCallback(
    (language: string) => {
      fetch(deployUrl + `?text=${pattern}&target=${language}`)
        .then(async (res) => {
          if (!res.ok) {
            return;
          }
          const resJson = await res.json();
          if (resJson.text) {
            setConverted(resJson.text);
          }
        })
        .catch((e) => {
          console.log(e);
        });

      setConverted(pattern);
    },
    [pattern, setConverted]
  );
  return (
    <>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          convert("ja");
        }}
      >
        日本語に翻訳
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          convert("en");
        }}
      >
        英語に翻訳
      </Button>
    </>
  );
};
