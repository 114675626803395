import { kanaToRomanTable } from "./romanTable";
const getHead = (str) => {
    return str.slice(0, 1);
};
/**
 * 小文字か判定
 * @return {boolean} 小文字の場合はtrue、そうでない場合はfalseを返す
 */
const isSmallChar = (str) => {
    return /^[ぁぃぅぇぉゃゅょゎァィゥェォャュョヮ]$/.test(str);
};
/**
 * カタカナからひらがなへ変換
 * @param {string} kana 元となるカタカナ
 * @return {string} ひらがなへ変換された文字列
 */
const toHiragana = (kana) => {
    return kana.replace(/[\u30a1-\u30f6]/g, function (match) {
        return String.fromCharCode(match.charCodeAt(0) - 0x60);
    });
};
/**
 * ひらがなから対応するローマ字を取得
 * @param {string} kana 元となるひらがな
 * @return {string} 見つかった場合は対応するローマ字、見つからなかったら元のひらがなを返す
 */
const getRoman = (type, kana, ignoreMark) => {
    const roman = kanaToRomanTable[toHiragana(kana)]; // ひらがなをキーとしたローマ字変換テーブルから対応するローマ字を取得
    if (ignoreMark) {
        return roman?.[type ?? "hepburn"] ?? kana;
    }
    else {
        return roman?.[type ?? "hepburn"];
    }
};
export const kanaToRoman = function (targetStr, type, options) {
    // 入力された文字列の型チェック。文字列または数字でなければ例外を投げる
    if (typeof targetStr !== "string" && typeof targetStr !== "number") {
        throw "変換する対象が文字列ではありません。";
    }
    type = type ?? "hepburn";
    // オプションのデフォルト値を設定
    if (!options)
        options = {};
    if (typeof options.kana !== "string")
        options.kana = "all";
    if (!options.kana.match(/^(all|hiragana|katakana)$/))
        options.kana = "all";
    if (typeof options.bmp !== "boolean")
        options.bmp = true;
    options.longSound = options.longSound ?? false;
    let remStr = toHiragana(targetStr); // ひらがなに変換
    let result = ""; // 最終的なローマ字の結果
    let slStr = ""; // 現在の処理対象の文字列
    let roman = ""; // ローマ字に変換された文字列
    /**
     * 残りの文字列から1文字を切り抜く
     * @return {string} 切り抜いた1つの文字列を返す
     */
    const splice = () => {
        const oneChar = remStr.slice(0, 1);
        remStr = remStr.slice(1);
        return oneChar;
    };
    // targetStrの各文字に対してローマ字変換を実施
    while (remStr) {
        slStr = splice(); // 一文字取り出す
        let xtu = false; // 「っ」の処理を行ったかどうか
        if (slStr.match(/^(っ|ッ)$/)) {
            const nextHead = getHead(remStr);
            if (nextHead === "" ||
                nextHead.match(/^[あいうえおぁぃぅぇぉゃゅょゎっ]$/)) {
                roman = getRoman(type, slStr, options.ignoreMark);
                result += roman;
                continue;
            }
            else {
                xtu = true;
                slStr = splice();
            }
        }
        if (isSmallChar(getHead(remStr))) {
            slStr += splice();
            const r = getRoman(type, slStr, options.ignoreMark);
            if ((options.ignoreMark && r !== slStr) || (!options.ignoreMark && r)) {
                roman = r;
            }
            else {
                if (options.ignoreMark) {
                    roman =
                        (getRoman(type, slStr.slice(0, 1), options.ignoreMark) ??
                            slStr.slice(0, 1)) +
                            (getRoman(type, slStr.slice(1), options.ignoreMark) ??
                                slStr.slice(1));
                }
                else {
                    roman =
                        (getRoman(type, slStr.slice(0, 1), options.ignoreMark) ?? "") +
                            (getRoman(type, slStr.slice(1), options.ignoreMark) ?? "");
                }
            }
        }
        else {
            roman = getRoman(type, slStr, options.ignoreMark); // ローマ字に変換
        }
        if (xtu) {
            if (roman?.match(/^ch/) && type === "hepburn") {
                roman = "t" + roman;
            }
            else if (roman?.match(/^ch/) && type === "hepburnTyping") {
                roman = "c" + roman;
            }
            else {
                roman = (roman ?? "").slice(0, 1) + roman;
            }
        }
        // 残りの文字列の最初の文字をローマ字に変換
        var nextRoman = kanaToRoman(remStr.slice(0, 1));
        // 'n'の後に母音が来る場合の処理
        if (roman === "n") {
            if (options.bmp && nextRoman.match(/^[bmp]/) && type === "hepburn") {
                // Hepburn式で次の文字がb, m, pのいずれかでbmpオプションがtrueの場合、'n'を'm'に変更
                roman = "m";
            }
        }
        else if (roman === "-") {
            // 長音符の処理
            const lastStr = !!result.match(/[aiueo]$/);
            if (lastStr) {
                const longStr = result.slice(-1);
                if (options.longSound) {
                    result += longStr;
                }
            }
            roman = "";
        }
        else if (type === "hepburn" && roman === "u") {
            if (!!result.match(/[uo]$/)) {
                roman = "*";
            }
        }
        else if (type === "hepburn" && roman === "o") {
            if (!!result.match(/[o]$/) && nextRoman != "") {
                roman = "*";
            }
        }
        if (result.slice(-1) === "*") {
            result = result.slice(0, -1);
        }
        result += roman; // 結果の文字列にローマ字を追加
    }
    if (result.slice(-1) === "*") {
        result = result.slice(0, -1);
    }
    return result;
};
