import DeleteIcon from "@mui/icons-material/Delete";
import { Menu, MenuProps } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { FC } from "react";

type SidebarMenuProps = {
  menuId?: number | string | null;
  handleClose: () => void;
  handleDelete: (menuId: number | string) => void;
} & MenuProps;
export const SidebarMenu: FC<SidebarMenuProps> = (props) => {
  const { menuId, handleClose, handleDelete, ...rest } = props;
  return (
    <Menu {...rest} onClose={handleClose}>
      <MenuList dense>
        <MenuItem
          onClick={() => {
            if (menuId !== null && menuId !== undefined) {
              handleDelete(menuId);
            }
            handleClose();
          }}
          disabled={(menuId ?? null) === null}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          削除
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
