import { Text } from "@react-pdf/renderer";
import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { StackData, StackSubTitleData } from "../stackData";

export const SubTitle: FC<{ contents: StackData }> = ({ contents }) => {
  return (
    <If condition={contents.type === "subtitle"}>
      <Text style={{ fontSize: 15 }}>
        {(contents as StackSubTitleData).text}
      </Text>
    </If>
  );
};
