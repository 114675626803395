import { FC, useState } from "react";
import { EditCommonForm } from "../StackSpace/editItems2/EditCommonForm";
import { Scene } from "./type/examType";
import { Stack, TextField } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  contents: any;
};

export const SceneInfo: FC<Props> = ({ children, contents }) => {
  const [scene, setScene] = useState<Scene>({
    timelimit: 0,
    timer: "countdown",
    continue: false,
    finishModal: false,
  });

  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "auto",
        padding: "20px",
        gap: "20px",
      }}
    >
      <p>設定できる内容</p>

      <TextField
        label="タイムリミット"
        value={scene.timelimit}
        onChange={(e) => {
          setScene({ ...scene, timelimit: Number(e.target.value) });
        }}
      />
      <TextField
        label="タイマーの種類"
        value={scene.timer}
        onChange={(e) => {
          setScene({
            ...scene,
            timer: e.target.value as "countdown" | "countup" | "none",
          });
        }}
      />
      <TextField
        label="タイムアップ後継続可能か"
        value={scene.continue}
        onChange={(e) => {
          setScene({ ...scene, continue: Boolean(e.target.value) });
        }}
      />
      <TextField
        label="終了時にモーダルを表示するか自動遷移か"
        value={scene.finishModal}
        onChange={(e) => {
          setScene({ ...scene, finishModal: Boolean(e.target.value) });
        }}
      />
      <TextField
        label="解答入力時のメッセージ"
        value={scene.answerInputMessage}
        onChange={(e) => {
          setScene({ ...scene, answerInputMessage: e.target.value });
        }}
      />
      <TextField
        label="解答入力時の短縮メッセージ"
        value={scene.answerInputMessageShort}
        onChange={(e) => {
          setScene({ ...scene, answerInputMessageShort: e.target.value });
        }}
      />
      <TextField
        label="ゲーム終了時のメッセージ"
        value={scene.gameFinishMessage}
        onChange={(e) => {
          setScene({ ...scene, gameFinishMessage: e.target.value });
        }}
      />
      <TextField
        label="ゲーム終了時のボタン表示フラグ"
        value={scene.gameFinishButton}
        onChange={(e) => {
          setScene({ ...scene, gameFinishButton: Boolean(e.target.value) });
        }}
      />

      <EditCommonForm
        content={scene}
        setContent={setScene}
        editorData={{}}
        selected={true}
        syncContentParent={() => {}}
      />
    </Stack>
  );
};
