export const calcEstimateValue = (currentEstimation, estimationFilter, match) => {
    if (match) {
        if (estimationFilter.matchEstimationOperator === "add") {
            return (currentEstimation +
                parseFloat(estimationFilter.matchEstimationValue ?? "0"));
        }
        return (currentEstimation *
            parseFloat(estimationFilter.matchEstimationValue ?? "1"));
    }
    if (estimationFilter.unmatchEstimationOperator === "add") {
        return (currentEstimation +
            parseFloat(estimationFilter.unmatchEstimationValue ?? "0"));
    }
    return (currentEstimation *
        parseFloat(estimationFilter.unmatchEstimationValue ?? "1"));
};
