import axios from "axios";

export const getImageUrl = async (url: string) => {
  console.log(url);

  if (url.startsWith("https://puzz.link/p?")) {
    const path = url.replace("https://puzz.link/p?", "");
    //TODO fix path
    const img = await axios.post(
      `https://us-central1-puzsq-studio.cloudfunctions.net/getPuzzlinkImage/${path}`
    );
    const newUrl = URL.createObjectURL(
      new Blob([new Uint8Array(img.data.image.data)], { type: "image/png" })
    );
    return newUrl;
  } else {
    return url;
  }
};
