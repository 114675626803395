import { httpsCallable } from "firebase/functions";
import { FC, useCallback, useState } from "react";
import { useFirebaseConfig } from "../../../_lib/eniverse/EniverseProvider";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { TextField, Button, Container } from "@mui/material";
import { Title } from "../../EnigmaHouse/common/Title";

type Props = {};
export const FunctionCaller: FC<Props> = () => {
  const [{ cloudFunctions }] = useFirebaseConfig();

  const { user } = useFirebaseLogin();
  const [name, setName] = useState("");
  const [data, setData] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [jsonError, setJsonError] = useState(false);

  const submit = useCallback(async () => {
    if (!user) {
      return;
    }
    if (!cloudFunctions) {
      console.log("cloudFunctions is not ready");
      return;
    }
    if (loading) {
      return;
    }
    if (jsonError) {
      return;
    }
    setLoading(true);

    const func = httpsCallable(cloudFunctions, name);
    const ret = await func(JSON.parse(data)).catch((err: any) => {
      console.log(err.message);
    });
    setLoading(false);
    if (!ret) {
      return;
    }
    setResponse(JSON.stringify(ret.data, null, 2));
  }, [cloudFunctions, data, jsonError, loading, name, user]);

  if (user?.email?.split("@")[1] !== "langue-de-chat.llc") {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Title>管理画面</Title>
        <p>この機能はLangue-De-Chat のメンバーのみが利用できます。</p>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>管理画面</Title>
      <TextField
        label="Function name"
        value={name}
        placeholder="admin-"
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />
      <TextField
        label="Data"
        value={data}
        onChange={(e) => {
          try {
            JSON.parse(e.target.value);
            setData(e.target.value);
            setJsonError(false);
          } catch (error) {
            setData(e.target.value);
            setJsonError(true);
          }
        }}
        error={jsonError}
        fullWidth
        multiline
        rows={10}
        sx={{
          mt: 2,
        }}
      />
      <Button
        onClick={submit}
        sx={{
          mt: 2,
        }}
        variant="contained"
        disabled={loading}
      >
        Submit
      </Button>
      <TextField
        label="Response"
        value={response}
        fullWidth
        multiline
        rows={10}
        aria-readonly
        sx={{
          mt: 2,
        }}
      />
    </Container>
  );
};
