import { MenuBook } from "@mui/icons-material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useContext } from "react";
import { useStatePersist } from "use-state-persist";
import { useFirebaseLogin } from "../../../../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { ConvertTimestampRecursively } from "../../../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { debug } from "../../../../../../debug";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { CloudItemLoadWrapper } from "../../../CloudItem/CloudItemLoadWrapper";
import { CloudItemNew } from "../../../CloudItem/CloudItemNew";
import { CloudItemSelectWrapper } from "../../../CloudItem/CloudItemSelectWrapper";
import { CloudDoc } from "../../../CloudItem/cloudType";
import { useCloud } from "../../../CloudItem/useCloud";
import { WordList } from "../../../Tools/WordList";
import { DictionaryLoader } from "./DictionaryLoader";
import { GeneralSearchInputDictionarySelector } from "./GeneralSearchInputDictionarySelector";

type GeneralSeachDictionarySelectorProps = {
  inputTabValue: string;
  setInputTabValue: (value: string) => void;
  dictionaries: string[];
  setDictionaries: (dictionaries: string[]) => void;
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
  fileListLoading: boolean;
};

export const GeneralSeachDictionarySelector: FC<
  GeneralSeachDictionarySelectorProps
> = ({
  inputTabValue,
  setInputTabValue,
  dictionaries,
  setDictionaries,
  fileListData,
  setFileListData,
  fileListLoading,
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const { user } = useFirebaseLogin();

  const handleInputTabChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string
  ) => {
    setInputTabValue(newValue);
  };

  const [mode, setMode] = useStatePersist<"select" | "load" | "upload">(
    "general-search-dictionary-selector-mode",
    "load");
  const cloud =
    (user && ["cloudplus", "biz", "biz-trial"].includes(ctx.plan)) ||
    debug.role === "cloudplus";

  const userId = user?.uid ?? "guest";
  const { storageTable } = useCloud({
    userId: cloud ? userId : null,
    fileListData,
  });

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <ToggleButtonGroup
          color="primary"
          value={inputTabValue}
          exclusive
          onChange={handleInputTabChange}
        >
          <ToggleButton value="dictionary">入力辞書選択</ToggleButton>
          <ToggleButton value="free">自由入力</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <If condition={inputTabValue === "dictionary"}>
        <Box sx={{ mt: 2 }}>
          <ToggleButton
            value="check"
            selected={mode === "load"}
            size="small"
            onChange={() => {
              if (mode === "load") {
                setMode("select");
              } else {
                setMode("load");
              }
            }}
          >
            <MenuBook />
            辞書読み込み
          </ToggleButton>
          <If condition={cloud}>
            <ToggleButton
              value="check"
              selected={mode === "upload"}
              size="small"
              onChange={() => {
                if (mode === "upload") {
                  setMode("select");
                } else {
                  setMode("upload");
                }
              }}
            >
              <FileUploadIcon />
              アップロード
            </ToggleButton>
          </If>
          <If condition={mode === "load"}>
            <Typography variant="caption" sx={{ ml: 1 }}>
              {"選択した辞書を読み込みます"}
            </Typography>
          </If>

          <If condition={mode === "upload"}>
            <Typography variant="caption" sx={{ ml: 1 }}>
              {"クラウドに辞書ファイルをアップロードします"}
            </Typography>
          </If>
        </Box>
        <If condition={mode === "load"}>
          <Box sx={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}>
            <DictionaryLoader
              selectedDictionaries={dictionaries}
              setSelectedDictionaries={setDictionaries}
              displayLabel={false}
            />
            <CloudItemLoadWrapper
              tabValue={inputTabValue}
              userId={user?.uid}
              selectedDictionaries={dictionaries}
              setSelectedDictionaries={setDictionaries}
              fileListData={fileListData}
              setFileListData={setFileListData}
              fileListLoading={fileListLoading}
              storageTable={storageTable}
            />
            <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }} />
          </Box>
        </If>
        <If condition={cloud && mode === "upload"}>
          <CloudItemNew
            userId={user?.uid || "debug"}
            fileListData={fileListData}
            setFileListData={setFileListData}
            onClose={() => {}}
          />
        </If>
        <GeneralSearchInputDictionarySelector
          selectedDictionaries={dictionaries}
          setSelectedDictionaries={setDictionaries}
          displayLabel={false}
        />
        <If condition={cloud}>
          <CloudItemSelectWrapper
            tabValue={inputTabValue}
            selectedDictionaries={dictionaries}
            setSelectedDictionaries={setDictionaries}
            fileListData={fileListData}
            setFileListData={setFileListData}
            fileListLoading={fileListLoading}
            storageTable={storageTable}
          />
        </If>
      </If>
      <If condition={inputTabValue === "free"}>
        <Box sx={{ pt: 1, pb: 2 }}>
          <WordList />
        </Box>
      </If>
      <Box sx={{ mb: 3, borderBottom: 1, borderColor: "divider" }}></Box>
    </>
  );
};
