import { useMemo } from "react";
import { Pos, RectPos } from "./gridToolType";

export const useCrosswordNumber = ({
  artboard,
  blackList,
  displayCrosswordNumberType,
}: {
  artboard: RectPos;
  blackList: Pos[];
  displayCrosswordNumberType: "none" | "horizontal" | "vertical";
}) => {
  const crosswordNumber = useMemo(() => {
    if (displayCrosswordNumberType === "none") {
      return {};
    }
    const ret: { [key: string]: number } = {};
    let number = 0;

    let x = 0;
    let y = 0;
    const increment = () => {
      if (displayCrosswordNumberType === "horizontal") {
        x++;
        if (x >= artboard.width) {
          x = 0;
          y++;
        }
      } else if (displayCrosswordNumberType === "vertical") {
        y++;
        if (y >= artboard.height) {
          x++;
          y = 0;
        }
      } else {
        return false;
      }
      if (x >= artboard.width || y >= artboard.height) {
        return false;
      }
      return true;
    };
    do {
      if (
        blackList.some((b) => b.x === x + artboard.x && b.y === y + artboard.y)
      ) {
        continue;
      }

      // vertical check
      const verticalCheck =
        (y === 0 ||
          blackList.some(
            (b) => b.x === x + artboard.x && b.y === y + artboard.y - 1
          )) &&
        y !== artboard.height - 1 &&
        !blackList.some(
          (b) => b.x === x + artboard.x && b.y === y + artboard.y + 1
        );
      // horizontal check
      const horizontalCheck =
        (x === 0 ||
          blackList.some(
            (b) => b.x === x - 1 + artboard.x && b.y === y + artboard.y
          )) &&
        x !== artboard.width - 1 &&
        !blackList.some(
          (b) => b.x === x + 1 + artboard.x && b.y === y + artboard.y
        );
      const aroundBlackCheck =
        (y === 0 ||
          blackList.some(
            (b) => b.x === x + artboard.x && b.y === y + artboard.y - 1
          )) &&
        (x === 0 ||
          blackList.some(
            (b) => b.x === x - 1 + artboard.x && b.y === y + artboard.y
          )) &&
        (x === artboard.width - 1 ||
          blackList.some(
            (b) => b.x === x + 1 + artboard.x && b.y === y + artboard.y
          )) &&
        (y === artboard.height - 1 ||
          blackList.some(
            (b) => b.x === x + artboard.x && b.y === y + artboard.y + 1
          ));

      if (verticalCheck || horizontalCheck || aroundBlackCheck) {
        ret[`${x}-${y}`] = ++number;
      }
    } while (increment());
    console.log(ret);
    return ret;
  }, [artboard, blackList, displayCrosswordNumberType]);

  return { crosswordNumber };
};
