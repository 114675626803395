import {
  EstimationInput,
  GeneralSearchQuery,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  Box,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import { FC } from "react";
import { queryTypeArray } from "../QueryTypeChip";
import { sequenceTypes } from "../recipe/data/generalSearchRecipe";
import { useStatePersist } from "use-state-persist";
import { jsonFromGeneralSearchObj } from "./generalSearchObject";

type Props = {
  queries: GeneralSearchQuery[];
  setQueries: (value: GeneralSearchQuery[]) => void;
  setQueryJson: (value: string) => void;
  setJsonError: (value: boolean) => void;
  isDemo: boolean;
  isEstimation: boolean;
  estimation: EstimationInput;
};

export const CommandSelector: FC<Props> = (props) => {
  const {
    queries,
    setQueries,
    setQueryJson,
    setJsonError,
    isDemo,
    isEstimation,
    estimation,
  } = props;

  const [displayAddQuery, setDisplayAddQuery] = useStatePersist<string[]>(
    "general-search-display-add-query",
    ["filter", "transform", "match", "tool"]
  );
  const [displayTooltip] = useStatePersist(
    "general-search-display-tooltip",
    true
  );

  const handleDisplayAddQueryChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string
  ) => {
    setDisplayAddQuery((prev) => {
      if (prev.includes(newValue)) {
        return prev.filter((v) => v !== newValue);
      } else {
        return [...prev, newValue];
      }
    });
  };
  return (
    <Box
      sx={{
        border: "1px dashed #ccc",
      }}
    >
      <Checkbox
        checked={displayAddQuery.length === 4}
        indeterminate={displayAddQuery.length > 0 && displayAddQuery.length < 4}
        onChange={() => {
          if (displayAddQuery.length === 4) {
            setDisplayAddQuery([]);
          } else {
            setDisplayAddQuery(["filter", "transform", "match", "tool"]);
          }
      }}
      />
      <ToggleButtonGroup
        color="primary"
        value={displayAddQuery}
        size="small"
        exclusive
        onChange={handleDisplayAddQueryChange}
        aria-label="Platform"
        sx={{
          mt: 2,
        }}
      >
        <ToggleButton value="filter">フィルタ</ToggleButton>
        <ToggleButton value="transform">変換</ToggleButton>
        <ToggleButton value="match">マッチ</ToggleButton>
        <ToggleButton value="tool">ツール</ToggleButton>
      </ToggleButtonGroup>
      {["filter", "transform", "match", "tool"]
        .filter((key) => displayAddQuery.includes(key))
        .map((key) => (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexWrap: "wrap",
            }}
            key={key}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
              }}
            >
              {
                {
                  filter: "フィルタ",
                  transform: "変換",
                  match: "マッチ",
                  tool: "ツール",
                }[key]
              }
            </Typography>
            {queryTypeArray
              .filter((query) => query.category === key)
              .map((query, index) => (
                <Tooltip
                  title={displayTooltip && query.description}
                  arrow
                  key={index}
                >
                  <Button
                    key={index}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      const updatedQueries = [
                        ...queries,
                        { type: query.key },
                      ] as GeneralSearchQuery[];
                      setQueries(updatedQueries as GeneralSearchQuery[]);
                      setQueryJson(
                        jsonFromGeneralSearchObj({
                          queries: updatedQueries,
                          estimation: isEstimation ? estimation : undefined,
                          version: "2",
                        })
                      );
                      setJsonError(false);
                    }}
                    sx={{ ml: 1, mt: 1 }}
                    disabled={isDemo}
                  >
                    {query.icon}
                    <span style={{ marginLeft: "4px" }}>{query.name}</span>
                  </Button>
                </Tooltip>
              ))}
            {Object.entries(sequenceTypes)
              .filter(([_, sequenceType]) => sequenceType.category === key)
              .map(([key, sequenceType]) => (
                <Tooltip
                  title={displayTooltip && sequenceType.description}
                  arrow
                >
                  <Button
                    key={key}
                    variant="outlined"
                    color="success"
                    onClick={() => {
                      const updatedQueries = [
                        ...queries,
                        JSON.parse(JSON.stringify(sequenceType.defaultQuery)),
                      ];
                      setQueries(updatedQueries as GeneralSearchQuery[]);
                      setQueryJson(JSON.stringify(updatedQueries));
                      setJsonError(false);
                    }}
                    sx={{ ml: 1, mt: 1 }}
                    disabled={isDemo}
                  >
                    {sequenceType.name}
                  </Button>
                </Tooltip>
              ))}
          </Box>
        ))}
    </Box>
  );
};
