import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Container,
  Stack,
  CardMedia,
} from "@mui/material";
import { FC } from "react";
import { Title } from "../common/Title";
import { useNavigate } from "react-router";
import { sprintAnswerEvents } from "./SprintAnswer/data/events";
import SprintAnswerMenuItem from "./SprintAnswer/SprintAnswerMenuItem";
import { hikakinImages } from "./HighAndLow/data/hikakinImages";
import { hikakinOpen } from "./HighAndLow/HighAndLow";

export const GameinTop: FC = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <Title>EnigmaGAMEIN</Title>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        <SprintAnswerMenuItem
          event={sprintAnswerEvents["misezan"]}
          media={"https://scrapbox.io/files/65c08116b38b220024f0f393.png"}
          key={"misezan"}
        />
        {hikakinOpen() && (
          <Card sx={{ minWidth: 275, maxWidth: "350px", my: 2 }}>
            <CardMedia
              component="img"
              src={hikakinImages[0]}
              sx={{
                width: "100%",
                objectFit: "contain",
              }}
            />
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {"タイムアタック"}
              </Typography>
              <Typography variant="h5" component="div">
                HIKAKIN結婚報告ハイ＆ロー
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                表示される100種類のヒカキンの結婚報告画像について <br />
                前の画像よりも番号が大きいか小さいか当ててください
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  navigate(`/g/hikakin-marriage`);
                }}
              >
                ゲームを開始
              </Button>
            </CardActions>
          </Card>
        )}

        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"タイムアタック"}
            </Typography>
            <Typography variant="h5" component="div">
              スプリントアンサー
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
            <Typography variant="body2">
              順番に表示される問題に素早く答えを入力してタイムを競います。
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => {
                navigate(`/g/sprint-answer`);
              }}
            >
              ゲームを開始
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Container>
  );
};
