import { Chip, Grid } from "@mui/material";
import { FC } from "react";
import { toNormal, fontConvert } from "../../../../../../lib/fontConvert";

interface ConvertProps {
  pattern: string;
  setConverted: (converted: string) => void;
}
export const FontConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  return (
    <Grid item sx={{ mt: 1 }}>
      <Chip
        label="ABCabc"
        variant="filled"
        onClick={() => {
          setConverted(toNormal(pattern));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="ᴀʙᴄᴀʙᴄ"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "smallCapitals"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝔸𝔹ℂ𝕒𝕓𝕔"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "outlineFonts"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />

      <Chip
        label="𝘼𝘽𝘾𝙖𝙗𝙘"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "boldItalic"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝗔𝗕𝗖𝗮𝗯𝗰"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "bold"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝘈𝘉𝘊𝘢𝘣𝘤"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "italic"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝑨𝑩𝑪𝒂𝒃𝒄"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "serifBoldItalic"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝐀𝐁𝐂𝐚𝐛𝐜"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "serifBold"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝐴𝐵𝐶𝑎𝑏𝑐"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "serifItalic"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝕬𝕭𝕮𝖆𝖇𝖈"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "frakturBold"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝔄𝔅ℭ𝔞𝔟𝔠"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "fraktur"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="🅐🅑🅒🅐🅑🅒"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "bubbleBlack"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="ⒶⒷⒸⓐⓑⓒ"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "bubbleWhite"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="🅰🅱🅲🅰🅱🅲"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "squareBlack"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="🄰🄱🄲🄰🄱🄲"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "squareWhite"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𓄿𓃀𓎡𓄿𓃀𓎡"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "hieroGlyph"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝓐𝓑𝓒𝓪𝓫𝓬"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "cursiveBold"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="𝒜ℬ𝒞𝒶𝒷𝒸"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "cursive"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
      <Chip
        label="ɐqɔɐqɔ"
        variant="outlined"
        onClick={() => {
          setConverted(fontConvert(pattern, "upsideDown"));
        }}
        sx={{ mt: 1, ml: 1 }}
      />
    </Grid>
  );
};
