import { frypanDictionary } from "../../data/frypan";
import { dataSource } from "../../dictionaryWorker";
const sortedTable = {};
const dictionaryTable = {};
const dictionaryTransformTable = {};
export const createSortedTable = (dictionary) => {
    const words = dictionary.includes("frypan")
        ? frypanDictionary
        : dataSource.getArray(dictionary);
    if (!words) {
        return {};
    }
    const key = dictionary.sort().join(",");
    if (sortedTable[key]) {
        return sortedTable[key];
    }
    const table = {};
    for (const word of words) {
        const key = word.split("").sort().join("");
        table[key] = table[key] ?? [];
        table[key].push(word);
    }
    return (sortedTable[key] = table);
};
export const createDictionaryTable = (dictionary) => {
    const key = dictionary.sort().join(",");
    if (dictionaryTable[key]) {
        return dictionaryTable[key];
    }
    const words = dataSource.getArray(dictionary);
    if (!words) {
        return {};
    }
    const table = {};
    for (const word of words) {
        table[word] = true;
    }
    return (dictionaryTable[key] = table);
};
export const createSeparatedDictionaryTable = (dictionary, src, dst, separator = ",") => {
    const key = `${dictionary}*${src.join(",")}-${dst.join(",")}`;
    if (dictionaryTransformTable[key]) {
        return dictionaryTransformTable[key];
    }
    const words = dataSource.get(dictionary);
    if (!words) {
        return {};
    }
    const table = {};
    for (const word of words) {
        const arr = word.split(separator);
        const srcValues = src.map((s) => arr[s].split("/")).flat();
        const dstValues = dst.map((d) => arr[d].split("/")).flat();
        srcValues.forEach((s) => {
            table[s] = table[s] ?? [];
            dstValues.forEach((d) => {
                table[s].push(d);
            });
        });
    }
    return (dictionaryTransformTable[key] = table);
};
