import あ from "./kobuta/あ.json";
import い from "./kobuta/い.json";
import う from "./kobuta/う.json";
import え from "./kobuta/え.json";
import お from "./kobuta/お.json";
import か from "./kobuta/か.json";
import き中盤 from "./kobuta/き中盤.json";
import き序盤 from "./kobuta/き序盤.json";
import き終盤 from "./kobuta/き終盤.json";
import く from "./kobuta/く.json";
import け from "./kobuta/け.json";
import こ from "./kobuta/こ.json";
import さ from "./kobuta/さ.json";
import しゃ from "./kobuta/しゃ.json";
import しゅ from "./kobuta/しゅ.json";
import しょ from "./kobuta/しょ.json";
import し序盤 from "./kobuta/し序盤.json";
import し終盤 from "./kobuta/し終盤.json";
import す from "./kobuta/す.json";
import せ from "./kobuta/せ.json";
import そ from "./kobuta/そ.json";
import た from "./kobuta/た.json";
import ち from "./kobuta/ち.json";
import つ from "./kobuta/つ.json";
import て from "./kobuta/て.json";
import と from "./kobuta/と.json";
import な from "./kobuta/な.json";
import に from "./kobuta/に.json";
import ぬ from "./kobuta/ぬ.json";
import ね from "./kobuta/ね.json";
import の from "./kobuta/の.json";
import は from "./kobuta/は.json";
import ひ from "./kobuta/ひ.json";
import ふ from "./kobuta/ふ.json";
import へ from "./kobuta/へ.json";
import ほ from "./kobuta/ほ.json";
import ま from "./kobuta/ま.json";
import み from "./kobuta/み.json";
import む from "./kobuta/む.json";
import め from "./kobuta/め.json";
import も from "./kobuta/も.json";
import や from "./kobuta/や.json";
import ゆ from "./kobuta/ゆ.json";
import よ from "./kobuta/よ.json";
import ら from "./kobuta/ら.json";
import り from "./kobuta/り.json";
import る from "./kobuta/る.json";
import れ from "./kobuta/れ.json";
import ろ from "./kobuta/ろ.json";
import わ from "./kobuta/わ.json";

const all = [
  あ,
  い,
  う,
  え,
  お,
  か,
  き序盤,
  き中盤,
  き終盤,
  く,
  け,
  こ,
  さ,
  し序盤,
  しゃ,
  しゅ,
  しょ,
  し終盤,
  す,
  せ,
  そ,
  た,
  ち,
  つ,
  て,
  と,
  な,
  に,
  ぬ,
  ね,
  の,
  は,
  ひ,
  ふ,
  へ,
  ほ,
  ま,
  み,
  む,
  め,
  も,
  や,
  ゆ,
  よ,
  ら,
  り,
  る,
  れ,
  ろ,
  わ,
];

const signMapper: {
  [key: string]: number;
} = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  a: 10,
  b: 11,
  c: 12,
  e: 14,
  g: 16,
  "?": -1,
  x: -1,
  "-": -1,
  "？": -1,
  "×": -1,
  "２": 2,
  "４": 4,
  "　": -1,
  ｘ: -1,
};

export const getAllValues = () => {
  const words: string[] = [];
  const errorWords: string[] = [];
  all.map((dic) => {
    return dic.values
      .filter((e, i) => i > 0)
      .forEach((line) => {
        const chars = line[0].split("");
        chars.forEach((char) => {
          if (signMapper[char] !== -1) {
            if (line[signMapper[char] + 2]) {
              words.push(line[signMapper[char] + 2]);
            } else {
              errorWords.push(line[2]);
            }
          }
        });
      });
  });
  return { words, errorWords };
};
