import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import LinkIcon from "@mui/icons-material/Link";
import {
  StorageReference,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import { useEffect, useState } from "react";

export const storagePath = "https://storage.googleapis.com/";

export const ImageItem = ({ imageRef }: { imageRef: StorageReference }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const downloadPath = storagePath + imageRef.bucket + "/" + imageRef.fullPath;
  useEffect(() => {
    getDownloadURL(imageRef).then((url) => {
      setImageUrl(url);
    });
  }, [imageRef]);
  return (
    <Card sx={{ maxWidth: 200, width: 200, mr: 1, mt: 1 }}>
      {imageUrl ? (
        <CardMedia
          component="img"
          height="200"
          image={imageUrl}
          alt={imageRef.name}
          sx={{
            objectFit: "contain",
          }}
        />
      ) : (
        <Skeleton variant="rectangular" width={200} height={200} />
      )}
      <CardContent
        sx={{
          height: "3em",
        }}
      >
        <Typography gutterBottom variant="caption" component="div">
          {imageRef.name}
        </Typography>
      </CardContent>
      <Box
        sx={{
          textAlign: "right",
        }}
      >
        <IconButton
          aria-label="link"
          onClick={() => {
            navigator.clipboard.writeText(downloadPath);
          }}
          sx={{
            ml: "auto",
            mt: 1,
            mr: 1,
          }}
        >
          <LinkIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteObject(imageRef);
          }}
          sx={{
            ml: "auto",
            mt: 1,
            mr: 1,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );
};
