import { SprintAnswerEvent, Question, QuestionFormType } from "../events";
import { prob13thRoot } from "../rawData/13thRoot";
import { createTake } from "../take";

export const root13th: SprintAnswerEvent = {
      id: "13thRoot",
    title: "50桁の数の13乗根",
    category: "ランダム出題",
    addMenu: true,
    menuDescription: (
      <>
        50桁の数の13乗根を素早く答えるゲームです。
        <br />
        答えは整数になります。
      </>
    ),
    descriptions: [
      <p key={0}>
        50桁の数の13乗根、例えば
        <br />
        X¹³ = 18939396095137353785333345053410266246749651329024
        <br />
        となるようなXを素早く答えるゲームです。
        <br />
        数字は10桁区切りで改行されており、答えはすべて整数になります。 (例:
        X=6174)
        <br />
        全部で5問出題されます。
        <br />
      </p>,
    ],
    questions: [],
    submit: false,
    retry: true,
    createQuestions: () => {
      const questions: Question[] = [];
      prob13thRoot.forEach((e) => {
        const question = {
          problemText: [0, 1, 2, 3, 4]
            .map((i) => e[1].slice(10 * i, 10 * i + 10))
            .join("\n"),
          answers: [e[0]],
          type: "numeric" as QuestionFormType,
        };
        questions.push(question);
      });
      return createTake(questions, 5);
    },
    shareButton: true,
    answerButton: true,
    hashTag: "13throot",
}