export const rawRytran = `ーーけっと,ふりま
ーーど,たわれこ
ーさるすたじおじゃぱん,ゆにば
ーじぇくと,はろぷろ
ーじん,めるまが
ーす,わんぴ
ーすめんと,ぱわはら
ーっー,はりぽた
ーっくす,すたば
ーつすけっと,ふるば
ーてぃんぐばー,すためん
ーとど,すけぼー
ーでぃしょなー,えあこん
ーととろーらー,りもこん
ーなるぴゅーた,ぱそこん
ーる,へびめた
ーるで,すくいず
ーるれす,めあど
ーれす,めるあど
あさまがる,まりみて
あす,あくえり
あに,めいと
ある,ばいと
あるばい,ふりーたー
あんしえる,らるく
いーしょん,ぷれすて
いとる,らのべ
いんぐっと,ふらげ
いんたーっお,ねとらじ
いんたーっと,ねかふぇ
いんとめんと,あぽ
うぇ,ぶろぐ
うどっく,さんとら
えんすすとあ,こんびに
えんたるお,おりらじ
おーてぃー,まりぱ
おーるすたーず,さざん
おと,まりかー
がーらいき,はんすと
かたく,ひらぱー
くたーぐ,きゃらそん
くちゃりんぐ,りすとら
ぐらむ,いんすた
げーしょん,かーなび
こーるすめんと,あるはら
ごぎー,でま
ごんすと,どらくえ
さら,りーまん
じっきょうふるやきゅう,ぱわぷろ
しふぃっく,ぱりーぐ
しゃるすめんと,せくはら
しゅよう,しょくぱん
じんーる,えんすと
すえっと,ぽかり
すけとーず,ばっしゅ
すたーたー,もんはん
すだんすーしょん,だんれぼ
すとらくちゃー,いんふら
すとーたー,いられ
すんじ,かしおれ
そくのーらんど,やくねば
ぞんいむ,あまぷら
たーーでぃんぐ,あふれこ
だーうんど,あんぐら
たーっじ,いんかれ
たーどれん,みすちる
だいら、やまいち,まつけん
だくしょん,いんとろ
たるすたー,でじもん
たるら,でじかめ
ちゅーむい,こすぷれ
っーく,ぶくま
いんたーっーむ,ねとげ
っちっく,すけぶ
っちゃちゃん,うんなん
っと,ぽけべる
っとすたー,ぽけもん
っとそん,りせまら
っぷ,ふぉとしょ
っぷっぷ,すますま
てぃーっきんぐ,ぱんすと
どせっさ,わーぷろ
とっぷす,ぽてち
とととしょうかんじゅう,ばかてす
とめーしょん,きょうあに
どるたー,あいます
なるど,まくど
にうむ,あるみ
にこほうそう,にこなま
にょん,どんぺり
にんぐーぱー,かんぺ
にんぐめ,もーむす
ねすてる,びじほ
ねのきんじゅつし,はがれん
ばーーす,なんぷれ
ばーがー,もす
ぱーこん,すーふぁみ
はら,あきば
ぷしる,しゃーぺん
ほーて,どんき
まーけっと,すーぱー
まぷれっくす,しねこん
みつとーばー,はちくろ
むく,すらだん
むたー,げーせん
めろーらいぶ,あにさま
めんとすとあ,でぱーと
もと,まつじゅん
やるすと,ろいほ
らいき,すと
らや,きむたく
らんだむ,めも
りーーと,ふぁみま
りーとふぁいたー,すとつー
りーとらん,ふぁみれす
りーまんゆう,さらきん
りっくしんどろーむ,めたぼ
るいんぐげーむ,ろーぷれ
るすーけてぃんぐ,すてま
るたーじゅ,るぽらいたー
るで,ぱねぽん
れーと,ちょこ
ろふぉん,まいく
んこ,がち
んすとれーしょん,でも
んてぃー,ぎゃら
んてぃんてぃ,ないない
んとぶ,ぷりくら
んとらる,せりーぐ`;
