export const word5alphabet = [
  ["1 2 15 21 20", "about"],
  ["1 2 15 22 5", "above"],
  ["1 2 21 19 5", "abuse"],
  ["1 3 20 15 18", "actor"],
  ["1 3 21 20 5", "acute"],
  ["1 4 1 16 20", "adapt"],
  ["1 4 13 9 20", "admit"],
  ["1 4 15 16 20", "adopt"],
  ["1 4 15 18 5", "adore"],
  ["1 4 15 18 14", "adorn"],
  ["1 4 21 12 20", "adult"],
  ["1 6 20 5 18", "after"],
  ["1 7 1 9 14", "again"],
  ["1 7 5 14 20", "agent"],
  ["1 7 15 14 25", "agony"],
  ["1 7 18 5 5", "agree"],
  ["1 8 5 1 4", "ahead"],
  ["1 9 19 12 5", "aisle"],
  ["1 12 1 18 13", "alarm"],
  ["1 12 2 21 13", "album"],
  ["1 12 5 18 20", "alert"],
  ["1 12 9 11 5", "alike"],
  ["1 12 9 22 5", "alive"],
  ["1 12 12 15 23", "allow"],
  ["1 12 15 14 5", "alone"],
  ["1 12 15 14 7", "along"],
  ["1 12 15 21 4", "aloud"],
  ["1 12 20 1 18", "altar"],
  ["1 12 20 5 18", "alter"],
  ["1 13 1 26 5", "amaze"],
  ["1 13 15 14 7", "among"],
  ["1 13 16 12 5", "ample"],
  ["1 13 21 19 5", "amuse"],
  ["1 14 7 5 12", "angel"],
  ["1 14 7 5 18", "anger"],
  ["1 14 7 12 5", "angle"],
  ["1 14 7 18 25", "angry"],
  ["1 14 11 12 5", "ankle"],
  ["1 14 14 15 25", "annoy"],
  ["1 16 1 18 20", "apart"],
  ["1 16 16 12 5", "apple"],
  ["1 16 16 12 25", "apply"],
  ["1 16 18 9 12", "april"],
  ["1 16 18 15 14", "apron"],
  ["1 18 5 14 1", "arena"],
  ["1 18 7 21 5", "argue"],
  ["1 18 9 19 5", "arise"],
  ["1 18 13 5 4", "armed"],
  ["1 18 18 15 23", "arrow"],
  ["1 19 9 4 5", "aside"],
  ["1 21 4 9 15", "audio"],
  ["1 21 4 9 20", "audit"],
  ["1 22 15 9 4", "avoid"],
  ["1 23 1 9 20", "await"],
  ["1 23 1 11 5", "awake"],
  ["1 23 1 18 4", "award"],
  ["1 23 1 18 5", "aware"],
  ["1 23 6 21 12", "awful"],
  ["2 1 3 15 14", "bacon"],
  ["2 1 4 7 5", "badge"],
  ["2 1 4 12 25", "badly"],
  ["2 1 11 5 18", "baker"],
  ["2 1 12 11 25", "balky"],
  ["2 1 19 9 3", "basic"],
  ["2 1 19 9 14", "basin"],
  ["2 1 19 9 19", "basis"],
  ["2 1 20 3 8", "batch"],
  ["2 1 20 8 5", "bathe"],
  ["2 5 1 3 8", "beach"],
  ["2 5 1 18 4", "beard"],
  ["2 5 1 19 20", "beast"],
  ["2 5 7 9 14", "begin"],
  ["2 5 9 14 7", "being"],
  ["2 5 12 12 25", "belly"],
  ["2 5 12 15 23", "below"],
  ["2 5 14 3 8", "bench"],
  ["2 5 18 18 25", "berry"],
  ["2 9 18 20 8", "birth"],
  ["2 12 1 3 11", "black"],
  ["2 12 1 4 5", "blade"],
  ["2 12 1 13 5", "blame"],
  ["2 12 1 14 11", "blank"],
  ["2 12 1 19 20", "blast"],
  ["2 12 1 26 5", "blaze"],
  ["2 12 5 5 4", "bleed"],
  ["2 12 5 14 4", "blend"],
  ["2 12 5 19 19", "bless"],
  ["2 12 9 14 4", "blind"],
  ["2 12 9 14 11", "blink"],
  ["2 12 15 3 11", "block"],
  ["2 12 15 15 4", "blood"],
  ["2 12 15 15 13", "bloom"],
  ["2 12 21 18 20", "blurt"],
  ["2 12 21 19 8", "blush"],
  ["2 15 1 18 4", "board"],
  ["2 15 1 19 20", "boast"],
  ["2 15 14 21 19", "bonus"],
  ["2 15 15 19 20", "boost"],
  ["2 15 15 20 8", "booth"],
  ["2 15 18 5 4", "bored"],
  ["2 15 19 19 25", "bossy"],
  ["2 15 21 14 4", "bound"],
  ["2 15 24 5 18", "boxer"],
  ["2 18 1 9 14", "brain"],
  ["2 18 1 11 5", "brake"],
  ["2 18 1 14 4", "brand"],
  ["2 18 1 19 19", "brass"],
  ["2 18 1 22 5", "brave"],
  ["2 18 5 1 4", "bread"],
  ["2 18 5 1 11", "break"],
  ["2 18 5 5 4", "breed"],
  ["2 18 9 3 11", "brick"],
  ["2 18 9 4 5", "bride"],
  ["2 18 9 5 6", "brief"],
  ["2 18 9 14 7", "bring"],
  ["2 18 15 1 4", "broad"],
  ["2 18 15 23 14", "brown"],
  ["2 18 21 19 8", "brush"],
  ["2 21 4 4 25", "buddy"],
  ["2 21 9 12 4", "build"],
  ["2 21 12 12 25", "bully"],
  ["2 21 14 3 8", "bunch"],
  ["2 21 18 19 20", "burst"],
  ["2 21 25 5 18", "buyer"],
  ["3 1 2 9 14", "cabin"],
  ["3 1 2 12 5", "cable"],
  ["3 1 13 5 12", "camel"],
  ["3 1 14 1 12", "canal"],
  ["3 1 14 4 25", "candy"],
  ["3 1 14 15 14", "canon"],
  ["3 1 18 18 25", "carry"],
  ["3 1 18 22 5", "carve"],
  ["3 1 20 3 8", "catch"],
  ["3 1 21 19 5", "cause"],
  ["3 4 18 15 13", "cdrom"],
  ["3 5 1 19 5", "cease"],
  ["3 5 12 12 15", "cello"],
  ["3 8 1 9 14", "chain"],
  ["3 8 1 9 18", "chair"],
  ["3 8 1 15 19", "chaos"],
  ["3 8 1 18 13", "charm"],
  ["3 8 1 18 20", "chart"],
  ["3 8 1 19 5", "chase"],
  ["3 8 5 1 16", "cheap"],
  ["3 8 5 1 20", "cheat"],
  ["3 8 5 3 11", "check"],
  ["3 8 5 5 11", "cheek"],
  ["3 8 5 5 18", "cheer"],
  ["3 8 5 19 19", "chess"],
  ["3 8 5 19 20", "chest"],
  ["3 8 9 5 6", "chief"],
  ["3 8 9 12 4", "child"],
  ["3 8 9 12 9", "chili"],
  ["3 8 9 18 16", "chirp"],
  ["3 8 15 9 18", "choir"],
  ["3 8 15 11 5", "choke"],
  ["3 8 21 14 11", "chunk"],
  ["3 9 22 9 12", "civil"],
  ["3 12 1 9 13", "claim"],
  ["3 12 1 19 19", "class"],
  ["3 12 5 1 14", "clean"],
  ["3 12 5 1 18", "clear"],
  ["3 12 5 18 11", "clerk"],
  ["3 12 9 3 11", "click"],
  ["3 12 9 6 6", "cliff"],
  ["3 12 9 13 2", "climb"],
  ["3 12 15 3 11", "clock"],
  ["3 12 15 14 5", "clone"],
  ["3 12 15 19 5", "close"],
  ["3 12 15 20 8", "cloth"],
  ["3 12 15 21 4", "cloud"],
  ["3 12 15 23 14", "clown"],
  ["3 15 1 3 8", "coach"],
  ["3 15 1 19 20", "coast"],
  ["3 15 3 15 1", "cocoa"],
  ["3 15 12 15 14", "colon"],
  ["3 15 12 15 18", "color"],
  ["3 15 13 5 20", "comet"],
  ["3 15 13 9 3", "comic"],
  ["3 15 13 13 1", "comma"],
  ["3 15 18 1 12", "coral"],
  ["3 15 21 3 8", "couch"],
  ["3 15 21 7 8", "cough"],
  ["3 15 21 12 4", "could"],
  ["3 15 21 14 20", "count"],
  ["3 15 21 18 20", "court"],
  ["3 15 22 5 18", "cover"],
  ["3 18 1 3 11", "crack"],
  ["3 18 1 6 20", "craft"],
  ["3 18 1 19 8", "crash"],
  ["3 18 1 23 12", "crawl"],
  ["3 18 1 26 25", "crazy"],
  ["3 18 5 1 13", "cream"],
  ["3 18 5 5 11", "creek"],
  ["3 18 9 13 5", "crime"],
  ["3 18 9 19 16", "crisp"],
  ["3 18 15 19 19", "cross"],
  ["3 18 15 23 4", "crowd"],
  ["3 18 15 23 14", "crown"],
  ["3 18 21 5 12", "cruel"],
  ["3 18 21 19 8", "crush"],
  ["3 21 18 12 25", "curly"],
  ["3 21 18 18 25", "curry"],
  ["3 21 18 19 5", "curse"],
  ["3 21 18 22 5", "curve"],
  ["3 25 3 12 5", "cycle"],
  ["4 1 4 4 25", "daddy"],
  ["4 1 9 12 25", "daily"],
  ["4 1 9 19 25", "daisy"],
  ["4 1 14 3 5", "dance"],
  ["4 1 20 5 4", "dated"],
  ["4 5 1 20 8", "death"],
  ["4 5 2 9 20", "debit"],
  ["4 5 3 1 25", "decay"],
  ["4 5 12 1 25", "delay"],
  ["4 5 14 9 13", "denim"],
  ["4 5 14 19 5", "dense"],
  ["4 5 16 20 8", "depth"],
  ["4 5 22 9 12", "devil"],
  ["4 9 1 18 25", "diary"],
  ["4 9 18 20 25", "dirty"],
  ["4 9 19 3 15", "disco"],
  ["4 9 22 5 18", "diver"],
  ["4 9 26 26 25", "dizzy"],
  ["4 15 9 14 7", "doing"],
  ["4 15 21 2 20", "doubt"],
  ["4 15 23 18 25", "dowry"],
  ["4 15 26 5 14", "dozen"],
  ["4 18 1 6 20", "draft"],
  ["4 18 1 9 14", "drain"],
  ["4 18 1 13 1", "drama"],
  ["4 18 5 1 4", "dread"],
  ["4 18 5 1 13", "dream"],
  ["4 18 5 19 19", "dress"],
  ["4 18 9 12 12", "drill"],
  ["4 18 9 14 11", "drink"],
  ["4 18 9 22 5", "drive"],
  ["4 18 15 15 16", "droop"],
  ["4 18 15 23 14", "drown"],
  ["4 18 21 14 11", "drunk"],
  ["4 21 19 20 25", "dusty"],
  ["4 21 22 5 20", "duvet"],
  ["4 23 1 18 6", "dwarf"],
  ["5 1 7 5 18", "eager"],
  ["5 1 7 12 5", "eagle"],
  ["5 1 18 12 25", "early"],
  ["5 1 18 20 8", "earth"],
  ["5 9 7 8 20", "eight"],
  ["5 12 2 15 23", "elbow"],
  ["5 12 5 3 20", "elect"],
  ["5 12 9 20 5", "elite"],
  ["5 13 1 9 12", "email"],
  ["5 13 16 20 25", "empty"],
  ["5 14 5 13 25", "enemy"],
  ["5 14 10 15 25", "enjoy"],
  ["5 14 18 15 12", "enrol"],
  ["5 14 20 5 18", "enter"],
  ["5 14 20 18 25", "entry"],
  ["5 17 21 1 12", "equal"],
  ["5 17 21 9 16", "equip"],
  ["5 18 5 3 20", "erect"],
  ["5 18 18 15 18", "error"],
  ["5 18 21 16 20", "erupt"],
  ["5 19 19 1 25", "essay"],
  ["5 20 8 9 3", "ethic"],
  ["5 22 5 14 20", "event"],
  ["5 22 5 18 25", "every"],
  ["5 24 1 3 20", "exact"],
  ["5 24 1 12 20", "exalt"],
  ["5 24 3 5 12", "excel"],
  ["5 24 9 12 5", "exile"],
  ["5 24 9 19 20", "exist"],
  ["5 24 20 18 1", "extra"],
  ["6 1 9 14 20", "faint"],
  ["6 1 9 18 25", "fairy"],
  ["6 1 9 20 8", "faith"],
  ["6 1 12 19 5", "false"],
  ["6 1 14 3 25", "fancy"],
  ["6 1 20 1 12", "fatal"],
  ["6 1 21 12 20", "fault"],
  ["6 1 22 15 18", "favor"],
  ["6 5 1 19 20", "feast"],
  ["6 5 14 3 5", "fence"],
  ["6 5 18 18 25", "ferry"],
  ["6 5 20 3 8", "fetch"],
  ["6 5 22 5 18", "fever"],
  ["6 9 2 5 18", "fiber"],
  ["6 9 2 18 5", "fibre"],
  ["6 9 5 12 4", "field"],
  ["6 9 6 20 8", "fifth"],
  ["6 9 6 20 25", "fifty"],
  ["6 9 7 8 20", "fight"],
  ["6 9 14 1 12", "final"],
  ["6 9 18 19 20", "first"],
  ["6 9 24 5 4", "fixed"],
  ["6 12 1 13 5", "flame"],
  ["6 12 1 19 8", "flash"],
  ["6 12 5 5 20", "fleet"],
  ["6 12 5 19 8", "flesh"],
  ["6 12 15 1 20", "float"],
  ["6 12 15 3 11", "flock"],
  ["6 12 15 15 4", "flood"],
  ["6 12 15 15 18", "floor"],
  ["6 12 15 21 18", "flour"],
  ["6 12 21 9 4", "fluid"],
  ["6 12 21 14 11", "flunk"],
  ["6 12 21 20 5", "flute"],
  ["6 15 3 21 19", "focus"],
  ["6 15 7 7 25", "foggy"],
  ["6 15 12 12 25", "folly"],
  ["6 15 18 3 5", "force"],
  ["6 15 18 20 8", "forth"],
  ["6 15 18 20 25", "forty"],
  ["6 15 21 14 4", "found"],
  ["6 18 1 13 5", "frame"],
  ["6 18 1 14 11", "frank"],
  ["6 18 1 21 4", "fraud"],
  ["6 18 5 1 11", "freak"],
  ["6 18 5 19 8", "fresh"],
  ["6 18 9 5 4", "fried"],
  ["6 18 15 14 20", "front"],
  ["6 18 15 19 20", "frost"],
  ["6 18 21 9 20", "fruit"],
  ["6 21 12 12 25", "fully"],
  ["6 21 14 11 25", "funky"],
  ["6 21 14 14 25", "funny"],
  ["6 21 18 15 18", "furor"],
  ["7 1 21 4 25", "gaudy"],
  ["7 5 14 18 5", "genre"],
  ["7 8 15 19 20", "ghost"],
  ["7 9 1 14 20", "giant"],
  ["7 9 22 5 14", "given"],
  ["7 12 1 18 5", "glare"],
  ["7 12 1 19 19", "glass"],
  ["7 12 5 1 13", "gleam"],
  ["7 12 9 4 5", "glide"],
  ["7 12 9 14 20", "glint"],
  ["7 12 15 2 5", "globe"],
  ["7 12 15 18 25", "glory"],
  ["7 12 15 22 5", "glove"],
  ["7 15 4 12 25", "godly"],
  ["7 15 15 4 19", "goods"],
  ["7 15 18 7 5", "gorge"],
  ["7 18 1 3 5", "grace"],
  ["7 18 1 4 5", "grade"],
  ["7 18 1 9 14", "grain"],
  ["7 18 1 14 4", "grand"],
  ["7 18 1 14 20", "grant"],
  ["7 18 1 16 5", "grape"],
  ["7 18 1 16 8", "graph"],
  ["7 18 1 19 16", "grasp"],
  ["7 18 1 19 19", "grass"],
  ["7 18 1 22 5", "grave"],
  ["7 18 1 22 25", "gravy"],
  ["7 18 5 1 20", "great"],
  ["7 18 5 5 14", "green"],
  ["7 18 5 5 20", "greet"],
  ["7 18 9 5 6", "grief"],
  ["7 18 9 12 12", "grill"],
  ["7 18 15 15 13", "groom"],
  ["7 18 15 19 19", "gross"],
  ["7 18 15 21 16", "group"],
  ["7 21 1 18 4", "guard"],
  ["7 21 5 19 19", "guess"],
  ["7 21 5 19 20", "guest"],
  ["7 21 9 4 5", "guide"],
  ["7 21 9 12 20", "guilt"],
  ["8 1 2 9 20", "habit"],
  ["8 1 9 18 25", "hairy"],
  ["8 1 14 4 25", "handy"],
  ["8 1 16 16 25", "happy"],
  ["8 1 19 20 5", "haste"],
  ["8 1 19 20 25", "hasty"],
  ["8 1 20 3 8", "hatch"],
  ["8 1 21 14 20", "haunt"],
  ["8 1 22 5 14", "haven"],
  ["8 5 1 18 20", "heart"],
  ["8 5 1 22 25", "heavy"],
  ["8 5 4 7 5", "hedge"],
  ["8 5 12 12 15", "hello"],
  ["8 15 2 2 25", "hobby"],
  ["8 15 14 5 25", "honey"],
  ["8 15 14 15 18", "honor"],
  ["8 15 18 19 5", "horse"],
  ["8 15 20 5 12", "hotel"],
  ["8 15 21 19 5", "house"],
  ["8 15 22 5 18", "hover"],
  ["8 21 13 1 14", "human"],
  ["8 21 13 9 4", "humid"],
  ["8 21 13 15 18", "humor"],
  ["8 21 18 18 25", "hurry"],
  ["9 4 5 1 12", "ideal"],
  ["9 4 9 15 13", "idiom"],
  ["9 4 9 15 20", "idiot"],
  ["9 13 1 7 5", "image"],
  ["9 13 16 12 25", "imply"],
  ["9 14 3 21 18", "incur"],
  ["9 14 4 5 24", "index"],
  ["9 14 5 18 20", "inert"],
  ["9 14 6 5 18", "infer"],
  ["9 14 14 5 18", "inner"],
  ["9 14 16 21 20", "input"],
  ["9 19 19 21 5", "issue"],
  ["9 22 15 18 25", "ivory"],
  ["10 5 1 14 19", "jeans"],
  ["10 5 12 12 25", "jelly"],
  ["10 5 23 5 12", "jewel"],
  ["10 15 9 14 20", "joint"],
  ["10 21 4 7 5", "judge"],
  ["10 21 9 3 5", "juice"],
  ["10 21 9 3 25", "juicy"],
  ["10 21 18 15 18", "juror"],
  ["11 14 5 5 12", "kneel"],
  ["11 14 9 6 5", "knife"],
  ["11 14 15 3 11", "knock"],
  ["11 14 15 23 14", "known"],
  ["12 1 2 5 12", "label"],
  ["12 1 2 15 18", "labor"],
  ["12 1 14 3 5", "lance"],
  ["12 1 18 7 5", "large"],
  ["12 1 19 5 18", "laser"],
  ["12 1 20 5 18", "later"],
  ["12 1 21 7 8", "laugh"],
  ["12 1 25 5 18", "layer"],
  ["12 5 1 18 14", "learn"],
  ["12 5 1 19 5", "lease"],
  ["12 5 1 19 8", "leash"],
  ["12 5 1 19 20", "least"],
  ["12 5 1 22 5", "leave"],
  ["12 5 7 1 12", "legal"],
  ["12 5 13 15 14", "lemon"],
  ["12 5 22 5 12", "level"],
  ["12 9 7 8 20", "light"],
  ["12 9 13 9 20", "limit"],
  ["12 9 14 5 14", "linen"],
  ["12 9 14 5 18", "liner"],
  ["12 9 20 5 18", "liter"],
  ["12 9 20 18 5", "litre"],
  ["12 9 22 5 18", "liver"],
  ["12 15 2 2 25", "lobby"],
  ["12 15 3 1 12", "local"],
  ["12 15 4 7 5", "lodge"],
  ["12 15 7 9 3", "logic"],
  ["12 15 15 19 5", "loose"],
  ["12 15 18 18 25", "lorry"],
  ["12 15 21 19 25", "lousy"],
  ["12 15 22 5 18", "lover"],
  ["12 15 23 5 18", "lower"],
  ["12 15 25 1 12", "loyal"],
  ["12 21 3 11 25", "lucky"],
  ["12 21 14 3 8", "lunch"],
  ["12 25 18 9 3", "lyric"],
  ["13 1 4 1 13", "madam"],
  ["13 1 7 9 3", "magic"],
  ["13 1 10 15 18", "major"],
  ["13 1 11 5 18", "maker"],
  ["13 1 13 13 1", "mamma"],
  ["13 1 14 7 15", "mango"],
  ["13 1 16 12 5", "maple"],
  ["13 1 18 3 8", "march"],
  ["13 1 18 18 25", "marry"],
  ["13 1 20 3 8", "match"],
  ["13 1 20 8 19", "maths"],
  ["13 1 25 2 5", "maybe"],
  ["13 1 25 15 18", "mayor"],
  ["13 5 1 14 19", "means"],
  ["13 5 4 1 12", "medal"],
  ["13 5 4 9 1", "media"],
  ["13 5 12 15 14", "melon"],
  ["13 5 18 3 25", "mercy"],
  ["13 5 18 7 5", "merge"],
  ["13 5 18 18 25", "merry"],
  ["13 5 19 19 25", "messy"],
  ["13 5 20 1 12", "metal"],
  ["13 5 20 5 18", "meter"],
  ["13 5 20 18 5", "metre"],
  ["13 9 3 18 15", "micro"],
  ["13 9 4 19 20", "midst"],
  ["13 9 7 8 20", "might"],
  ["13 9 13 9 3", "mimic"],
  ["13 9 14 15 18", "minor"],
  ["13 9 14 21 19", "minus"],
  ["13 9 19 20 25", "misty"],
  ["13 15 4 1 12", "modal"],
  ["13 15 4 5 12", "model"],
  ["13 15 4 5 13", "modem"],
  ["13 15 7 21 12", "mogul"],
  ["13 15 13 13 25", "mommy"],
  ["13 15 14 5 25", "money"],
  ["13 15 14 20 8", "month"],
  ["13 15 15 4 25", "moody"],
  ["13 15 18 1 12", "moral"],
  ["13 15 20 15 18", "motor"],
  ["13 15 20 20 15", "motto"],
  ["13 15 21 12 4", "mould"],
  ["13 15 21 14 20", "mount"],
  ["13 15 21 18 14", "mourn"],
  ["13 15 21 19 5", "mouse"],
  ["13 15 21 20 8", "mouth"],
  ["13 15 22 5 18", "mover"],
  ["13 15 22 9 5", "movie"],
  ["13 21 4 4 25", "muddy"],
  ["13 21 19 9 3", "music"],
  ["14 1 11 5 4", "naked"],
  ["14 1 19 20 25", "nasty"],
  ["14 1 22 1 12", "naval"],
  ["14 5 5 4 25", "needy"],
  ["14 5 18 22 5", "nerve"],
  ["14 5 22 5 18", "never"],
  ["14 5 23 12 25", "newly"],
  ["14 9 5 3 5", "niece"],
  ["14 9 7 8 20", "night"],
  ["14 15 2 12 5", "noble"],
  ["14 15 9 19 5", "noise"],
  ["14 15 9 19 25", "noisy"],
  ["14 15 13 1 4", "nomad"],
  ["14 15 18 20 8", "north"],
  ["14 15 22 5 12", "novel"],
  ["14 21 18 19 5", "nurse"],
  ["15 3 3 21 18", "occur"],
  ["15 3 5 1 14", "ocean"],
  ["15 6 6 5 18", "offer"],
  ["15 6 20 5 14", "often"],
  ["15 12 9 22 5", "olive"],
  ["15 14 9 15 14", "onion"],
  ["15 16 5 18 1", "opera"],
  ["15 18 2 9 20", "orbit"],
  ["15 18 4 5 18", "order"],
  ["15 18 7 1 14", "organ"],
  ["15 20 8 5 18", "other"],
  ["15 21 7 8 20", "ought"],
  ["15 21 14 3 5", "ounce"],
  ["15 21 20 4 15", "outdo"],
  ["15 21 20 5 18", "outer"],
  ["15 23 9 14 7", "owing"],
  ["15 23 14 5 18", "owner"],
  ["15 26 15 14 5", "ozone"],
  ["16 1 9 14 20", "paint"],
  ["16 1 14 5 12", "panel"],
  ["16 1 14 9 3", "panic"],
  ["16 1 14 20 19", "pants"],
  ["16 1 16 5 18", "paper"],
  ["16 1 18 20 25", "party"],
  ["16 1 19 20 1", "pasta"],
  ["16 1 20 3 8", "patch"],
  ["16 1 21 19 5", "pause"],
  ["16 5 1 3 5", "peace"],
  ["16 5 1 3 8", "peach"],
  ["16 5 1 18 12", "pearl"],
  ["16 5 4 1 12", "pedal"],
  ["16 5 14 3 5", "pence"],
  ["16 5 14 14 25", "penny"],
  ["16 5 20 20 25", "petty"],
  ["16 8 1 19 5", "phase"],
  ["16 8 15 14 5", "phone"],
  ["16 8 15 20 15", "photo"],
  ["16 9 1 14 15", "piano"],
  ["16 9 3 11 25", "picky"],
  ["16 9 5 3 5", "piece"],
  ["16 9 5 20 25", "piety"],
  ["16 9 12 15 20", "pilot"],
  ["16 9 20 3 8", "pitch"],
  ["16 9 26 26 1", "pizza"],
  ["16 12 1 3 5", "place"],
  ["16 12 1 9 14", "plain"],
  ["16 12 1 14 5", "plane"],
  ["16 12 1 14 20", "plant"],
  ["16 12 1 20 5", "plate"],
  ["16 12 5 1 4", "plead"],
  ["16 12 21 3 11", "pluck"],
  ["16 15 9 14 20", "point"],
  ["16 15 12 1 18", "polar"],
  ["16 15 12 9 15", "polio"],
  ["16 15 18 3 8", "porch"],
  ["16 15 21 14 4", "pound"],
  ["16 15 23 5 18", "power"],
  ["16 18 1 23 14", "prawn"],
  ["16 18 5 19 19", "press"],
  ["16 18 9 3 5", "price"],
  ["16 18 9 3 11", "prick"],
  ["16 18 9 4 5", "pride"],
  ["16 18 9 13 5", "prime"],
  ["16 18 9 14 20", "print"],
  ["16 18 9 15 18", "prior"],
  ["16 18 9 26 5", "prize"],
  ["16 18 15 2 5", "probe"],
  ["16 18 15 15 6", "proof"],
  ["16 18 15 19 5", "prose"],
  ["16 18 15 21 4", "proud"],
  ["16 18 15 22 5", "prove"],
  ["16 21 12 19 5", "pulse"],
  ["16 21 14 3 8", "punch"],
  ["16 21 16 9 12", "pupil"],
  ["16 21 16 16 25", "puppy"],
  ["16 21 18 19 5", "purse"],
  ["17 21 1 11 5", "quake"],
  ["17 21 5 5 14", "queen"],
  ["17 21 5 5 18", "queer"],
  ["17 21 5 18 25", "query"],
  ["17 21 5 21 5", "queue"],
  ["17 21 9 3 11", "quick"],
  ["17 21 9 5 20", "quiet"],
  ["17 21 9 20 5", "quite"],
  ["17 21 15 20 5", "quote"],
  ["18 1 4 1 18", "radar"],
  ["18 1 4 9 15", "radio"],
  ["18 1 9 14 25", "rainy"],
  ["18 1 9 19 5", "raise"],
  ["18 1 12 12 25", "rally"],
  ["18 1 14 7 5", "range"],
  ["18 1 16 9 4", "rapid"],
  ["18 1 26 15 18", "razor"],
  ["18 5 1 3 8", "reach"],
  ["18 5 1 3 20", "react"],
  ["18 5 1 4 25", "ready"],
  ["18 5 2 5 12", "rebel"],
  ["18 5 6 5 18", "refer"],
  ["18 5 12 1 24", "relax"],
  ["18 5 12 1 25", "relay"],
  ["18 5 12 9 3", "relic"],
  ["18 5 14 5 23", "renew"],
  ["18 5 16 1 25", "repay"],
  ["18 5 16 12 25", "reply"],
  ["18 8 25 13 5", "rhyme"],
  ["18 9 4 5 18", "rider"],
  ["18 9 4 7 5", "ridge"],
  ["18 9 7 8 20", "right"],
  ["18 9 19 11 25", "risky"],
  ["18 9 22 1 12", "rival"],
  ["18 9 22 5 18", "river"],
  ["18 15 1 19 20", "roast"],
  ["18 15 2 9 14", "robin"],
  ["18 15 2 15 20", "robot"],
  ["18 15 3 11 25", "rocky"],
  ["18 15 21 7 8", "rough"],
  ["18 15 21 14 4", "round"],
  ["18 15 21 20 5", "route"],
  ["18 15 25 1 12", "royal"],
  ["18 21 7 2 25", "rugby"],
  ["18 21 12 5 18", "ruler"],
  ["18 21 13 15 18", "rumor"],
  ["18 21 18 1 12", "rural"],
  ["18 21 19 20 25", "rusty"],
  ["19 1 4 12 25", "sadly"],
  ["19 1 12 1 4", "salad"],
  ["19 1 12 15 14", "salon"],
  ["19 1 12 19 1", "salsa"],
  ["19 1 12 20 25", "salty"],
  ["19 1 14 4 25", "sandy"],
  ["19 1 21 3 5", "sauce"],
  ["19 3 1 12 5", "scale"],
  ["19 3 1 18 5", "scare"],
  ["19 3 1 18 6", "scarf"],
  ["19 3 1 18 25", "scary"],
  ["19 3 5 14 5", "scene"],
  ["19 3 5 14 20", "scent"],
  ["19 3 15 12 4", "scold"],
  ["19 3 15 16 5", "scope"],
  ["19 3 15 18 5", "score"],
  ["19 3 18 5 23", "screw"],
  ["19 3 21 2 1", "scuba"],
  ["19 5 9 26 5", "seize"],
  ["19 5 14 19 5", "sense"],
  ["19 5 18 22 5", "serve"],
  ["19 5 22 5 14", "seven"],
  ["19 8 1 4 5", "shade"],
  ["19 8 1 11 5", "shake"],
  ["19 8 1 11 25", "shaky"],
  ["19 8 1 12 12", "shall"],
  ["19 8 1 13 5", "shame"],
  ["19 8 1 16 5", "shape"],
  ["19 8 1 18 5", "share"],
  ["19 8 1 18 16", "sharp"],
  ["19 8 1 22 5", "shave"],
  ["19 8 5 5 16", "sheep"],
  ["19 8 5 5 18", "sheer"],
  ["19 8 5 5 20", "sheet"],
  ["19 8 5 12 6", "shelf"],
  ["19 8 5 12 12", "shell"],
  ["19 8 9 6 20", "shift"],
  ["19 8 9 14 5", "shine"],
  ["19 8 9 14 25", "shiny"],
  ["19 8 9 18 20", "shirt"],
  ["19 8 15 3 11", "shock"],
  ["19 8 15 15 20", "shoot"],
  ["19 8 15 18 5", "shore"],
  ["19 8 15 18 20", "short"],
  ["19 8 15 21 20", "shout"],
  ["19 8 18 21 7", "shrug"],
  ["19 9 7 8 20", "sight"],
  ["19 9 12 12 25", "silly"],
  ["19 9 14 3 5", "since"],
  ["19 9 24 20 25", "sixty"],
  ["19 11 1 20 5", "skate"],
  ["19 11 9 12 12", "skill"],
  ["19 11 9 18 20", "skirt"],
  ["19 12 1 14 7", "slang"],
  ["19 12 1 19 8", "slash"],
  ["19 12 1 22 5", "slave"],
  ["19 12 5 5 16", "sleep"],
  ["19 12 9 3 5", "slice"],
  ["19 12 9 4 5", "slide"],
  ["19 12 15 16 5", "slope"],
  ["19 13 1 12 12", "small"],
  ["19 13 1 18 20", "smart"],
  ["19 13 1 19 8", "smash"],
  ["19 13 5 12 12", "smell"],
  ["19 13 9 12 5", "smile"],
  ["19 13 9 20 8", "smith"],
  ["19 13 15 11 5", "smoke"],
  ["19 14 1 3 11", "snack"],
  ["19 14 1 11 5", "snake"],
  ["19 14 5 1 11", "sneak"],
  ["19 14 5 5 18", "sneer"],
  ["19 14 9 6 6", "sniff"],
  ["19 14 15 18 5", "snore"],
  ["19 14 15 23 25", "snowy"],
  ["19 15 12 1 18", "solar"],
  ["19 15 12 9 4", "solid"],
  ["19 15 12 22 5", "solve"],
  ["19 15 18 18 25", "sorry"],
  ["19 15 21 14 4", "sound"],
  ["19 15 21 20 8", "south"],
  ["19 16 1 3 5", "space"],
  ["19 16 1 18 5", "spare"],
  ["19 16 1 18 11", "spark"],
  ["19 16 5 1 11", "speak"],
  ["19 16 5 5 4", "speed"],
  ["19 16 5 12 12", "spell"],
  ["19 16 5 14 4", "spend"],
  ["19 16 9 3 5", "spice"],
  ["19 16 9 3 25", "spicy"],
  ["19 16 9 12 12", "spill"],
  ["19 16 9 20 5", "spite"],
  ["19 16 12 9 20", "split"],
  ["19 16 15 9 12", "spoil"],
  ["19 16 15 15 14", "spoon"],
  ["19 16 15 18 20", "sport"],
  ["19 16 18 1 25", "spray"],
  ["19 17 21 1 4", "squad"],
  ["19 20 1 3 11", "stack"],
  ["19 20 1 6 6", "staff"],
  ["19 20 1 7 5", "stage"],
  ["19 20 1 9 14", "stain"],
  ["19 20 1 9 18", "stair"],
  ["19 20 1 11 5", "stake"],
  ["19 20 1 12 12", "stall"],
  ["19 20 1 13 16", "stamp"],
  ["19 20 1 14 4", "stand"],
  ["19 20 1 18 5", "stare"],
  ["19 20 1 18 20", "start"],
  ["19 20 1 20 5", "state"],
  ["19 20 5 1 11", "steak"],
  ["19 20 5 1 12", "steal"],
  ["19 20 5 1 13", "steam"],
  ["19 20 5 5 12", "steel"],
  ["19 20 5 5 16", "steep"],
  ["19 20 5 5 18", "steer"],
  ["19 20 5 18 14", "stern"],
  ["19 20 9 3 11", "stick"],
  ["19 20 9 6 6", "stiff"],
  ["19 20 9 12 12", "still"],
  ["19 20 9 14 11", "stink"],
  ["19 20 15 3 11", "stock"],
  ["19 20 15 14 5", "stone"],
  ["19 20 15 15 12", "stool"],
  ["19 20 15 18 5", "store"],
  ["19 20 15 18 13", "storm"],
  ["19 20 15 18 25", "story"],
  ["19 20 15 22 5", "stove"],
  ["19 20 18 1 23", "straw"],
  ["19 20 18 9 16", "strip"],
  ["19 20 21 3 11", "stuck"],
  ["19 20 21 4 25", "study"],
  ["19 20 21 6 6", "stuff"],
  ["19 20 25 12 5", "style"],
  ["19 21 7 1 18", "sugar"],
  ["19 21 14 14 25", "sunny"],
  ["19 21 16 5 18", "super"],
  ["19 21 18 7 5", "surge"],
  ["19 23 1 18 13", "swarm"],
  ["19 23 5 1 18", "swear"],
  ["19 23 5 1 20", "sweat"],
  ["19 23 5 5 16", "sweep"],
  ["19 23 5 5 20", "sweet"],
  ["19 23 5 12 12", "swell"],
  ["19 23 9 6 20", "swift"],
  ["19 23 9 14 7", "swing"],
  ["19 23 15 18 4", "sword"],
  ["20 1 2 12 5", "table"],
  ["20 1 19 20 5", "taste"],
  ["20 1 19 20 25", "tasty"],
  ["20 1 21 14 20", "taunt"],
  ["20 5 1 3 8", "teach"],
  ["20 5 1 19 5", "tease"],
  ["20 5 13 16 20", "tempt"],
  ["20 5 14 19 5", "tense"],
  ["20 8 1 14 11", "thank"],
  ["20 8 5 6 20", "theft"],
  ["20 8 5 9 18", "their"],
  ["20 8 5 13 5", "theme"],
  ["20 8 5 18 5", "there"],
  ["20 8 5 19 5", "these"],
  ["20 8 9 3 11", "thick"],
  ["20 8 9 5 6", "thief"],
  ["20 8 9 7 8", "thigh"],
  ["20 8 9 14 7", "thing"],
  ["20 8 9 14 11", "think"],
  ["20 8 9 18 4", "third"],
  ["20 8 15 19 5", "those"],
  ["20 8 18 5 5", "three"],
  ["20 8 18 15 23", "throw"],
  ["20 8 21 13 2", "thumb"],
  ["20 8 21 13 16", "thump"],
  ["20 9 7 5 18", "tiger"],
  ["20 9 7 8 20", "tight"],
  ["20 9 13 5 19", "times"],
  ["20 9 18 5 4", "tired"],
  ["20 9 20 12 5", "title"],
  ["20 15 1 19 20", "toast"],
  ["20 15 4 1 25", "today"],
  ["20 15 14 14 5", "tonne"],
  ["20 15 15 20 8", "tooth"],
  ["20 15 16 9 3", "topic"],
  ["20 15 18 3 8", "torch"],
  ["20 15 20 1 12", "total"],
  ["20 15 21 3 8", "touch"],
  ["20 15 21 7 8", "tough"],
  ["20 15 23 5 12", "towel"],
  ["20 15 23 5 18", "tower"],
  ["20 15 24 9 3", "toxic"],
  ["20 18 1 3 5", "trace"],
  ["20 18 1 3 11", "track"],
  ["20 18 1 4 5", "trade"],
  ["20 18 1 9 12", "trail"],
  ["20 18 1 9 14", "train"],
  ["20 18 1 9 20", "trait"],
  ["20 18 1 13 16", "tramp"],
  ["20 18 1 19 8", "trash"],
  ["20 18 5 1 4", "tread"],
  ["20 18 5 1 20", "treat"],
  ["20 18 5 14 4", "trend"],
  ["20 18 9 1 12", "trial"],
  ["20 18 9 2 5", "tribe"],
  ["20 18 9 3 11", "trick"],
  ["20 18 15 15 16", "troop"],
  ["20 18 21 3 11", "truck"],
  ["20 18 21 12 25", "truly"],
  ["20 18 21 14 11", "trunk"],
  ["20 18 21 19 20", "trust"],
  ["20 18 21 20 8", "truth"],
  ["20 21 12 9 16", "tulip"],
  ["20 21 13 15 18", "tumor"],
  ["20 21 20 15 18", "tutor"],
  ["20 23 9 3 5", "twice"],
  ["20 23 9 19 20", "twist"],
  ["21 14 3 12 5", "uncle"],
  ["21 14 4 5 18", "under"],
  ["21 14 6 9 20", "unfit"],
  ["21 14 9 6 25", "unify"],
  ["21 14 9 15 14", "union"],
  ["21 14 9 20 5", "unite"],
  ["21 14 20 9 5", "untie"],
  ["21 14 20 9 12", "until"],
  ["21 16 16 5 18", "upper"],
  ["21 16 19 5 20", "upset"],
  ["21 18 2 1 14", "urban"],
  ["21 19 1 7 5", "usage"],
  ["21 19 8 5 18", "usher"],
  ["21 19 21 1 12", "usual"],
  ["21 20 20 5 18", "utter"],
  ["22 1 7 21 5", "vague"],
  ["22 1 12 9 4", "valid"],
  ["22 1 12 21 5", "value"],
  ["22 5 14 21 5", "venue"],
  ["22 5 18 19 5", "verse"],
  ["22 9 4 5 15", "video"],
  ["22 9 7 15 18", "vigor"],
  ["22 9 12 12 1", "villa"],
  ["22 9 18 21 19", "virus"],
  ["22 9 19 9 20", "visit"],
  ["22 9 20 1 12", "vital"],
  ["22 9 22 9 4", "vivid"],
  ["22 15 9 3 5", "voice"],
  ["22 15 13 9 20", "vomit"],
  ["22 15 20 5 18", "voter"],
  ["22 15 23 5 12", "vowel"],
  ["23 1 7 15 14", "wagon"],
  ["23 1 9 19 20", "waist"],
  ["23 1 19 20 5", "waste"],
  ["23 1 20 3 8", "watch"],
  ["23 1 20 5 18", "water"],
  ["23 5 1 18 25", "weary"],
  ["23 5 1 22 5", "weave"],
  ["23 5 9 7 8", "weigh"],
  ["23 5 9 18 4", "weird"],
  ["23 8 1 12 5", "whale"],
  ["23 8 5 1 20", "wheat"],
  ["23 8 5 5 12", "wheel"],
  ["23 8 5 18 5", "where"],
  ["23 8 9 3 8", "which"],
  ["23 8 9 12 5", "while"],
  ["23 8 9 20 5", "white"],
  ["23 8 15 12 5", "whole"],
  ["23 8 15 19 5", "whose"],
  ["23 9 4 5 14", "widen"],
  ["23 9 4 15 23", "widow"],
  ["23 9 4 20 8", "width"],
  ["23 9 14 4 25", "windy"],
  ["23 9 20 20 25", "witty"],
  ["23 15 13 1 14", "woman"],
  ["23 15 18 12 4", "world"],
  ["23 15 18 18 25", "worry"],
  ["23 15 18 19 5", "worse"],
  ["23 15 18 19 20", "worst"],
  ["23 15 18 20 8", "worth"],
  ["23 15 21 12 4", "would"],
  ["23 15 21 14 4", "wound"],
  ["23 18 5 3 11", "wreck"],
  ["23 18 9 19 20", "wrist"],
  ["23 18 9 20 5", "write"],
  ["23 18 15 14 7", "wrong"],
  ["25 1 3 8 20", "yacht"],
  ["25 5 1 18 14", "yearn"],
  ["25 9 5 12 4", "yield"],
  ["25 15 21 14 7", "young"],
  ["25 15 21 18 19", "yours"],
  ["25 15 21 20 8", "youth"],
  ["25 21 13 13 25", "yummy"],
  ["26 5 2 18 1", "zebra"],
];
