import * as React from "react";
import Typography from "@mui/material/Typography";

interface TextProps {
  children?: React.ReactNode;
  color?: string;
}

export const PlainText = (props: TextProps) => {
  return (
    <Typography
      variant="body1"
      color={props.color ? props.color : "text.primary"}
    >
      {props.children}
    </Typography>
  );
};
