import { FC, useCallback } from "react";
import { QueryForm } from "./QueryForm";
import { useState } from "react";
import { Container, OnDropCallback } from "../../../../../lib/react-smooth-dnd";
import { GeneralSearchQuery } from "@langue-de-chat-llc/enigmastudio-algorithm";

type QueryContainerProps = {
  updateQuery: (index: number, field: string, value: any) => void;
  deleteQueryField: (index: number, field: string) => void;
  removeQuery: (index: number) => void;
  displayTraceNumber: boolean;
  queries: GeneralSearchQuery[];
  sequencialQueries: GeneralSearchQuery[];
  traceCounter: number[];
  setQueries: (queries: GeneralSearchQuery[]) => void;
  setQuery: (query: string) => void;
  displayTooltip: boolean;
  isDemo: boolean;
};

export const QueryContainer: FC<QueryContainerProps> = (props) => {
  const {
    updateQuery,
    deleteQueryField,
    removeQuery,
    displayTraceNumber,
    queries,
    sequencialQueries,
    traceCounter,
    setQueries,
    setQuery,
    displayTooltip,
    isDemo,
  } = props;

  const [selectedContentsIndex, setSelectedContentsIndex] = useState(-1);
  const [hoveredContentsIndex, setHoveredContentsIndex] = useState(-1);

  const onSelect = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, contentIndex: number) => {
      setSelectedContentsIndex(contentIndex);
    },
    []
  );

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex, payload }) => {
    if (removedIndex === null || addedIndex === null) {
      return;
    }
    const updatedQueries = [...queries];
    const [removed] = updatedQueries.splice(removedIndex, 1);
    updatedQueries.splice(addedIndex, 0, removed);
    setQueries(updatedQueries);
    setQuery(JSON.stringify(updatedQueries));
    setSelectedContentsIndex(addedIndex);
  };

  return (
    <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
      {queries.map((query, index) => (
        <QueryForm
          key={index}
          index={index}
          query={query}
          updateQuery={updateQuery}
          deleteQueryField={deleteQueryField}
          removeQuery={removeQuery}
          displayTraceNumber={displayTraceNumber}
          queries={queries}
          traceCounter={traceCounter}
          setQueries={setQueries}
          setQuery={setQuery}
          sequencialQueries={sequencialQueries}
          displayTooltip={displayTooltip}
          isDemo={isDemo}
          onSelect={onSelect}
          hoveredContentsIndex={hoveredContentsIndex}
          setHoveredContentsIndex={setHoveredContentsIndex}
        />
      ))}
    </Container>
  );
};
