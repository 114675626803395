import { FC, useMemo } from "react";
import { useSiteConfig } from "../../../../../_lib/eniverse/EniverseProvider";
import { MainProps } from "../../../../../_lib/eniverse/imaginate/CommonMain";
import { useImaginateConfig } from "../../../../../_lib/eniverse/imaginate/ImaginateProvider";
import { FolderView } from "../../../../../_lib/eniverse/imaginate/mainView/FolderView";
import { If } from "../../../../../_lib/eniverse/util/Condition";
import { randomKeyGen } from "../../../../../_lib/eniverse/util/keygen";
import { IroiroTop } from "./IroiroTop";
import { iroiroAppsData } from "./iroiroAppsData";
import { NazoConnectMapView } from "./nazo-connect-map/NazoConnectMapView";
import { Apps } from "../../../../../_lib/eniverse/imaginate/mainView/Apps";

export const IroiroMain: FC<MainProps> = (props) => {
  const { currentNode, updateNode, treeData, setSelectedNode, treeCommand } =
    props;

  const [{ serviceId }] = useSiteConfig();
  const [{ projectId }] = useImaginateConfig();
  const workspaceId = useMemo(() => serviceId, [serviceId]);

  if (!currentNode) {
    return <IroiroTop />;
  }

  const createDocument = (type: string) => {
    if (type.endsWith("folder")) {
      updateNode({
        ...currentNode,
        droppable: true,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    } else {
      updateNode({
        ...currentNode,
        droppable: false,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    }
  };

  if (!currentNode.data || currentNode.data.fileType === "") {
    return (
      <>
        <If condition={!currentNode.droppable}>
          <Apps createDocument={createDocument} appsData={iroiroAppsData} />
        </If>
        <If condition={currentNode.droppable}>
          <FolderView
            currentNode={currentNode}
            treeData={treeData}
            addApp={() => {
              treeCommand.addNode(currentNode.id, treeData);
            }}
            setSelectedNode={setSelectedNode}
          />
        </If>
      </>
    );
  }

  return (
    <>
      <If condition={currentNode.data.fileType === "nazo-connect-map"}>
        <NazoConnectMapView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspaceId}
          project={projectId}
        />
      </If>
    </>
  );
};
