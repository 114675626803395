import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid
} from "@mui/material";
import { FC, useState } from "react";
import { useStatePersist } from "use-state-persist";
import { RegexInputWithCompletion } from "../../../../commonStudio/RegexInputWithCompletion";
import { ReplacementInput } from "../../../../commonStudio/ReplacementInput";
import { ConvertProps } from "./ConvertTemplate";

export const SearchConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  const [regexPattern, setRegexPattern] = useState("");
  const [regexNotMatch, setRegexNotMatch] = useState(false);
  const [replacement, setReplacement] = useState("");
  const [replaceFullsizeSymbolToHalfsize] = useStatePersist(
    "general-search-replace-fullsize-symbol-to-halfsize",
    false
  );

  return (
    <>
      <Grid
        item
        sx={{
          width: "100%",
        }}
      >
        <RegexInputWithCompletion
          pattern={regexPattern}
          onPatternChange={(newPattern) => {
            setRegexPattern(newPattern);
          }}
        />
      </Grid>
      <Grid>
        <Button
          variant="contained"
          sx={{ mt: 1, mr: 1, mb: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .filter((line) => {
                  return regexNotMatch
                    ? !line.match(new RegExp(regexPattern))
                    : line.match(new RegExp(regexPattern));
                })
                .join("\n")
            );
          }}
        >
          正規表現フィルタ
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={regexNotMatch || false}
              onChange={(e) => {
                setRegexNotMatch(e.target.checked);
              }}
            />
          }
          sx={{ ml: 1 }}
          label="否定マッチ"
        />
      </Grid>
      <Grid item>
        <ReplacementInput
          replacement={replacement}
          onReplacementChange={(newReplacement) => {
            setReplacement(newReplacement);
          }}
          sx={{ ml: 1, width: "300px" }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{ mt: 1, mr: 1, mb: 1 }}
          onClick={() => {
            setConverted(
              pattern.replace(new RegExp(regexPattern), replacement)
            );
          }}
        >
          正規表現置換
        </Button>
        <Button
          variant="contained"
          sx={{ mt: 1, mr: 1, mb: 1 }}
          onClick={() => {
            setConverted(
              pattern.replace(new RegExp(regexPattern, "g"), replacement)
            );
          }}
        >
          全置換
        </Button>
      </Grid>
    </>
  );
};
