import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import "./SprintAnswer.css";
// App.tsx
import { FC } from "react";
import { SprintAnswerEvent } from "./data/events";
import { useNavigate } from "react-router";

const SprintAnswerMenuItem: FC<{
  event: SprintAnswerEvent;
  media?: string;
}> = ({ event, media }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card sx={{ minWidth: 275, maxWidth: "350px", my: 2 }}>
        {media && (
          <CardMedia
            component="img"
            src={media}
            sx={{
              width: "100%",
              objectFit: "contain",
            }}
          />
        )}

        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {event.category}
          </Typography>
          <Typography variant="h5" component="div">
            {event.title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
          <Typography variant="body2">{event.menuDescription}</Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              navigate(`/g/sprint-answer/${event.id}`);
            }}
          >
            ゲームを開始
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default SprintAnswerMenuItem;
