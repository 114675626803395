import { Box, Button, Container } from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { useWorker } from "../../../../hooks/useWorker";
import { regexEnumerate } from "../../../../lib/infiniteRegex/regex_enumerate";
import { ResultList } from "../../common/Result";
import { Title } from "../../common/Title";
import { RegexInputWithCompletion } from "../../commonStudio/RegexInputWithCompletion";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";

export const RegexInfinitySearch: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  ctx.setEnableLoad(true);
  const [keywords, setKeywords] = useState("");
  const [dictionary, setDictionary] = useState("");
  const [result, setResult] = useState<string[]>([]);
  const [searching, setSearching] = useState(false);
  const onSearch = useCallback(() => {
    const results: string[] = [];
    for (let i = 0; i < 1; ++i) {
      const result = regexEnumerate(keywords);
      results.push(result);
    }
    setResult(results);
  }, [keywords]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>正規表現無限検索</Title>
      <Box sx={{ width: "100vw" }}>
        <RegexInputWithCompletion
          pattern={keywords}
          onPatternChange={(newPattern) => {
            setKeywords(newPattern);
          }}
        />
      </Box>
      <Box>
        <Button variant="contained" onClick={onSearch} disabled={!ctx.loaded}>
          列挙検索
        </Button>
        <Button
          variant="contained"
          onClick={onSearch}
          disabled={!ctx.loaded}
          sx={{
            ml: 2,
          }}
        >
          ランダム検索
        </Button>
      </Box>
      <ResultList items={result} loading={searching} />
    </Container>
  );
};
