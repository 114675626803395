import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { useWorker } from "../../../../hooks/useWorker";
import { Title } from "../../common/Title";
import { AnagramResultList } from "../../commonStudio/AnagramSearchResult";
import { DictionaryRadioSelector } from "../../commonStudio/DictionaryRadioSelector";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { DemoModeMessage } from "../../commonStudio/DemoModeMessage";
import {
  AnagramProgress,
  DictionaryKey,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

export const Anagram: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  ctx.setEnableLoad(true);
  const [keyword, setKeyword] = useState("");
  const [dictionary, setDictionary] = useState("");
  const [result, setResult] = useState<string[]>([]);
  const [searching, setSearching] = useState(false);
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [minWordCount, setMinWordCount] = useState("");
  const [maxWordCount, setMaxWordCount] = useState("");
  const [useWord, setUseWord] = useState("");
  const [unuseWord, setUnuseWord] = useState("");
  const [ignoreSmall, setIgnoreSmall] = useState(false);
  const [ignoreSeiDaku, setIgnoreSeiDaku] = useState(false);

  const [progress, setProgress] = useState<AnagramProgress | null>(null);
  const { multipleAnagram, abort } = useWorker();

  const onSearch = useCallback(() => {
    setSearching(true);
    setResult([]);
    multipleAnagram(
      {
        keyword,
        minimumLength: minLength,
        maximumLength: maxLength,
        minimumWordCount: minWordCount,
        maximumWordCount: maxWordCount,
        useWord,
        unuseWord,
        dictionary: dictionary as DictionaryKey,
        option: {
          ignoreSeiDaku,
          ignoreSmall,
        },
      },
      (result) => {
        if (result) {
          setResult(result);
          setSearching(false);
        }
      },
      (progress) => {
        setProgress(progress);
      }
    );
  }, [
    multipleAnagram,
    keyword,
    minLength,
    maxLength,
    minWordCount,
    maxWordCount,
    useWord,
    unuseWord,
    dictionary,
    ignoreSeiDaku,
    ignoreSmall,
  ]);

  const handleChangeDictionary = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDictionary((event.target as HTMLInputElement).value);
    },
    []
  );

  const onSearchStop = useCallback(() => {
    abort();
  }, [abort]);

  const plan = ctx.plan;
  const isDemo = plan === "normal";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>複数単語アナグラム検索</Title>
      <Typography variant="body1">
        使用文字を1度ずつ使ってできる単語の組み合わせを検索します。
      </Typography>
      <DemoModeMessage isDemo={isDemo} />
      <TextField
        label="使用文字"
        variant="outlined"
        sx={{ width: "80vw", mt: 2 }}
        value={keyword}
        onChange={(event) => {
          setKeyword(event.target.value);
        }}
        disabled={isDemo}
      />

      <Box>
        <TextField
          label="含む単語"
          variant="outlined"
          multiline
          sx={{ width: "80vw", mt: 2 }}
          value={useWord}
          onChange={(event) => {
            setUseWord(event.target.value);
          }}
          disabled={isDemo}
        />
        <TextField
          label="含まない単語"
          variant="outlined"
          multiline
          sx={{ width: "80vw", mt: 2 }}
          value={unuseWord}
          onChange={(event) => {
            setUnuseWord(event.target.value);
          }}
          disabled={isDemo}
        />
      </Box>
      <Box>
        <TextField
          label="最低長"
          type="number"
          variant="outlined"
          value={minLength || ""}
          onChange={(e) => setMinLength(e.target.value)}
          sx={{ mt: 2, width: "150px" }}
          disabled={isDemo}
        />
        <TextField
          label="最大長"
          type="number"
          variant="outlined"
          value={maxLength || ""}
          onChange={(e) => setMaxLength(e.target.value)}
          sx={{ mt: 2, ml: 2, width: "150px" }}
          disabled={isDemo}
        />
      </Box>
      <Box>
        <TextField
          label="最小単語数"
          type="number"
          variant="outlined"
          value={minWordCount || ""}
          onChange={(e) => setMinWordCount(e.target.value)}
          sx={{ mt: 2, width: "150px" }}
          disabled={isDemo}
        />
        <TextField
          label="最大単語数"
          type="number"
          variant="outlined"
          value={maxWordCount || ""}
          onChange={(e) => setMaxWordCount(e.target.value)}
          sx={{ mt: 2, ml: 2, width: "150px" }}
          disabled={isDemo}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={ignoreSeiDaku}
              onChange={(e) => setIgnoreSeiDaku(e.target.checked)}
            />
          }
          sx={{ ml: 1 }}
          label="清濁同一視"
          disabled={isDemo}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={ignoreSmall}
              onChange={(e) => setIgnoreSmall(e.target.checked)}
            />
          }
          sx={{ ml: 1 }}
          label="小書き文字同一視"
          disabled={isDemo}
        />
      </Box>
      <DictionaryRadioSelector
        dictionary={dictionary}
        onChange={handleChangeDictionary}
        disabled={isDemo}
      />
      <Box>
        <Button
          variant="contained"
          onClick={onSearch}
          disabled={!ctx.loaded || dictionary === "" || searching}
          sx={{ mt: 2 }}
        >
          検索
        </Button>
        <Button
          variant="contained"
          onClick={onSearchStop}
          disabled={!searching}
          sx={{ mt: 2, ml: 2 }}
        >
          停止
        </Button>
      </Box>

      <AnagramResultList
        items={result}
        loading={searching}
        progress={progress}
      />
    </Container>
  );
};
