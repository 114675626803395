import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";

export const SeparatedDictionarySelector = ({
  labelName,
  dictionary,
  setDictionary,
  isDemo,
  onBlur,
}: {
  labelName: string;
  dictionary: string;
  setDictionary: (value: string) => void;
  isDemo: boolean;
  onBlur?: () => void;
}) => {
  const ctx = useContext(EnigmaStudioContext);
  return (
    <FormControl variant="outlined">
      <InputLabel id="select-dictionary-label">{labelName}</InputLabel>
      <Select
        labelId="select-dictionary-label"
        value={dictionary || ""}
        onChange={(e) => setDictionary(e.target.value)}
        onBlur={onBlur}
        sx={{
          ml: 1,
          width: "200px",
        }}
        disabled={isDemo}
      >
        {ctx.enabledUserDictionary
          .filter(e => e.doc.type === 'text' && e.doc.separator)
          .map((e) => (
          <MenuItem value={"user:" + e.key} key={"user:" + e.key}>
            {e.name}
          </MenuItem>
        ))}
        {ctx.enabledUserDictionary.length === 0 && (
          <MenuItem value="" key="">
            辞書を有効化してください
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
