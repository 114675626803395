import { splitGrapheme } from "./splitGrapheme";
const orderList = [
    "あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん",
    "#####がぎぐげござじずぜぞだぢづでど",
    "#########################ばびぶべぼ",
    "#########################ぱぴぷぺぽ",
    "ぁぃぅぇぉ############っ#################ゃゅょ########",
    "abcdefghijklmnopqrstuvwxyz",
];
export const orderConvert = (str) => {
    return str
        .split("\n")
        .map((line) => {
        return splitGrapheme(line.trim())
            .map((char) => {
            const index = orderList.findIndex((list) => list.includes(char));
            return index !== -1 ? orderList[index].indexOf(char) + 1 : "";
        })
            .filter((char) => char !== "")
            .join(",");
    })
        .join("\n");
};
export const orderToAlphabet = (str) => {
    return str
        .split("\n")
        .map((line) => {
        return line
            .trim()
            .split(/[,\s]/)
            .map((char) => {
            const n = parseInt(char);
            if (Number.isNaN(n))
                return char;
            return orderList[5][n - 1] ?? "";
        })
            .filter((char) => char !== "")
            .join("");
    })
        .join("\n");
};
export const orderToHiragana = (str) => {
    return str
        .split("\n")
        .map((line) => {
        return line
            .trim()
            .split(/[,\s]/)
            .map((char) => {
            const n = parseInt(char);
            if (Number.isNaN(n))
                return char;
            return orderList[0][n - 1] ?? "";
        })
            .filter((char) => char !== "")
            .join("");
    })
        .join("\n");
};
