import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import GradeIcon from "@mui/icons-material/Grade";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import Search from "@mui/icons-material/Search";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { FC, useCallback } from "react";
import { BsDiscord } from "react-icons/bs";
import { useNavigate } from "react-router";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const StudioDrawer: FC<{
  open: boolean;
  handleDrawerClose: () => void;
  drawerWidth: number;
  theme: any;
  planKey: string;
  plan: string;
}> = ({ open, handleDrawerClose, drawerWidth, theme, planKey, plan }) => {
  const nav = useNavigate();

  const navigate = useCallback(
    (path: string) => {
      if (planKey) {
        nav(path + "?plan=" + planKey);
      } else {
        nav(path);
      }
    },
    [planKey, nav]
  );
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      anchor="left"
      open={open}
    >
      <DrawerHeader theme={theme}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List dense>
        <ListItem key={"search-menu"} sx={{ px: 0 }}>
          <ListItemButton
            onClick={() => {
              navigate("/search-menu");
              handleDrawerClose();
            }}
          >
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary={"検索"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"tool-menu"} sx={{ px: 0 }}>
          <ListItemButton
            onClick={() => {
              navigate("/tool-menu");
              handleDrawerClose();
            }}
          >
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary={"ツール"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"oldtool-menu"} sx={{ px: 0 }}>
          <ListItemButton
            onClick={() => {
              navigate("/oldtool-menu");
              handleDrawerClose();
            }}
          >
            <ListItemIcon>
              <OpenInBrowserIcon />
            </ListItemIcon>
            <ListItemText primary={"旧ツール"} />
          </ListItemButton>
        </ListItem>
        {["cloudplus", "pro+", "biz", "biz-trial", "debug"].includes(plan) && (
          <ListItem key={"grid-tool"} sx={{ px: 0 }}>
            <ListItemButton
              onClick={() => {
                navigate("/grid-tool");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <Grid4x4Icon />
              </ListItemIcon>
              <ListItemText primary={"方眼ツール"} />
            </ListItemButton>
          </ListItem>
        )}

        {["pro", "pro+"].includes(plan) ? (
          <ListItem key={"pro-plan"} sx={{ px: 0 }}>
            <ListItemButton
              onClick={() => {
                navigate("/pro-plan");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary={"プラン変更"} />
            </ListItemButton>
          </ListItem>
        ) : ["normal"].includes(plan) ? (
          <ListItem
            key={"pro-plan"}
            onClick={() => {
              navigate("/pro-plan");
              handleDrawerClose();
            }}
            sx={{ px: 0 }}
          >
            <ListItemButton
              onClick={() => {
                navigate("/pro-plan");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary={"EnigmaStudio Pro"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        <ListItem key={"tool-menu"} sx={{ px: 0 }}>
          <ListItemButton
            onClick={() => {
              window.open("https://discord.gg/bb4JwGKHUC", "_blank");
              handleDrawerClose();
            }}
          >
            <ListItemIcon>
              <BsDiscord />
            </ListItemIcon>
            <ListItemText primary={"公式Discordサーバー"} />
          </ListItemButton>
        </ListItem>
        {
          <>
            <Divider />
            <ListItem key={"enigma-gamein"} sx={{ px: 0 }}>
              <ListItemButton
                onClick={() => {
                  if (
                    window.location.origin === "https://studio.enigmahouse.jp"
                  ) {
                    const origin = "https://gamein.enigmahouse.jp";
                    if (plan !== "normal") {
                      window.location.href = origin + "/g?plan=" + planKey;
                    } else {
                      window.location.href = origin + "/g";
                    }
                  } else {
                    if (plan !== "normal") {
                      window.location.href =
                        window.location.origin + "/g?plan=" + planKey;
                    } else {
                      window.location.href = window.location.origin + "/g";
                    }
                  }
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <SportsEsportsIcon />
                </ListItemIcon>
                <ListItemText primary={"EnigmaGAMEIN"} />
              </ListItemButton>
            </ListItem>
          </>
        }
      </List>
    </Drawer>
  );
};
