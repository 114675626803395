import { GeneralSearchQuery } from "@langue-de-chat-llc/enigmastudio-algorithm";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Chip } from "@mui/material";
import { FC } from "react";

export type FilterType =
  | "regex"
  | "dictionaryMatch"
  | "keyValueMatch"
  | "identicalTrace"
  | "length"
  | "fishBone";

export type QueryTypeCategory =
  | "filter"
  | "transform"
  | "match"
  | "tool"
  | "other";

export const queryTypeTable: {
  [key: string]: {
    name: string;
    icon: JSX.Element;
    description?: string;
    category: QueryTypeCategory;
    tags: string[];
  };
} = {
  regex: {
    name: "正規表現フィルタ",
    icon: <FilterAltIcon />,
    description: "正規表現にマッチする文字列のみ出力します",
    category: "filter",
    tags: ["regex"],
  },
  dictionaryMatch: {
    name: "辞書マッチ",
    icon: <FilterAltIcon />,
    description: "辞書に含まれる単語のみ出力します",
    category: "filter",
    tags: ["dictionary"],
  },
  length: {
    name: "長さフィルタ",
    icon: <FilterAltIcon />,
    description: "文字列の長さでフィルタします",
    category: "filter",
    tags: [],
  },
  candidateCharacters: {
    name: "候補文字フィルタ",
    icon: <FilterAltIcon />,
    description: "候補文字を使用して候補を絞り込みます",
    category: "filter",
    tags: [],
  },
  regexMatchCompare: {
    name: "正規表現マッチ比較",
    icon: <FilterAltIcon />,
    description: "正規表現にマッチした複数の文字列を比較します",
    category: "filter",
    tags: [],
  },
  identicalTrace: {
    name: "トレース比較",
    icon: <FilterAltIcon />,
    description: "トレースを比較して条件に当てはまるもののみ出力します",
    category: "filter",
    tags: [],
  },
  fishBone: {
    name: "魚の骨フィルタ",
    icon: <FilterAltIcon />,
    description: "複数の単語から1文字ずつ拾ってできる文字列を出力します",
    category: "filter",
    tags: [],
  },
  parallelFilter: {
    name: "並列フィルタ",
    icon: <FilterAltIcon />,
    description: "複数のフィルタ処理のどれかにマッチするものでフィルタします",
    category: "filter",
    tags: ["parallel"],
  },
  keyValueMatch: {
    name: "Key-Valueマッチ",
    icon: <AccountTreeIcon />,
    description: "同じKeyのValue同士をペアにします",
    category: "match",
    tags: [],
  },
  traceGrouping: {
    name: "トレース集約",
    icon: <AccountTreeIcon />,
    description: "トレースをグループ化します",
    category: "match",
    tags: [],
  },
  regexReplace: {
    name: "正規表現置換",
    icon: <ChangeCircleIcon />,
    description: "正規表現にマッチした文字列を置換します",
    category: "transform",
    tags: ["regex"],
  },
  anagramDictionaryMatch: {
    name: "アナグラム辞書マッチ",
    icon: <ChangeCircleIcon />,
    description: "アナグラムして辞書マッチする文字列を出力します",
    category: "transform",
    tags: ["dictionary"],
  },
  dictionaryTransform: {
    name: "辞書変換",
    icon: <ChangeCircleIcon />,
    description: "区切り付き辞書にマッチした文字列を変換します",
    category: "transform",
    tags: ["dictionary"],
  },
  parallelTransform: {
    name: "並列変換",
    icon: <ChangeCircleIcon />,
    description: "複数の変換処理を分岐して適用します",
    category: "transform",
    tags: ["parallel"],
  },
  conditionalTransform: {
    name: "条件置換",
    icon: <ChangeCircleIcon />,
    description: "条件によって処理を分岐して正規表現置換処理を適用します",
    category: "transform",
    tags: [],
  },
};

export const queryTypeArray = Object.keys(queryTypeTable).map((key) => ({
  key,
  name: queryTypeTable[key].name,
  icon: queryTypeTable[key].icon,
  description: queryTypeTable[key].description,
  category: queryTypeTable[key].category,
}));

type QueryTypeChipProps = {
  query: GeneralSearchQuery;
  onDelete?: () => void;
  onClick?: () => void;
  isDemo: boolean;
};

export const QueryTypeChip: FC<QueryTypeChipProps> = ({
  query,
  onClick,
  onDelete,
  isDemo,
}) => {
  return (
    queryTypeTable[query?.type as string] && (
      <Chip
        label={queryTypeTable[query.type as string].name || ""}
        icon={queryTypeTable[query.type as string].icon || <></>}
        color={
          query.enabled === undefined || query.enabled === true
            ? "primary"
            : "default"
        }
        onClick={onClick}
        onDelete={onDelete}
        disabled={isDemo}
      />
    )
  );
};
