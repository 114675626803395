// @ts-nocheck
import { Button, Grid, Typography, TextField } from "@mui/material";
import { If } from "../../util/Condition";
import { FC, useEffect } from "react";

const newContents = (
  obj: any,
  fields: string[],
  value: any
): {
  [key: string]: any;
} => {
  if (fields.length === 1) {
    return { ...obj, [fields[0]]: value };
  }
  return {
    ...obj,
    [fields[0]]: newContents(obj[fields[0]], fields.slice(1), value),
  };
};

type Props = {
  content: any;
  setContent: any;
  editorData: any;
  selected: boolean;
  syncContentParent: any;
};

export const EditCommonForm: FC<Props> = ({
  content,
  setContent,
  editorData,
  selected,
  syncContentParent,
}) => {
  useEffect(() => {
    if (selected) {
      /** focus */
      const input =
        document.querySelector("input") || document.querySelector("textarea");
      if (input) {
        input.focus();
        if (input.selectionStart === 0) {
          input.selectionStart = input.value.length;
        }
      }
    }
  }, [selected]);

  return (
    <>
      <If condition={selected}>
        {editorData?.form?.map((list, index) => {
          return (
            <Grid
              key={index}
              sx={{
                my: 1,
              }}
            >
              {list.map((item) => {
                if (item.formType === "category-label") {
                  return (
                    <Typography
                      variant="caption"
                      align="left"
                      sx={{
                        mr: 2,
                      }}
                      key={item.label}
                    >
                      {item.label}
                    </Typography>
                  );
                }
                if (item.formType === "button") {
                  return (
                    <Button
                      key={item.setType}
                      variant="outlined"
                      onClick={() =>
                        setContent({ ...content, type: item.setType }, true)
                      }
                      sx={{
                        mr: 1,
                      }}
                    >
                      {item.label}
                    </Button>
                  );
                }
                if (item.formType === "number") {
                  /** number form, numerical */
                  return (
                    <TextField
                      key={item.setField}
                      onChange={(e) => {
                        setContent(
                          newContents(
                            content,
                            item.setField.split("."),
                            parseInt(e.target.value)
                          )
                        );
                      }}
                      onBlur={syncContentParent}
                      value={content[item.setField]}
                      label={item.label}
                      type="number"
                    />
                  );
                }
                if (item.formType === "text") {
                  /** text form, string */
                  return (
                    <TextField
                      key={item.setField}
                      fullWidth
                      multiline
                      onChange={(e) =>
                        setContent(
                          newContents(
                            content,
                            item.setField.split("."),
                            e.target.value
                          )
                        )
                      }
                      onBlur={syncContentParent}
                      value={content[item.setField]}
                      label={item.label}
                    />
                  );
                }
                return <></>;
              })}
            </Grid>
          );
        })}
      </If>
      <If condition={!selected}>
        {editorData?.summary?.map((list, index) => {
          return (
            <Grid
              key={index}
              sx={{
                my: 1,
              }}
            >
              {/* view component (not form )*/}
              {list.map((item) => {
                if (item.formType === "category-label") {
                  return <></>;
                }
                if (item.formType === "button") {
                  return <></>;
                }
                if (item.formType === "number") {
                  /** number form, numerical */
                  return (
                    <Typography key={item.setField} variant="body1">
                      {item.label}: {content[item.setField]}
                    </Typography>
                  );
                }
                if (item.formType === "text") {
                  /** text form, string */
                  return (
                    <Typography key={item.setField} variant="body1">
                      {item.label}: {content[item.setField]}
                    </Typography>
                  );
                }
                if (item.formType === "image") {
                  return (
                    <img
                      key={item.setField}
                      src={content[item.setField]}
                      alt={content[item.setField]}
                      style={{
                        maxWidth: "350px",
                        maxHeight: "350px",
                        objectFit: "contain",
                      }}
                    />
                  );
                }
                if (item.formType === "movie") {
                  return (
                    <iframe
                      key={item.setField}
                      width="560"
                      height="315"
                      src={content[item.setField]}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  );
                }

                return <></>;
              })}
            </Grid>
          );
        })}
      </If>
    </>
  );
};
