import { AnagramDictionaryMatchTransform } from "@langue-de-chat-llc/enigmastudio-algorithm";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { DictionarySelector } from "../../../../commonStudio/DictionarySelector";
import { BasicFormProps } from "../FormProps";

export const AnagramDictionaryMatchForm: FC<
  BasicFormProps<AnagramDictionaryMatchTransform>
> = ({ index, query, updateQuery, deleteQueryField, isDemo }) => {
  return (
    <>
      <DictionarySelector
        labelName="辞書"
        dictionary={query.dictionary}
        setDictionary={(value) => updateQuery(index, "dictionary", value)}
        isDemo={isDemo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={true}
            disabled={isDemo}
            onChange={(e) => {
              updateQuery(index, "type", "dictionaryMatch");
            }}
          />
        }
        sx={{ ml: 1 }}
        label="アナグラムマッチ変換"
      />
    </>
  );
};
