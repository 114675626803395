import { Chip, Grid, Typography } from "@mui/material";
import { StorageReference } from "firebase/storage";
import { FC, useContext } from "react";
import { ConvertTimestampRecursively } from "../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { CloudDoc, DocFile, Metadata } from "./cloudType";
import { getFileChip } from "./getFileChip";

export type CloudItemSelectProps = {
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
  selectedDictionaries: string[];
  setSelectedDictionaries: (dictionaries: string[]) => void;
  storageTable: Record<string, StorageReference>;
  onSelect: (doc: DocFile & Metadata, item: StorageReference) => void;
};

export const CloudItemSelect: FC<CloudItemSelectProps> = (props) => {
  const { fileListData, selectedDictionaries, storageTable, onSelect } = props;

  const { enabledUserDictionary } = useContext(EnigmaStudioContext);

  return (
    <Grid container>
      {storageTable && fileListData?.files && (
        <>
          <Grid item xs={12}>
            {Object.keys(fileListData.files).some(
              (e) =>
                fileListData.files[e].type === "text" &&
                !fileListData.files[e].separator &&
                enabledUserDictionary.find(
                  (d) => d.key === fileListData.files[e].fileName
                )
            ) && (
              <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
                クラウド辞書:
              </Typography>
            )}
            {Object.keys(fileListData.files)
              .filter(
                (e) =>
                  fileListData.files[e].type === "text" &&
                  !fileListData.files[e].separator &&
                  enabledUserDictionary.find(
                    (d) => d.key === fileListData.files[e].fileName
                  )
              )
              .map((key) => {
                const file = fileListData.files[key];
                if (!file) return null;
                const item = storageTable[file.fileName];
                const { color, variant } = getFileChip(
                  selectedDictionaries.includes("user:" + file.fileName)
                    ? "selected"
                    : "loaded"
                );
                if (!item) {
                  return null;
                }
                return (
                  <Chip
                    key={key}
                    label={file.name}
                    onClick={() => {
                      onSelect(file as DocFile & Metadata, item);
                    }}
                    color={color}
                    variant={variant}
                    sx={{
                      mb: 1,
                      mr: 1,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
          </Grid>
          <Grid item xs={12}>
            {Object.keys(fileListData.files).some(
              (e) =>
                fileListData.files[e].type === "text" &&
                fileListData.files[e].separator &&
                enabledUserDictionary.find(
                  (d) => d.key === fileListData.files[e].fileName
                )
            ) && (
              <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
                クラウド区切り付き辞書:
              </Typography>
            )}
            {Object.keys(fileListData.files)
              .filter(
                (k) =>
                  fileListData.files[k].type === "text" &&
                  fileListData.files[k].separator &&
                  enabledUserDictionary.find(
                    (d) => d.key === fileListData.files[k].fileName
                  )
              )
              .map((key) => {
                const file = fileListData.files[key];
                if (!file) return null;
                if (file.type !== "text") return null;
                const item = storageTable[file.fileName];
                const { color, variant } = getFileChip(
                  selectedDictionaries.includes("user:" + file.fileName)
                    ? "selected"
                    : "loaded"
                );
                if (!item) {
                  return null;
                }
                return (
                  <Chip
                    key={key}
                    label={file.name}
                    onClick={() => {
                      onSelect(file as DocFile & Metadata, item);
                    }}
                    color={color}
                    variant={variant}
                    sx={{
                      mb: 1,
                      mr: 1,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
          </Grid>
        </>
      )}
    </Grid>
  );
};
