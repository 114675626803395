import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface GenericDialogProps {
  open: boolean;
  title: string;
  description: string;
  agreeText: string;
  disagreeText: string;
  onAgree: () => void;
  onDisagree: () => void;
  onClose: () => void;
}

export const GenericDialog: React.FC<GenericDialogProps> = ({
  open,
  title,
  description,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="generic-dialog-title"
      aria-describedby="generic-dialog-description"
    >
      <DialogTitle id="generic-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="generic-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree}>{disagreeText}</Button>
        <Button onClick={onAgree} autoFocus>
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
