import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import {
  dictionaries,
  DictionaryKey,
} from "@langue-de-chat-llc/enigmastudio-algorithm";

export const DictionaryRadioSelector = ({
  dictionary,
  onChange,
  displayLabel = true,
  disabled = false,
}: {
  dictionary: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  displayLabel?: boolean;
  disabled?: boolean;
}) => {
  const ctx = useContext(EnigmaStudioContext);

  return (
    <Box>
      <FormControl sx={{ my: 2 }} disabled={disabled}>
        {displayLabel && (
          <FormLabel id="radio-buttons-group-label">辞書</FormLabel>
        )}
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={dictionary}
          onChange={onChange}
        >
          {ctx.enabledDictionary
            .sort(
              (a, b) =>
                dictionaries[a as DictionaryKey].index -
                dictionaries[b as DictionaryKey].index
            )
            .map((key) => (
              <FormControlLabel
                value={key}
                control={<Radio />}
                label={dictionaries[key as DictionaryKey].name}
                key={key}
              />
            ))}
          {ctx.enabledDictionary.length === 0 && (
            <>
              <FormControlLabel
                value=""
                control={<Radio />}
                label="辞書を有効化してください"
                disabled
              />
              <Link to={"/dictionary-selector"}>
                辞書選択へ移動 <ArrowForwardIcon />
              </Link>
            </>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
