import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, IconButton } from "@mui/material";

export default function EditSideMenu({
  anchorEl,
  onClose,
  items,
}: {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  items: {
    addContents: () => void;
    copyContents: () => void;
    deleteContents: () => void;
    upContents: (() => void) | null;
    downContents: (() => void) | null;
  };
}) {
  const {
    addContents,
    copyContents,
    deleteContents,
    upContents,
    downContents,
  } = items;
  const open = Boolean(anchorEl);
  return (
    <Card
      sx={{
        position: "absolute",
        zIndex: 100,
        display: open ? "block" : "none",
        /** anchorEl right position */
        top:
          (anchorEl?.getBoundingClientRect().top ?? 0) +
          (globalThis.window.scrollY ?? 0),
        left: (anchorEl?.getBoundingClientRect().right ?? 0) + 8,
        width: 32,
        height: 160,
        py: 1,
        textAlign: "center",
      }}
    >
      <IconButton aria-label="add" size="small" onClick={addContents}>
        <AddCircleOutlineIcon fontSize="small" />
      </IconButton>
      <IconButton aria-label="copy" size="small" onClick={copyContents}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
      <IconButton aria-label="delete" size="small" onClick={deleteContents}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="up"
        size="small"
        onClick={upContents ?? undefined}
        disabled={!upContents}
      >
        <ArrowUpwardIcon fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="down"
        size="small"
        onClick={downContents ?? undefined}
        disabled={!downContents}
      >
        <ArrowDownwardIcon fontSize="small" />
      </IconButton>
    </Card>
  );
}
