import { Container } from "@mui/material";
import { FC } from "react";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { useUserClaims } from "../auth/useUserClaims";
import { Title } from "../common/Title";
import { EnigmaStudioSubscriptionButton } from "./EnigmaStudioSubscriptionButton";
import { EnigmaStudioPaymentManagerButton } from "./EnigmaStudioPaymentManagerButton";

export const Payment: FC = () => {
  const { user } = useFirebaseLogin();
  const [{ stripeRole }, loading] = useUserClaims(user);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>決済ページ</Title>
      <p>
        Your role: {loading ? "loading..." : stripeRole ? stripeRole : "none"}
      </p>

      {!loading && stripeRole !== "cloudplus" && (
        <EnigmaStudioSubscriptionButton label="決済ページを開く" />
      )}
      {!loading && stripeRole === "cloudplus" && (
        <EnigmaStudioPaymentManagerButton label="決済情報管理ページを開く" />
      )}
    </Container>
  );
};
