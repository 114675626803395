import { anagramDictionaryMatchTransform } from "./anagramDictionaryMatchFilter";
import { candidateCharactersFilter } from "./candidateCharactersFilter";
import { conditionalTransform } from "./conditionTransform";
import { dictionaryMatchFilter } from "./dictionaryMatchFilter";
import { dictionaryTransform } from "./dictionaryTransform";
import { fishBoneFilter } from "./fishBoneFilter";
import { identicalTraceFilter } from "./identicalTraceFilter";
import { keyValueMatch } from "./keyValueMatch";
import { lengthFilter } from "./lengthFilter";
import { parallelFilter } from "./parallelFilter";
import { parallelTransform } from "./parallelTransform";
import { regexFilter } from "./regexFilter";
import { regexMatchCompareFilter } from "./regexMatchCompareFilter";
import { regexReplaceSearch } from "./regexReplaceSearch";
import { traceGrouping } from "./traceGrouping";
export const generalSearchFuncTable = {
    regex: regexFilter,
    dictionaryMatch: dictionaryMatchFilter,
    anagramDictionaryMatch: anagramDictionaryMatchTransform,
    keyValueMatch,
    identicalTrace: identicalTraceFilter,
    length: lengthFilter,
    candidateCharacters: candidateCharactersFilter,
    regexMatchCompare: regexMatchCompareFilter,
    regexReplace: regexReplaceSearch,
    regexReplaceSearch,
    dictionaryTransform,
    parallelTransform,
    conditionalTransform,
    fishBone: fishBoneFilter,
    traceGrouping,
    parallelFilter,
};
