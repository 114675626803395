import { root13th } from "./event/13thRoot";
import { abcaddition } from "./event/abcaddition";
import { country2continent } from "./event/country2continent";
import { depacos2023 } from "./event/depacos2023";
import { etoAdvance } from "./event/etoAdvance";
import { misezan } from "./event/misezan";
import { num2abc } from "./event/num2abc";
import { num2kana } from "./event/num2kana";
import { riddledCapital } from "./event/riddledCapital";
import { riddledPref } from "./event/riddledPref";
import { rytran } from "./event/rytran";
import { seizaAdvance } from "./event/seizaAdvance";
import { weekday } from "./event/weekday";
import { zz } from "./event/zz";

export type QuestionFormType = "decimal" | "numeric" | "text" | "choice";
export type Question = {
  problemText?: string; // 問題文
  guideText?: string; // 説明文
  image?: string; // 問題画像
  hint?: string; // ヒント
  answers: string[]; // 正解リスト
  choices?: string[]; // 選択肢
  errors?: string[]; // エラーリスト
  type?: QuestionFormType; // 入力タイプ
};

export type SprintAnswerEvent = {
  id: string; // イベントID
  title: string; // タイトル
  category: string; // カテゴリ
  author?: string; // 作者
  menuDescription: JSX.Element; // メニューに表示する説明
  descriptions?: JSX.Element[]; // 上部説明
  descriptions2?: JSX.Element[]; // 下部説明
  questions: Question[]; // 問題
  createQuestions?: () => Question[]; // 問題を作成する関数
  hintOpenTime?: number; // ヒントを開くまでの時間
  shareButton: boolean; // シェアボタンを表示するか
  submit: boolean; // スコア送信ボタンを表示するか
  retry: boolean; // リトライボタンを表示するか
  addMenu?: boolean; // メニューに表示するか
  answerButton?: boolean; // 答えを表示するボタンを表示するか
  hashTag?: string; // ハッシュタグ
  textStyle?: { variant?: "h6" | "body1" }; // テキストのスタイル
};

export const sprintAnswerEvents: {
  [key: string]: SprintAnswerEvent;
} = {
  misezan,
  weekday,
  zz,
  "13thRoot": root13th,
  etoAdvance,
  seizaAdvance,
  num2kana,
  num2abc,
  country2continent,
  depacos2023,
  riddledPref,
  riddledCapital,
  abcaddition,
  rytran,
};
