import { Button } from "@mui/material";
import React, { useState, useRef, ChangeEvent, MouseEvent } from "react";

type Pixel = {
  r: number;
  g: number;
  b: number;
  hue: number;
  saturation: number;
  lightness: number;
};

const rgbToHsl = (
  r: number,
  g: number,
  b: number
): [number, number, number] => {
  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number,
    s: number,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
        break; // should never happen
    }
    h /= 6;
  }

  return [h, s, l];
};

const ImageSlicer: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageSrc(URL.createObjectURL(file));
    }
  };

  const analyzeImage = (slice: "row" | "col") => {
    const img = new Image();
    img.onload = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const list: Pixel[] = [];
      for (let i = 0; i < imageData.data.length; i += 4) {
        const r = imageData.data[i];
        const g = imageData.data[i + 1];
        const b = imageData.data[i + 2];
        const alpha = imageData.data[i + 3];
        if (alpha === 0) continue;

        const [hue, saturation, lightness] = rgbToHsl(r, g, b);
        list.push({ r, g, b, hue, saturation, lightness });
      }

      list.sort(
        (a, b) =>
          a.hue - b.hue ||
          a.saturation - b.saturation ||
          a.lightness - b.lightness
      );
      const pixel = list.map(({ r, g, b }) => ({ r, g, b }));
      console.log(list);
      if (slice === "row") {
        for (let i = 0; i < imageData.height; i++) {
          ctx.fillStyle = `rgb(${pixel[i].r}, ${pixel[i].g}, ${pixel[i].b})`;
          ctx.fillRect(0, i, imageData.width, 1);
        }
      } else {
        for (let i = 0; i < imageData.width; i++) {
          ctx.fillStyle = `rgb(${pixel[i].r}, ${pixel[i].g}, ${pixel[i].b})`;
          ctx.fillRect(i, 0, 1, imageData.height);
        }
      }
    };
    img.src = imageSrc || "";
    img.onerror = () => console.error("Image could not be loaded.");
  };

  const handleDownload = (event: MouseEvent<HTMLButtonElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.toBlob((blob) => {
      if (!blob) return;
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "image.png";
      a.click();
    }, "image/png");
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} />
      <Button variant="contained" onClick={() => analyzeImage("row")}>
        横スライス
      </Button>
      <Button variant="contained" onClick={() => analyzeImage("col")}>
        縦スライス
      </Button>
      <div>
        <canvas
          ref={canvasRef}
          width="200"
          height="200"
          style={{
            border: "1px solid black",
          }}
        />
      </div>
      <Button
        variant="contained"
        onClick={handleDownload}
        style={{ display: imageSrc ? "block" : "none" }}
      >
        ダウンロード
      </Button>
    </div>
  );
};

export default ImageSlicer;
