import { Box, Container, TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { ResultList } from "../../common/Result";
import { Title } from "../../common/Title";
import { DemoModeMessage } from "../../commonStudio/DemoModeMessage";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { splitGrapheme } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const NthChar: FC = () => {
  const [result, setResult] = useState<string[]>([]);
  const [keywords, setKeywords] = useState("");
  const [multipleCount, setMultipleCount] = useState(2);
  const [repeatCount, setRepeatCount] = useState(1);

  useEffect(() => {
    const result: string[] = [];

    const line = new Array(repeatCount).fill(keywords).join("");
    for (let j = 0; j < multipleCount; j++) {
      result.push(
        splitGrapheme(line)
          .filter((c, i) => i % multipleCount === j)
          .join("")
      );
    }
    setResult(result.map((e, i) => i + "\t" + e));
  }, [keywords, multipleCount, repeatCount]);

  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const isDemo = plan === "normal";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>倍数番目の文字</Title>
      <DemoModeMessage isDemo={isDemo} />
      <Box>
        <TextField
          label="Keywords"
          variant="outlined"
          sx={{ width: "80vw", mt: 3 }}
          value={keywords}
          onChange={(event) => {
            setKeywords(event.target.value);
          }}
          disabled={isDemo}
        />
        <TextField
          type="number"
          label="倍数"
          variant="outlined"
          sx={{ mt: 2 }}
          value={multipleCount}
          onChange={(event) => {
            setMultipleCount(parseInt(event.target.value));
          }}
          disabled={isDemo}
        />
        <TextField
          type="number"
          label="リピート回数"
          variant="outlined"
          sx={{ mt: 2 }}
          value={repeatCount}
          onChange={(event) => {
            setRepeatCount(parseInt(event.target.value));
          }}
          disabled={isDemo}
        />
      </Box>
      <ResultList items={result} loading={false} />
    </Container>
  );
};
