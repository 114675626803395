export const village = `しんしのつ
しままき
まっかり
るすつ
とまり
かもえない
あかいがわ
しむかっぷ
おといねっぷ
しょさんべつ
さるふつ
にしおこっぺ
なかさつない
さらべつ
つるい
よもぎた
にしめや
いなかだて
ろっかしょ
ひがしどおり
かざまうら
さい
しんごう
たのはた
ふだい
のだ
くのへ
おおひら
かみこあに
おおがた
ひがしなるせ
おおくら
さけがわ
とざわ
おおたま
てんえい
ひのえまた
きたしおばら
ゆがわ
しょうわ
にしごう
いずみざき
なかじま
さめがわ
たまかわ
ひらた
かわうち
かつらお
いいたて
とうかい
みほ
しんとう
うえの
なんもく
つまごい
たかやま
かたしな
かわば
しょうわ
ひがしちちぶ
ちょうせい
ひのはら
としま
にいじま
こうづしま
みやけ
みくらじま
あおがしま
おがさわら
きよかわ
やひこ
かりわ
せきかわ
あわしまうら
ふなはし
どうし
おしの
やまなかこ
なるさわ
こすげ
たばやま
かわかみ
みなみまき
みなみあいき
きたあいき
あおき
はら
みなみみのわ
なかがわ
みやだ
あち
ひらや
ねば
しもじょう
うるぎ
てんりゅう
やすおか
たかぎ
とよおか
おおしか
きそ
おうたき
おおくわ
おみ
いくさか
やまがた
あさひ
ちくほく
まつかわ
はくば
おたり
たかやま
きじまだいら
のざわおんせん
おがわ
さかえ
ひがししらかわ
しらかわ
とびしま
とよね
みなみやましろ
ちはやあかさか
やまぞえ
そに
みつえ
あすか
くろたき
てんかわ
のせがわ
とつかわ
しもきたやま
かみきたやま
かわかみ
ひがしよしの
きたやま
ひえづ
ちぶ
しんじょう
にしあわくら
さなごうち
きたがわ
うまじ
げいせい
おおかわ
ひだか
みはら
とうほう
あか
うぶやま
にしはら
みなみあそ
みずかみ
さがら
いつき
やまえ
くま
ひめしま
にしめら
もろつか
しいば
みしま
としま
やまと
うけん
くにがみ
おおぎみ
ひがし
なきじん
おんな
ぎのざ
いえ
よみたん
きたなかぐすく
なかぐすく
とかしき
ざまみ
あぐに
となき
みなみだいとう
きただいとう
いへや
いぜな
たらま`;
