import { FC } from "react";
import { If } from "../../../../../../_lib/eniverse/util/Condition";
import { StackData, StackHorizonData } from "../stackData";

import { View } from "@react-pdf/renderer";
import { PdfViewItem } from "../PdfViewItem";

export const Horizon: FC<{ content: StackData }> = ({ content }) => {
  return (
    <If condition={content.type === "horizon"}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          width: (content as StackHorizonData).width,
          height: (content as StackHorizonData).height,
        }}
      >
        {(content as StackHorizonData).list?.map((child: StackData) => (
          <PdfViewItem
            content={child}
            key={child.keyId ?? `${content._index}_${child._index}`}
          />
        )) ?? <></>}
      </View>
    </If>
  );
};
