export const kanjiKata = {
  了: "ア",
  伽: "イカロ",
  佑: "イナロ",
  仁: "イニ",
  伝: "イニム",
  化: "イヒ",
  仏: "イム",
  穴: "ウハ",
  空: "ウハエ/ウルエ",
  工: "エ",
  功: "エカ",
  才: "オ",
  力: "カ",
  加: "カロ",
  竹: "ケケ",
  花: "サイヒ",
  葬: "サタヒサ",
  江: "シエ",
  沿: "シハロ",
  夕: "タ",
  多: "タタ",
  外: "タト",
  死: "タヒ",
  列: "タリ",
  名: "タロ",
  千: "チ",
  升: "チト",
  舌: "チロ",
  乱: "チロレ",
  占: "トロ",
  左: "ナエ",
  友: "ナヌ",
  右: "ナロ",
  二: "ニ",
  元: "ニル",
  又: "ヌ",
  双: "ヌヌ",
  礼: "ネレ",
  行: "ノイテ",
  受: "ノツワヌ",
  八: "ハ",
  谷: "ハヘロ",
  公: "ハム",
  父: "ハメ",
  比: "ヒヒ",
  予: "マア",
  三: "ミ",
  弁: "ムサ",
  台: "ムロ",
  刈: "メリ",
  口: "ロ",
  只: "ロハ",
  叱: "ロヒ",
  四: "ロル",
  兄: "ロル",
  回: "ロロ",
  品: "ロロロ",
  冶: "ンムロ",
};
