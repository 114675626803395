export { searchMultiple } from "./anagram";
export { searchChimata } from "./chimata";
export { parseEquation, solveCryptarithm, } from "./cryptoarismetic";
export { onLoad, onLoadCustom, onLoadCustomArr, onUnload, onLoadText, } from "./dictionaryWorker";
export { onGeneralSearch, } from "./generalSearch/generalSearch";
export * from "./generalSearch/search";
export { onNumCrossSearch } from "./numCrossSearch";
export { onNumToWordSearch, onRegexReplaceSearch, onRegexSearch, } from "./search";
export { onWordMatching } from "./wordMatching";
export const workerStatus = {
    abort: false,
};
export const postMessage = (message) => {
    self.postMessage(message);
};
