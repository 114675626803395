import { FC } from "react";
import { RectPos } from "./gridToolType";
import { Line, Rect } from "react-konva";

type Props = {
  row: number;
  col: number;
  size: number;
  gridOffsetX: number;
  gridOffsetY: number;
  artboard: RectPos;
};

export const GridLine: FC<Props> = ({
  row,
  col,
  size,
  gridOffsetX,
  gridOffsetY,
  artboard,
}) => {
  return (
    <>
      {Array.from(Array(row + 1).keys()).map((r) =>
        Array.from(Array(col).keys()).map((c) => (
          <Line
            points={[
              c * size + gridOffsetX,
              r * size + gridOffsetY,
              c * size + gridOffsetX + size,
              r * size + gridOffsetY,
            ]}
            stroke={"#ccc"}
            strokeWidth={2}
          />
        ))
      )}
      {Array.from(Array(row).keys()).map((r) =>
        Array.from(Array(col + 1).keys()).map((c) => (
          <Line
            points={[
              c * size + gridOffsetX,
              r * size + gridOffsetY,
              c * size + gridOffsetX,
              r * size + gridOffsetY + size,
            ]}
            stroke={"#ccc"}
            strokeWidth={2}
          />
        ))
      )}
      <Rect
        x={gridOffsetX + artboard.x * size}
        y={gridOffsetY + artboard.y * size}
        width={artboard.width * size}
        height={artboard.height * size}
        strokeEnabled
        stroke="#000"
        strokeWidth={8}
      />
    </>
  );
};
