export type Point = { x: number; y: number };

export const bresenhamLine = (pos1: Point, pos2: Point): Point[] => {
  const delta: Point = {
    x: Math.abs(pos2.x - pos1.x),
    y: Math.abs(pos2.y - pos1.y),
  };
  const step: Point = {
    x: pos1.x < pos2.x ? 1 : -1,
    y: pos1.y < pos2.y ? 1 : -1,
  };
  let err = delta.x - delta.y;

  const points: Point[] = [];
  let current: Point = { x: pos1.x, y: pos1.y };

  while (true) {
    points.push({ x: current.x, y: current.y });
    if (current.x === pos2.x && current.y === pos2.y) {
      break;
    }
    let e2 = 2 * err;
    if (e2 > -delta.y) {
      err -= delta.y;
      current.x += step.x;
    }
    if (e2 < delta.x) {
      err += delta.x;
      current.y += step.y;
    }
  }

  return points;
};
