export const pokemon2 = `チコリータ
ベイリーフ
メガニウム
ヒノアラシ
マグマラシ
バクフーン
ワニノコ
アリゲイツ
オーダイル
オタチ
オオタチ
ホーホー
ヨルノズク
レディバ
レディアン
イトマル
アリアドス
クロバット
チョンチー
ランターン
ピチュー
ピィ
ププリン
トゲピー
トゲチック
ネイティ
ネイティオ
メリープ
モココ
デンリュウ
キレイハナ
マリル
マリルリ
ウソッキー
ニョロトノ
ハネッコ
ポポッコ
ワタッコ
エイパム
ヒマナッツ
キマワリ
ヤンヤンマ
ウパー
ヌオー
エーフィ
ブラッキー
ヤミカラス
ヤドキング
ムウマ
アンノーン
ソーナンス
キリンリキ
クヌギダマ
フォレトス
ノコッチ
グライガー
ハガネール
ブルー
グランブル
ハリーセン
ハッサム
ツボツボ
ヘラクロス
ニューラ
ヒメグマ
リングマ
マグマッグ
マグカルゴ
ウリムー
イノムー
サニーゴ
テッポウオ
オクタン
デリバード
マンタイン
エアームド
デルビル
ヘルガー
キングドラ
ゴマゾウ
ドンファン
ポリゴン2
オドシシ
ドーブル
バルキー
カポエラー
ムチュール
エレキッド
ブビィ
ミルタンク
ハピナス
ライコウ
エンテイ
スイクン
ヨーギラス
サナギラス
バンギラス
ルギア
ホウオウ
セレビィ`;
