import { KonvaEventObject } from "konva/lib/Node";
import { useState, useCallback } from "react";
import { bresenhamLine } from "../../../../../../lib/mathematics/bresenhamLine";
import { Pos } from "../gridToolType";
import { Rect } from "react-konva";
import { ModeType } from "../Menu/ModeSelector";

export const useHandleWhiteList = ({
  whiteList,
  setWhiteList,
  dragging,
  setDragging,
  posToCoord,
}: {
  whiteList: Pos[];
  setWhiteList: React.Dispatch<React.SetStateAction<Pos[]>>;
  dragging: boolean;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  posToCoord: (x: number, y: number) => Pos;
}) => {
  /**
   * whiteList Handling
   */
  const [whiteListMode, setWhiteListMode] = useState<"add" | "remove">("add");
  const [beforeWhiteCoord, setBeforeWhiteCoord] = useState<Pos>({ x: 0, y: 0 });

  const handleWhiteListStart = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      const position = e.target.getRelativePointerPosition();
      const coords = posToCoord(position.x, position.y);

      if (whiteList.some((w) => w.x === coords.x && w.y === coords.y)) {
        setWhiteListMode("remove");
      } else {
        setWhiteListMode("add");
      }

      if (whiteListMode === "remove") {
        const newWhiteList = whiteList.filter(
          (w) => w.x !== coords.x || w.y !== coords.y
        );
        setWhiteList(newWhiteList);
      } else {
        const newWhiteList = [...whiteList, coords];
        setWhiteList(newWhiteList);
      }
      setBeforeWhiteCoord(coords);
      setDragging(true);
    },
    [setWhiteList, whiteList, whiteListMode]
  );

  const handleWhiteListMove = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      if (!dragging) {
        return;
      }
      const position = e.target.getRelativePointerPosition();
      const coords = posToCoord(position.x, position.y);

      const lineCoords = bresenhamLine(beforeWhiteCoord, coords);
      console.log(lineCoords);
      let newWhiteList = [...whiteList];
      for (const lineCoord of lineCoords) {
        if (whiteListMode === "remove") {
          newWhiteList = whiteList.filter(
            (w) => w.x !== lineCoord.x || w.y !== lineCoord.y
          );
        } else {
          if (
            !whiteList.some((w) => w.x === lineCoord.x && w.y === lineCoord.y)
          ) {
            newWhiteList.push(lineCoord);
          }
        }
      }
      setWhiteList(newWhiteList);
      setBeforeWhiteCoord(coords);
    },
    [dragging, beforeWhiteCoord, whiteList, setWhiteList, whiteListMode]
  );

  const handleWhiteListEnd = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      setDragging(false);
      // const newWhiteList = [...whiteList];
      // const node = e.target;
    },
    []
  );

  return {
    handleWhiteListStart,
    handleWhiteListMove,
    handleWhiteListEnd,
  };
};

export const WhiteListView = ({
  whiteList,
  setWhiteList,
  size,
  gridOffsetX,
  gridOffsetY,
  mode,
}: {
  whiteList: Pos[];
  setWhiteList: React.Dispatch<React.SetStateAction<Pos[]>>;
  size: number;
  gridOffsetX: number;
  gridOffsetY: number;
  mode: ModeType | null;
}) => {
  return (
    <>
      {whiteList.map((cell, i) => {
        return (
          <Rect
            key={"white-" + i}
            x={cell.x * size + gridOffsetX}
            y={cell.y * size + gridOffsetY}
            width={size}
            height={size}
            fill="#afa"
            onClick={(e) => {
              if (mode !== "white-square") {
                return;
              }
              const newWhiteList = [...whiteList];
              newWhiteList.splice(i, 1);
              setWhiteList(newWhiteList);
            }}
          />
        );
      })}
    </>
  );
};
