export const frypanDictionary = [
    "あしふぇち",
    "あとふかいひとやお",
    "あり",
    "あるてぃめっと",
    "あるてぃめっとくろすおーばーももたろう",
    "いれぶん",
    "いろのなぞ",
    "うるとら",
    "うるとらばいおれっと",
    "えっぐもばごりら",
    "えぬえふ",
    "おうじ",
    "おおかみ",
    "おしのこ",
    "おもちゃばこ",
    "かいぶんしょ",
    "ぎゃーてーるみねっせんす",
    "きんこ",
    "きんべんろんぶ",
    "くろーずどさーくる",
    "くろすおーばー",
    "げんてんかいき",
    "ごうがしゃ",
    "ごうがしゃがしゃ",
    "ごじゅうおんひょう",
    "ごしょく",
    "ごしょくなぞ",
    "こんぱんは",
    "さいころ",
    "ざこほどのてにねずめろん",
    "さてらいと",
    "しあわせ",
    "しあわせのおうじ",
    "しょうたいじょう",
    "しょくにん",
    "しんらばんしょうまーけっと",
    "しんらばんしょうまーけっとつー",
    "すいーと",
    "すいーとのべんばーぶるーす",
    "せいきひょうげん",
    "せいなるおくりもの",
    "ちょぞうこ",
    "つめあわせ",
    "でぃーでぃーでぃー",
    "てくのろじー",
    "てっぱん",
    "どせい",
    "とっきゅうじゅぶつ",
    "とどうふけん",
    "とどうふけんはあんきしろ",
    "なぞときいんくろすわーど",
    "なぞときおもちゃばこ",
    "なぞときにもあながあるんだよね",
    "なぞとなつやすみ",
    "なぞのつめあわせ",
    "なぞまみれ",
    "なぞまみれつー",
    "なつやすみ",
    "ななふしぎ",
    "なんなんもんもんなぞなぞ",
    "ねこぱん",
    "ねこみち",
    "ねこみちだいさくせん",
    "のうき",
    "のうきんぐ",
    "のべんばー",
    "ばいおれっと",
    "ぱすてる",
    "はらぺこ",
    "はらぺこありとひみつのいえ",
    "ばんえいけいば",
    "ぱんぐらむ",
    "ぱんこ",
    "ひあみぎょ",
    "ふうらいおりひと",
    "ふうらいはんじ",
    "ふじなみこかげ",
    "ふらいどぽてと",
    "ふらいぱん",
    "ふらいぱんしょくにん",
    "ふりだし",
    "ぶるーす",
    "ほうきょう",
    "まつりはみじかしだっしゅつせよおとめ",
    "みすていく",
    "むらさき",
    "めでぃかる",
    "めでぃかるてくのろじー",
    "もくしろく",
    "ももたろう",
    "もらとりあむ",
    "もらとりあむからのだっしゅつ",
    "ゆうりょうふらいぱんなぞ",
    "ゆうりょうふらいぱんなぞつー",
    "よるとぼくらとななつのふしぎ",
    "よるなな",
    "りすえん",
    "るーる",
    "るーるおぶるーる",
    "ろんりぱずる",
];
