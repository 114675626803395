import { Text, View } from "@react-pdf/renderer";
import axios from "axios";
import { useEffect, useState } from "react";
import { SvgXmlToComponent } from "./lib/XmlToReactPdfSvgComponent";
import { styles } from "../style";

type SvgContentsProps = {
  url: string;
  width: number | "auto";
  height: number | "auto";
};

export const SvgComponents = (props: SvgContentsProps) => {
  const [svg, setSvg] = useState(null as JSX.Element | null);
  useEffect(() => {
    if (!props.url.startsWith("https://puzz.link/p?")) {
      return;
    }
    const path = props.url.replace("https://puzz.link/p?", "");
    axios
      .post(
        `https://us-central1-puzsq-studio.cloudfunctions.net/getPuzzlinkSVGImage/${path}`
      )
      .then((res) => {
        const binaryData = res.data.image.data;
        const svgXml = binaryData
          .map((byte: number) => String.fromCharCode(byte))
          .join("") as string;
        console.log(svgXml);

        const svg = SvgXmlToComponent({
          xml: svgXml,
          width: props.width === "auto" ? undefined : props.width,
          height: props.height === "auto" ? undefined : props.height,
        });
        console.log(svg);
        setSvg(svg);
      });
  }, [props.url, props.width, props.height]);
  return <View style={styles.svg}>{svg ?? <Text>loading...</Text>}</View>;
};
