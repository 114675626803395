import { getDoc, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirebaseConfig } from "../../../EniverseProvider";

type DocArg<T> = {
  path: string;
  initialData?: T;
};

const useDocOnce = <T>({
  path,
}: DocArg<T>): [T | undefined, (data: T) => void, boolean] => {
  const [data, setLocalData] = useState<T>();
  const [{ cloudFirestore }] = useFirebaseConfig();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!cloudFirestore) {
      return;
    }
    const fetchData = async () => {
      const docSnapshot = await getDoc(doc(cloudFirestore, path));
      if (docSnapshot.exists()) {
        setLocalData(docSnapshot.data() as T);
      }
      setLoading(false);
    };
    fetchData();
  }, [path]);

  const setData = (data: T) => {
    if (!cloudFirestore) {
      return;
    }
    setDoc(doc(cloudFirestore, path), data as any);
  };

  return [data, setData, loading];
};

export { useDocOnce };
