import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { useNavigate } from "react-router";

export type MenuItem = {
  title: string;
  category?: string;
  description?: string;
  path: string;
  buttonName?: string;
  proTool?: boolean;
  lab?: boolean;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  openExternal?: boolean;
  media?: string;
  author?: {
    name: string;
    avatar: string;
    description: string;
  };
};

type CommonMenuItemProps = {
  menuItem: MenuItem;
};
export const CommonMenuItem: FC<CommonMenuItemProps> = ({ menuItem }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card sx={{ minWidth: 275, maxWidth: "350px", my: 2 }}>
        {menuItem.media && (
          <CardMedia
            component="img"
            src={menuItem.media}
            sx={{
              width: "100%",
              objectFit: "contain",
            }}
          />
        )}

        <CardActions
          onClick={() => {
            if (menuItem.openExternal) {
              window.open(menuItem.path, "_blank");
              return;
            } else {
              navigate(menuItem.path);
            }
          }}
          sx={{
            width: "100%",
            cursor: "pointer",
            flexDirection: "column",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
            }}
          >
            <Typography variant="h5" component="div">
              {menuItem.title}
              {menuItem.category && (
                <Chip
                  label={menuItem.category}
                  color="primary"
                  sx={{
                    ml: 2,
                  }}
                />
              )}
            </Typography>

            <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
            {menuItem.Icon && (
              <menuItem.Icon
                sx={{
                  color: "#444",
                  fontSize: 100,
                }}
              />
            )}

            {menuItem.description && (
              <Typography variant="body2">{menuItem.description}</Typography>
            )}
          </CardContent>
          {menuItem.author && (
            <CardHeader
              sx={{
                borderTop: "1px solid #ddd",
                mt: 1,
                width: "100%",
              }}
              sizes="small"
              avatar={<Avatar src={menuItem.author.avatar} sizes="small" />}
              title={menuItem.author.name}
              subheader={menuItem.author.description}
            />
          )}
        </CardActions>
      </Card>
    </>
  );
};
