import * as React from "react";
import Typography from "@mui/material/Typography";

interface TitleProps {
  children?: React.ReactNode;
}

export const Title = (props: TitleProps) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
};

export const Subtitle = (props: TitleProps) => {
  return (
    <Typography component="h3" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
};
