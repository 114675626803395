import { Checkbox, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { DictionarySelector } from "../../../../commonStudio/DictionarySelector";
import { BasicFormProps } from "../FormProps";
import { DictionaryMatchFilter } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const DictionaryMatchForm: FC<BasicFormProps<DictionaryMatchFilter>> = ({
  index,
  query,
  updateQuery,
  deleteQueryField,
  isDemo,
}) => {
  return (
    <>
      <DictionarySelector
        labelName="辞書"
        dictionary={query.dictionary}
        setDictionary={(value) => {
          updateQuery(index, "dictionary", value);
        }}
        isDemo={isDemo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={false}
            disabled={isDemo}
            onChange={(e) => {
              updateQuery(index, "type", "anagramDictionaryMatch");
              deleteQueryField(index, "matchEstimationValue");
              deleteQueryField(index, "matchEstimationOperation");
              deleteQueryField(index, "unmatchEstimationValue");
              deleteQueryField(index, "unmatchEstimationOperation");
            }}
          />
        }
        sx={{ ml: 1 }}
        label="アナグラムマッチ変換"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.notMatch || false}
            disabled={isDemo}
            onChange={(e) => updateQuery(index, "notMatch", e.target.checked)}
          />
        }
        sx={{ ml: 1 }}
        label="否定マッチ"
      />
    </>
  );
};
