import { maxWidth, maxHeight } from "@mui/system";
import { FC } from "react";
import { Stage, Layer, Rect } from "react-konva";
import { PuzzleView } from "./PuzzleView";

type Props = {
  maxWidth: number;
  maxHeight: number;
  scale: number;
};

export const GridKitView: FC<Props> = (props) => {
  const { maxWidth, maxHeight, scale } = props;
  return (
    <Stage width={maxWidth} height={maxHeight} scaleX={scale} scaleY={scale}>
      <Layer>
        <Rect
          x={0}
          y={0}
          width={maxWidth / scale}
          height={maxHeight / scale}
          fill="#d8d8d8"
        />
      </Layer>
      <PuzzleView width={maxWidth} height={maxHeight} />
    </Stage>
  );
};
