export const pokemon5 = `ビクティニ
ツタージャ
ジャノビー
ジャローダ
ポカブ
チャオブー
エンブオー
ミジュマル
フタチマル
ダイケンキ
ミネズミ
ミルホッグ
ヨーテリー
ハーデリア
ムーランド
チョロネコ
レパルダス
ヤナップ
ヤナッキー
バオップ
バオッキー
ヒヤップ
ヒヤッキー
ムンナ
ムシャーナ
マメパト
ハトーボー
ケンホロウ
シママ
ゼブライカ
ダンゴロ
ガントル
ギガイアス
コロモリ
ココロモリ
モグリュー
ドリュウズ
タブンネ
ドッコラー
ドテッコツ
ローブシン
オタマロ
ガマガル
ガマゲロゲ
ナゲキ
ダゲキ
クルミル
クルマユ
ハハコモリ
フシデ
ホイーガ
ペンドラー
モンメン
エルフーン
チュリネ
ドレディア
バスラオ
メグロコ
ワルビル
ワルビアル
ダルマッカ
ヒヒダルマ
マラカッチ
イシズマイ
イワパレス
ズルッグ
ズルズキン
シンボラー
デスマス
デスカーン
プロトーガ
アバゴーラ
アーケン
アーケオス
ヤブクロン
ダストダス
ゾロア
ゾロアーク
チラーミィ
チラチーノ
ゴチム
ゴチミル
ゴチルゼル
ユニラン
ダブラン
ランクルス
コアルヒー
スワンナ
バニプッチ
バニリッチ
バイバニラ
シキジカ
メブキジカ
エモンガ
カブルモ
シュバルゴ
タマゲタケ
モロバレル
プルリル
ブルンゲル
ママンボウ
バチュル
デンチュラ
テッシード
ナットレイ
ギアル
ギギアル
ギギギアル
シビシラス
シビビール
シビルドン
リグレー
オーベム
ヒトモシ
ランプラー
シャンデラ
キバゴ
オノンド
オノノクス
クマシュン
ツンベアー
フリージオ
チョボマキ
アギルダー
マッギョ
コジョフー
コジョンド
クリムガン
ゴビット
ゴルーグ
コマタナ
キリキザン
バッフロン
ワシボン
ウォーグル
バルチャイ
バルジーナ
クイタラン
アイアント
モノズ
ジヘッド
サザンドラ
メラルバ
ウルガモス
コバルオン
テラキオン
ビリジオン
トルネロス
ボルトロス
レシラム
ゼクロム
ランドロス
キュレム
ケルディオ
メロエッタ
ゲノセクト`;
