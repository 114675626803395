import { Menu as MenuIcon } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { User, signOut } from "firebase/auth";
import { FC, useState } from "react";
import { MaterialUISwitch } from "./MaterialUISwitch";
import { drawerWidth } from "./Wrapper";
import { useFirebaseConfig } from "../../../_lib/eniverse/EniverseProvider";
import { useCommonRouter } from "../../../_lib/router/cra/useCommonRouter";

type CommonAppBarProps = {
  open: boolean;
  handleDrawerOpen: () => void;
  plan: string;
  service: "gamein" | "studio";
  themeMode: "light" | "dark";
  setThemeMode: (themeMode: "light" | "dark") => void;
  user?: User | null;
};

export const getServiceName = ({
  service,
  plan,
}: {
  service: "gamein" | "studio";
  plan: string;
}) => {
  const serviceName = service === "gamein" ? "GAMEIN" : "Studio";

  const planToName = (plan: string) => {
    return (
      {
        pro: "Pro",
        "pro+": "Pro+",
        debug: "Debug Mode",
        biz: "Works",
        "biz-trial": "Works",
        cloudplus: "Cloud",
        cloud: "Cloud",
        lc: "LC",
        "lc-pro+": "PPLC",
        "lc-pro": "PLC",
      }[plan] || ""
    );
  };
  return (
    <>
      Enigma{serviceName} {planToName(plan)}
    </>
  );
};

export const getLongServiceName = ({
  service,
  plan,
}: {
  service: "gamein" | "studio";
  plan: string;
}) => {
  const serviceName = service === "gamein" ? "GAMEIN" : "Studio";

  const planToName = (plan: string) => {
    return (
      {
        pro: "Pro",
        "pro+": "Pro+",
        debug: "Debug Mode",
        biz: "Works",
        "biz-trial": "Works",
        cloudplus: "Cloud Premium",
        cloud: "Cloud",
        lc: "Limited Cloud",
        "lc-pro+": "Pro+ Limited Cloud",
        "lc-pro": "Pro Limited Cloud",
      }[plan] || ""
    );
  };
  return (
    <>
      Enigma{serviceName}{" "}
      <span style={{ fontSize: "66%" }}>{planToName(plan)}</span>
    </>
  );
};

export const getStudioServiceDescription = ({ plan }: { plan: string }) =>
  plan === "pro" ? (
    <>最先端の謎構築プラットフォーム</>
  ) : plan === "pro+" ? (
    <>最先端の謎構築プラットフォーム</>
  ) : plan === "debug" ? (
    <>最先端の謎構築プラットフォーム(開発中)</>
  ) : plan === "cloud" ? (
    <>個人向け謎制作環境</>
  ) : plan === "lc" ? (
    <>個人向け謎制作環境</>
  ) : plan === "lc-pro" ? (
    <>個人向け謎制作環境</>
  ) : plan === "lc-pro+" ? (
    <>個人向け謎制作環境</>
  ) : plan === "cloudplus" ? (
    <>個人向け謎制作環境</>
  ) : plan === "biz" ? (
    <>業務・チーム向け謎制作環境</>
  ) : plan === "biz-trial" ? (
    <>業務・チーム向け謎制作環境 (トライアル)</>
  ) : (
    <>謎解き制作支援サービス＆プラットフォーム</>
  );

export const CommonAppBar: FC<CommonAppBarProps> = ({
  open,
  handleDrawerOpen,
  plan,
  service,
  themeMode,
  setThemeMode,
  user,
}) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [{ firebaseAuth }] = useFirebaseConfig();
  const router = useCommonRouter();

  return (
    <AppBar position="static" open={open}>
      <Toolbar variant="dense">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            userSelect: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            router.push("/");
          }}
        >
          {getServiceName({ service, plan })}
        </Typography>
        <MaterialUISwitch
          sx={{ m: 1 }}
          checked={themeMode === "dark"}
          onChange={() => {
            setThemeMode(themeMode === "dark" ? "light" : "dark");
          }}
        />
        {/*<Button color="inherit">Login</Button>*/}
        {user && (
          <>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: "auto" }}>
              {user.photoURL ? (
                <Avatar alt="user" src={user.photoURL} />
              ) : (
                <Avatar alt="user">{user.displayName?.charAt(0)}</Avatar>
              )}
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography
                sx={{ p: 2 }}
                variant="h6"
                color={themeMode === "dark" ? "text.primary" : "text.secondary"}
              >
                {user.displayName}
              </Typography>
              <Typography sx={{ p: 2 }} variant="caption">
                {user.email}
              </Typography>
              <MenuItem
                onClick={() => {
                  if (!firebaseAuth) {
                    return;
                  }
                  signOut(firebaseAuth);
                }}
                disabled={!firebaseAuth}
              >
                ログアウト
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
