import {
  DictionaryKey,
  FunctionSearchProgress,
  GeneralSearchInput,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  AnagramProgress,
  CryptoArithmeticSearchProgress,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import { AnagramPayload } from "@langue-de-chat-llc/enigmastudio-algorithm/dist/worker/messageType";
import { EnigmaStudioContext } from "../components/EnigmaHouse/context/EnigmaStudioContext";
import { useContext } from "react";

export const useWorker = () => {
  const { workerInstance } = useContext(EnigmaStudioContext);
  const load = (
    dictionary: DictionaryKey,
    hasAll: boolean,
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "load",
      payload: {
        dictionary,
        hasAll,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const loadCustom = (
    text: string,
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "loadCustom",
      payload: {
        text,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const loadCustomArr = (
    items: string[],
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;

    workerInstance.postMessage({
      type: "loadCustomArr",
      payload: {
        items,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const unload = (dictionary: DictionaryKey, hasAll?: boolean) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "unload",
      payload: { dictionary, hasAll },
    });
  };

  const loadText = (
    key: string,
    text: string,
    onComplete?: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "loadText",
      payload: {
        key,
        text,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      console.log("loadText", result);
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const wordMatching = (
    keywords: string,
    wordLength: number,
    dictionary: DictionaryKey,
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;
    console.log(keywords, wordLength, dictionary);
    workerInstance.postMessage({
      type: "wordMatching",
      payload: {
        keywords,
        wordLength,
        dictionary,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      onComplete(result);
    };
  };

  const regexSearch = (
    keyword: string,
    dictionary: DictionaryKey,
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "regexSearch",
      payload: {
        keyword,
        dictionary,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      onComplete(result);
    };
  };

  const numToWordSearch = (
    value: number,
    pattern: string,
    dictionary: DictionaryKey,
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "numToWordSearch",
      payload: {
        value,
        pattern,
        dictionary,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      onComplete(result);
    };
  };

  const chimataSearch = (
    payload: {
      keyword: string;
      addSize: number;
      dictionary: DictionaryKey;
    },
    onComplete: (result: string[] | undefined) => void
  ) => {
    if (!workerInstance) return;

    console.log("start chimata");
    workerInstance.postMessage({
      type: "chimataSearch",
      payload,
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{ result: string[] | undefined }>
    ) => {
      const { result } = ev.data;
      console.log(result);
      onComplete(result);
    };
  };

  const cryptoArithmetic = (
    input1: string,
    solutionCount: number,
    onComplete: (result: string[] | undefined) => void,
    onProgress: (progress: CryptoArithmeticSearchProgress) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "cryptoArithmetic",
      payload: {
        input1,
        solutionCount,
      },
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{
        result: string[] | undefined;
        progress: CryptoArithmeticSearchProgress | undefined;
        abort: boolean | undefined;
      }>
    ) => {
      if (!workerInstance) return;
      const { result, progress } = ev.data;
      if (result) {
        onComplete(result);
      }
      if (progress) {
        onProgress(progress);
      }
    };
  };

  const multipleAnagram = (
    payload: AnagramPayload,
    onComplete: (result: string[] | undefined) => void,
    onProgress: (progress: AnagramProgress) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "multipleAnagram",
      payload,
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{
        result: string[] | undefined;
        progress: AnagramProgress | undefined;
        abort: boolean | undefined;
      }>
    ) => {
      const { result, progress } = ev.data;
      if (result) {
        onComplete(result);
      }
      if (progress) {
        onProgress(progress);
      }
    };
  };

  const generalSearch = (
    payload: {
      input: GeneralSearchInput;
    },
    onComplete: (
      result: { s: string }[] | undefined,
      payload: { input: GeneralSearchInput }
    ) => void,
    onProgress: (
      progress: FunctionSearchProgress | undefined,
      payload: { input: GeneralSearchInput }
    ) => void
  ) => {
    if (!workerInstance) return;
    workerInstance.postMessage({
      type: "generalSearch",
      payload,
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{
        result: { s: string }[] | undefined;
        progress: FunctionSearchProgress | undefined;
        abort: boolean | undefined;
      }>
    ) => {
      const { result, progress } = ev.data;
      if (result) {
        onComplete(result, payload);
      }
      if (progress) {
        onProgress(progress, payload);
      }
    };
  };

  const abort = () => {
    if (!workerInstance) return;

    workerInstance.postMessage({
      type: "abort",
    });
  };

  return {
    load,
    loadCustom,
    loadCustomArr,
    unload,
    loadText,
    chimataSearch,
    wordMatching,
    regexSearch,
    cryptoArithmetic,
    numToWordSearch,
    multipleAnagram,
    generalSearch,
    abort,
  };
};
