import AppsIcon from "@mui/icons-material/Apps";
import CalculateIcon from "@mui/icons-material/Calculate";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import HubIcon from "@mui/icons-material/Hub";
import ImageIcon from "@mui/icons-material/Image";
import LayersIcon from "@mui/icons-material/Layers";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import SchemaIcon from "@mui/icons-material/Schema";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import SettingsIcon from "@mui/icons-material/Settings";
import ExtensionIcon from "@mui/icons-material/Extension";
import { FC } from "react";
import { FaBook, FaStamp, FaTrophy, FaTwitter } from "react-icons/fa";
import { TbMovie } from "react-icons/tb";

type Props = {
  droppable: boolean;
  fileType?: string;
  sx?: any;
};

type FileTypeProps = {
  fileType: string;
  sx?: any;
};

export const FileTypeIcon: FC<FileTypeProps> = (props) => {
  const { fileType, sx = {} } = props;
  switch (fileType) {
    case "puzsq-contest-folder":
    case "puzsq-contest-problem-folder":
      return <FolderIcon sx={sx} />;
    case "puzsq-contest-manager":
      return <SettingsIcon sx={sx} />;
    case "puzsq-contest-problem-item":
      return <ExtensionIcon sx={sx} />;
    case "puzsq-contest-app":
      return <AppsIcon sx={sx} />;
    default:
      return null;
  }
};

export const TypeIcon: FC<Props> = (props) => {
  const { sx } = props;

  if (!props.fileType) {
    return <AppsIcon sx={sx} />;
  }
  const ret = <FileTypeIcon fileType={props.fileType} sx={sx} />;
  if (ret) {
    return ret;
  }
  if (props.droppable) {
    return <FolderIcon sx={sx} />;
  }
  return null;
};
