import { textLengthFast } from "../../../wordGenerator/textLength";
import { estimateFilter, filter } from "../commonLoop";
export const lengthFilter = async ({ query, input, result, workerContext, }) => {
    const minLength = query.minLength ?? "";
    const maxLength = query.maxLength ?? "";
    const filterFunc = (e) => {
        return ((minLength === "" || textLengthFast(e.currentWord) >= minLength) &&
            (maxLength === "" || textLengthFast(e.currentWord) <= maxLength));
    };
    if (input.estimation) {
        return estimateFilter(workerContext, result, filterFunc, input.estimation, query);
    }
    return filter(workerContext, result, filterFunc);
};
