import { Direction } from "./direction";

export type RectPos = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Pos = {
  x: number;
  y: number;
};

export type WordObj = {
  word: string;
  x: number; // 座標の値
  y: number; // 座標の値
  index: number;
  direction: Direction;
};

export type WhiteLineObj = {
  x: number; // 座標の値
  y: number; // 座標の値
  index: number;
  length: number;
  direction: Direction;
};

export type CrossPoint = {
  x: number;
  y: number;
  rightIndex: number;
  downIndex: number;
};

export type WhiteLineCandidate = {
  index: number;
  reverse: boolean;
};

export const twoDirection: Direction[] = ["right", "down"];
export const fourDirection: Direction[] = ["right", "down", "left", "up"];
export const eightDirection: Direction[] = [
  "right",
  "right-down",
  "down",
  "left-down",
  "left",
  "left-up",
  "up",
  "right-up",
];
