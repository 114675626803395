import { useCallback, useEffect, useState } from "react";
import {
  CrossPoint,
  WhiteLineCandidate,
  WhiteLineObj,
  WordObj,
} from "../gridToolType";
import { hiraganaToKatakana } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const useSolveSkeleton = ({
  whiteLines,
  wordList,
  rightIndexCrossPointTable,
  downIndexCrossPointTable,
  dragging,
  cursorText,
  cursorTextKana,
  realtimeSkeletonSearch,
}: {
  whiteLines: WhiteLineObj[];
  wordList: WordObj[];
  rightIndexCrossPointTable: { [key: number]: CrossPoint[] } | undefined;
  downIndexCrossPointTable: { [key: number]: CrossPoint[] } | undefined;
  dragging: boolean;
  cursorText: { [key: string]: string };
  cursorTextKana: { [key: string]: string };
  realtimeSkeletonSearch: boolean;
}) => {
  const [whiteLineCandidateList, setWhiteLineCandidateList] = useState<
    WhiteLineCandidate[][]
  >([]);

  const [wordCandidateList, setWordCandidateList] = useState<number[][]>([]);

  const solveSkeleton = useCallback(() => {
    // add candidate word list
    if (
      !wordList ||
      !whiteLines ||
      rightIndexCrossPointTable === undefined ||
      downIndexCrossPointTable === undefined ||
      !wordCandidateList
    ) {
      return;
    }
    if (dragging) {
      return;
    }
    const _whiteLineCandidateList: WhiteLineCandidate[][] = [];

    for (const whiteLine of whiteLines) {
      const { length } = whiteLine;
      let candidateWordList = wordList.filter(
        (wordObj) => wordObj.word.length === length
      );

      // check coord / cursorText
      for (let i = 0; i < length; i++) {
        const x = whiteLine.x + (whiteLine.direction === "right" ? i : 0);
        const y = whiteLine.y + (whiteLine.direction === "down" ? i : 0);
        const char = cursorText[`${x}-${y}`];
        const charKana = cursorTextKana[`${x}-${y}`];
        if (char) {
          candidateWordList = candidateWordList.filter((word) => {
            return (
              word.word[i] === char ||
              word.word[i] === charKana ||
              word.word[i] === hiraganaToKatakana(charKana)
            );
          });
        }
      }

      const candidateWordIndexList = candidateWordList.map((e) => ({
        index: e.index,
        reverse: false,
      }));
      _whiteLineCandidateList[whiteLine.index] = candidateWordIndexList;
    }

    // solve
    // whiteLinesに対してループを回す、CrossPointTableを参照して、候補を絞り込む
    const fixedTable: { [key: number]: number } = {};
    for (const whiteLine of whiteLines) {
      const cand = _whiteLineCandidateList[whiteLine.index];
      if (cand.length === 1) {
        fixedTable[cand[0].index] = whiteLine.index;
      }
    }

    let isChanged = false;
    do {
      isChanged = false;
      for (const whiteLine of whiteLines) {
        const beforeSize = _whiteLineCandidateList[whiteLine.index].length;
        if (beforeSize === 1) {
          if (
            fixedTable[_whiteLineCandidateList[whiteLine.index][0].index] ===
            undefined
          ) {
            fixedTable[_whiteLineCandidateList[whiteLine.index][0].index] =
              whiteLine.index;
            isChanged = true;
          }
          continue;
        }

        // 交点がある場合は、交点の右側の単語の候補を絞り込む
        const crossPoints = (
          whiteLine.direction === "down"
            ? downIndexCrossPointTable
            : rightIndexCrossPointTable
        )[whiteLine.index];

        if (!crossPoints) {
          continue;
        }
        for (const crossPoint of crossPoints) {
          const candidateWordList = _whiteLineCandidateList[
            whiteLine.index
          ].map((e) => wordList[e.index]);
          const characters = candidateWordList.map((wordObj) => {
            const char =
              whiteLine.direction === "right"
                ? wordObj.word[crossPoint.x - whiteLine.x]
                : wordObj.word[crossPoint.y - whiteLine.y];
            return { i: wordObj.index, c: char };
          });
          const oppositeIndex =
            whiteLine.direction === "down"
              ? crossPoint.rightIndex
              : crossPoint.downIndex;
          const oppositeLine = whiteLines[oppositeIndex];
          const oppositeCharacters = _whiteLineCandidateList[
            oppositeLine.index
          ].map(({ index }) => {
            const wordObj = wordList[index];
            const char =
              oppositeLine.direction === "right"
                ? wordObj.word[crossPoint.x - oppositeLine.x]
                : wordObj.word[crossPoint.y - oppositeLine.y];
            return { i: index, c: char };
          });
          const next = characters.filter((e) =>
            oppositeCharacters.some(
              (e2) =>
                (fixedTable[e.i] ?? whiteLine.index) === whiteLine.index &&
                (fixedTable[e2.i] ?? oppositeLine.index) ===
                  oppositeLine.index &&
                e.i !== e2.i &&
                e.c === e2.c
            )
          );
          _whiteLineCandidateList[whiteLine.index] = next.map((e) => ({
            index: e.i,
            reverse: false,
          }));
        }
        if (beforeSize !== _whiteLineCandidateList[whiteLine.index].length) {
          isChanged = true;
        }
      }

      const _wordCandidateList: number[][] = [];
      for (const wordObj of wordList) {
        _wordCandidateList[wordObj.index] = [];
      }
      for (const whiteLine of whiteLines) {
        const cand = _whiteLineCandidateList[whiteLine.index];
        for (const c of cand) {
          _wordCandidateList[c.index].push(whiteLine.index);
        }
      }

      setWhiteLineCandidateList(_whiteLineCandidateList);
      setWordCandidateList(_wordCandidateList);
      console.log(_whiteLineCandidateList, _wordCandidateList);
    } while (isChanged);
  }, [
    whiteLines,
    wordList,
    rightIndexCrossPointTable,
    downIndexCrossPointTable,
    wordCandidateList,
    dragging,
    cursorText,
    cursorTextKana,
  ]);

  useEffect(() => {
    setWhiteLineCandidateList([]);
    setWordCandidateList([]);
    if (realtimeSkeletonSearch) {
      solveSkeleton();
    }
  }, [
    realtimeSkeletonSearch,
    wordList,
    whiteLines,
    rightIndexCrossPointTable,
    downIndexCrossPointTable,
    cursorText,
    cursorTextKana,
    solveSkeleton,
  ]);

  return {
    whiteLineCandidateList,
    wordCandidateList,
    solveSkeleton,
    setWhiteLineCandidateList,
    setWordCandidateList,
  };
};
