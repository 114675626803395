export const pokemon4 = `ナエトル
ハヤシガメ
ドダイトス
ヒコザル
モウカザル
ゴウカザル
ポッチャマ
ポッタイシ
エンペルト
ムックル
ムクバード
ムクホーク
ビッパ
ビーダル
コロボーシ
コロトック
コリンク
ルクシオ
レントラー
スボミー
ロズレイド
ズガイドス
ラムパルド
タテトプス
トリデプス
ミノムッチ
ミノマダム
ガーメイル
ミツハニー
ビークイン
パチリス
ブイゼル
フローゼル
チェリンボ
チェリム
カラナクシ
トリトドン
エテボース
フワンテ
フワライド
ミミロル
ミミロップ
ムウマージ
ドンカラス
ニャルマー
ブニャット
リーシャン
スカンプー
スカタンク
ドーミラー
ドータクン
ウソハチ
マネネ
ピンプク
ペラップ
ミカルゲ
フカマル
ガバイト
ガブリアス
ゴンベ
リオル
ルカリオ
ヒポポタス
カバルドン
スコルピ
ドラピオン
グレッグル
ドクロッグ
マスキッパ
ケイコウオ
ネオラント
タマンタ
ユキカブリ
ユキノオー
マニューラ
ジバコイル
ベロベルト
ドサイドン
モジャンボ
エレキブル
ブーバーン
トゲキッス
メガヤンマ
リーフィア
グレイシア
グライオン
マンムー
ポリゴンZ
エルレイド
ダイノーズ
ヨノワール
ユキメノコ
ロトム
ユクシー
エムリット
アグノム
ディアルガ
パルキア
ヒードラン
レジギガス
ギラティナ
クレセリア
フィオネ
マナフィ
ダークライ
シェイミ
アルセウス`;
