import { createTake } from "../take";
import { word4kana } from "../word4kana";

export const num2kana = {
  id: "num2kana",
  title: "ひらがな何番目ゲーム",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「6 2 19 46」→「かいてん」のように
      <br />
      五十音順の数字をひらがなに変換した単語を答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「6 2 19 46」→「かいてん」のように
      <br />
      五十音順の数字をひらがなに変換した単語を答えます。
      <br />
      全部で10問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () =>
    createTake(
      word4kana.map((e) => ({
        problemText: e[0],
        answers: [e[1]],
      })),
      10
    ),
  shareButton: true,
  hashTag: "ひらがな何番目ゲーム",
};