import {
  dictionaries,
  DictionaryKey,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Fragment, useContext } from "react";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { getFileChip } from "../../../CloudItem/getFileChip";
import { mul } from "@excalidraw/excalidraw/types/ga";
import { If } from "../../../../../../_lib/eniverse/util/Condition";

export const GeneralSearchInputDictionarySelector = ({
  selectedDictionaries,
  setSelectedDictionaries,
  displayLabel = true,
}: {
  selectedDictionaries: string[];
  setSelectedDictionaries: (value: string[]) => void;
  displayLabel?: boolean;
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const { multiSelect, setMultiSelect } = ctx;
  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>複数選択</Typography>
        <Switch
          checked={multiSelect}
          onChange={() => setMultiSelect((prev) => !prev)}
        />
      </Stack>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          デフォルト辞書
        </Typography>
        <Box>
          <If condition={multiSelect}>
            <Checkbox
              checked={
                selectedDictionaries.length === ctx.enabledDictionary.length
              }
              indeterminate={
                selectedDictionaries.length > 0 &&
                selectedDictionaries.length < ctx.enabledDictionary.length
              }
              onChange={() => {
                if (
                  selectedDictionaries.length === ctx.enabledDictionary.length
                ) {
                  setSelectedDictionaries([]);
                } else {
                  setSelectedDictionaries(ctx.enabledDictionary);
                }
              }}
            />
          </If>
          {ctx.enabledDictionary
            .sort(
              (a, b) =>
                dictionaries[a as DictionaryKey].index -
                dictionaries[b as DictionaryKey].index
            )
            .filter((item) => item !== "all")
            .map((item: DictionaryKey, i) => {
              const fileChipType = selectedDictionaries.includes(item)
                ? "selected"
                : "loaded";
              const { color, variant } = getFileChip(fileChipType);
              return (
                <Fragment key={item}>
                  <Chip
                    key={item}
                    variant={variant}
                    color={color}
                    label={dictionaries[item].name}
                    sx={{
                      mb: 1,
                      mr: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (multiSelect) {
                        if (selectedDictionaries.includes(item)) {
                          setSelectedDictionaries(
                            selectedDictionaries.filter((e) => e !== item)
                          );
                        } else {
                          setSelectedDictionaries([
                            ...selectedDictionaries,
                            item,
                          ]);
                        }
                      } else {
                        if (selectedDictionaries.includes(item)) {
                          setSelectedDictionaries([]);
                        } else {
                          setSelectedDictionaries([item]);
                        }
                      }
                    }}
                  />
                </Fragment>
              );
            })}
        </Box>
      </Box>
    </Stack>
  );
};
