import { memo, useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const SelectedFileCard = memo(
  ({ file, onRemove }: { file: File; onRemove: () => void }) => {
    const imageUrl = useMemo(() => {
      if (file.type.startsWith("image")) {
        return URL.createObjectURL(file);
      }
      return "";
    }, [file]);
    return (
      <Card sx={{ maxWidth: 200, mr: 1, mt: 1 }}>
        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <IconButton
            aria-label="delete"
            onClick={onRemove}
            sx={{
              ml: "auto",
              mt: 1,
              mr: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <CardMedia
          component="img"
          height="200"
          image={imageUrl}
          alt={file.name}
          sx={{
            objectFit: "contain",
          }}
        />
        <CardContent>
          <Typography gutterBottom variant="caption" component="div">
            {file.name}
          </Typography>
        </CardContent>
      </Card>
    );
  }
);
SelectedFileCard.displayName = "SelectedFileCard";
