export const pokemon8 = `サルノリ
バチンキー
ゴリランダー
ヒバニー
ラビフット
エースバーン
メッソン
ジメレオン
インテレオン
ホシガリス
ヨクバリス
ココガラ
アオガラス
アーマーガア
サッチムシ
レドームシ
イオルブ
クスネ
フォクスライ
ヒメンカ
ワタシラガ
ウールー
バイウールー
カムカメ
カジリガメ
ワンパチ
パルスワン
タンドン
トロッゴン
セキタンザン
カジッチュ
アップリュー
タルップル
スナヘビ
サダイジャ
ウッウ
サシカマス
カマスジョー
エレズン
ストリンダー
ヤクデ
マルヤクデ
タタッコ
オトスパス
ヤバチャ
ポットデス
ミブリム
テブリム
ブリムオン
ベロバー
ギモー
オーロンゲ
タチフサグマ
ニャイキング
サニゴーン
ネギガナイト
バリコオル
デスバーン
マホミル
マホイップ
タイレーツ
バチンウニ
ユキハミ
モスノウ
イシヘンジン
コオリッポ
イエッサン
モルペコ
ゾウドウ
ダイオウドウ
パッチラゴン
パッチルドン
ウオノラゴン
ウオチルドン
ジュラルドン
ドラメシヤ
ドロンチ
ドラパルト
ザシアン
ザマゼンタ
ムゲンダイナ
ダクマ
ウーラオス
ザルード
レジエレキ
レジドラゴ
ブリザポス
レイスポス
バドレックス`;
