import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export type TypeData = Array<{
  name: string;
  type: "string" | "number" | "boolean";
}>;

type GeneralFormProps = {
  type: "string" | "number" | "boolean";
  content: any;
  setContent: (content: any) => void;
  label: string;
};

type GeneralArrayFormProps = {
  type: "array";
  content: any;
  setContent: (content: any) => void;
  label: string;
  defaultValue: any;
  typeData: TypeData;
};

type GeneralFormBuilderProps = {
  content: any;
  setContent: (content: any) => void;
  label: string;
  typeData: TypeData;
};

export const GeneralFormBuilder: FC<GeneralFormBuilderProps> = (props) => {
  return (
    <>
      {props.typeData.map((item) => {
        switch (item.type) {
          case "string":
          case "number":
          case "boolean":
            return (
              <Box key={props.label + "_" + item.name}>
                <GeneralForm
                  type={item.type}
                  content={props.content?.[item.name]}
                  setContent={(content) => {
                    props.setContent({
                      ...props.content,
                      [item.name]: content,
                    });
                  }}
                  label={props.label + "_" + item.name}
                />
              </Box>
            );
        }
      })}
    </>
  );
};

export const GeneralArrayForm: FC<GeneralArrayFormProps> = (props) => {
  const addArray = (index: number) => {
    const newArray = [...props.content];
    newArray.splice(index, 0, props.defaultValue);
    props.setContent(newArray);
  };
  const removeArray = (index: number) => {
    const newArray = [...props.content];
    newArray.splice(index, 1);
    props.setContent(newArray);
  };
  return (
    <Box>
      {props.content.map((item: any, index: number) => {
        console.log(item, "item", props.typeData, "typeData");
        return (
          <Box key={index}>
            <Grid container>
              <Grid item xs={10}>
                <GeneralFormBuilder
                  typeData={props.typeData}
                  content={item}
                  setContent={(content) => {
                    const newArray = [...props.content];
                    newArray[index] = content;
                    props.setContent(newArray);
                  }}
                  label={props.label + "_" + index}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => addArray(index)}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <IconButton onClick={() => removeArray(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        );
      })}

      <IconButton
        onClick={() => props.setContent([...props.content, props.defaultValue])}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </Box>
  );
};

export const GeneralForm: FC<GeneralFormProps> = (props) => {
  console.log(props.content, "content");
  const [_content, _setContent] = useState(props.content);
  useEffect(() => {
    _setContent(props.content);
  }, [props.content]);
  switch (props.type) {
    case "string":
      return (
        <TextField
          onChange={(e) => {
            _setContent(e.target.value);
          }}
          onBlur={() => {
            (props.setContent as (_: string) => void)(_content as string);
          }}
          value={_content}
          label={props.label}
          fullWidth
          sx={{ mt: 2 }}
        />
      );
    case "number":
      return (
        <TextField
          onChange={(e) => {
            _setContent(parseInt(e.target.value));
          }}
          onBlur={() => {
            (props.setContent as (_: number) => void)(_content as number);
          }}
          value={_content}
          label={props.label}
          type="number"
          sx={{ mt: 2 }}
        />
      );
    case "boolean":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={_content}
                onChange={(e) => {
                  (props.setContent as (_: boolean) => void)(e.target.checked);
                  _setContent(e.target.checked);
                }}
              />
            }
            label={props.label}
          />
        </FormGroup>
      );
    default:
      throw new Error("not implemented");
  }
};
