import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import GradeIcon from "@mui/icons-material/Grade";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router";
import HandymanIcon from "@mui/icons-material/Handyman";
import TimerIcon from "@mui/icons-material/Timer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HomeIcon from "@mui/icons-material/Home";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { hikakinOpen } from "../Gamein/HighAndLow/HighAndLow";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const GameinDrawer: FC<{
  open: boolean;
  handleDrawerClose: () => void;
  drawerWidth: number;
  theme: any;
  planKey: string;
  plan: string;
}> = ({ open, handleDrawerClose, drawerWidth, theme, planKey, plan }) => {
  const nav = useNavigate();

  const navigate = useCallback(
    (path: string) => {
      if (planKey) {
        nav(path + "?plan=" + planKey);
      } else {
        nav(path);
      }
    },
    [planKey, nav]
  );
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      anchor="left"
      open={open}
    >
      <DrawerHeader theme={theme}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem
          button
          key={"sprint-answer"}
          onClick={() => {
            navigate("/g/sprint-answer");
            handleDrawerClose();
          }}
        >
          <ListItemIcon>
            <TimerIcon />
          </ListItemIcon>
          <ListItemText primary={"スプリントアンサー"} />
        </ListItem>
        <ListItem
          button
          key={"misezan"}
          onClick={() => {
            navigate("/g/sprint-answer/misezan");
            handleDrawerClose();
          }}
        >
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText primary={"一桁の見せ算"} />
        </ListItem>
        {hikakinOpen() && (
          <ListItem
            button
            key={"hikakin-marriage"}
            onClick={() => {
              navigate("/g/hikakin-marriage");
              handleDrawerClose();
            }}
          >
            <ListItemIcon>
              <TimerIcon />
            </ListItemIcon>
            <ListItemText primary={"HIKAKIN結婚報告ハイ&ロー"} />
          </ListItem>
        )}
        <Divider />
        <ListItem
          button
          key={"hikakin-marriage"}
          onClick={() => {
            navigate("/g");
            handleDrawerClose();
          }}
        >
          <ListItemIcon>
            <SportsEsportsIcon />
          </ListItemIcon>
          <ListItemText primary={"ゲーム一覧"} />
        </ListItem>
        {false &&
          (["pro", "pro+", "debug"].includes(plan) ? (
            <ListItem
              button
              key={"pro-plan"}
              onClick={() => {
                navigate("/g/pro-plan");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary={"プラン変更"} />
            </ListItem>
          ) : (
            <ListItem
              button
              key={"pro-plan"}
              onClick={() => {
                navigate("/g/pro-plan");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary={"ProPlan"} />
            </ListItem>
          ))}
        <ListItem
          button
          key={"enigmahosue"}
          onClick={() => {
            handleDrawerClose();
            window.location.href = "https://enigmahouse.jp";
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"ホーム"} />
        </ListItem>
      </List>
    </Drawer>
  );
};
