import { Button, Grid } from "@mui/material";
import { FC, useCallback, useContext } from "react";
import { longestShiritori } from "../../../../../../lib/longestShiritori";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { ConvertProps } from "./ConvertTemplate";
import { splitGrapheme } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const ListConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;

  const shuffleArray = useCallback((array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const r = Math.floor(Math.random() * (i + 1));
      const tmp = array[i];
      array[i] = array[r];
      array[r] = tmp;
    }
    return array;
  }, []);

  return (
    <>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(pattern.replace(/\n/g, ","));
          }}
        >
          改行をカンマに
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(splitGrapheme(pattern).join("\n"));
          }}
        >
          文字→改行
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(pattern.replace(/,/g, "\n"));
          }}
        >
          カンマ→改行
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .replace(/\\n/g, "\n")
                .replace(/\\r/g, "\r")
                .replace(/\\t/g, "\t")
                .replace(/\\b/g, "\b")
                .replace(/\\f/g, "\f")
                .replace(/\\v/g, "\v")
                .replace(/\\0/g, "\0")
                .replace(/\\'/g, "'")
                .replace(/\\"/g, '"')
                .replace(/\\\\/g, "\\")
            );
          }}
        >
          エスケープ展開
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .map((line) => line.replace(/[\s,]/g, ""))
                .join("\n")
            );
          }}
        >
          カンマ空白除去
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(splitGrapheme(pattern).reverse().join(""));
          }}
        >
          全体反転
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              splitGrapheme(pattern.replace(/\n/g, "")).sort().join("")
            );
          }}
        >
          全体ソート
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            const lens = pattern.split("\n").map((e) => e.length);
            const word = shuffleArray(
              splitGrapheme(pattern.replace(/\n/g, ""))
            ).join("");

            let n = 0;
            const words = lens.map((len) => {
              const ret = word.slice(n, n + len);
              n += len;
              return ret;
            });
            setConverted(words.join("\n"));
          }}
        >
          全体シャッフル
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .map((e) => splitGrapheme(e).reverse().join(""))
                .join("\n")
            );
          }}
        >
          各行反転
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .map((e) => splitGrapheme(e).sort().join(""))
                .join("\n")
            );
          }}
        >
          各行ソート
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .map((e) => shuffleArray(splitGrapheme(e)).join(""))
                .join("\n")
            );
          }}
        >
          各行シャッフル
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(pattern.replace(/\n\n+/g, "\n"));
          }}
        >
          空行除去
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(pattern.split("\n").reverse().join("\n"));
          }}
        >
          行反転
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(pattern.split("\n").sort().join("\n"));
          }}
        >
          行ソート
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .sort((a, b) => a.length - b.length || a.localeCompare(b))
                .join("\n")
            );
          }}
        >
          文字数+辞書順行ソート
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(shuffleArray(pattern.split("\n")).join("\n"));
          }}
        >
          行シャッフル
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(longestShiritori(pattern.split("\n")).join("\n"));
          }}
          disabled={plan === "normal"}
        >
          最長しりとり
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setConverted(
              pattern
                .split("\n")
                .map((line) => splitGrapheme(line).join("\t"))
                .join("\n")
            );
          }}
        >
          文字間にtab
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            //transpose
            const lineArr = pattern.split("\n");
            const paddingCharactor = " ";
            const maxLen = Math.max(...lineArr.map((e) => e.length));
            const padding = (str: string) =>
              str + paddingCharactor.repeat(maxLen - str.length);
            const transposed = lineArr
              .map((e) => padding(e))
              .map((e) => splitGrapheme(e))
              .reduce((prev, current) => {
                current.forEach((e, i) => {
                  prev[i] = (prev[i] ?? "") + e;
                });
                return prev;
              }, [] as string[])
              .map((e) => e.trimEnd())
              .join("\n");

            setConverted(transposed);
          }}
          disabled={plan === "normal"}
        >
          転置
        </Button>
      </Grid>
    </>
  );
};
