"use client";
import { Button } from "@mui/material";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { FC, useCallback, useState } from "react";
import { useFirebaseConfig } from "../../EniverseProvider";

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const GoogleLogin: FC<Props> = ({ loading, setLoading }) => {
  const [{ firebaseAuth }] = useFirebaseConfig();

  const signInWithGoogle = useCallback(() => {
    if (!firebaseAuth) return;
    setLoading(true);
    const provider = new GoogleAuthProvider();
    console.log("auth", firebaseAuth, "provider", provider);
    signInWithRedirect(firebaseAuth, provider);
  }, [firebaseAuth, setLoading]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          signInWithGoogle();
        }}
        disabled={loading}
      >
        Googleでログイン
      </Button>
    </>
  );
};
