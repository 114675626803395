import { Container } from "@mui/material";

export const LgContainer = ({
  children,
  layout,
  ...props
}: {
  children: React.ReactNode;
  layout?: any;
  props?: any;
}) => {
  return (
    <Container
      maxWidth="lg"
      sx={{ bgcolor: "background.paper", ...layout }}
      {...props}
    >
      {children}
    </Container>
  );
};

export const MdContainer = ({
  children,
  layout,
  ...props
}: {
  children: React.ReactNode;
  layout?: any;
  props?: any;
}) => {
  return (
    <Container
      maxWidth="md"
      sx={{ bgcolor: "background.paper", ...layout }}
      {...props}
    >
      {children}
    </Container>
  );
};

export const ClearContainer = ({
  children,
  layout,
  ...props
}: {
  children: React.ReactNode;
  layout?: any;
  props?: any;
}) => {
  return (
    <Container
      maxWidth="md"
      sx={{ bgcolor: "rgba(255,255,255,0.7)", ...layout }}
      {...props}
    >
      {children}
    </Container>
  );
};
