import {
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { presetItems } from "./list/presetItems";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

type ListDefininitionProps = {
  editableItems: { name: string; value: string }[];
  handleDelete: (index: number) => void;
  handleAdd: () => void;
  inputName: string;
  setInputName: (name: string) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
};

export const ListDefininition: FC<ListDefininitionProps> = ({
  editableItems,
  handleDelete,
  handleAdd,
  inputName,
  setInputName,
  inputValue,
  setInputValue,
}) => {
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="h6">
            <b>リスト名</b>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">
            <b>リスト内容</b>
          </Typography>
        </Grid>
      </Grid>
      {presetItems.map((item, index) => (
        <Grid container key={index} spacing={3} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body2">{item.name}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{item.value}</Typography>
          </Grid>
        </Grid>
      ))}
      <List>
        {editableItems.map((item, index) => (
          <ListItem key={index} dense>
            <IconButton
              edge="start"
              size="small"
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body2">{item.name}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{item.value}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      {/* Add New Item */}
      <Box mt={0}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={1}>
            <IconButton
              edge="start"
              size="small"
              onClick={handleAdd}
              disabled={
                inputName.match(/[^a-zA-Z0-9_]/) !== null ||
                presetItems.map((item) => item.name).includes(inputName) ||
                editableItems.map((item) => item.name).includes(inputName)
              }
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              placeholder="Name"
              value={inputName}
              error={
                inputName.match(/[^a-zA-Z0-9_:|/]/) !== null ||
                presetItems.map((item) => item.name).includes(inputName) ||
                editableItems.map((item) => item.name).includes(inputName)
              }
              onChange={(e) => setInputName(e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              placeholder="Value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
