import { DictionaryTransform } from "@langue-de-chat-llc/enigmastudio-algorithm/dist/param/generalSearchQuery";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FC, useContext } from "react";
import { SeparatedDictionarySelector } from "../../../../commonStudio/SeparatedDictionarySelector";
import { BasicFormProps } from "../FormProps";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { ColumnSelector } from "../../../../commonStudio/ColumnSelector";
import { TextDocFile } from "../../../CloudItem/cloudType";

export const DictionaryTransformForm: FC<
  BasicFormProps<DictionaryTransform>
> = ({ index, query, updateQuery, deleteQueryField, isDemo, onBlur }) => {
  const { enabledUserDictionary } = useContext(EnigmaStudioContext);
  const selectedDictDoc = enabledUserDictionary.find(
    (e) => "user:" + e.key === query.dictionary
  );

  return (
    <>
      <SeparatedDictionarySelector
        labelName="区切り付き辞書"
        dictionary={query.dictionary}
        setDictionary={(value) => {
          updateQuery(index, "dictionary", value);
        }}
        isDemo={isDemo}
      />
      <ColumnSelector
        labelName="変換元"
        column={
          (selectedDictDoc?.doc as TextDocFile)?.headerText?.split(
            (selectedDictDoc?.doc as TextDocFile)?.separator || ","
          ) ?? []
        }
        selected={query.src}
        setSelected={(value) => {
          updateQuery(index, "src", value);
        }}
        isDemo={isDemo}
      />
      <ColumnSelector
        labelName="変換先"
        column={
          (selectedDictDoc?.doc as TextDocFile)?.headerText?.split(
            (selectedDictDoc?.doc as TextDocFile)?.separator || ","
          ) ?? []
        }
        selected={query.dst}
        setSelected={(value) => {
          updateQuery(index, "dst", value);
        }}
        isDemo={isDemo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.matchOnly ?? true}
            onChange={(e) => updateQuery(index, "matchOnly", e.target.checked)}
            onBlur={onBlur}
          />
        }
        sx={{ ml: 1 }}
        label="マッチのみ抽出"
        disabled={isDemo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.grouping ?? true}
            onChange={(e) => updateQuery(index, "grouping", e.target.checked)}
            onBlur={onBlur}
          />
        }
        sx={{ ml: 1 }}
        label="結果を集約"
        disabled={isDemo}
      />
    </>
  );
};
