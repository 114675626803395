import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { ModeType } from "./Menu/ModeSelector";
import { Pos } from "./gridToolType";
import { CharTypeValue } from "./GridTool";
import { romajiToHira } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const useKeydownHandler = ({
  mode,
  cursor,
  setCursor,
  whiteList,
  setWhiteList,
  blackList,
  setBlackList,
  cursorText,
  setCursorText,
  cursorTextKana,
  setCursorTextKana,
  whiteInputMode,
  setWhiteInputMode,
  charTypeValue,
  row,
  col,
}: {
  mode: ModeType | null;
  cursor: Pos;
  setCursor: Dispatch<SetStateAction<Pos>>;
  whiteList: Pos[];
  setWhiteList: Dispatch<SetStateAction<Pos[]>>;
  blackList: Pos[];
  setBlackList: Dispatch<SetStateAction<Pos[]>>;
  cursorText: { [key: string]: string };
  setCursorText: Dispatch<SetStateAction<{ [key: string]: string }>>;
  cursorTextKana: { [key: string]: string };
  setCursorTextKana: Dispatch<SetStateAction<{ [key: string]: string }>>;
  whiteInputMode: "add" | "delete";
  setWhiteInputMode: Dispatch<SetStateAction<"add" | "delete">>;
  charTypeValue: CharTypeValue;
  row: number;
  col: number;
}) => {
  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (mode !== "char") {
        return false;
      }
      e.preventDefault();
      console.log(e.key);
      const onlyShift =
        e.shiftKey &&
        !e.metaKey &&
        !e.ctrlKey &&
        !e.altKey &&
        !e.isComposing &&
        !e.code.match(/^F\d\d?$/);
      if (mode === "char") {
        // cursor
        if (e.key === "ArrowLeft" || e.key === "<") {
          setCursor((prev) => ({ ...prev, x: Math.max(0, prev.x - 1) }));

          // white
          if (cursor.x !== 0) {
            if (onlyShift) {
              if (whiteInputMode === "add") {
                const newWhiteList = [...whiteList];
                newWhiteList.push({ x: cursor.x - 1, y: cursor.y });
                setWhiteList(newWhiteList);
              } else {
                const newWhiteList = whiteList.filter(
                  (w) => w.x !== cursor.x - 1 || w.y !== cursor.y
                );
                setWhiteList(newWhiteList);
              }
            }
          }
        } else if (
          e.key === "ArrowRight" ||
          e.key === "Tab" ||
          e.key === "," ||
          e.key === ">"
        ) {
          setCursor((prev) => ({
            ...prev,
            x: Math.min(col - 1, prev.x + 1),
          }));

          // white
          if (cursor.x !== col - 1) {
            if (onlyShift) {
              if (whiteInputMode === "add") {
                const newWhiteList = [...whiteList];
                newWhiteList.push({ x: cursor.x + 1, y: cursor.y });
                setWhiteList(newWhiteList);
              } else {
                const newWhiteList = whiteList.filter(
                  (w) => w.x !== cursor.x + 1 || w.y !== cursor.y
                );
                setWhiteList(newWhiteList);
              }
            }
          }
        } else if (e.key === "ArrowUp" || e.key === ":" || e.key === "+") {
          setCursor((prev) => ({ ...prev, y: Math.max(0, prev.y - 1) }));

          // white
          if (cursor.y !== 0) {
            if (onlyShift) {
              if (whiteInputMode === "add") {
                const newWhiteList = [...whiteList];
                newWhiteList.push({ x: cursor.x, y: cursor.y - 1 });
                setWhiteList(newWhiteList);
              } else {
                const newWhiteList = whiteList.filter(
                  (w) => w.x !== cursor.x || w.y !== cursor.y - 1
                );
                setWhiteList(newWhiteList);
              }
            }
          }
        } else if (
          e.key === "ArrowDown" ||
          e.key === "Enter" ||
          e.key === ";" ||
          e.key === "."
        ) {
          setCursor((prev) => ({
            ...prev,
            y: Math.min(row - 1, prev.y + 1),
          }));

          // white
          if (cursor.y !== row - 1) {
            if (onlyShift) {
              if (whiteInputMode === "add") {
                const newWhiteList = [...whiteList];
                newWhiteList.push({ x: cursor.x, y: cursor.y + 1 });
                setWhiteList(newWhiteList);
              } else {
                const newWhiteList = whiteList.filter(
                  (w) => w.x !== cursor.x || w.y !== cursor.y + 1
                );
                setWhiteList(newWhiteList);
              }
            }
          }
        }
        console.log(e);
        if (e.key === "Shift" && onlyShift) {
          const newWhiteList = [...whiteList];
          const index = newWhiteList.findIndex(
            (w) => w.x === cursor.x && w.y === cursor.y
          );
          if (index !== -1) {
            newWhiteList.splice(index, 1);
            setWhiteInputMode("delete");
          } else {
            newWhiteList.push({ x: cursor.x, y: cursor.y });
            setWhiteInputMode("add");
          }
          setWhiteList(newWhiteList);
        }

        if (e.ctrlKey) {
          if (e.key === "h") {
            setCursor((prev) => ({ ...prev, x: Math.max(0, prev.x - 1) }));
          }
          if (e.key === "l") {
            setCursor((prev) => ({
              ...prev,
              x: Math.min(col - 1, prev.x + 1),
            }));
          }
          if (e.key === "k") {
            setCursor((prev) => ({ ...prev, y: Math.max(0, prev.y - 1) }));
          }
          if (e.key === "j") {
            setCursor((prev) => ({
              ...prev,
              y: Math.min(row - 1, prev.y + 1),
            }));
          }
        } else {
          // char
          if (e.key === " ") {
            const newBlackList = [...blackList];
            const index = newBlackList.findIndex(
              (b) => b.x === cursor.x && b.y === cursor.y
            );
            if (index !== -1) {
              newBlackList.splice(index, 1);
            } else {
              newBlackList.push({ x: cursor.x, y: cursor.y });
            }
            setBlackList(newBlackList);
          }
          // a-z to cursor text
          if (e.key.match(/^[a-zA-Z]$/)) {
            const newCursorText = { ...cursorText };
            if (
              !newCursorText[`${cursor.x}-${cursor.y}`] ||
              newCursorText[`${cursor.x}-${cursor.y}`].length >= 3 ||
              (newCursorText[`${cursor.x}-${cursor.y}`].length >= 2 &&
                !cursorTextKana[`${cursor.x}-${cursor.y}`].match(/[a-z]/))
            ) {
              newCursorText[`${cursor.x}-${cursor.y}`] = "";
            }
            newCursorText[`${cursor.x}-${cursor.y}`] += e.key;
            setCursorText(newCursorText);
            setCursorTextKana((prev) => {
              const newCursorTextKana = { ...prev };
              newCursorTextKana[`${cursor.x}-${cursor.y}`] = romajiToHira(
                newCursorText[`${cursor.x}-${cursor.y}`]
              ).substring(0, 1);
              return newCursorTextKana;
            });
          }
          if (e.key.match(/^[0-9]$/)) {
            const newCursorText = { ...cursorText };
            const newCursorTextKana = { ...cursorTextKana };
            if (
              !newCursorText[`${cursor.x}-${cursor.y}`] ||
              newCursorText[`${cursor.x}-${cursor.y}`].length >= 3 ||
              (newCursorText[`${cursor.x}-${cursor.y}`].length >= 2 &&
                !cursorTextKana[`${cursor.x}-${cursor.y}`].match(/[a-z0-9]/))
            ) {
              newCursorText[`${cursor.x}-${cursor.y}`] = "";
              newCursorTextKana[`${cursor.x}-${cursor.y}`] = "";
            }

            newCursorText[`${cursor.x}-${cursor.y}`] += e.key;
            newCursorTextKana[`${cursor.x}-${cursor.y}`] += e.key;
            setCursorText(newCursorText);
            setCursorTextKana(newCursorTextKana);
          }
          if (e.key === "-") {
            const newCursorText = { ...cursorText };
            const newCursorTextKana = { ...cursorTextKana };
            if (
              !newCursorText[`${cursor.x}-${cursor.y}`] ||
              newCursorText[`${cursor.x}-${cursor.y}`].length >= 3 ||
              (newCursorText[`${cursor.x}-${cursor.y}`].length >= 2 &&
                !cursorTextKana[`${cursor.x}-${cursor.y}`].match(/[a-z]/))
            ) {
              newCursorText[`${cursor.x}-${cursor.y}`] = "";
              newCursorTextKana[`${cursor.x}-${cursor.y}`] = "";
            }
            newCursorText[`${cursor.x}-${cursor.y}`] += e.key;
            newCursorTextKana[`${cursor.x}-${cursor.y}`] = "ー";
            setCursorText(newCursorText);
            setCursorTextKana(newCursorTextKana);
          }
          // delete
          if (e.key === "Backspace") {
            const newCursorText = { ...cursorText };
            if (newCursorText[`${cursor.x}-${cursor.y}`]) {
              if (
                charTypeValue === "alphabet" ||
                cursorTextKana[`${cursor.x}-${cursor.y}`].match(/[a-z]/)
              ) {
                newCursorText[`${cursor.x}-${cursor.y}`] = newCursorText[
                  `${cursor.x}-${cursor.y}`
                ].slice(0, -1);
                setCursorText(newCursorText);
                setCursorTextKana((prev) => {
                  const newCursorTextKana = { ...prev };
                  newCursorTextKana[`${cursor.x}-${cursor.y}`] = romajiToHira(
                    newCursorText[`${cursor.x}-${cursor.y}`]
                  );
                  return newCursorTextKana;
                });
              } else {
                newCursorText[`${cursor.x}-${cursor.y}`] = "";
                setCursorText(newCursorText);
                setCursorTextKana((prev) => {
                  const newCursorTextKana = { ...prev };
                  newCursorTextKana[`${cursor.x}-${cursor.y}`] = romajiToHira(
                    newCursorText[`${cursor.x}-${cursor.y}`]
                  );
                  return newCursorTextKana;
                });
              }
            }
          }
        }
        // delete
        if (e.key === "Delete") {
          const newCursorText = { ...cursorText };
          if (newCursorText[`${cursor.x}-${cursor.y}`]) {
            newCursorText[`${cursor.x}-${cursor.y}`] = "";
            setCursorText(newCursorText);
            setCursorTextKana((prev) => {
              const newCursorTextKana = { ...prev };
              newCursorTextKana[`${cursor.x}-${cursor.y}`] = romajiToHira(
                newCursorText[`${cursor.x}-${cursor.y}`]
              );
              return newCursorTextKana;
            });
          }
        }
      }
    },
    [
      mode,
      setCursor,
      cursor.x,
      cursor.y,
      whiteInputMode,
      whiteList,
      setWhiteList,
      col,
      row,
      setWhiteInputMode,
      blackList,
      setBlackList,
      cursorText,
      cursorTextKana,
      setCursorText,
      setCursorTextKana,
      charTypeValue,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  return {
    keyDownHandler,
  };
};
