import { checkReplacementCondition } from "../checkReplacementCondition";
import { convertFlat } from "../commonLoop";
import { createRegex } from "../exRegexp";
import { progress } from "../generalSearchProgress";
import { processNestedFunctions } from "../patternFunctions";
export const conditionalTransform = async ({ query, input, listDefinitionsTable, result, workerContext, }) => {
    const pattern = (query.pattern ?? "") === "" ? "^(.*)$" : query.pattern;
    const reg = createRegex(pattern, listDefinitionsTable);
    if (query.matchOnly) {
        result = result.filter((e) => {
            return e.currentWord.match(reg);
        });
    }
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount = result.length;
    return convertFlat(workerContext, result, (e) => {
        let flag = true;
        const replaced = e.currentWord.replace(reg, (match) => {
            const replacements = query.replacements.map((replacement) => {
                const replaced = match.replace(reg, replacement ?? "");
                return processNestedFunctions(replaced, listDefinitionsTable, e.trace ?? []);
            });
            flag = checkReplacementCondition({
                input,
                query,
                replacements,
                e,
            });
            return match.replace(reg, (flag ? query.replacementTrue : query.replacementFalse) ?? "");
        });
        return processNestedFunctions(replaced, listDefinitionsTable, e.trace).map((e2) => {
            // deep clone e
            const newe = JSON.parse(JSON.stringify(e));
            newe.currentWord = e2;
            return newe;
        });
    });
};
