import { httpsCallable } from "firebase/functions";
import { useState, useCallback } from "react";
import { functions } from "../../..";
import { Button } from "@mui/material";
import { useFirebaseConfig } from "../../../_lib/eniverse/EniverseProvider";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";

type PurchaseButtonProps = {
  label: string;
};

export const EnigmaStudioPaymentManagerButton = (
  props: PurchaseButtonProps
) => {
  const { user } = useFirebaseLogin();
  const [{ cloudFunctions }] = useFirebaseConfig();
  const [loading, setLoading] = useState(false);

  const showPaymentManager = useCallback(async () => {
    if (!user) {
      return;
    }
    if (loading) {
      return;
    }
    if (!cloudFunctions) {
      console.log("cloudFunctions is not ready");
      return;
    }
    setLoading(true);
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin + "/payment",
    });
    window.location.assign((data as any).url);
  }, [loading, user, cloudFunctions]);
  return (
    <Button
      variant="contained"
      onClick={() => showPaymentManager()}
      disabled={loading}
    >
      決済情報管理ページを開く
    </Button>
  );
};
