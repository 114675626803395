import CasinoIcon from "@mui/icons-material/Casino";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import EjectIcon from "@mui/icons-material/Eject";
import {
  Chip,
  Grid,
  Pagination,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ResultProps } from "../../commonStudio/GeneralSearchResult";
import { useLocalStorage } from "usehooks-ts";
import { DictionaryKey, dictionaries } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const WordSearchResult: FC<ResultProps> = ({
  items,
  loading,
  progress,
  reset,
  result,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [enableDictionary, setEnableDictionary] = useLocalStorage<
    DictionaryKey[]
  >("word-search-enable-dictionary", ["common", "illust1", "cefr"]);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [download, setDownload] = useState("");

  const resultItems = useMemo(() => {
    if (!result) {
      return [];
    }
    const set = new Set<string>();
    enableDictionary.forEach((key) => {
      if (result[key]) {
        result[key].forEach((item) => {
          set.add(item);
        });
      }
    });
    return [...set].sort();
  }, [result, enableDictionary]);

  useEffect(() => {
    setPage(1);
  }, [enableDictionary]);

  const handleDownload = useCallback(() => {
    setDownload(
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(resultItems.join("\n"))
    );
  }, [resultItems]);

  const handleOpenRandomWordSnack = useCallback(() => {
    if (!resultItems || resultItems.length === 0) {
      return;
    }
    enqueueSnackbar(
      resultItems[Math.floor(Math.random() * resultItems.length)]
    );
  }, [enqueueSnackbar, resultItems]);

  if (loading || items == null) {
    return (
      <>
        <Typography mt={2} variant="body1">
          検索中...{" "}
          {progress && ((progress.currentTime - progress.startTime) / 1000).toFixed(1)}s
        </Typography>
      </>
    );
  }

  return (
    <>
      {
        <>
          <Typography mt={2} variant="body1">
            {!loading && resultItems.length > 0 && (
              <>
                {reset && (
                  <Tooltip title="リセット">
                    <ToggleButton
                      value="reset"
                      size="small"
                      color="primary"
                      onChange={() => {
                        if (reset) {
                          reset();
                        }
                      }}
                      sx={{ mr: 1 }}
                    >
                      <EjectIcon />
                    </ToggleButton>
                  </Tooltip>
                )}
                <Tooltip title="リストをコピー">
                  <ToggleButton
                    value="copylist"
                    size="small"
                    color="primary"
                    onChange={() => {
                      navigator.clipboard.writeText(resultItems.join("\n"));
                    }}
                    sx={{ mr: 1 }}
                  >
                    <ContentCopyIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="ダウンロード">
                  <ToggleButton
                    value="download"
                    size="small"
                    color="primary"
                    onChange={() => {
                      handleDownload();
                    }}
                    component="a"
                    href={download}
                    download="download.txt"
                    sx={{ mr: 1 }}
                  >
                    <DownloadIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="ランダム">
                  <ToggleButton
                    value="random"
                    size="small"
                    color="primary"
                    onChange={() => {
                      handleOpenRandomWordSnack();
                    }}
                    sx={{ mr: 1 }}
                  >
                    <CasinoIcon />
                  </ToggleButton>
                </Tooltip>
              </>
            )}
          </Typography>
        </>
      }
      <Box
        sx={{
          my: 2,
        }}
      />
      {result &&
        (["illust1", "common", "buta", "cefr"] as DictionaryKey[]).map(
          (key: DictionaryKey) => {
            if (result[key] && result[key].length > 0) {
              return (
                <Chip
                  key={key}
                  label={`${dictionaries[key].name}(${result[key].length})`}
                  sx={{
                    m: 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEnableDictionary(
                      enableDictionary.includes(key)
                        ? enableDictionary.filter((item) => item !== key)
                        : [...enableDictionary, key]
                    );
                  }}
                  color={enableDictionary.includes(key) ? "primary" : "default"}
                />
              );
            }
            return <></>;
          }
        )}
      <Box
        sx={{
          my: 2,
        }}
      />
      <Typography>
        {resultItems.length}件中 {page * 300 - 299}-
        {Math.min(page * 300, resultItems.length)}件
      </Typography>
      <Box
        sx={{
          my: 2,
        }}
      />
      {resultItems.length >= 300 && (
        <Pagination
          count={Math.ceil(resultItems.length / 300)}
          page={page}
          onChange={handleChange}
        />
      )}
      <Box
        sx={{
          my: 2,
        }}
      />

      {!loading && (
        <Grid container spacing={1}>
          {resultItems.length > 0 &&
            resultItems
              .filter((_, i) => i < 300 * page && i >= 300 * (page - 1))
              .map((item, i) => (
                <Grid item xs={12} md={6} lg={4} key={i}>
                  {item}
                </Grid>
              ))}
        </Grid>
      )}

      <Box
        sx={{
          my: 2,
        }}
      />
      {resultItems.length >= 300 && (
        <Pagination
          count={Math.ceil(resultItems.length / 300)}
          page={page}
          onChange={handleChange}
        />
      )}
    </>
  );
};
