import { processNestedFunctions } from "./patternFunctions";
export const createKeyValueTable = (result, listDefinitionsTable, query) => {
    const table = {};
    for (const content of result) {
        const keys = processNestedFunctions(query.key, listDefinitionsTable, content.trace);
        const values = processNestedFunctions(query.value, listDefinitionsTable, content.trace);
        keys.forEach((key) => {
            values.forEach((value) => {
                table[key] = table[key] ?? [];
                table[key].push(value);
            });
        });
    }
    return table;
};
