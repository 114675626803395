import { Question } from "../events";
import { riddledPrefData } from "../rawData/todofuken";
import { createTake } from "../take";

export const riddledPref = {
  id: "riddledPref",
  title: "穴あき都道府県ゲーム",
  category: "ランダム出題",
  addMenu: true,
  menuDescription: (
    <>
      「＊＊＊＊＊う」→「ほっかいどう」のように、
      <br />
      ひらがなの一部が隠された状態から都道府県名をひらがなで答えます。
    </>
  ),
  descriptions: [
    <p key={0}>
      「＊＊＊＊＊う」→「ほっかいどう」のように、
      <br />
      ひらがなの一部が隠された状態から都道府県名をひらがなで答えます。
      <br />
      複数あるものは伏字のある都道府県名のみ答えてください。
      <br />
      「と」「ふ」「けん」は省略します。
      <br />
      全部で20問出題されます。
      <br />
    </p>,
  ],
  questions: [],
  submit: false,
  retry: true,
  createQuestions: () => {
    const questions: Question[] = [];
    riddledPrefData.forEach((e) => {
      const question = {
        problemText: e[1],
        answers: [e[0]],
      };
      questions.push(question);
    });
    return createTake(questions, 20);
  },
  shareButton: true,
  answerButton: true,
  hashTag: "穴あき都道府県",
};
