import { ThemeOptions } from "@mui/material";
import { createContext } from "react";

export type SiteConfigProps = {
  serviceId: string;
  siteName: string;
  hashedPassword: string;
  themeOptions: ThemeOptions;
};

export const defaultSiteConfig: SiteConfigProps = {
  serviceId: "eniverse",
  siteName: "Eniverse",
  hashedPassword: "",
  themeOptions: {},
};

export const SiteContext = createContext<
  [SiteConfigProps, React.Dispatch<React.SetStateAction<SiteConfigProps>>]
>([
  defaultSiteConfig,
  () => {
    return defaultSiteConfig;
  },
]);
