import { splitGrapheme } from "../../../wordGenerator/splitGrapheme";
import { estimateFilter, filter } from "../commonLoop";
const candidateCharactersFilterFunc = async ({ query, }) => {
    if ((query.candidateType ?? "candidate") === "candidate") {
        let exceptionsCount = 0;
        let characters = query.characters;
        if (query.characters.match(/\+(\d)+$/g)) {
            exceptionsCount = parseInt(query.characters.match(/\+(\d)+$/g)[0].slice(1));
            characters = query.characters.replace(/\+(\d)+$/g, "");
        }
        const set = new Set(splitGrapheme(characters));
        return (e) => {
            let flag = true;
            let counter = 0;
            for (const c of e.currentWord) {
                if (!set.has(c)) {
                    ++counter;
                    if (counter > exceptionsCount) {
                        flag = false;
                        break;
                    }
                }
            }
            return flag;
        };
    }
    else if (query.candidateType === "included") {
        return (e) => {
            let exceptionsCount = 0;
            let characters = query.characters;
            if (query.characters.match(/-(\d)+$/g)) {
                exceptionsCount = parseInt(query.characters.match(/-(\d)+$/g)[0].slice(1));
                characters = query.characters.replace(/-(\d)+$/g, "");
            }
            const counterTable = splitGrapheme(characters).reduce((table, c) => {
                table[c] = (table[c] ?? 0) + 1;
                return table;
            }, {});
            const wordCount = splitGrapheme(e.currentWord).reduce((table, c) => {
                table[c] = (table[c] ?? 0) + 1;
                return table;
            }, {});
            let flag = true;
            let counter = 0;
            for (const key in counterTable) {
                if (counterTable[key] > (wordCount[key] ?? 0)) {
                    counter += counterTable[key] - (wordCount[key] ?? 0);
                    if (counter > exceptionsCount) {
                        flag = false;
                        break;
                    }
                }
            }
            return flag;
        };
    }
    else if (query.candidateType === "excluded") {
        return (e) => {
            const set = new Set(splitGrapheme(query.characters));
            let flag = true;
            for (const c of e.currentWord) {
                if (set.has(c)) {
                    flag = false;
                    break;
                }
            }
            return flag;
        };
    }
    else if (query.candidateType === "supersequence") {
        return (e) => {
            const flag = new RegExp(splitGrapheme(query.characters).join(".*")).test(e.currentWord);
            return flag;
        };
    }
    else if (query.candidateType === "subsequence") {
        return (e) => {
            const flag = new RegExp("^" + splitGrapheme(query.characters).join("?") + "?$").test(e.currentWord);
            return flag;
        };
    }
    return (e) => true;
};
export const candidateCharactersFilter = async ({ query, input, result, workerContext, }) => {
    const filterFunc = await candidateCharactersFilterFunc({ query });
    if (input.estimation) {
        return estimateFilter(workerContext, result, filterFunc, input.estimation, query);
    }
    return filter(workerContext, result, filterFunc);
};
