import {
  brailleToAlphabet,
  brailleToKana,
  hiraganaToMorse,
  morseToAlphabet,
  morseToHiragana,
  orderConvert,
  orderToAlphabet,
  orderToHiragana,
  toBraille,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import { Button, Grid } from "@mui/material";
import { FC } from "react";
import { jaconv } from "../../../../../../lib/jaconv";
import { ConvertProps } from "./ConvertTemplate";

export const SomeConverter: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  return (
    <Grid item>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(orderConvert(jaconv.toHiragana(pattern.toLowerCase())));
        }}
      >
        あ/a→123
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(orderToHiragana(pattern));
        }}
      >
        123→あいう
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(orderToAlphabet(pattern));
        }}
      >
        123→abc
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          // morse convert / japanese, english
          setConverted(hiraganaToMorse(jaconv.toHiragana(pattern)));
        }}
      >
        あ/a→モールス
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          // morse convert / japanese, english
          setConverted(morseToHiragana(pattern));
        }}
      >
        モールス→かな
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(morseToAlphabet(pattern));
        }}
      >
        モールス→abc
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          // morse convert / japanese, english
          setConverted(toBraille(jaconv.toHiragana(pattern.toLowerCase())));
        }}
      >
        あ/a→点字
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          // morse convert / japanese, english
          setConverted(brailleToKana(pattern));
        }}
      >
        点字→かな
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          setConverted(brailleToAlphabet(pattern));
        }}
      >
        点字→abc
      </Button>
    </Grid>
  );
};
