import { useState, useEffect, FC } from "react";
import { WhiteLineCandidate, WordObj } from "../gridToolType";
import { Grid, Typography } from "@mui/material";
import { PlainText } from "../../../../common/PlainText";

type SkeletonWord = {
  word: string;
  wordIndex: number;
  fixed: boolean;
  unused: boolean;
};

type SkeletonList = {
  wordList: { [key: number]: SkeletonWord[] };
  minSize: number;
  maxSize: number;
};

export const useSkeletonList = ({
  displaySkeletonList,
  whiteLineCandidateList,
  wordCandidateList,
  wordList,
}: {
  displaySkeletonList: boolean;
  whiteLineCandidateList: WhiteLineCandidate[][];
  wordCandidateList: number[][];
  wordList: WordObj[];
}) => {
  // displaySkeletonList
  const [skeletonList, setSkeletonList] = useState<SkeletonList>({
    wordList: {},
    minSize: 0,
    maxSize: 0,
  });

  useEffect(() => {
    if (!displaySkeletonList) {
      return;
    }
    const newSkeletonList: SkeletonList = {
      wordList: {},
      minSize: 0,
      maxSize: 0,
    };
    const solving = whiteLineCandidateList.length > 0;

    for (const wordObj of wordList) {
      const { word } = wordObj;
      if (!newSkeletonList.wordList[word.length]) {
        newSkeletonList.wordList[word.length] = [];
      }
      newSkeletonList.wordList[word.length].push({
        word,
        wordIndex: wordObj.index,
        fixed: false,
        unused: solving,
      });
    }

    for (const list of Object.values(newSkeletonList.wordList)) {
      list.sort((a, b) => {
        if (a.word < b.word) {
          return -1;
        }
        if (a.word > b.word) {
          return 1;
        }
        return 0;
      });
    }

    newSkeletonList.minSize = Math.min(
      ...Object.keys(newSkeletonList.wordList).map((e) => Number(e))
    );
    newSkeletonList.maxSize = Math.max(
      ...Object.keys(newSkeletonList.wordList).map((e) => Number(e))
    );

    if (wordCandidateList.length > 0) {
      for (const wordList of Object.values(newSkeletonList.wordList)) {
        for (const word of wordList) {
          if (wordCandidateList[word.wordIndex].length > 0) {
            word.unused = false;
          }
          if (
            wordCandidateList[word.wordIndex].length === 1 &&
            whiteLineCandidateList[wordCandidateList[word.wordIndex][0]]
              .length === 1
          ) {
            word.fixed = true;
          }
        }
      }
    }

    setSkeletonList(newSkeletonList);
  }, [
    wordList,
    displaySkeletonList,
    wordCandidateList,
    whiteLineCandidateList,
  ]);

  return {
    skeletonList,
  };
};
type Props = {
  visible: boolean;
  skeletonList: SkeletonList;
};

export const SkeletonListView: FC<Props> = ({ visible, skeletonList }) => {
  if (!visible) {
    return <></>;
  }

  return (
    <Grid container>
      {[...Array(skeletonList.maxSize - skeletonList.minSize + 1).keys()].map(
        (i) => {
          const size = i + skeletonList.minSize;
          if (!skeletonList.wordList[size]) {
            return null;
          }
          return (
            <Grid item key={size} xs={2}>
              <PlainText>＜{size}文字＞</PlainText>
              {skeletonList.wordList[size]?.map((wordObj, i) => {
                if (wordObj.fixed) {
                  return (
                    <Typography
                      variant="body1"
                      key={i}
                      sx={{
                        textDecoration: "line-through",
                      }}
                    >
                      {wordObj.word}
                    </Typography>
                  );
                }
                if (wordObj.unused) {
                  return (
                    <Typography
                      variant="body1"
                      key={i}
                      sx={{
                        color: "gray",
                      }}
                    >
                      {wordObj.word}
                    </Typography>
                  );
                }
                return <PlainText key={i}>{wordObj.word}</PlainText>;
              })}
            </Grid>
          );
        }
      )}
    </Grid>
  );
};
