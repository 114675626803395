import { splitGrapheme } from "../../wordGenerator/splitGrapheme";
import { wordAllDifferents } from "../lib/bipartiteMatching";
import { createDictionaryTable, createSortedTable } from "./table/dictionaryTable";
const hasSubstringInAnyAdjacentArrays = (arr) => {
    for (let i = 0; i < arr.length - 1; i++) {
        const hasSubstring = arr[i].some((e1) => arr[i + 1].some((e2) => e2.includes(e1)));
        if (!hasSubstring) {
            return false;
        }
    }
    return true;
};
const hasNotSubstringInAnyAdjacentArrays = (arr) => {
    for (let i = 0; i < arr.length - 1; i++) {
        const hasnotSubstring = arr[i].some((e1) => arr[i + 1].some((e2) => !e2.includes(e1)));
        if (!hasnotSubstring) {
            return false;
        }
    }
    return true;
};
export const checkReplacementCondition = ({ input, query, replacements, e, }) => {
    let flag = true;
    if (query.compare === "equal") {
        flag =
            replacements.reduce((ret, replacement) => {
                return ret.filter((e2) => replacement.includes(e2));
            }).length > 0;
    }
    else if (query.compare === "anagramEqual") {
        flag =
            replacements
                .map((replacement) => {
                return replacement.map((e) => splitGrapheme(e).sort().join(""));
            })
                .reduce((ret, replacement) => {
                return ret.filter((e2) => replacement.includes(e2));
            }).length > 0;
    }
    else if (query.compare === "notEqual") {
        flag = !(replacements.reduce((ret, replacement) => {
            return ret.filter((e2) => replacement.includes(e2));
        }).length > 0);
    }
    else if (query.compare === "allDifferent") {
        flag = wordAllDifferents(replacements);
    }
    else if (query.compare === "dictionaryMatch") {
        const dictKey = query.dictionary ||
            (input.inputDictionary !== "free" ? input.inputDictionary : "");
        if (!dictKey) {
            return false;
        }
        const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
        const dict = createDictionaryTable(dictKeyArr);
        if (!dict) {
            return false;
        }
        flag = replacements.every((replacement) => {
            return replacement.some((e) => dict[e]);
        });
    }
    else if (query.compare === "dictionaryAnagramMatch") {
        const dictKey = query.dictionary ||
            (input.inputDictionary !== "free" ? input.inputDictionary : "");
        if (!dictKey) {
            return false;
        }
        const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
        const dict = createSortedTable(dictKeyArr);
        if (!dict) {
            return false;
        }
        flag = replacements.every((replacement) => {
            return replacement.some((e) => {
                const key = splitGrapheme(e).sort().join("");
                return dict[key] ?? false;
            });
        });
        if (flag) {
            e.trace = e.trace ?? [];
            replacements.forEach((replacement, i) => {
                if (!query.trace || i >= query.trace.length || !query.trace[i]) {
                    return;
                }
                const tr = replacement
                    .map((e) => dict[splitGrapheme(e).sort().join("")] ?? [])
                    .flat();
                if (tr.length === 1) {
                    e.trace.push(tr[0]);
                }
                else if (tr.length > 1) {
                    e.trace.push("{" + [...new Set(tr)].sort().join(",") + "}");
                }
            });
        }
    }
    else if (query.compare === "include") {
        flag = hasSubstringInAnyAdjacentArrays(replacements);
    }
    else if (query.compare === "exclude") {
        flag = hasNotSubstringInAnyAdjacentArrays(replacements);
    }
    else if (query.compare === "less-equal") {
        if (replacements.length >= 2 && replacements[0].length > 0) {
            let checkWord = replacements[0].sort()[0];
            for (let i = 1; i < replacements.length; i++) {
                let chk = replacements[i].filter((e) => checkWord <= e);
                if (chk.length === 0) {
                    flag = false;
                    break;
                }
                checkWord = chk.sort()[0];
            }
        }
    }
    else if (query.compare === "less") {
        if (replacements.length >= 2 && replacements[0].length > 0) {
            let checkWord = replacements[0].sort()[0];
            for (let i = 1; i < replacements.length; i++) {
                let chk = replacements[i].filter((e) => checkWord < e);
                if (chk.length === 0) {
                    flag = false;
                    break;
                }
                checkWord = chk.sort()[0];
            }
        }
    }
    // trace処理
    if (query.compare !== "dictionaryAnagramMatch") {
        (query.trace ?? [])
            .filter((_, i) => i < query.replacements.length)
            .forEach((trace, i) => {
            if (trace) {
                e.trace = e.trace ?? [];
                e.trace.push((replacements[i] ?? []).length === 0
                    ? ""
                    : replacements[i].length === 1
                        ? replacements[i][0]
                        : "{" + [...new Set(replacements[i])].sort().join(",") + "}");
            }
        });
    }
    return flag;
};
