import { FC } from "react";

import { PDFViewer } from "@react-pdf/renderer";
import { PDF } from "./pdf/Pdf";
import sampleImage from "./pdf/data/image/puzsq.3864e15a.jpg";
import { PdfData } from "./stackSpace/stackSpaceData";

export const ContestPdfBuilder: FC = () => {
  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <PDFViewer width="100%" height="100%">
        <PDF data={sample} />
      </PDFViewer>
    </div>
  );
};

const sample: PdfData = {
  title: "Sample Contest",
  header: {
    list: [
      {
        type: "horizon",
        list: [
          {
            type: "imageurl",
            url: sampleImage,
            width: 30,
            height: 30,
          },
          {
            type: "title",
            text: "Sample Puzzle Contest",
          },
        ],
      },
    ],
  },
  pages: {
    list: [
      { type: "title", text: "Nurikabe" },
      { type: "subtitle", text: "Author: Author1" },
      {
        type: "horizon",
        list: [
          {
            type: "vertical",
            width: 400,
            list: [
              {
                type: "text",
                text: "盤面のいくつかのマスを黒くぬりましょう。",
              },
              {
                type: "text",
                text: "盤面の数字は、その数字が含まれる、黒マスによって分断されたところ（シマと呼びます）のマスの数を表します。",
              },
              {
                type: "text",
                text: "すべてのシマに数字が１つずつ入るようにします。また、数字が入っているマスを黒くぬってはいけません。",
              },
              {
                type: "text",
                text: "すべての黒マスはタテヨコにひとつながりになっていなければなりません。",
              },
              {
                type: "text",
                text: "黒マスを２×２以上のカタマリにしてはいけません。",
              },
            ],
          },

          {
            type: "verticalspace",
            space: 20,
          },

          {
            type: "svg",
            url: "https://puzz.link/p?nurikabe/5/5/1k2k3k4k5",
            width: 100,
            height: 100,
          },
        ],
      },
      { type: "space", space: 20 },
      {
        type: "svg",
        url: "https://puzz.link/p?castle/10/10/zh021s00.00.225e142v034n",
        width: 400,
        height: 400,
      },
      { type: "pageDivider" },
      { type: "title", text: "Sun And Moon" },
      { type: "subtitle", text: "Author: Author2" },
      { type: "text", text: "ルール文" },
      {
        type: "svg",
        url: "https://puzz.link/p?moonsun/5/5/0h000e0090i000000",
        width: 200,
        height: 200,
      },
      { type: "space", space: 20 },
      { type: "title", text: "ABC Place" },
      { type: "subtitle", text: "Author: Author2" },
      { type: "text", text: "ルール文" },
      {
        type: "svg",
        url: "https://puzz.link/p?easyasabc/5/5/3/1l2h1n3m11k12o",
        width: "auto",
        height: 300,
      },
    ],
  },

  footer: {
    list: [
      {
        type: "horizon",
        list: [
          {
            type: "text",
            text: "Page: @@PAGE_COUNT@@ / @@PAGE_TOTAL@@",
          },
        ],
      },
    ],
  },
};
